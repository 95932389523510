const sgk_gen_1_metadata = {
    "1631": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1631",
                "description": "description",
                "dna": "594a42b1a4e72fa40d9de4660ed229416ab83b8f",
                "date": 1683774892992,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1631",
                "rarity_score": 182.049044290634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1631.png",
            "scheme": "https"
        }
    },
    "1210": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1210",
                "description": "description",
                "dna": "55b5c58674bce23b1aa03e8ffb6ea32470d68e90",
                "date": 1683439425831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1210",
                "rarity_score": 164.6385592696189,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1210.png",
            "scheme": "https"
        }
    },
    "3098": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3098",
                "description": "description",
                "dna": "26064d63e6b40c899a6d6568ae757c17ea74d034",
                "date": 1683774933515,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3098",
                "rarity_score": 233.58220063119822,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3098.png",
            "scheme": "https"
        }
    },
    "428": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #428",
                "description": "description",
                "dna": "0b34bafb51d4a3bff5faed01c7f9257648915181",
                "date": 1683430812306,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "428",
                "rarity_score": 158.32123262182606,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/428.png",
            "scheme": "https"
        }
    },
    "28": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #28",
                "description": "description",
                "dna": "1037ba790188ee4cdbd647c3b8de5cb5855c612f",
                "date": 1683447002556,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "28",
                "rarity_score": 191.91660184090952,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/28.png",
            "scheme": "https"
        }
    },
    "1526": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1526",
                "description": "description",
                "dna": "b6fe6cd18d0efc0ba3a162155592e3775217946d",
                "date": 1683434412069,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1526",
                "rarity_score": 170.83613972435833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1526.png",
            "scheme": "https"
        }
    },
    "3336": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3336",
                "description": "description",
                "dna": "11db28e0cd6f5ab5ac082223eedb962a9eb16ec9",
                "date": 1683776412887,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3336",
                "rarity_score": 190.03268268389476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3336.png",
            "scheme": "https"
        }
    },
    "3676": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3676",
                "description": "description",
                "dna": "297b838984cce7d9e3e15b53b7cc6e0d6f07ae0e",
                "date": 1683776337308,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3676",
                "rarity_score": 394.31983476084656,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3676.png",
            "scheme": "https"
        }
    },
    "2961": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2961",
                "description": "description",
                "dna": "6402c2cfa55ca834929b8b5300635f47bc67fa4e",
                "date": 1683436719645,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2961",
                "rarity_score": 287.7068016058991,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2961.png",
            "scheme": "https"
        }
    },
    "691": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #691",
                "description": "description",
                "dna": "daa68a21ecf4e500d45129eb90eaa4e8762b07f3",
                "date": 1683442900026,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "691",
                "rarity_score": 169.85894657168944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/691.png",
            "scheme": "https"
        }
    },
    "941": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #941",
                "description": "description",
                "dna": "a0a863e6b6f6210b33e32f4a0b674430ad7a93ad",
                "date": 1683442843897,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "941",
                "rarity_score": 248.32285818299252,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/941.png",
            "scheme": "https"
        }
    },
    "2989": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2989",
                "description": "description",
                "dna": "b87f754f66d013ba0f5e426d5ffa5afe4c350a37",
                "date": 1683429486654,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2989",
                "rarity_score": 171.8176441973689,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2989.png",
            "scheme": "https"
        }
    },
    "2052": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2052",
                "description": "description",
                "dna": "581a3c822c9791d1bb6ffdb953c367a4abbbbdfb",
                "date": 1683429593643,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2052",
                "rarity_score": 217.36165275440584,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2052.png",
            "scheme": "https"
        }
    },
    "928": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #928",
                "description": "description",
                "dna": "0b726f7d621e0667e8a51938aa554e1a89be2f23",
                "date": 1683430575275,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "928",
                "rarity_score": 440.10084396559284,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/928.png",
            "scheme": "https"
        }
    },
    "2139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2139",
                "description": "description",
                "dna": "f80e3065d4c8ca83cf50e7ac7826d0f0275ce303",
                "date": 1683437507196,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2139",
                "rarity_score": 161.79005086640578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2139.png",
            "scheme": "https"
        }
    },
    "1990": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1990",
                "description": "description",
                "dna": "a8dd95817fcf37cbfadb71b43dcf4bdc3a46da92",
                "date": 1683436910333,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1990",
                "rarity_score": 143.26153711643914,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1990.png",
            "scheme": "https"
        }
    },
    "2165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2165",
                "description": "description",
                "dna": "66a41d65ac8be2ab3dd605c610d8bdde48d20f8a",
                "date": 1683774686537,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2165",
                "rarity_score": 186.75406556803637,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2165.png",
            "scheme": "https"
        }
    },
    "4043": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4043",
                "description": "description",
                "dna": "b25192e78849707d2e7da45a6d227e25792e34ea",
                "date": 1683433847748,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4043",
                "rarity_score": 160.96655618677954,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4043.png",
            "scheme": "https"
        }
    },
    "938": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #938",
                "description": "description",
                "dna": "1ce88a5dd2bc31502eeb6c85cff623ff9c198561",
                "date": 1683446222088,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "938",
                "rarity_score": 178.14695429253888,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/938.png",
            "scheme": "https"
        }
    },
    "4020": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4020",
                "description": "description",
                "dna": "a8e349c18d6bb2d87094005090534ed3ff9fc291",
                "date": 1683445754333,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4020",
                "rarity_score": 265.81877326180114,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4020.png",
            "scheme": "https"
        }
    },
    "104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #104",
                "description": "description",
                "dna": "0c0851d17f37349fdbd0bf0db5f6db9ee9bccdd6",
                "date": 1683774852405,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "104",
                "rarity_score": 151.3998581722991,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/104.png",
            "scheme": "https"
        }
    },
    "2879": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2879",
                "description": "description",
                "dna": "8394bd9403f5d53e84c4e986634b168d15b8938c",
                "date": 1683435705793,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2879",
                "rarity_score": 187.81035910732876,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2879.png",
            "scheme": "https"
        }
    },
    "2435": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2435",
                "description": "description",
                "dna": "aa3613889ec6c2f4dbafc32bc33c5c4f2abd9d85",
                "date": 1683439264468,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2435",
                "rarity_score": 192.16714069287352,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2435.png",
            "scheme": "https"
        }
    },
    "4014": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4014",
                "description": "description",
                "dna": "440c4aa5ab04f1298bf9535f321e74f8429ee19a",
                "date": 1683446855082,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "4014",
                "rarity_score": 160.63281608091268,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4014.png",
            "scheme": "https"
        }
    },
    "1365": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1365",
                "description": "description",
                "dna": "25332d4620eb1ffec7eba2116e5f073c9248c670",
                "date": 1683771421039,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1365",
                "rarity_score": 211.0525300359674,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1365.png",
            "scheme": "https"
        }
    },
    "414": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #414",
                "description": "description",
                "dna": "e0f2540ac5362224744404e39d8fd315241db7dc",
                "date": 1683774444234,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "414",
                "rarity_score": 204.4289139636811,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/414.png",
            "scheme": "https"
        }
    },
    "87": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #87",
                "description": "description",
                "dna": "ef431245cd2d8ca099bc73faf19f6d06d345627d",
                "date": 1683434956418,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "87",
                "rarity_score": 201.64273447842595,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/87.png",
            "scheme": "https"
        }
    },
    "2924": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2924",
                "description": "description",
                "dna": "dc1e308cbd3944acb9b82508eee3fb4b4c569ae3",
                "date": 1683444690847,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2924",
                "rarity_score": 207.55537719684787,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2924.png",
            "scheme": "https"
        }
    },
    "2958": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2958",
                "description": "description",
                "dna": "87451afa9a5813683c1d684cf73016a368cc46d0",
                "date": 1683436761267,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2958",
                "rarity_score": 308.0142518423111,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2958.png",
            "scheme": "https"
        }
    },
    "603": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #603",
                "description": "description",
                "dna": "ac1148fb0dc153997461248e64432411d0e8d284",
                "date": 1683436634839,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "603",
                "rarity_score": 192.39435065894142,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/603.png",
            "scheme": "https"
        }
    },
    "1676": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1676",
                "description": "description",
                "dna": "21aea94d617b52ee9e9a4607d2f673563aef07ac",
                "date": 1683439152281,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1676",
                "rarity_score": 171.86612943218094,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1676.png",
            "scheme": "https"
        }
    },
    "370": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #370",
                "description": "description",
                "dna": "1734425f44b7a6d5de394bc95721a11eeca3641a",
                "date": 1683443119811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "370",
                "rarity_score": 213.0749578804295,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/370.png",
            "scheme": "https"
        }
    },
    "635": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #635",
                "description": "description",
                "dna": "a82cfdf99f02db5a8fd0f3a588230efa56f3c5b3",
                "date": 1683436990633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "635",
                "rarity_score": 148.755459549022,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/635.png",
            "scheme": "https"
        }
    },
    "1684": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1684",
                "description": "description",
                "dna": "884106944ff52b62af59d039d10ee632c428d8b1",
                "date": 1683434823210,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1684",
                "rarity_score": 275.2899491438345,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1684.png",
            "scheme": "https"
        }
    },
    "2429": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2429",
                "description": "description",
                "dna": "8d82901efe5166480b615fb34cde328b84b19b97",
                "date": 1683435560125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2429",
                "rarity_score": 167.60762493861645,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2429.png",
            "scheme": "https"
        }
    },
    "1801": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1801",
                "description": "description",
                "dna": "740e06209e6610e4be354e4151da24478ed9ba18",
                "date": 1683437958004,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1801",
                "rarity_score": 220.65769047581225,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1801.png",
            "scheme": "https"
        }
    },
    "2843": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2843",
                "description": "description",
                "dna": "d3ae86ac90fec273c1cd744f82c023951497d48b",
                "date": 1683771303129,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2843",
                "rarity_score": 190.10066456938833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2843.png",
            "scheme": "https"
        }
    },
    "1066": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1066",
                "description": "description",
                "dna": "bdb35517c690300060aa9350c22fb2c83235e85d",
                "date": 1683436223247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1066",
                "rarity_score": 254.37998296293227,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1066.png",
            "scheme": "https"
        }
    },
    "1092": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1092",
                "description": "description",
                "dna": "cc3e1c2ed1c61b278395b0e2edfa9e8c1d26bf1b",
                "date": 1683440854987,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1092",
                "rarity_score": 200.09924774091743,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1092.png",
            "scheme": "https"
        }
    },
    "385": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #385",
                "description": "description",
                "dna": "791c50469d9e0c6dd59e0074a22b3d7b50d3999d",
                "date": 1683431192960,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "385",
                "rarity_score": 176.49848222442768,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/385.png",
            "scheme": "https"
        }
    },
    "256": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #256",
                "description": "description",
                "dna": "465b2747af5e557f48d55110942ac16c4bb8516c",
                "date": 1683446459020,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "256",
                "rarity_score": 155.47843409005935,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/256.png",
            "scheme": "https"
        }
    },
    "2089": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2089",
                "description": "description",
                "dna": "62f53eb5e9005d1ebf20e59415d054d38a83ca17",
                "date": 1684016277153,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Gem",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2089",
                "rarity_score": 4236.782795712434,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2089.png",
            "scheme": "https"
        }
    },
    "2811": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2811",
                "description": "description",
                "dna": "f18efa59cf75b6595996d07a538cb88cb377d9a1",
                "date": 1683447393725,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2811",
                "rarity_score": 222.0112781264716,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2811.png",
            "scheme": "https"
        }
    },
    "1615": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1615",
                "description": "description",
                "dna": "108ef85118514816f4e1931b4b605d812dfcdaed",
                "date": 1683445790377,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1615",
                "rarity_score": 207.9734943148926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1615.png",
            "scheme": "https"
        }
    },
    "1172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1172",
                "description": "description",
                "dna": "c3e32bf8d24ab51638e5cfab10ea64117e732a3d",
                "date": 1683445630597,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1172",
                "rarity_score": 234.94635419253493,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1172.png",
            "scheme": "https"
        }
    },
    "2658": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2658",
                "description": "description",
                "dna": "48aa05232750aa7f4e4b913b394cfeedbc06128d",
                "date": 1683444824454,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2658",
                "rarity_score": 235.04912736260272,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2658.png",
            "scheme": "https"
        }
    },
    "945": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #945",
                "description": "description",
                "dna": "b6bfe4c4b10fa0fa078af729e1d872a9901fd508",
                "date": 1683444876653,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "945",
                "rarity_score": 166.77103967590563,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/945.png",
            "scheme": "https"
        }
    },
    "3927": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3927",
                "description": "description",
                "dna": "257e3b0fa468e58aff547574f4f5dab374c4b47c",
                "date": 1683433043581,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3927",
                "rarity_score": 201.07132082832288,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3927.png",
            "scheme": "https"
        }
    },
    "3359": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3359",
                "description": "description",
                "dna": "3cf6c0e6b46bf37b62e0e1a1c0982a910d2a81c7",
                "date": 1683438065780,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3359",
                "rarity_score": 406.32138851682186,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3359.png",
            "scheme": "https"
        }
    },
    "3206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3206",
                "description": "description",
                "dna": "b1a89524a1e5b63e4927bf99f80f021ce551c5a8",
                "date": 1683775082750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3206",
                "rarity_score": 182.26802427472597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3206.png",
            "scheme": "https"
        }
    },
    "2256": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2256",
                "description": "description",
                "dna": "682bc73ba81555633537598e6f33baace62cf654",
                "date": 1683442633231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2256",
                "rarity_score": 166.57833697066974,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2256.png",
            "scheme": "https"
        }
    },
    "3204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3204",
                "description": "description",
                "dna": "7c8226957cfad756ded6be64d8786c4064b940fb",
                "date": 1683439578395,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3204",
                "rarity_score": 203.91210538920907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3204.png",
            "scheme": "https"
        }
    },
    "3953": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3953",
                "description": "description",
                "dna": "fc7da188ca95f2536ecad92d448bb5c44ff49ee1",
                "date": 1683430012206,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3953",
                "rarity_score": 168.97947853178167,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3953.png",
            "scheme": "https"
        }
    },
    "3710": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3710",
                "description": "description",
                "dna": "ea3313479af633f756fcecf18446acc3fcd896bc",
                "date": 1683447268239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3710",
                "rarity_score": 248.968975625916,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3710.png",
            "scheme": "https"
        }
    },
    "3427": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3427",
                "description": "description",
                "dna": "ec4d301e1e1d3a564df8b2d8b3f3723e17662d05",
                "date": 1683436779052,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3427",
                "rarity_score": 203.97802609538368,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3427.png",
            "scheme": "https"
        }
    },
    "2236": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2236",
                "description": "description",
                "dna": "c11c27001c1d5254f1103902021321d46f95be07",
                "date": 1683438935346,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2236",
                "rarity_score": 164.8584123051528,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2236.png",
            "scheme": "https"
        }
    },
    "2058": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2058",
                "description": "description",
                "dna": "347bd7c6390a327a60bf87a4b0a54ad1f18e0de6",
                "date": 1683444537971,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2058",
                "rarity_score": 223.29842576388515,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2058.png",
            "scheme": "https"
        }
    },
    "2394": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2394",
                "description": "description",
                "dna": "648d468fcf3bb9321b1a143fc0a866292832c541",
                "date": 1683430878991,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2394",
                "rarity_score": 250.68687968915222,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2394.png",
            "scheme": "https"
        }
    },
    "1029": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1029",
                "description": "description",
                "dna": "ab332eefb4c973fdf33c50ba5e543a12ef960571",
                "date": 1683770498687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1029",
                "rarity_score": 253.08411244745201,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1029.png",
            "scheme": "https"
        }
    },
    "1281": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1281",
                "description": "description",
                "dna": "ef1c380b7ea58d5cca67d38fb2c30a9852f52152",
                "date": 1683442808296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1281",
                "rarity_score": 187.1544058072383,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1281.png",
            "scheme": "https"
        }
    },
    "206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #206",
                "description": "description",
                "dna": "3630708b597bdd8ea4fb11f45713f512bd583c72",
                "date": 1683440084010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "206",
                "rarity_score": 176.98421637297076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/206.png",
            "scheme": "https"
        }
    },
    "3911": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3911",
                "description": "description",
                "dna": "2ea8ccded9fc4aa2601591603cfde262682cb8d0",
                "date": 1683437953916,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3911",
                "rarity_score": 219.8101454125387,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3911.png",
            "scheme": "https"
        }
    },
    "690": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #690",
                "description": "description",
                "dna": "7706f48a7e6e7b9d291952e99818146ca599e5c9",
                "date": 1683446054666,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "690",
                "rarity_score": 123.4793889682634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/690.png",
            "scheme": "https"
        }
    },
    "2112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2112",
                "description": "description",
                "dna": "05814a0001c465e8cc269fa54c1f92bdadd40aff",
                "date": 1683437927540,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2112",
                "rarity_score": 183.83280184593082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2112.png",
            "scheme": "https"
        }
    },
    "493": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #493",
                "description": "description",
                "dna": "3aacd6f8225f95fa576113872654a93ac73de8e2",
                "date": 1683442786119,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "493",
                "rarity_score": 208.96800316870102,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/493.png",
            "scheme": "https"
        }
    },
    "4023": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4023",
                "description": "description",
                "dna": "18d0b76ffc5ccfd4ea8960ad65799030472b5ca8",
                "date": 1683437782839,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "4023",
                "rarity_score": 217.09206719419825,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4023.png",
            "scheme": "https"
        }
    },
    "1104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1104",
                "description": "description",
                "dna": "27ad73f46e03d9cada34adab005159d20e1e22c0",
                "date": 1683446210257,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1104",
                "rarity_score": 167.2377913141085,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1104.png",
            "scheme": "https"
        }
    },
    "3292": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3292",
                "description": "description",
                "dna": "ecd408c80dd7230ef90c295a09c46d6126aa0122",
                "date": 1683441373308,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3292",
                "rarity_score": 197.88371262929158,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3292.png",
            "scheme": "https"
        }
    },
    "1473": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1473",
                "description": "description",
                "dna": "28253718714d988515a088e6ab38faeb30a79b6c",
                "date": 1683436881131,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1473",
                "rarity_score": 125.63293944734748,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1473.png",
            "scheme": "https"
        }
    },
    "1831": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1831",
                "description": "description",
                "dna": "fb937f756f6f6580f15b89898cb5b2bc4f08e64d",
                "date": 1683436538326,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1831",
                "rarity_score": 272.961996733694,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1831.png",
            "scheme": "https"
        }
    },
    "2095": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2095",
                "description": "description",
                "dna": "cad7b483fcc472d19d33260ae13987f0ce51db54",
                "date": 1683432413821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2095",
                "rarity_score": 209.22840205167307,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2095.png",
            "scheme": "https"
        }
    },
    "1438": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1438",
                "description": "description",
                "dna": "f9935232255a7c38bb54847363be648b8b83f50c",
                "date": 1683443206422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1438",
                "rarity_score": 204.32609415549146,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1438.png",
            "scheme": "https"
        }
    },
    "3390": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3390",
                "description": "description",
                "dna": "b3fb1f2306182b7cf26e3f97dbc5d030601d65ba",
                "date": 1683445106503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3390",
                "rarity_score": 191.18102001958516,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3390.png",
            "scheme": "https"
        }
    },
    "1635": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1635",
                "description": "description",
                "dna": "1d589963a9c9c5a2caa7e13d34d901f7bba8f63f",
                "date": 1683438405071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1635",
                "rarity_score": 262.51401139907216,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1635.png",
            "scheme": "https"
        }
    },
    "3880": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3880",
                "description": "description",
                "dna": "d22c123ac40d0973330485c2c6545924049719b9",
                "date": 1683771547831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3880",
                "rarity_score": 150.6660441538008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3880.png",
            "scheme": "https"
        }
    },
    "2782": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2782",
                "description": "description",
                "dna": "217dbc29d000170c8ec8fae1fcd80e95b8ba4a2b",
                "date": 1683439479514,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2782",
                "rarity_score": 197.32778048944965,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2782.png",
            "scheme": "https"
        }
    },
    "2286": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2286",
                "description": "description",
                "dna": "99abcd400d459a1a5dd4ce934787e882b2101e87",
                "date": 1683446759893,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2286",
                "rarity_score": 161.8306466862492,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2286.png",
            "scheme": "https"
        }
    },
    "1898": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1898",
                "description": "description",
                "dna": "d6b662f27783915c7fd3f7da09fdaf111e6ddb97",
                "date": 1683437572631,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1898",
                "rarity_score": 131.94426195199554,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1898.png",
            "scheme": "https"
        }
    },
    "2147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2147",
                "description": "description",
                "dna": "665b8682c86d51256e5a61177b9d04c3d565c879",
                "date": 1683441248297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2147",
                "rarity_score": 233.46409031008292,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2147.png",
            "scheme": "https"
        }
    },
    "1860": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1860",
                "description": "description",
                "dna": "68c90c760e07aa7bc32dc7e40176f865aea33690",
                "date": 1683433650625,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1860",
                "rarity_score": 172.9154075938843,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1860.png",
            "scheme": "https"
        }
    },
    "1704": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1704",
                "description": "description",
                "dna": "05baefb2ed291bb1fc6fa557e6a28a06dc843173",
                "date": 1683447135334,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1704",
                "rarity_score": 233.3303597028911,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1704.png",
            "scheme": "https"
        }
    },
    "1984": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1984",
                "description": "description",
                "dna": "e5ab90a684474bee308e633f5275738325ca8375",
                "date": 1683446016004,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1984",
                "rarity_score": 145.23492665133048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1984.png",
            "scheme": "https"
        }
    },
    "2387": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2387",
                "description": "description",
                "dna": "b10fd15dc766319e86711241fd9eac1eeca2c496",
                "date": 1683442073782,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2387",
                "rarity_score": 201.8759918181357,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2387.png",
            "scheme": "https"
        }
    },
    "535": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #535",
                "description": "description",
                "dna": "0ad0772049ea4d20110b51bf8c6a457eaf62632d",
                "date": 1683771539538,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "535",
                "rarity_score": 178.04185697483763,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/535.png",
            "scheme": "https"
        }
    },
    "3642": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3642",
                "description": "description",
                "dna": "af8045be4e339f82b51751aba27d38bb736f5419",
                "date": 1683433988824,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3642",
                "rarity_score": 217.19485513323013,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3642.png",
            "scheme": "https"
        }
    },
    "3878": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3878",
                "description": "description",
                "dna": "5b8022677508507c0b97106f8b314dd43ffcb72e",
                "date": 1683434488942,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3878",
                "rarity_score": 203.46112396760438,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3878.png",
            "scheme": "https"
        }
    },
    "35": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #35",
                "description": "description",
                "dna": "d0a8f9519a3dcec8bf7db8f25b31f05c58c98122",
                "date": 1683508679394,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "35",
                "rarity_score": 384.752511486759,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/35.png",
            "scheme": "https"
        }
    },
    "3331": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3331",
                "description": "description",
                "dna": "ffc2c21a5db3cde8fc87375d47a0318f06f81e2c",
                "date": 1683431363779,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3331",
                "rarity_score": 355.9506349501262,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3331.png",
            "scheme": "https"
        }
    },
    "799": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #799",
                "description": "description",
                "dna": "d2d3e85ede6abf38034c4feacc9ca57b823476a7",
                "date": 1683446947354,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "799",
                "rarity_score": 189.05841769678338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/799.png",
            "scheme": "https"
        }
    },
    "1854": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1854",
                "description": "description",
                "dna": "8e8c0b038ef8eb9f46627def1209b373c57bd243",
                "date": 1683444506748,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1854",
                "rarity_score": 200.6534578721757,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1854.png",
            "scheme": "https"
        }
    },
    "183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #183",
                "description": "description",
                "dna": "936dbd6348875978523ce6927aec68ccfa70043c",
                "date": 1683434707524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "183",
                "rarity_score": 164.45032074929816,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/183.png",
            "scheme": "https"
        }
    },
    "2232": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2232",
                "description": "description",
                "dna": "edeb2517db089921381bf3f67c1bf1b8843ab30f",
                "date": 1683442268264,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2232",
                "rarity_score": 165.2566040947587,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2232.png",
            "scheme": "https"
        }
    },
    "3448": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3448",
                "description": "description",
                "dna": "81468fa49bb26b76ec0692a69f35dee5544c9bd4",
                "date": 1683432374669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3448",
                "rarity_score": 203.31027871075878,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3448.png",
            "scheme": "https"
        }
    },
    "552": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #552",
                "description": "description",
                "dna": "7e042ab374d7a99a9a0e0b4a46a669c0c5ace4d0",
                "date": 1683440161697,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "552",
                "rarity_score": 221.83883752446084,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/552.png",
            "scheme": "https"
        }
    },
    "1577": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1577",
                "description": "description",
                "dna": "8e833699349e5268e7d4581b613c4e89feb08a5b",
                "date": 1683436692606,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1577",
                "rarity_score": 208.32198958350148,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1577.png",
            "scheme": "https"
        }
    },
    "3713": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3713",
                "description": "description",
                "dna": "0cd3d6ce818c642223f616edc12870935e3844f8",
                "date": 1683439832707,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3713",
                "rarity_score": 288.0288700145458,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3713.png",
            "scheme": "https"
        }
    },
    "949": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #949",
                "description": "description",
                "dna": "210cc5692c0b221ae658f9b1cbaffe82855127fc",
                "date": 1683447147158,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "949",
                "rarity_score": 181.1627033904298,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/949.png",
            "scheme": "https"
        }
    },
    "3738": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3738",
                "description": "description",
                "dna": "a30e9029db2aa47f4ed256545e52b6121d1dff30",
                "date": 1683446395704,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3738",
                "rarity_score": 123.35348622938355,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3738.png",
            "scheme": "https"
        }
    },
    "434": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #434",
                "description": "description",
                "dna": "1444f60611a36feeaa795f9ec43de0c3baf7b7c1",
                "date": 1683445388429,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "434",
                "rarity_score": 164.31013744791053,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/434.png",
            "scheme": "https"
        }
    },
    "1958": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1958",
                "description": "description",
                "dna": "fcea3305e8314f1c654cf274ac7221be7e83e395",
                "date": 1683433843116,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1958",
                "rarity_score": 157.182944687248,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1958.png",
            "scheme": "https"
        }
    },
    "119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #119",
                "description": "description",
                "dna": "01618058005196877343a969deee470eaca681fd",
                "date": 1683442573550,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "119",
                "rarity_score": 204.5765469370296,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/119.png",
            "scheme": "https"
        }
    },
    "2230": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2230",
                "description": "description",
                "dna": "0765a080282c3c2880ecac047c724b9a2114e499",
                "date": 1683438117631,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2230",
                "rarity_score": 183.53321204232475,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2230.png",
            "scheme": "https"
        }
    },
    "3083": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3083",
                "description": "description",
                "dna": "a0c5283dfb41b510ca8e4c7e39167cba15343c10",
                "date": 1683435015533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3083",
                "rarity_score": 173.26059556856163,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3083.png",
            "scheme": "https"
        }
    },
    "349": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #349",
                "description": "description",
                "dna": "6133f43f90e8286a3e123d328ed3e5fb5afed869",
                "date": 1683438269514,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "349",
                "rarity_score": 243.91402063286847,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/349.png",
            "scheme": "https"
        }
    },
    "3606": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3606",
                "description": "description",
                "dna": "e55d1b7d76f0e0e1b76375f332cda1976d132882",
                "date": 1683441516193,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3606",
                "rarity_score": 174.2219530219135,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3606.png",
            "scheme": "https"
        }
    },
    "1540": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1540",
                "description": "description",
                "dna": "00fe3eef7a39b6306ebb38b25ce029d8b11f9044",
                "date": 1683517604434,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 5",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1540",
                "rarity_score": 4225.437997138567,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1540.png",
            "scheme": "https"
        }
    },
    "1626": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1626",
                "description": "description",
                "dna": "3e332110f3c16efc3a9f97b6dde7ee7a3362039d",
                "date": 1683431735223,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1626",
                "rarity_score": 308.2563487858557,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1626.png",
            "scheme": "https"
        }
    },
    "3136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3136",
                "description": "description",
                "dna": "cca71f49bd711b7887a3f0369b7bc5b0444ad5a8",
                "date": 1683431138056,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3136",
                "rarity_score": 175.9918279714932,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3136.png",
            "scheme": "https"
        }
    },
    "3867": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3867",
                "description": "description",
                "dna": "6aa10db74cd4a2bebae1d27a4503291f21e59595",
                "date": 1683438464180,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3867",
                "rarity_score": 288.54314681656933,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3867.png",
            "scheme": "https"
        }
    },
    "2596": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2596",
                "description": "description",
                "dna": "7f8775a48407a3c94f063d8236bd6e09f770c1fd",
                "date": 1683443922955,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2596",
                "rarity_score": 278.84886940925315,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2596.png",
            "scheme": "https"
        }
    },
    "2234": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2234",
                "description": "description",
                "dna": "00bbb69e4c5e90d679fe9982c2fa3cd467f32600",
                "date": 1683445456298,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2234",
                "rarity_score": 211.82228865994236,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2234.png",
            "scheme": "https"
        }
    },
    "171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #171",
                "description": "description",
                "dna": "4ec2a62fd5a02be249bd8dc2b268c937529d0af2",
                "date": 1683437843011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "171",
                "rarity_score": 184.71548456112993,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/171.png",
            "scheme": "https"
        }
    },
    "3638": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3638",
                "description": "description",
                "dna": "228c7aa589f18a3232242c156cfc5c1f3bb493cc",
                "date": 1683430970930,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3638",
                "rarity_score": 147.745986659137,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3638.png",
            "scheme": "https"
        }
    },
    "2872": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2872",
                "description": "description",
                "dna": "fcef784b0fa62dd8eca30ab3936523dd9d051715",
                "date": 1683444750835,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2872",
                "rarity_score": 249.42454885395304,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2872.png",
            "scheme": "https"
        }
    },
    "3528": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3528",
                "description": "description",
                "dna": "f7a0d7b389c538b8daf57ed10121156196b22aa5",
                "date": 1683439054817,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3528",
                "rarity_score": 189.61640346184484,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3528.png",
            "scheme": "https"
        }
    },
    "1341": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1341",
                "description": "description",
                "dna": "e6ad04bf00d3a82cd5abda17aa96fa074fd5cdd2",
                "date": 1683438027410,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1341",
                "rarity_score": 154.460733650623,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1341.png",
            "scheme": "https"
        }
    },
    "926": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #926",
                "description": "description",
                "dna": "a4fbef5aa7024bd1e16bd30879c03839d6c60f9e",
                "date": 1683437133195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "926",
                "rarity_score": 187.59192626548526,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/926.png",
            "scheme": "https"
        }
    },
    "2806": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2806",
                "description": "description",
                "dna": "9e80917e8b1fccb72cb3509b60acae1642fe3c3f",
                "date": 1683434786245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2806",
                "rarity_score": 206.91668817514747,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2806.png",
            "scheme": "https"
        }
    },
    "1187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1187",
                "description": "description",
                "dna": "b1c145747bb8c728b2258795622396f3fef101eb",
                "date": 1683446549631,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1187",
                "rarity_score": 138.9018444546781,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1187.png",
            "scheme": "https"
        }
    },
    "732": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #732",
                "description": "description",
                "dna": "0c928f9c93f99d65280f93bcc7982de74307ad7b",
                "date": 1683433150266,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "732",
                "rarity_score": 202.88222833964994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/732.png",
            "scheme": "https"
        }
    },
    "889": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #889",
                "description": "description",
                "dna": "abb49f72aa17d833e4489d3bd57992467241fe07",
                "date": 1683438306646,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "889",
                "rarity_score": 218.9991083843613,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/889.png",
            "scheme": "https"
        }
    },
    "3123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3123",
                "description": "description",
                "dna": "d3d5c7a0eb75cec28cbb60337fdfd1449b5c1824",
                "date": 1683437855157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3123",
                "rarity_score": 181.21918432721964,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3123.png",
            "scheme": "https"
        }
    },
    "2158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2158",
                "description": "description",
                "dna": "e29ac935a3ddafe0c593193e9782995e45e94466",
                "date": 1683436246311,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2158",
                "rarity_score": 493.0341309696894,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2158.png",
            "scheme": "https"
        }
    },
    "3820": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3820",
                "description": "description",
                "dna": "dc5a7d5b48c3841cc4236852383836dd5403fd3f",
                "date": 1683445066525,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3820",
                "rarity_score": 184.41040225212225,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3820.png",
            "scheme": "https"
        }
    },
    "3205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3205",
                "description": "description",
                "dna": "536f5764b46eca6cad22b89d8205170b0319099c",
                "date": 1683446931309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3205",
                "rarity_score": 152.18754189714528,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3205.png",
            "scheme": "https"
        }
    },
    "2383": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2383",
                "description": "description",
                "dna": "fc3f446f6160802ed91c686dac5d280b5c660162",
                "date": 1683430520879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2383",
                "rarity_score": 179.71567100366138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2383.png",
            "scheme": "https"
        }
    },
    "2370": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2370",
                "description": "description",
                "dna": "c019de1000bbc376ad508ceee6f6e0a30bb13e1d",
                "date": 1683441772811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2370",
                "rarity_score": 198.05592659122394,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2370.png",
            "scheme": "https"
        }
    },
    "2154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2154",
                "description": "description",
                "dna": "f0242d04349ab4f3bab783cb1886cb6630ee82c4",
                "date": 1683444247042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2154",
                "rarity_score": 159.65879950484762,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2154.png",
            "scheme": "https"
        }
    },
    "1972": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1972",
                "description": "description",
                "dna": "fec477467fc38925a467e40515d7ab8228d1f503",
                "date": 1683429768848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1972",
                "rarity_score": 173.39084162392058,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1972.png",
            "scheme": "https"
        }
    },
    "2020": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2020",
                "description": "description",
                "dna": "40176083d0b83ebeb1ac3101e84aa98179119308",
                "date": 1683437458010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2020",
                "rarity_score": 123.9065287048123,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2020.png",
            "scheme": "https"
        }
    },
    "2014": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2014",
                "description": "description",
                "dna": "bd3ecfc15835aa548b8f1f7533f5f67ef388042d",
                "date": 1683435462138,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2014",
                "rarity_score": 170.3250632557353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2014.png",
            "scheme": "https"
        }
    },
    "255": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #255",
                "description": "description",
                "dna": "3de6e4be64ea088ae078b14759b38047227d3060",
                "date": 1683771450031,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "255",
                "rarity_score": 269.3225484227549,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/255.png",
            "scheme": "https"
        }
    },
    "775": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #775",
                "description": "description",
                "dna": "60d223ca61b394605b71d13f99c6bffb2923ee54",
                "date": 1683437846863,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "775",
                "rarity_score": 205.81806775502105,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/775.png",
            "scheme": "https"
        }
    },
    "1057": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1057",
                "description": "description",
                "dna": "6b4754526e13e210bc3cbf9b629f55bc6e1b47ca",
                "date": 1683446482712,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1057",
                "rarity_score": 172.0362221058609,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1057.png",
            "scheme": "https"
        }
    },
    "78": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #78",
                "description": "description",
                "dna": "b6398f3d18e1c1109fe6fcfcf7cbe319e1ed49c4",
                "date": 1683446846608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "78",
                "rarity_score": 151.64098211989628,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/78.png",
            "scheme": "https"
        }
    },
    "266": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #266",
                "description": "description",
                "dna": "faa813eaefc16b0abbe8703ccf34f7520bf9986f",
                "date": 1683442540593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "266",
                "rarity_score": 165.95991775420424,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/266.png",
            "scheme": "https"
        }
    },
    "1401": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1401",
                "description": "description",
                "dna": "64cbc392ccfcd6fcd58af4508890cb5d713b559a",
                "date": 1683441332675,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1401",
                "rarity_score": 183.2809572584544,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1401.png",
            "scheme": "https"
        }
    },
    "1720": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1720",
                "description": "description",
                "dna": "75dbf318d465151cabff523194ada45e949567d1",
                "date": 1683438078577,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1720",
                "rarity_score": 210.43631313611445,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1720.png",
            "scheme": "https"
        }
    },
    "556": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #556",
                "description": "description",
                "dna": "00502ff4cbc5295e71959ebfed28500f1b5ae5ad",
                "date": 1683446132213,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "556",
                "rarity_score": 144.09966957080596,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/556.png",
            "scheme": "https"
        }
    },
    "4069": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4069",
                "description": "description",
                "dna": "6f59be21b2f22be056715054363c6946dd4ee18a",
                "date": 1683438853221,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4069",
                "rarity_score": 208.35542156553942,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4069.png",
            "scheme": "https"
        }
    },
    "329": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #329",
                "description": "description",
                "dna": "0be6e92d7da9bd723080cb5c368e78ab5888bff2",
                "date": 1683440210931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "329",
                "rarity_score": 198.41637680328563,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/329.png",
            "scheme": "https"
        }
    },
    "1392": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1392",
                "description": "description",
                "dna": "c86c3cc89cd39d35e47755d99d3914cee3cd97d4",
                "date": 1683431133512,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1392",
                "rarity_score": 163.80549139423601,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1392.png",
            "scheme": "https"
        }
    },
    "609": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #609",
                "description": "description",
                "dna": "f339eeabdc1bee5f4db47ca11af274a87b9eb95c",
                "date": 1683771412846,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "609",
                "rarity_score": 190.81963672316434,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/609.png",
            "scheme": "https"
        }
    },
    "4100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4100",
                "description": "description",
                "dna": "df14ed041fd3623aabe41efeab1f12ee45546ed7",
                "date": 1683434946822,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "4100",
                "rarity_score": 239.2037060052425,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4100.png",
            "scheme": "https"
        }
    },
    "2788": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2788",
                "description": "description",
                "dna": "d953ac6cca67c357d21d98d59ac3b329506d1373",
                "date": 1683437498833,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2788",
                "rarity_score": 127.85951327252958,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2788.png",
            "scheme": "https"
        }
    },
    "2124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2124",
                "description": "description",
                "dna": "74af07af010aa3bd946287c2d2445fd91641c9b4",
                "date": 1683433510785,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2124",
                "rarity_score": 253.7543531636728,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2124.png",
            "scheme": "https"
        }
    },
    "3177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3177",
                "description": "description",
                "dna": "34af3d700154d0eb9af5eb8cae8a9492e8f63613",
                "date": 1683437606329,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3177",
                "rarity_score": 130.53613624184794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3177.png",
            "scheme": "https"
        }
    },
    "950": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #950",
                "description": "description",
                "dna": "42db41ce8db61929044fc7127c502e7dced065c3",
                "date": 1683774770412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "950",
                "rarity_score": 181.38728868188514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/950.png",
            "scheme": "https"
        }
    },
    "903": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #903",
                "description": "description",
                "dna": "762abed1c359339d1b97a72bd2c1d79299cd6969",
                "date": 1683441284391,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "903",
                "rarity_score": 320.7575226301053,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/903.png",
            "scheme": "https"
        }
    },
    "4024": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4024",
                "description": "description",
                "dna": "2c8550d561f5ececc1f42ad344e5b149c8d6622c",
                "date": 1683434740173,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "4024",
                "rarity_score": 223.01412567607076,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4024.png",
            "scheme": "https"
        }
    },
    "1386": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1386",
                "description": "description",
                "dna": "b5db49d7fb61c67f24cfeadb3e3264c07375b041",
                "date": 1683508652405,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1386",
                "rarity_score": 478.0895118240351,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1386.png",
            "scheme": "https"
        }
    },
    "2063": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2063",
                "description": "description",
                "dna": "2af9ccf03ec826b22dcb34d16168a58ed0fb5991",
                "date": 1683446261963,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2063",
                "rarity_score": 193.1791755719574,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2063.png",
            "scheme": "https"
        }
    },
    "882": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #882",
                "description": "description",
                "dna": "9f5cadb7534c2cb78b0bb85e7c8e4fa1082ed1e2",
                "date": 1683429989877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "882",
                "rarity_score": 201.2221696632212,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/882.png",
            "scheme": "https"
        }
    },
    "974": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #974",
                "description": "description",
                "dna": "f32777fc23b2da42f2c9a1e794c80a9645172689",
                "date": 1683444655705,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "974",
                "rarity_score": 154.36844067371595,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/974.png",
            "scheme": "https"
        }
    },
    "1482": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1482",
                "description": "description",
                "dna": "3fd4fe5a94fb482246bc6bd100a69ee03a922364",
                "date": 1683442927733,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1482",
                "rarity_score": 197.78353180083428,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1482.png",
            "scheme": "https"
        }
    },
    "3467": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3467",
                "description": "description",
                "dna": "bc6ef5e799bef1adb056bbbc9910f605369937b4",
                "date": 1683434615746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3467",
                "rarity_score": 211.72193160583322,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3467.png",
            "scheme": "https"
        }
    },
    "1975": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1975",
                "description": "description",
                "dna": "19e7b4f25b972473a8f422734567df56ce3fe8d7",
                "date": 1683445534180,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1975",
                "rarity_score": 195.16538087877095,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1975.png",
            "scheme": "https"
        }
    },
    "2798": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2798",
                "description": "description",
                "dna": "80c917f13616d42c67da52728155924b97a723bd",
                "date": 1683440134533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2798",
                "rarity_score": 166.66702559994076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2798.png",
            "scheme": "https"
        }
    },
    "4088": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4088",
                "description": "description",
                "dna": "7da3787a0f01de0ae830e6edfffba922659eb9b1",
                "date": 1683439160934,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "4088",
                "rarity_score": 183.2279013836876,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4088.png",
            "scheme": "https"
        }
    },
    "2979": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2979",
                "description": "description",
                "dna": "41805d55588374ef8f96a992dd6579fc3a62bde5",
                "date": 1683443834356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2979",
                "rarity_score": 243.81480171713648,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2979.png",
            "scheme": "https"
        }
    },
    "2727": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2727",
                "description": "description",
                "dna": "3636bf5f95c78e3c86c6ef5ea3588fa1a2b90028",
                "date": 1683429392934,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2727",
                "rarity_score": 248.02304895641998,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2727.png",
            "scheme": "https"
        }
    },
    "1915": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1915",
                "description": "description",
                "dna": "7abe07e9f651c42968c3883ca95d6bd69cd218da",
                "date": 1683433353096,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1915",
                "rarity_score": 208.9589425004406,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1915.png",
            "scheme": "https"
        }
    },
    "2247": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2247",
                "description": "description",
                "dna": "0263ecbb8680c2be32d96c7156e0c12b31f7f75a",
                "date": 1683446151474,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2247",
                "rarity_score": 198.9314786206,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2247.png",
            "scheme": "https"
        }
    },
    "3394": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3394",
                "description": "description",
                "dna": "7a815e7fc9755997a53406a3c62d535ebab4d8ce",
                "date": 1683436751705,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3394",
                "rarity_score": 197.82553433886494,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3394.png",
            "scheme": "https"
        }
    },
    "2863": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2863",
                "description": "description",
                "dna": "91377490e3f915ea9da870909b072c6e1b500f05",
                "date": 1683430428428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2863",
                "rarity_score": 295.9141895595088,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2863.png",
            "scheme": "https"
        }
    },
    "3217": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3217",
                "description": "description",
                "dna": "5f743c256f9426fb56c7c9b6e7e2183ffaf2b1cc",
                "date": 1683431230228,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3217",
                "rarity_score": 233.45661694863404,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3217.png",
            "scheme": "https"
        }
    },
    "3748": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3748",
                "description": "description",
                "dna": "4760f94e5793f5a2e8842512a9d2ca7d09c72b8d",
                "date": 1683776557643,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3748",
                "rarity_score": 185.08434319314534,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3748.png",
            "scheme": "https"
        }
    },
    "1574": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1574",
                "description": "description",
                "dna": "c1f4fb8a1b36586b2f2947c0300a2425b51d9079",
                "date": 1683431591020,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1574",
                "rarity_score": 189.49441739577168,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1574.png",
            "scheme": "https"
        }
    },
    "1504": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1504",
                "description": "description",
                "dna": "c56ddb39472d0375f4c99765017a1050b6e2fbf6",
                "date": 1683432251327,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1504",
                "rarity_score": 203.56423386982755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1504.png",
            "scheme": "https"
        }
    },
    "2675": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2675",
                "description": "description",
                "dna": "10db1519cd9144832a99c036b5b46dbaa851486b",
                "date": 1683444872339,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2675",
                "rarity_score": 286.73394921598566,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2675.png",
            "scheme": "https"
        }
    },
    "562": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #562",
                "description": "description",
                "dna": "8ba7e865415681766914dcc5711dfdcc760aa802",
                "date": 1683439969833,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "562",
                "rarity_score": 266.19579342748534,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/562.png",
            "scheme": "https"
        }
    },
    "3535": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3535",
                "description": "description",
                "dna": "d08bf7c0e088d10f3f89df157779e08c6acaa29b",
                "date": 1683433286405,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3535",
                "rarity_score": 161.3315519077311,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3535.png",
            "scheme": "https"
        }
    },
    "3334": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3334",
                "description": "description",
                "dna": "a133ce59dded390db01f7f37db46970e1082cef1",
                "date": 1683431518094,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3334",
                "rarity_score": 178.78082088277972,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3334.png",
            "scheme": "https"
        }
    },
    "332": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #332",
                "description": "description",
                "dna": "fc35b2ab4f8aec53c8c80215893b22ab47e83abb",
                "date": 1683446989673,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "332",
                "rarity_score": 208.4924439546357,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/332.png",
            "scheme": "https"
        }
    },
    "2525": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2525",
                "description": "description",
                "dna": "aa36ccb89deecfd558e9c713542977b85f7c0606",
                "date": 1683776519442,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2525",
                "rarity_score": 183.3564353674521,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2525.png",
            "scheme": "https"
        }
    },
    "3701": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3701",
                "description": "description",
                "dna": "a0c139b36aeeb96483b16af15a693fd3c0323523",
                "date": 1683442127594,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3701",
                "rarity_score": 153.18807698370338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3701.png",
            "scheme": "https"
        }
    },
    "2874": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2874",
                "description": "description",
                "dna": "fbdb93d7de64387794e2f91da80302347e34ef33",
                "date": 1683432716436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2874",
                "rarity_score": 336.90333024316175,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2874.png",
            "scheme": "https"
        }
    },
    "2692": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2692",
                "description": "description",
                "dna": "8a07009ab44dcabefe6576bade3ee1e7527212ca",
                "date": 1683774834388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2692",
                "rarity_score": 234.03925794005232,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2692.png",
            "scheme": "https"
        }
    },
    "1444": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1444",
                "description": "description",
                "dna": "1b50993e5e918d13a50488f162fcd77326a67797",
                "date": 1683436213920,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1444",
                "rarity_score": 277.0521485447804,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1444.png",
            "scheme": "https"
        }
    },
    "1663": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1663",
                "description": "description",
                "dna": "920ff41274ddbcda1acd2eb20c072ae38a4b8b81",
                "date": 1683438057808,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1663",
                "rarity_score": 191.57458025523417,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1663.png",
            "scheme": "https"
        }
    },
    "1749": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1749",
                "description": "description",
                "dna": "635ccb57f5ff5f72cbbe1ec2561c2a3106ecd629",
                "date": 1683444175923,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1749",
                "rarity_score": 244.37921457548748,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1749.png",
            "scheme": "https"
        }
    },
    "1345": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1345",
                "description": "description",
                "dna": "ae1f5d6b3ec2649b4e9db740aedc4d00c751feba",
                "date": 1683432457374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1345",
                "rarity_score": 171.9625285323267,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1345.png",
            "scheme": "https"
        }
    },
    "961": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #961",
                "description": "description",
                "dna": "84596592f57ea6c1688bb26e83b6fe3298f3f4ff",
                "date": 1683444467227,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "961",
                "rarity_score": 152.8894291690591,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/961.png",
            "scheme": "https"
        }
    },
    "402": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #402",
                "description": "description",
                "dna": "faba410adea9b771047e1a699e69224d2ada0232",
                "date": 1683445018091,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "402",
                "rarity_score": 346.91366376108124,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/402.png",
            "scheme": "https"
        }
    },
    "2799": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2799",
                "description": "description",
                "dna": "99dbb00eefa958fab8c0d0b8cc4de8d8f67e229f",
                "date": 1683440750961,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2799",
                "rarity_score": 161.10694468407024,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2799.png",
            "scheme": "https"
        }
    },
    "696": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #696",
                "description": "description",
                "dna": "9931beba99fbb0ed706499db280b5e19e832b05e",
                "date": 1683437850968,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "696",
                "rarity_score": 311.6719897701141,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/696.png",
            "scheme": "https"
        }
    },
    "1957": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1957",
                "description": "description",
                "dna": "accdfac00f6ec9a9fc5f74e0475d00f9837bea4e",
                "date": 1683430794146,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1957",
                "rarity_score": 267.3137717910707,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1957.png",
            "scheme": "https"
        }
    },
    "3370": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3370",
                "description": "description",
                "dna": "34dad95a0e518ff6eeb28238a2bea66cd5ddfc7a",
                "date": 1683446675381,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3370",
                "rarity_score": 163.24799952188755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3370.png",
            "scheme": "https"
        }
    },
    "1171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1171",
                "description": "description",
                "dna": "58cf347c254f2e8d91ac39f537f3095669c7b8f5",
                "date": 1683433141608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1171",
                "rarity_score": 161.49823089009158,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1171.png",
            "scheme": "https"
        }
    },
    "2344": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2344",
                "description": "description",
                "dna": "15d084353964aac8eefb8b255dc761641c033695",
                "date": 1683436565442,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2344",
                "rarity_score": 236.00174296252735,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2344.png",
            "scheme": "https"
        }
    },
    "849": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #849",
                "description": "description",
                "dna": "e6ee99790ce716fee6022177a19c1acfd0851c3f",
                "date": 1683430441738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "849",
                "rarity_score": 171.54597707454855,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/849.png",
            "scheme": "https"
        }
    },
    "3565": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3565",
                "description": "description",
                "dna": "a62068a9ed71886f4bf9aee792d5b875f59b90c7",
                "date": 1683442937372,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3565",
                "rarity_score": 184.54809497045343,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3565.png",
            "scheme": "https"
        }
    },
    "4065": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4065",
                "description": "description",
                "dna": "bedc4f7d5aa4cae8869032f6ed54fa9a7b5b28dc",
                "date": 1683431964406,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "4065",
                "rarity_score": 211.53130204536137,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4065.png",
            "scheme": "https"
        }
    },
    "1222": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1222",
                "description": "description",
                "dna": "1adc37bf80c843b17dae9203b3b1e2b9e8964d37",
                "date": 1683430716789,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1222",
                "rarity_score": 215.1110654870525,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1222.png",
            "scheme": "https"
        }
    },
    "2485": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2485",
                "description": "description",
                "dna": "283eb58ff1503f40879a5738a8205f48a57679c6",
                "date": 1683445719054,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2485",
                "rarity_score": 202.4414151955847,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2485.png",
            "scheme": "https"
        }
    },
    "327": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #327",
                "description": "description",
                "dna": "604f0a4ac3ab5f333e735192ce8575fc38f34ea0",
                "date": 1683438953851,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "327",
                "rarity_score": 162.64283898598688,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/327.png",
            "scheme": "https"
        }
    },
    "506": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #506",
                "description": "description",
                "dna": "3c428cf688d0ca5f6188e11dcb2bb891f3993041",
                "date": 1683431730783,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "506",
                "rarity_score": 210.13258413599954,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/506.png",
            "scheme": "https"
        }
    },
    "770": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #770",
                "description": "description",
                "dna": "882a6a8cf01d80b3eb8e85a8941a6a3fa0ecf0f7",
                "date": 1683447416345,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "770",
                "rarity_score": 171.41199956175626,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/770.png",
            "scheme": "https"
        }
    },
    "4093": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4093",
                "description": "description",
                "dna": "e8540d15532cda1d9c2ed1dbc3ed7e2edc63fba0",
                "date": 1683430803466,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "4093",
                "rarity_score": 380.76504202754415,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4093.png",
            "scheme": "https"
        }
    },
    "2653": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2653",
                "description": "description",
                "dna": "b63109654836cd3e631dd0f87f589f9245e4f5ef",
                "date": 1683443047463,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2653",
                "rarity_score": 236.0579145501089,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2653.png",
            "scheme": "https"
        }
    },
    "1897": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1897",
                "description": "description",
                "dna": "ce6b3fa4d4ec3932e9785c2ee1d82282b845b026",
                "date": 1683447223950,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1897",
                "rarity_score": 204.334804756986,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1897.png",
            "scheme": "https"
        }
    },
    "3906": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3906",
                "description": "description",
                "dna": "2301653599134c6811fb8d55ea52e300b8b4ec0f",
                "date": 1683434531124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3906",
                "rarity_score": 183.4965005144082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3906.png",
            "scheme": "https"
        }
    },
    "1398": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1398",
                "description": "description",
                "dna": "91c400d345f12bedeadbd03100e5bb78140c517b",
                "date": 1683447349728,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1398",
                "rarity_score": 200.47963288172463,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1398.png",
            "scheme": "https"
        }
    },
    "172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #172",
                "description": "description",
                "dna": "0eb1b3fdf7bcc258c36a95d00013a01d4c9fa415",
                "date": 1683430436921,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "172",
                "rarity_score": 156.83423766391755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/172.png",
            "scheme": "https"
        }
    },
    "2106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2106",
                "description": "description",
                "dna": "eddb002ba0805fd096cf3d80d9b933388f160706",
                "date": 1683440600450,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2106",
                "rarity_score": 185.26786414629345,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2106.png",
            "scheme": "https"
        }
    },
    "2681": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2681",
                "description": "description",
                "dna": "eba45643f1a52d934337c65241d31cd36c1304ef",
                "date": 1683447421176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2681",
                "rarity_score": 242.9077988710454,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2681.png",
            "scheme": "https"
        }
    },
    "1414": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1414",
                "description": "description",
                "dna": "9bd14d226cbe9f2e16e9ff4ab56c6fb65693e478",
                "date": 1683771183649,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "1414",
                "rarity_score": 395.5837784120366,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1414.png",
            "scheme": "https"
        }
    },
    "1335": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1335",
                "description": "description",
                "dna": "b95e9bab84818a75fdbe8ebc0f3348c08246e609",
                "date": 1683433096938,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1335",
                "rarity_score": 198.12022857793275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1335.png",
            "scheme": "https"
        }
    },
    "272": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #272",
                "description": "description",
                "dna": "5d6e3ba38893a2a2ee3fa9c94402786777c9fa9d",
                "date": 1683439695325,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "272",
                "rarity_score": 266.82104913597504,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/272.png",
            "scheme": "https"
        }
    },
    "2186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2186",
                "description": "description",
                "dna": "b6f88a8f965cd2a3d57d6371a39a475ee4fe8711",
                "date": 1683771311728,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2186",
                "rarity_score": 176.81409838623617,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2186.png",
            "scheme": "https"
        }
    },
    "1346": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1346",
                "description": "description",
                "dna": "a4a6ca2503d574cab2cb3a4eca769358400e0383",
                "date": 1683437417440,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1346",
                "rarity_score": 122.86170797473778,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1346.png",
            "scheme": "https"
        }
    },
    "2006": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2006",
                "description": "description",
                "dna": "9a803039960703523b126b3a52d70b22796ea5c5",
                "date": 1683444401416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2006",
                "rarity_score": 205.643823852972,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2006.png",
            "scheme": "https"
        }
    },
    "643": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #643",
                "description": "description",
                "dna": "9c127d23bf08832a870afe02bac773a6dc54919f",
                "date": 1683442781369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "643",
                "rarity_score": 163.45530124115479,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/643.png",
            "scheme": "https"
        }
    },
    "2135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2135",
                "description": "description",
                "dna": "2fce648dc6613b484e7c47d49e47078497a5e034",
                "date": 1683441781669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2135",
                "rarity_score": 157.11818903249153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2135.png",
            "scheme": "https"
        }
    },
    "622": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #622",
                "description": "description",
                "dna": "84c1e24958780544ec13826fe602c44db183da5b",
                "date": 1683447295039,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "622",
                "rarity_score": 178.2836189127048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/622.png",
            "scheme": "https"
        }
    },
    "3913": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3913",
                "description": "description",
                "dna": "d10beb7276ed48a5e0a7e2f2236f18e0391c52ff",
                "date": 1683774884034,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3913",
                "rarity_score": 205.89789735118603,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3913.png",
            "scheme": "https"
        }
    },
    "523": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #523",
                "description": "description",
                "dna": "2f309bccfc322bf005ad0dbe949c75f8bcafb64a",
                "date": 1683432295887,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "523",
                "rarity_score": 328.2242990741302,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/523.png",
            "scheme": "https"
        }
    },
    "2191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2191",
                "description": "description",
                "dna": "08bf7f704c96e6432092321873da65946396314a",
                "date": 1683440413159,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2191",
                "rarity_score": 252.87157700383716,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2191.png",
            "scheme": "https"
        }
    },
    "2324": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2324",
                "description": "description",
                "dna": "3a32ed9b805c4c61267aa30e27c4abd8d156ab9d",
                "date": 1683438069859,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2324",
                "rarity_score": 216.66935114654189,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2324.png",
            "scheme": "https"
        }
    },
    "2213": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2213",
                "description": "description",
                "dna": "deefd3aa50d99ceb3b5df0bc90be509eb0b3249f",
                "date": 1683446399837,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2213",
                "rarity_score": 157.4141862246215,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2213.png",
            "scheme": "https"
        }
    },
    "2813": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2813",
                "description": "description",
                "dna": "efd2ec849c7b8cdd24eb1b7aa07f5b1f1b218ed8",
                "date": 1683440723026,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2813",
                "rarity_score": 178.59811645335205,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2813.png",
            "scheme": "https"
        }
    },
    "3154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3154",
                "description": "description",
                "dna": "ae9e70674e21ccd9d341db712a6db616f2c90f8c",
                "date": 1683439731549,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3154",
                "rarity_score": 224.72341917626443,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3154.png",
            "scheme": "https"
        }
    },
    "109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #109",
                "description": "description",
                "dna": "46ba82efcd462fe39f63c751e8df7aba89315c25",
                "date": 1683432843675,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "109",
                "rarity_score": 271.4222385596524,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/109.png",
            "scheme": "https"
        }
    },
    "2776": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2776",
                "description": "description",
                "dna": "4f1ff1ae4aff6c7b45b0cbbb2907f58a41b346f3",
                "date": 1683775231391,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2776",
                "rarity_score": 186.75810388949188,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2776.png",
            "scheme": "https"
        }
    },
    "2664": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2664",
                "description": "description",
                "dna": "fb5752973a4560d8925074dce01287b269812118",
                "date": 1683438756735,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2664",
                "rarity_score": 180.58840071242793,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2664.png",
            "scheme": "https"
        }
    },
    "2492": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2492",
                "description": "description",
                "dna": "932d6916477728bf673556e5f237fb0c5d4c41b1",
                "date": 1683443610259,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2492",
                "rarity_score": 249.55385700567388,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2492.png",
            "scheme": "https"
        }
    },
    "3329": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3329",
                "description": "description",
                "dna": "cc1b350a342e00a3f2f7615671e6278bd924fcbf",
                "date": 1683441020729,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3329",
                "rarity_score": 191.7469608317004,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3329.png",
            "scheme": "https"
        }
    },
    "189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #189",
                "description": "description",
                "dna": "a4209abe599ca368b39b0f7426fca18b533c4597",
                "date": 1683446238418,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "189",
                "rarity_score": 190.03087378139273,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/189.png",
            "scheme": "https"
        }
    },
    "597": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #597",
                "description": "description",
                "dna": "95c765dd35031b1818ac692a943233e68de5bf6d",
                "date": 1683446893245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "597",
                "rarity_score": 232.76433906926297,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/597.png",
            "scheme": "https"
        }
    },
    "3386": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3386",
                "description": "description",
                "dna": "c947ba2f85c17efb6b3593f59ec6270728d85174",
                "date": 1683431015833,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3386",
                "rarity_score": 146.92289461587305,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3386.png",
            "scheme": "https"
        }
    },
    "3617": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3617",
                "description": "description",
                "dna": "7b77811301a00b5fa4fe7a9a7761f81a1f2856bd",
                "date": 1683432834870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3617",
                "rarity_score": 216.36653982829307,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3617.png",
            "scheme": "https"
        }
    },
    "808": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #808",
                "description": "description",
                "dna": "5ca5996cab8883433870a5e5947eab2751a64da3",
                "date": 1683443532827,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "808",
                "rarity_score": 159.70750890534362,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/808.png",
            "scheme": "https"
        }
    },
    "1964": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1964",
                "description": "description",
                "dna": "fbdb29740c94cf07490b7abf3a8dd7cd3fc00a49",
                "date": 1683445392285,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1964",
                "rarity_score": 218.2203643301048,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1964.png",
            "scheme": "https"
        }
    },
    "1180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1180",
                "description": "description",
                "dna": "fe80403f829b9bef3f06e9c458fe76b469d4ce32",
                "date": 1683443505490,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1180",
                "rarity_score": 186.4410925020441,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1180.png",
            "scheme": "https"
        }
    },
    "1529": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1529",
                "description": "description",
                "dna": "a6a452edd266527584b4850f44807ea668e6dd7f",
                "date": 1683436402707,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1529",
                "rarity_score": 226.8154511213894,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1529.png",
            "scheme": "https"
        }
    },
    "1275": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1275",
                "description": "description",
                "dna": "42a926367e460fa1f158c5d471501de0855bc59b",
                "date": 1683440882192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1275",
                "rarity_score": 193.57395824954665,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1275.png",
            "scheme": "https"
        }
    },
    "320": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #320",
                "description": "description",
                "dna": "f04d0d7b357137c8f2be2eec0354175259b90a6f",
                "date": 1683440233512,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "320",
                "rarity_score": 152.64936878644508,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/320.png",
            "scheme": "https"
        }
    },
    "606": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #606",
                "description": "description",
                "dna": "e4c553cb3e485c2d146a553871c5e8fe3d0d2a5c",
                "date": 1683444806786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "606",
                "rarity_score": 153.68065016804422,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/606.png",
            "scheme": "https"
        }
    },
    "3703": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3703",
                "description": "description",
                "dna": "13b808ed9f7e49fdb5aa2a9947157b209d85520a",
                "date": 1683433398995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3703",
                "rarity_score": 220.29047677715596,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3703.png",
            "scheme": "https"
        }
    },
    "257": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #257",
                "description": "description",
                "dna": "777d8d21e471a6d4189e5cd81745e2362979bc33",
                "date": 1683431413095,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "257",
                "rarity_score": 345.9181498035548,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/257.png",
            "scheme": "https"
        }
    },
    "3096": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3096",
                "description": "description",
                "dna": "c24b8f6beb57758f0227180b99fa6235ba4cf5de",
                "date": 1683439929137,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3096",
                "rarity_score": 183.2264860188888,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3096.png",
            "scheme": "https"
        }
    },
    "2065": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2065",
                "description": "description",
                "dna": "2056083adc8e9099565ae93280d533766ce46dca",
                "date": 1683442671060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2065",
                "rarity_score": 177.07386299188977,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2065.png",
            "scheme": "https"
        }
    },
    "2140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2140",
                "description": "description",
                "dna": "dbf37ddd408e37495ec7f301d605dfe53f7c06cc",
                "date": 1683438001610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2140",
                "rarity_score": 196.77241087294377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2140.png",
            "scheme": "https"
        }
    },
    "1869": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1869",
                "description": "description",
                "dna": "0522f111182c65d6b77c80d99df8d4884290e667",
                "date": 1683439997117,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1869",
                "rarity_score": 156.1911404323618,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1869.png",
            "scheme": "https"
        }
    },
    "4034": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4034",
                "description": "description",
                "dna": "45dd30a2a1a1c074287a1e522cabc66ff46da09b",
                "date": 1683445560253,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4034",
                "rarity_score": 207.40515867641284,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4034.png",
            "scheme": "https"
        }
    },
    "2100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2100",
                "description": "description",
                "dna": "a2c3c9d204904238beb6b74e54a3d938fd263190",
                "date": 1683437186140,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2100",
                "rarity_score": 236.49804659800384,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2100.png",
            "scheme": "https"
        }
    },
    "1421": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1421",
                "description": "description",
                "dna": "101671276fad21e158f521da4fdee784ced1f662",
                "date": 1683446281699,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1421",
                "rarity_score": 143.7768183261984,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1421.png",
            "scheme": "https"
        }
    },
    "1833": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1833",
                "description": "description",
                "dna": "bebeeb2185dcca9e3b35b9404ebf3a26952f7a86",
                "date": 1683434023201,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1833",
                "rarity_score": 218.50781266971794,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1833.png",
            "scheme": "https"
        }
    },
    "1463": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1463",
                "description": "description",
                "dna": "a3a65bd38e069677670a44a90feebeb13d1feed4",
                "date": 1683774730964,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1463",
                "rarity_score": 211.68960216482083,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1463.png",
            "scheme": "https"
        }
    },
    "279": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #279",
                "description": "description",
                "dna": "1b5f8c2cd5352744dbc3629901acbde0ac3d3789",
                "date": 1683432440178,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "279",
                "rarity_score": 152.29367315913666,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/279.png",
            "scheme": "https"
        }
    },
    "612": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #612",
                "description": "description",
                "dna": "94ef82ff97eb8b4a004ffec10c679c799519a09e",
                "date": 1683444497557,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "612",
                "rarity_score": 219.61674993923444,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/612.png",
            "scheme": "https"
        }
    },
    "2269": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2269",
                "description": "description",
                "dna": "c95960e19c46ce4feabb894e0a235fa2b549678c",
                "date": 1683438728478,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2269",
                "rarity_score": 200.7532803196608,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2269.png",
            "scheme": "https"
        }
    },
    "2508": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2508",
                "description": "description",
                "dna": "aaf1cb7e7640ecc10f33ba99a6cd4cecc9188826",
                "date": 1683440476598,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2508",
                "rarity_score": 173.54662671400388,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2508.png",
            "scheme": "https"
        }
    },
    "1713": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1713",
                "description": "description",
                "dna": "b7cd5c8bdd5118a5d5862ddf089e76ee039bde38",
                "date": 1683776481163,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1713",
                "rarity_score": 220.52864352990392,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1713.png",
            "scheme": "https"
        }
    },
    "2791": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2791",
                "description": "description",
                "dna": "723848c988a6fa2f389eb4ae8a791727670bcf9f",
                "date": 1683430379926,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2791",
                "rarity_score": 206.9610753124785,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2791.png",
            "scheme": "https"
        }
    },
    "2702": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2702",
                "description": "description",
                "dna": "239628d2c2edf7efa540f84c699c025ac743bfc8",
                "date": 1683438880807,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2702",
                "rarity_score": 203.87024010011112,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2702.png",
            "scheme": "https"
        }
    },
    "2389": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2389",
                "description": "description",
                "dna": "1fc83750882d0b274dc86cec69f1b93a976510aa",
                "date": 1683446250098,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2389",
                "rarity_score": 133.88206266181766,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2389.png",
            "scheme": "https"
        }
    },
    "178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #178",
                "description": "description",
                "dna": "a084b7d5a4576c54a48b57a2f866aed621383b77",
                "date": 1683774735755,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "178",
                "rarity_score": 147.0869506380502,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/178.png",
            "scheme": "https"
        }
    },
    "505": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #505",
                "description": "description",
                "dna": "0f7b4967dfac77c45895b2af73b16e4284978a1c",
                "date": 1683438715157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "505",
                "rarity_score": 165.84600706918334,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/505.png",
            "scheme": "https"
        }
    },
    "88": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #88",
                "description": "description",
                "dna": "237349e7f7da611a3fde550b16d19a3170c3c97d",
                "date": 1683440895758,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "88",
                "rarity_score": 209.7594665737332,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/88.png",
            "scheme": "https"
        }
    },
    "2869": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2869",
                "description": "description",
                "dna": "9028781bedf5f72fa5c76c00f9966a1db14d25ba",
                "date": 1683442146362,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2869",
                "rarity_score": 173.28010489187216,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2869.png",
            "scheme": "https"
        }
    },
    "446": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #446",
                "description": "description",
                "dna": "b7e9e6a6958699fa1ba6b8868cbcef2678cc5996",
                "date": 1683432321910,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "446",
                "rarity_score": 158.04855570053098,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/446.png",
            "scheme": "https"
        }
    },
    "2986": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2986",
                "description": "description",
                "dna": "2d9a5e62d60a9ebdb313bf53199e321f526b9790",
                "date": 1683434361951,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2986",
                "rarity_score": 228.4599120109541,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2986.png",
            "scheme": "https"
        }
    },
    "3686": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3686",
                "description": "description",
                "dna": "f8cf0160052227b39519282e88dc4c8e5c776b6d",
                "date": 1683444585572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3686",
                "rarity_score": 159.87238062612983,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3686.png",
            "scheme": "https"
        }
    },
    "3117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3117",
                "description": "description",
                "dna": "477de439017413db7c3d36576e183064595c6185",
                "date": 1683444903648,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3117",
                "rarity_score": 179.92927465519352,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3117.png",
            "scheme": "https"
        }
    },
    "3265": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3265",
                "description": "description",
                "dna": "7db70d6ce3f4ce3d50014196fb6051bbcef4f898",
                "date": 1683436345118,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3265",
                "rarity_score": 196.6274829299538,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3265.png",
            "scheme": "https"
        }
    },
    "1229": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1229",
                "description": "description",
                "dna": "dc9a872068c3ec98d9e0cb21f7f4d752aec0cfeb",
                "date": 1683437351452,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1229",
                "rarity_score": 223.50062217766668,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1229.png",
            "scheme": "https"
        }
    },
    "4081": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4081",
                "description": "description",
                "dna": "b29134424c93f44af2e68bb3d0eec73ca431193a",
                "date": 1683445375380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4081",
                "rarity_score": 172.67322942707352,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4081.png",
            "scheme": "https"
        }
    },
    "1930": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1930",
                "description": "description",
                "dna": "4633e2638896744a3f678763342975e99ca1279d",
                "date": 1683444768289,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1930",
                "rarity_score": 266.59001058026286,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1930.png",
            "scheme": "https"
        }
    },
    "1664": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1664",
                "description": "description",
                "dna": "ce6e555866b748c9e4e2d48300e3c1e1aa8f35ad",
                "date": 1683447304176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1664",
                "rarity_score": 185.79382856961757,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1664.png",
            "scheme": "https"
        }
    },
    "1624": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1624",
                "description": "description",
                "dna": "37ddc4a88373e898578216b95675ac6784b5d5d6",
                "date": 1683435679434,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1624",
                "rarity_score": 173.5930473481323,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1624.png",
            "scheme": "https"
        }
    },
    "345": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #345",
                "description": "description",
                "dna": "7350f74a53a2acd5dce91877ec09e6c6d01990b0",
                "date": 1683433145859,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "345",
                "rarity_score": 187.15881028629187,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/345.png",
            "scheme": "https"
        }
    },
    "2615": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2615",
                "description": "description",
                "dna": "a58623f74d9768aaaf221733076d9313c6a61ba1",
                "date": 1683439363058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2615",
                "rarity_score": 179.92327397292274,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2615.png",
            "scheme": "https"
        }
    },
    "3051": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3051",
                "description": "description",
                "dna": "0e78057ab5bf1fb1e4ff474a77fd178baa45b109",
                "date": 1683435850445,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3051",
                "rarity_score": 277.50584373451517,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3051.png",
            "scheme": "https"
        }
    },
    "3951": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3951",
                "description": "description",
                "dna": "31aa40a9f38c2dcd6e9ce26963cabe6c5338107c",
                "date": 1683430351676,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3951",
                "rarity_score": 198.55425882754437,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3951.png",
            "scheme": "https"
        }
    },
    "1244": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1244",
                "description": "description",
                "dna": "8a5a8953c5bdee573ef6f4eeda4c5f61176e6a3b",
                "date": 1683438215367,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1244",
                "rarity_score": 181.84887378698122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1244.png",
            "scheme": "https"
        }
    },
    "3574": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3574",
                "description": "description",
                "dna": "3b9287dbc39f89e75040cda5d04047abc1e3224b",
                "date": 1683438049102,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3574",
                "rarity_score": 325.3920343358653,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3574.png",
            "scheme": "https"
        }
    },
    "159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #159",
                "description": "description",
                "dna": "5d72620e8bf811ec5ae4d3ec13ce4c924906d439",
                "date": 1683442360019,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "159",
                "rarity_score": 231.87400471046766,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/159.png",
            "scheme": "https"
        }
    },
    "1510": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1510",
                "description": "description",
                "dna": "6e5f5ad090ecd101a2ab366d00add9de13da7397",
                "date": 1683440800374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1510",
                "rarity_score": 161.17670131897592,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1510.png",
            "scheme": "https"
        }
    },
    "1106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1106",
                "description": "description",
                "dna": "e1e1ccd3c1a06cab09d70a6e4ee951fee892c5ea",
                "date": 1683432331100,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1106",
                "rarity_score": 158.1767509153466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1106.png",
            "scheme": "https"
        }
    },
    "4027": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4027",
                "description": "description",
                "dna": "5bc70ee97a91a1adb4be193c04fad54c2504e881",
                "date": 1683444453733,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4027",
                "rarity_score": 160.26177127855198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4027.png",
            "scheme": "https"
        }
    },
    "645": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #645",
                "description": "description",
                "dna": "f79c87200662d16b3dd6154ce6786ea5a29b5c75",
                "date": 1683446901911,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "645",
                "rarity_score": 178.14793225897245,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/645.png",
            "scheme": "https"
        }
    },
    "1228": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1228",
                "description": "description",
                "dna": "84764199e2ae895b6cd29b3c4ba1b76c519cc0e5",
                "date": 1683771217264,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1228",
                "rarity_score": 183.3571250016129,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1228.png",
            "scheme": "https"
        }
    },
    "2288": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2288",
                "description": "description",
                "dna": "0a3903f78fd8dd930f7bda5d39f7bd891494f309",
                "date": 1683433407586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2288",
                "rarity_score": 159.0914357937016,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2288.png",
            "scheme": "https"
        }
    },
    "1478": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1478",
                "description": "description",
                "dna": "d592163a10b5b0af9b1adbad82b80600ddac3d82",
                "date": 1683771234687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1478",
                "rarity_score": 247.7817374315348,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1478.png",
            "scheme": "https"
        }
    },
    "291": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #291",
                "description": "description",
                "dna": "1475770f1cfa87f9219554e7c0363455b67a7327",
                "date": 1683436085002,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "291",
                "rarity_score": 212.64097467093796,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/291.png",
            "scheme": "https"
        }
    },
    "1103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1103",
                "description": "description",
                "dna": "c238b9b5fcb515ce7568400c28f0fcdc53461eef",
                "date": 1683436978580,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1103",
                "rarity_score": 164.95022558983771,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1103.png",
            "scheme": "https"
        }
    },
    "2758": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2758",
                "description": "description",
                "dna": "67d10c8ac4405e14ad6c7d0af54ab6804f6b65d3",
                "date": 1683446254122,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2758",
                "rarity_score": 121.72014717592623,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2758.png",
            "scheme": "https"
        }
    },
    "3637": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3637",
                "description": "description",
                "dna": "1307296953f32012947bfe19a68b5b89e23ff89d",
                "date": 1683437727006,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3637",
                "rarity_score": 188.09608282322264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3637.png",
            "scheme": "https"
        }
    },
    "3669": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3669",
                "description": "description",
                "dna": "dd613e5cb11d61b6725104383d08674c392fd24e",
                "date": 1683440764511,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3669",
                "rarity_score": 188.2201624593919,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3669.png",
            "scheme": "https"
        }
    },
    "1081": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1081",
                "description": "description",
                "dna": "1b3fec0d18f1fc0c2dc45e59fbda03bcfedfff5b",
                "date": 1683437678192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1081",
                "rarity_score": 263.35190775543845,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1081.png",
            "scheme": "https"
        }
    },
    "2509": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2509",
                "description": "description",
                "dna": "4bb432c930b2389673aa11c295ad47e92abc5aee",
                "date": 1683445057561,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2509",
                "rarity_score": 164.09491163918383,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2509.png",
            "scheme": "https"
        }
    },
    "2491": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2491",
                "description": "description",
                "dna": "e6a704133c21d8d287bff0dfd4eeb4223f1bca42",
                "date": 1683439658569,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2491",
                "rarity_score": 185.62397712348715,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2491.png",
            "scheme": "https"
        }
    },
    "3772": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3772",
                "description": "description",
                "dna": "f97dab176fc8115e5b6c52a2a0ecaf14ed1692fa",
                "date": 1683436774552,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3772",
                "rarity_score": 269.83106275369676,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3772.png",
            "scheme": "https"
        }
    },
    "3690": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3690",
                "description": "description",
                "dna": "a705d7d64057f8bd84e00e829019784225d0d4ba",
                "date": 1683438353714,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3690",
                "rarity_score": 212.23450313048465,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3690.png",
            "scheme": "https"
        }
    },
    "25": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #25",
                "description": "description",
                "dna": "9432a53a606a6f2d7d9c953243b3084c1e64455e",
                "date": 1683445334432,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "25",
                "rarity_score": 317.08721476309154,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/25.png",
            "scheme": "https"
        }
    },
    "3291": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3291",
                "description": "description",
                "dna": "2609005826b4c00a9ff1b556b7182ece425f1941",
                "date": 1683431115938,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3291",
                "rarity_score": 174.93466941473343,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3291.png",
            "scheme": "https"
        }
    },
    "2558": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2558",
                "description": "description",
                "dna": "d0434c9c96b9a50c9e6479258bb33e57d8c8a34d",
                "date": 1683445966101,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2558",
                "rarity_score": 136.73822696195728,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2558.png",
            "scheme": "https"
        }
    },
    "3006": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3006",
                "description": "description",
                "dna": "d1e389ac88e884e29b38a4f1b05d18685562eb12",
                "date": 1683440490610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3006",
                "rarity_score": 169.97844864493783,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3006.png",
            "scheme": "https"
        }
    },
    "2679": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2679",
                "description": "description",
                "dna": "573c16131c229b85c247e36a8581f8dc95aa3b57",
                "date": 1683438224380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2679",
                "rarity_score": 266.07411501984353,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2679.png",
            "scheme": "https"
        }
    },
    "389": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #389",
                "description": "description",
                "dna": "b6733ede7a79aab080db5d5ffa0a9c254069e772",
                "date": 1683438789073,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "389",
                "rarity_score": 162.2069525787514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/389.png",
            "scheme": "https"
        }
    },
    "779": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #779",
                "description": "description",
                "dna": "724e1fb4c034f410a14a56638329aa8fa41f3d73",
                "date": 1683433132784,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "779",
                "rarity_score": 172.4119184287848,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/779.png",
            "scheme": "https"
        }
    },
    "2835": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2835",
                "description": "description",
                "dna": "69e88f95c2649fd8fb21de817fb966da58aa2ed6",
                "date": 1683770543682,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2835",
                "rarity_score": 232.38993040051133,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2835.png",
            "scheme": "https"
        }
    },
    "786": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #786",
                "description": "description",
                "dna": "493e7acfe95f67d1dfae945c78a69c863fcb4189",
                "date": 1683431373720,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "786",
                "rarity_score": 193.71783368863066,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/786.png",
            "scheme": "https"
        }
    },
    "2848": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2848",
                "description": "description",
                "dna": "daaa6074f872833f3e034c0bb627527eb2c323fe",
                "date": 1683444348273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2848",
                "rarity_score": 249.96206564052133,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2848.png",
            "scheme": "https"
        }
    },
    "2302": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2302",
                "description": "description",
                "dna": "77e2e2e4b0b80543f99397a33664f19afc698247",
                "date": 1683432825528,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2302",
                "rarity_score": 201.15012105279828,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2302.png",
            "scheme": "https"
        }
    },
    "2541": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2541",
                "description": "description",
                "dna": "66f2c2b0623f789e6c5bdf0419d62c51a57d2902",
                "date": 1683776506493,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2541",
                "rarity_score": 258.1744313450878,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2541.png",
            "scheme": "https"
        }
    },
    "365": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #365",
                "description": "description",
                "dna": "63f306c3e19f1416daa3050f08665c67fddce929",
                "date": 1683444042424,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "365",
                "rarity_score": 191.21620711257782,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/365.png",
            "scheme": "https"
        }
    },
    "2581": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2581",
                "description": "description",
                "dna": "a41d62a3c635611d4fce76f5df7b1ee3c27cfe7e",
                "date": 1683443061505,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2581",
                "rarity_score": 219.61468132677356,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2581.png",
            "scheme": "https"
        }
    },
    "412": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #412",
                "description": "description",
                "dna": "3cd3f7ad44f1fe90eaddc2924779e1bcd2e120aa",
                "date": 1683444493296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "412",
                "rarity_score": 279.7585051639626,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/412.png",
            "scheme": "https"
        }
    },
    "995": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #995",
                "description": "description",
                "dna": "4c54d3680aaaea66c75d2fef3feee1b49cbd52e8",
                "date": 1683432461821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "995",
                "rarity_score": 150.83306588145487,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/995.png",
            "scheme": "https"
        }
    },
    "262": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #262",
                "description": "description",
                "dna": "c9eaa6f7fdbbac6832ef6f061ffd0a6a2ac74432",
                "date": 1683442407810,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "262",
                "rarity_score": 165.92525197096938,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/262.png",
            "scheme": "https"
        }
    },
    "408": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #408",
                "description": "description",
                "dna": "e59a1e647d89acd26dc464bc54f97a317e223bb9",
                "date": 1683447151177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "408",
                "rarity_score": 205.55598805120076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/408.png",
            "scheme": "https"
        }
    },
    "1417": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1417",
                "description": "description",
                "dna": "fe68271efee7550e155b22e10cf70703029f7355",
                "date": 1683431527397,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1417",
                "rarity_score": 291.3285119740893,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1417.png",
            "scheme": "https"
        }
    },
    "2348": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2348",
                "description": "description",
                "dna": "f34b3b703cf430c9c0ea3900139fb8f2e5f20c32",
                "date": 1683442173435,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2348",
                "rarity_score": 202.23749151539528,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2348.png",
            "scheme": "https"
        }
    },
    "176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #176",
                "description": "description",
                "dna": "19f6b7b0df1ce2a91063c9c1c45a4725e137a69e",
                "date": 1683443816680,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "176",
                "rarity_score": 204.48318738398203,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/176.png",
            "scheme": "https"
        }
    },
    "33": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #33",
                "description": "description",
                "dna": "cbf33e4f78549d1aaf337915ce081182fe798c38",
                "date": 1683774915209,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "33",
                "rarity_score": 199.85533052868757,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/33.png",
            "scheme": "https"
        }
    },
    "2237": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2237",
                "description": "description",
                "dna": "490e61fb858ade8da09f0d1c72b735becc50e201",
                "date": 1683435985147,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2237",
                "rarity_score": 287.998214482328,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2237.png",
            "scheme": "https"
        }
    },
    "3005": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3005",
                "description": "description",
                "dna": "c3c1ac79b11f9b18fe124d212cc08642b6743171",
                "date": 1683430673835,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3005",
                "rarity_score": 274.82482012109483,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3005.png",
            "scheme": "https"
        }
    },
    "1064": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1064",
                "description": "description",
                "dna": "882f85b17836c03b085a27615e21577aafd65a03",
                "date": 1683446776099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1064",
                "rarity_score": 177.210378984627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1064.png",
            "scheme": "https"
        }
    },
    "2545": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2545",
                "description": "description",
                "dna": "78cfa7206e7899570def70c4f73587d49c591e1f",
                "date": 1683436276677,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2545",
                "rarity_score": 278.5994170845348,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2545.png",
            "scheme": "https"
        }
    },
    "3031": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3031",
                "description": "description",
                "dna": "50d36a9502950578e479da243db7d6830f9f92e4",
                "date": 1683771370849,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3031",
                "rarity_score": 221.99101227002015,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3031.png",
            "scheme": "https"
        }
    },
    "3521": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3521",
                "description": "description",
                "dna": "11c885474c97ca91a6e0449abcf302d38614a819",
                "date": 1683432607931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3521",
                "rarity_score": 180.941347211238,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3521.png",
            "scheme": "https"
        }
    },
    "2716": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2716",
                "description": "description",
                "dna": "f647c0bcb0fecd8c7e947f7b2bd6a80e1a03db99",
                "date": 1683437885032,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2716",
                "rarity_score": 229.96403999617579,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2716.png",
            "scheme": "https"
        }
    },
    "4001": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4001",
                "description": "description",
                "dna": "5095dea5f78daae400c5b44c485ec2a98b8803a7",
                "date": 1683436938254,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "4001",
                "rarity_score": 290.7231064108176,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4001.png",
            "scheme": "https"
        }
    },
    "837": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #837",
                "description": "description",
                "dna": "5afbcc30a6f787c0bc2b8a69079a1976dad0ca37",
                "date": 1683429446107,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "837",
                "rarity_score": 172.93295488526957,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/837.png",
            "scheme": "https"
        }
    },
    "3834": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3834",
                "description": "description",
                "dna": "37d79611d564ade3ba941fdbfa7a81f523c755c2",
                "date": 1683433092082,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3834",
                "rarity_score": 174.75997060516747,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3834.png",
            "scheme": "https"
        }
    },
    "3876": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3876",
                "description": "description",
                "dna": "4db48ddb7544e8a82252f3da792f28eb8372664c",
                "date": 1683430975597,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3876",
                "rarity_score": 247.1785969715345,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3876.png",
            "scheme": "https"
        }
    },
    "3766": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3766",
                "description": "description",
                "dna": "40842fbb8c2799369423500b3965f90146dddf5a",
                "date": 1683440645510,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3766",
                "rarity_score": 308.0486675971718,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3766.png",
            "scheme": "https"
        }
    },
    "1867": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1867",
                "description": "description",
                "dna": "5ca45aa1394a9beaec371c50fa5c59754b089578",
                "date": 1683433217441,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1867",
                "rarity_score": 187.47241535892124,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1867.png",
            "scheme": "https"
        }
    },
    "2407": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2407",
                "description": "description",
                "dna": "aa5bf43b56b8d8a126820025849cfa1a88763a99",
                "date": 1683431147114,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "2407",
                "rarity_score": 356.52329634442873,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2407.png",
            "scheme": "https"
        }
    },
    "316": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #316",
                "description": "description",
                "dna": "7a95eaffca7dc8a47497291c60cc6899b6da847f",
                "date": 1683436298541,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "316",
                "rarity_score": 204.0071379047012,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/316.png",
            "scheme": "https"
        }
    },
    "74": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #74",
                "description": "description",
                "dna": "792dc1b74e0c83453cb7e7d329bca193f08e81e5",
                "date": 1683435293011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "74",
                "rarity_score": 172.66604197421051,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/74.png",
            "scheme": "https"
        }
    },
    "1770": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1770",
                "description": "description",
                "dna": "0a871fcf459f2c9a5b8134eec2db95e24fb6d26e",
                "date": 1683441727423,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1770",
                "rarity_score": 151.9738503699866,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1770.png",
            "scheme": "https"
        }
    },
    "1136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1136",
                "description": "description",
                "dna": "99893bd455a41570a8d0e0a0d38db3acbb116461",
                "date": 1683446356210,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1136",
                "rarity_score": 165.1112896627979,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1136.png",
            "scheme": "https"
        }
    },
    "3220": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3220",
                "description": "description",
                "dna": "e199a38f902cd61354b7dc47853a8fee3be3d6fc",
                "date": 1683436035555,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3220",
                "rarity_score": 233.57919854617805,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3220.png",
            "scheme": "https"
        }
    },
    "2035": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2035",
                "description": "description",
                "dna": "204164f7dd13fe692c44d537f2a56efaafa3cae1",
                "date": 1683436643975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2035",
                "rarity_score": 246.02219306592357,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2035.png",
            "scheme": "https"
        }
    },
    "2521": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2521",
                "description": "description",
                "dna": "8eaeb67ce88d04d65141ff9be17d1fcef13eb83b",
                "date": 1683445163307,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2521",
                "rarity_score": 172.30014220219354,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2521.png",
            "scheme": "https"
        }
    },
    "682": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #682",
                "description": "description",
                "dna": "722933c7ea0104beccb0952ddbf8e1f91cafdf66",
                "date": 1683771111494,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "682",
                "rarity_score": 172.24177932866644,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/682.png",
            "scheme": "https"
        }
    },
    "987": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #987",
                "description": "description",
                "dna": "25a231f30f3e850525413ff8dc16274e6550180e",
                "date": 1683430920260,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "987",
                "rarity_score": 219.88265891118027,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/987.png",
            "scheme": "https"
        }
    },
    "670": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #670",
                "description": "description",
                "dna": "97b406e972047be0d1c5937bd78328437a721180",
                "date": 1683433303472,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "670",
                "rarity_score": 245.9755177055946,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/670.png",
            "scheme": "https"
        }
    },
    "2400": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2400",
                "description": "description",
                "dna": "29c3d2173674a9700b272d39fb39b14aa0919b96",
                "date": 1683431950282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2400",
                "rarity_score": 167.42051410457267,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2400.png",
            "scheme": "https"
        }
    },
    "2369": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2369",
                "description": "description",
                "dna": "d24439d872fdfbd7d8a6522f9bdf1d5ecb3fff44",
                "date": 1683432177470,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2369",
                "rarity_score": 253.15188347302166,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2369.png",
            "scheme": "https"
        }
    },
    "2098": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2098",
                "description": "description",
                "dna": "5dd1857782a56adf93a580ad15423e532adb3c55",
                "date": 1683442637773,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2098",
                "rarity_score": 259.59339857952654,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2098.png",
            "scheme": "https"
        }
    },
    "769": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #769",
                "description": "description",
                "dna": "6f8b7ce5e8204d2bf1586659bff1b2d4b41c4981",
                "date": 1683441750208,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "769",
                "rarity_score": 182.22862962974142,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/769.png",
            "scheme": "https"
        }
    },
    "72": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #72",
                "description": "description",
                "dna": "b0f9ee8ecafe470711be0952d8ad24ba89d68d6a",
                "date": 1683517592360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Grey Fist",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "72",
                "rarity_score": 4245.102566062972,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/72.png",
            "scheme": "https"
        }
    },
    "2037": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2037",
                "description": "description",
                "dna": "d17180e144e7e9a30175e4e0da2c2d6ed949cf6b",
                "date": 1683439792655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2037",
                "rarity_score": 256.4809065611609,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2037.png",
            "scheme": "https"
        }
    },
    "2621": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2621",
                "description": "description",
                "dna": "41ac5b3ac824c6e4c4b022170a176ffc3baa1602",
                "date": 1683774847608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2621",
                "rarity_score": 138.3583518461085,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2621.png",
            "scheme": "https"
        }
    },
    "2917": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2917",
                "description": "description",
                "dna": "10b5caa218b1f29e18cb5b0536c82a719e970d07",
                "date": 1683430966368,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2917",
                "rarity_score": 221.90765355000073,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2917.png",
            "scheme": "https"
        }
    },
    "410": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #410",
                "description": "description",
                "dna": "284671af71442e4bbc47c588c2e9115e5be02ab4",
                "date": 1683445666015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "410",
                "rarity_score": 249.8490786177644,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/410.png",
            "scheme": "https"
        }
    },
    "2316": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2316",
                "description": "description",
                "dna": "b595f35c93e13d1ac38c412b956431ea8e75b862",
                "date": 1683434507340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2316",
                "rarity_score": 192.37325404226894,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2316.png",
            "scheme": "https"
        }
    },
    "67": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #67",
                "description": "description",
                "dna": "79f4057dab24e620f7cb8499ec1978aa97c3a43f",
                "date": 1683441723099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "67",
                "rarity_score": 239.5878882768846,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/67.png",
            "scheme": "https"
        }
    },
    "1453": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1453",
                "description": "description",
                "dna": "b7fe088af8154b3ada72f4314a45f55988325bb2",
                "date": 1683434471060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1453",
                "rarity_score": 402.32355108279876,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1453.png",
            "scheme": "https"
        }
    },
    "1194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1194",
                "description": "description",
                "dna": "e36def689ff5174b922116078c63be07804807c5",
                "date": 1683441341772,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1194",
                "rarity_score": 182.41214099939307,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1194.png",
            "scheme": "https"
        }
    },
    "3194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3194",
                "description": "description",
                "dna": "d42e8b0a46f8636aa7af310fe4813322d43eda2f",
                "date": 1683432078641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3194",
                "rarity_score": 197.66986098805722,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3194.png",
            "scheme": "https"
        }
    },
    "1817": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1817",
                "description": "description",
                "dna": "196e4a3f1b42c8aba1bdc2ce7603c0c9e8c39fc2",
                "date": 1683442118601,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1817",
                "rarity_score": 155.19963105486212,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1817.png",
            "scheme": "https"
        }
    },
    "3983": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3983",
                "description": "description",
                "dna": "da91412643a1899a984d40fe683bbb5e658b7af0",
                "date": 1683447090715,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3983",
                "rarity_score": 201.54583763445393,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3983.png",
            "scheme": "https"
        }
    },
    "3451": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3451",
                "description": "description",
                "dna": "fedf179fcfa8ba68ebc1a03a101d7d049c69b975",
                "date": 1683441080374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3451",
                "rarity_score": 207.85817790954792,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3451.png",
            "scheme": "https"
        }
    },
    "422": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #422",
                "description": "description",
                "dna": "d88bf424f918e439c0da2082caabf13a0cd617ea",
                "date": 1683432309324,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "422",
                "rarity_score": 227.09509997791935,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/422.png",
            "scheme": "https"
        }
    },
    "1361": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1361",
                "description": "description",
                "dna": "a16e13210e4fa974f2487cb652bb3098bccb7b10",
                "date": 1683438265221,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "1361",
                "rarity_score": 338.1601018531989,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1361.png",
            "scheme": "https"
        }
    },
    "2500": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2500",
                "description": "description",
                "dna": "2f632bc663c204e8c459f4308dae2946428065b0",
                "date": 1683437935927,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2500",
                "rarity_score": 187.18894610219772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2500.png",
            "scheme": "https"
        }
    },
    "2569": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2569",
                "description": "description",
                "dna": "7b573481003a6466bb0e2f57165f3ea3a74d400d",
                "date": 1683438455164,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2569",
                "rarity_score": 174.7185876504268,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2569.png",
            "scheme": "https"
        }
    },
    "341": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #341",
                "description": "description",
                "dna": "aa03b1d1f25f1340d3303899b1ebcfd6d1f67089",
                "date": 1683432247235,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "341",
                "rarity_score": 238.67261122567913,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/341.png",
            "scheme": "https"
        }
    },
    "1883": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1883",
                "description": "description",
                "dna": "0ccc803acfff27e908437beebfb525a4aa830751",
                "date": 1683433506346,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1883",
                "rarity_score": 181.52155430117017,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1883.png",
            "scheme": "https"
        }
    },
    "2038": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2038",
                "description": "description",
                "dna": "4755f758ea959d86b6754416bfb1af3d3e7b42c2",
                "date": 1683440827156,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2038",
                "rarity_score": 159.83094519955682,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2038.png",
            "scheme": "https"
        }
    },
    "2796": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2796",
                "description": "description",
                "dna": "7b2c152c0150028443ea2e0f9988816aa71f7022",
                "date": 1683443353351,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2796",
                "rarity_score": 173.9777494745817,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2796.png",
            "scheme": "https"
        }
    },
    "3651": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3651",
                "description": "description",
                "dna": "5b3b968b354f013b2cc78da2dcc8addcec5ed993",
                "date": 1683431569311,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3651",
                "rarity_score": 173.3980278082741,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3651.png",
            "scheme": "https"
        }
    },
    "2614": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2614",
                "description": "description",
                "dna": "f91046b3280796dbdc8397371826bc2aa687a90b",
                "date": 1683438023223,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2614",
                "rarity_score": 171.33271493442444,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2614.png",
            "scheme": "https"
        }
    },
    "3907": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3907",
                "description": "description",
                "dna": "767e6647fc6eb59ade4ed91ad177d4b446d35c70",
                "date": 1683431923531,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3907",
                "rarity_score": 183.60624331087,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3907.png",
            "scheme": "https"
        }
    },
    "2494": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2494",
                "description": "description",
                "dna": "3318b5cd0590f9b749a908d6f739b820abe39bd5",
                "date": 1683442601457,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2494",
                "rarity_score": 213.97692824042753,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2494.png",
            "scheme": "https"
        }
    },
    "3575": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3575",
                "description": "description",
                "dna": "fbb5d9f9f68e7fecb54b0fd8059dd58b55f3ca8b",
                "date": 1683429786259,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3575",
                "rarity_score": 252.20643750838286,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3575.png",
            "scheme": "https"
        }
    },
    "1093": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1093",
                "description": "description",
                "dna": "fb43f3f54c43f21933b0ff956302329c856c17d6",
                "date": 1683444081670,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1093",
                "rarity_score": 264.2888273273056,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1093.png",
            "scheme": "https"
        }
    },
    "3640": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3640",
                "description": "description",
                "dna": "fc5c67cdafad45dd5ac1ae778672f014ddecef86",
                "date": 1683440376856,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3640",
                "rarity_score": 160.48406404301411,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3640.png",
            "scheme": "https"
        }
    },
    "3128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3128",
                "description": "description",
                "dna": "5acf6574ff339fdaa5dc58024d3be9e2c82730c0",
                "date": 1683437893394,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3128",
                "rarity_score": 260.61514757565044,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3128.png",
            "scheme": "https"
        }
    },
    "1690": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1690",
                "description": "description",
                "dna": "6ec5ba09fc4a7e907b0c1efd4cdcb4db29b13543",
                "date": 1683436116932,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1690",
                "rarity_score": 212.7977057363856,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1690.png",
            "scheme": "https"
        }
    },
    "3628": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3628",
                "description": "description",
                "dna": "d7530af1f52f0fd3b886ebf9f83155eb910a6a89",
                "date": 1683442817229,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3628",
                "rarity_score": 175.90427608715405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3628.png",
            "scheme": "https"
        }
    },
    "593": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #593",
                "description": "description",
                "dna": "ba90a3741bec63898c80678d2e0b8f898d1c5b55",
                "date": 1683446277590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "593",
                "rarity_score": 116.83880231878798,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/593.png",
            "scheme": "https"
        }
    },
    "3902": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3902",
                "description": "description",
                "dna": "f30d67f02d3b04dadf8a0556e863355aa05b6e0d",
                "date": 1683438316364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "3902",
                "rarity_score": 302.08334412637794,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3902.png",
            "scheme": "https"
        }
    },
    "482": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #482",
                "description": "description",
                "dna": "d39fc0adc996e67ef15a9175224c27037927f67e",
                "date": 1683447143291,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "482",
                "rarity_score": 237.72110346296438,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/482.png",
            "scheme": "https"
        }
    },
    "238": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #238",
                "description": "description",
                "dna": "4b9f80ccb5cc99f54dbf68ceda68e598a5e0446b",
                "date": 1683431599521,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "238",
                "rarity_score": 204.4883537177837,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/238.png",
            "scheme": "https"
        }
    },
    "1612": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1612",
                "description": "description",
                "dna": "b13886ee1d845c61a084a8709fa421e1723be3e1",
                "date": 1683437364138,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1612",
                "rarity_score": 115.19847022748112,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1612.png",
            "scheme": "https"
        }
    },
    "4070": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4070",
                "description": "description",
                "dna": "b82e0f88981f1ba29ba953d66d4b945d2995a90d",
                "date": 1683431349660,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4070",
                "rarity_score": 177.45937717092934,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4070.png",
            "scheme": "https"
        }
    },
    "352": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #352",
                "description": "description",
                "dna": "f08eaaaf0c3acfb90b100e4bdcc27f0fc19daa6b",
                "date": 1683430570906,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "352",
                "rarity_score": 242.6876351930098,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/352.png",
            "scheme": "https"
        }
    },
    "2832": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2832",
                "description": "description",
                "dna": "cfc3bdc528dd396656cbe67d716ef4bd9be7ee27",
                "date": 1683443523794,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2832",
                "rarity_score": 155.4166145696706,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2832.png",
            "scheme": "https"
        }
    },
    "504": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #504",
                "description": "description",
                "dna": "d263c1611ed526131e4023cae34abaafb9e4374b",
                "date": 1683434772344,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "504",
                "rarity_score": 148.5598883428784,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/504.png",
            "scheme": "https"
        }
    },
    "1774": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1774",
                "description": "description",
                "dna": "b2626ae4fa135f592ce049eec6aa1d7b334bab35",
                "date": 1683441926739,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1774",
                "rarity_score": 224.33099354993251,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1774.png",
            "scheme": "https"
        }
    },
    "359": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #359",
                "description": "description",
                "dna": "73586ffe60e8efde8b58a2876dccc378a5d42eed",
                "date": 1683445590434,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "359",
                "rarity_score": 207.3730476370762,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/359.png",
            "scheme": "https"
        }
    },
    "3957": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3957",
                "description": "description",
                "dna": "54c2b536d5d5d67e24d5cfae7447ada232e6ebf3",
                "date": 1683774551316,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3957",
                "rarity_score": 170.36191683874492,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3957.png",
            "scheme": "https"
        }
    },
    "3689": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3689",
                "description": "description",
                "dna": "9ab7f0df936623c0865150607e27be290b517a27",
                "date": 1683439538581,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3689",
                "rarity_score": 151.04597896781385,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3689.png",
            "scheme": "https"
        }
    },
    "1308": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1308",
                "description": "description",
                "dna": "a0c165a46da39bbf3529bd0d4f8f4e5565390ce9",
                "date": 1683437297870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1308",
                "rarity_score": 219.40770991688152,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1308.png",
            "scheme": "https"
        }
    },
    "2876": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2876",
                "description": "description",
                "dna": "1bc12f32f2b9a92e72a0ae76691a7303f252bae7",
                "date": 1683435823072,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2876",
                "rarity_score": 147.05429631877948,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2876.png",
            "scheme": "https"
        }
    },
    "173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #173",
                "description": "description",
                "dna": "7669e0c7c9031dc8c1716c9e3cb81a30336b9485",
                "date": 1683432790343,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "173",
                "rarity_score": 187.52641365392515,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/173.png",
            "scheme": "https"
        }
    },
    "1446": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1446",
                "description": "description",
                "dna": "0e6d3e5a34279fe3ed408ffe78fa69aafbdb18bd",
                "date": 1683443908801,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1446",
                "rarity_score": 182.63062516218014,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1446.png",
            "scheme": "https"
        }
    },
    "3452": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3452",
                "description": "description",
                "dna": "d33d164212f2e269cfa9316b30e30a1a715017fe",
                "date": 1683436606541,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3452",
                "rarity_score": 201.65635281839008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3452.png",
            "scheme": "https"
        }
    },
    "2351": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2351",
                "description": "description",
                "dna": "a9eca4adfe93aed78463468f4998e5995d02600c",
                "date": 1683771150118,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2351",
                "rarity_score": 190.81767929654833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2351.png",
            "scheme": "https"
        }
    },
    "756": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #756",
                "description": "description",
                "dna": "58894c03230406dd4b016c74ffda823521e71927",
                "date": 1683437696156,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "756",
                "rarity_score": 257.8554802810322,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/756.png",
            "scheme": "https"
        }
    },
    "3811": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3811",
                "description": "description",
                "dna": "f31a31b81d4c1362b752ecd1fb7b5960fb49e48e",
                "date": 1683776434603,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3811",
                "rarity_score": 263.97271225221175,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3811.png",
            "scheme": "https"
        }
    },
    "1633": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1633",
                "description": "description",
                "dna": "f1df6a2d0ed696f84ccd9d56188c2eb939bbfa8b",
                "date": 1683441557090,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1633",
                "rarity_score": 172.61118004598876,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1633.png",
            "scheme": "https"
        }
    },
    "3079": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3079",
                "description": "description",
                "dna": "2596b30adee94b8c110deaa7479c208973a75eca",
                "date": 1683437876201,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3079",
                "rarity_score": 157.31301276182407,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3079.png",
            "scheme": "https"
        }
    },
    "563": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #563",
                "description": "description",
                "dna": "e2c8af07a47f48e05728c5c375a8a8e35500f208",
                "date": 1683774574291,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "563",
                "rarity_score": 208.41667621506048,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/563.png",
            "scheme": "https"
        }
    },
    "2942": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2942",
                "description": "description",
                "dna": "3d3b7898a458859bf79054451cc52d2c72ddbb4b",
                "date": 1683430539235,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2942",
                "rarity_score": 194.10936420515552,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2942.png",
            "scheme": "https"
        }
    },
    "3671": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3671",
                "description": "description",
                "dna": "1d45cc78c672c72df0985558b930aef4d3a5ec3f",
                "date": 1683439596244,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3671",
                "rarity_score": 160.8978890455802,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3671.png",
            "scheme": "https"
        }
    },
    "3590": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3590",
                "description": "description",
                "dna": "3f21fe4dc9ff4f2638487ab9cdbd3b1d0a779b18",
                "date": 1683435536240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3590",
                "rarity_score": 152.54847420377823,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3590.png",
            "scheme": "https"
        }
    },
    "3890": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3890",
                "description": "description",
                "dna": "73fcfd78d780e6c45e603990bea9aec689020d3f",
                "date": 1683444260189,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3890",
                "rarity_score": 211.21384936034613,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3890.png",
            "scheme": "https"
        }
    },
    "771": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #771",
                "description": "description",
                "dna": "4d1094b714c90a8a06693da6f7cb0ecffa41f18e",
                "date": 1683431065617,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "771",
                "rarity_score": 215.76397111038406,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/771.png",
            "scheme": "https"
        }
    },
    "465": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #465",
                "description": "description",
                "dna": "458162e0e2e17cb1f7f3b49579ad17b489f8a9fe",
                "date": 1683435373577,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "465",
                "rarity_score": 177.60986296189338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/465.png",
            "scheme": "https"
        }
    },
    "2039": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2039",
                "description": "description",
                "dna": "63e75f1d76640973c054b12ea3454d099cfb9745",
                "date": 1683432893604,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2039",
                "rarity_score": 277.8736092332098,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2039.png",
            "scheme": "https"
        }
    },
    "2812": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2812",
                "description": "description",
                "dna": "d4494e0cc1dad2271d18d68a9d7e3b5274e854ee",
                "date": 1683442032478,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2812",
                "rarity_score": 176.623550873921,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2812.png",
            "scheme": "https"
        }
    },
    "1113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1113",
                "description": "description",
                "dna": "1dc3595d5e9002aaa5147d0a408c77910f1a0fa2",
                "date": 1683433966416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1113",
                "rarity_score": 224.92991559227795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1113.png",
            "scheme": "https"
        }
    },
    "2226": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2226",
                "description": "description",
                "dna": "cc510d19231915419afe66f448bd4f4cd084de7d",
                "date": 1683444029698,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2226",
                "rarity_score": 171.08503630776187,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2226.png",
            "scheme": "https"
        }
    },
    "3479": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3479",
                "description": "description",
                "dna": "3b816c58c3ef3f990884d6cccca833ffc261a8c9",
                "date": 1683776702705,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3479",
                "rarity_score": 215.43775907399302,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3479.png",
            "scheme": "https"
        }
    },
    "2227": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2227",
                "description": "description",
                "dna": "3780def55197d49f9ff371715b8727a1989f859b",
                "date": 1683440945339,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2227",
                "rarity_score": 191.37796759420857,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2227.png",
            "scheme": "https"
        }
    },
    "3826": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3826",
                "description": "description",
                "dna": "926f97ced0081e59dbac30c14af9825eae17a561",
                "date": 1683447354001,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3826",
                "rarity_score": 226.31723160625663,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3826.png",
            "scheme": "https"
        }
    },
    "517": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #517",
                "description": "description",
                "dna": "dfc83502f6d97964979fba90b10c7dc92301136b",
                "date": 1683434050560,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "517",
                "rarity_score": 213.73452988137427,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/517.png",
            "scheme": "https"
        }
    },
    "1185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1185",
                "description": "description",
                "dna": "9ac7ae8a5c1091c1151ebe4dcb8610b414cfec6f",
                "date": 1683431101760,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1185",
                "rarity_score": 269.6390616112113,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1185.png",
            "scheme": "https"
        }
    },
    "3613": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3613",
                "description": "description",
                "dna": "7c4634a28d7162cae28e13d268dcb84157091797",
                "date": 1683776392001,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3613",
                "rarity_score": 187.66265767323858,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3613.png",
            "scheme": "https"
        }
    },
    "233": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #233",
                "description": "description",
                "dna": "e42c5ace1142f8ec613af0900fc48d146251adf7",
                "date": 1683445900474,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "233",
                "rarity_score": 131.31231879484446,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/233.png",
            "scheme": "https"
        }
    },
    "470": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #470",
                "description": "description",
                "dna": "83bb843d61685214e03e63b095c8a01e96d1539a",
                "date": 1683775184122,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "470",
                "rarity_score": 218.1104716890773,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/470.png",
            "scheme": "https"
        }
    },
    "519": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #519",
                "description": "description",
                "dna": "a0e9c8d38c7672993b1cc80363921737be7e0af2",
                "date": 1683436688330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "519",
                "rarity_score": 233.2369439378753,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/519.png",
            "scheme": "https"
        }
    },
    "2922": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2922",
                "description": "description",
                "dna": "ea8f222eeb801740cd247f8e520a2ca13b3c8697",
                "date": 1683435087895,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2922",
                "rarity_score": 188.17845187223904,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2922.png",
            "scheme": "https"
        }
    },
    "2157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2157",
                "description": "description",
                "dna": "9a9f0b9615499e70ac7b95e51394017bcba3bbdb",
                "date": 1683430516619,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2157",
                "rarity_score": 199.10025626140944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2157.png",
            "scheme": "https"
        }
    },
    "1832": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1832",
                "description": "description",
                "dna": "d5e89c83f520b731ce9fc3726b6dc37a9108c10b",
                "date": 1683432753236,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1832",
                "rarity_score": 197.28968476953867,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1832.png",
            "scheme": "https"
        }
    },
    "1206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1206",
                "description": "description",
                "dna": "7a32eaf3f1fe0c4e3c7db2195256037f99054c4d",
                "date": 1683439823423,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1206",
                "rarity_score": 262.9172501786541,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1206.png",
            "scheme": "https"
        }
    },
    "3228": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3228",
                "description": "description",
                "dna": "458ca3f8c075ee40f9a54761b1c1e5b8b848c01b",
                "date": 1683429668991,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3228",
                "rarity_score": 262.7570024426616,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3228.png",
            "scheme": "https"
        }
    },
    "3116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3116",
                "description": "description",
                "dna": "0f76f2c05d914eb8dad50ba870bbbd749d6b6f40",
                "date": 1683438627330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3116",
                "rarity_score": 235.65058138129595,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3116.png",
            "scheme": "https"
        }
    },
    "2947": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2947",
                "description": "description",
                "dna": "f0e648f68132ded23cdc89a4ca45f5c2ccdd63b8",
                "date": 1683438540571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2947",
                "rarity_score": 236.94706249210725,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2947.png",
            "scheme": "https"
        }
    },
    "3910": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3910",
                "description": "description",
                "dna": "835adcd11c2662a897c811dddb31b52dd6b24cd2",
                "date": 1683435259382,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3910",
                "rarity_score": 272.95008637385456,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3910.png",
            "scheme": "https"
        }
    },
    "2143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2143",
                "description": "description",
                "dna": "5ee5c01409e32c7632812fe70174aaa6c206ee82",
                "date": 1683776616691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2143",
                "rarity_score": 184.60401514101332,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2143.png",
            "scheme": "https"
        }
    },
    "2881": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2881",
                "description": "description",
                "dna": "78aff0fdbdf4727a1e3bfc6db828618fdd239360",
                "date": 1683437425505,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2881",
                "rarity_score": 168.20667804313936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2881.png",
            "scheme": "https"
        }
    },
    "3283": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3283",
                "description": "description",
                "dna": "4deeada2b7651b36b03174b4a72dd2e2cf468a34",
                "date": 1683776641679,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3283",
                "rarity_score": 182.14365037210464,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3283.png",
            "scheme": "https"
        }
    },
    "2749": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2749",
                "description": "description",
                "dna": "d1b3ddf3ac1535f4b5b898b24145bf0d3683b817",
                "date": 1683432612358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2749",
                "rarity_score": 272.6885500686736,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2749.png",
            "scheme": "https"
        }
    },
    "2417": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2417",
                "description": "description",
                "dna": "33d6b1e18629f5d4da650ec44f4ac6923690ff82",
                "date": 1683437380327,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2417",
                "rarity_score": 141.00935477914857,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2417.png",
            "scheme": "https"
        }
    },
    "1174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1174",
                "description": "description",
                "dna": "d934c86f372187f711f8cdaf336797ff7d93a58a",
                "date": 1683431207116,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1174",
                "rarity_score": 168.22491160283036,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1174.png",
            "scheme": "https"
        }
    },
    "1459": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1459",
                "description": "description",
                "dna": "c134b182651ab5f7dbef9a84e38bd03cd98ecae3",
                "date": 1683432694226,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1459",
                "rarity_score": 205.64604348330653,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1459.png",
            "scheme": "https"
        }
    },
    "2655": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2655",
                "description": "description",
                "dna": "e51e0225d65edd90c80f33f89af7627e60e50a0e",
                "date": 1683432237807,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2655",
                "rarity_score": 229.50979198929022,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2655.png",
            "scheme": "https"
        }
    },
    "2475": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2475",
                "description": "description",
                "dna": "a623bba603287c6ad0749564f6613fd0899d4fe6",
                "date": 1683431331117,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2475",
                "rarity_score": 213.68175664787776,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2475.png",
            "scheme": "https"
        }
    },
    "44": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #44",
                "description": "description",
                "dna": "45eeca1fe9bbac79b20af483a387af75fa0f22aa",
                "date": 1683776417125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "44",
                "rarity_score": 236.19368923506235,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/44.png",
            "scheme": "https"
        }
    },
    "469": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #469",
                "description": "description",
                "dna": "3c1f882392a1792ee3723bbcdbcdd16c5d75d26c",
                "date": 1683431097470,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "469",
                "rarity_score": 196.86166123533175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/469.png",
            "scheme": "https"
        }
    },
    "1928": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1928",
                "description": "description",
                "dna": "c90ea85af8830a4acf0f06225107457a08622319",
                "date": 1683776494063,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1928",
                "rarity_score": 192.72885246261833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1928.png",
            "scheme": "https"
        }
    },
    "2199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2199",
                "description": "description",
                "dna": "3d73fe9384d4d40747c9f21826b9259aa8cfe513",
                "date": 1683776379240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2199",
                "rarity_score": 370.96705200356695,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2199.png",
            "scheme": "https"
        }
    },
    "538": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #538",
                "description": "description",
                "dna": "2b2acbcafff66c5090bf942c1aa7f61c12e3a80e",
                "date": 1683443976596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "538",
                "rarity_score": 206.5278205684437,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/538.png",
            "scheme": "https"
        }
    },
    "62": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #62",
                "description": "description",
                "dna": "3259866714fa654d09899f66f3e3542ca10e340f",
                "date": 1683774637533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "62",
                "rarity_score": 187.40174248742423,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/62.png",
            "scheme": "https"
        }
    },
    "551": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #551",
                "description": "description",
                "dna": "6251aba5411650558ba101daf7e77e44441496a1",
                "date": 1683431261023,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "551",
                "rarity_score": 187.88513412300165,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/551.png",
            "scheme": "https"
        }
    },
    "3519": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3519",
                "description": "description",
                "dna": "82676aa1801dd5d1a4dd94ec12680c85734074be",
                "date": 1683439560759,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3519",
                "rarity_score": 190.0252616758416,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3519.png",
            "scheme": "https"
        }
    },
    "3841": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3841",
                "description": "description",
                "dna": "08122a77c8cad2558804c150acd5f2c6036e4bac",
                "date": 1683770623133,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3841",
                "rarity_score": 275.5081953361738,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3841.png",
            "scheme": "https"
        }
    },
    "198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #198",
                "description": "description",
                "dna": "5bc4ce15900f9002b425401311a16ff8aeceef29",
                "date": 1683437490465,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "198",
                "rarity_score": 144.6475257807945,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/198.png",
            "scheme": "https"
        }
    },
    "1722": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1722",
                "description": "description",
                "dna": "16f1074f8d53565381811c66ef482b5b694974dd",
                "date": 1683436905940,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1722",
                "rarity_score": 272.2640040765926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1722.png",
            "scheme": "https"
        }
    },
    "2707": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2707",
                "description": "description",
                "dna": "3d1fde9ceb7e514aa8349d83d22fc2b5e144f827",
                "date": 1683437889283,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2707",
                "rarity_score": 206.5661258019319,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2707.png",
            "scheme": "https"
        }
    },
    "3270": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3270",
                "description": "description",
                "dna": "04149159fa3a6418a2966e225be7aca0956e57a6",
                "date": 1683774682481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3270",
                "rarity_score": 298.8921882768915,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3270.png",
            "scheme": "https"
        }
    },
    "561": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #561",
                "description": "description",
                "dna": "d5773b03054fcf095428bcb12c43d8ae9ff1f334",
                "date": 1683437705005,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "561",
                "rarity_score": 182.63858520328532,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/561.png",
            "scheme": "https"
        }
    },
    "418": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #418",
                "description": "description",
                "dna": "b85eaaffc5476989c55567f203043139d0d13dcb",
                "date": 1683775108632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "418",
                "rarity_score": 203.25472060813706,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/418.png",
            "scheme": "https"
        }
    },
    "2449": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2449",
                "description": "description",
                "dna": "dc700abd89c5cc98164b49e4c4e0ba0edf7fa12b",
                "date": 1683774726422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2449",
                "rarity_score": 192.50403014631476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2449.png",
            "scheme": "https"
        }
    },
    "1381": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1381",
                "description": "description",
                "dna": "6af13e01408e7a0086cad3108a0ad229e9d44912",
                "date": 1683443454111,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1381",
                "rarity_score": 199.31294152490167,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1381.png",
            "scheme": "https"
        }
    },
    "3246": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3246",
                "description": "description",
                "dna": "12e4fd89f847fef535e757c0558a811b1ee65be1",
                "date": 1683436783479,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3246",
                "rarity_score": 192.22406447817093,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3246.png",
            "scheme": "https"
        }
    },
    "2493": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2493",
                "description": "description",
                "dna": "281e2918a5122d247939d210a31a198a2e777a90",
                "date": 1683433267899,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2493",
                "rarity_score": 241.3493364985359,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2493.png",
            "scheme": "https"
        }
    },
    "583": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #583",
                "description": "description",
                "dna": "a43873a18a14ef191c0bebc7d756ea9ddae90892",
                "date": 1683439511517,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "583",
                "rarity_score": 155.56623523457762,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/583.png",
            "scheme": "https"
        }
    },
    "2786": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2786",
                "description": "description",
                "dna": "68a5965df9dffbec549d0395440336a5093c92eb",
                "date": 1683774744268,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2786",
                "rarity_score": 238.55200141364006,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2786.png",
            "scheme": "https"
        }
    },
    "3533": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3533",
                "description": "description",
                "dna": "d8b1478156e5b7488986f4b5bc90bfb65bc667d0",
                "date": 1683776421261,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3533",
                "rarity_score": 213.25234782422086,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3533.png",
            "scheme": "https"
        }
    },
    "3810": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3810",
                "description": "description",
                "dna": "1d81f94c089e58e06db8e58d8a71b4be96fbb510",
                "date": 1683430230324,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "3810",
                "rarity_score": 304.4447558797817,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3810.png",
            "scheme": "https"
        }
    },
    "1739": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1739",
                "description": "description",
                "dna": "6546da69e1e3587a1bdf939fb41b92478633b117",
                "date": 1683430780141,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1739",
                "rarity_score": 233.3705940161778,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1739.png",
            "scheme": "https"
        }
    },
    "4086": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4086",
                "description": "description",
                "dna": "aab91a7997637ae7e1dc02b18680159ab50c2ad4",
                "date": 1683437339164,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "4086",
                "rarity_score": 301.40100997535376,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4086.png",
            "scheme": "https"
        }
    },
    "96": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #96",
                "description": "description",
                "dna": "d185d2050e6e563ef9218134ceed60a67b2873bd",
                "date": 1683434385497,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "96",
                "rarity_score": 215.06668208714444,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/96.png",
            "scheme": "https"
        }
    },
    "3955": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3955",
                "description": "description",
                "dna": "a8149cf98cbefe5c99d072c04719f042f0b151f5",
                "date": 1683437433787,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3955",
                "rarity_score": 127.77251417754826,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3955.png",
            "scheme": "https"
        }
    },
    "2299": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2299",
                "description": "description",
                "dna": "3969f01ad1c4d9b34600e484351697917939d332",
                "date": 1683442196879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2299",
                "rarity_score": 224.40702966098206,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2299.png",
            "scheme": "https"
        }
    },
    "3567": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3567",
                "description": "description",
                "dna": "2072d944f2228b874d8fbe46d9005ddc5744f72f",
                "date": 1683438144172,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3567",
                "rarity_score": 216.18738205471007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3567.png",
            "scheme": "https"
        }
    },
    "3440": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3440",
                "description": "description",
                "dna": "227cbe8c68945dd1c7e914cddbdaa014ba326266",
                "date": 1683447166879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3440",
                "rarity_score": 283.6847031654055,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3440.png",
            "scheme": "https"
        }
    },
    "1399": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1399",
                "description": "description",
                "dna": "62ad899871e703a2e6220887291baa22d7b23fae",
                "date": 1683438609159,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1399",
                "rarity_score": 292.80086899404506,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1399.png",
            "scheme": "https"
        }
    },
    "739": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #739",
                "description": "description",
                "dna": "dc06581ed0fd7998fcc4a683bcc7255cd2942031",
                "date": 1683443564523,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "739",
                "rarity_score": 168.6823209591613,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/739.png",
            "scheme": "https"
        }
    },
    "495": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #495",
                "description": "description",
                "dna": "30a41d3ccd1c2783bec215cac3cb7c225dd177af",
                "date": 1683440627334,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "495",
                "rarity_score": 187.56814891392474,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/495.png",
            "scheme": "https"
        }
    },
    "3666": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3666",
                "description": "description",
                "dna": "d49a750219cfb9785f0e94618b2fe5860c5a44f4",
                "date": 1683774614705,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3666",
                "rarity_score": 182.45565342056247,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3666.png",
            "scheme": "https"
        }
    },
    "2800": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2800",
                "description": "description",
                "dna": "25972994298200b07435a2c9bad9681340ff56fa",
                "date": 1683443283750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2800",
                "rarity_score": 180.69265226051664,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2800.png",
            "scheme": "https"
        }
    },
    "2469": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2469",
                "description": "description",
                "dna": "8a6378e8e07c0b6fcf558caccc3228648ff7ffdc",
                "date": 1683435148546,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2469",
                "rarity_score": 248.53391453024645,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2469.png",
            "scheme": "https"
        }
    },
    "2484": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2484",
                "description": "description",
                "dna": "e87a4179d92021b772a117056f0dc4194b865b07",
                "date": 1683430169144,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2484",
                "rarity_score": 197.97708223203418,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2484.png",
            "scheme": "https"
        }
    },
    "3456": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3456",
                "description": "description",
                "dna": "6a85437296c9df6c90782e9863039d05e63ca586",
                "date": 1683434893398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3456",
                "rarity_score": 204.28642067537936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3456.png",
            "scheme": "https"
        }
    },
    "3982": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3982",
                "description": "description",
                "dna": "e63c48bcc300dfd0a0f370643592ca477a968482",
                "date": 1683442725971,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3982",
                "rarity_score": 186.15519487062951,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3982.png",
            "scheme": "https"
        }
    },
    "1388": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1388",
                "description": "description",
                "dna": "6dac6c07a2f3c459d4b585813c191f2bd4ba64cf",
                "date": 1683442444061,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1388",
                "rarity_score": 212.66891043242973,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1388.png",
            "scheme": "https"
        }
    },
    "1784": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1784",
                "description": "description",
                "dna": "54b656e6561d9d99e9f48b61ec525938cb2990e8",
                "date": 1683775240151,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1784",
                "rarity_score": 153.9015420104162,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1784.png",
            "scheme": "https"
        }
    },
    "472": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #472",
                "description": "description",
                "dna": "703b3cf7073a758a3a229f797eee1a69290e0d47",
                "date": 1683771471026,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "472",
                "rarity_score": 182.0917957519116,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/472.png",
            "scheme": "https"
        }
    },
    "1059": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1059",
                "description": "description",
                "dna": "66cdb23023d17536dd31a4afe09ac5837c5a0a73",
                "date": 1683435306846,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1059",
                "rarity_score": 211.1664308658768,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1059.png",
            "scheme": "https"
        }
    },
    "883": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #883",
                "description": "description",
                "dna": "788b696acd5b9031eeba3168d5f35fbf21801dba",
                "date": 1683445396552,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "883",
                "rarity_score": 202.4604062375963,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/883.png",
            "scheme": "https"
        }
    },
    "992": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #992",
                "description": "description",
                "dna": "98ec9df96426f8ae1d0357bf72d42b2ca8b4f13d",
                "date": 1683431417477,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "992",
                "rarity_score": 151.52270310517292,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/992.png",
            "scheme": "https"
        }
    },
    "3943": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3943",
                "description": "description",
                "dna": "f75d8e8e117c1313d99c4f307a4722a184d5a90a",
                "date": 1683438962868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3943",
                "rarity_score": 258.75900384570093,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3943.png",
            "scheme": "https"
        }
    },
    "1495": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1495",
                "description": "description",
                "dna": "234cf88522ada9723dfc1321e8616a06022107c0",
                "date": 1683437919349,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1495",
                "rarity_score": 189.0217158487325,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1495.png",
            "scheme": "https"
        }
    },
    "2789": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2789",
                "description": "description",
                "dna": "1fa51489900d38dd872f49cf32458cb91bbce473",
                "date": 1683444660065,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2789",
                "rarity_score": 158.79521692879825,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2789.png",
            "scheme": "https"
        }
    },
    "2734": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2734",
                "description": "description",
                "dna": "e883b5fd8d3ee527b307a0133e4f78992f3bbbeb",
                "date": 1683433199738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2734",
                "rarity_score": 220.0762669104853,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2734.png",
            "scheme": "https"
        }
    },
    "3531": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3531",
                "description": "description",
                "dna": "5a5749cddeafc32d4896cc1e511eda26bdb3a0b9",
                "date": 1683774475778,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3531",
                "rarity_score": 161.9122171286236,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3531.png",
            "scheme": "https"
        }
    },
    "1067": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1067",
                "description": "description",
                "dna": "a7a399188d214dff3b29914faa6ef5717b4912fc",
                "date": 1683771128796,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1067",
                "rarity_score": 205.41896212600173,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1067.png",
            "scheme": "https"
        }
    },
    "3406": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3406",
                "description": "description",
                "dna": "83d72517d70f548dcfe1f9f4db3e542453a0bd0d",
                "date": 1683771341494,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3406",
                "rarity_score": 242.17861986642097,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3406.png",
            "scheme": "https"
        }
    },
    "3857": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3857",
                "description": "description",
                "dna": "2966e8af88513130b565171b9a6fa0955e49f208",
                "date": 1683443390210,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3857",
                "rarity_score": 163.5555556611524,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3857.png",
            "scheme": "https"
        }
    },
    "1699": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1699",
                "description": "description",
                "dna": "ee77e8565033a80392ed86a89056473ef14404bb",
                "date": 1683439023091,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1699",
                "rarity_score": 132.7554828642399,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1699.png",
            "scheme": "https"
        }
    },
    "2868": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2868",
                "description": "description",
                "dna": "c59af948a258188a6cf02d9351111e4552116805",
                "date": 1683437660004,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2868",
                "rarity_score": 175.09923374944896,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2868.png",
            "scheme": "https"
        }
    },
    "2505": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2505",
                "description": "description",
                "dna": "d8353fa031ad31cc3ae8596220562ad69a20b807",
                "date": 1683442628947,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2505",
                "rarity_score": 211.93673816821413,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2505.png",
            "scheme": "https"
        }
    },
    "2346": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2346",
                "description": "description",
                "dna": "9cfe167ed0e65e5f4fc2d982a2f39ecf4303bce2",
                "date": 1683436497141,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2346",
                "rarity_score": 198.17717001159113,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2346.png",
            "scheme": "https"
        }
    },
    "264": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #264",
                "description": "description",
                "dna": "c84575af99a663c10dd8ab3e6b70c10f0e1f5111",
                "date": 1683776633857,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "264",
                "rarity_score": 199.5079553330912,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/264.png",
            "scheme": "https"
        }
    },
    "3231": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3231",
                "description": "description",
                "dna": "51e0007d20ffba905974cbffa280dd5a0f3335cc",
                "date": 1683441890496,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3231",
                "rarity_score": 164.92247784754727,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3231.png",
            "scheme": "https"
        }
    },
    "997": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #997",
                "description": "description",
                "dna": "44a2bfd3f53ebc629c1d4bc8ba6a6d8b2842cadb",
                "date": 1683441511682,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "997",
                "rarity_score": 246.52330101880668,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/997.png",
            "scheme": "https"
        }
    },
    "1086": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1086",
                "description": "description",
                "dna": "827638bb4323e28950561db886f917180d3635e2",
                "date": 1683437540780,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1086",
                "rarity_score": 160.99405121174516,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1086.png",
            "scheme": "https"
        }
    },
    "3409": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3409",
                "description": "description",
                "dna": "56a952d70e02a426037b77766e0afcbd10d45a54",
                "date": 1683440804243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3409",
                "rarity_score": 215.57173818519902,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3409.png",
            "scheme": "https"
        }
    },
    "2413": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2413",
                "description": "description",
                "dna": "86a3e9aeef585a64339c62dc75d13f9d57912812",
                "date": 1683430614853,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2413",
                "rarity_score": 245.87304873880254,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2413.png",
            "scheme": "https"
        }
    },
    "2128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2128",
                "description": "description",
                "dna": "a4429862a71d89ebcd80f30eca22c45183430b83",
                "date": 1683776456458,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2128",
                "rarity_score": 335.8838800764571,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2128.png",
            "scheme": "https"
        }
    },
    "2524": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2524",
                "description": "description",
                "dna": "6c283890309368ab01d9a0870b6a5d960ed6ce85",
                "date": 1683438031616,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2524",
                "rarity_score": 182.9276912875484,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2524.png",
            "scheme": "https"
        }
    },
    "1042": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1042",
                "description": "description",
                "dna": "2e21c24cbf4f25dea0ba9d5698f723c83c65548f",
                "date": 1683436186088,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1042",
                "rarity_score": 278.7994922635013,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1042.png",
            "scheme": "https"
        }
    },
    "3785": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3785",
                "description": "description",
                "dna": "91c3cf3876ef5e89bd9c3119cdc29890ab89ccc6",
                "date": 1683430610782,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3785",
                "rarity_score": 204.4310894033278,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3785.png",
            "scheme": "https"
        }
    },
    "1461": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1461",
                "description": "description",
                "dna": "3d6882980375fab824429fdd6e4ba8cb6dfa4f2f",
                "date": 1683436259561,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1461",
                "rarity_score": 244.62531854896437,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1461.png",
            "scheme": "https"
        }
    },
    "3687": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3687",
                "description": "description",
                "dna": "96a4febbd09889b05699887d974395723ab30451",
                "date": 1683436303340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3687",
                "rarity_score": 160.10445725945618,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3687.png",
            "scheme": "https"
        }
    },
    "369": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #369",
                "description": "description",
                "dna": "be7b61015b207677f9164a27687734c977f6c3bf",
                "date": 1683440197296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "369",
                "rarity_score": 239.46034325250864,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/369.png",
            "scheme": "https"
        }
    },
    "3514": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3514",
                "description": "description",
                "dna": "a5ec0d49fbec7ae649a4f61c3318b098ad10d2e3",
                "date": 1683775188702,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3514",
                "rarity_score": 176.6488675265633,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3514.png",
            "scheme": "https"
        }
    },
    "2036": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2036",
                "description": "description",
                "dna": "bb7504346180196ce16dccdcae74db97857bee9d",
                "date": 1683441093597,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2036",
                "rarity_score": 199.0880134792917,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2036.png",
            "scheme": "https"
        }
    },
    "2573": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2573",
                "description": "description",
                "dna": "f428a6cefa3d5e4c05112cf60da70d5c54422344",
                "date": 1683438949120,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2573",
                "rarity_score": 282.16249428914597,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2573.png",
            "scheme": "https"
        }
    },
    "726": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #726",
                "description": "description",
                "dna": "e5c14ffdba0460e171a6ed603047f8317c759666",
                "date": 1683437511341,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "726",
                "rarity_score": 130.70628982243662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/726.png",
            "scheme": "https"
        }
    },
    "4017": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4017",
                "description": "description",
                "dna": "017d43ae2216af8712dbad375896180e412bab0f",
                "date": 1683433007530,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "4017",
                "rarity_score": 287.61953379020605,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4017.png",
            "scheme": "https"
        }
    },
    "317": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #317",
                "description": "description",
                "dna": "32f0e5ca871d67c68ef1812a76f79e9983fe2328",
                "date": 1683444445586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "317",
                "rarity_score": 196.76521283007884,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/317.png",
            "scheme": "https"
        }
    },
    "1037": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1037",
                "description": "description",
                "dna": "98420f2d4457b56eacfa048414dbd6f378934ae0",
                "date": 1683431166136,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1037",
                "rarity_score": 189.48599417406177,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1037.png",
            "scheme": "https"
        }
    },
    "3240": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3240",
                "description": "description",
                "dna": "5a400e0ad426428d8c11acb72484469825b22016",
                "date": 1683774462103,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "3240",
                "rarity_score": 383.27608610434436,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3240.png",
            "scheme": "https"
        }
    },
    "1525": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1525",
                "description": "description",
                "dna": "8ef00a878fab3afa0859dac49366777ae5374eff",
                "date": 1683431536530,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1525",
                "rarity_score": 202.27439922190345,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1525.png",
            "scheme": "https"
        }
    },
    "1150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1150",
                "description": "description",
                "dna": "b6dfb7f0e6653f7bb78f543195b4e48b484a7154",
                "date": 1683432125136,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1150",
                "rarity_score": 190.96575426892363,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1150.png",
            "scheme": "https"
        }
    },
    "3976": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3976",
                "description": "description",
                "dna": "e629bd089b5c3e16cdc6196b7ba6c3d39a601ef7",
                "date": 1683430682339,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3976",
                "rarity_score": 149.96576422966902,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3976.png",
            "scheme": "https"
        }
    },
    "3202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3202",
                "description": "description",
                "dna": "f20d0304a7149011d48518e1149a66709c6dc43c",
                "date": 1683435129233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3202",
                "rarity_score": 256.3529426670422,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3202.png",
            "scheme": "https"
        }
    },
    "3122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3122",
                "description": "description",
                "dna": "284f5b2a5e4a45b4b7a731d76137de00466a634a",
                "date": 1683429969416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3122",
                "rarity_score": 151.77165726966945,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3122.png",
            "scheme": "https"
        }
    },
    "3233": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3233",
                "description": "description",
                "dna": "3ed08bd5e5174faca24a520d8479b800ff851dc0",
                "date": 1683445154659,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3233",
                "rarity_score": 204.73684974628478,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3233.png",
            "scheme": "https"
        }
    },
    "3721": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3721",
                "description": "description",
                "dna": "25c888d0cea8dc4e9ff2c5d8193f08b4bef35638",
                "date": 1683433668426,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3721",
                "rarity_score": 198.15002642497382,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3721.png",
            "scheme": "https"
        }
    },
    "3032": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3032",
                "description": "description",
                "dna": "516dd259cac8b515a036d9688e21800626cdcf9c",
                "date": 1683433537949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3032",
                "rarity_score": 211.36344725506092,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3032.png",
            "scheme": "https"
        }
    },
    "3623": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3623",
                "description": "description",
                "dna": "5c156240edfd677b84cd5b1f69bbf4fb0da5110d",
                "date": 1683431695604,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3623",
                "rarity_score": 169.77985638234998,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3623.png",
            "scheme": "https"
        }
    },
    "4047": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4047",
                "description": "description",
                "dna": "a9f0f7178d09a7cb73fdcdcd861157a451c660f2",
                "date": 1683771517820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "4047",
                "rarity_score": 221.7803774592333,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4047.png",
            "scheme": "https"
        }
    },
    "1465": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1465",
                "description": "description",
                "dna": "d3df6eb90bee956ab72348ed5486818f4fe8b522",
                "date": 1683442272831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1465",
                "rarity_score": 229.78087030534658,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1465.png",
            "scheme": "https"
        }
    },
    "1698": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1698",
                "description": "description",
                "dna": "0bb84e0cebb6b88ba9ebc5bd199963f79c0e560b",
                "date": 1683446905804,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1698",
                "rarity_score": 177.4609075804445,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1698.png",
            "scheme": "https"
        }
    },
    "2415": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2415",
                "description": "description",
                "dna": "4cccd6390030a88b4ed20da1973d58dacfc8c514",
                "date": 1683437293426,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2415",
                "rarity_score": 141.84055769619218,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2415.png",
            "scheme": "https"
        }
    },
    "532": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #532",
                "description": "description",
                "dna": "fe81d70f9bc5e14e2ffca40befd4c6ae0c2d5f3b",
                "date": 1683444611757,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "532",
                "rarity_score": 228.4266711466596,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/532.png",
            "scheme": "https"
        }
    },
    "927": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #927",
                "description": "description",
                "dna": "f44b60f8db9b564369f99b8f7a7622a6bd5262ad",
                "date": 1683517616971,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Lotus Flower",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "927",
                "rarity_score": 4249.948299730629,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/927.png",
            "scheme": "https"
        }
    },
    "3279": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3279",
                "description": "description",
                "dna": "257ae622b9d869829c921cd7546c564be084fabb",
                "date": 1683437992861,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3279",
                "rarity_score": 249.3614846021029,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3279.png",
            "scheme": "https"
        }
    },
    "1853": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1853",
                "description": "description",
                "dna": "8d3b07624cffcfe439c1df48aadffe20e27dbf62",
                "date": 1683770647365,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1853",
                "rarity_score": 362.84957850320086,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1853.png",
            "scheme": "https"
        }
    },
    "3678": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3678",
                "description": "description",
                "dna": "412e38c6c4068d24a57cd8c92aca88c962b7e927",
                "date": 1683774874996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3678",
                "rarity_score": 159.24476204331586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3678.png",
            "scheme": "https"
        }
    },
    "456": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #456",
                "description": "description",
                "dna": "a1353119132970406e8ab64d9125d9686e319023",
                "date": 1683436308052,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "456",
                "rarity_score": 277.94617857818656,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/456.png",
            "scheme": "https"
        }
    },
    "4048": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4048",
                "description": "description",
                "dna": "357fa09c202855a032e455384f79ed3d67b6bb8c",
                "date": 1683445274600,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4048",
                "rarity_score": 259.5819358628527,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4048.png",
            "scheme": "https"
        }
    },
    "3908": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3908",
                "description": "description",
                "dna": "80b498c2c3bf9a5857af18181a3e1a32136336ec",
                "date": 1683436965949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3908",
                "rarity_score": 217.15414321187836,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3908.png",
            "scheme": "https"
        }
    },
    "1641": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1641",
                "description": "description",
                "dna": "e16839f4807f41dada6c41b3c5d0c71d06e4617c",
                "date": 1683432675959,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1641",
                "rarity_score": 201.79489470735615,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1641.png",
            "scheme": "https"
        }
    },
    "2285": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2285",
                "description": "description",
                "dna": "fbe7712eb008684b312fe4da0ea03f1d6064a799",
                "date": 1683776473390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2285",
                "rarity_score": 225.17206917214475,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2285.png",
            "scheme": "https"
        }
    },
    "75": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #75",
                "description": "description",
                "dna": "156410761cf53c2aa1d1684f0c6a595675f33669",
                "date": 1683442350607,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "75",
                "rarity_score": 168.86285160683911,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/75.png",
            "scheme": "https"
        }
    },
    "2433": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2433",
                "description": "description",
                "dna": "a73c9b74a038f4e95cb9cd05a0d88ef342940cef",
                "date": 1683429281040,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2433",
                "rarity_score": 194.7252768676409,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2433.png",
            "scheme": "https"
        }
    },
    "2424": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2424",
                "description": "description",
                "dna": "575fcba94cd8558624c61e3897c6c71818398a9d",
                "date": 1683430324775,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2424",
                "rarity_score": 169.98988911978623,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2424.png",
            "scheme": "https"
        }
    },
    "1474": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1474",
                "description": "description",
                "dna": "c09fe950b5d4d86fe5a3d6ba9878a3f5f8c7efe4",
                "date": 1683442392952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1474",
                "rarity_score": 216.38752222564221,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1474.png",
            "scheme": "https"
        }
    },
    "3534": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3534",
                "description": "description",
                "dna": "18dc9f733c1416e4a687ee36035af3e30118780b",
                "date": 1683446372075,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3534",
                "rarity_score": 153.32520117814207,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3534.png",
            "scheme": "https"
        }
    },
    "254": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #254",
                "description": "description",
                "dna": "53acd7e06c47a204abf198b7b2aa0765b29220f5",
                "date": 1683432766745,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "254",
                "rarity_score": 315.7592936841305,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/254.png",
            "scheme": "https"
        }
    },
    "444": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #444",
                "description": "description",
                "dna": "11b863d5e89b89bebdf782e05c4e44acbdc673b0",
                "date": 1683775113009,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "444",
                "rarity_score": 167.78879476000932,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/444.png",
            "scheme": "https"
        }
    },
    "3273": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3273",
                "description": "description",
                "dna": "599261f39e186313b609401c40606128781d48fc",
                "date": 1683442453741,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3273",
                "rarity_score": 227.54129894986542,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3273.png",
            "scheme": "https"
        }
    },
    "2177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2177",
                "description": "description",
                "dna": "99ac1e32ba00a1a8888426e92b47e6447e2faf57",
                "date": 1683433154524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2177",
                "rarity_score": 202.28661555876573,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2177.png",
            "scheme": "https"
        }
    },
    "788": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #788",
                "description": "description",
                "dna": "52ff20e16a4e473acb23cd61e383c7269c957e4b",
                "date": 1683437437707,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "788",
                "rarity_score": 144.7454128881198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/788.png",
            "scheme": "https"
        }
    },
    "3579": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3579",
                "description": "description",
                "dna": "59c1910e31f349130cbfd60b41eb14f0c96de028",
                "date": 1683446717330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3579",
                "rarity_score": 181.18002784645407,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3579.png",
            "scheme": "https"
        }
    },
    "1511": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1511",
                "description": "description",
                "dna": "1f77943f1225a020a76ac37f18f042bf736b2f59",
                "date": 1683433343979,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1511",
                "rarity_score": 265.31705622681915,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1511.png",
            "scheme": "https"
        }
    },
    "1250": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1250",
                "description": "description",
                "dna": "156d9bb969ce555ad551f79fd9e5e99f2d62be1c",
                "date": 1683439205781,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1250",
                "rarity_score": 156.27387914824996,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1250.png",
            "scheme": "https"
        }
    },
    "867": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #867",
                "description": "description",
                "dna": "51524de5c4f4e4db660d676d6bdc7f49ea5435d2",
                "date": 1683431777313,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "867",
                "rarity_score": 220.62457555063793,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/867.png",
            "scheme": "https"
        }
    },
    "3986": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3986",
                "description": "description",
                "dna": "4bece830073556c9c4d297bae61ff16fe170b368",
                "date": 1683436970106,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3986",
                "rarity_score": 132.29001794783636,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3986.png",
            "scheme": "https"
        }
    },
    "2597": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2597",
                "description": "description",
                "dna": "97107a55a69cfce1d6cb02219577e83f77aaa0ba",
                "date": 1683771307179,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2597",
                "rarity_score": 209.04245834219122,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2597.png",
            "scheme": "https"
        }
    },
    "1805": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1805",
                "description": "description",
                "dna": "33855451b1e791f0c32a193d2f78ec3680bd2ebd",
                "date": 1683435471380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1805",
                "rarity_score": 197.62318348958817,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1805.png",
            "scheme": "https"
        }
    },
    "2414": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2414",
                "description": "description",
                "dna": "c3cd8b50d92a24e75a671cfc4eedfad681d08506",
                "date": 1683434768122,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2414",
                "rarity_score": 177.25458674763593,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2414.png",
            "scheme": "https"
        }
    },
    "1944": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1944",
                "description": "description",
                "dna": "379842872a5f18b710cccc9e287ad9b0805dc58e",
                "date": 1683776497896,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1944",
                "rarity_score": 317.7352217632795,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1944.png",
            "scheme": "https"
        }
    },
    "1486": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1486",
                "description": "description",
                "dna": "00446e48ead98e465625228f61eb3ac854e42952",
                "date": 1683433793518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1486",
                "rarity_score": 186.14274330685492,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1486.png",
            "scheme": "https"
        }
    },
    "427": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #427",
                "description": "description",
                "dna": "7364ed3ce3067e2fba2e8a2e0afb9d45b870bfcb",
                "date": 1683443546456,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "427",
                "rarity_score": 152.21715268198307,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/427.png",
            "scheme": "https"
        }
    },
    "3444": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3444",
                "description": "description",
                "dna": "7810f09755b1e4624f4e889608ff062fc73ebdc4",
                "date": 1683435120092,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3444",
                "rarity_score": 224.59448073957662,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3444.png",
            "scheme": "https"
        }
    },
    "3263": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3263",
                "description": "description",
                "dna": "01ece4511326e0ef07036bce7504dbdc59d0feaf",
                "date": 1683441588582,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3263",
                "rarity_score": 179.31141633256328,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3263.png",
            "scheme": "https"
        }
    },
    "736": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #736",
                "description": "description",
                "dna": "e956d862cf89281de4cfffeb9b12fc5a2cd3fbbc",
                "date": 1683433542047,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "736",
                "rarity_score": 171.7864858572632,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/736.png",
            "scheme": "https"
        }
    },
    "453": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #453",
                "description": "description",
                "dna": "7caa6f6903d8350c1e7ac937edf1a04ebf5c0b07",
                "date": 1683444238034,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "453",
                "rarity_score": 179.0908917179284,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/453.png",
            "scheme": "https"
        }
    },
    "1524": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1524",
                "description": "description",
                "dna": "e112c52362582a032aea8dabfbdef95d7f013058",
                "date": 1683441966411,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1524",
                "rarity_score": 192.8266540291403,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1524.png",
            "scheme": "https"
        }
    },
    "3405": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3405",
                "description": "description",
                "dna": "83559964c8501efc2f5314b8a6ce3e74fb8b5eb2",
                "date": 1683440386601,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3405",
                "rarity_score": 171.7785016292679,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3405.png",
            "scheme": "https"
        }
    },
    "478": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #478",
                "description": "description",
                "dna": "5130bb00c56ee8cc7feb3aad4408b1e9ff011862",
                "date": 1683441346151,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "478",
                "rarity_score": 181.17746932545924,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/478.png",
            "scheme": "https"
        }
    },
    "1205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1205",
                "description": "description",
                "dna": "a2188d02acfdaec493de7f07cac337dadd6d0581",
                "date": 1683439619057,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1205",
                "rarity_score": 220.60144581988234,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1205.png",
            "scheme": "https"
        }
    },
    "2730": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2730",
                "description": "description",
                "dna": "5d2a64873f61457d06661b74211f35da55f55582",
                "date": 1683770576845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2730",
                "rarity_score": 222.37600746023617,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2730.png",
            "scheme": "https"
        }
    },
    "3198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3198",
                "description": "description",
                "dna": "a8a06de7980f3fa30b1866925ff76c6187fb80a2",
                "date": 1683776465262,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3198",
                "rarity_score": 267.99125237271727,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3198.png",
            "scheme": "https"
        }
    },
    "1294": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1294",
                "description": "description",
                "dna": "dfc5b40e51efec7280810b42bd38cfe0293bda6d",
                "date": 1683774762011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1294",
                "rarity_score": 256.2523177915041,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1294.png",
            "scheme": "https"
        }
    },
    "2937": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2937",
                "description": "description",
                "dna": "bdf5fb01ccad9402d2674c876bff25a3523aa2c4",
                "date": 1683429370958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2937",
                "rarity_score": 264.80923186519834,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2937.png",
            "scheme": "https"
        }
    },
    "1850": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1850",
                "description": "description",
                "dna": "00bcfe7c7a68a5444259d9b63b3149de7e3459be",
                "date": 1683774906427,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1850",
                "rarity_score": 200.67501834137101,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1850.png",
            "scheme": "https"
        }
    },
    "777": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #777",
                "description": "description",
                "dna": "f4e976eb437c880cddac84b46e7327cb44a5ab3f",
                "date": 1683440139074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "777",
                "rarity_score": 179.22515192777433,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/777.png",
            "scheme": "https"
        }
    },
    "587": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #587",
                "description": "description",
                "dna": "8503443b9b453ab99ae9d19d7d86380bf7e845de",
                "date": 1683440079260,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "587",
                "rarity_score": 228.35828033942462,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/587.png",
            "scheme": "https"
        }
    },
    "113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #113",
                "description": "description",
                "dna": "a8a22ab706db563e22750b2fee8b91a30a4e1ef8",
                "date": 1683433348554,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "113",
                "rarity_score": 243.58531581567837,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/113.png",
            "scheme": "https"
        }
    },
    "575": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #575",
                "description": "description",
                "dna": "9f0de6077d97351ec8726dc5def9bae88d126ec8",
                "date": 1683441115574,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "575",
                "rarity_score": 187.34171382499878,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/575.png",
            "scheme": "https"
        }
    },
    "3707": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3707",
                "description": "description",
                "dna": "dbaf94ecbecb5a0b32ff32e4b3cb08ef1cf0b7c9",
                "date": 1683435065028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3707",
                "rarity_score": 162.93525364650537,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3707.png",
            "scheme": "https"
        }
    },
    "2733": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2733",
                "description": "description",
                "dna": "71b8b35e662b616700b169b8f939c90730acd96f",
                "date": 1683440215452,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2733",
                "rarity_score": 179.95101554189046,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2733.png",
            "scheme": "https"
        }
    },
    "1356": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1356",
                "description": "description",
                "dna": "140de7bf3ea71e554f3ab6ca309c2fb6dfa9098f",
                "date": 1683435616055,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1356",
                "rarity_score": 157.97927706077854,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1356.png",
            "scheme": "https"
        }
    },
    "2552": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2552",
                "description": "description",
                "dna": "d9280845cf8cef94c708d17afa50833dd06be933",
                "date": 1683774829500,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2552",
                "rarity_score": 294.95474458853573,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2552.png",
            "scheme": "https"
        }
    },
    "3422": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3422",
                "description": "description",
                "dna": "d7069a54d9b3f5b41b9cb05b4ef9a07403da55a7",
                "date": 1683435124295,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3422",
                "rarity_score": 192.4284490903201,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3422.png",
            "scheme": "https"
        }
    },
    "3320": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3320",
                "description": "description",
                "dna": "5ea4040de7f9da81d73111bdb54ac52201979b47",
                "date": 1683434303382,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3320",
                "rarity_score": 186.98382843478868,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3320.png",
            "scheme": "https"
        }
    },
    "1422": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1422",
                "description": "description",
                "dna": "6cfae0bc3b5c000da142bcf49946e212bbbf000d",
                "date": 1683443029475,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1422",
                "rarity_score": 195.50711855271496,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1422.png",
            "scheme": "https"
        }
    },
    "4058": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4058",
                "description": "description",
                "dna": "3b057122ec118d2b3fa02e5670e01d084758f6e3",
                "date": 1683445980949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "4058",
                "rarity_score": 228.11136838793186,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4058.png",
            "scheme": "https"
        }
    },
    "3729": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3729",
                "description": "description",
                "dna": "a73275f15cb18ce13ce5e04b126f6f9822bb9bb7",
                "date": 1683446960013,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3729",
                "rarity_score": 202.80705564278847,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3729.png",
            "scheme": "https"
        }
    },
    "1748": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1748",
                "description": "description",
                "dna": "5b6fa47ad4681489e6b922371188ee2b9d07b77d",
                "date": 1683441221406,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1748",
                "rarity_score": 178.14554291130122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1748.png",
            "scheme": "https"
        }
    },
    "65": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #65",
                "description": "description",
                "dna": "a0923cb3940a59dfa3d901a0f3966ee3d5e5e22f",
                "date": 1683443247518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "65",
                "rarity_score": 200.88564877835742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/65.png",
            "scheme": "https"
        }
    },
    "2053": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2053",
                "description": "description",
                "dna": "5f125cbd005d53d645d546d5919337daa3c9d0b2",
                "date": 1683434997635,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2053",
                "rarity_score": 227.3820649629202,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2053.png",
            "scheme": "https"
        }
    },
    "1485": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1485",
                "description": "description",
                "dna": "519e2432c9a0f71461bd03e4a204a9f4ca7cf1e0",
                "date": 1683430117993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1485",
                "rarity_score": 221.22545389371726,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1485.png",
            "scheme": "https"
        }
    },
    "1982": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1982",
                "description": "description",
                "dna": "d07633f1b8b2621344f02f9d7303e5aa278679e9",
                "date": 1683444934013,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1982",
                "rarity_score": 196.1594404540793,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1982.png",
            "scheme": "https"
        }
    },
    "1818": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1818",
                "description": "description",
                "dna": "f7f0b03ce322a0d8ead109860fa1af7ad5dfe75c",
                "date": 1683447127038,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1818",
                "rarity_score": 217.11803818102896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1818.png",
            "scheme": "https"
        }
    },
    "1721": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1721",
                "description": "description",
                "dna": "bf2a56d6a7d67af7e4c89221386a7a1972f3dd79",
                "date": 1683441494231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1721",
                "rarity_score": 208.85691056982895,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1721.png",
            "scheme": "https"
        }
    },
    "1855": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1855",
                "description": "description",
                "dna": "9c3c255320aa04edd3bece46bb54ba01b1f4a01f",
                "date": 1683436889432,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1855",
                "rarity_score": 137.96112245401036,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1855.png",
            "scheme": "https"
        }
    },
    "966": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #966",
                "description": "description",
                "dna": "9bd210b7b3b8058559737a4364716592d91270a7",
                "date": 1683437219109,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "966",
                "rarity_score": 141.6833889525519,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/966.png",
            "scheme": "https"
        }
    },
    "1253": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1253",
                "description": "description",
                "dna": "ae1b2810cae5fda98ab74b1c9304709a664f4fb8",
                "date": 1683445884256,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1253",
                "rarity_score": 368.0816781927882,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1253.png",
            "scheme": "https"
        }
    },
    "990": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #990",
                "description": "description",
                "dna": "06fea6b909ed7212851bfbb9229c6bd68ed80fc2",
                "date": 1683439484369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "990",
                "rarity_score": 178.91111769806565,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/990.png",
            "scheme": "https"
        }
    },
    "1992": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1992",
                "description": "description",
                "dna": "c6a92f77c032de286560ffb9ff2dd26665d77b0f",
                "date": 1683440188534,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1992",
                "rarity_score": 188.4103237311212,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1992.png",
            "scheme": "https"
        }
    },
    "2133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2133",
                "description": "description",
                "dna": "29b2cc6b873693dcbed20ea507bba0b366148f6c",
                "date": 1683770539438,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2133",
                "rarity_score": 243.52093049050762,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2133.png",
            "scheme": "https"
        }
    },
    "1863": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1863",
                "description": "description",
                "dna": "8c3c15e5638c0d3b6403ab140adf3dc2fe6b3500",
                "date": 1683443578291,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1863",
                "rarity_score": 176.18122012263476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1863.png",
            "scheme": "https"
        }
    },
    "127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #127",
                "description": "description",
                "dna": "730aace5c8cca200aace0cb062838487fc7a2dbb",
                "date": 1683443860725,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "127",
                "rarity_score": 175.86203141492894,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/127.png",
            "scheme": "https"
        }
    },
    "263": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #263",
                "description": "description",
                "dna": "f206d2c567b9d16b0f732f296a6fafe4f3d9d0fa",
                "date": 1683432862110,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "263",
                "rarity_score": 206.28214641001577,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/263.png",
            "scheme": "https"
        }
    },
    "120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #120",
                "description": "description",
                "dna": "85f10f3c16d8af117dc3db5567745af9145eee26",
                "date": 1683445927590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "120",
                "rarity_score": 132.74497928603148,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/120.png",
            "scheme": "https"
        }
    },
    "3944": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3944",
                "description": "description",
                "dna": "96070a19a9d791b2007bc565984bc1fe5e96e924",
                "date": 1683776779242,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3944",
                "rarity_score": 310.2844587469151,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3944.png",
            "scheme": "https"
        }
    },
    "2605": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2605",
                "description": "description",
                "dna": "4057cd8f6e5d0fe3a87df822bd958468d248732f",
                "date": 1683442471784,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2605",
                "rarity_score": 176.65290752949147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2605.png",
            "scheme": "https"
        }
    },
    "4030": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4030",
                "description": "description",
                "dna": "e2aebcc23748cec73bfe83c7f6ceabfbcf2d2157",
                "date": 1683436840808,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "4030",
                "rarity_score": 194.63423771066803,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4030.png",
            "scheme": "https"
        }
    },
    "1027": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1027",
                "description": "description",
                "dna": "88b412042525f6cf27f6d2d8aeda179fdc08f739",
                "date": 1683433560949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1027",
                "rarity_score": 164.38756281962202,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1027.png",
            "scheme": "https"
        }
    },
    "474": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #474",
                "description": "description",
                "dna": "2bf67ec00fbd09ddd1a54d2292d2688b8853f8d5",
                "date": 1683446730094,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "474",
                "rarity_score": 169.91511395774498,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/474.png",
            "scheme": "https"
        }
    },
    "3004": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3004",
                "description": "description",
                "dna": "532a74c459a6adbc492dec835aa3c6b2aba15325",
                "date": 1683439556511,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3004",
                "rarity_score": 218.04498652127208,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3004.png",
            "scheme": "https"
        }
    },
    "559": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #559",
                "description": "description",
                "dna": "ffd54f6be6f2dcf181317297a17eccf7a3d2efd7",
                "date": 1683442019073,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "559",
                "rarity_score": 174.80918562563687,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/559.png",
            "scheme": "https"
        }
    },
    "3223": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3223",
                "description": "description",
                "dna": "709fe00853af68f3bb6345b460db0df289f3278c",
                "date": 1683440317896,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3223",
                "rarity_score": 193.50257168403635,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3223.png",
            "scheme": "https"
        }
    },
    "19": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #19",
                "description": "description",
                "dna": "b6fa8522960a5f9b5d7e55555ddb2fb18acc4515",
                "date": 1683433123897,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "19",
                "rarity_score": 223.0620839451471,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/19.png",
            "scheme": "https"
        }
    },
    "2005": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2005",
                "description": "description",
                "dna": "a36ff1454d275de4d7c09677d0e3f747d7aab490",
                "date": 1683437129060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2005",
                "rarity_score": 152.8922249493109,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2005.png",
            "scheme": "https"
        }
    },
    "1140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1140",
                "description": "description",
                "dna": "3ab0fc549b95d2f7b004ac1b2a84b90cc74e97a9",
                "date": 1683434633832,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "1140",
                "rarity_score": 350.7215381413147,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1140.png",
            "scheme": "https"
        }
    },
    "34": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #34",
                "description": "description",
                "dna": "ae0bb7adbb4374c910250d0b0135ffdc4c267f0c",
                "date": 1683440709106,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "34",
                "rarity_score": 178.352120699307,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/34.png",
            "scheme": "https"
        }
    },
    "3594": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3594",
                "description": "description",
                "dna": "4f8ac9380e06473d810d466b4f5da69df64a1c6b",
                "date": 1683438246718,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3594",
                "rarity_score": 179.65659245548235,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3594.png",
            "scheme": "https"
        }
    },
    "107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #107",
                "description": "description",
                "dna": "a83974edde3a1fd93debf0d94f60bcb8805a3f42",
                "date": 1683446734508,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "107",
                "rarity_score": 194.99408463732414,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/107.png",
            "scheme": "https"
        }
    },
    "3791": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3791",
                "description": "description",
                "dna": "d72046223e2b1456ee896509083a687ff7617e38",
                "date": 1683442187679,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3791",
                "rarity_score": 189.99963478386562,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3791.png",
            "scheme": "https"
        }
    },
    "3771": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3771",
                "description": "description",
                "dna": "9e4583a8db8ece542d3fa6eea113f4f3746a50ca",
                "date": 1683437610453,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3771",
                "rarity_score": 123.57639656991773,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3771.png",
            "scheme": "https"
        }
    },
    "2687": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2687",
                "description": "description",
                "dna": "b3c4bcbbd31a50d6fb9de36d1cba9e500f05518f",
                "date": 1683430669748,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2687",
                "rarity_score": 287.79996093302617,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2687.png",
            "scheme": "https"
        }
    },
    "2725": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2725",
                "description": "description",
                "dna": "a9dfd05c9ebea39caa49ec56668654c7b6a3fe68",
                "date": 1683442531332,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2725",
                "rarity_score": 158.88971629082246,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2725.png",
            "scheme": "https"
        }
    },
    "92": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #92",
                "description": "description",
                "dna": "c0144e9731aa3ad0ec90852516d6b5a806a187de",
                "date": 1683442712238,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "92",
                "rarity_score": 161.77483394211913,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/92.png",
            "scheme": "https"
        }
    },
    "1977": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1977",
                "description": "description",
                "dna": "b22184caf2490d73ccdcf93d703c7af3907ca5db",
                "date": 1683447035629,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1977",
                "rarity_score": 195.89236895813616,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1977.png",
            "scheme": "https"
        }
    },
    "3717": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3717",
                "description": "description",
                "dna": "34773f068c8712bb6b6ba9ca44d10afa9356e63c",
                "date": 1683438349195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3717",
                "rarity_score": 181.53073097303118,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3717.png",
            "scheme": "https"
        }
    },
    "600": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #600",
                "description": "description",
                "dna": "78eab4aaa26080e895bb86f6b533d9e0dbc60a25",
                "date": 1683445564170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "600",
                "rarity_score": 222.8982680865365,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/600.png",
            "scheme": "https"
        }
    },
    "967": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #967",
                "description": "description",
                "dna": "fd957d2155e7c17decae9ff465f29506fae250ac",
                "date": 1683774937951,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "967",
                "rarity_score": 261.8136872746088,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/967.png",
            "scheme": "https"
        }
    },
    "3645": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3645",
                "description": "description",
                "dna": "15dfdc5c34838f0225424c7d20f18eaae2f36499",
                "date": 1683440331129,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3645",
                "rarity_score": 180.31488581778729,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3645.png",
            "scheme": "https"
        }
    },
    "1551": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1551",
                "description": "description",
                "dna": "a488bcb7750d69da886cc099dcf305ab6a05510e",
                "date": 1683447094924,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1551",
                "rarity_score": 209.45088770540926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1551.png",
            "scheme": "https"
        }
    },
    "1156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1156",
                "description": "description",
                "dna": "a7ae2d302ca2cc7bb8556563f7871335373e54fd",
                "date": 1683446000502,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1156",
                "rarity_score": 135.2297990560417,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1156.png",
            "scheme": "https"
        }
    },
    "939": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #939",
                "description": "description",
                "dna": "d9a19dfdd5cb179b0dc0edad6ae5ca35001fddad",
                "date": 1683435886071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "939",
                "rarity_score": 210.21036949938346,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/939.png",
            "scheme": "https"
        }
    },
    "1123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1123",
                "description": "description",
                "dna": "cc649d69bb8a232bd663cd0079a60ef0e953b784",
                "date": 1683435024342,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1123",
                "rarity_score": 164.3628186018666,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1123.png",
            "scheme": "https"
        }
    },
    "3846": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3846",
                "description": "description",
                "dna": "005d8343fa96fa5c88988fa33fcb511f0e64c8ee",
                "date": 1683444837157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3846",
                "rarity_score": 226.52925809491222,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3846.png",
            "scheme": "https"
        }
    },
    "1955": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1955",
                "description": "description",
                "dna": "dd8d27fd79255b04f0d70f00ee3267cc75770083",
                "date": 1683433825290,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1955",
                "rarity_score": 264.7564090252795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1955.png",
            "scheme": "https"
        }
    },
    "3934": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3934",
                "description": "description",
                "dna": "1a73cdc6794c200e30e468c0fc6f9bfc63eafb93",
                "date": 1683444542263,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3934",
                "rarity_score": 219.181063202058,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3934.png",
            "scheme": "https"
        }
    },
    "942": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #942",
                "description": "description",
                "dna": "d1fbde58568120b68ff324ebe7fda0d740b1a814",
                "date": 1683434535383,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "942",
                "rarity_score": 169.29117048642513,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/942.png",
            "scheme": "https"
        }
    },
    "3379": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3379",
                "description": "description",
                "dna": "1a5e64fbc317ec368457ac218957969cba1f7ff5",
                "date": 1683445643954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3379",
                "rarity_score": 273.18703353256893,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3379.png",
            "scheme": "https"
        }
    },
    "525": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #525",
                "description": "description",
                "dna": "e1ceee3f433512d09133e990de8076405de2a48b",
                "date": 1683439147691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "525",
                "rarity_score": 357.3207270451771,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/525.png",
            "scheme": "https"
        }
    },
    "2427": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2427",
                "description": "description",
                "dna": "a609809d6477011a93ded7ddd4981eb4aad684e4",
                "date": 1683444686471,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2427",
                "rarity_score": 186.6907940339281,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2427.png",
            "scheme": "https"
        }
    },
    "3007": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3007",
                "description": "description",
                "dna": "3d1c3552cd109c8013ce87c2eea8669aeefc4d39",
                "date": 1683444776845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3007",
                "rarity_score": 196.77218611762427,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3007.png",
            "scheme": "https"
        }
    },
    "2852": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2852",
                "description": "description",
                "dna": "e6bb9961227d08d1470cdcad5f7acd6c6f92bd5c",
                "date": 1683437203437,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2852",
                "rarity_score": 121.98279355679676,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2852.png",
            "scheme": "https"
        }
    },
    "590": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #590",
                "description": "description",
                "dna": "f6a78135ab8ccefb0dac1b4f6bf5665689febd6e",
                "date": 1683432498425,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "590",
                "rarity_score": 183.44029068877543,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/590.png",
            "scheme": "https"
        }
    },
    "651": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #651",
                "description": "description",
                "dna": "affe8e41b972f2f9d51d4cb9aa5e8e3b71e64cea",
                "date": 1683776625239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "651",
                "rarity_score": 217.6092367258372,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/651.png",
            "scheme": "https"
        }
    },
    "2579": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2579",
                "description": "description",
                "dna": "b31a6c8f8e844636d136dae685e2138d6db7b840",
                "date": 1683430406762,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2579",
                "rarity_score": 199.08645126645135,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2579.png",
            "scheme": "https"
        }
    },
    "200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #200",
                "description": "description",
                "dna": "a6170ef0fefc1e169e46a054cce8a148a82e1cc0",
                "date": 1683438686802,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "200",
                "rarity_score": 158.33255662262468,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/200.png",
            "scheme": "https"
        }
    },
    "228": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #228",
                "description": "description",
                "dna": "d277481fdf7e85e67cd18db4576ed3ce7f06351c",
                "date": 1683434308023,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "228",
                "rarity_score": 208.59653077223888,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/228.png",
            "scheme": "https"
        }
    },
    "968": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #968",
                "description": "description",
                "dna": "2dda09be84b2896cb5089b78490e1c0b24609deb",
                "date": 1683433457229,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "968",
                "rarity_score": 169.35881200767798,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/968.png",
            "scheme": "https"
        }
    },
    "2598": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2598",
                "description": "description",
                "dna": "066c11b1889b1db54cdeafde8f69f1da9efe7f94",
                "date": 1683774520695,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2598",
                "rarity_score": 166.71720341137151,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2598.png",
            "scheme": "https"
        }
    },
    "1549": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1549",
                "description": "description",
                "dna": "a5d11cdd498affd862c3d61c65572d7f27d0ba10",
                "date": 1683771268170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1549",
                "rarity_score": 182.6696091464372,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1549.png",
            "scheme": "https"
        }
    },
    "3210": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3210",
                "description": "description",
                "dna": "53f6da1c9f9b499f6bd7e5f138bf3fa6300fdaec",
                "date": 1683430729504,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3210",
                "rarity_score": 270.89011312616464,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3210.png",
            "scheme": "https"
        }
    },
    "3974": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3974",
                "description": "description",
                "dna": "fb408c2af2ebdd99cf084b4417af8da032559fd9",
                "date": 1683433619742,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3974",
                "rarity_score": 214.3297501105132,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3974.png",
            "scheme": "https"
        }
    },
    "3488": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3488",
                "description": "description",
                "dna": "0d7fcfabb4b5912d45df9ed169af69212c8fa827",
                "date": 1683508670211,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3488",
                "rarity_score": 396.4086690557666,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3488.png",
            "scheme": "https"
        }
    },
    "1506": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1506",
                "description": "description",
                "dna": "aa629b24b69a21200966dc868d77739bea9f01b5",
                "date": 1683437731278,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1506",
                "rarity_score": 244.14406907614028,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1506.png",
            "scheme": "https"
        }
    },
    "3012": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3012",
                "description": "description",
                "dna": "e9dd97464fd4e52ab35d435674134fb8e77b23c9",
                "date": 1683776783945,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3012",
                "rarity_score": 199.30696066696845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3012.png",
            "scheme": "https"
        }
    },
    "377": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #377",
                "description": "description",
                "dna": "e58366106bd5deb08d637f6fea08e1c0e19a4c93",
                "date": 1683440148256,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "377",
                "rarity_score": 190.95002909784031,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/377.png",
            "scheme": "https"
        }
    },
    "2182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2182",
                "description": "description",
                "dna": "4dca9b8a255843c72bb0eedb78c99f37c28e2370",
                "date": 1683774843064,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2182",
                "rarity_score": 179.59045814268777,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2182.png",
            "scheme": "https"
        }
    },
    "2327": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2327",
                "description": "description",
                "dna": "39380348dda89f83bbbd520300b50de769935189",
                "date": 1683435999226,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2327",
                "rarity_score": 264.84317751157766,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2327.png",
            "scheme": "https"
        }
    },
    "3513": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3513",
                "description": "description",
                "dna": "012d2d7eae1574b97a31038f48777f6451358866",
                "date": 1683445473720,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3513",
                "rarity_score": 247.86266743877292,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3513.png",
            "scheme": "https"
        }
    },
    "4029": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4029",
                "description": "description",
                "dna": "df9631f15e6d8243fbc0864f651acb1df0855c36",
                "date": 1683437079384,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "4029",
                "rarity_score": 309.3714749120213,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4029.png",
            "scheme": "https"
        }
    },
    "3008": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3008",
                "description": "description",
                "dna": "c11839eb31e6eea08b567614711d92f6da946780",
                "date": 1683430646886,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3008",
                "rarity_score": 266.14477614721477,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3008.png",
            "scheme": "https"
        }
    },
    "1491": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1491",
                "description": "description",
                "dna": "202edfb7c977ff996262866c0c7997c08c70012e",
                "date": 1683774555848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1491",
                "rarity_score": 179.2522255778512,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1491.png",
            "scheme": "https"
        }
    },
    "541": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #541",
                "description": "description",
                "dna": "e0ed907d2ff972160b3979206f4570f00d8bc2f3",
                "date": 1683434263222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "541",
                "rarity_score": 316.0987724489521,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/541.png",
            "scheme": "https"
        }
    },
    "3061": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3061",
                "description": "description",
                "dna": "7565dd60c7fea4a7d69c292a68efbc82333c3967",
                "date": 1683445110458,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3061",
                "rarity_score": 191.69125207238255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3061.png",
            "scheme": "https"
        }
    },
    "3133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3133",
                "description": "description",
                "dna": "b1a0f6fd84c7777d3caaa3b6d4f8531d2363032c",
                "date": 1683433551628,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3133",
                "rarity_score": 162.0282681089514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3133.png",
            "scheme": "https"
        }
    },
    "3071": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3071",
                "description": "description",
                "dna": "d612f5c1b506df4d60fb3fec9de6105d8a798cf5",
                "date": 1683432190238,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3071",
                "rarity_score": 172.73698187683482,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3071.png",
            "scheme": "https"
        }
    },
    "3494": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3494",
                "description": "description",
                "dna": "f933a22037c96edc2b894dd95e74c12172287163",
                "date": 1683444046618,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3494",
                "rarity_score": 213.27277267862397,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3494.png",
            "scheme": "https"
        }
    },
    "1416": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1416",
                "description": "description",
                "dna": "4648a0845fbc6afadbf87b95cd99391760f13267",
                "date": 1683775039523,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1416",
                "rarity_score": 242.37992625266446,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1416.png",
            "scheme": "https"
        }
    },
    "459": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #459",
                "description": "description",
                "dna": "2f12232cae327432434eafa952d84cab3d3ebf31",
                "date": 1683437718669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "459",
                "rarity_score": 160.99433595942804,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/459.png",
            "scheme": "https"
        }
    },
    "1400": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1400",
                "description": "description",
                "dna": "78d3258a976f1fc9c8f638c66b46ed87d003a96b",
                "date": 1683432738919,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1400",
                "rarity_score": 169.32821824404704,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1400.png",
            "scheme": "https"
        }
    },
    "842": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #842",
                "description": "description",
                "dna": "5433b36568d3b42d17a7d86113509099f78da6aa",
                "date": 1683439111979,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "842",
                "rarity_score": 176.1589539781377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/842.png",
            "scheme": "https"
        }
    },
    "1347": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1347",
                "description": "description",
                "dna": "ec5d4538b8d898c03669ff63209b6cefab47f72c",
                "date": 1683431278424,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1347",
                "rarity_score": 168.51973939690387,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1347.png",
            "scheme": "https"
        }
    },
    "1441": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1441",
                "description": "description",
                "dna": "a9292371b6995ea75421ee97cc091c00935d97c6",
                "date": 1683446530290,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1441",
                "rarity_score": 152.97377458143015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1441.png",
            "scheme": "https"
        }
    },
    "3050": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3050",
                "description": "description",
                "dna": "05079b1dab976f779be05e0b0ca29874812d6ca8",
                "date": 1683442848612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3050",
                "rarity_score": 173.5549032901228,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3050.png",
            "scheme": "https"
        }
    },
    "3274": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3274",
                "description": "description",
                "dna": "69608a9de1944495f645ed56247e465bc832c8df",
                "date": 1683429375796,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3274",
                "rarity_score": 214.34561047779715,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3274.png",
            "scheme": "https"
        }
    },
    "3705": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3705",
                "description": "description",
                "dna": "ca0429b8036a9ea1cfc24332272c707420ed0e6f",
                "date": 1683439587616,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3705",
                "rarity_score": 149.4771268225819,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3705.png",
            "scheme": "https"
        }
    },
    "158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #158",
                "description": "description",
                "dna": "ddc6cd216bd1ba66f8a671d9203d4e9603ef798a",
                "date": 1683436700994,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "158",
                "rarity_score": 389.0222735527829,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/158.png",
            "scheme": "https"
        }
    },
    "762": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #762",
                "description": "description",
                "dna": "00882266429899b86223b21fd5a1616a8671bf99",
                "date": 1683442239667,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "762",
                "rarity_score": 185.9430515032655,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/762.png",
            "scheme": "https"
        }
    },
    "3430": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3430",
                "description": "description",
                "dna": "0cd0776891082efceb1d0d363d5b53ce7506c1f2",
                "date": 1683439896238,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3430",
                "rarity_score": 148.84428850490423,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3430.png",
            "scheme": "https"
        }
    },
    "3441": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3441",
                "description": "description",
                "dna": "0596699d6a93810371d4041a7dcc40541ba2b7ae",
                "date": 1683438649888,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3441",
                "rarity_score": 252.78343774588538,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3441.png",
            "scheme": "https"
        }
    },
    "3022": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3022",
                "description": "description",
                "dna": "1b538d14a8cdbc6250f4be0ad4a39cda3976fe43",
                "date": 1683776788277,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3022",
                "rarity_score": 191.85595872917366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3022.png",
            "scheme": "https"
        }
    },
    "2759": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2759",
                "description": "description",
                "dna": "48e89e9905ae2297375f0df63912942897a8acd2",
                "date": 1683432369942,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2759",
                "rarity_score": 160.02744202498505,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2759.png",
            "scheme": "https"
        }
    },
    "2630": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2630",
                "description": "description",
                "dna": "9d1404365291e0fd55a828b60472d686139bcb37",
                "date": 1683439992626,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2630",
                "rarity_score": 149.79327886250857,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2630.png",
            "scheme": "https"
        }
    },
    "143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #143",
                "description": "description",
                "dna": "0f3eb79f8c306934a8fd7f4aac3a53df5e66e582",
                "date": 1683446368224,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "143",
                "rarity_score": 174.79258167261662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/143.png",
            "scheme": "https"
        }
    },
    "3311": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3311",
                "description": "description",
                "dna": "24033cd920d97e7d201f3e6e32b3ac3c37a868d0",
                "date": 1683433281906,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3311",
                "rarity_score": 205.2502735491779,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3311.png",
            "scheme": "https"
        }
    },
    "2377": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2377",
                "description": "description",
                "dna": "3554be907fd043e51da8cbf62d7f45c253503c65",
                "date": 1683438201331,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2377",
                "rarity_score": 181.06391726214775,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2377.png",
            "scheme": "https"
        }
    },
    "6": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #6",
                "description": "description",
                "dna": "449b491e31fb6ae89e58eea62c757fa3247d92ce",
                "date": 1683447107343,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "6",
                "rarity_score": 171.05511608739448,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/6.png",
            "scheme": "https"
        }
    },
    "611": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #611",
                "description": "description",
                "dna": "56452599fc9b63f32d8f3129982ddbfc9f78d8f2",
                "date": 1683432511375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "611",
                "rarity_score": 256.03764337266676,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/611.png",
            "scheme": "https"
        }
    },
    "1602": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1602",
                "description": "description",
                "dna": "43811d8d373f0fd6d4d907151d71800793186d39",
                "date": 1683433180956,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1602",
                "rarity_score": 247.07311420503714,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1602.png",
            "scheme": "https"
        }
    },
    "363": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #363",
                "description": "description",
                "dna": "b03fe3c8fcee397b7195b95eceb55bcd40b8d7da",
                "date": 1683446573375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "363",
                "rarity_score": 167.58074698511544,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/363.png",
            "scheme": "https"
        }
    },
    "3288": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3288",
                "description": "description",
                "dna": "4cf63164f00c034bc0d398a83b038e9a179320e1",
                "date": 1683437602318,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3288",
                "rarity_score": 127.02625557586758,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3288.png",
            "scheme": "https"
        }
    },
    "3059": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3059",
                "description": "description",
                "dna": "813f2b6b7b9b09d827f28ee982800bce9dcddf89",
                "date": 1683439232587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3059",
                "rarity_score": 170.35349632963332,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3059.png",
            "scheme": "https"
        }
    },
    "580": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #580",
                "description": "description",
                "dna": "455932f52a5967e5b93e88ecde361b22737f19c3",
                "date": 1683434079074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "580",
                "rarity_score": 210.79196245845054,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/580.png",
            "scheme": "https"
        }
    },
    "1391": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1391",
                "description": "description",
                "dna": "fc9e568d371adec96dbb4be8b4d82b3d1e207b3a",
                "date": 1683432046643,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1391",
                "rarity_score": 154.58393044382362,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1391.png",
            "scheme": "https"
        }
    },
    "910": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #910",
                "description": "description",
                "dna": "76949c13664ca177c02c014259a010b4cfebdf07",
                "date": 1683445807450,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "910",
                "rarity_score": 205.5803859230658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/910.png",
            "scheme": "https"
        }
    },
    "884": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #884",
                "description": "description",
                "dna": "8f5c55c2fd0d99852f9ce0804453ad2d0f2478c2",
                "date": 1683774815797,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "884",
                "rarity_score": 165.02204728248324,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/884.png",
            "scheme": "https"
        }
    },
    "3912": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3912",
                "description": "description",
                "dna": "e2a554f7b99591b51c066bef007cf6926b23b1c6",
                "date": 1683517644039,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Z Shield",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3912",
                "rarity_score": 4318.100016979406,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3912.png",
            "scheme": "https"
        }
    },
    "2668": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2668",
                "description": "description",
                "dna": "898bd8c7221aeae67d7015bfb384c130e27e99c5",
                "date": 1683433944523,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2668",
                "rarity_score": 184.22362128985907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2668.png",
            "scheme": "https"
        }
    },
    "1934": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1934",
                "description": "description",
                "dna": "e82fdda006e8cc75a8dbc92293e800d8f269b2fa",
                "date": 1683430261021,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1934",
                "rarity_score": 154.05059410038402,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1934.png",
            "scheme": "https"
        }
    },
    "1792": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1792",
                "description": "description",
                "dna": "74a0cc5354338e3d19a7fdbbac08f0e3afdc603e",
                "date": 1683436639627,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1792",
                "rarity_score": 190.33320219619878,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1792.png",
            "scheme": "https"
        }
    },
    "1342": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1342",
                "description": "description",
                "dna": "299d3a6bc3020a311a4804d0c982d1f2ed2c39c7",
                "date": 1683776681098,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1342",
                "rarity_score": 212.32678898143902,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1342.png",
            "scheme": "https"
        }
    },
    "1705": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1705",
                "description": "description",
                "dna": "7541690df097ac45dcd2cd7e0f4d43da9c65fea2",
                "date": 1683776540621,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1705",
                "rarity_score": 203.82877352006858,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1705.png",
            "scheme": "https"
        }
    },
    "3547": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3547",
                "description": "description",
                "dna": "6848235ac08eeed0b4fa8f8007cf1687e97be860",
                "date": 1683776698383,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3547",
                "rarity_score": 180.83697710914376,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3547.png",
            "scheme": "https"
        }
    },
    "1006": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1006",
                "description": "description",
                "dna": "f7190a8cd6b87a60e98fc9d5b33b52a45f75c82c",
                "date": 1683437748135,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1006",
                "rarity_score": 164.2747973470155,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1006.png",
            "scheme": "https"
        }
    },
    "1269": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1269",
                "description": "description",
                "dna": "41b2c697d044c387013f34c1fa05a0b2bf1bb9bf",
                "date": 1683431234538,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1269",
                "rarity_score": 321.59304858552093,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1269.png",
            "scheme": "https"
        }
    },
    "2898": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2898",
                "description": "description",
                "dna": "15eaf417f3789f24520ac5e9048f803058ebd8de",
                "date": 1683435828037,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2898",
                "rarity_score": 214.34191911373944,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2898.png",
            "scheme": "https"
        }
    },
    "2056": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2056",
                "description": "description",
                "dna": "51adc92c68165050231b01d6c03e8bbf78e4ac16",
                "date": 1683436705481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2056",
                "rarity_score": 218.12577268195443,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2056.png",
            "scheme": "https"
        }
    },
    "1584": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1584",
                "description": "description",
                "dna": "cf4a65543e28a6a5e1647773eb15b2edb41d5724",
                "date": 1683771298987,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1584",
                "rarity_score": 238.89916148122612,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1584.png",
            "scheme": "https"
        }
    },
    "2672": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2672",
                "description": "description",
                "dna": "3e381d0be5233d2a41921b374a4b8b488089e521",
                "date": 1683771569602,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2672",
                "rarity_score": 184.87282006530015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2672.png",
            "scheme": "https"
        }
    },
    "3636": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3636",
                "description": "description",
                "dna": "f64e795d14ce86325304c53dbda97dd4c810c750",
                "date": 1683444730441,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3636",
                "rarity_score": 189.33339989173732,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3636.png",
            "scheme": "https"
        }
    },
    "55": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #55",
                "description": "description",
                "dna": "19582517503287b3091f3c75a36681f600767b89",
                "date": 1683444440844,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "55",
                "rarity_score": 409.87010671584477,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/55.png",
            "scheme": "https"
        }
    },
    "1220": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1220",
                "description": "description",
                "dna": "908799c81f598ffd46fa1415b1ec555da8146fd1",
                "date": 1683434827758,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1220",
                "rarity_score": 176.60130278269557,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1220.png",
            "scheme": "https"
        }
    },
    "2301": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2301",
                "description": "description",
                "dna": "dd8e82c69bac752ecb35e84430103013bfcefe2d",
                "date": 1683770639424,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2301",
                "rarity_score": 221.9139556843829,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2301.png",
            "scheme": "https"
        }
    },
    "1603": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1603",
                "description": "description",
                "dna": "dc720acfd38ca195180a0c00c252e7e6d7b80860",
                "date": 1683445749771,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1603",
                "rarity_score": 311.9087116705161,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1603.png",
            "scheme": "https"
        }
    },
    "890": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #890",
                "description": "description",
                "dna": "391c90ee0bfef99a4246d8b0ac7b0ec25adf15a2",
                "date": 1683437256331,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "890",
                "rarity_score": 170.5255001678952,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/890.png",
            "scheme": "https"
        }
    },
    "2015": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2015",
                "description": "description",
                "dna": "c28594ce09f48ba66b2eb59f03f8e7e91af845f2",
                "date": 1683445136436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2015",
                "rarity_score": 151.8503933935856,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2015.png",
            "scheme": "https"
        }
    },
    "1961": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1961",
                "description": "description",
                "dna": "33722d83b7bbea580a0be7f222a4e7f88dc96acf",
                "date": 1683436657400,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1961",
                "rarity_score": 242.57659756704092,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1961.png",
            "scheme": "https"
        }
    },
    "1802": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1802",
                "description": "description",
                "dna": "1fd9cbe49328026acd4eee839024c9c2a350e49d",
                "date": 1683433744669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1802",
                "rarity_score": 178.78489102948916,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1802.png",
            "scheme": "https"
        }
    },
    "3525": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3525",
                "description": "description",
                "dna": "f784aba2c641fb8d174ada1287229bac3a8690e9",
                "date": 1683437190587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3525",
                "rarity_score": 123.16558336284683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3525.png",
            "scheme": "https"
        }
    },
    "3080": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3080",
                "description": "description",
                "dna": "b37fa3004f28bf5e326263904f2819c405b3ce24",
                "date": 1683432096919,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3080",
                "rarity_score": 210.88922879119897,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3080.png",
            "scheme": "https"
        }
    },
    "439": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #439",
                "description": "description",
                "dna": "b21eff91a5100b11050f5dde5efe553c8fe885c5",
                "date": 1683436804268,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "439",
                "rarity_score": 150.61675388865035,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/439.png",
            "scheme": "https"
        }
    },
    "2311": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2311",
                "description": "description",
                "dna": "049a28386673acaa1a2c42f8a8cd62b55a5dec5f",
                "date": 1683430397859,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2311",
                "rarity_score": 153.39543194392073,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2311.png",
            "scheme": "https"
        }
    },
    "3501": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3501",
                "description": "description",
                "dna": "2c51ef4db669938a34b8229942c2c7669cc61e3e",
                "date": 1683444533793,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3501",
                "rarity_score": 228.0539349837963,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3501.png",
            "scheme": "https"
        }
    },
    "3718": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3718",
                "description": "description",
                "dna": "fdcd5e3369208d93f579d6e107ffd6bd379b643b",
                "date": 1683447078026,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3718",
                "rarity_score": 250.51941041703998,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3718.png",
            "scheme": "https"
        }
    },
    "3523": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3523",
                "description": "description",
                "dna": "6caef9b1e198e18f65f3b70f9cd2fb4f4f74f558",
                "date": 1683437055194,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3523",
                "rarity_score": 117.71887987408974,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3523.png",
            "scheme": "https"
        }
    },
    "1095": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1095",
                "description": "description",
                "dna": "9ae444b380d53249e266916d3f832b6d9ebfa825",
                "date": 1683430852076,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1095",
                "rarity_score": 149.5028177748018,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1095.png",
            "scheme": "https"
        }
    },
    "649": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #649",
                "description": "description",
                "dna": "af87a5cc6e381641b28afdf8bf627f828ee87ef1",
                "date": 1683771133135,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "649",
                "rarity_score": 195.94147584086343,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/649.png",
            "scheme": "https"
        }
    },
    "2459": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2459",
                "description": "description",
                "dna": "66d7a507a6941ea4d1160e628fe56fc37b6af886",
                "date": 1683438669495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2459",
                "rarity_score": 170.30397142647467,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2459.png",
            "scheme": "https"
        }
    },
    "2944": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2944",
                "description": "description",
                "dna": "075b4e20aa44d03976dfe4e71e2c9126d2f07abf",
                "date": 1683444321375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2944",
                "rarity_score": 201.59949417935715,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2944.png",
            "scheme": "https"
        }
    },
    "2855": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2855",
                "description": "description",
                "dna": "06bab0f2eee093311618bea68460615446cb6f8c",
                "date": 1683441404422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2855",
                "rarity_score": 214.32312573071692,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2855.png",
            "scheme": "https"
        }
    },
    "1640": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1640",
                "description": "description",
                "dna": "31a30d3a8be4b9e8ede77b654be0b816406eae4e",
                "date": 1683438340375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1640",
                "rarity_score": 247.29141370909588,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1640.png",
            "scheme": "https"
        }
    },
    "3173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3173",
                "description": "description",
                "dna": "bb4e614ab87fc81d133584999f64602b2a36dc5b",
                "date": 1683443560068,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3173",
                "rarity_score": 252.70170121304056,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3173.png",
            "scheme": "https"
        }
    },
    "625": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #625",
                "description": "description",
                "dna": "bf6b8a7be8ec27564df86bb7e3f4b8498398a19e",
                "date": 1683437796554,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "625",
                "rarity_score": 151.40228301502046,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/625.png",
            "scheme": "https"
        }
    },
    "619": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #619",
                "description": "description",
                "dna": "500be5d2b22f6507c9b8724f2b2bbaa3bb80e7f7",
                "date": 1683447069573,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "619",
                "rarity_score": 226.53173991689607,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/619.png",
            "scheme": "https"
        }
    },
    "921": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #921",
                "description": "description",
                "dna": "60a6c854526fbeed537f59b1803d7f72fec5d911",
                "date": 1683446328386,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "921",
                "rarity_score": 266.15715327238195,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/921.png",
            "scheme": "https"
        }
    },
    "2481": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2481",
                "description": "description",
                "dna": "7bcb69f81d5286c1da2dbe75909a7be936cb612f",
                "date": 1683443459287,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2481",
                "rarity_score": 156.4777067384476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2481.png",
            "scheme": "https"
        }
    },
    "2988": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2988",
                "description": "description",
                "dna": "30b07d7f321dc3af547710c6d2ea3f8bd70fefac",
                "date": 1683437805014,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2988",
                "rarity_score": 156.3538580212894,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2988.png",
            "scheme": "https"
        }
    },
    "271": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #271",
                "description": "description",
                "dna": "c27fbe2ce9d413da332fc7a1a15a40bc9e2be6ab",
                "date": 1683445216731,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "271",
                "rarity_score": 157.74745173927886,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/271.png",
            "scheme": "https"
        }
    },
    "595": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #595",
                "description": "description",
                "dna": "b575eb3af02a8ef0d4fb70fdc19cb3c951087cf3",
                "date": 1683446607537,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "595",
                "rarity_score": 197.85903112671656,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/595.png",
            "scheme": "https"
        }
    },
    "3139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3139",
                "description": "description",
                "dna": "23f1f72e3bce94382e46820e6ebb80112be1d7ea",
                "date": 1683433803079,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3139",
                "rarity_score": 259.3859510901924,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3139.png",
            "scheme": "https"
        }
    },
    "3442": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3442",
                "description": "description",
                "dna": "d4b970c933f3339eec1a75edabc33825ca18c1b8",
                "date": 1683439924457,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3442",
                "rarity_score": 201.98229877744393,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3442.png",
            "scheme": "https"
        }
    },
    "2885": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2885",
                "description": "description",
                "dna": "4cb651580c6f1b8d96fa93a0c7b84501d0bdab92",
                "date": 1683446628962,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2885",
                "rarity_score": 183.4319646859812,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2885.png",
            "scheme": "https"
        }
    },
    "718": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #718",
                "description": "description",
                "dna": "ec05dc81c14966bb0cf6621263b0ad4bd136fd4b",
                "date": 1683430093787,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "718",
                "rarity_score": 210.15417368372408,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/718.png",
            "scheme": "https"
        }
    },
    "379": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #379",
                "description": "description",
                "dna": "b6078c9544af761eb3bd14c7c37444d66f8b1409",
                "date": 1683441992472,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "379",
                "rarity_score": 200.41151227680191,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/379.png",
            "scheme": "https"
        }
    },
    "401": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #401",
                "description": "description",
                "dna": "de66674906f8bc4f9a8242b8f617e3a480e08c6e",
                "date": 1683444136179,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "401",
                "rarity_score": 280.49931970563637,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/401.png",
            "scheme": "https"
        }
    },
    "3162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3162",
                "description": "description",
                "dna": "259c4d3a454b41e32fdb438e46687a4dd0616616",
                "date": 1683446285416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3162",
                "rarity_score": 132.04653930448183,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3162.png",
            "scheme": "https"
        }
    },
    "1518": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1518",
                "description": "description",
                "dna": "c6ca5133948b62c24681a38221e3e4674a62e8d7",
                "date": 1683442526885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1518",
                "rarity_score": 172.43519883118267,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1518.png",
            "scheme": "https"
        }
    },
    "179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #179",
                "description": "description",
                "dna": "f67ed46df685faedbf25c95c4cafd3d216aafca8",
                "date": 1683441673514,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "179",
                "rarity_score": 165.71325572186154,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/179.png",
            "scheme": "https"
        }
    },
    "485": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #485",
                "description": "description",
                "dna": "c21960f9fbbcb6f11d518b5b8397cd48103d7d1a",
                "date": 1683432277816,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "485",
                "rarity_score": 359.8811839055073,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/485.png",
            "scheme": "https"
        }
    },
    "1036": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1036",
                "description": "description",
                "dna": "4cae7d67a1807a7f308c0df9c614adae308649a4",
                "date": 1683441197562,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1036",
                "rarity_score": 183.05434673988992,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1036.png",
            "scheme": "https"
        }
    },
    "1520": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1520",
                "description": "description",
                "dna": "e9ef0dbe6c93675a119a03ee79bd2ac528b34eac",
                "date": 1683776341786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1520",
                "rarity_score": 188.72164534725917,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1520.png",
            "scheme": "https"
        }
    },
    "1170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1170",
                "description": "description",
                "dna": "ca4abc13581fe8bd0e8461ee3dc487dad2d7c837",
                "date": 1683775170946,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1170",
                "rarity_score": 182.74571807109072,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1170.png",
            "scheme": "https"
        }
    },
    "2526": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2526",
                "description": "description",
                "dna": "f3658e4d87dfb10b5c416a2a5cdbf292b2be75ce",
                "date": 1683440886605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2526",
                "rarity_score": 152.94608847853345,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2526.png",
            "scheme": "https"
        }
    },
    "1796": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1796",
                "description": "description",
                "dna": "629af7d6fd6bf0ef2b8ca0bda928dce8d3b314dc",
                "date": 1683438673586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1796",
                "rarity_score": 224.7280887910636,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1796.png",
            "scheme": "https"
        }
    },
    "3882": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3882",
                "description": "description",
                "dna": "082bf2045efa1567f194d78e37599065b4c0e7fc",
                "date": 1683437310618,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3882",
                "rarity_score": 131.02375836175537,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3882.png",
            "scheme": "https"
        }
    },
    "3309": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3309",
                "description": "description",
                "dna": "53fb33525b495eaeed6ece355e6881adc9d482d2",
                "date": 1683443865227,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3309",
                "rarity_score": 225.07727646544333,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3309.png",
            "scheme": "https"
        }
    },
    "3507": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3507",
                "description": "description",
                "dna": "d4d5dd8c59dee12203e10e34211ab317b9fe3ef9",
                "date": 1683435526121,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3507",
                "rarity_score": 266.54731237948727,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3507.png",
            "scheme": "https"
        }
    },
    "2677": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2677",
                "description": "description",
                "dna": "1db7ec9cf7f054e16338c9cdcb1f990084ef63c4",
                "date": 1683445714032,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2677",
                "rarity_score": 191.72678309243165,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2677.png",
            "scheme": "https"
        }
    },
    "785": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #785",
                "description": "description",
                "dna": "91e2b2c7707a2aa6c6322a99d7908cfa62f17328",
                "date": 1683433230740,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "785",
                "rarity_score": 182.67942219289722,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/785.png",
            "scheme": "https"
        }
    },
    "3562": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3562",
                "description": "description",
                "dna": "1ef89d6b2faf0e1506740e5da2d0d8e6671987aa",
                "date": 1683445741304,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3562",
                "rarity_score": 189.61524169014163,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3562.png",
            "scheme": "https"
        }
    },
    "2801": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2801",
                "description": "description",
                "dna": "507c8804fc9ccaa8f42a2efda5817d2f641f55a1",
                "date": 1683438604610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2801",
                "rarity_score": 162.92098963503003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2801.png",
            "scheme": "https"
        }
    },
    "1779": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1779",
                "description": "description",
                "dna": "9eec09d1c64a8dacaa46b20028d00762f785a929",
                "date": 1683438395701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1779",
                "rarity_score": 338.98902871895604,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1779.png",
            "scheme": "https"
        }
    },
    "3407": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3407",
                "description": "description",
                "dna": "88ac8740ae7fa3a5a3c02fba77ca83f72cc0dece",
                "date": 1683776578297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3407",
                "rarity_score": 214.1439951521486,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3407.png",
            "scheme": "https"
        }
    },
    "3764": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3764",
                "description": "description",
                "dna": "e96394a4a6425187e4a05c3b33a0a1e8794ad8b4",
                "date": 1683433896362,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3764",
                "rarity_score": 263.1951901770841,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3764.png",
            "scheme": "https"
        }
    },
    "866": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #866",
                "description": "description",
                "dna": "77c9225aaaba7a8d15cbeae6a671152cfe11e6bc",
                "date": 1683445626254,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "866",
                "rarity_score": 240.47567414971812,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/866.png",
            "scheme": "https"
        }
    },
    "1350": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1350",
                "description": "description",
                "dna": "635ff9d3f1b73b158cef818f80e30b7e4c346c98",
                "date": 1683443900139,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1350",
                "rarity_score": 142.7960575901603,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1350.png",
            "scheme": "https"
        }
    },
    "1950": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1950",
                "description": "description",
                "dna": "744e8ecff5a0fb9e06b0f9c9f5d238061cb24c40",
                "date": 1683433878930,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1950",
                "rarity_score": 161.1273365921048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1950.png",
            "scheme": "https"
        }
    },
    "2511": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2511",
                "description": "description",
                "dna": "fbec0b29ca5e77eedd897cea8109aadccfc7daad",
                "date": 1683441337311,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2511",
                "rarity_score": 163.39836782731118,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2511.png",
            "scheme": "https"
        }
    },
    "3940": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3940",
                "description": "description",
                "dna": "5c501d5bcd69a33b1482c8b65ee909700868e2c2",
                "date": 1683437075643,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3940",
                "rarity_score": 161.48289804143258,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3940.png",
            "scheme": "https"
        }
    },
    "2742": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2742",
                "description": "description",
                "dna": "f5d1213be25309e4baa701d44356fb08d5009a93",
                "date": 1683776620986,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2742",
                "rarity_score": 219.2661511905854,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2742.png",
            "scheme": "https"
        }
    },
    "2488": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2488",
                "description": "description",
                "dna": "78b3c8a8a0a0a422cd8d507f6b4780cd07608c69",
                "date": 1683433597700,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2488",
                "rarity_score": 237.4020494214051,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2488.png",
            "scheme": "https"
        }
    },
    "3254": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3254",
                "description": "description",
                "dna": "630a456bdbbef56bd8108c484e2ea1a79bda2dcc",
                "date": 1683774542631,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3254",
                "rarity_score": 206.14017152677332,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3254.png",
            "scheme": "https"
        }
    },
    "1689": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1689",
                "description": "description",
                "dna": "f7e1529ab73f9fbbda4a41917187c2a5adfeb895",
                "date": 1683430856401,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1689",
                "rarity_score": 202.42734260741767,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1689.png",
            "scheme": "https"
        }
    },
    "175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #175",
                "description": "description",
                "dna": "a02d2e6bc4f14e4d2d58686ec6fdbfd6644c2166",
                "date": 1683438210587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "175",
                "rarity_score": 321.4900117193125,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/175.png",
            "scheme": "https"
        }
    },
    "2808": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2808",
                "description": "description",
                "dna": "215226458e8eca2731679962c68c79f496b140a1",
                "date": 1683438586528,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2808",
                "rarity_score": 212.188146856338,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2808.png",
            "scheme": "https"
        }
    },
    "3509": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3509",
                "description": "description",
                "dna": "6b03bd464ce8623ae5cb2e6ae7ef1b00bb528784",
                "date": 1683437215254,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3509",
                "rarity_score": 274.7335784811282,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3509.png",
            "scheme": "https"
        }
    },
    "2624": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2624",
                "description": "description",
                "dna": "2697f732f48132a3c71ac0f7ae478abed3adb797",
                "date": 1683442091414,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2624",
                "rarity_score": 281.89636192992856,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2624.png",
            "scheme": "https"
        }
    },
    "3188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3188",
                "description": "description",
                "dna": "ab2697ace5941d9cb407c3fadcd879b7019bf631",
                "date": 1683445469353,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3188",
                "rarity_score": 212.25365073140114,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3188.png",
            "scheme": "https"
        }
    },
    "3966": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3966",
                "description": "description",
                "dna": "10a78d844fceb0d711af76c191fdb0b24db3f7b3",
                "date": 1683435873110,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3966",
                "rarity_score": 182.7684477183617,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3966.png",
            "scheme": "https"
        }
    },
    "2418": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2418",
                "description": "description",
                "dna": "6bc69d158ccdfe7832f580f00611ef3b110d030e",
                "date": 1683444955944,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2418",
                "rarity_score": 265.91214658452225,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2418.png",
            "scheme": "https"
        }
    },
    "3682": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3682",
                "description": "description",
                "dna": "ca194653baacf8706eeeb8c080902451f42696d4",
                "date": 1683439938113,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3682",
                "rarity_score": 245.46526591735892,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3682.png",
            "scheme": "https"
        }
    },
    "715": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #715",
                "description": "description",
                "dna": "1b3325d081bfb835b0e5aa8370dae9062ca4fd7d",
                "date": 1683445325687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "715",
                "rarity_score": 237.563931188355,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/715.png",
            "scheme": "https"
        }
    },
    "4077": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4077",
                "description": "description",
                "dna": "8566d0491d5ff3d4f70f8124b64ed53c7399899b",
                "date": 1683445119765,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "4077",
                "rarity_score": 187.19333346939987,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4077.png",
            "scheme": "https"
        }
    },
    "3914": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3914",
                "description": "description",
                "dna": "834aa823b817293146f3e1b44aa154f90684b4bc",
                "date": 1683433366680,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3914",
                "rarity_score": 197.94526036958223,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3914.png",
            "scheme": "https"
        }
    },
    "617": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #617",
                "description": "description",
                "dna": "dd8ad7be01b97fb9ff072a807af2dc5a72745c87",
                "date": 1683436330641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "617",
                "rarity_score": 169.8457814215755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/617.png",
            "scheme": "https"
        }
    },
    "2747": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2747",
                "description": "description",
                "dna": "326ca624c9d030b781fe8390137c23ffb7111288",
                "date": 1683436961730,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2747",
                "rarity_score": 393.1584142440604,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2747.png",
            "scheme": "https"
        }
    },
    "4085": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4085",
                "description": "description",
                "dna": "6fb61ffbb7076f38952e4e07435d256d643e80f8",
                "date": 1683776654745,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "4085",
                "rarity_score": 222.26770066664096,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4085.png",
            "scheme": "https"
        }
    },
    "3510": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3510",
                "description": "description",
                "dna": "7003b60a63ef4c5b362c6c3094281b8f2706797c",
                "date": 1683774645945,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3510",
                "rarity_score": 192.45479063283545,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3510.png",
            "scheme": "https"
        }
    },
    "1611": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1611",
                "description": "description",
                "dna": "95df58a3a95aeaad18bb2737d3fd8ed1e18fde91",
                "date": 1683444772639,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1611",
                "rarity_score": 167.26507596618507,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1611.png",
            "scheme": "https"
        }
    },
    "1353": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1353",
                "description": "description",
                "dna": "8328f4dd291d813a6e6ede790565b1285a0e9bca",
                "date": 1683443106440,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1353",
                "rarity_score": 208.3238674242043,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1353.png",
            "scheme": "https"
        }
    },
    "2816": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2816",
                "description": "description",
                "dna": "496d25c12c8c808f59fcc3f1f129f63a9098b24d",
                "date": 1683774806666,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2816",
                "rarity_score": 303.9314464120334,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2816.png",
            "scheme": "https"
        }
    },
    "167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #167",
                "description": "description",
                "dna": "ebfd035b89365b4e1b42657e5a42d4b7abc1942f",
                "date": 1683429759768,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "167",
                "rarity_score": 235.0424033841469,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/167.png",
            "scheme": "https"
        }
    },
    "3979": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3979",
                "description": "description",
                "dna": "80f5511a98ae0fb4983a796994b51bd52d0795d9",
                "date": 1683438986203,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3979",
                "rarity_score": 228.4660656624343,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3979.png",
            "scheme": "https"
        }
    },
    "3995": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3995",
                "description": "description",
                "dna": "d938e056a42cb4eaa8da024ce2dc7e6faf6b7ba8",
                "date": 1683443954657,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3995",
                "rarity_score": 191.19098963648662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3995.png",
            "scheme": "https"
        }
    },
    "2706": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2706",
                "description": "description",
                "dna": "880783cb02d07200096d20d882882b3cbfdaed4a",
                "date": 1683443691571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2706",
                "rarity_score": 292.9831361743317,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2706.png",
            "scheme": "https"
        }
    },
    "3208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3208",
                "description": "description",
                "dna": "4fcc69f855effc004c419377c95c8ca344270282",
                "date": 1683433448395,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3208",
                "rarity_score": 225.39400443806568,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3208.png",
            "scheme": "https"
        }
    },
    "782": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #782",
                "description": "description",
                "dna": "7f9baf85da9e9f790e376e0470c1e2f1e2e2c7ff",
                "date": 1683431999915,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "782",
                "rarity_score": 203.45249425452766,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/782.png",
            "scheme": "https"
        }
    },
    "193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #193",
                "description": "description",
                "dna": "cdf7288eb1d2f6b7f56929c33cfc6abeb93f171a",
                "date": 1683443936695,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "193",
                "rarity_score": 154.4688215733331,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/193.png",
            "scheme": "https"
        }
    },
    "2883": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2883",
                "description": "description",
                "dna": "621e716caeeac4d4cdba895ff48d072a35db7c38",
                "date": 1683436487307,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2883",
                "rarity_score": 202.28948115898936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2883.png",
            "scheme": "https"
        }
    },
    "1428": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1428",
                "description": "description",
                "dna": "4da79646eca52a9f6bcfcfdfd914768e8e6a1b36",
                "date": 1683442463124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1428",
                "rarity_score": 171.71122375755994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1428.png",
            "scheme": "https"
        }
    },
    "800": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #800",
                "description": "description",
                "dna": "bdef3e62a3902c2b135b4992b683c7e060e7d040",
                "date": 1683447272751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "800",
                "rarity_score": 195.64072156702278,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/800.png",
            "scheme": "https"
        }
    },
    "4067": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4067",
                "description": "description",
                "dna": "e1a3b5eb0cf71a74287700cce697a4d389afe7ce",
                "date": 1683433970829,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "4067",
                "rarity_score": 220.73012441040964,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4067.png",
            "scheme": "https"
        }
    },
    "2931": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2931",
                "description": "description",
                "dna": "28f3a20e162dceaa9e35a00d0dcfb78a44b21b0a",
                "date": 1683436820710,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2931",
                "rarity_score": 164.70850815822845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2931.png",
            "scheme": "https"
        }
    },
    "2666": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2666",
                "description": "description",
                "dna": "27749be926fce95cc9ada2d1bbea24407ae50d95",
                "date": 1683432069398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2666",
                "rarity_score": 185.08954577264598,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2666.png",
            "scheme": "https"
        }
    },
    "2518": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2518",
                "description": "description",
                "dna": "3bf5d6d3554ed6083c0fba3fddf0047eaff57cd5",
                "date": 1683447241245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2518",
                "rarity_score": 199.17830016145416,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2518.png",
            "scheme": "https"
        }
    },
    "1143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1143",
                "description": "description",
                "dna": "039a68579cb0d8a040877c0a575fc97b854061ec",
                "date": 1683444850252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1143",
                "rarity_score": 330.40564084578307,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1143.png",
            "scheme": "https"
        }
    },
    "2251": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2251",
                "description": "description",
                "dna": "48f58bbd15fcea805dff45e5735da14827be763c",
                "date": 1683431092614,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2251",
                "rarity_score": 165.15061677733473,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2251.png",
            "scheme": "https"
        }
    },
    "2861": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2861",
                "description": "description",
                "dna": "632d7eb112f2ef4ecda6620c3bf3902e1c352f47",
                "date": 1683430980408,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2861",
                "rarity_score": 208.23030517840573,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2861.png",
            "scheme": "https"
        }
    },
    "1111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1111",
                "description": "description",
                "dna": "ac0414752adf721e32a628114e6bfe0bebd97069",
                "date": 1683439442870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1111",
                "rarity_score": 171.8554014412563,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1111.png",
            "scheme": "https"
        }
    },
    "1669": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1669",
                "description": "description",
                "dna": "35893a7db92a4c2d71b4c2f693e9328a4422dbcc",
                "date": 1683439000227,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1669",
                "rarity_score": 181.43018177387705,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1669.png",
            "scheme": "https"
        }
    },
    "3770": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3770",
                "description": "description",
                "dna": "19f4c502e917658228da7e86955d655cd4f70371",
                "date": 1683445573122,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3770",
                "rarity_score": 212.56582101366803,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3770.png",
            "scheme": "https"
        }
    },
    "1475": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1475",
                "description": "description",
                "dna": "26c7e78b9fb1652ce551a01fd06193d70558d370",
                "date": 1683441646012,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1475",
                "rarity_score": 186.42270087929023,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1475.png",
            "scheme": "https"
        }
    },
    "1717": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1717",
                "description": "description",
                "dna": "fed9913826215829bb0a703465a69dcf9015755c",
                "date": 1683442323412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1717",
                "rarity_score": 154.75969449806956,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1717.png",
            "scheme": "https"
        }
    },
    "303": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #303",
                "description": "description",
                "dna": "2ef4771bada8662bb5fd8b4a28fdd0a39a90ac65",
                "date": 1683775175402,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "303",
                "rarity_score": 205.13355588211522,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/303.png",
            "scheme": "https"
        }
    },
    "1219": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1219",
                "description": "description",
                "dna": "c306df42da845f6a47d5aba32e716fc2380c0bd2",
                "date": 1683770552028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1219",
                "rarity_score": 249.26261229474284,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1219.png",
            "scheme": "https"
        }
    },
    "2303": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2303",
                "description": "description",
                "dna": "03604c35d6199080f5c2e8f14fd4bfccf531f04a",
                "date": 1683771425232,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2303",
                "rarity_score": 217.12170794192718,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2303.png",
            "scheme": "https"
        }
    },
    "404": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #404",
                "description": "description",
                "dna": "fe6efd3da6907056a44ccdb99a9b5207c3730f36",
                "date": 1683432160335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "404",
                "rarity_score": 258.95542714180533,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/404.png",
            "scheme": "https"
        }
    },
    "2352": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2352",
                "description": "description",
                "dna": "4a13c087fcc2fab60bc27d05f5e6ed2f9a37980d",
                "date": 1683776745187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2352",
                "rarity_score": 233.25110774751053,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2352.png",
            "scheme": "https"
        }
    },
    "1109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1109",
                "description": "description",
                "dna": "51047b50e79ecab11535707bfa74fda071e36c83",
                "date": 1683441655380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1109",
                "rarity_score": 144.92299839528798,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1109.png",
            "scheme": "https"
        }
    },
    "2042": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2042",
                "description": "description",
                "dna": "ecb2a6d7a509ca423511d103850008633f36d988",
                "date": 1683435493744,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2042",
                "rarity_score": 348.8427293715937,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2042.png",
            "scheme": "https"
        }
    },
    "2648": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2648",
                "description": "description",
                "dna": "30de9557602a98564b62cef494a369aa93b992ba",
                "date": 1683443007307,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2648",
                "rarity_score": 151.1589840003809,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2648.png",
            "scheme": "https"
        }
    },
    "1884": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1884",
                "description": "description",
                "dna": "7639575a7c5f3881bc413b05ec962b5d81477e18",
                "date": 1683441444503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1884",
                "rarity_score": 181.5520441614587,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1884.png",
            "scheme": "https"
        }
    },
    "2840": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2840",
                "description": "description",
                "dna": "cf0627577471ee089f703ab290a70f566db189ae",
                "date": 1683771446030,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2840",
                "rarity_score": 190.47594290000703,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2840.png",
            "scheme": "https"
        }
    },
    "2712": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2712",
                "description": "description",
                "dna": "4b64f3a1facf215d6ebc4be7c63562c5615125b6",
                "date": 1683437022482,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2712",
                "rarity_score": 129.7836430216903,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2712.png",
            "scheme": "https"
        }
    },
    "1938": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1938",
                "description": "description",
                "dna": "f28675b97e2b961b2f7bc98d2737730f6436ae3d",
                "date": 1683445604166,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1938",
                "rarity_score": 181.2897142310146,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1938.png",
            "scheme": "https"
        }
    },
    "3341": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3341",
                "description": "description",
                "dna": "71ba2f26c0c6d0a00d0a944818bbcffebd0bb390",
                "date": 1683446918777,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3341",
                "rarity_score": 155.37464503869435,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3341.png",
            "scheme": "https"
        }
    },
    "91": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #91",
                "description": "description",
                "dna": "42bdc32824d2118f91380b437c53ec1e22a813dd",
                "date": 1683435037951,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "91",
                "rarity_score": 250.14349227593797,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/91.png",
            "scheme": "https"
        }
    },
    "1665": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1665",
                "description": "description",
                "dna": "791dd2793cd1d75eeeb465f313bc811554a9df47",
                "date": 1683774664386,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1665",
                "rarity_score": 225.04621195985112,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1665.png",
            "scheme": "https"
        }
    },
    "3042": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3042",
                "description": "description",
                "dna": "84449ff8150caca175c82afebf53840703197dcc",
                "date": 1683684137565,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand (Prosthetic)",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Special",
                        "value": "Prosthetic",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3042",
                "rarity_score": 8339.934188129444,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3042.png",
            "scheme": "https"
        }
    },
    "633": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #633",
                "description": "description",
                "dna": "27cb474a4734a18dc3e84ca1f942f6a155b2448a",
                "date": 1683774650613,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "633",
                "rarity_score": 156.50947804724402,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/633.png",
            "scheme": "https"
        }
    },
    "2279": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2279",
                "description": "description",
                "dna": "32ab5b71fcc12437a741fce6d6f08e54c58dc27b",
                "date": 1683436946416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2279",
                "rarity_score": 137.54002208777413,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2279.png",
            "scheme": "https"
        }
    },
    "3121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3121",
                "description": "description",
                "dna": "bf84b290839a75e571cb7e54d19840bbce6fe3a7",
                "date": 1683435392820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3121",
                "rarity_score": 191.67490504515354,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3121.png",
            "scheme": "https"
        }
    },
    "2318": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2318",
                "description": "description",
                "dna": "0d79ce0c8cc4b5323db8435da7a983e4543fb66a",
                "date": 1683442748529,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2318",
                "rarity_score": 211.21108659069168,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2318.png",
            "scheme": "https"
        }
    },
    "2632": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2632",
                "description": "description",
                "dna": "165daaf41bf3aa6ec0c80fb7e002c7f4dede0bab",
                "date": 1683442961233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2632",
                "rarity_score": 213.75351300872842,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2632.png",
            "scheme": "https"
        }
    },
    "2646": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2646",
                "description": "description",
                "dna": "2fc55ea880f1d680474a1c73518238a5f5815888",
                "date": 1683443335310,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2646",
                "rarity_score": 193.8895089669891,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2646.png",
            "scheme": "https"
        }
    },
    "1245": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1245",
                "description": "description",
                "dna": "dbfda1cd7086ec41b71c8b7b7cd9ed9f1c581d30",
                "date": 1683430178279,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1245",
                "rarity_score": 298.4711980368571,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1245.png",
            "scheme": "https"
        }
    },
    "2431": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2431",
                "description": "description",
                "dna": "58d54cd59a7b1ff6b95d5e345e900768b69e9ade",
                "date": 1683774659894,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2431",
                "rarity_score": 167.8560816992615,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2431.png",
            "scheme": "https"
        }
    },
    "1951": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1951",
                "description": "description",
                "dna": "7c0793c3903d53f2f000799a95dd5e4b7abd79d3",
                "date": 1683443128968,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1951",
                "rarity_score": 191.28629830882096,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1951.png",
            "scheme": "https"
        }
    },
    "137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #137",
                "description": "description",
                "dna": "ad46063bd2f89fb2982429ecadcc68d5be0437b9",
                "date": 1683432064974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "137",
                "rarity_score": 370.6982078142799,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/137.png",
            "scheme": "https"
        }
    },
    "155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #155",
                "description": "description",
                "dna": "d4ebde66efc4969f1ccdc9e72bdb1410bf86f2e4",
                "date": 1683430583895,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "155",
                "rarity_score": 270.2412237347038,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/155.png",
            "scheme": "https"
        }
    },
    "1323": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1323",
                "description": "description",
                "dna": "d24e9de42d327063ca56cb096d9d346c5b88753f",
                "date": 1683445639442,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1323",
                "rarity_score": 301.0317097355346,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1323.png",
            "scheme": "https"
        }
    },
    "42": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #42",
                "description": "description",
                "dna": "29a897c1e3e63bddf5fe99eaa9ab787336ddab1d",
                "date": 1683508674906,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "42",
                "rarity_score": 392.59309746066424,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/42.png",
            "scheme": "https"
        }
    },
    "3896": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3896",
                "description": "description",
                "dna": "48f9dcbe585106fd9bfb860e3f0c23f58d024ac4",
                "date": 1683440769003,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3896",
                "rarity_score": 198.6683695748921,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3896.png",
            "scheme": "https"
        }
    },
    "2992": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2992",
                "description": "description",
                "dna": "d3a0849e7524e73fdef41a1e275a47c5cfd09a4c",
                "date": 1683442095583,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2992",
                "rarity_score": 165.22134977579785,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2992.png",
            "scheme": "https"
        }
    },
    "2248": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2248",
                "description": "description",
                "dna": "c4905871385d7046c2c81b8c1577a1692f99723d",
                "date": 1683443757352,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2248",
                "rarity_score": 163.0624636855367,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2248.png",
            "scheme": "https"
        }
    },
    "727": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #727",
                "description": "description",
                "dna": "abd123525eb8e6b8ef64f84eccf32037686b7222",
                "date": 1683776720200,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "727",
                "rarity_score": 181.49495598540216,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/727.png",
            "scheme": "https"
        }
    },
    "2826": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2826",
                "description": "description",
                "dna": "8c29593825d2a74a04b84881c7da2a0170548dfa",
                "date": 1683430901885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2826",
                "rarity_score": 180.80903199166158,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2826.png",
            "scheme": "https"
        }
    },
    "2686": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2686",
                "description": "description",
                "dna": "54f822773fd3e1525ef98a357fb3d7722ac874b8",
                "date": 1683445581954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2686",
                "rarity_score": 184.87674695220676,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2686.png",
            "scheme": "https"
        }
    },
    "3614": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3614",
                "description": "description",
                "dna": "d0c80bdf1ef3045ab91d2f1daf389eaac32bf17d",
                "date": 1683429715244,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3614",
                "rarity_score": 164.51206530032775,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3614.png",
            "scheme": "https"
        }
    },
    "3740": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3740",
                "description": "description",
                "dna": "b1cf305b70e29fb33cf5474d1d8082cbc9096c1b",
                "date": 1683776728902,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3740",
                "rarity_score": 202.06441874971836,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3740.png",
            "scheme": "https"
        }
    },
    "60": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #60",
                "description": "description",
                "dna": "2a7506ac39a9515ae7c074a36819e2a46643883b",
                "date": 1683444033786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "60",
                "rarity_score": 240.3209399635702,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/60.png",
            "scheme": "https"
        }
    },
    "1666": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1666",
                "description": "description",
                "dna": "9733b4cea1c16107e4104e9e35d9eccc4670e6b1",
                "date": 1683443177550,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1666",
                "rarity_score": 160.90818465059377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1666.png",
            "scheme": "https"
        }
    },
    "2537": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2537",
                "description": "description",
                "dna": "67423b51d1d22a149b4093438d04abfc886839ed",
                "date": 1683442952236,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2537",
                "rarity_score": 169.51473295620252,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2537.png",
            "scheme": "https"
        }
    },
    "2079": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2079",
                "description": "description",
                "dna": "49a8952fe49b04b1e3e80e3c6ece9340438ea07d",
                "date": 1683429865386,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2079",
                "rarity_score": 302.519039190022,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2079.png",
            "scheme": "https"
        }
    },
    "1513": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1513",
                "description": "description",
                "dna": "c4babb5bd3f025a832794844b3ec820bf548b712",
                "date": 1683433208449,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1513",
                "rarity_score": 192.7609854141441,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1513.png",
            "scheme": "https"
        }
    },
    "2498": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2498",
                "description": "description",
                "dna": "ae1bfd53c95ccb7fad2ec5cb5ed771c3800ff205",
                "date": 1683431504998,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2498",
                "rarity_score": 200.16793529316055,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2498.png",
            "scheme": "https"
        }
    },
    "1155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1155",
                "description": "description",
                "dna": "e823102d439279ac893e7ac04fc010c506b2ad4e",
                "date": 1683439882767,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1155",
                "rarity_score": 176.63385605396874,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1155.png",
            "scheme": "https"
        }
    },
    "2222": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2222",
                "description": "description",
                "dna": "86c0c5245552ffc5d7c23e56ec9e16bddc13023a",
                "date": 1683442776439,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2222",
                "rarity_score": 208.0002817520733,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2222.png",
            "scheme": "https"
        }
    },
    "2059": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2059",
                "description": "description",
                "dna": "ed26befc566a79179cdd3fa5319466af6ae01831",
                "date": 1683444832969,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2059",
                "rarity_score": 239.76484677883272,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2059.png",
            "scheme": "https"
        }
    },
    "4072": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4072",
                "description": "description",
                "dna": "62aeb29879f5ff4031ace5c44f22e29c22d398c4",
                "date": 1683437289310,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "4072",
                "rarity_score": 168.14326648031943,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4072.png",
            "scheme": "https"
        }
    },
    "164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #164",
                "description": "description",
                "dna": "bc072a27a0ac6d757eac6bfcb069255188917c6b",
                "date": 1683446340275,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "164",
                "rarity_score": 219.460032108913,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/164.png",
            "scheme": "https"
        }
    },
    "150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #150",
                "description": "description",
                "dna": "23e74133aa1a4db863719bf474c8a20d09fff223",
                "date": 1683517649029,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "BTC",
                        "score": 4100.0
                    }
                ],
                "id": "150",
                "rarity_score": 4303.7036134198825,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/150.png",
            "scheme": "https"
        }
    },
    "3537": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3537",
                "description": "description",
                "dna": "02ba82f544190336df377c46bc16aba84dc0b48b",
                "date": 1683446876224,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3537",
                "rarity_score": 245.29152312296966,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3537.png",
            "scheme": "https"
        }
    },
    "4055": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4055",
                "description": "description",
                "dna": "781c50109c8498bf0b9c17581f32f27048acffc2",
                "date": 1683435662279,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "4055",
                "rarity_score": 157.04878858011486,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4055.png",
            "scheme": "https"
        }
    },
    "630": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #630",
                "description": "description",
                "dna": "13e323ee6fe56b4efa7586376ffbdb2ef14feb98",
                "date": 1683436661973,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "630",
                "rarity_score": 245.9984871043878,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/630.png",
            "scheme": "https"
        }
    },
    "3187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3187",
                "description": "description",
                "dna": "0acdfa1117cf8a04da853c2d2f0efbe80c62a861",
                "date": 1683443994032,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3187",
                "rarity_score": 207.6853871928589,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3187.png",
            "scheme": "https"
        }
    },
    "2240": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2240",
                "description": "description",
                "dna": "1bd8f23d2504858809ebda88e4e861f2dcd97f8a",
                "date": 1683774565160,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2240",
                "rarity_score": 168.50479859083904,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2240.png",
            "scheme": "https"
        }
    },
    "429": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #429",
                "description": "description",
                "dna": "da882f096c190e9f2d48b1a71b6035879e3b2517",
                "date": 1683446344243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "429",
                "rarity_score": 158.39885744485832,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/429.png",
            "scheme": "https"
        }
    },
    "2752": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2752",
                "description": "description",
                "dna": "f07b699999cd8fbcae5336dc7660c2727007a1fc",
                "date": 1683432014182,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2752",
                "rarity_score": 185.62160792324136,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2752.png",
            "scheme": "https"
        }
    },
    "3066": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3066",
                "description": "description",
                "dna": "0407c3d51e684aaf7d1e41fd12f1f36f8541ff73",
                "date": 1683440831728,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3066",
                "rarity_score": 175.89462585639976,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3066.png",
            "scheme": "https"
        }
    },
    "121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #121",
                "description": "description",
                "dna": "a4ac387890e8d8ef437f64bee4de753b5d2b9aa0",
                "date": 1683446312692,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "121",
                "rarity_score": 148.99391895580442,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/121.png",
            "scheme": "https"
        }
    },
    "1647": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1647",
                "description": "description",
                "dna": "a29cd0af827d283fcf9feee334d973b4a22b2c6a",
                "date": 1683436816319,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1647",
                "rarity_score": 129.25266697717868,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1647.png",
            "scheme": "https"
        }
    },
    "3459": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3459",
                "description": "description",
                "dna": "79ff1cd6e2ea164cdd2acd96e15bbfe3d1104642",
                "date": 1683439583055,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3459",
                "rarity_score": 201.33163281001848,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3459.png",
            "scheme": "https"
        }
    },
    "644": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #644",
                "description": "description",
                "dna": "85cab5c6f8cb6c3bc2a7e0ae242fd7388292601f",
                "date": 1683776650364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "644",
                "rarity_score": 225.82729431512146,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/644.png",
            "scheme": "https"
        }
    },
    "4037": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4037",
                "description": "description",
                "dna": "cc1bddaa6fcc190e60f67bad6aeff982007e7ba6",
                "date": 1683444880867,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "4037",
                "rarity_score": 198.75822063813055,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4037.png",
            "scheme": "https"
        }
    },
    "2654": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2654",
                "description": "description",
                "dna": "3faceb1638fc33a18f7b8e15b20d70a361d1bc32",
                "date": 1683446320469,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2654",
                "rarity_score": 151.118626001988,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2654.png",
            "scheme": "https"
        }
    },
    "1154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1154",
                "description": "description",
                "dna": "635c1837e3a92b9a9d0aebaefab3993a644cac6a",
                "date": 1683430446568,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1154",
                "rarity_score": 167.5953123658858,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1154.png",
            "scheme": "https"
        }
    },
    "187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #187",
                "description": "description",
                "dna": "f61e304ffbbc6e45131749ac4340344cbcf92702",
                "date": 1683439915409,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "187",
                "rarity_score": 198.44800807297827,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/187.png",
            "scheme": "https"
        }
    },
    "3146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3146",
                "description": "description",
                "dna": "9f0164f38c2cc7fde8686922ace0cd6571de01a9",
                "date": 1683440153058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3146",
                "rarity_score": 203.89008562741785,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3146.png",
            "scheme": "https"
        }
    },
    "1276": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1276",
                "description": "description",
                "dna": "f54d773fe1e8a5b14f7a60604e8012fdc021aa24",
                "date": 1683441257254,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1276",
                "rarity_score": 163.35878788710616,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1276.png",
            "scheme": "https"
        }
    },
    "1252": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1252",
                "description": "description",
                "dna": "b3c232b5f974a224f4cc15ab264af60b93c4b53d",
                "date": 1683436857016,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1252",
                "rarity_score": 141.28331810191446,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1252.png",
            "scheme": "https"
        }
    },
    "53": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #53",
                "description": "description",
                "dna": "3537b5847de3c19daf1ce48b52f284d6bd5fefff",
                "date": 1683444568607,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "53",
                "rarity_score": 155.58257407429966,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/53.png",
            "scheme": "https"
        }
    },
    "2245": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2245",
                "description": "description",
                "dna": "0f86a4c5db6dfbbfc79ce120da7e4624f4d66e3c",
                "date": 1683444126801,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2245",
                "rarity_score": 284.1892662813405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2245.png",
            "scheme": "https"
        }
    },
    "2093": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2093",
                "description": "description",
                "dna": "65941bc915b046b7f11088c49235af2b33d2b6ff",
                "date": 1683444695139,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2093",
                "rarity_score": 176.18946636074153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2093.png",
            "scheme": "https"
        }
    },
    "2189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2189",
                "description": "description",
                "dna": "2f86c3f8c1b3048fcff35496bcd19f2e3a0b311a",
                "date": 1683433263060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2189",
                "rarity_score": 208.0090298611613,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2189.png",
            "scheme": "https"
        }
    },
    "1008": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1008",
                "description": "description",
                "dna": "a958b7ba650eb9e22591c08d8aa86c25a787e460",
                "date": 1683429817452,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1008",
                "rarity_score": 160.28709185757526,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1008.png",
            "scheme": "https"
        }
    },
    "4060": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4060",
                "description": "description",
                "dna": "850fe047d8e19873ad5265334dfd0b1619e5997e",
                "date": 1683432409611,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "4060",
                "rarity_score": 327.90250000484974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4060.png",
            "scheme": "https"
        }
    },
    "1745": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1745",
                "description": "description",
                "dna": "c84ff1caf9ebb893d51fd1ec6e7002362bcd6488",
                "date": 1683434552509,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1745",
                "rarity_score": 215.86330713399084,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1745.png",
            "scheme": "https"
        }
    },
    "824": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #824",
                "description": "description",
                "dna": "f393e83cdf9125e363a94ac00b1b482af953199a",
                "date": 1683441692136,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "824",
                "rarity_score": 160.0368118428068,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/824.png",
            "scheme": "https"
        }
    },
    "2907": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2907",
                "description": "description",
                "dna": "bf965df6ee552faec69ffd352a602d37fa92763e",
                "date": 1683434032685,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2907",
                "rarity_score": 199.18860930457168,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2907.png",
            "scheme": "https"
        }
    },
    "2974": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2974",
                "description": "description",
                "dna": "d772ef6b56f76c253fec78409a413fc55e8eb8a3",
                "date": 1683445856958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2974",
                "rarity_score": 216.84371106215707,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2974.png",
            "scheme": "https"
        }
    },
    "94": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #94",
                "description": "description",
                "dna": "db9b74cd1490c773b5d02b0f36dfb2eab0d341d8",
                "date": 1683430597493,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "94",
                "rarity_score": 312.3000251131108,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/94.png",
            "scheme": "https"
        }
    },
    "14": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #14",
                "description": "description",
                "dna": "915ee1fdb4ccfd9a3f474318b04c00545b193b66",
                "date": 1683445923578,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "14",
                "rarity_score": 151.74204192398338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/14.png",
            "scheme": "https"
        }
    },
    "3163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3163",
                "description": "description",
                "dna": "f809f0f2a3c2e12b2c258e805f1d8e48680b4b57",
                "date": 1683438916937,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3163",
                "rarity_score": 229.1043148125883,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3163.png",
            "scheme": "https"
        }
    },
    "1114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1114",
                "description": "description",
                "dna": "049bbeecbcd836c0c646e9487b2563093abde61e",
                "date": 1683433299200,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1114",
                "rarity_score": 177.51974937379572,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1114.png",
            "scheme": "https"
        }
    },
    "3544": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3544",
                "description": "description",
                "dna": "c9aaefe4ac5584e7da9f61f49926c2698335ab8e",
                "date": 1683439773001,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3544",
                "rarity_score": 194.33134804539145,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3544.png",
            "scheme": "https"
        }
    },
    "99": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #99",
                "description": "description",
                "dna": "8fa98f0379197b3a315db8dd1523164a476422c3",
                "date": 1683437178036,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "99",
                "rarity_score": 214.303636811427,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/99.png",
            "scheme": "https"
        }
    },
    "2620": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2620",
                "description": "description",
                "dna": "af47c33fa21eba6233d8b4555fe5aaffc80343ab",
                "date": 1683443137937,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2620",
                "rarity_score": 167.8777139336892,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2620.png",
            "scheme": "https"
        }
    },
    "1556": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1556",
                "description": "description",
                "dna": "8e70c66a5bceccf8d6d4048debf3383601cdd314",
                "date": 1683443527973,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1556",
                "rarity_score": 253.97152814771707,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1556.png",
            "scheme": "https"
        }
    },
    "1493": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1493",
                "description": "description",
                "dna": "f3413b797cdd65fd361a77d84d6fdfb718028ead",
                "date": 1683446688163,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1493",
                "rarity_score": 145.82613976535123,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1493.png",
            "scheme": "https"
        }
    },
    "1201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1201",
                "description": "description",
                "dna": "5d3937e5e595d7baf6b53a99fbbba76e3e34a159",
                "date": 1683440353572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1201",
                "rarity_score": 213.7935541829343,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1201.png",
            "scheme": "https"
        }
    },
    "2162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2162",
                "description": "description",
                "dna": "ef57929d3eb5546c8317141aec418679479c3ea4",
                "date": 1683776758044,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2162",
                "rarity_score": 247.4526076606236,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2162.png",
            "scheme": "https"
        }
    },
    "2333": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2333",
                "description": "description",
                "dna": "237d3f6a19cd36ea0d1f2b4432127ff5ea1f149f",
                "date": 1683436926716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2333",
                "rarity_score": 143.9867288630161,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2333.png",
            "scheme": "https"
        }
    },
    "2704": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2704",
                "description": "description",
                "dna": "fbb0f91041613672eebe1501958eac982c88085e",
                "date": 1683429331320,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2704",
                "rarity_score": 232.9137294077746,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2704.png",
            "scheme": "https"
        }
    },
    "2711": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2711",
                "description": "description",
                "dna": "250906aa31ed2c385c8c991cfa0a00a859fa672b",
                "date": 1683437421388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2711",
                "rarity_score": 240.9619197335646,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2711.png",
            "scheme": "https"
        }
    },
    "3041": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3041",
                "description": "description",
                "dna": "fe34f41a28b47d6d346efb1827af4baaa04e913d",
                "date": 1683445829874,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3041",
                "rarity_score": 329.3497494174896,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3041.png",
            "scheme": "https"
        }
    },
    "3404": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3404",
                "description": "description",
                "dna": "c0679194002a189224cecd4557d07d61aef7ae94",
                "date": 1683441216889,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3404",
                "rarity_score": 167.022151083104,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3404.png",
            "scheme": "https"
        }
    },
    "3602": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3602",
                "description": "description",
                "dna": "9682bd5e6a74a6ce6c0a8d1f76de7887e8556f37",
                "date": 1683442688566,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3602",
                "rarity_score": 358.01091299968783,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3602.png",
            "scheme": "https"
        }
    },
    "3655": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3655",
                "description": "description",
                "dna": "b9470e83f99e380d753148d3f50f09a57329d796",
                "date": 1683430315287,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3655",
                "rarity_score": 158.29962173199416,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3655.png",
            "scheme": "https"
        }
    },
    "1936": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1936",
                "description": "description",
                "dna": "71fbab07b4f801627f5c24e29929bf60217c7c8e",
                "date": 1683441399724,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1936",
                "rarity_score": 287.72292173223093,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1936.png",
            "scheme": "https"
        }
    },
    "2263": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2263",
                "description": "description",
                "dna": "24f34dc30291e189483f81835b497d75817acbeb",
                "date": 1683446364263,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2263",
                "rarity_score": 185.7262968476337,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2263.png",
            "scheme": "https"
        }
    },
    "3276": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3276",
                "description": "description",
                "dna": "0065e043cdb72df6b66505e2e8b5ef145b39c153",
                "date": 1683435512418,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3276",
                "rarity_score": 286.28753259422086,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3276.png",
            "scheme": "https"
        }
    },
    "2792": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2792",
                "description": "description",
                "dna": "33cfa884221d4f7f57f495a8f9e769575adf3887",
                "date": 1683435420885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2792",
                "rarity_score": 285.75465214944546,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2792.png",
            "scheme": "https"
        }
    },
    "3859": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3859",
                "description": "description",
                "dna": "1f78544a762bf0b4d570575755e43556eaf4680b",
                "date": 1683431335716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3859",
                "rarity_score": 163.11972801250604,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3859.png",
            "scheme": "https"
        }
    },
    "80": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #80",
                "description": "description",
                "dna": "7c6f49a83bdcf8d8ddc828085999f7cbedd8e63a",
                "date": 1683437116727,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "80",
                "rarity_score": 171.19618816469358,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/80.png",
            "scheme": "https"
        }
    },
    "115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #115",
                "description": "description",
                "dna": "d0487083ece2c7f2662d496cff60b83ef94d95fb",
                "date": 1683441768366,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "115",
                "rarity_score": 215.43620807740078,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/115.png",
            "scheme": "https"
        }
    },
    "3105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3105",
                "description": "description",
                "dna": "5278a189beddbaf09b095e8d4a203a5708b0b05f",
                "date": 1683432707446,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3105",
                "rarity_score": 214.8720191535508,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3105.png",
            "scheme": "https"
        }
    },
    "1986": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1986",
                "description": "description",
                "dna": "1f176e13d12d9bede1f72e1781ff4dc39f6af6ea",
                "date": 1683442168993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1986",
                "rarity_score": 165.42201791741445,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1986.png",
            "scheme": "https"
        }
    },
    "3374": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3374",
                "description": "description",
                "dna": "e63a4fd63931b04869b09b44ba5e7d5d9359d547",
                "date": 1683430757495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3374",
                "rarity_score": 197.63095739453834,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3374.png",
            "scheme": "https"
        }
    },
    "2845": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2845",
                "description": "description",
                "dna": "aa9881a67b4c2ee460416ca6a7224e218b843d11",
                "date": 1683444197856,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2845",
                "rarity_score": 248.8984531200703,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2845.png",
            "scheme": "https"
        }
    },
    "1282": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1282",
                "description": "description",
                "dna": "0435a13e82fc8f5e0720b23fd33f35074c3920c4",
                "date": 1683438428351,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1282",
                "rarity_score": 194.56504331466994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1282.png",
            "scheme": "https"
        }
    },
    "3054": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3054",
                "description": "description",
                "dna": "564a268eee4a0b6bbdea6ab6da7c1a370d3adb4e",
                "date": 1683433167820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3054",
                "rarity_score": 166.31599778852518,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3054.png",
            "scheme": "https"
        }
    },
    "2895": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2895",
                "description": "description",
                "dna": "24c357f5a0d2188adfb1d6676e198a078985ded6",
                "date": 1683446499361,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2895",
                "rarity_score": 123.31423945084458,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2895.png",
            "scheme": "https"
        }
    },
    "223": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #223",
                "description": "description",
                "dna": "8cc5d334c42d1b8b6f55d1810fd08ae5cf2ac9f4",
                "date": 1683431687209,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "223",
                "rarity_score": 205.47462546838412,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/223.png",
            "scheme": "https"
        }
    },
    "384": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #384",
                "description": "description",
                "dna": "4f2e4a0e7db1d25847e84e1922409714730610fa",
                "date": 1683441632120,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "384",
                "rarity_score": 166.8399080416449,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/384.png",
            "scheme": "https"
        }
    },
    "683": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #683",
                "description": "description",
                "dna": "1e4482025e98519b0dcdb11a4344f6e198935591",
                "date": 1683446743018,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "683",
                "rarity_score": 153.69019962533483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/683.png",
            "scheme": "https"
        }
    },
    "160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #160",
                "description": "description",
                "dna": "94dee7e81f4eb33d699f01e5bf9021bcc5c930ef",
                "date": 1683439295015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "160",
                "rarity_score": 252.2109650053639,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/160.png",
            "scheme": "https"
        }
    },
    "1839": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1839",
                "description": "description",
                "dna": "53d40410b441543613c3f1ed70258b000f8d22d3",
                "date": 1683445996526,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1839",
                "rarity_score": 153.7162420105607,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1839.png",
            "scheme": "https"
        }
    },
    "1891": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1891",
                "description": "description",
                "dna": "bfe2cb7cf0ff864d574329702507b6ae13325fea",
                "date": 1683445478329,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1891",
                "rarity_score": 163.1288253776205,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1891.png",
            "scheme": "https"
        }
    },
    "3715": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3715",
                "description": "description",
                "dna": "c686bfbdae8bdf07c2fbce0e831888642ef80567",
                "date": 1683434122691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3715",
                "rarity_score": 339.30214900327974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3715.png",
            "scheme": "https"
        }
    },
    "2661": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2661",
                "description": "description",
                "dna": "a410c3776857deba7a6eb1de136dcacff192e716",
                "date": 1683440006454,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2661",
                "rarity_score": 222.303459764628,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2661.png",
            "scheme": "https"
        }
    },
    "236": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #236",
                "description": "description",
                "dna": "2d2a554c4b661e664b0e4d72900d89f3248cbcd0",
                "date": 1683439237162,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "236",
                "rarity_score": 165.3301173115356,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/236.png",
            "scheme": "https"
        }
    },
    "2264": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2264",
                "description": "description",
                "dna": "bd202bbad3dcab5a2c701de648c0a539f4a93fd4",
                "date": 1683433993188,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2264",
                "rarity_score": 318.3263716231393,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2264.png",
            "scheme": "https"
        }
    },
    "2562": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2562",
                "description": "description",
                "dna": "8be4065de825f7a76185d5b6989572167d2cfe37",
                "date": 1683444356683,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2562",
                "rarity_score": 202.67693271441337,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2562.png",
            "scheme": "https"
        }
    },
    "873": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #873",
                "description": "description",
                "dna": "ab0b51bf08bd412ab48f2701b1598b039933112b",
                "date": 1683444620461,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "873",
                "rarity_score": 204.75230794932713,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/873.png",
            "scheme": "https"
        }
    },
    "1655": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1655",
                "description": "description",
                "dna": "5e49edadb2ec2c34470c17f50dbf77e50a9de0cc",
                "date": 1683430687094,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1655",
                "rarity_score": 272.08458169011544,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1655.png",
            "scheme": "https"
        }
    },
    "386": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #386",
                "description": "description",
                "dna": "3b221ad7514e251c9eab1d44dca42fc940332f48",
                "date": 1683447345467,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "386",
                "rarity_score": 221.92293485111097,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/386.png",
            "scheme": "https"
        }
    },
    "2153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2153",
                "description": "description",
                "dna": "79d10f02fb4764b687fe6d17d6ce147db5959e5a",
                "date": 1683434140573,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2153",
                "rarity_score": 201.39392028924806,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2153.png",
            "scheme": "https"
        }
    },
    "916": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #916",
                "description": "description",
                "dna": "ac4660bdb0697b663612ea586438455babebbbe3",
                "date": 1683776762487,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "916",
                "rarity_score": 222.53528602464942,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/916.png",
            "scheme": "https"
        }
    },
    "3604": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3604",
                "description": "description",
                "dna": "b50927ef6df8eca1f74a89fc3141fed8303aeeee",
                "date": 1683443877871,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3604",
                "rarity_score": 214.1845944886503,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3604.png",
            "scheme": "https"
        }
    },
    "3458": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3458",
                "description": "description",
                "dna": "24ef07744687b3a6d8e5736b3688bf83fc83c032",
                "date": 1683446427342,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3458",
                "rarity_score": 150.80909229214046,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3458.png",
            "scheme": "https"
        }
    },
    "1116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1116",
                "description": "description",
                "dna": "534f8852353ec11c07882adb44f00221ffcad27f",
                "date": 1683445834568,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1116",
                "rarity_score": 187.4179593486895,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1116.png",
            "scheme": "https"
        }
    },
    "1514": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1514",
                "description": "description",
                "dna": "16047e2546b5ae2cd834e201d117a8d98bbbae85",
                "date": 1683432991000,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1514",
                "rarity_score": 176.75420497973045,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1514.png",
            "scheme": "https"
        }
    },
    "2964": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2964",
                "description": "description",
                "dna": "99020b8279e26ef595a66d307a4c9e270e138032",
                "date": 1683430361239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2964",
                "rarity_score": 207.4984855529419,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2964.png",
            "scheme": "https"
        }
    },
    "774": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #774",
                "description": "description",
                "dna": "bdcc0041c0cdb7460961b919dd1c4da9455c3024",
                "date": 1683446490985,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "774",
                "rarity_score": 131.72456603072044,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/774.png",
            "scheme": "https"
        }
    },
    "1723": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1723",
                "description": "description",
                "dna": "5c7daae9a0009330e8f5e2aa76bbe39f694471b0",
                "date": 1683774879521,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1723",
                "rarity_score": 246.7072772525714,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1723.png",
            "scheme": "https"
        }
    },
    "3310": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3310",
                "description": "description",
                "dna": "11224555657dace69ebce273dbc2a796e49e4131",
                "date": 1683442374009,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3310",
                "rarity_score": 180.8813648257234,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3310.png",
            "scheme": "https"
        }
    },
    "2741": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2741",
                "description": "description",
                "dna": "0fc3fb2f7acb1773b174da8e1f8fed82ba52d7b3",
                "date": 1683434208671,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2741",
                "rarity_score": 286.17615749663975,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2741.png",
            "scheme": "https"
        }
    },
    "1707": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1707",
                "description": "description",
                "dna": "6b20394e0d65b0fc431ac1d4a8e3d164a4e65404",
                "date": 1683436380299,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1707",
                "rarity_score": 170.87135736872116,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1707.png",
            "scheme": "https"
        }
    },
    "2967": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2967",
                "description": "description",
                "dna": "c51528d9ffab871fab9fd1ac2cf148f05499dcf7",
                "date": 1683432427214,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2967",
                "rarity_score": 166.24381656128278,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2967.png",
            "scheme": "https"
        }
    },
    "1648": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1648",
                "description": "description",
                "dna": "c21f167e850ad4301510336499f54607ea88a4e7",
                "date": 1683432794945,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1648",
                "rarity_score": 237.69649079745926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1648.png",
            "scheme": "https"
        }
    },
    "3081": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3081",
                "description": "description",
                "dna": "e91bd77753798b57b58db8bb5c23bf8d63bf4dc7",
                "date": 1683431652299,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3081",
                "rarity_score": 359.4583261422928,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3081.png",
            "scheme": "https"
        }
    },
    "1284": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1284",
                "description": "description",
                "dna": "acf661f0ad7af842b4407fd5c4e9b8250e040ee5",
                "date": 1683430450964,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1284",
                "rarity_score": 190.04137145965566,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1284.png",
            "scheme": "https"
        }
    },
    "2137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2137",
                "description": "description",
                "dna": "4c413f1b8f92fdf30c05e77f8aac9f9b30f3bc7b",
                "date": 1683443856337,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2137",
                "rarity_score": 166.3311052095377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2137.png",
            "scheme": "https"
        }
    },
    "623": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #623",
                "description": "description",
                "dna": "6772eb34024ed23f8c66c600b50e64dff5caeb18",
                "date": 1683442657287,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "623",
                "rarity_score": 214.5688386524276,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/623.png",
            "scheme": "https"
        }
    },
    "1782": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1782",
                "description": "description",
                "dna": "bfcc18a4a01b1709a213fba707c9426a5ba4e993",
                "date": 1683447429724,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1782",
                "rarity_score": 189.7243719351378,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1782.png",
            "scheme": "https"
        }
    },
    "3118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3118",
                "description": "description",
                "dna": "2352ee9c7517006bbe850b77097fa1178ad9d905",
                "date": 1683432164726,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "3118",
                "rarity_score": 340.78185036996024,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3118.png",
            "scheme": "https"
        }
    },
    "2952": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2952",
                "description": "description",
                "dna": "abf799fa1535d5e44097dac3d1dfafe945da21c6",
                "date": 1683445049039,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2952",
                "rarity_score": 155.93944670701183,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2952.png",
            "scheme": "https"
        }
    },
    "2425": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2425",
                "description": "description",
                "dna": "130861f323831ae17af2410314e0e5cce20c6b67",
                "date": 1683442512638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2425",
                "rarity_score": 235.4360331142518,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2425.png",
            "scheme": "https"
        }
    },
    "1041": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1041",
                "description": "description",
                "dna": "2c5d4f09893b52bbc52e58061e2ff023dc8f9f18",
                "date": 1683434394164,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1041",
                "rarity_score": 162.9087147339531,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1041.png",
            "scheme": "https"
        }
    },
    "278": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #278",
                "description": "description",
                "dna": "adcaa99a591ca924d46835bb55eb55c71837b054",
                "date": 1683444303863,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "278",
                "rarity_score": 166.18249596814715,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/278.png",
            "scheme": "https"
        }
    },
    "458": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #458",
                "description": "description",
                "dna": "5ad39c200a4fcd50525104f0eb7b8e6d8c26819f",
                "date": 1683429917732,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "458",
                "rarity_score": 156.01473412789343,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/458.png",
            "scheme": "https"
        }
    },
    "3905": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3905",
                "description": "description",
                "dna": "de002e91971c3fc63d4c60522c7dd2f19b16d1f9",
                "date": 1683441596738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3905",
                "rarity_score": 161.3664968159431,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3905.png",
            "scheme": "https"
        }
    },
    "116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #116",
                "description": "description",
                "dna": "317b64fd2f42f4ae986a45f1dc55388108d05315",
                "date": 1683437813483,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "116",
                "rarity_score": 165.73551122061406,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/116.png",
            "scheme": "https"
        }
    },
    "203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #203",
                "description": "description",
                "dna": "ff761893c840f235eb5980013dda1ba517edb1c5",
                "date": 1683774820424,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "203",
                "rarity_score": 191.80862611719508,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/203.png",
            "scheme": "https"
        }
    },
    "3040": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3040",
                "description": "description",
                "dna": "cccb5f335c4f3e4d9584c2799f2e5fc9428a9636",
                "date": 1683771145976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3040",
                "rarity_score": 223.24664097181176,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3040.png",
            "scheme": "https"
        }
    },
    "2701": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2701",
                "description": "description",
                "dna": "67a75cec003d6015814f973e62df20417647828c",
                "date": 1683431408665,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2701",
                "rarity_score": 155.04966227843832,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2701.png",
            "scheme": "https"
        }
    },
    "1927": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1927",
                "description": "description",
                "dna": "6f6f2653fc4f43734c90291421793db6ed274923",
                "date": 1683432259845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1927",
                "rarity_score": 190.49771185011647,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1927.png",
            "scheme": "https"
        }
    },
    "3743": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3743",
                "description": "description",
                "dna": "f38cbe547dc26f8ff8af3ec27704512224ae98a6",
                "date": 1683775161996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3743",
                "rarity_score": 168.58045008841998,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3743.png",
            "scheme": "https"
        }
    },
    "3737": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3737",
                "description": "description",
                "dna": "21566102c4b5796724a3bffbb1c731a2e6bacbe8",
                "date": 1683433065113,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3737",
                "rarity_score": 175.7434746577066,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3737.png",
            "scheme": "https"
        }
    },
    "3169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3169",
                "description": "description",
                "dna": "29ec8bcc164e0f8739e081c7e5bee51bf3c3247f",
                "date": 1683440125018,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3169",
                "rarity_score": 185.09155545362526,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3169.png",
            "scheme": "https"
        }
    },
    "3557": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3557",
                "description": "description",
                "dna": "09a4bcf57e2ba13fe90f90d83bcd551d37d6bc98",
                "date": 1683439777757,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3557",
                "rarity_score": 215.24625699428168,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3557.png",
            "scheme": "https"
        }
    },
    "2659": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2659",
                "description": "description",
                "dna": "5e0a36be98d17f476aac507c363bba05071252cf",
                "date": 1683432194723,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2659",
                "rarity_score": 201.2733659308431,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2659.png",
            "scheme": "https"
        }
    },
    "947": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #947",
                "description": "description",
                "dna": "77106e8cc5feee37808108b0a9b6d48adb5a526b",
                "date": 1683435783364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "947",
                "rarity_score": 179.81138298082325,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/947.png",
            "scheme": "https"
        }
    },
    "3024": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3024",
                "description": "description",
                "dna": "8e3145e00c6200707f8e31da8bb5eeecbc76f984",
                "date": 1683446910012,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3024",
                "rarity_score": 217.59951133743849,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3024.png",
            "scheme": "https"
        }
    },
    "1502": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1502",
                "description": "description",
                "dna": "a93a7ffd9e48d80f4d8966598d4387c62b2826f3",
                "date": 1683433681509,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1502",
                "rarity_score": 203.15820135850333,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1502.png",
            "scheme": "https"
        }
    },
    "1328": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1328",
                "description": "description",
                "dna": "836a3f90d249f2d58d5d1a0e7a6ba6ab52f4bc49",
                "date": 1683436868583,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1328",
                "rarity_score": 222.71620662696287,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1328.png",
            "scheme": "https"
        }
    },
    "2292": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2292",
                "description": "description",
                "dna": "7b3f80e8f3c5fdb2d208074c262860e130a58f73",
                "date": 1683432120256,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2292",
                "rarity_score": 244.37209908827992,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2292.png",
            "scheme": "https"
        }
    },
    "2339": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2339",
                "description": "description",
                "dna": "df95f2f2893fbc93f57609e1c79c2c8b781ed3d2",
                "date": 1683446194481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2339",
                "rarity_score": 151.90489380709664,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2339.png",
            "scheme": "https"
        }
    },
    "3725": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3725",
                "description": "description",
                "dna": "e5e9ee1aa0a7410238db00fcdb20c502eff33ce6",
                "date": 1683443638660,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3725",
                "rarity_score": 154.10942968053604,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3725.png",
            "scheme": "https"
        }
    },
    "632": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #632",
                "description": "description",
                "dna": "a0761e399dfc1bae27ac4ee58f5f800999f84501",
                "date": 1683771582498,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "632",
                "rarity_score": 218.20558965887835,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/632.png",
            "scheme": "https"
        }
    },
    "374": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #374",
                "description": "description",
                "dna": "fd48eafab3c8f424fb403e8d7f1cd2ee78328765",
                "date": 1683438489973,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "374",
                "rarity_score": 177.1446272999521,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/374.png",
            "scheme": "https"
        }
    },
    "4035": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4035",
                "description": "description",
                "dna": "b8eb61977446076d240d50c38e3111701f27f840",
                "date": 1683432959632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "4035",
                "rarity_score": 190.2974082842569,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4035.png",
            "scheme": "https"
        }
    },
    "2528": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2528",
                "description": "description",
                "dna": "266014328abdc52c6330b50e12cb508084abf184",
                "date": 1683446435304,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2528",
                "rarity_score": 170.26090871645994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2528.png",
            "scheme": "https"
        }
    },
    "1182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1182",
                "description": "description",
                "dna": "1215243b9e310a1406516965899d704c8edc4a12",
                "date": 1683441120240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1182",
                "rarity_score": 259.13934516064063,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1182.png",
            "scheme": "https"
        }
    },
    "1653": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1653",
                "description": "description",
                "dna": "2d01aa2db2e66dadd5d20956be965734f434614f",
                "date": 1683436003716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1653",
                "rarity_score": 158.68783238526188,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1653.png",
            "scheme": "https"
        }
    },
    "1576": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1576",
                "description": "description",
                "dna": "181453c4adc4df3edce03dddeba7565b6a7ee384",
                "date": 1683436950114,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1576",
                "rarity_score": 137.8955409972552,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1576.png",
            "scheme": "https"
        }
    },
    "290": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #290",
                "description": "description",
                "dna": "88be97c5dc91a5a3ae929e2d6fed1113c7724bbe",
                "date": 1683437034811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "290",
                "rarity_score": 193.39155094711128,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/290.png",
            "scheme": "https"
        }
    },
    "2378": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2378",
                "description": "description",
                "dna": "479316b6d649c12816a1a29d4e18c619f382d86c",
                "date": 1683776362530,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2378",
                "rarity_score": 250.55214305993792,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2378.png",
            "scheme": "https"
        }
    },
    "3313": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3313",
                "description": "description",
                "dna": "bcf9a10b92d5d2bf2c5793a2a6b9e2a4a10c19da",
                "date": 1683443998788,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3313",
                "rarity_score": 155.30017224658678,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3313.png",
            "scheme": "https"
        }
    },
    "2527": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2527",
                "description": "description",
                "dna": "67e35a9db8d28d990d52787392cc0f8c085c8051",
                "date": 1683430887954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2527",
                "rarity_score": 190.60123773658103,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2527.png",
            "scheme": "https"
        }
    },
    "2940": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2940",
                "description": "description",
                "dna": "c2ff871b6fc0760e09ffe5bc10b703010ce8969e",
                "date": 1683429821810,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2940",
                "rarity_score": 179.7923535132048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2940.png",
            "scheme": "https"
        }
    },
    "1623": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1623",
                "description": "description",
                "dna": "fe744b239af176d9cdde0c829b9d31e4b2fa908e",
                "date": 1683440098237,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1623",
                "rarity_score": 184.89684880484782,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1623.png",
            "scheme": "https"
        }
    },
    "1039": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1039",
                "description": "description",
                "dna": "7a92fb3806e909ad86add2178ab2406bbe1e3421",
                "date": 1683436167322,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1039",
                "rarity_score": 246.8907767518871,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1039.png",
            "scheme": "https"
        }
    },
    "3238": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3238",
                "description": "description",
                "dna": "38cb93f58cd757c632dfe2063be98d0be15da9fe",
                "date": 1683435540388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3238",
                "rarity_score": 240.74921191226042,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3238.png",
            "scheme": "https"
        }
    },
    "3172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3172",
                "description": "description",
                "dna": "23a4cc45c4dd7a7d05681a2e0c52be31c1e23ae9",
                "date": 1683437700623,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3172",
                "rarity_score": 204.41760933678725,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3172.png",
            "scheme": "https"
        }
    },
    "4": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4",
                "description": "description",
                "dna": "aca4d72d7f53eb24fc94e7f91d9a25c725fb7264",
                "date": 1683431542549,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4",
                "rarity_score": 181.45598094657987,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4.png",
            "scheme": "https"
        }
    },
    "1424": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1424",
                "description": "description",
                "dna": "ea6c2d4a27b219e55285f177ef3419ab39d0ac3a",
                "date": 1683771200939,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1424",
                "rarity_score": 200.92340357975053,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1424.png",
            "scheme": "https"
        }
    },
    "2847": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2847",
                "description": "description",
                "dna": "c50a1f82d97bd2f6a1ea1edbb303ccbd33efcdb9",
                "date": 1683443912786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2847",
                "rarity_score": 170.65063055802477,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2847.png",
            "scheme": "https"
        }
    },
    "531": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #531",
                "description": "description",
                "dna": "22b5a99a4d593a393af2d563b4c62216cc792ae5",
                "date": 1683447215328,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "531",
                "rarity_score": 185.95131739855984,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/531.png",
            "scheme": "https"
        }
    },
    "2208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2208",
                "description": "description",
                "dna": "2d558bb4a282ebfa333dfb33d0860b688f565399",
                "date": 1683439951365,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2208",
                "rarity_score": 234.158619179246,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2208.png",
            "scheme": "https"
        }
    },
    "3484": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3484",
                "description": "description",
                "dna": "22351425204d57370327b53d194784314dcc8bca",
                "date": 1683442739805,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3484",
                "rarity_score": 166.2028333868115,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3484.png",
            "scheme": "https"
        }
    },
    "1249": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1249",
                "description": "description",
                "dna": "8caf68c966fdb12983c1965376f8b0f09f5a114f",
                "date": 1683433461373,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1249",
                "rarity_score": 172.7072791535044,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1249.png",
            "scheme": "https"
        }
    },
    "2584": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2584",
                "description": "description",
                "dna": "3634b8b0f3db8d46a148351d0afc8c65aafbd484",
                "date": 1683431630448,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2584",
                "rarity_score": 150.2229293453082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2584.png",
            "scheme": "https"
        }
    },
    "2645": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2645",
                "description": "description",
                "dna": "3f8ecd77ea72a80b7407dd6df23eed9a77922617",
                "date": 1683445820858,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2645",
                "rarity_score": 227.74981069282634,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2645.png",
            "scheme": "https"
        }
    },
    "251": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #251",
                "description": "description",
                "dna": "1e137c5ea1bdd2f03ace290471697792b135301d",
                "date": 1683435200665,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "251",
                "rarity_score": 200.80460840170883,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/251.png",
            "scheme": "https"
        }
    },
    "3389": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3389",
                "description": "description",
                "dna": "5a8e19bec6a03e9f68d031d656e9e96b2fc93ef1",
                "date": 1683431884460,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3389",
                "rarity_score": 182.91873254494755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3389.png",
            "scheme": "https"
        }
    },
    "591": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #591",
                "description": "description",
                "dna": "adf6cae733d09c292b30053595b20991cc2b74ba",
                "date": 1683437108735,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "591",
                "rarity_score": 161.0021837292029,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/591.png",
            "scheme": "https"
        }
    },
    "1575": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1575",
                "description": "description",
                "dna": "0256ffef04857500fb322e99f3010d4cb3ee1ab0",
                "date": 1683435881623,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1575",
                "rarity_score": 225.3162308303194,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1575.png",
            "scheme": "https"
        }
    },
    "3376": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3376",
                "description": "description",
                "dna": "efba599fbba24a3792c4892d8c876f0ddccf58c7",
                "date": 1683775074228,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3376",
                "rarity_score": 249.54719363000046,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3376.png",
            "scheme": "https"
        }
    },
    "874": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #874",
                "description": "description",
                "dna": "07a38309f52c0afdd65a6b59a0192ffb1ec13a58",
                "date": 1683443950296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "874",
                "rarity_score": 229.17779527602153,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/874.png",
            "scheme": "https"
        }
    },
    "2696": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2696",
                "description": "description",
                "dna": "44f9aae0d3a9641b7591468c266cbdd4100e8888",
                "date": 1683435268926,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2696",
                "rarity_score": 145.53731123051713,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2696.png",
            "scheme": "https"
        }
    },
    "891": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #891",
                "description": "description",
                "dna": "c2ce2adeab81a35ca5fe6328de76bae99ae93449",
                "date": 1683436697104,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "891",
                "rarity_score": 248.5654098455724,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/891.png",
            "scheme": "https"
        }
    },
    "1896": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1896",
                "description": "description",
                "dna": "00614be936c9bb855877fb6ba5d7871fae2f597e",
                "date": 1683437691833,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1896",
                "rarity_score": 292.16897287231313,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1896.png",
            "scheme": "https"
        }
    },
    "139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #139",
                "description": "description",
                "dna": "432f5fbf259aac5a835b3feafcc6666fe1d4326b",
                "date": 1683430235014,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "139",
                "rarity_score": 260.71323975025075,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/139.png",
            "scheme": "https"
        }
    },
    "3724": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3724",
                "description": "description",
                "dna": "2370300781ef16da98a65b04ea4a4f0e790e5f77",
                "date": 1683775016225,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3724",
                "rarity_score": 191.84306729153994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3724.png",
            "scheme": "https"
        }
    },
    "2439": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2439",
                "description": "description",
                "dna": "48b1fe62bbeb442cbdead84593484c9a52bb95e6",
                "date": 1683432110625,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2439",
                "rarity_score": 294.19945754688774,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2439.png",
            "scheme": "https"
        }
    },
    "1966": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1966",
                "description": "description",
                "dna": "7d9bf5641b3bd0d522fc780ae8d20649d2e51504",
                "date": 1683438793774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1966",
                "rarity_score": 177.61592568379072,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1966.png",
            "scheme": "https"
        }
    },
    "3893": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3893",
                "description": "description",
                "dna": "89d50bdd6ed30289f56d52af5030c12c05b2fd6a",
                "date": 1683435414898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3893",
                "rarity_score": 245.80915533169957,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3893.png",
            "scheme": "https"
        }
    },
    "3568": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3568",
                "description": "description",
                "dna": "ad786002fa830220b5c6ae8095603693595a63e3",
                "date": 1683770631043,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3568",
                "rarity_score": 237.818975479979,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3568.png",
            "scheme": "https"
        }
    },
    "3761": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3761",
                "description": "description",
                "dna": "c3f095b15ad4a3cf0b8f6a3994bf66e01f106967",
                "date": 1683437482711,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3761",
                "rarity_score": 169.79694609436433,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3761.png",
            "scheme": "https"
        }
    },
    "1874": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1874",
                "description": "description",
                "dna": "30a4bca137328e8a3481f041dc315903916e5a87",
                "date": 1683441627874,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1874",
                "rarity_score": 197.58464667176116,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1874.png",
            "scheme": "https"
        }
    },
    "2763": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2763",
                "description": "description",
                "dna": "027f13af785b460b4d851346d9b7c28bedb35346",
                "date": 1683447254771,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2763",
                "rarity_score": 263.8867090256769,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2763.png",
            "scheme": "https"
        }
    },
    "1881": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1881",
                "description": "description",
                "dna": "82daa229669d63af626150305c7aed2b1b4b6f0a",
                "date": 1683440690234,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1881",
                "rarity_score": 187.8488848245621,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1881.png",
            "scheme": "https"
        }
    },
    "1343": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1343",
                "description": "description",
                "dna": "504d20aa7c74df8331eccc857e097ee40ca0a9d9",
                "date": 1683447331816,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1343",
                "rarity_score": 173.57762014416028,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1343.png",
            "scheme": "https"
        }
    },
    "533": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #533",
                "description": "description",
                "dna": "adbe436a46910316c21df1181f0b5215f96a166d",
                "date": 1683441202572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "533",
                "rarity_score": 188.0292730265075,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/533.png",
            "scheme": "https"
        }
    },
    "424": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #424",
                "description": "description",
                "dna": "e9dc39e6501e2fc5e069ed90e653fca7fc551e65",
                "date": 1683441364017,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "424",
                "rarity_score": 207.06992511589198,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/424.png",
            "scheme": "https"
        }
    },
    "2306": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2306",
                "description": "description",
                "dna": "c885d42495f3642c12eb623cd189cc89e60f5573",
                "date": 1683430460097,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2306",
                "rarity_score": 265.68892601695575,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2306.png",
            "scheme": "https"
        }
    },
    "740": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #740",
                "description": "description",
                "dna": "74d2f333213361719de9a9e225ac30aad952f14a",
                "date": 1683771171140,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "740",
                "rarity_score": 259.4156863551972,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/740.png",
            "scheme": "https"
        }
    },
    "2640": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2640",
                "description": "description",
                "dna": "8b67d6c545588d7d3e818c5ef20c1086a96d5ffd",
                "date": 1683439032060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2640",
                "rarity_score": 181.82891152471296,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2640.png",
            "scheme": "https"
        }
    },
    "2841": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2841",
                "description": "description",
                "dna": "285a1885c31a474bbc1c62a6a77311cb7aeff199",
                "date": 1683433416316,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2841",
                "rarity_score": 317.48832376719145,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2841.png",
            "scheme": "https"
        }
    },
    "3463": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3463",
                "description": "description",
                "dna": "294ee5062956df4bf8fae03c4746b67773267366",
                "date": 1683445409662,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3463",
                "rarity_score": 215.91491521099533,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3463.png",
            "scheme": "https"
        }
    },
    "915": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #915",
                "description": "description",
                "dna": "51777947333a8297e4be76927bf5f65d55820783",
                "date": 1683430993027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "915",
                "rarity_score": 181.3876505591776,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/915.png",
            "scheme": "https"
        }
    },
    "2223": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2223",
                "description": "description",
                "dna": "7e16264f46f65e1f640d5c33cabf94aa635aa181",
                "date": 1683441175071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2223",
                "rarity_score": 212.70696393756134,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2223.png",
            "scheme": "https"
        }
    },
    "1043": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1043",
                "description": "description",
                "dna": "690c776e12575c468ecbf943c3b42467153e8c8f",
                "date": 1683441849757,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1043",
                "rarity_score": 165.52176200095718,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1043.png",
            "scheme": "https"
        }
    },
    "2908": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2908",
                "description": "description",
                "dna": "92d24fe05b6eeedc0cfca476126aa1063faa4e41",
                "date": 1683434988868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2908",
                "rarity_score": 322.6759559882488,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2908.png",
            "scheme": "https"
        }
    },
    "2291": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2291",
                "description": "description",
                "dna": "b9988e33b9d8472639e5ff4da061866c7416b069",
                "date": 1683446553608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2291",
                "rarity_score": 156.11638199441262,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2291.png",
            "scheme": "https"
        }
    },
    "801": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #801",
                "description": "description",
                "dna": "ff562ef1db9f05d8629c31cc057627d3f10695a3",
                "date": 1683446039076,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "801",
                "rarity_score": 164.99215469813282,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/801.png",
            "scheme": "https"
        }
    },
    "2411": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2411",
                "description": "description",
                "dna": "c8213d471f57480b907815b371c653743dc0f9e7",
                "date": 1683445234099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2411",
                "rarity_score": 179.38511706620227,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2411.png",
            "scheme": "https"
        }
    },
    "2238": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2238",
                "description": "description",
                "dna": "9faf606653fdefcb3762cc867017732821957e8d",
                "date": 1683775217956,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2238",
                "rarity_score": 175.80659880656881,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2238.png",
            "scheme": "https"
        }
    },
    "2516": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2516",
                "description": "description",
                "dna": "2a4654e45b85463286bb0ccce3da81185de56bb1",
                "date": 1683435443634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2516",
                "rarity_score": 259.2437021208158,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2516.png",
            "scheme": "https"
        }
    },
    "2850": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2850",
                "description": "description",
                "dna": "148a93310bf4e19654e7fa97292841706973ea9b",
                "date": 1683441759326,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2850",
                "rarity_score": 163.67435570033007,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2850.png",
            "scheme": "https"
        }
    },
    "2536": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2536",
                "description": "description",
                "dna": "ec56f0816c774bbb44c6a7a3c5e80038eeca557e",
                "date": 1683431531930,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2536",
                "rarity_score": 300.40031568402475,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2536.png",
            "scheme": "https"
        }
    },
    "2927": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2927",
                "description": "description",
                "dna": "8985fa85af284f692c2ed83815c80879efbc551c",
                "date": 1683442078385,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2927",
                "rarity_score": 183.12382247126018,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2927.png",
            "scheme": "https"
        }
    },
    "2549": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2549",
                "description": "description",
                "dna": "f23d5e989fdeff026e08f27a9d947652fc3cfdd3",
                "date": 1683440381921,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2549",
                "rarity_score": 208.9137589828764,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2549.png",
            "scheme": "https"
        }
    },
    "2075": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2075",
                "description": "description",
                "dna": "856f123e138e45e2e1f956c6d367bdf34764f69e",
                "date": 1683446800798,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2075",
                "rarity_score": 196.01075008176377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2075.png",
            "scheme": "https"
        }
    },
    "1718": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1718",
                "description": "description",
                "dna": "be1f0f654c70f712c25092f864aca67d7c65eda9",
                "date": 1683430473157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1718",
                "rarity_score": 189.64293408341715,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1718.png",
            "scheme": "https"
        }
    },
    "2601": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2601",
                "description": "description",
                "dna": "6cfe55e286d54009a02200bab581cce561125c4f",
                "date": 1683434190224,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2601",
                "rarity_score": 226.24568352353987,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2601.png",
            "scheme": "https"
        }
    },
    "4026": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4026",
                "description": "description",
                "dna": "ac6e039715d3911c8c09d7eedfd411f9365eda29",
                "date": 1683436828644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "4026",
                "rarity_score": 173.1077182865075,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4026.png",
            "scheme": "https"
        }
    },
    "5": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #5",
                "description": "description",
                "dna": "e23b2424a9e23ec29c3947cfe1057abe24a0c213",
                "date": 1683439367412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "5",
                "rarity_score": 171.84946049770642,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/5.png",
            "scheme": "https"
        }
    },
    "1434": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1434",
                "description": "description",
                "dna": "1f4610460c98689534ae815fc37d7747d0db1861",
                "date": 1683435484425,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1434",
                "rarity_score": 242.98163318451083,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1434.png",
            "scheme": "https"
        }
    },
    "2627": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2627",
                "description": "description",
                "dna": "97fa34086a384559236805ce1df20cd793c8dd07",
                "date": 1683433957928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2627",
                "rarity_score": 244.1123110490302,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2627.png",
            "scheme": "https"
        }
    },
    "1101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1101",
                "description": "description",
                "dna": "a03311480c8571df63691ad2d5ce0a1ca6fe33b7",
                "date": 1683430910692,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1101",
                "rarity_score": 285.8364464919656,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1101.png",
            "scheme": "https"
        }
    },
    "3813": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3813",
                "description": "description",
                "dna": "55f6a49ed9580f323aaceff3438e1b7d806d1797",
                "date": 1683436918655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3813",
                "rarity_score": 153.8832606034048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3813.png",
            "scheme": "https"
        }
    },
    "1621": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1621",
                "description": "description",
                "dna": "efdbd2b5c5268c939ffebf2f7ccb80766f056a40",
                "date": 1683446538286,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1621",
                "rarity_score": 161.5426081070878,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1621.png",
            "scheme": "https"
        }
    },
    "497": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #497",
                "description": "description",
                "dna": "1af83a4d69d8dc5d14e282f278829bf735d016b1",
                "date": 1683436441810,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "497",
                "rarity_score": 244.65078901319777,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/497.png",
            "scheme": "https"
        }
    },
    "722": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #722",
                "description": "description",
                "dna": "99c555a11ff0443a6f3e81498d22439f44beba0c",
                "date": 1683775052722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "722",
                "rarity_score": 208.93180303277964,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/722.png",
            "scheme": "https"
        }
    },
    "3468": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3468",
                "description": "description",
                "dna": "302dc5f5fdaa164b7d2fe22a18d8aa41312576a2",
                "date": 1683442499000,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3468",
                "rarity_score": 193.401398432445,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3468.png",
            "scheme": "https"
        }
    },
    "3971": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3971",
                "description": "description",
                "dna": "3b39d3d0ae756f650f7d2d6f67b367afa6f2106b",
                "date": 1683435945358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3971",
                "rarity_score": 232.1871677761227,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3971.png",
            "scheme": "https"
        }
    },
    "3969": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3969",
                "description": "description",
                "dna": "8fb53af808e439b7b23bba17830d69f0c7dffd0a",
                "date": 1683443869121,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3969",
                "rarity_score": 203.92344178345738,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3969.png",
            "scheme": "https"
        }
    },
    "261": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #261",
                "description": "description",
                "dna": "80303df278e91f2ee1cdff44c8ce110c40dc0072",
                "date": 1683436407380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "261",
                "rarity_score": 174.84199601511764,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/261.png",
            "scheme": "https"
        }
    },
    "3155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3155",
                "description": "description",
                "dna": "2215f51b3b9df51de8abe28a58e537c30d0281a5",
                "date": 1683431795443,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3155",
                "rarity_score": 259.5706496009602,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3155.png",
            "scheme": "https"
        }
    },
    "781": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #781",
                "description": "description",
                "dna": "e2e65731d794e8f35383a3ec136b01e6cf904f59",
                "date": 1683444550350,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "781",
                "rarity_score": 202.8919244698887,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/781.png",
            "scheme": "https"
        }
    },
    "2342": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2342",
                "description": "description",
                "dna": "aa64e68eabae9a9b7654b14f09514fc7951b784a",
                "date": 1683517659055,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Yin Yang",
                        "score": 4100.0
                    }
                ],
                "id": "2342",
                "rarity_score": 4243.46751195132,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2342.png",
            "scheme": "https"
        }
    },
    "3697": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3697",
                "description": "description",
                "dna": "2f2d58c5c32bb2551f1ebfa64d5431b503f5fd2f",
                "date": 1683431287814,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3697",
                "rarity_score": 258.8258521236125,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3697.png",
            "scheme": "https"
        }
    },
    "2538": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2538",
                "description": "description",
                "dna": "f33a2159ce49a5e5e772801fa79eb6f441aeb734",
                "date": 1683440015503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2538",
                "rarity_score": 189.31798762271413,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2538.png",
            "scheme": "https"
        }
    },
    "2560": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2560",
                "description": "description",
                "dna": "23ca40cb1f5655470c089f2f5bce4139469a4c7d",
                "date": 1683439520818,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2560",
                "rarity_score": 252.99566853718125,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2560.png",
            "scheme": "https"
        }
    },
    "310": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #310",
                "description": "description",
                "dna": "0469272f43c74f86afde96ea5f18d6f1838acda7",
                "date": 1683443886974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "310",
                "rarity_score": 209.80002527395703,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/310.png",
            "scheme": "https"
        }
    },
    "3369": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3369",
                "description": "description",
                "dna": "e6d2ccba9394684e71218fd2d6a677a07c42a9f9",
                "date": 1683431391051,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3369",
                "rarity_score": 186.685756417466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3369.png",
            "scheme": "https"
        }
    },
    "3988": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3988",
                "description": "description",
                "dna": "da05f0d2a0bd75d034780d2706cd02866c61bc80",
                "date": 1683434438975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3988",
                "rarity_score": 204.16900559148812,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3988.png",
            "scheme": "https"
        }
    },
    "1507": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1507",
                "description": "description",
                "dna": "d55c0d550f48b751ba741286cc34e3e254c9f383",
                "date": 1683774435241,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1507",
                "rarity_score": 215.78433993806408,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1507.png",
            "scheme": "https"
        }
    },
    "2915": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2915",
                "description": "description",
                "dna": "c68459c65359d404613033ff801da2a470253df1",
                "date": 1683445167594,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2915",
                "rarity_score": 173.98030134093818,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2915.png",
            "scheme": "https"
        }
    },
    "1589": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1589",
                "description": "description",
                "dna": "2d281dfb9b3111f1244b186e0328dfc017632e93",
                "date": 1683438774655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1589",
                "rarity_score": 163.4339865429917,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1589.png",
            "scheme": "https"
        }
    },
    "2858": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2858",
                "description": "description",
                "dna": "88d1a991803b4bc67541f865f3bd54d88325290a",
                "date": 1683440033197,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2858",
                "rarity_score": 144.81467968347627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2858.png",
            "scheme": "https"
        }
    },
    "3239": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3239",
                "description": "description",
                "dna": "9ca0a1972d36acd4294de3d9e49b2b32ee420a52",
                "date": 1683436044411,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3239",
                "rarity_score": 228.2449961488287,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3239.png",
            "scheme": "https"
        }
    },
    "3130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3130",
                "description": "description",
                "dna": "983d7b0bd2b02aa86b329e54ef5d4154851701df",
                "date": 1683429335738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3130",
                "rarity_score": 231.78015316209803,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3130.png",
            "scheme": "https"
        }
    },
    "2071": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2071",
                "description": "description",
                "dna": "b751a639a7d2c341df38d8fa70b2a7e66b5650f9",
                "date": 1683434403046,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2071",
                "rarity_score": 202.9462582940081,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2071.png",
            "scheme": "https"
        }
    },
    "1176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1176",
                "description": "description",
                "dna": "ec25ba5bc063c52a512ccc864545f862710300df",
                "date": 1683436616127,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1176",
                "rarity_score": 317.13652288422986,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1176.png",
            "scheme": "https"
        }
    },
    "1000": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1000",
                "description": "description",
                "dna": "f7340fbd0e9710b99614620de68b202f3c0dbdbf",
                "date": 1683446569233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1000",
                "rarity_score": 197.05639172791984,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1000.png",
            "scheme": "https"
        }
    },
    "196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #196",
                "description": "description",
                "dna": "9f4932c5fefc5aef54b8e19ebd8c8334943d758d",
                "date": 1683438701004,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "196",
                "rarity_score": 215.94153216169707,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/196.png",
            "scheme": "https"
        }
    },
    "118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #118",
                "description": "description",
                "dna": "d13285c4a19b41cb9f579e54666b7b348332d554",
                "date": 1683430265813,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "118",
                "rarity_score": 218.4035162408751,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/118.png",
            "scheme": "https"
        }
    },
    "152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #152",
                "description": "description",
                "dna": "7dc64d15efd9c973c9c2edfd8d0c53996ec4b0b7",
                "date": 1683771454350,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "152",
                "rarity_score": 220.03129404606747,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/152.png",
            "scheme": "https"
        }
    },
    "3675": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3675",
                "description": "description",
                "dna": "e682a159f7ed7791e6283261e04cc578aab77394",
                "date": 1683447175364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3675",
                "rarity_score": 196.70356579092424,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3675.png",
            "scheme": "https"
        }
    },
    "3398": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3398",
                "description": "description",
                "dna": "09806a5d789d2a5513c1960a2e4e5bd5badfb6c2",
                "date": 1683433479328,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3398",
                "rarity_score": 243.53086074801607,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3398.png",
            "scheme": "https"
        }
    },
    "343": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #343",
                "description": "description",
                "dna": "e416d516146ed1719d49ea3640f3561cb9ae5dcf",
                "date": 1683436714699,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "343",
                "rarity_score": 273.1036712242297,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/343.png",
            "scheme": "https"
        }
    },
    "2062": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2062",
                "description": "description",
                "dna": "4bb057adfe45f4b1d1c50d40565c2a84dfbd637d",
                "date": 1683774713521,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2062",
                "rarity_score": 191.81546929782928,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2062.png",
            "scheme": "https"
        }
    },
    "1355": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1355",
                "description": "description",
                "dna": "ee39d3bdb436745cfb5622e4dd59d20daa7f1f25",
                "date": 1683432986360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1355",
                "rarity_score": 260.8770621089783,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1355.png",
            "scheme": "https"
        }
    },
    "3585": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3585",
                "description": "description",
                "dna": "61ba59733a6de499cfb99ca3c2a3aee36c167a6e",
                "date": 1683431759312,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3585",
                "rarity_score": 237.9389083960425,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3585.png",
            "scheme": "https"
        }
    },
    "2380": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2380",
                "description": "description",
                "dna": "05a08a29cd8f2e36a1653a36450bcbfd10de4bc9",
                "date": 1683432291291,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2380",
                "rarity_score": 185.398285848161,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2380.png",
            "scheme": "https"
        }
    },
    "2865": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2865",
                "description": "description",
                "dna": "975e1abc55be117c7009fbe089ab4befcc1c7f31",
                "date": 1683444317202,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2865",
                "rarity_score": 155.95534372569603,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2865.png",
            "scheme": "https"
        }
    },
    "1311": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1311",
                "description": "description",
                "dna": "a662fb314de9ffa40402ac57c6aaaaf89443460b",
                "date": 1683774578941,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1311",
                "rarity_score": 186.57287590798157,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1311.png",
            "scheme": "https"
        }
    },
    "2017": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2017",
                "description": "description",
                "dna": "96b9973cd9da8cc0819b203f3fc453faf23d282b",
                "date": 1683432087104,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2017",
                "rarity_score": 186.75955661347245,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2017.png",
            "scheme": "https"
        }
    },
    "358": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #358",
                "description": "description",
                "dna": "2a5fad2df9cfb32a819d05f112a0c52058750c40",
                "date": 1683445452131,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "358",
                "rarity_score": 183.74569538972992,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/358.png",
            "scheme": "https"
        }
    },
    "375": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #375",
                "description": "description",
                "dna": "d6528ec832de0fe9f3188f8e2f9199545916fbb6",
                "date": 1683445044712,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "375",
                "rarity_score": 161.14866523551547,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/375.png",
            "scheme": "https"
        }
    },
    "3373": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3373",
                "description": "description",
                "dna": "83c383b3955832d19835bbbe064a002cb9f6a730",
                "date": 1683441097939,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3373",
                "rarity_score": 193.58365489869993,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3373.png",
            "scheme": "https"
        }
    },
    "1420": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1420",
                "description": "description",
                "dna": "62190ad7bc9d743c4b6d413eaa5fc348c228aa6a",
                "date": 1683445482257,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1420",
                "rarity_score": 340.7723600635201,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1420.png",
            "scheme": "https"
        }
    },
    "1555": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1555",
                "description": "description",
                "dna": "aa702c453587fff204914ba7e485634f6b3b02b4",
                "date": 1683431722146,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1555",
                "rarity_score": 271.3641450764562,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1555.png",
            "scheme": "https"
        }
    },
    "1481": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1481",
                "description": "description",
                "dna": "23e073fad7c9481bb2ed5c32686d67067016f568",
                "date": 1683445912466,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1481",
                "rarity_score": 120.6947671688383,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1481.png",
            "scheme": "https"
        }
    },
    "1197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1197",
                "description": "description",
                "dna": "043c271cb5cc90e50a53029055adff851bf5bab9",
                "date": 1683437632699,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1197",
                "rarity_score": 181.11804386387547,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1197.png",
            "scheme": "https"
        }
    },
    "3325": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3325",
                "description": "description",
                "dna": "ba081b2aabb17928c2560c71557ff3c982eafd0b",
                "date": 1683435625996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3325",
                "rarity_score": 148.44703256983033,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3325.png",
            "scheme": "https"
        }
    },
    "1786": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1786",
                "description": "description",
                "dna": "faf3a67d883c15b513aa46279e372f1b21dd299e",
                "date": 1683442177949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1786",
                "rarity_score": 169.88613115592898,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1786.png",
            "scheme": "https"
        }
    },
    "1903": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1903",
                "description": "description",
                "dna": "9184e876985c35127b1d0d439ab4ed4cb231b77f",
                "date": 1683439183042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1903",
                "rarity_score": 194.13007173448247,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1903.png",
            "scheme": "https"
        }
    },
    "887": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #887",
                "description": "description",
                "dna": "1b1b712a9693af0538cd121eb4b97a3de39f1aac",
                "date": 1683435110474,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "887",
                "rarity_score": 211.0788416042598,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/887.png",
            "scheme": "https"
        }
    },
    "3836": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3836",
                "description": "description",
                "dna": "31c02352c7853c24814f1ef63b99ea7c0f11037d",
                "date": 1683441525742,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3836",
                "rarity_score": 190.70813923187305,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3836.png",
            "scheme": "https"
        }
    },
    "3898": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3898",
                "description": "description",
                "dna": "acd4106ada120291aab8104382552063d34242a8",
                "date": 1683439699590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3898",
                "rarity_score": 214.34396602791858,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3898.png",
            "scheme": "https"
        }
    },
    "1675": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1675",
                "description": "description",
                "dna": "572d83aca1be5ba85b5d67577818ed42bd76879f",
                "date": 1683436030830,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1675",
                "rarity_score": 171.7970008573392,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1675.png",
            "scheme": "https"
        }
    },
    "3850": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3850",
                "description": "description",
                "dna": "1a543037901aff75621d291aee56a59f34f80c42",
                "date": 1683429548346,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3850",
                "rarity_score": 167.5421704463318,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3850.png",
            "scheme": "https"
        }
    },
    "1034": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1034",
                "description": "description",
                "dna": "a2ff94007c6fcdbbcef0ce37b43d6dde8ad583b9",
                "date": 1683433569872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1034",
                "rarity_score": 153.86388170026405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1034.png",
            "scheme": "https"
        }
    },
    "3674": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3674",
                "description": "description",
                "dna": "7e224a160224b5b3ab9863cebd8ac9e203ee84d3",
                "date": 1683446105173,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3674",
                "rarity_score": 157.8869683892883,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3674.png",
            "scheme": "https"
        }
    },
    "2033": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2033",
                "description": "description",
                "dna": "fb4afece9ff81229e12659050662d0b6dd2ea315",
                "date": 1683430494668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2033",
                "rarity_score": 248.2937942338292,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2033.png",
            "scheme": "https"
        }
    },
    "3049": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3049",
                "description": "description",
                "dna": "dba1e02c2d58d0b27883de0121644b56fc25bbe8",
                "date": 1683775192781,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3049",
                "rarity_score": 185.16873021874903,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3049.png",
            "scheme": "https"
        }
    },
    "1235": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1235",
                "description": "description",
                "dna": "680ea1f7878c0262e1fac8eb7721b5976d595ee0",
                "date": 1683446813546,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1235",
                "rarity_score": 182.98777963861897,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1235.png",
            "scheme": "https"
        }
    },
    "750": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #750",
                "description": "description",
                "dna": "3d08a220554a59fb6746236a7f4f2333437252b1",
                "date": 1683446526603,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "750",
                "rarity_score": 212.42842819301356,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/750.png",
            "scheme": "https"
        }
    },
    "1532": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1532",
                "description": "description",
                "dna": "90d8063076340b6317951f7d2b715f9f80c12f27",
                "date": 1683446838016,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1532",
                "rarity_score": 157.32677706045328,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1532.png",
            "scheme": "https"
        }
    },
    "2473": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2473",
                "description": "description",
                "dna": "4884890b00aff7979d65f481bb4cf9eb8f0e4896",
                "date": 1683771442028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2473",
                "rarity_score": 176.1181037077711,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2473.png",
            "scheme": "https"
        }
    },
    "184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #184",
                "description": "description",
                "dna": "c35caaf4272245c029ca907cc5a5b574a36601fd",
                "date": 1683435845417,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "184",
                "rarity_score": 176.8767778094613,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/184.png",
            "scheme": "https"
        }
    },
    "298": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #298",
                "description": "description",
                "dna": "553c15f13d06c3ec3f13327a6e1bbda81d358ab5",
                "date": 1683447281622,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "298",
                "rarity_score": 178.1391860799693,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/298.png",
            "scheme": "https"
        }
    },
    "2724": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2724",
                "description": "description",
                "dna": "49218bd08a1ee23f6e8efc5011d1238bf688ef11",
                "date": 1683445343701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2724",
                "rarity_score": 230.90174571925036,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2724.png",
            "scheme": "https"
        }
    },
    "2522": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2522",
                "description": "description",
                "dna": "e4d824f9732edc635cd7aa99774e56f799588b1e",
                "date": 1683430384378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2522",
                "rarity_score": 203.0121117798743,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2522.png",
            "scheme": "https"
        }
    },
    "3353": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3353",
                "description": "description",
                "dna": "3632e1a8884075175266fdc388e67942295c63a6",
                "date": 1683440056335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3353",
                "rarity_score": 173.82450302661775,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3353.png",
            "scheme": "https"
        }
    },
    "3538": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3538",
                "description": "description",
                "dna": "161baf9abff765b522fa76078033fc6e61c04cdc",
                "date": 1683437494510,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3538",
                "rarity_score": 241.2798814346233,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3538.png",
            "scheme": "https"
        }
    },
    "1702": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1702",
                "description": "description",
                "dna": "fa3d51bea9e5a8434afcd81b29eccda93c1e1799",
                "date": 1683431028989,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1702",
                "rarity_score": 267.4452962033241,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1702.png",
            "scheme": "https"
        }
    },
    "2419": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2419",
                "description": "description",
                "dna": "fc87f7dcd33874f2cdcf9e95879d33cdb6ea7f52",
                "date": 1683774408398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2419",
                "rarity_score": 183.6337835331711,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2419.png",
            "scheme": "https"
        }
    },
    "234": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #234",
                "description": "description",
                "dna": "438813bc6f5f07d1c7691128a2bea7d7fd33a5fa",
                "date": 1683445035469,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "234",
                "rarity_score": 247.08550506240346,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/234.png",
            "scheme": "https"
        }
    },
    "3165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3165",
                "description": "description",
                "dna": "99d6adce6afe6e58042fd80419718d3bbe3288f9",
                "date": 1683430007924,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3165",
                "rarity_score": 183.06262227982518,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3165.png",
            "scheme": "https"
        }
    },
    "3806": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3806",
                "description": "description",
                "dna": "a409da417521be2d0c8d1a66d69805712afda4e9",
                "date": 1683440042407,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3806",
                "rarity_score": 157.38610508229428,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3806.png",
            "scheme": "https"
        }
    },
    "1433": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1433",
                "description": "description",
                "dna": "294a12012d807465944b7bc5f5c0f484bfce877b",
                "date": 1683443433707,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1433",
                "rarity_score": 213.4338559601821,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1433.png",
            "scheme": "https"
        }
    },
    "2720": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2720",
                "description": "description",
                "dna": "53a2e1138e76ab902cbc7ac633f8c91725aec34c",
                "date": 1683444746962,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2720",
                "rarity_score": 189.5498720227471,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2720.png",
            "scheme": "https"
        }
    },
    "2985": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2985",
                "description": "description",
                "dna": "9082511cb5ae80dcdf19e55f977f36fcfd60b3d1",
                "date": 1683433821254,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2985",
                "rarity_score": 163.97715431468836,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2985.png",
            "scheme": "https"
        }
    },
    "245": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #245",
                "description": "description",
                "dna": "f0f79be005eb05216ea7803dc7ab2261da262357",
                "date": 1683446269644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "245",
                "rarity_score": 117.15617707454366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/245.png",
            "scheme": "https"
        }
    },
    "839": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #839",
                "description": "description",
                "dna": "174f09500299dcf3dfd04c651df409a8e47dec1c",
                "date": 1683444294268,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "839",
                "rarity_score": 203.4919719112248,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/839.png",
            "scheme": "https"
        }
    },
    "3235": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3235",
                "description": "description",
                "dna": "752fb5712ec1f7d7c436338eca53e00dce33e925",
                "date": 1683430301335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3235",
                "rarity_score": 211.67957572550256,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3235.png",
            "scheme": "https"
        }
    },
    "2572": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2572",
                "description": "description",
                "dna": "767cbd0aab31eedd90bd4dff38c00da211448c0a",
                "date": 1683444573332,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2572",
                "rarity_score": 160.91183473276186,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2572.png",
            "scheme": "https"
        }
    },
    "1231": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1231",
                "description": "description",
                "dna": "be393f06fb006ba7b0fc8ecd1a08c2c226922a1d",
                "date": 1683430296617,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1231",
                "rarity_score": 178.23793690148497,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1231.png",
            "scheme": "https"
        }
    },
    "11": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #11",
                "description": "description",
                "dna": "fc3d24e96e86a16f07ed5f8db3cbc2db8eda93a6",
                "date": 1683441359861,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "11",
                "rarity_score": 199.12365320946333,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/11.png",
            "scheme": "https"
        }
    },
    "2613": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2613",
                "description": "description",
                "dna": "56e490ad0d16ae333f49de5b841321f4d50667a9",
                "date": 1683442327501,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2613",
                "rarity_score": 212.95051505020507,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2613.png",
            "scheme": "https"
        }
    },
    "3799": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3799",
                "description": "description",
                "dna": "77e548c09169fbc3534c41cacb4c5d03e35f1524",
                "date": 1683774942432,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3799",
                "rarity_score": 192.5569526609064,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3799.png",
            "scheme": "https"
        }
    },
    "3515": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3515",
                "description": "description",
                "dna": "daffd38f44c5de36b426852542859350358f91e3",
                "date": 1683438752192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3515",
                "rarity_score": 213.03954715992478,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3515.png",
            "scheme": "https"
        }
    },
    "1924": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1924",
                "description": "description",
                "dna": "ab6bc38621342cdda31aaf84af7fde43e7d01659",
                "date": 1683434897826,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1924",
                "rarity_score": 213.84012623211177,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1924.png",
            "scheme": "https"
        }
    },
    "190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #190",
                "description": "description",
                "dna": "af76185629998f0ac53c4e3eca7d123e30565c00",
                "date": 1683770506834,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "190",
                "rarity_score": 240.03996763036378,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/190.png",
            "scheme": "https"
        }
    },
    "3134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3134",
                "description": "description",
                "dna": "c4140d3e3608cbda3b7a793a24c4317480a84eee",
                "date": 1683446981701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3134",
                "rarity_score": 162.54897611317767,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3134.png",
            "scheme": "https"
        }
    },
    "436": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #436",
                "description": "description",
                "dna": "4150b234ae4cdbd1546b8247c67e0a06a2636cec",
                "date": 1683430807666,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "436",
                "rarity_score": 145.33628593521436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/436.png",
            "scheme": "https"
        }
    },
    "2945": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2945",
                "description": "description",
                "dna": "dcc47ed34fd6514bee67893709069b35db3e28bc",
                "date": 1683437104716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2945",
                "rarity_score": 186.4436743581237,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2945.png",
            "scheme": "https"
        }
    },
    "3244": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3244",
                "description": "description",
                "dna": "3e70a0472e3ba1167cfbbe5e0ce26e23558040ca",
                "date": 1683436792490,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3244",
                "rarity_score": 223.0702395239907,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3244.png",
            "scheme": "https"
        }
    },
    "3552": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3552",
                "description": "description",
                "dna": "f424b3efa42066e61f15fc7af3c9b31afe059b29",
                "date": 1683447188166,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3552",
                "rarity_score": 251.18726615140187,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3552.png",
            "scheme": "https"
        }
    },
    "454": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #454",
                "description": "description",
                "dna": "c53b232a018d5a85a792739d983141827a194019",
                "date": 1683771466810,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "454",
                "rarity_score": 268.94709411184505,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/454.png",
            "scheme": "https"
        }
    },
    "716": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #716",
                "description": "description",
                "dna": "a125826b871a455f8318c1455a6142cd0be913a9",
                "date": 1683437743915,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "716",
                "rarity_score": 280.0526847780864,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/716.png",
            "scheme": "https"
        }
    },
    "211": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #211",
                "description": "description",
                "dna": "9c0b65d2a893cdba02925e8dbf3fa39bdbfde50e",
                "date": 1683438742795,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "211",
                "rarity_score": 144.4478873680754,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/211.png",
            "scheme": "https"
        }
    },
    "452": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #452",
                "description": "description",
                "dna": "54a24b2a871a9fa892ee4085249916aa356d6ba3",
                "date": 1683771324059,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "452",
                "rarity_score": 231.4164816956903,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/452.png",
            "scheme": "https"
        }
    },
    "282": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #282",
                "description": "description",
                "dna": "35bb509af984a2fea187807ec84b1c3949ff00b2",
                "date": 1683445577619,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "282",
                "rarity_score": 205.09523696265504,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/282.png",
            "scheme": "https"
        }
    },
    "3661": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3661",
                "description": "description",
                "dna": "8e0966cb12919fc931c716b6b6d6d4438cc9aae5",
                "date": 1683443519229,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3661",
                "rarity_score": 196.04806797597683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3661.png",
            "scheme": "https"
        }
    },
    "1312": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1312",
                "description": "description",
                "dna": "6a0e40886eace23f3130caf6d379faefd664c25f",
                "date": 1683443596611,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1312",
                "rarity_score": 169.24542372203553,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1312.png",
            "scheme": "https"
        }
    },
    "2192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2192",
                "description": "description",
                "dna": "f78325f8926387e6f367b08dbe3d5780f085ca8b",
                "date": 1683433493138,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2192",
                "rarity_score": 222.4201445536359,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2192.png",
            "scheme": "https"
        }
    },
    "3968": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3968",
                "description": "description",
                "dna": "bf4e51c3a1cef08d85339409c08806cc2fd9a70a",
                "date": 1683442099548,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3968",
                "rarity_score": 210.74578649427443,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3968.png",
            "scheme": "https"
        }
    },
    "1279": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1279",
                "description": "description",
                "dna": "64785024f2381fabf6e035b98aae5bfc0fb17215",
                "date": 1683444273112,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1279",
                "rarity_score": 159.55774184267437,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1279.png",
            "scheme": "https"
        }
    },
    "1987": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1987",
                "description": "description",
                "dna": "c6a452c8c8d109e342783818a487712befd91959",
                "date": 1683445031134,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1987",
                "rarity_score": 171.58295756777568,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1987.png",
            "scheme": "https"
        }
    },
    "1614": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1614",
                "description": "description",
                "dna": "92def7712f67175831c7053a6c7dd31b86633bca",
                "date": 1683445634998,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1614",
                "rarity_score": 207.48895408448305,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1614.png",
            "scheme": "https"
        }
    },
    "2918": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2918",
                "description": "description",
                "dna": "2de63a0da3d0a11ad00d2447d61a6c4712a62d2d",
                "date": 1683432453061,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2918",
                "rarity_score": 247.29214300929476,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2918.png",
            "scheme": "https"
        }
    },
    "188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #188",
                "description": "description",
                "dna": "c01c04135c0cf5f361ef45ded256030738595b1b",
                "date": 1683445013782,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "188",
                "rarity_score": 214.93111685756057,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/188.png",
            "scheme": "https"
        }
    },
    "3731": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3731",
                "description": "description",
                "dna": "4034e06f8df0cf5fce92f4cc0f4dccf74b76d091",
                "date": 1683432042244,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3731",
                "rarity_score": 192.46918889717483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3731.png",
            "scheme": "https"
        }
    },
    "3819": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3819",
                "description": "description",
                "dna": "39be3d3542ea2a45829f4976a29e643f94ca3ea3",
                "date": 1683436134999,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3819",
                "rarity_score": 195.11227999095865,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3819.png",
            "scheme": "https"
        }
    },
    "3543": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3543",
                "description": "description",
                "dna": "9a698975498dd7d893e22d49c862128d27bf0a70",
                "date": 1683439058947,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3543",
                "rarity_score": 160.76468929412624,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3543.png",
            "scheme": "https"
        }
    },
    "2718": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2718",
                "description": "description",
                "dna": "5a12638e8e66266381e8d0a3295a1d48c81d19bb",
                "date": 1683432233398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2718",
                "rarity_score": 222.42931730908978,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2718.png",
            "scheme": "https"
        }
    },
    "2821": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2821",
                "description": "description",
                "dna": "0d4f8a98fcbf7869b783c59c1284dffd1b5e22ad",
                "date": 1683438582035,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2821",
                "rarity_score": 183.98141094366017,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2821.png",
            "scheme": "https"
        }
    },
    "3719": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3719",
                "description": "description",
                "dna": "9dcebd4f133dfc0ed072566b8371b2c5abf960f9",
                "date": 1683435836480,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3719",
                "rarity_score": 204.97199120751725,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3719.png",
            "scheme": "https"
        }
    },
    "3114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3114",
                "description": "description",
                "dna": "bf94d6de6083da9f27ba17c8e019b5ab6d572d0e",
                "date": 1683439827890,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3114",
                "rarity_score": 211.07171483580612,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3114.png",
            "scheme": "https"
        }
    },
    "1947": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1947",
                "description": "description",
                "dna": "acb1271371229b62b93fee84fd04935d46ae93f3",
                "date": 1683430140493,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1947",
                "rarity_score": 205.94634826973012,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1947.png",
            "scheme": "https"
        }
    },
    "1415": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1415",
                "description": "description",
                "dna": "d78e9c68f7ef06cab22dc5b9cbfae5882a270b0f",
                "date": 1683775117770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1415",
                "rarity_score": 159.84030273761837,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1415.png",
            "scheme": "https"
        }
    },
    "2050": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2050",
                "description": "description",
                "dna": "12c5862ac2fd930b5e77bb7250489df6d947d98d",
                "date": 1683439434455,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2050",
                "rarity_score": 190.47838490103908,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2050.png",
            "scheme": "https"
        }
    },
    "430": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #430",
                "description": "description",
                "dna": "ac4d217ce16eb72ec1b55d10492259d5dae44569",
                "date": 1683432594446,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "430",
                "rarity_score": 207.9236746823873,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/430.png",
            "scheme": "https"
        }
    },
    "3621": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3621",
                "description": "description",
                "dna": "b64b8bd631baa0507a81e67a037ef18c1d39123e",
                "date": 1683438335451,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3621",
                "rarity_score": 301.8682028764773,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3621.png",
            "scheme": "https"
        }
    },
    "2969": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2969",
                "description": "description",
                "dna": "a9cfc83afd76af6fa08a9804c7d12c4f528c6a85",
                "date": 1683439192137,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2969",
                "rarity_score": 184.06781909729975,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2969.png",
            "scheme": "https"
        }
    },
    "2714": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2714",
                "description": "description",
                "dna": "db5faee6d4584595d3f164913d61b5b65b4f6289",
                "date": 1683770503028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2714",
                "rarity_score": 228.99240768442883,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2714.png",
            "scheme": "https"
        }
    },
    "2643": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2643",
                "description": "description",
                "dna": "7e839ee03c21b244ecf3b3443c2928551a534f40",
                "date": 1683442001175,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2643",
                "rarity_score": 163.76852685226862,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2643.png",
            "scheme": "https"
        }
    },
    "1058": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1058",
                "description": "description",
                "dna": "7e6d4124c5213e82a32e0683a2f747f614094c45",
                "date": 1683430708314,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1058",
                "rarity_score": 275.2534537456552,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1058.png",
            "scheme": "https"
        }
    },
    "3662": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3662",
                "description": "description",
                "dna": "fa122009e4688dbd430c6fdc35b7b6587b413ccc",
                "date": 1683446495042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3662",
                "rarity_score": 136.740622952912,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3662.png",
            "scheme": "https"
        }
    },
    "2972": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2972",
                "description": "description",
                "dna": "ad39f7f61e094284d4d13f72e4d86b426c9590af",
                "date": 1683436864644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2972",
                "rarity_score": 118.81856145627405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2972.png",
            "scheme": "https"
        }
    },
    "2125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2125",
                "description": "description",
                "dna": "3470231a9b936e2964918244a0ff58a6b79d0bc9",
                "date": 1683432392121,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2125",
                "rarity_score": 165.8723859264675,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2125.png",
            "scheme": "https"
        }
    },
    "1845": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1845",
                "description": "description",
                "dna": "21d4d47798867a3b45b0d37fb106a87e0ae852b8",
                "date": 1683443660304,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1845",
                "rarity_score": 242.6033718338424,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1845.png",
            "scheme": "https"
        }
    },
    "3342": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3342",
                "description": "description",
                "dna": "c10208e365312c82b93ff5c9412773bcf255f15b",
                "date": 1683446679983,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3342",
                "rarity_score": 158.64073291083255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3342.png",
            "scheme": "https"
        }
    },
    "3": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3",
                "description": "description",
                "dna": "32c9b625c7332ae4a8bfa6b1b10d1e785e794ded",
                "date": 1683431088187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3",
                "rarity_score": 199.74376412887275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3.png",
            "scheme": "https"
        }
    },
    "826": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #826",
                "description": "description",
                "dna": "b993695fe4dbb217c1795d1d8cfbf68a9e83529a",
                "date": 1683446880246,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "826",
                "rarity_score": 181.73970585660712,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/826.png",
            "scheme": "https"
        }
    },
    "631": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #631",
                "description": "description",
                "dna": "a035155934b16244ae4579a544080dc411273496",
                "date": 1683776646187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "631",
                "rarity_score": 183.31585881274827,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/631.png",
            "scheme": "https"
        }
    },
    "46": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #46",
                "description": "description",
                "dna": "c69c75b341c3d8b9b4fec03c6bc5c736c4ffdb85",
                "date": 1683441701110,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "46",
                "rarity_score": 152.59238536901708,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/46.png",
            "scheme": "https"
        }
    },
    "584": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #584",
                "description": "description",
                "dna": "07f31854608cb048bc67010e90eb1d6d939c1739",
                "date": 1683439713531,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "584",
                "rarity_score": 206.38234284401133,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/584.png",
            "scheme": "https"
        }
    },
    "3141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3141",
                "description": "description",
                "dna": "46c190462a590679e1ea40d56f576bc4be689583",
                "date": 1683436057820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3141",
                "rarity_score": 161.4410679557109,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3141.png",
            "scheme": "https"
        }
    },
    "2084": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2084",
                "description": "description",
                "dna": "34fbf7c2d7e29714188928023c1a5ffcd76f2136",
                "date": 1683436353834,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2084",
                "rarity_score": 229.28141071353,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2084.png",
            "scheme": "https"
        }
    },
    "2362": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2362",
                "description": "description",
                "dna": "80e38af68e3e6efd78c9adff08d5b1fdd2770d17",
                "date": 1683436112603,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2362",
                "rarity_score": 293.774183044371,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2362.png",
            "scheme": "https"
        }
    },
    "3823": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3823",
                "description": "description",
                "dna": "80ed081359a920ed200a5bd2a7a044431393a3cf",
                "date": 1683440940679,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3823",
                "rarity_score": 208.28937861352668,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3823.png",
            "scheme": "https"
        }
    },
    "2768": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2768",
                "description": "description",
                "dna": "c03cdc2a913b535f3918a70125fed5c7950da2b6",
                "date": 1683776663811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2768",
                "rarity_score": 271.14257746531877,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2768.png",
            "scheme": "https"
        }
    },
    "3931": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3931",
                "description": "description",
                "dna": "7f99c1e3bb460c2f4890afaacd2c53ba8b2b8325",
                "date": 1683444094913,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3931",
                "rarity_score": 308.78733565710036,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3931.png",
            "scheme": "https"
        }
    },
    "2578": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2578",
                "description": "description",
                "dna": "f93cb2610cfd6c3548355b5203ccb5544da2bddd",
                "date": 1683446897597,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2578",
                "rarity_score": 198.66338200087728,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2578.png",
            "scheme": "https"
        }
    },
    "1953": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1953",
                "description": "description",
                "dna": "425ee2960b6c5cbe9da789899af23c99cd0eb009",
                "date": 1683436742984,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1953",
                "rarity_score": 221.24829809930822,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1953.png",
            "scheme": "https"
        }
    },
    "2173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2173",
                "description": "description",
                "dna": "4acd4be2d1e6bf8ea5dadc0400139bebd86b1ff9",
                "date": 1683435751785,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2173",
                "rarity_score": 182.86089898843704,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2173.png",
            "scheme": "https"
        }
    },
    "2587": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2587",
                "description": "description",
                "dna": "6c5298acb96f7087e9877ba8b23d12a631100b62",
                "date": 1683442885868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2587",
                "rarity_score": 194.63226364776807,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2587.png",
            "scheme": "https"
        }
    },
    "3517": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3517",
                "description": "description",
                "dna": "7a5b94c6c9003ff14c96fd859ed006f45371f01a",
                "date": 1683775166388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3517",
                "rarity_score": 152.32612706944136,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3517.png",
            "scheme": "https"
        }
    },
    "637": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #637",
                "description": "description",
                "dna": "07602c1d7c4d001cc5327a542dc650adae0a977d",
                "date": 1683430306233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "637",
                "rarity_score": 232.38401315774468,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/637.png",
            "scheme": "https"
        }
    },
    "3426": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3426",
                "description": "description",
                "dna": "4a554235de733d6b9514336765011fc6676e4ecf",
                "date": 1683434312608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3426",
                "rarity_score": 195.8771352621194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3426.png",
            "scheme": "https"
        }
    },
    "2512": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2512",
                "description": "description",
                "dna": "91bf1524a88ad8eded85ba4bb02cc313635cbfb0",
                "date": 1683439018549,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2512",
                "rarity_score": 204.20359814607292,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2512.png",
            "scheme": "https"
        }
    },
    "2941": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2941",
                "description": "description",
                "dna": "3ba1318fa33710df32b9d835808ee8a3c7acabfc",
                "date": 1683446700687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2941",
                "rarity_score": 245.63108161671872,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2941.png",
            "scheme": "https"
        }
    },
    "3397": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3397",
                "description": "description",
                "dna": "2334f838a576270cf3f0b3a5ef90e2a855ca1503",
                "date": 1683437124866,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3397",
                "rarity_score": 255.4874556784842,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3397.png",
            "scheme": "https"
        }
    },
    "1544": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1544",
                "description": "description",
                "dna": "9878fa076828ccc95a82e9e7803046845067829a",
                "date": 1683443399840,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1544",
                "rarity_score": 211.81602182657016,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1544.png",
            "scheme": "https"
        }
    },
    "3449": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3449",
                "description": "description",
                "dna": "466b03814febe3c9d01306d2b8ddb2f8fedb69ec",
                "date": 1683445430734,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3449",
                "rarity_score": 193.86368917620052,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3449.png",
            "scheme": "https"
        }
    },
    "892": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #892",
                "description": "description",
                "dna": "6c9c2562b44ca6f8cada0530902849ee14f60cdc",
                "date": 1683433857120,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "892",
                "rarity_score": 250.192029508808,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/892.png",
            "scheme": "https"
        }
    },
    "3057": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3057",
                "description": "description",
                "dna": "6b897a6fd073213a154b6722e519ee474a16a414",
                "date": 1683442610867,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3057",
                "rarity_score": 163.559586573722,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3057.png",
            "scheme": "https"
        }
    },
    "4000": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4000",
                "description": "description",
                "dna": "6d30bf7460fc1813e8352f828bc55e390ec9fe87",
                "date": 1683437536522,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "4000",
                "rarity_score": 191.03643609700865,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4000.png",
            "scheme": "https"
        }
    },
    "2928": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2928",
                "description": "description",
                "dna": "7d97a28923337faf6642c1dabcfeaf71fdf45f44",
                "date": 1683431024503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2928",
                "rarity_score": 307.2563275023973,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2928.png",
            "scheme": "https"
        }
    },
    "1498": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1498",
                "description": "description",
                "dna": "7becdd748c63217c7646e502eb68dafc711cfb6b",
                "date": 1683438664737,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1498",
                "rarity_score": 178.45772112878717,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1498.png",
            "scheme": "https"
        }
    },
    "131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #131",
                "description": "description",
                "dna": "802abd9898f73ced4671c83248b9c16ecdff106d",
                "date": 1683445696255,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "131",
                "rarity_score": 222.6021657158019,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/131.png",
            "scheme": "https"
        }
    },
    "1389": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1389",
                "description": "description",
                "dna": "27be72dbfd0dc11dc995c230bd2bd47b1899f8b3",
                "date": 1683435692871,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1389",
                "rarity_score": 207.75563839274312,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1389.png",
            "scheme": "https"
        }
    },
    "334": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #334",
                "description": "description",
                "dna": "d4856042e365f3c36cfb78a3c53fa08e0fed3111",
                "date": 1683445661624,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "334",
                "rarity_score": 192.92491365864407,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/334.png",
            "scheme": "https"
        }
    },
    "3801": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3801",
                "description": "description",
                "dna": "907ab0d58bf4c34d6131245ba7c06d5a5cde2493",
                "date": 1683432880621,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3801",
                "rarity_score": 189.5589372105318,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3801.png",
            "scheme": "https"
        }
    },
    "741": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #741",
                "description": "description",
                "dna": "90acacb6ce7af2b234911ddd98665477b244f84e",
                "date": 1683443624905,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "741",
                "rarity_score": 179.97486718955525,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/741.png",
            "scheme": "https"
        }
    },
    "3809": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3809",
                "description": "description",
                "dna": "a1965e05901a0ed6461b1ee5a4cb845b631ec9ef",
                "date": 1683435653477,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3809",
                "rarity_score": 172.7022066264381,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3809.png",
            "scheme": "https"
        }
    },
    "2778": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2778",
                "description": "description",
                "dna": "d8696c7a4dfcad50c08a97d7570ab6cf382cb7ec",
                "date": 1683771319857,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2778",
                "rarity_score": 253.7348539952621,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2778.png",
            "scheme": "https"
        }
    },
    "2489": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2489",
                "description": "description",
                "dna": "e540e87f66778e68714b354d80e68fac36fc3818",
                "date": 1683431626348,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2489",
                "rarity_score": 264.72148548382734,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2489.png",
            "scheme": "https"
        }
    },
    "1852": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1852",
                "description": "description",
                "dna": "cbfe60e514009f6f859771013c205577425ee5f1",
                "date": 1683429423209,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1852",
                "rarity_score": 221.19037227899682,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1852.png",
            "scheme": "https"
        }
    },
    "360": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #360",
                "description": "description",
                "dna": "308f1fbabc18654ec9ce4ee7a2dad050bcc1786b",
                "date": 1683443385230,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "360",
                "rarity_score": 198.55806028701227,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/360.png",
            "scheme": "https"
        }
    },
    "462": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #462",
                "description": "description",
                "dna": "f5a3ce82b3dfddb80b5f13818cf843f39e2be2a0",
                "date": 1683440773388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "462",
                "rarity_score": 202.20173384017497,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/462.png",
            "scheme": "https"
        }
    },
    "3295": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3295",
                "description": "description",
                "dna": "d00a378b8bdf70c4579cfaafc9d0a05c23bc4450",
                "date": 1683437170155,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3295",
                "rarity_score": 204.18808570623992,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3295.png",
            "scheme": "https"
        }
    },
    "1937": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1937",
                "description": "description",
                "dna": "1fa50b24d91506c6b1b103f67cd5e030511409c8",
                "date": 1683431368300,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1937",
                "rarity_score": 193.21518507663575,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1937.png",
            "scheme": "https"
        }
    },
    "2118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2118",
                "description": "description",
                "dna": "aba929eca9069248b26bd368b1de105dd15ca70e",
                "date": 1683429309360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2118",
                "rarity_score": 180.272904534023,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2118.png",
            "scheme": "https"
        }
    },
    "1838": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1838",
                "description": "description",
                "dna": "bc3b81d9bf37b5094277a73e4f9736f11bfc6022",
                "date": 1683439227996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1838",
                "rarity_score": 203.3280741889172,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1838.png",
            "scheme": "https"
        }
    },
    "2319": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2319",
                "description": "description",
                "dna": "86d906207f24012dbe7d1caacd788eb6123b8cb1",
                "date": 1683443989742,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2319",
                "rarity_score": 182.33434386103949,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2319.png",
            "scheme": "https"
        }
    },
    "733": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #733",
                "description": "description",
                "dna": "82eb73717e277b9843a8d769059237f7295c2b45",
                "date": 1683774484587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "733",
                "rarity_score": 192.94859134088136,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/733.png",
            "scheme": "https"
        }
    },
    "1870": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1870",
                "description": "description",
                "dna": "2f4c5798d9af0d7b08e84a54d5ed19eeeb87c352",
                "date": 1683430665310,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1870",
                "rarity_score": 249.311701324981,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1870.png",
            "scheme": "https"
        }
    },
    "367": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #367",
                "description": "description",
                "dna": "e1570ee8a0edb7f351543babea84ed8c6c59c491",
                "date": 1683445839255,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "367",
                "rarity_score": 289.69733083781733,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/367.png",
            "scheme": "https"
        }
    },
    "3286": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3286",
                "description": "description",
                "dna": "e417e237b3cb751c62dfc62330c33378e919fe2f",
                "date": 1683434717019,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3286",
                "rarity_score": 222.20642384978572,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3286.png",
            "scheme": "https"
        }
    },
    "1126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1126",
                "description": "description",
                "dna": "9926759a65c4c6e3bb90d5840f4fc4dbc6a7775c",
                "date": 1683430477319,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1126",
                "rarity_score": 167.47614092750942,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1126.png",
            "scheme": "https"
        }
    },
    "2103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2103",
                "description": "description",
                "dna": "7d9b8f1434b6ce55347a999b2cb08df4a31a7e4e",
                "date": 1683431033390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2103",
                "rarity_score": 249.01184541573238,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2103.png",
            "scheme": "https"
        }
    },
    "59": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #59",
                "description": "description",
                "dna": "536a77f690124cfa4c7573875ab04c4f563813cb",
                "date": 1683771221798,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "59",
                "rarity_score": 270.25993749830866,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/59.png",
            "scheme": "https"
        }
    },
    "1321": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1321",
                "description": "description",
                "dna": "c3697d25173da9c6ccb46710eb816e0df03aaf7d",
                "date": 1683445984751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1321",
                "rarity_score": 147.07393351666735,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1321.png",
            "scheme": "https"
        }
    },
    "1145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1145",
                "description": "description",
                "dna": "bcc7f7e2a6efaeb1200353cba315f84853286e2c",
                "date": 1683441975216,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1145",
                "rarity_score": 196.77888922098623,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1145.png",
            "scheme": "https"
        }
    },
    "138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #138",
                "description": "description",
                "dna": "9c32d3980178909ead1fd26b7af11a3a608d9693",
                "date": 1683431888952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "138",
                "rarity_score": 174.45630517157431,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/138.png",
            "scheme": "https"
        }
    },
    "1866": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1866",
                "description": "description",
                "dna": "13bdc6aad4f5d0bb6d924b8ca170b3566c151de2",
                "date": 1683447119246,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1866",
                "rarity_score": 249.83311486138186,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1866.png",
            "scheme": "https"
        }
    },
    "1714": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1714",
                "description": "description",
                "dna": "dda6d71d44ef2b55f2e8fa65411c487a94488ec4",
                "date": 1683437910790,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1714",
                "rarity_score": 223.9936461443246,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1714.png",
            "scheme": "https"
        }
    },
    "4005": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4005",
                "description": "description",
                "dna": "ace5dac6509df4d4602b24e4fe6d2510d4e01198",
                "date": 1683436237151,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "4005",
                "rarity_score": 228.66964815082451,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4005.png",
            "scheme": "https"
        }
    },
    "655": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #655",
                "description": "description",
                "dna": "8d992978e612368948d222c2f6c6dcff107b6079",
                "date": 1683446300897,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "655",
                "rarity_score": 139.02561501624544,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/655.png",
            "scheme": "https"
        }
    },
    "2220": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2220",
                "description": "description",
                "dna": "84be6507fe9c6f5388fbd86ad9079547bd2470a1",
                "date": 1683446081993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2220",
                "rarity_score": 178.15957461853858,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2220.png",
            "scheme": "https"
        }
    },
    "1419": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1419",
                "description": "description",
                "dna": "dfcf3f43ccfc4b7248bcabadc165cfcce2a83edc",
                "date": 1683447052751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1419",
                "rarity_score": 181.80880133563736,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1419.png",
            "scheme": "https"
        }
    },
    "3704": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3704",
                "description": "description",
                "dna": "6a98f46e393aadcbba835ae9414be868c75c212b",
                "date": 1683770494645,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3704",
                "rarity_score": 233.4489180745319,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3704.png",
            "scheme": "https"
        }
    },
    "3243": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3243",
                "description": "description",
                "dna": "83a07caa6231febb307a7e9fe139d0afe9337d3d",
                "date": 1683429974113,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3243",
                "rarity_score": 154.12363470730426,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3243.png",
            "scheme": "https"
        }
    },
    "550": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #550",
                "description": "description",
                "dna": "b388e7da20be7fabbe21b085eea488e6d184b473",
                "date": 1683431803928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "550",
                "rarity_score": 348.0012793758255,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/550.png",
            "scheme": "https"
        }
    },
    "689": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #689",
                "description": "description",
                "dna": "8a017f1ef453de429fb5eb86171d0343f1c49ff3",
                "date": 1683432857638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "689",
                "rarity_score": 356.61858505412386,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/689.png",
            "scheme": "https"
        }
    },
    "1017": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1017",
                "description": "description",
                "dna": "59556aa843e8c455ce2d6cefebb67ba214bccd91",
                "date": 1683429936212,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1017",
                "rarity_score": 201.56900050479948,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1017.png",
            "scheme": "https"
        }
    },
    "2385": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2385",
                "description": "description",
                "dna": "f1ecbd958654d2ccda286207236bea554b364d96",
                "date": 1683441161176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2385",
                "rarity_score": 202.18394063728647,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2385.png",
            "scheme": "https"
        }
    },
    "1931": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1931",
                "description": "description",
                "dna": "f29388e46fb6c3aa8aee0e9e5b15c1c46ea1f7a3",
                "date": 1683446136137,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1931",
                "rarity_score": 221.56192128648982,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1931.png",
            "scheme": "https"
        }
    },
    "1798": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1798",
                "description": "description",
                "dna": "581e64061c822b0c81134323985842fc6614f037",
                "date": 1683438015107,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1798",
                "rarity_score": 242.9761761884127,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1798.png",
            "scheme": "https"
        }
    },
    "1880": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1880",
                "description": "description",
                "dna": "d184b0b5e93b1b7bfc175e4f6b0e7877e56d266d",
                "date": 1683433056365,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1880",
                "rarity_score": 165.76605171242366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1880.png",
            "scheme": "https"
        }
    },
    "3746": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3746",
                "description": "description",
                "dna": "8a0e9dde88885e6fd764c098623be357b93a20da",
                "date": 1683445311367,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3746",
                "rarity_score": 277.0000258993558,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3746.png",
            "scheme": "https"
        }
    },
    "3612": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3612",
                "description": "description",
                "dna": "b1478575e727822fdd95346b104aa88be6416d61",
                "date": 1683776668323,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3612",
                "rarity_score": 256.05179687000185,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3612.png",
            "scheme": "https"
        }
    },
    "3886": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3886",
                "description": "description",
                "dna": "a830e8458ab0699872ff383534850e69d6c65d24",
                "date": 1683438061759,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3886",
                "rarity_score": 178.8622359429008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3886.png",
            "scheme": "https"
        }
    },
    "3486": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3486",
                "description": "description",
                "dna": "533f127576e6da5fd48fd2f465709244251a8b16",
                "date": 1683433997224,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3486",
                "rarity_score": 167.64627400998197,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3486.png",
            "scheme": "https"
        }
    },
    "579": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #579",
                "description": "description",
                "dna": "333b224c8400268b6696f08c0e0519a57cde0f8c",
                "date": 1683440808458,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "579",
                "rarity_score": 206.38247983940695,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/579.png",
            "scheme": "https"
        }
    },
    "1767": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1767",
                "description": "description",
                "dna": "8b53b0d5015384836e5d7807dcbf8bef7f0b482a",
                "date": 1683774910778,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1767",
                "rarity_score": 169.0426868969476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1767.png",
            "scheme": "https"
        }
    },
    "1207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1207",
                "description": "description",
                "dna": "14b53a00933870399624a8773678f44bac40167c",
                "date": 1683442295864,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1207",
                "rarity_score": 258.0549772693809,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1207.png",
            "scheme": "https"
        }
    },
    "1979": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1979",
                "description": "description",
                "dna": "13be8d7c7823f334e2e31526806dd38bc762262e",
                "date": 1683446780169,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1979",
                "rarity_score": 168.4774355619818,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1979.png",
            "scheme": "https"
        }
    },
    "3219": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3219",
                "description": "description",
                "dna": "89ce90ab8ecdae8117dcfea1d92646d7570f8e58",
                "date": 1683774779213,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3219",
                "rarity_score": 209.31926184920812,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3219.png",
            "scheme": "https"
        }
    },
    "614": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #614",
                "description": "description",
                "dna": "bc9f0baa242639208e76d6b617ea868d6be91625",
                "date": 1683771154648,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "614",
                "rarity_score": 298.7452419133473,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/614.png",
            "scheme": "https"
        }
    },
    "293": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #293",
                "description": "description",
                "dna": "4537d1d3bd69050790ac166c8f3924c393ee74af",
                "date": 1683443366282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "293",
                "rarity_score": 172.00335491477466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/293.png",
            "scheme": "https"
        }
    },
    "908": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #908",
                "description": "description",
                "dna": "bd7fc68ad6bdea3f1ce102c1c934674781999dbe",
                "date": 1683443292645,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "908",
                "rarity_score": 236.35037799552092,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/908.png",
            "scheme": "https"
        }
    },
    "2438": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2438",
                "description": "description",
                "dna": "7043b10ece798a921588013ebaea881e8e4dd3e6",
                "date": 1683437580787,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2438",
                "rarity_score": 112.41486868049398,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2438.png",
            "scheme": "https"
        }
    },
    "4031": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4031",
                "description": "description",
                "dna": "e5c6252fc2f28a08014fae4740f3117c66071a9c",
                "date": 1683440617475,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4031",
                "rarity_score": 187.35140201111497,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4031.png",
            "scheme": "https"
        }
    },
    "2683": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2683",
                "description": "description",
                "dna": "4a2a5a3767cc37cc6cfa1da8ccc8b7cdace4bbc9",
                "date": 1683430915584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2683",
                "rarity_score": 198.43754584809898,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2683.png",
            "scheme": "https"
        }
    },
    "1376": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1376",
                "description": "description",
                "dna": "745ff8c17a27820169408e597ea11836c3790f74",
                "date": 1683429664095,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1376",
                "rarity_score": 221.94746387471122,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1376.png",
            "scheme": "https"
        }
    },
    "3344": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3344",
                "description": "description",
                "dna": "3b5a9a1e2d756f153d6fadd2587d032b9e39f4d2",
                "date": 1683776439051,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3344",
                "rarity_score": 214.4274645088326,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3344.png",
            "scheme": "https"
        }
    },
    "1024": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1024",
                "description": "description",
                "dna": "2dbb00255e27af4655387adfb3989de585c588b4",
                "date": 1683440229074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1024",
                "rarity_score": 167.71775497430252,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1024.png",
            "scheme": "https"
        }
    },
    "698": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #698",
                "description": "description",
                "dna": "889ffb3c7b6abbe6c96580857f27e2895281b5ab",
                "date": 1683435397172,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "698",
                "rarity_score": 164.2174067245145,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/698.png",
            "scheme": "https"
        }
    },
    "166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #166",
                "description": "description",
                "dna": "c4df933147c0975f440eb4dfb2912751b789896c",
                "date": 1683446927066,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "166",
                "rarity_score": 237.64391282489314,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/166.png",
            "scheme": "https"
        }
    },
    "676": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #676",
                "description": "description",
                "dna": "7f082b1b5467981d61f5e64dde4590d4b2f09c2c",
                "date": 1683445709439,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "676",
                "rarity_score": 208.76088093120114,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/676.png",
            "scheme": "https"
        }
    },
    "1240": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1240",
                "description": "description",
                "dna": "ba17be78f8bebc5f292f58ff06424eba95fc899b",
                "date": 1683437859261,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1240",
                "rarity_score": 158.32831046696649,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1240.png",
            "scheme": "https"
        }
    },
    "3018": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3018",
                "description": "description",
                "dna": "7a50a9f5fed78e9b4ed1e483d795802b90e68d21",
                "date": 1683434298814,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3018",
                "rarity_score": 200.4280888188227,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3018.png",
            "scheme": "https"
        }
    },
    "3786": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3786",
                "description": "description",
                "dna": "9c608da3cb35df1a2a756dd95df28b8de679b750",
                "date": 1683433829870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3786",
                "rarity_score": 202.83699571288415,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3786.png",
            "scheme": "https"
        }
    },
    "3315": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3315",
                "description": "description",
                "dna": "9e4ace6a81ff70bc10580c6cf128352c9666cf56",
                "date": 1683441230666,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3315",
                "rarity_score": 194.96594390244752,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3315.png",
            "scheme": "https"
        }
    },
    "1654": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1654",
                "description": "description",
                "dna": "23853290a9733af39a8ee04917ff3ec7a59456e8",
                "date": 1683431051840,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1654",
                "rarity_score": 267.5753353802644,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1654.png",
            "scheme": "https"
        }
    },
    "2046": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2046",
                "description": "description",
                "dna": "d3cc261daea5bc1b0458c3178b202ab0334c5d8a",
                "date": 1683444885419,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2046",
                "rarity_score": 217.8185234546256,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2046.png",
            "scheme": "https"
        }
    },
    "1999": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1999",
                "description": "description",
                "dna": "95bdca788ad8a4d7f09b3c2f592e2b14d623aea5",
                "date": 1683776676751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1999",
                "rarity_score": 214.2182242109846,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1999.png",
            "scheme": "https"
        }
    },
    "1295": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1295",
                "description": "description",
                "dna": "9c18d55611138cfb0297e2260189379c7dbfaebf",
                "date": 1683438459568,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1295",
                "rarity_score": 251.78440754264085,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1295.png",
            "scheme": "https"
        }
    },
    "287": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #287",
                "description": "description",
                "dna": "b98dd5e6d8692a7a0b282576bf82babdbf4b3599",
                "date": 1683440286574,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "287",
                "rarity_score": 185.91454790411353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/287.png",
            "scheme": "https"
        }
    },
    "302": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #302",
                "description": "description",
                "dna": "48a814f8fc2828e75bb5ac89b899c03cd9f9e3d8",
                "date": 1683445251900,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "302",
                "rarity_score": 162.29245360273745,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/302.png",
            "scheme": "https"
        }
    },
    "2456": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2456",
                "description": "description",
                "dna": "ee76981d841d252dad1a353d43d508bd6c83dad9",
                "date": 1683431577514,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2456",
                "rarity_score": 269.59848775012,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2456.png",
            "scheme": "https"
        }
    },
    "1649": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1649",
                "description": "description",
                "dna": "114b7d9ca9ceee0d12e2ba6a48c99069dedc2575",
                "date": 1683771429243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1649",
                "rarity_score": 221.3416451580733,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1649.png",
            "scheme": "https"
        }
    },
    "675": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #675",
                "description": "description",
                "dna": "2a47ee18dcd0a26600eb165d1e51a1021bc39aec",
                "date": 1683442114147,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "675",
                "rarity_score": 184.76668610885764,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/675.png",
            "scheme": "https"
        }
    },
    "2382": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2382",
                "description": "description",
                "dna": "fd54449030e6a9d6debaae5df89d8b2a378e98aa",
                "date": 1683430935062,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2382",
                "rarity_score": 321.04901734830645,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2382.png",
            "scheme": "https"
        }
    },
    "1981": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1981",
                "description": "description",
                "dna": "e6dc5735598170f1e807f6324071bdacc6c26e12",
                "date": 1683435191132,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1981",
                "rarity_score": 185.4588253901062,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1981.png",
            "scheme": "https"
        }
    },
    "449": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #449",
                "description": "description",
                "dna": "4800ef21709d67463b9a2627fe24e92012e9353c",
                "date": 1683430464528,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "449",
                "rarity_score": 316.5601457079189,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/449.png",
            "scheme": "https"
        }
    },
    "3664": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3664",
                "description": "description",
                "dna": "76f09c13752f2794378ebda85cff5109acb1fb39",
                "date": 1683439960505,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3664",
                "rarity_score": 203.19037860331287,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3664.png",
            "scheme": "https"
        }
    },
    "2221": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2221",
                "description": "description",
                "dna": "0ca59c72639a30a629bc925926ad7dd2ba44e6df",
                "date": 1683445969922,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2221",
                "rarity_score": 122.49177200139533,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2221.png",
            "scheme": "https"
        }
    },
    "4091": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4091",
                "description": "description",
                "dna": "be057390d279f75e2982528029bd370b2df12fb9",
                "date": 1683439290638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "4091",
                "rarity_score": 180.16044616227214,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4091.png",
            "scheme": "https"
        }
    },
    "3874": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3874",
                "description": "description",
                "dna": "545c5fff83a9b5ff0a07c7069f32e4cf72ab59f0",
                "date": 1683431161157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "3874",
                "rarity_score": 296.13765000427225,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3874.png",
            "scheme": "https"
        }
    },
    "1889": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1889",
                "description": "description",
                "dna": "2c2ef81e6af7810d3812035dc81b4580987ba0d6",
                "date": 1683445158975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1889",
                "rarity_score": 178.30025992374698,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1889.png",
            "scheme": "https"
        }
    },
    "1744": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1744",
                "description": "description",
                "dna": "9c3a7100d904f6aa055663636aae9134565d8efa",
                "date": 1683444103615,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1744",
                "rarity_score": 205.5773576315165,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1744.png",
            "scheme": "https"
        }
    },
    "3842": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3842",
                "description": "description",
                "dna": "20ff5c2ebd50c181893bab65f9d9b830f38abb46",
                "date": 1683440658915,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3842",
                "rarity_score": 186.95347867988576,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3842.png",
            "scheme": "https"
        }
    },
    "1040": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1040",
                "description": "description",
                "dna": "4279c443f887696b44c8833069586d695af48114",
                "date": 1683430961544,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1040",
                "rarity_score": 264.94667356946997,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1040.png",
            "scheme": "https"
        }
    },
    "1993": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1993",
                "description": "description",
                "dna": "9ffd23a012d244843ebf8e6743ef6effd0c09236",
                "date": 1683770481694,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1993",
                "rarity_score": 238.59768982595267,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1993.png",
            "scheme": "https"
        }
    },
    "2580": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2580",
                "description": "description",
                "dna": "697536e3f037f8c2e51c6a99c3e747982d932329",
                "date": 1683443251658,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2580",
                "rarity_score": 206.39950454878732,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2580.png",
            "scheme": "https"
        }
    },
    "1657": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1657",
                "description": "description",
                "dna": "a821ddd01cd638bfad836bc5368b519b6b43aec5",
                "date": 1683442662019,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1657",
                "rarity_score": 173.26646817284796,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1657.png",
            "scheme": "https"
        }
    },
    "988": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #988",
                "description": "description",
                "dna": "ba2b43b1dfb378992e069db03c104c087b6d1a33",
                "date": 1683431449127,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "988",
                "rarity_score": 165.31768005976937,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/988.png",
            "scheme": "https"
        }
    },
    "2416": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2416",
                "description": "description",
                "dna": "03501fbcd9fb802a4f41dd26f1698a4087ce82a8",
                "date": 1683440458999,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2416",
                "rarity_score": 168.5822673189286,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2416.png",
            "scheme": "https"
        }
    },
    "1868": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1868",
                "description": "description",
                "dna": "db6945dee67c4c46311201908902fe9e224eb684",
                "date": 1683439722412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1868",
                "rarity_score": 159.93379238115324,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1868.png",
            "scheme": "https"
        }
    },
    "537": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #537",
                "description": "description",
                "dna": "b4a3bdbcd22bc274b1301160cfd8a7428afb81a9",
                "date": 1683441746137,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "537",
                "rarity_score": 185.05186058907566,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/537.png",
            "scheme": "https"
        }
    },
    "18": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #18",
                "description": "description",
                "dna": "af74dbf70692c34983736e1c8f4d5b08d3d228af",
                "date": 1683433757471,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "18",
                "rarity_score": 158.10297885930984,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/18.png",
            "scheme": "https"
        }
    },
    "3985": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3985",
                "description": "description",
                "dna": "7b0c6f0cea8d75f0d6b5b172ebc61da0d617f295",
                "date": 1683435958612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3985",
                "rarity_score": 173.31973319004922,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3985.png",
            "scheme": "https"
        }
    },
    "2069": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2069",
                "description": "description",
                "dna": "803ce0ec1b8d46497e68f78b6ec4ba42ffa2dd19",
                "date": 1683430455511,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2069",
                "rarity_score": 232.90492174863604,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2069.png",
            "scheme": "https"
        }
    },
    "1118": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1118",
                "description": "description",
                "dna": "8a2b0e154347bcf6325f6f3ef4a05441aa04593c",
                "date": 1683431211605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1118",
                "rarity_score": 218.30362218435164,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1118.png",
            "scheme": "https"
        }
    },
    "681": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #681",
                "description": "description",
                "dna": "ab8bda76e0134f8a3cdccb45a633c9a597df82ad",
                "date": 1683432923862,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "681",
                "rarity_score": 299.8612517761715,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/681.png",
            "scheme": "https"
        }
    },
    "817": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #817",
                "description": "description",
                "dna": "50c76a1c14d840af7b1e9a8f1edef577557ef56c",
                "date": 1683776489862,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "817",
                "rarity_score": 220.0405171872253,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/817.png",
            "scheme": "https"
        }
    },
    "2818": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2818",
                "description": "description",
                "dna": "c85e1bb1471bb755673211fe05ae6909fa511866",
                "date": 1683517612006,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "RedDead Outfit",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2818",
                "rarity_score": 4238.051537560002,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2818.png",
            "scheme": "https"
        }
    },
    "3384": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3384",
                "description": "description",
                "dna": "9b58bb2b5ab57dc48e55b2e50211872d78eef00c",
                "date": 1683447313268,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3384",
                "rarity_score": 189.62233237594472,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3384.png",
            "scheme": "https"
        }
    },
    "3400": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3400",
                "description": "description",
                "dna": "81426017815ce893480d21b578be64d29f37a579",
                "date": 1683444629090,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3400",
                "rarity_score": 170.74570400487832,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3400.png",
            "scheme": "https"
        }
    },
    "640": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #640",
                "description": "description",
                "dna": "9eac72036648c23db40f852f62ae883f8dfaf1e9",
                "date": 1683440596049,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "640",
                "rarity_score": 177.5331736352024,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/640.png",
            "scheme": "https"
        }
    },
    "2013": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2013",
                "description": "description",
                "dna": "f81c70fb9f027fb9824b66f437070e5a042b92bc",
                "date": 1683437395997,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2013",
                "rarity_score": 130.06040811950794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2013.png",
            "scheme": "https"
        }
    },
    "1464": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1464",
                "description": "description",
                "dna": "ce9dcf98d6c4f6a1aeaa17f1247bfc9fde17ad50",
                "date": 1683447389137,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1464",
                "rarity_score": 175.49053551279587,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1464.png",
            "scheme": "https"
        }
    },
    "2274": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2274",
                "description": "description",
                "dna": "5de253344add35ec4292b37791e918058e4b96a0",
                "date": 1683434277044,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2274",
                "rarity_score": 213.29427505888438,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2274.png",
            "scheme": "https"
        }
    },
    "2842": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2842",
                "description": "description",
                "dna": "e44bf7ed25c3fede1d0eb105acb1ddfd5945fa9e",
                "date": 1683432347696,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2842",
                "rarity_score": 171.37602472482214,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2842.png",
            "scheme": "https"
        }
    },
    "975": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #975",
                "description": "description",
                "dna": "2e66c56600655248572a1cdb7e024da759d0bba0",
                "date": 1683440251646,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "975",
                "rarity_score": 171.77118465240147,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/975.png",
            "scheme": "https"
        }
    },
    "3817": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3817",
                "description": "description",
                "dna": "95aea6530ff37fc4bbffef3292ad4a509b45847e",
                "date": 1683431070173,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3817",
                "rarity_score": 246.3830172966587,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3817.png",
            "scheme": "https"
        }
    },
    "3870": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3870",
                "description": "description",
                "dna": "9e6190b892fb1549e0f77c877e0852f0114bd1b2",
                "date": 1683774623944,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3870",
                "rarity_score": 223.36319924992,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3870.png",
            "scheme": "https"
        }
    },
    "2913": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2913",
                "description": "description",
                "dna": "9f6b5746623671285ab9020209ef0c59661b0725",
                "date": 1683443394997,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2913",
                "rarity_score": 194.82327822425322,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2913.png",
            "scheme": "https"
        }
    },
    "3888": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3888",
                "description": "description",
                "dna": "9998badf79410b69c8c0afa9765a943fb85f2cae",
                "date": 1683442046635,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3888",
                "rarity_score": 161.597347779646,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3888.png",
            "scheme": "https"
        }
    },
    "3490": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3490",
                "description": "description",
                "dna": "7f6ceab996d403a1763967453e102c5369dce0fe",
                "date": 1683770477661,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3490",
                "rarity_score": 351.6897843385846,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3490.png",
            "scheme": "https"
        }
    },
    "1213": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1213",
                "description": "description",
                "dna": "90daa6f09c953d3e331f2a28a497b859fe477928",
                "date": 1683439004836,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1213",
                "rarity_score": 192.01602076442447,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1213.png",
            "scheme": "https"
        }
    },
    "3923": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3923",
                "description": "description",
                "dna": "f940b59a526af62321cdedba3e347e387469d5c6",
                "date": 1683443664565,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3923",
                "rarity_score": 177.29686328143694,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3923.png",
            "scheme": "https"
        }
    },
    "2074": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2074",
                "description": "description",
                "dna": "210db4f3304e04df08493a6db6b64bd56d0bf463",
                "date": 1683434867213,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2074",
                "rarity_score": 224.24933635318052,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2074.png",
            "scheme": "https"
        }
    },
    "494": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #494",
                "description": "description",
                "dna": "3efc5ccf6a0b90a8e93813d64c27af397398844c",
                "date": 1683444020974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "494",
                "rarity_score": 153.49115077282792,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/494.png",
            "scheme": "https"
        }
    },
    "1844": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1844",
                "description": "description",
                "dna": "aa53bb40b112a2793f0fc79dba31bf329ae79f9d",
                "date": 1683445401100,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1844",
                "rarity_score": 167.67581200066613,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1844.png",
            "scheme": "https"
        }
    },
    "3367": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3367",
                "description": "description",
                "dna": "8987c1f1380f7a94a6db5267278914b1e664e442",
                "date": 1683774439667,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3367",
                "rarity_score": 157.32122583210983,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3367.png",
            "scheme": "https"
        }
    },
    "3063": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3063",
                "description": "description",
                "dna": "1949da53f085d88b5a5d62a7906e7984d0062798",
                "date": 1683438358761,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3063",
                "rarity_score": 283.47857631480935,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3063.png",
            "scheme": "https"
        }
    },
    "3104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3104",
                "description": "description",
                "dna": "12211bcd4a70e379710c6b90824d1c09edf57269",
                "date": 1683439103196,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3104",
                "rarity_score": 340.151195079404,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3104.png",
            "scheme": "https"
        }
    },
    "4028": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4028",
                "description": "description",
                "dna": "3c079064e8fb37fe76c33a3af5c19ce76e77b60b",
                "date": 1683444854393,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "4028",
                "rarity_score": 217.27512570953,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4028.png",
            "scheme": "https"
        }
    },
    "1597": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1597",
                "description": "description",
                "dna": "ad2c65f363c9993153bc1429644e7ddfa3c51d8e",
                "date": 1683431312910,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1597",
                "rarity_score": 215.0172134902603,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1597.png",
            "scheme": "https"
        }
    },
    "364": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #364",
                "description": "description",
                "dna": "ab2f26f54b567d1a7b64bda72888fbd1032d13a8",
                "date": 1683433119084,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "364",
                "rarity_score": 209.91552462497958,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/364.png",
            "scheme": "https"
        }
    },
    "3864": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3864",
                "description": "description",
                "dna": "7dd9b783d34005d741e5eb4dd9050501dc737e6c",
                "date": 1683438187232,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3864",
                "rarity_score": 189.79053319962688,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3864.png",
            "scheme": "https"
        }
    },
    "543": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #543",
                "description": "description",
                "dna": "d3ed932496053657e036d804bea192500c54d563",
                "date": 1683431866796,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "543",
                "rarity_score": 236.71369585969035,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/543.png",
            "scheme": "https"
        }
    },
    "3148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3148",
                "description": "description",
                "dna": "c5943ad4cb7ed4a2c471dc0a6e5410ec78573e01",
                "date": 1683429379995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3148",
                "rarity_score": 205.79861894650494,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3148.png",
            "scheme": "https"
        }
    },
    "3641": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3641",
                "description": "description",
                "dna": "2c590b6ef71ef52f3c1e0d1c3a262e22775bf912",
                "date": 1683436397959,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3641",
                "rarity_score": 167.20517619901733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3641.png",
            "scheme": "https"
        }
    },
    "4066": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4066",
                "description": "description",
                "dna": "bb8c313f287cd69755b447e0a47c249daf30aba0",
                "date": 1683441368571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "4066",
                "rarity_score": 176.45546435435332,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4066.png",
            "scheme": "https"
        }
    },
    "1080": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1080",
                "description": "description",
                "dna": "77202df84865a6134b86ca1cf001334210283fb7",
                "date": 1683438724250,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1080",
                "rarity_score": 162.2554309991302,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1080.png",
            "scheme": "https"
        }
    },
    "1063": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1063",
                "description": "description",
                "dna": "7a3e17a6a66eff697e2438d0165a0df5d1c9ab75",
                "date": 1683430656267,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1063",
                "rarity_score": 296.4753924000363,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1063.png",
            "scheme": "https"
        }
    },
    "1644": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1644",
                "description": "description",
                "dna": "77546d898cbd6eae9affba4ca08c187f2b5487ed",
                "date": 1683434540014,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1644",
                "rarity_score": 213.2908778611923,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1644.png",
            "scheme": "https"
        }
    },
    "1430": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1430",
                "description": "description",
                "dna": "7a768473ddfd5fa96aba6643f422d95403059162",
                "date": 1683429812658,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1430",
                "rarity_score": 252.40858391101565,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1430.png",
            "scheme": "https"
        }
    },
    "1091": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1091",
                "description": "description",
                "dna": "b951d80eac8c7204a5e5afa14d5a3e4f3fe69515",
                "date": 1683439045596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1091",
                "rarity_score": 218.28516768877878,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1091.png",
            "scheme": "https"
        }
    },
    "54": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #54",
                "description": "description",
                "dna": "17222f7a5f3909e5932c017550fa811d3461380c",
                "date": 1683445674526,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "54",
                "rarity_score": 247.04996875085297,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/54.png",
            "scheme": "https"
        }
    },
    "1367": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1367",
                "description": "description",
                "dna": "e7c085a15474ba708f3cbdd90ccd1ee02faa4319",
                "date": 1683437331314,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1367",
                "rarity_score": 149.3825094201821,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1367.png",
            "scheme": "https"
        }
    },
    "1489": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1489",
                "description": "description",
                "dna": "d89512b0836418e74cab628d4c2b04e9f18c126c",
                "date": 1683443700825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1489",
                "rarity_score": 160.20245050320003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1489.png",
            "scheme": "https"
        }
    },
    "376": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #376",
                "description": "description",
                "dna": "addb2f6a2ffbf827ed560f8d0679c62a60104759",
                "date": 1683434790503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "376",
                "rarity_score": 226.31538296570795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/376.png",
            "scheme": "https"
        }
    },
    "2548": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2548",
                "description": "description",
                "dna": "3c49bbfe42d1f7f97bfc14b9ee80941f38069738",
                "date": 1683442970170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2548",
                "rarity_score": 228.4530211678411,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2548.png",
            "scheme": "https"
        }
    },
    "1598": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1598",
                "description": "description",
                "dna": "45879219883e2dd0151991bd66924d04617bf6c7",
                "date": 1683434466610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1598",
                "rarity_score": 165.29519035837902,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1598.png",
            "scheme": "https"
        }
    },
    "194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #194",
                "description": "description",
                "dna": "a50ae33ad90bcb5fb7339a619333c07fe97cff67",
                "date": 1683434579521,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "194",
                "rarity_score": 301.30836429281925,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/194.png",
            "scheme": "https"
        }
    },
    "1762": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1762",
                "description": "description",
                "dna": "481910c0bf2daab922b223e411a61c50ff61b037",
                "date": 1683431901789,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1762",
                "rarity_score": 174.2965995076501,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1762.png",
            "scheme": "https"
        }
    },
    "710": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #710",
                "description": "description",
                "dna": "f407ea5173289908b9f97a0575951d698dbde7fa",
                "date": 1683435425769,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "710",
                "rarity_score": 283.3205316189434,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/710.png",
            "scheme": "https"
        }
    },
    "1098": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1098",
                "description": "description",
                "dna": "41d84a216eb4700d8f933a23350ef6cd4f1cbe76",
                "date": 1683446796545,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1098",
                "rarity_score": 177.14619246235867,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1098.png",
            "scheme": "https"
        }
    },
    "114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #114",
                "description": "description",
                "dna": "b0938a607ecfc8b16bff2077ee0815847129798e",
                "date": 1683445352455,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "114",
                "rarity_score": 297.56586673737274,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/114.png",
            "scheme": "https"
        }
    },
    "1952": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1952",
                "description": "description",
                "dna": "060ca11d8e0849ad747763c39100e49d91f107a7",
                "date": 1683433922577,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1952",
                "rarity_score": 153.55155177177414,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1952.png",
            "scheme": "https"
        }
    },
    "4040": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4040",
                "description": "description",
                "dna": "8928e4fd098db194b416f0b2aa16d4d893ef9f65",
                "date": 1683432563359,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "4040",
                "rarity_score": 197.4785882865821,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4040.png",
            "scheme": "https"
        }
    },
    "3530": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3530",
                "description": "description",
                "dna": "835db313a894a5ef66dd800beaeb068e81fa2200",
                "date": 1683437735474,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3530",
                "rarity_score": 174.2677118323826,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3530.png",
            "scheme": "https"
        }
    },
    "1594": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1594",
                "description": "description",
                "dna": "64dd1de8ac28d0a6b967b4432e7caef45a1e935a",
                "date": 1683770465371,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1594",
                "rarity_score": 242.1574141789959,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1594.png",
            "scheme": "https"
        }
    },
    "3744": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3744",
                "description": "description",
                "dna": "76f12a04cf57199eeb0b1a2ef4b3e021837a7f22",
                "date": 1683434335714,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3744",
                "rarity_score": 200.93490106529654,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3744.png",
            "scheme": "https"
        }
    },
    "1778": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1778",
                "description": "description",
                "dna": "03cb95bd5a8f70a5c596488f02768be8c0cc6022",
                "date": 1683429795092,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1778",
                "rarity_score": 351.882807597184,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1778.png",
            "scheme": "https"
        }
    },
    "1724": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1724",
                "description": "description",
                "dna": "a25e4e5d624056479c2712b75c8a68463b179e77",
                "date": 1683437240021,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1724",
                "rarity_score": 165.20077254795575,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1724.png",
            "scheme": "https"
        }
    },
    "1960": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1960",
                "description": "description",
                "dna": "12e5313f5915676c4c462b87cd9f54bcaaf130d0",
                "date": 1683437046783,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1960",
                "rarity_score": 156.38296090643794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1960.png",
            "scheme": "https"
        }
    },
    "818": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #818",
                "description": "description",
                "dna": "536da748b40957ccb1e85af4d3a90cee460e9689",
                "date": 1683429908303,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "818",
                "rarity_score": 297.7752467954786,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/818.png",
            "scheme": "https"
        }
    },
    "1225": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1225",
                "description": "description",
                "dna": "f324c1a4e6d68949c54630bfca4c31977b027db9",
                "date": 1683441234974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1225",
                "rarity_score": 232.0751008706831,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1225.png",
            "scheme": "https"
        }
    },
    "307": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #307",
                "description": "description",
                "dna": "c88698fa9ad09c479b4fd43d04cb26b1c2036018",
                "date": 1683517621787,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "French Beard",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "307",
                "rarity_score": 4301.442664563324,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/307.png",
            "scheme": "https"
        }
    },
    "572": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #572",
                "description": "description",
                "dna": "92c15f33b6d00fc32bc53561884e8c0ccbf4adc8",
                "date": 1683436666477,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "572",
                "rarity_score": 194.72273026261783,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/572.png",
            "scheme": "https"
        }
    },
    "1527": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1527",
                "description": "description",
                "dna": "3e5b750146e333e3da8ff0799e1d1298a0ed14ed",
                "date": 1683431042476,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1527",
                "rarity_score": 231.6705015489388,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1527.png",
            "scheme": "https"
        }
    },
    "1503": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1503",
                "description": "description",
                "dna": "97b02c5136edf51ddc7dc60d9e4ed759a86a1df9",
                "date": 1683432937245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1503",
                "rarity_score": 180.02814335640437,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1503.png",
            "scheme": "https"
        }
    },
    "549": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #549",
                "description": "description",
                "dna": "ddb75179566d76a65e1eb20c6a2fe9edb5b510b0",
                "date": 1683441066357,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "549",
                "rarity_score": 196.54076996117055,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/549.png",
            "scheme": "https"
        }
    },
    "4073": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4073",
                "description": "description",
                "dna": "0edc92ebc3016ac2dd21599b18e3839669651e67",
                "date": 1683445181189,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "4073",
                "rarity_score": 257.66231891271434,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4073.png",
            "scheme": "https"
        }
    },
    "943": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #943",
                "description": "description",
                "dna": "8ad07810016a38ebe993502d084d694cddf463f4",
                "date": 1683439196683,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "943",
                "rarity_score": 150.7515372083446,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/943.png",
            "scheme": "https"
        }
    },
    "695": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #695",
                "description": "description",
                "dna": "8ffbf723ac633c9d33de797c47a2693b7055b11f",
                "date": 1683430226028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "695",
                "rarity_score": 189.29727412497894,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/695.png",
            "scheme": "https"
        }
    },
    "2693": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2693",
                "description": "description",
                "dna": "f2e6f7760b4e3f425b9c40acf2d079b9d927fc78",
                "date": 1683433003156,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2693",
                "rarity_score": 181.40018213464995,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2693.png",
            "scheme": "https"
        }
    },
    "746": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #746",
                "description": "description",
                "dna": "2d757c1700b6cd050f43fc9749d8c0ac5a5dc1bf",
                "date": 1683432950487,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "746",
                "rarity_score": 289.15188727020046,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/746.png",
            "scheme": "https"
        }
    },
    "2736": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2736",
                "description": "description",
                "dna": "9de8caedb69146085689eed95dde25b776c935ad",
                "date": 1683439494035,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2736",
                "rarity_score": 247.98227292542146,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2736.png",
            "scheme": "https"
        }
    },
    "86": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #86",
                "description": "description",
                "dna": "1691acaa274f19d146a05b1d2f2c0a197beb8017",
                "date": 1683438912265,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "86",
                "rarity_score": 197.13432131345198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/86.png",
            "scheme": "https"
        }
    },
    "1148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1148",
                "description": "description",
                "dna": "be0c2f1b8a36e3374a6a806505f1e0e1965224d6",
                "date": 1683432004632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1148",
                "rarity_score": 227.70316501050215,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1148.png",
            "scheme": "https"
        }
    },
    "319": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #319",
                "description": "description",
                "dna": "3b209853e003a44babb8ad62e8fc53e42b37dfed",
                "date": 1683441996704,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "319",
                "rarity_score": 247.00058772587406,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/319.png",
            "scheme": "https"
        }
    },
    "3644": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3644",
                "description": "description",
                "dna": "b23f247b86a0eea0087788c265b58bd636d79686",
                "date": 1683433221773,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3644",
                "rarity_score": 337.3023673688308,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3644.png",
            "scheme": "https"
        }
    },
    "2138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2138",
                "description": "description",
                "dna": "5750994db1579e25c40c2f99a96eb7129ed6930f",
                "date": 1683429578239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2138",
                "rarity_score": 147.4012111797307,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2138.png",
            "scheme": "https"
        }
    },
    "994": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #994",
                "description": "description",
                "dna": "f84cfe4f9b2d7d69169914f01f229375f41412e3",
                "date": 1683433914052,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "994",
                "rarity_score": 188.36934326829322,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/994.png",
            "scheme": "https"
        }
    },
    "2432": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2432",
                "description": "description",
                "dna": "ff6454a91a61a4233b228f312d2056a7bb85f5fc",
                "date": 1683436250603,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2432",
                "rarity_score": 232.20204910151793,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2432.png",
            "scheme": "https"
        }
    },
    "3646": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3646",
                "description": "description",
                "dna": "32db40bd7c19664c35d36b5d3667f4bd6f1d1e85",
                "date": 1683436294463,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3646",
                "rarity_score": 206.96034859678298,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3646.png",
            "scheme": "https"
        }
    },
    "3935": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3935",
                "description": "description",
                "dna": "a0d819b76083c06b6d23653324cefa009949ea6a",
                "date": 1683445617363,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3935",
                "rarity_score": 217.66999681244224,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3935.png",
            "scheme": "https"
        }
    },
    "989": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #989",
                "description": "description",
                "dna": "0c6259783d5dce96c1c16dcc369ba442664899b0",
                "date": 1683438522187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "989",
                "rarity_score": 227.3474536494456,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/989.png",
            "scheme": "https"
        }
    },
    "1065": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1065",
                "description": "description",
                "dna": "eca284e821d1c9ac809b5fa31c4234b0f29ce931",
                "date": 1683431726199,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1065",
                "rarity_score": 229.75726542371365,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1065.png",
            "scheme": "https"
        }
    },
    "3259": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3259",
                "description": "description",
                "dna": "ec109d45f7fbd1b907c2e57caf0d2479c6d0d711",
                "date": 1683436468931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3259",
                "rarity_score": 214.27919055165137,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3259.png",
            "scheme": "https"
        }
    },
    "2": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2",
                "description": "description",
                "dna": "0f7e46622bf43dbf9c8d5253aaba8b4b8796b81e",
                "date": 1683434625161,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2",
                "rarity_score": 207.37530647022135,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2.png",
            "scheme": "https"
        }
    },
    "448": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #448",
                "description": "description",
                "dna": "61d595e3e0d1e1fc1f1c61be4dd0ebed8456cef2",
                "date": 1683435747404,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "448",
                "rarity_score": 181.86338060051517,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/448.png",
            "scheme": "https"
        }
    },
    "1188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1188",
                "description": "description",
                "dna": "fa69c3f32c7357bab8348ff1b32fe8f723a5f2d7",
                "date": 1683432471335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1188",
                "rarity_score": 265.9860719102615,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1188.png",
            "scheme": "https"
        }
    },
    "3989": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3989",
                "description": "description",
                "dna": "d875a4ec2b10d49b07b5625fb085cd7c84cdd63d",
                "date": 1683436683967,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3989",
                "rarity_score": 291.33752627461763,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3989.png",
            "scheme": "https"
        }
    },
    "806": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #806",
                "description": "description",
                "dna": "4ff78d44d2b00140fe70b1313a868dba66fea681",
                "date": 1683774901983,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "806",
                "rarity_score": 320.80206133287925,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/806.png",
            "scheme": "https"
        }
    },
    "2451": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2451",
                "description": "description",
                "dna": "bda7ec89cc3c24afcc0fc46098bea92b0108988c",
                "date": 1683430624441,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2451",
                "rarity_score": 267.1899899960215,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2451.png",
            "scheme": "https"
        }
    },
    "669": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #669",
                "description": "description",
                "dna": "12528ac3bc97481df37944bcdd06e50d4e862dac",
                "date": 1683438325905,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "669",
                "rarity_score": 220.6273518020473,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/669.png",
            "scheme": "https"
        }
    },
    "3028": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3028",
                "description": "description",
                "dna": "4388a36b4441fb8fbe6c2ce61863eafc894e3dbd",
                "date": 1683434294794,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3028",
                "rarity_score": 194.9764520206818,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3028.png",
            "scheme": "https"
        }
    },
    "3013": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3013",
                "description": "description",
                "dna": "f25b8df412a002f9239053ad4d7ff2bce2cfb316",
                "date": 1683441048877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3013",
                "rarity_score": 171.62711827369688,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3013.png",
            "scheme": "https"
        }
    },
    "2054": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2054",
                "description": "description",
                "dna": "8907c807c38d5c903674088657b37644787dd443",
                "date": 1683446738625,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2054",
                "rarity_score": 170.64124567541947,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2054.png",
            "scheme": "https"
        }
    },
    "275": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #275",
                "description": "description",
                "dna": "9db33c61af6339866496bcff5e3347f14cccfe59",
                "date": 1683439460955,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "275",
                "rarity_score": 169.08614872452574,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/275.png",
            "scheme": "https"
        }
    },
    "2649": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2649",
                "description": "description",
                "dna": "f9ce515f8d531af2663b0658b08a467707b00572",
                "date": 1683441044350,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2649",
                "rarity_score": 185.24949324816328,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2649.png",
            "scheme": "https"
        }
    },
    "794": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #794",
                "description": "description",
                "dna": "e228782f265ca3ec7d82530c96c46af57bb6a6a5",
                "date": 1683436321604,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "794",
                "rarity_score": 224.41040255173274,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/794.png",
            "scheme": "https"
        }
    },
    "3252": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3252",
                "description": "description",
                "dna": "80700966ff1e698680ac58ff1da2cbfa36f03ac6",
                "date": 1683444738319,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3252",
                "rarity_score": 209.01840134367146,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3252.png",
            "scheme": "https"
        }
    },
    "41": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #41",
                "description": "description",
                "dna": "d540d3a82c884124421c5b56c1bc2b9abd6e215d",
                "date": 1683429723952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "41",
                "rarity_score": 201.9265338911177,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/41.png",
            "scheme": "https"
        }
    },
    "3720": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3720",
                "description": "description",
                "dna": "d5b2e9140bfb554e631abca4d0acb830a9b9f8c9",
                "date": 1683439403584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3720",
                "rarity_score": 167.9890698451144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3720.png",
            "scheme": "https"
        }
    },
    "2043": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2043",
                "description": "description",
                "dna": "1c7a27022b2201404d156567cbde722f9a4fc572",
                "date": 1683438944292,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2043",
                "rarity_score": 303.4343263821439,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2043.png",
            "scheme": "https"
        }
    },
    "2575": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2575",
                "description": "description",
                "dna": "2fc8d287aa87903015909b6809c963d69cea2c54",
                "date": 1683443468641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2575",
                "rarity_score": 255.15209930143914,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2575.png",
            "scheme": "https"
        }
    },
    "1019": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1019",
                "description": "description",
                "dna": "1c3522f3181984929f10325dbefb9822803bae86",
                "date": 1683447236993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1019",
                "rarity_score": 311.52255035041526,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1019.png",
            "scheme": "https"
        }
    },
    "217": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #217",
                "description": "description",
                "dna": "191cc91bc05f64226ea033e58c2bec68974b800f",
                "date": 1683430469296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "217",
                "rarity_score": 225.58748076156104,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/217.png",
            "scheme": "https"
        }
    },
    "283": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #283",
                "description": "description",
                "dna": "86d961d5aa6ea513529d8d13b7e009d1e7856129",
                "date": 1683431221151,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "283",
                "rarity_score": 227.16181075928372,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/283.png",
            "scheme": "https"
        }
    },
    "3247": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3247",
                "description": "description",
                "dna": "b9bf3dd0bebe5ce31d3299913715aea37e46fe67",
                "date": 1683774583821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3247",
                "rarity_score": 226.5914691735338,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3247.png",
            "scheme": "https"
        }
    },
    "2957": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2957",
                "description": "description",
                "dna": "22d0c517da0113f3af716f8767ca76dd2c70e0fc",
                "date": 1683443150607,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2957",
                "rarity_score": 210.6000164263917,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2957.png",
            "scheme": "https"
        }
    },
    "3095": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3095",
                "description": "description",
                "dna": "0c8bc51805409a801fc0199e1af7121f76661e39",
                "date": 1683444012280,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3095",
                "rarity_score": 184.6263941144673,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3095.png",
            "scheme": "https"
        }
    },
    "2325": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2325",
                "description": "description",
                "dna": "6e258c13e95ac01b59366faff4bb560b94309e47",
                "date": 1683440891198,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2325",
                "rarity_score": 181.84838414337008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2325.png",
            "scheme": "https"
        }
    },
    "3593": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3593",
                "description": "description",
                "dna": "2466e8090481a5880ac3854ad536b39102a19873",
                "date": 1683440179857,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3593",
                "rarity_score": 199.07874868985476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3593.png",
            "scheme": "https"
        }
    },
    "3937": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3937",
                "description": "description",
                "dna": "b364714b82345c56e6cb647f9573f86d00acae47",
                "date": 1683443927051,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3937",
                "rarity_score": 291.1128714202986,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3937.png",
            "scheme": "https"
        }
    },
    "1613": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1613",
                "description": "description",
                "dna": "0d32ddd1114bc1b85843758825270d39eb6cda9d",
                "date": 1683435350450,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1613",
                "rarity_score": 178.43064171338787,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1613.png",
            "scheme": "https"
        }
    },
    "3919": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3919",
                "description": "description",
                "dna": "25bae75955a1b88f3bc77a2c7b5e2021616ae51b",
                "date": 1683430003593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3919",
                "rarity_score": 158.52057952385366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3919.png",
            "scheme": "https"
        }
    },
    "2894": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2894",
                "description": "description",
                "dna": "05a5a775e6defaa4600a3037c2029607c972153f",
                "date": 1683444090625,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2894",
                "rarity_score": 198.58807026245805,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2894.png",
            "scheme": "https"
        }
    },
    "2756": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2756",
                "description": "description",
                "dna": "7c4eeb86de18ed9a1a8223681429b5f575a02b94",
                "date": 1683776430261,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2756",
                "rarity_score": 323.19578653063303,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2756.png",
            "scheme": "https"
        }
    },
    "3576": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3576",
                "description": "description",
                "dna": "b1893ed1cc2a8b2726bdf6f484f461a181307535",
                "date": 1683435321208,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3576",
                "rarity_score": 215.49450812716643,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3576.png",
            "scheme": "https"
        }
    },
    "3782": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3782",
                "description": "description",
                "dna": "1967145e3b1d5107b2420728d227fbee70d5d8fc",
                "date": 1683429710412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3782",
                "rarity_score": 376.8990110165099,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3782.png",
            "scheme": "https"
        }
    },
    "3224": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3224",
                "description": "description",
                "dna": "44a82fc0438ea1e7a3df4e61117f771ea3908b5c",
                "date": 1683441917572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3224",
                "rarity_score": 154.27526074949444,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3224.png",
            "scheme": "https"
        }
    },
    "3264": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3264",
                "description": "description",
                "dna": "bc58d9477c7f6562b7a407ee0d8dc19fea59fad0",
                "date": 1683438228990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3264",
                "rarity_score": 226.61331401320044,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3264.png",
            "scheme": "https"
        }
    },
    "3434": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3434",
                "description": "description",
                "dna": "934890e735e2f399d6a329048277fc8725afaf43",
                "date": 1683439983838,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3434",
                "rarity_score": 258.0041912173278,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3434.png",
            "scheme": "https"
        }
    },
    "1371": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1371",
                "description": "description",
                "dna": "d59f4114c0466cb3f493acbfe2a4b65affdf97e5",
                "date": 1683438544990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1371",
                "rarity_score": 206.23995820579498,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1371.png",
            "scheme": "https"
        }
    },
    "3044": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3044",
                "description": "description",
                "dna": "b3e00c03401d283355d9d9dedbfd1d5aaa5b0675",
                "date": 1683439842205,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3044",
                "rarity_score": 175.56361114839115,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3044.png",
            "scheme": "https"
        }
    },
    "3307": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3307",
                "description": "description",
                "dna": "a970c2786eab1142280d02b762a3b1b9db80d8df",
                "date": 1683435042301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3307",
                "rarity_score": 188.00160699350778,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3307.png",
            "scheme": "https"
        }
    },
    "2546": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2546",
                "description": "description",
                "dna": "9680d2d3aa5cfb0c79187cd84dfe099ec7907d41",
                "date": 1683432335466,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2546",
                "rarity_score": 174.2862940053821,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2546.png",
            "scheme": "https"
        }
    },
    "2820": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2820",
                "description": "description",
                "dna": "f2e2d5832b59f7c615cadb0c2f176af62df2e372",
                "date": 1683770527475,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2820",
                "rarity_score": 243.8791029628006,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2820.png",
            "scheme": "https"
        }
    },
    "1962": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1962",
                "description": "description",
                "dna": "14adb3f50a5c81dfc3672eceffe3a7f06d90fe92",
                "date": 1683442624518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1962",
                "rarity_score": 186.7265606042184,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1962.png",
            "scheme": "https"
        }
    },
    "1218": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1218",
                "description": "description",
                "dna": "2dc3b99f6e5497eda705811736ebfc7e9f7ab644",
                "date": 1683429777875,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1218",
                "rarity_score": 159.31894468758364,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1218.png",
            "scheme": "https"
        }
    },
    "3894": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3894",
                "description": "description",
                "dna": "081ac7cafa84ef55e6fb9eefc0ffcdaaa8f74182",
                "date": 1683434942193,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3894",
                "rarity_score": 208.3474651844092,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3894.png",
            "scheme": "https"
        }
    },
    "3577": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3577",
                "description": "description",
                "dna": "fa842a85c62c50af73436f5443a6dc0aebd41f36",
                "date": 1683433904915,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3577",
                "rarity_score": 197.8191080669066,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3577.png",
            "scheme": "https"
        }
    },
    "3816": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3816",
                "description": "description",
                "dna": "f4128c615b80d5413c2eb268086d7a2175e20233",
                "date": 1683517639028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "GnomeLast",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3816",
                "rarity_score": 4339.2652805675625,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3816.png",
            "scheme": "https"
        }
    },
    "185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #185",
                "description": "description",
                "dna": "0de998712659271ed2dd0d8e1f6feaef58585822",
                "date": 1683435311804,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "185",
                "rarity_score": 181.16512114505514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/185.png",
            "scheme": "https"
        }
    },
    "1591": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1591",
                "description": "description",
                "dna": "2a18fd611e75c7aa097e9cf2d0e9b14cc0cfa22f",
                "date": 1683439768560,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1591",
                "rarity_score": 229.28853691768742,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1591.png",
            "scheme": "https"
        }
    },
    "838": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #838",
                "description": "description",
                "dna": "653135a9a8193b4cc0db3837d7090535dc83e805",
                "date": 1683441030186,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "838",
                "rarity_score": 170.68088871727662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/838.png",
            "scheme": "https"
        }
    },
    "1998": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1998",
                "description": "description",
                "dna": "183b64ab6b3eeda39e538b62aec125b6d308ff7e",
                "date": 1683430287493,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1998",
                "rarity_score": 229.6505358209446,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1998.png",
            "scheme": "https"
        }
    },
    "2607": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2607",
                "description": "description",
                "dna": "5799cccef73abad17e655cc70745899a6eadd563",
                "date": 1683436957602,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2607",
                "rarity_score": 189.17748607938952,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2607.png",
            "scheme": "https"
        }
    },
    "3663": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3663",
                "description": "description",
                "dna": "91317a3a77bb65027be24892c40af5c69d923628",
                "date": 1683770598122,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3663",
                "rarity_score": 240.69619907852888,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3663.png",
            "scheme": "https"
        }
    },
    "1716": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1716",
                "description": "description",
                "dna": "eb92ad3baa7cae0c2acc257ce748463ff4390a86",
                "date": 1683439979259,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1716",
                "rarity_score": 215.9913348465138,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1716.png",
            "scheme": "https"
        }
    },
    "709": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #709",
                "description": "description",
                "dna": "a662f1f38f2820139d2178cfe4bccedf402e5517",
                "date": 1683430043388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "709",
                "rarity_score": 189.3525661881514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/709.png",
            "scheme": "https"
        }
    },
    "3881": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3881",
                "description": "description",
                "dna": "ec19693069fb0645aac89d3d6d9e242287695534",
                "date": 1683443159848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3881",
                "rarity_score": 165.43885524740944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3881.png",
            "scheme": "https"
        }
    },
    "3087": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3087",
                "description": "description",
                "dna": "e09a832aa3523716fc06f4a473e08fb2d0aaef32",
                "date": 1683431106642,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3087",
                "rarity_score": 166.30781946031505,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3087.png",
            "scheme": "https"
        }
    },
    "1082": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1082",
                "description": "description",
                "dna": "9c97aeaf2bdab7cd0c0919637b699b6e297d0175",
                "date": 1683439272867,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1082",
                "rarity_score": 201.71170114695073,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1082.png",
            "scheme": "https"
        }
    },
    "3338": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3338",
                "description": "description",
                "dna": "6c9b8d41762b7517a109db2bd3417a18329c2ed3",
                "date": 1683433321503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3338",
                "rarity_score": 163.06277057400058,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3338.png",
            "scheme": "https"
        }
    },
    "248": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #248",
                "description": "description",
                "dna": "d029fa63ba0cc813d5be6c4cb776b57c6e4d0f77",
                "date": 1683437388220,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "248",
                "rarity_score": 132.6017999116034,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/248.png",
            "scheme": "https"
        }
    },
    "895": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #895",
                "description": "description",
                "dna": "5b0afd3d9359eefc5e67ceddd6a42b6cf7fb971d",
                "date": 1683438939958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "895",
                "rarity_score": 160.9660485101429,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/895.png",
            "scheme": "https"
        }
    },
    "4019": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4019",
                "description": "description",
                "dna": "8b43530da06d829e70b43816eb041452c66ca9da",
                "date": 1683433249021,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "4019",
                "rarity_score": 327.93850823194884,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4019.png",
            "scheme": "https"
        }
    },
    "2609": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2609",
                "description": "description",
                "dna": "1e5dfca89eb2e3c4699abcbb639527fd499e6a2d",
                "date": 1683441226252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2609",
                "rarity_score": 166.60494531492327,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2609.png",
            "scheme": "https"
        }
    },
    "2690": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2690",
                "description": "description",
                "dna": "8bcd4f808ed2a478bdc6a1871303518dbf5d6834",
                "date": 1683430243437,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2690",
                "rarity_score": 205.7269284251388,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2690.png",
            "scheme": "https"
        }
    },
    "387": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #387",
                "description": "description",
                "dna": "679532e278b7945f7aa8d08680bcbf32ff69ac34",
                "date": 1683432229178,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "387",
                "rarity_score": 230.10227772971368,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/387.png",
            "scheme": "https"
        }
    },
    "1069": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1069",
                "description": "description",
                "dna": "54d8aeb88f5e945ee0f3df93aaa49e7529405403",
                "date": 1683430283128,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1069",
                "rarity_score": 199.61754001254752,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1069.png",
            "scheme": "https"
        }
    },
    "1212": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1212",
                "description": "description",
                "dna": "2802f7078fabb3507cc597eeadbe78c4c9f0ed91",
                "date": 1683445243371,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1212",
                "rarity_score": 205.812867595806,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1212.png",
            "scheme": "https"
        }
    },
    "2943": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2943",
                "description": "description",
                "dna": "d81d795b11da8cd8fb49550285d00ac9f7410f48",
                "date": 1683443215755,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2943",
                "rarity_score": 233.4131388148092,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2943.png",
            "scheme": "https"
        }
    },
    "2542": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2542",
                "description": "description",
                "dna": "6be4586887125b8291cc88fc361bfb8910fb522b",
                "date": 1683445931245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2542",
                "rarity_score": 197.18309775794566,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2542.png",
            "scheme": "https"
        }
    },
    "3714": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3714",
                "description": "description",
                "dna": "534a251e835c5d4b281ea83d93f268a1baabb3e1",
                "date": 1683446447233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3714",
                "rarity_score": 132.51040719662274,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3714.png",
            "scheme": "https"
        }
    },
    "1448": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1448",
                "description": "description",
                "dna": "10cdac61edf429c6b4ca6cba7d74a6b375a1e8de",
                "date": 1683439174099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1448",
                "rarity_score": 176.636449361119,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1448.png",
            "scheme": "https"
        }
    },
    "1072": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1072",
                "description": "description",
                "dna": "fd54e14922397016769713b97484afa69a04f742",
                "date": 1683442023661,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1072",
                "rarity_score": 227.39981130282695,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1072.png",
            "scheme": "https"
        }
    },
    "596": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #596",
                "description": "description",
                "dna": "9518c19d1372ac98135ed960d093e6125a77177b",
                "date": 1683439627593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "596",
                "rarity_score": 198.27722230871487,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/596.png",
            "scheme": "https"
        }
    },
    "749": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #749",
                "description": "description",
                "dna": "938fb22c98a80246252a4027f88c097eeb36118a",
                "date": 1683433312737,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "749",
                "rarity_score": 194.10233757889148,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/749.png",
            "scheme": "https"
        }
    },
    "2393": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2393",
                "description": "description",
                "dna": "91637acfdf0d19ceb2d5fa9510e1a902378bef22",
                "date": 1683433676930,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2393",
                "rarity_score": 284.87353423258264,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2393.png",
            "scheme": "https"
        }
    },
    "3767": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3767",
                "description": "description",
                "dna": "0adbdddf20cd99b9f178804d54255d394bd8997d",
                "date": 1683437014253,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3767",
                "rarity_score": 126.91788500941615,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3767.png",
            "scheme": "https"
        }
    },
    "1149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1149",
                "description": "description",
                "dna": "335c1820419eee94d7827e4502e6c1d6b8f9f978",
                "date": 1683774448762,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1149",
                "rarity_score": 247.1079940475049,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1149.png",
            "scheme": "https"
        }
    },
    "1315": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1315",
                "description": "description",
                "dna": "db593fff70c537e149b5f2e970cfdab65bb2a2c0",
                "date": 1683437949061,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1315",
                "rarity_score": 173.56742574376045,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1315.png",
            "scheme": "https"
        }
    },
    "3176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3176",
                "description": "description",
                "dna": "888df4762d191729eb04eaa90b1896a1200111a6",
                "date": 1683440982722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3176",
                "rarity_score": 183.18088468213267,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3176.png",
            "scheme": "https"
        }
    },
    "4003": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4003",
                "description": "description",
                "dna": "b9ff8f3d809f10a8f1eed31b7f8a4c55c9f10eba",
                "date": 1683430144946,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "4003",
                "rarity_score": 157.14180306110757,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4003.png",
            "scheme": "https"
        }
    },
    "186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #186",
                "description": "description",
                "dna": "554c80162bd1f6f4dd7d0cf050f1a0607f0f5cec",
                "date": 1683440613055,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "186",
                "rarity_score": 235.17744156745175,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/186.png",
            "scheme": "https"
        }
    },
    "3814": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3814",
                "description": "description",
                "dna": "d9ac3106050cb2fc9da0101d362daf83b7f28c4c",
                "date": 1683432151571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3814",
                "rarity_score": 223.2922882626835,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3814.png",
            "scheme": "https"
        }
    },
    "2446": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2446",
                "description": "description",
                "dna": "77a10cd0387996577c764a72f78a86f7163e35ca",
                "date": 1683432520229,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2446",
                "rarity_score": 192.78847968183055,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2446.png",
            "scheme": "https"
        }
    },
    "76": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #76",
                "description": "description",
                "dna": "6f6a8d8971338f64731e739bf3d4448fdb7c26cb",
                "date": 1683432734506,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "76",
                "rarity_score": 199.0016508531291,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/76.png",
            "scheme": "https"
        }
    },
    "1047": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1047",
                "description": "description",
                "dna": "92885564c415dcfb6b34806601859c7361f03b56",
                "date": 1683430544104,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1047",
                "rarity_score": 219.12255366518622,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1047.png",
            "scheme": "https"
        }
    },
    "2235": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2235",
                "description": "description",
                "dna": "accd016b26c3db917ee6661ac560068801881b77",
                "date": 1683436204948,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2235",
                "rarity_score": 226.06733817788023,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2235.png",
            "scheme": "https"
        }
    },
    "742": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #742",
                "description": "description",
                "dna": "587c6106fabf8e6eb1b806c08f1dc05b2078be9e",
                "date": 1683429808648,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "742",
                "rarity_score": 174.75340205568483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/742.png",
            "scheme": "https"
        }
    },
    "1686": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1686",
                "description": "description",
                "dna": "b0e197cfba3e57fcc9e787275669413f6d6f1090",
                "date": 1683432169072,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1686",
                "rarity_score": 202.17748939500026,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1686.png",
            "scheme": "https"
        }
    },
    "7": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #7",
                "description": "description",
                "dna": "f33c915fce40d4894a5b8aad7a1fa19102efbfc8",
                "date": 1683771281412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "7",
                "rarity_score": 180.81249089853137,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/7.png",
            "scheme": "https"
        }
    },
    "3656": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3656",
                "description": "description",
                "dna": "783f30532ffeaa598c66bfc0c83168eda6199a23",
                "date": 1683770651786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3656",
                "rarity_score": 261.1677123654439,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3656.png",
            "scheme": "https"
        }
    },
    "1247": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1247",
                "description": "description",
                "dna": "a7262dfbc5c0d4bf95504a9ae8c74cb6f0227b7c",
                "date": 1683430131729,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1247",
                "rarity_score": 227.4575621012814,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1247.png",
            "scheme": "https"
        }
    },
    "3319": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3319",
                "description": "description",
                "dna": "f55b92c0673b9145a5a4a527435438fe35d5568b",
                "date": 1683441641320,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3319",
                "rarity_score": 165.13559348199357,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3319.png",
            "scheme": "https"
        }
    },
    "3215": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3215",
                "description": "description",
                "dna": "f11649dc0bdd609959c088c557938ec995a71d79",
                "date": 1683438019228,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3215",
                "rarity_score": 341.0954071600073,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3215.png",
            "scheme": "https"
        }
    },
    "3132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3132",
                "description": "description",
                "dna": "314947f9e2b87a82c205d1a301441c5c75977168",
                "date": 1683445026600,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3132",
                "rarity_score": 222.71812061436285,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3132.png",
            "scheme": "https"
        }
    },
    "2198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2198",
                "description": "description",
                "dna": "34a38663511242f5d40a65f0205cd4950718baba",
                "date": 1683438535763,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2198",
                "rarity_score": 183.70137142013425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2198.png",
            "scheme": "https"
        }
    },
    "2361": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2361",
                "description": "description",
                "dna": "d606be2b9fc6245f05f6aa03f62c4403816d60d9",
                "date": 1683445692101,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2361",
                "rarity_score": 215.1078147368934,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2361.png",
            "scheme": "https"
        }
    },
    "3300": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3300",
                "description": "description",
                "dna": "b34faf4712accc9ba98d30279eeebfc9580e0133",
                "date": 1683433082837,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3300",
                "rarity_score": 210.1265942818874,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3300.png",
            "scheme": "https"
        }
    },
    "652": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #652",
                "description": "description",
                "dna": "f4736336aa18fd3b92ee1bf8ee65a1dc6e759180",
                "date": 1683440129816,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "652",
                "rarity_score": 258.84654712032125,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/652.png",
            "scheme": "https"
        }
    },
    "222": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #222",
                "description": "description",
                "dna": "f03622c5a031ad0890e2c49ecb66842d1866fc28",
                "date": 1683429599206,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "222",
                "rarity_score": 301.79529690560287,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/222.png",
            "scheme": "https"
        }
    },
    "2178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2178",
                "description": "description",
                "dna": "7af0ce3e2f4497633c18d14de144a580d6b322c8",
                "date": 1683447407074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2178",
                "rarity_score": 217.74122727002637,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2178.png",
            "scheme": "https"
        }
    },
    "3237": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3237",
                "description": "description",
                "dna": "5f60aa9f32327d18e2ab34ca8947d064ff12bab7",
                "date": 1683441188767,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3237",
                "rarity_score": 169.39213570825004,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3237.png",
            "scheme": "https"
        }
    },
    "1050": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1050",
                "description": "description",
                "dna": "0174de973a6668d6af360b8507b45c96934e61b7",
                "date": 1683432537716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1050",
                "rarity_score": 141.84294302802934,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1050.png",
            "scheme": "https"
        }
    },
    "2163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2163",
                "description": "description",
                "dna": "4be547416c22b3cef0b42e493e67a25c626f91a2",
                "date": 1683443788282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2163",
                "rarity_score": 200.29648827023155,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2163.png",
            "scheme": "https"
        }
    },
    "4056": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4056",
                "description": "description",
                "dna": "92706389dc222d793416c4d92ac63a9e6f939835",
                "date": 1683775205471,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4056",
                "rarity_score": 220.6919136418187,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4056.png",
            "scheme": "https"
        }
    },
    "130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #130",
                "description": "description",
                "dna": "0bf3c9776d40c66831175faf60f71d27b3e7eb7d",
                "date": 1683441845453,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "130",
                "rarity_score": 257.1216696975038,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/130.png",
            "scheme": "https"
        }
    },
    "673": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #673",
                "description": "description",
                "dna": "52e8466ca9678db06ec41d8a61f05d6b78ffd0db",
                "date": 1683443656412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "673",
                "rarity_score": 164.01316414945887,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/673.png",
            "scheme": "https"
        }
    },
    "2919": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2919",
                "description": "description",
                "dna": "4c7f153285713941cbcca257e2eacbbfc214a656",
                "date": 1683430136212,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2919",
                "rarity_score": 272.45212148500923,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2919.png",
            "scheme": "https"
        }
    },
    "3984": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3984",
                "description": "description",
                "dna": "1d9bcb8a8360d59e3cd9dda866c58acb270665a1",
                "date": 1683442652354,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3984",
                "rarity_score": 146.1234442860613,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3984.png",
            "scheme": "https"
        }
    },
    "2909": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2909",
                "description": "description",
                "dna": "e5f13618471fe38fd5cd8b73abc1d7712445c5cb",
                "date": 1683430150165,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2909",
                "rarity_score": 239.36599163900541,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2909.png",
            "scheme": "https"
        }
    },
    "3419": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3419",
                "description": "description",
                "dna": "29bb5d7794a14ab1ff1468b1228f256ca24f08b2",
                "date": 1683443408430,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3419",
                "rarity_score": 190.5467020028229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3419.png",
            "scheme": "https"
        }
    },
    "2984": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2984",
                "description": "description",
                "dna": "8524ed8e35a5ba966b40ae156ba89b6590c0d068",
                "date": 1683775214010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2984",
                "rarity_score": 175.59332625032158,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2984.png",
            "scheme": "https"
        }
    },
    "706": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #706",
                "description": "description",
                "dna": "7f2c9a767dc93bbb6578244bcc62b66226ce11a1",
                "date": 1683445852533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "706",
                "rarity_score": 200.89681801685612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/706.png",
            "scheme": "https"
        }
    },
    "3831": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3831",
                "description": "description",
                "dna": "c806a334d5d3468ea7edbec63c1ed76b67dcc6d5",
                "date": 1683442364446,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3831",
                "rarity_score": 154.6083782968353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3831.png",
            "scheme": "https"
        }
    },
    "1054": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1054",
                "description": "description",
                "dna": "8d8768776fa4f2a541e1dd928c79304441cd90a9",
                "date": 1683770581357,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1054",
                "rarity_score": 338.54555024341937,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1054.png",
            "scheme": "https"
        }
    },
    "754": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #754",
                "description": "description",
                "dna": "774656f9732992a083dac3bb394d3e21617e5ce9",
                "date": 1683444607734,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "754",
                "rarity_score": 342.29148972547364,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/754.png",
            "scheme": "https"
        }
    },
    "1325": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1325",
                "description": "description",
                "dna": "b0e5a6dc09e9820d1dc39d02643e10fd8cbe471c",
                "date": 1683442286056,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1325",
                "rarity_score": 172.72400778252035,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1325.png",
            "scheme": "https"
        }
    },
    "2215": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2215",
                "description": "description",
                "dna": "42ef65224b71d2ac57e0642b4e02c2cb26304b86",
                "date": 1683439548056,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2215",
                "rarity_score": 206.04518416885466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2215.png",
            "scheme": "https"
        }
    },
    "1985": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1985",
                "description": "description",
                "dna": "813b4a5d9c220cae7aa90c1558e4364da9054388",
                "date": 1683445288497,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1985",
                "rarity_score": 215.35431573808498,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1985.png",
            "scheme": "https"
        }
    },
    "3112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3112",
                "description": "description",
                "dna": "f5db5097546ca9fb324e46b199fe816bc65dc0cb",
                "date": 1683447380091,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3112",
                "rarity_score": 171.34640067649613,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3112.png",
            "scheme": "https"
        }
    },
    "362": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #362",
                "description": "description",
                "dna": "0f6af9bcd5de229083d742f154da8974545092cc",
                "date": 1683776711565,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "362",
                "rarity_score": 198.41380211567153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/362.png",
            "scheme": "https"
        }
    },
    "31": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #31",
                "description": "description",
                "dna": "d3765c54089aae5634a3f1b7967931dec75cd536",
                "date": 1683433439164,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "31",
                "rarity_score": 224.68197166380696,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/31.png",
            "scheme": "https"
        }
    },
    "2420": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2420",
                "description": "description",
                "dna": "2959201ba40574ba93d44e39d9f41872d0731847",
                "date": 1683444969260,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2420",
                "rarity_score": 199.16521964680058,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2420.png",
            "scheme": "https"
        }
    },
    "2887": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2887",
                "description": "description",
                "dna": "3189ff80c03ea1f5ba71ec3abf78e04978ceb8de",
                "date": 1683444393090,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2887",
                "rarity_score": 212.253848121466,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2887.png",
            "scheme": "https"
        }
    },
    "1913": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1913",
                "description": "description",
                "dna": "12e2e98b6ed23f073e5a5ce515d8799e02e8628f",
                "date": 1683436796586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1913",
                "rarity_score": 194.3769870098557,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1913.png",
            "scheme": "https"
        }
    },
    "3877": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3877",
                "description": "description",
                "dna": "2a52f9a472cd6f1c7ec0249f48fa21a05430b5ab",
                "date": 1683439905847,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3877",
                "rarity_score": 175.97362150130039,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3877.png",
            "scheme": "https"
        }
    },
    "1810": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1810",
                "description": "description",
                "dna": "c92fc6ca0181f319042fd7d7fcc2bae190680683",
                "date": 1683770485971,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1810",
                "rarity_score": 246.05677414897264,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1810.png",
            "scheme": "https"
        }
    },
    "2616": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2616",
                "description": "description",
                "dna": "57d1b63ccd3a73577b03a72af84c69f537c1054d",
                "date": 1683437628344,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2616",
                "rarity_score": 174.24033423811733,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2616.png",
            "scheme": "https"
        }
    },
    "2594": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2594",
                "description": "description",
                "dna": "b87c8aeb6ab1ec21ee91fa2f8ff43c40955c816f",
                "date": 1683445547191,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2594",
                "rarity_score": 199.52813601731415,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2594.png",
            "scheme": "https"
        }
    },
    "3804": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3804",
                "description": "description",
                "dna": "cecae8c259e97cd8dab97c9caae65ccd3872bbaa",
                "date": 1683446704918,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3804",
                "rarity_score": 236.36072451831177,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3804.png",
            "scheme": "https"
        }
    },
    "3189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3189",
                "description": "description",
                "dna": "c77fa25496c44058dc966e1afe92780398a87315",
                "date": 1683438719677,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3189",
                "rarity_score": 276.79026268415043,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3189.png",
            "scheme": "https"
        }
    },
    "3837": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3837",
                "description": "description",
                "dna": "9825c6220c72457a8ff35aa728dc385f7053b547",
                "date": 1683447040408,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3837",
                "rarity_score": 271.6876542524142,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3837.png",
            "scheme": "https"
        }
    },
    "499": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #499",
                "description": "description",
                "dna": "ccb7c6e003dc88d05f3a6277c5d4540f36b4dd3b",
                "date": 1683443895868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "499",
                "rarity_score": 211.49009418595008,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/499.png",
            "scheme": "https"
        }
    },
    "1483": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1483",
                "description": "description",
                "dna": "fe59e88b627f386c45c08ebd702549a4a5c7c133",
                "date": 1683435863923,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1483",
                "rarity_score": 204.15645580649692,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1483.png",
            "scheme": "https"
        }
    },
    "2617": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2617",
                "description": "description",
                "dna": "d8e5f407e2fa081afff194b9b37f5e8dc07c9532",
                "date": 1683433011985,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2617",
                "rarity_score": 211.97789762669018,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2617.png",
            "scheme": "https"
        }
    },
    "3275": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3275",
                "description": "description",
                "dna": "b2be076ea792847889008dbb05695def086cec9e",
                "date": 1683771391872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3275",
                "rarity_score": 307.9482057266636,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3275.png",
            "scheme": "https"
        }
    },
    "3094": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3094",
                "description": "description",
                "dna": "55ee6585a1066c111af1caa78ef23d1432f8005c",
                "date": 1683442762650,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3094",
                "rarity_score": 193.1070741645021,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3094.png",
            "scheme": "https"
        }
    },
    "1411": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1411",
                "description": "description",
                "dna": "c467ecdd48dafb45658e7dbfcb35834ac247e6fd",
                "date": 1683444780825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1411",
                "rarity_score": 169.15504983555945,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1411.png",
            "scheme": "https"
        }
    },
    "3960": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3960",
                "description": "description",
                "dna": "047d6c4aa641440c51598069603ae7b46ee73771",
                "date": 1683433726956,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3960",
                "rarity_score": 307.44251273406974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3960.png",
            "scheme": "https"
        }
    },
    "1083": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1083",
                "description": "description",
                "dna": "6cd7b3c00c6481a84479943a1f35e99b9577b31b",
                "date": 1683771526547,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1083",
                "rarity_score": 301.98961187598945,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1083.png",
            "scheme": "https"
        }
    },
    "218": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #218",
                "description": "description",
                "dna": "340fef4504b3a038b5acd8a9c922bcb91a5f6c72",
                "date": 1683429804374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "218",
                "rarity_score": 188.01905183959627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/218.png",
            "scheme": "https"
        }
    },
    "2341": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2341",
                "description": "description",
                "dna": "6f10585276ecba26dff10533ea6ea93533cea627",
                "date": 1683434258824,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2341",
                "rarity_score": 196.28389417079055,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2341.png",
            "scheme": "https"
        }
    },
    "3582": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3582",
                "description": "description",
                "dna": "743a25b87904e6b74da5d2643a870016147fb6f2",
                "date": 1683446090271,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3582",
                "rarity_score": 136.8361815791675,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3582.png",
            "scheme": "https"
        }
    },
    "3077": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3077",
                "description": "description",
                "dna": "239358dcaf7e96fd15a1c5037cd52140ae92d927",
                "date": 1683444510837,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3077",
                "rarity_score": 217.3601228732256,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3077.png",
            "scheme": "https"
        }
    },
    "1731": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1731",
                "description": "description",
                "dna": "d635687643d63f91b4c588ded55e44423e98f458",
                "date": 1683443634187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1731",
                "rarity_score": 191.8890793404069,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1731.png",
            "scheme": "https"
        }
    },
    "2823": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2823",
                "description": "description",
                "dna": "e2125c52d6a3bef8ed273b7f75f317cc094275fe",
                "date": 1683440395330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2823",
                "rarity_score": 228.97753896563702,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2823.png",
            "scheme": "https"
        }
    },
    "1320": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1320",
                "description": "description",
                "dna": "4856ce6c0ac238618f5b0c7ebb3427088c0bb3ba",
                "date": 1683438770076,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1320",
                "rarity_score": 182.7958538674845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1320.png",
            "scheme": "https"
        }
    },
    "3271": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3271",
                "description": "description",
                "dna": "2ee4de1bb05037242f582001a71665e370af8c0f",
                "date": 1683429839035,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3271",
                "rarity_score": 283.11760240521926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3271.png",
            "scheme": "https"
        }
    },
    "1257": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1257",
                "description": "description",
                "dna": "8e053486e71c09dacde88de3f71689b80bada25b",
                "date": 1683433470058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1257",
                "rarity_score": 190.0762624858745,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1257.png",
            "scheme": "https"
        }
    },
    "2388": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2388",
                "description": "description",
                "dna": "d0bb38d0e5c27f076d82832509dc42a4b9692f39",
                "date": 1683443550807,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2388",
                "rarity_score": 299.23334508130057,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2388.png",
            "scheme": "https"
        }
    },
    "3326": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3326",
                "description": "description",
                "dna": "068bb70ef7c9534dbf0ec165794fd94ca23758ba",
                "date": 1683442904549,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3326",
                "rarity_score": 201.4145496290721,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3326.png",
            "scheme": "https"
        }
    },
    "2896": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2896",
                "description": "description",
                "dna": "64e04c54d63fe693a4000d0112c9d1ef1a58f2b6",
                "date": 1683430838619,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2896",
                "rarity_score": 169.09341885859774,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2896.png",
            "scheme": "https"
        }
    },
    "3797": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3797",
                "description": "description",
                "dna": "b2fa653f77bd62a9ed96cecd47a2b6ba2194a06f",
                "date": 1683431790933,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3797",
                "rarity_score": 176.61795109917782,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3797.png",
            "scheme": "https"
        }
    },
    "1857": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1857",
                "description": "description",
                "dna": "4f986b0f137fc38f398cb3e272d511167c418d42",
                "date": 1683771437481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1857",
                "rarity_score": 228.43032827123116,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1857.png",
            "scheme": "https"
        }
    },
    "2825": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2825",
                "description": "description",
                "dna": "a86dfa3afd3b19bac69b9cf3e7b1b99e18d1afab",
                "date": 1683445460477,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2825",
                "rarity_score": 176.0922427275364,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2825.png",
            "scheme": "https"
        }
    },
    "1267": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1267",
                "description": "description",
                "dna": "745f2b2997e686ba37b32768a28c62ceef2b5bfc",
                "date": 1683439632003,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1267",
                "rarity_score": 192.53702581493198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1267.png",
            "scheme": "https"
        }
    },
    "1604": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1604",
                "description": "description",
                "dna": "301b5e991a2a65511ce7cf90155db4a90ebc0e86",
                "date": 1683435805762,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1604",
                "rarity_score": 169.47031210278965,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1604.png",
            "scheme": "https"
        }
    },
    "3759": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3759",
                "description": "description",
                "dna": "c19e088695ca2f54bfe1992b3a844980e2374d1d",
                "date": 1683438450595,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3759",
                "rarity_score": 200.36852709397985,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3759.png",
            "scheme": "https"
        }
    },
    "901": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #901",
                "description": "description",
                "dna": "e4fe7579332a26b9b1dd7c728ff652c9409a5497",
                "date": 1683446792605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "901",
                "rarity_score": 194.2385073575255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/901.png",
            "scheme": "https"
        }
    },
    "2047": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2047",
                "description": "description",
                "dna": "22cdab8e69dd092f8945680c45a83cee5494799f",
                "date": 1683439349239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2047",
                "rarity_score": 145.4191361595109,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2047.png",
            "scheme": "https"
        }
    },
    "3388": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3388",
                "description": "description",
                "dna": "b59e22cf8dd47dcabbd7025b67a6fe25b229ac55",
                "date": 1683445128340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3388",
                "rarity_score": 265.62070698454886,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3388.png",
            "scheme": "https"
        }
    },
    "1242": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1242",
                "description": "description",
                "dna": "97ab821b5e0533346a089436c120a81528172afe",
                "date": 1683437975468,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1242",
                "rarity_score": 198.03599481799657,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1242.png",
            "scheme": "https"
        }
    },
    "1557": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1557",
                "description": "description",
                "dna": "d90ff618f8b061303d5fbdc0dc7c5fd94a3677d4",
                "date": 1683440836192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1557",
                "rarity_score": 193.60688791406386,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1557.png",
            "scheme": "https"
        }
    },
    "2340": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2340",
                "description": "description",
                "dna": "2f031f1def5c6742394f5c50268a3a1567fea10c",
                "date": 1683775130958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2340",
                "rarity_score": 179.40062490461696,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2340.png",
            "scheme": "https"
        }
    },
    "846": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #846",
                "description": "description",
                "dna": "984781f01d9e906e43396e2a8eadb0a7ed5132f0",
                "date": 1683446348265,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "846",
                "rarity_score": 132.5688003663358,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/846.png",
            "scheme": "https"
        }
    },
    "3631": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3631",
                "description": "description",
                "dna": "7067fe98d953c681099150a45fe8c04e3ba42adf",
                "date": 1683439810642,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3631",
                "rarity_score": 204.90064912237108,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3631.png",
            "scheme": "https"
        }
    },
    "3355": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3355",
                "description": "description",
                "dna": "f8fac91c63281ffac1431acde8e22617b37ccb8a",
                "date": 1683442439536,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3355",
                "rarity_score": 171.26122776837806,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3355.png",
            "scheme": "https"
        }
    },
    "3416": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3416",
                "description": "description",
                "dna": "e4fdcc4936a037c8c869384f71f232bb83c15ac6",
                "date": 1683437515742,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3416",
                "rarity_score": 233.67462672917097,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3416.png",
            "scheme": "https"
        }
    },
    "1678": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1678",
                "description": "description",
                "dna": "2b55aac39228f24450d7a9c8bf506055bb306810",
                "date": 1683444759715,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1678",
                "rarity_score": 223.03393392023625,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1678.png",
            "scheme": "https"
        }
    },
    "3021": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3021",
                "description": "description",
                "dna": "f5a96c32d92064784323104eb1f8447211716980",
                "date": 1683441133791,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3021",
                "rarity_score": 204.38470241253867,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3021.png",
            "scheme": "https"
        }
    },
    "1349": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1349",
                "description": "description",
                "dna": "db7356d2ee84c887c558ae710c38ec3175e508c8",
                "date": 1683771491859,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1349",
                "rarity_score": 234.8322263904501,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1349.png",
            "scheme": "https"
        }
    },
    "985": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #985",
                "description": "description",
                "dna": "28564d631255e3ac4738fcae9333dcb11afb82dd",
                "date": 1683436648376,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "985",
                "rarity_score": 193.70124291712645,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/985.png",
            "scheme": "https"
        }
    },
    "1851": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1851",
                "description": "description",
                "dna": "d19d98aad050e33de74fd09cbc43a3575fbe3de8",
                "date": 1683441088976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1851",
                "rarity_score": 154.99246745190908,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1851.png",
            "scheme": "https"
        }
    },
    "3029": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3029",
                "description": "description",
                "dna": "1650f0fb6d3fe085acc875ebd5db47294a3564d9",
                "date": 1683447098907,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3029",
                "rarity_score": 175.82182999128327,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3029.png",
            "scheme": "https"
        }
    },
    "288": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #288",
                "description": "description",
                "dna": "f9b5b2ce39070b7f28d66021a770674b8ddc28d2",
                "date": 1683431317286,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "288",
                "rarity_score": 186.5963717264547,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/288.png",
            "scheme": "https"
        }
    },
    "3156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3156",
                "description": "description",
                "dna": "f94997e97af1ec60f10699b0e7fdc1c0f456187b",
                "date": 1683433918029,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3156",
                "rarity_score": 160.2835260999275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3156.png",
            "scheme": "https"
        }
    },
    "3691": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3691",
                "description": "description",
                "dna": "1592e5a95e84f314f0f40ef4e9c86d164489ef9b",
                "date": 1683443761727,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3691",
                "rarity_score": 216.69784817321045,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3691.png",
            "scheme": "https"
        }
    },
    "3159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3159",
                "description": "description",
                "dna": "8904daf17dd96932287cd212fc941caeef888893",
                "date": 1683774497560,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3159",
                "rarity_score": 171.0494326939206,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3159.png",
            "scheme": "https"
        }
    },
    "1363": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1363",
                "description": "description",
                "dna": "850937abdd9bbfcdb5bbc6a705accf2d7b83d53a",
                "date": 1683508643626,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1363",
                "rarity_score": 423.47058815153855,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1363.png",
            "scheme": "https"
        }
    },
    "259": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #259",
                "description": "description",
                "dna": "91da92de00921d6faf1f1f74b413c0e4eae06951",
                "date": 1683441988154,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "259",
                "rarity_score": 204.81514068404337,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/259.png",
            "scheme": "https"
        }
    },
    "1254": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1254",
                "description": "description",
                "dna": "e32d149fc0032a2e8c46ad2264d7ee54c38924ff",
                "date": 1683447130993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1254",
                "rarity_score": 249.38778586983705,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1254.png",
            "scheme": "https"
        }
    },
    "3963": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3963",
                "description": "description",
                "dna": "d2a03bc9d83348c40dc73662c490161f364afb35",
                "date": 1683431857784,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3963",
                "rarity_score": 242.73626219134846,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3963.png",
            "scheme": "https"
        }
    },
    "810": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #810",
                "description": "description",
                "dna": "a0e165169e987716b061c20394af0e5582782d5f",
                "date": 1683435728240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "810",
                "rarity_score": 161.8336543944377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/810.png",
            "scheme": "https"
        }
    },
    "734": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #734",
                "description": "description",
                "dna": "0bfaefa3689ba257ee8f993f215fd3c27180bfc0",
                "date": 1683444916195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "734",
                "rarity_score": 195.1182676805306,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/734.png",
            "scheme": "https"
        }
    },
    "2111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2111",
                "description": "description",
                "dna": "58ff2b38a4534e68940fdf33421f58515f812a76",
                "date": 1683430816804,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2111",
                "rarity_score": 256.9036575357281,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2111.png",
            "scheme": "https"
        }
    },
    "680": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #680",
                "description": "description",
                "dna": "289e9a5065a8b3b0499ba4ab2e32cc5d844fde53",
                "date": 1683436144429,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "680",
                "rarity_score": 184.14688924909447,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/680.png",
            "scheme": "https"
        }
    },
    "3111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3111",
                "description": "description",
                "dna": "753ed52db984344fb3fba7a55da2228a9f105e8e",
                "date": 1683444554974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3111",
                "rarity_score": 187.98741261622757,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3111.png",
            "scheme": "https"
        }
    },
    "3917": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3917",
                "description": "description",
                "dna": "6ac1065a1c616cb4529bed1da6c0097e2c8f7355",
                "date": 1683446603221,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3917",
                "rarity_score": 200.87648865698262,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3917.png",
            "scheme": "https"
        }
    },
    "2107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2107",
                "description": "description",
                "dna": "3b7baee30e9f1b5b44e685b72509f7444b9130ee",
                "date": 1683440503739,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2107",
                "rarity_score": 157.53192637556697,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2107.png",
            "scheme": "https"
        }
    },
    "36": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #36",
                "description": "description",
                "dna": "e42f5b881e29c51f34a2a0f2a89e8cca1bfd5648",
                "date": 1683434231303,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "36",
                "rarity_score": 242.84901912536955,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/36.png",
            "scheme": "https"
        }
    },
    "699": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #699",
                "description": "description",
                "dna": "4fefbbd8161fafca5712986073472102216602b1",
                "date": 1683771395876,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "699",
                "rarity_score": 199.77795005853534,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/699.png",
            "scheme": "https"
        }
    },
    "1217": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1217",
                "description": "description",
                "dna": "8c0db99c74dfa1d70844fb38ec533380534eacdf",
                "date": 1683446922894,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1217",
                "rarity_score": 163.9910315845425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1217.png",
            "scheme": "https"
        }
    },
    "3818": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3818",
                "description": "description",
                "dna": "5fe990792a8ee7e4d13a91897cd5726eb21ab0d5",
                "date": 1683440304124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3818",
                "rarity_score": 185.57872522438794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3818.png",
            "scheme": "https"
        }
    },
    "3195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3195",
                "description": "description",
                "dna": "1a7a90ff0c46f79aad25e996923e9d293e03a701",
                "date": 1683429751004,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3195",
                "rarity_score": 209.95816231724993,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3195.png",
            "scheme": "https"
        }
    },
    "1169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1169",
                "description": "description",
                "dna": "f30b7dfed98c64530be69cc560613b91d8dd2efd",
                "date": 1683442559541,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1169",
                "rarity_score": 158.339518788015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1169.png",
            "scheme": "https"
        }
    },
    "3272": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3272",
                "description": "description",
                "dna": "b999f64c65711eb2837696052eb072cc8e0b8183",
                "date": 1683442697525,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3272",
                "rarity_score": 162.32772497528106,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3272.png",
            "scheme": "https"
        }
    },
    "2229": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2229",
                "description": "description",
                "dna": "94b2ba9ff8ee4834fe05ef525a335da3ab01f8b9",
                "date": 1683776408671,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2229",
                "rarity_score": 262.23031114755855,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2229.png",
            "scheme": "https"
        }
    },
    "3381": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3381",
                "description": "description",
                "dna": "34706515db186dc2a0989228e67860f959dfc907",
                "date": 1683445586231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3381",
                "rarity_score": 193.15170089016465,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3381.png",
            "scheme": "https"
        }
    },
    "3157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3157",
                "description": "description",
                "dna": "31629e78e298efca26ff852832ff00317546287c",
                "date": 1683443274511,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3157",
                "rarity_score": 191.08452352001194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3157.png",
            "scheme": "https"
        }
    },
    "154": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #154",
                "description": "description",
                "dna": "4175b1f8569a26908c5dd8b9d58a837bd25f70b1",
                "date": 1683447336722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "154",
                "rarity_score": 269.13858957867956,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/154.png",
            "scheme": "https"
        }
    },
    "904": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #904",
                "description": "description",
                "dna": "528defb6a546ac0cdde8cec55a484c2f4669f8e0",
                "date": 1683430074967,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "904",
                "rarity_score": 259.84927704880346,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/904.png",
            "scheme": "https"
        }
    },
    "249": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #249",
                "description": "description",
                "dna": "2e2a8df45f54d8a46438c558b4ad88a2b3e7e835",
                "date": 1683441886363,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "249",
                "rarity_score": 207.91449819427612,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/249.png",
            "scheme": "https"
        }
    },
    "2721": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2721",
                "description": "description",
                "dna": "f8187810456d8c07308f219aa8e1993c4bbb75a3",
                "date": 1683439412545,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2721",
                "rarity_score": 158.58539122159374,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2721.png",
            "scheme": "https"
        }
    },
    "2168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2168",
                "description": "description",
                "dna": "9e193ee70855246d6d6aeb255dacb9eff764e7a4",
                "date": 1683434928198,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2168",
                "rarity_score": 186.39992090577516,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2168.png",
            "scheme": "https"
        }
    },
    "3933": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3933",
                "description": "description",
                "dna": "a80bc3f39fbcf4b6517980d66bc78e09e7716116",
                "date": 1683437466524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3933",
                "rarity_score": 142.59422780269276,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3933.png",
            "scheme": "https"
        }
    },
    "3897": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3897",
                "description": "description",
                "dna": "b7920ad75506fe1a2e1c1b747bc9ca26e5a1933d",
                "date": 1683440404237,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3897",
                "rarity_score": 166.0075333064747,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3897.png",
            "scheme": "https"
        }
    },
    "565": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #565",
                "description": "description",
                "dna": "c6187b447aac8e36599c2f4de75081ba09fe4982",
                "date": 1683443097738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "565",
                "rarity_score": 168.50985250150097,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/565.png",
            "scheme": "https"
        }
    },
    "2530": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2530",
                "description": "description",
                "dna": "fb217f51595da98ad5c6d87322b8bcbfd6162a44",
                "date": 1683771586875,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2530",
                "rarity_score": 169.35807060558037,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2530.png",
            "scheme": "https"
        }
    },
    "1537": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1537",
                "description": "description",
                "dna": "fa99a10dc01da7d2deb084b11a7a7d25b62b9992",
                "date": 1683442235687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1537",
                "rarity_score": 214.13446851020828,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1537.png",
            "scheme": "https"
        }
    },
    "1656": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1656",
                "description": "description",
                "dna": "038201652fe0f0a828ecff18e78ed37f833a9e9d",
                "date": 1683438074155,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1656",
                "rarity_score": 194.50647809153807,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1656.png",
            "scheme": "https"
        }
    },
    "3377": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3377",
                "description": "description",
                "dna": "0455ae9e5a891041ea0437ebabf62b282428dc09",
                "date": 1683770664124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3377",
                "rarity_score": 282.62585229007374,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3377.png",
            "scheme": "https"
        }
    },
    "1521": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1521",
                "description": "description",
                "dna": "a83effda7c4ac3511dcd17cfcbfc1ef349455d48",
                "date": 1683443775027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1521",
                "rarity_score": 216.60262198859164,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1521.png",
            "scheme": "https"
        }
    },
    "745": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #745",
                "description": "description",
                "dna": "14a9e9d0cd90822ae3364021cf2a662aef06d0cf",
                "date": 1683443793741,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "745",
                "rarity_score": 297.32444491869825,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/745.png",
            "scheme": "https"
        }
    },
    "1445": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1445",
                "description": "description",
                "dna": "4e0b507a808ac3085c4a5e129de8b4cdfee5e3b8",
                "date": 1683771107410,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1445",
                "rarity_score": 199.5894653820358,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1445.png",
            "scheme": "https"
        }
    },
    "2479": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2479",
                "description": "description",
                "dna": "dc730de33fe318f21914cef3d02d56163d263fc6",
                "date": 1683774981879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2479",
                "rarity_score": 258.93096501524667,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2479.png",
            "scheme": "https"
        }
    },
    "3511": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3511",
                "description": "description",
                "dna": "8865c42febc457524b986075f643173e3fb9eb03",
                "date": 1683433127804,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3511",
                "rarity_score": 173.81828515133176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3511.png",
            "scheme": "https"
        }
    },
    "128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #128",
                "description": "description",
                "dna": "920d094560e1e673c5b2e69932d7b7c063970d97",
                "date": 1683431386335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "128",
                "rarity_score": 173.00883943684855,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/128.png",
            "scheme": "https"
        }
    },
    "2590": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2590",
                "description": "description",
                "dna": "8a7029b6ad9bef2f57e5bbd92718770959dd8f43",
                "date": 1683441922264,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2590",
                "rarity_score": 157.73083064731955,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2590.png",
            "scheme": "https"
        }
    },
    "1134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1134",
                "description": "description",
                "dna": "8d457e38fd1d2102fdb462465598ee111bc5c2fc",
                "date": 1683429454430,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1134",
                "rarity_score": 213.93223712793656,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1134.png",
            "scheme": "https"
        }
    },
    "2506": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2506",
                "description": "description",
                "dna": "6dd1cb762d0065c96f4b5866544f2ecf58aeb5b0",
                "date": 1683435480182,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2506",
                "rarity_score": 226.74067623843885,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2506.png",
            "scheme": "https"
        }
    },
    "3027": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3027",
                "description": "description",
                "dna": "1afc3397a7862ec178b0a9ff1f022846e7c466e6",
                "date": 1683436986791,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3027",
                "rarity_score": 119.46607800023182,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3027.png",
            "scheme": "https"
        }
    },
    "3351": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3351",
                "description": "description",
                "dna": "f4740082836c8a4fcd4d7c86fc0df5c97c3b7525",
                "date": 1683437355850,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3351",
                "rarity_score": 128.4961608836465,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3351.png",
            "scheme": "https"
        }
    },
    "473": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #473",
                "description": "description",
                "dna": "429e29d46915e08e8377ea4f252145b8519d62cc",
                "date": 1683508687976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "473",
                "rarity_score": 405.0353653810342,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/473.png",
            "scheme": "https"
        }
    },
    "963": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #963",
                "description": "description",
                "dna": "fed76b89f51ade8f39134450f283b713760040f5",
                "date": 1683434763510,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "963",
                "rarity_score": 159.74259504312502,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/963.png",
            "scheme": "https"
        }
    },
    "3997": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3997",
                "description": "description",
                "dna": "e7fa777214d07a40cc69c579fcb12dee5ecc79b1",
                "date": 1683771552293,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3997",
                "rarity_score": 163.70755047999143,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3997.png",
            "scheme": "https"
        }
    },
    "3241": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3241",
                "description": "description",
                "dna": "5ca525fde86c1b837d54e28ce2fbcd206f6dfe77",
                "date": 1683439900822,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3241",
                "rarity_score": 224.79078075610562,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3241.png",
            "scheme": "https"
        }
    },
    "735": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #735",
                "description": "description",
                "dna": "da149b4d513c14b512e163efe777523a99bbff29",
                "date": 1683437646563,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "735",
                "rarity_score": 185.3699136373804,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/735.png",
            "scheme": "https"
        }
    },
    "3887": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3887",
                "description": "description",
                "dna": "e6d310541010b19475d5d517ea346899ab9932e1",
                "date": 1683437445619,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3887",
                "rarity_score": 214.71455278081783,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3887.png",
            "scheme": "https"
        }
    },
    "1605": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1605",
                "description": "description",
                "dna": "517daa69c24298e60fa918f1a9a003c77361e561",
                "date": 1683436674918,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1605",
                "rarity_score": 259.49454189302327,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1605.png",
            "scheme": "https"
        }
    },
    "868": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #868",
                "description": "description",
                "dna": "ccd8233e89942e802391b74887292d788685445f",
                "date": 1683436914786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "868",
                "rarity_score": 215.4098646910125,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/868.png",
            "scheme": "https"
        }
    },
    "2403": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2403",
                "description": "description",
                "dna": "5607eb3ff60c6b8511ffa11cda095fab20f2330f",
                "date": 1683444789598,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2403",
                "rarity_score": 166.72507172753797,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2403.png",
            "scheme": "https"
        }
    },
    "3757": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3757",
                "description": "description",
                "dna": "51d3df7d0653b10b6789d96f30d318acc9892b5b",
                "date": 1683429357644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3757",
                "rarity_score": 170.38952312354849,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3757.png",
            "scheme": "https"
        }
    },
    "1087": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1087",
                "description": "description",
                "dna": "9476c73a47764e2ee954802c14a08c97ef22a095",
                "date": 1683776387687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1087",
                "rarity_score": 198.7936577632349,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1087.png",
            "scheme": "https"
        }
    },
    "3363": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3363",
                "description": "description",
                "dna": "1a44dda8af282ce826117cdf5d7161b4b340193a",
                "date": 1683445977090,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3363",
                "rarity_score": 206.10266349930578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3363.png",
            "scheme": "https"
        }
    },
    "3733": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3733",
                "description": "description",
                "dna": "5059f20c3781318abf54cb06bd3241bd6dfeb27f",
                "date": 1683437042820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3733",
                "rarity_score": 166.70780678459357,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3733.png",
            "scheme": "https"
        }
    },
    "1559": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1559",
                "description": "description",
                "dna": "6137ba67747d1f29bdd421d590735d8af4909a5e",
                "date": 1683445938863,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1559",
                "rarity_score": 139.51896927045067,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1559.png",
            "scheme": "https"
        }
    },
    "1208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1208",
                "description": "description",
                "dna": "7fa399a264cc36b74fa82d3ec868f8abf8a02dab",
                "date": 1683438931047,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1208",
                "rarity_score": 196.9670172644833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1208.png",
            "scheme": "https"
        }
    },
    "1002": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1002",
                "description": "description",
                "dna": "2d6a73c4abdf5b04abc8b265a850d47cd86c67d1",
                "date": 1683444184385,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1002",
                "rarity_score": 209.79680956252668,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1002.png",
            "scheme": "https"
        }
    },
    "1681": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1681",
                "description": "description",
                "dna": "b8c50f0fbf5805aa62fd1a631b9aa6aebf4d8ed3",
                "date": 1683436872915,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1681",
                "rarity_score": 160.85658092737324,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1681.png",
            "scheme": "https"
        }
    },
    "2486": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2486",
                "description": "description",
                "dna": "dbffb4c01b7a214f4496ad38b5c48708c999a3ef",
                "date": 1683445843719,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2486",
                "rarity_score": 185.74162198329347,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2486.png",
            "scheme": "https"
        }
    },
    "2982": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2982",
                "description": "description",
                "dna": "4b7772e895fc0f720942d6794d112e8f590f4561",
                "date": 1683435388047,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2982",
                "rarity_score": 198.4370659347996,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2982.png",
            "scheme": "https"
        }
    },
    "1967": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1967",
                "description": "description",
                "dna": "a95f082909019c87820ab452edfc3368ef04d6bb",
                "date": 1683433034411,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1967",
                "rarity_score": 177.08033497150586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1967.png",
            "scheme": "https"
        }
    },
    "1965": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1965",
                "description": "description",
                "dna": "7e65e4a00b7ccc6946654d001ca85f48c16d43ac",
                "date": 1683436464272,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1965",
                "rarity_score": 243.90578445208243,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1965.png",
            "scheme": "https"
        }
    },
    "3287": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3287",
                "description": "description",
                "dna": "e369e6d6e9bf699a7908180f556c3334c041ffba",
                "date": 1683431202252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3287",
                "rarity_score": 249.7896293045055,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3287.png",
            "scheme": "https"
        }
    },
    "455": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #455",
                "description": "description",
                "dna": "30cdaf9da493023edef2c58223145cc52e7f54ad",
                "date": 1683434290236,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "455",
                "rarity_score": 222.1247505311684,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/455.png",
            "scheme": "https"
        }
    },
    "854": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #854",
                "description": "description",
                "dna": "b9e5284986146296372f68c0a50ed5c42c6e5cb6",
                "date": 1683776549247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "854",
                "rarity_score": 172.43589551762884,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/854.png",
            "scheme": "https"
        }
    },
    "530": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #530",
                "description": "description",
                "dna": "861f47cec762340678731ca76810153a2e976623",
                "date": 1683436570816,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "530",
                "rarity_score": 295.3621218255797,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/530.png",
            "scheme": "https"
        }
    },
    "2183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2183",
                "description": "description",
                "dna": "753d5c32278bd891294ca63bc7aa323dc2cc6f9e",
                "date": 1683445599594,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2183",
                "rarity_score": 235.34658365867253,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2183.png",
            "scheme": "https"
        }
    },
    "1266": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1266",
                "description": "description",
                "dna": "ef65932eaf98c226b18742a81c2c96019dae61cc",
                "date": 1683445229682,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1266",
                "rarity_score": 184.03123724235985,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1266.png",
            "scheme": "https"
        }
    },
    "49": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #49",
                "description": "description",
                "dna": "903737b7ecdb22946456fd07c1bd6b5d5ebe5506",
                "date": 1683431799385,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "49",
                "rarity_score": 165.71254793449478,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/49.png",
            "scheme": "https"
        }
    },
    "1352": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1352",
                "description": "description",
                "dna": "ae55b6f422d596509b075b0cb2d41bda6a464464",
                "date": 1683446431293,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1352",
                "rarity_score": 135.68474078783663,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1352.png",
            "scheme": "https"
        }
    },
    "3186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3186",
                "description": "description",
                "dna": "53cbf4b4e214bc8c5ca6f91e70ba0033ae3f8e30",
                "date": 1683443478203,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3186",
                "rarity_score": 169.94204095548764,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3186.png",
            "scheme": "https"
        }
    },
    "3700": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3700",
                "description": "description",
                "dna": "c3efead4c86a6081b8a8d2743188668a1a08d32d",
                "date": 1683444734429,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3700",
                "rarity_score": 185.38536787440557,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3700.png",
            "scheme": "https"
        }
    },
    "738": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #738",
                "description": "description",
                "dna": "2c5eb9d3ee61afdfd62279bff4c2cc65cfd90a31",
                "date": 1683442136592,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "738",
                "rarity_score": 178.5365992411581,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/738.png",
            "scheme": "https"
        }
    },
    "2384": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2384",
                "description": "description",
                "dna": "fd2253d2ea5d9946be27a4d93bca6db91664ccaf",
                "date": 1683432648925,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2384",
                "rarity_score": 211.61235475286495,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2384.png",
            "scheme": "https"
        }
    },
    "126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #126",
                "description": "description",
                "dna": "78ae9a07f5338456eff6577501d68074b0665f6f",
                "date": 1683433226093,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "126",
                "rarity_score": 319.1586054457749,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/126.png",
            "scheme": "https"
        }
    },
    "501": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #501",
                "description": "description",
                "dna": "443b4871236b553da7522a025860237f52694eb3",
                "date": 1683429826058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "501",
                "rarity_score": 173.59746726074255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/501.png",
            "scheme": "https"
        }
    },
    "2838": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2838",
                "description": "description",
                "dna": "0a57ec100365df3356c82a3d5dc8f4d397c9b3ba",
                "date": 1683776732877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2838",
                "rarity_score": 245.95075252469388,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2838.png",
            "scheme": "https"
        }
    },
    "3854": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3854",
                "description": "description",
                "dna": "c35a3a74c0af01e8145acd8d8b50a0aa9bbb6065",
                "date": 1683440582553,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3854",
                "rarity_score": 184.7884268011108,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3854.png",
            "scheme": "https"
        }
    },
    "2092": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2092",
                "description": "description",
                "dna": "d879f9876ac880e0443b6729016cbc56b081550a",
                "date": 1683439214847,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2092",
                "rarity_score": 160.38101844933132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2092.png",
            "scheme": "https"
        }
    },
    "1447": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1447",
                "description": "description",
                "dna": "ad76b088d391dad01646a4b16afb252fa6aa04d7",
                "date": 1683434684835,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1447",
                "rarity_score": 153.6826905401643,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1447.png",
            "scheme": "https"
        }
    },
    "1071": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1071",
                "description": "description",
                "dna": "226296d0901d9add40457b0061fe5b735f022c12",
                "date": 1683432199245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1071",
                "rarity_score": 225.07670382569898,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1071.png",
            "scheme": "https"
        }
    },
    "3314": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3314",
                "description": "description",
                "dna": "dae270df4c063d91b38dbcd2e121ce44a671d869",
                "date": 1683443669004,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3314",
                "rarity_score": 178.26032444175794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3314.png",
            "scheme": "https"
        }
    },
    "3296": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3296",
                "description": "description",
                "dna": "ede2b4e7450c530a5618ee413ce815ab36f03c36",
                "date": 1683444682133,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3296",
                "rarity_score": 187.0963103391689,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3296.png",
            "scheme": "https"
        }
    },
    "2423": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2423",
                "description": "description",
                "dna": "cfe4811f7a198ee24a5f09674466314a28b9f301",
                "date": 1683445512743,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2423",
                "rarity_score": 172.4598331613989,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2423.png",
            "scheme": "https"
        }
    },
    "1616": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1616",
                "description": "description",
                "dna": "49e9f21f2b235690873f67ff54dcd684dafd18e7",
                "date": 1683429697100,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1616",
                "rarity_score": 197.78828691110368,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1616.png",
            "scheme": "https"
        }
    },
    "705": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #705",
                "description": "description",
                "dna": "c6f80700f299cf06b02e20bbfb6582ad2dfdd173",
                "date": 1683441899116,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "705",
                "rarity_score": 174.2680853590123,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/705.png",
            "scheme": "https"
        }
    },
    "1601": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1601",
                "description": "description",
                "dna": "ab306e55dca56511299a3385a31cb009d541b911",
                "date": 1683446391740,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1601",
                "rarity_score": 146.90986596499226,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1601.png",
            "scheme": "https"
        }
    },
    "3152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3152",
                "description": "description",
                "dna": "c359f4d511b0e72777680b6678b7b0eb06d6d09b",
                "date": 1683771487674,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3152",
                "rarity_score": 174.5560967695162,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3152.png",
            "scheme": "https"
        }
    },
    "1280": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1280",
                "description": "description",
                "dna": "7c71fff9e3c6d49da3321c39e1ce3ec51995c05d",
                "date": 1683434055063,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1280",
                "rarity_score": 174.95835753342743,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1280.png",
            "scheme": "https"
        }
    },
    "2452": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2452",
                "description": "description",
                "dna": "d484cbd9aa78f0c907abc3f97b2a90f8d845b14d",
                "date": 1683434884907,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2452",
                "rarity_score": 166.08001485440175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2452.png",
            "scheme": "https"
        }
    },
    "2349": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2349",
                "description": "description",
                "dna": "f848c2cbde4ec872e77e87a64035b6ac98edcdaf",
                "date": 1683443981093,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2349",
                "rarity_score": 202.67069756570683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2349.png",
            "scheme": "https"
        }
    },
    "1364": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1364",
                "description": "description",
                "dna": "86a99d8ee2b97160b8edd45542b1876704cfa83a",
                "date": 1683431647881,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1364",
                "rarity_score": 182.85902568865302,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1364.png",
            "scheme": "https"
        }
    },
    "1763": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1763",
                "description": "description",
                "dna": "fd9174b7eeff4c197293afd2148a41a7fde59405",
                "date": 1683436620948,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1763",
                "rarity_score": 224.29131158021497,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1763.png",
            "scheme": "https"
        }
    },
    "1278": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1278",
                "description": "description",
                "dna": "13983bad35f6f3d1572fad3ddd61859cd4011a51",
                "date": 1683437470486,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1278",
                "rarity_score": 194.45070078709944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1278.png",
            "scheme": "https"
        }
    },
    "1476": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1476",
                "description": "description",
                "dna": "b6330debd296b17ef665cc05de40ca663a39944f",
                "date": 1683432743723,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1476",
                "rarity_score": 270.0332673772541,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1476.png",
            "scheme": "https"
        }
    },
    "129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #129",
                "description": "description",
                "dna": "8bef1a9dac314eb26047768db2b2305006e4713d",
                "date": 1683437800656,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "129",
                "rarity_score": 192.26820879382035,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/129.png",
            "scheme": "https"
        }
    },
    "1452": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1452",
                "description": "description",
                "dna": "b0b2ca0cab8ff52654ac5d3d3c18e88f8bae5435",
                "date": 1683435019958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1452",
                "rarity_score": 366.39785552824253,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1452.png",
            "scheme": "https"
        }
    },
    "2176": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2176",
                "description": "description",
                "dna": "a4b0eb447959ab050dc98606ef4ce9505c16f285",
                "date": 1683438613613,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2176",
                "rarity_score": 213.80748859643802,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2176.png",
            "scheme": "https"
        }
    },
    "1887": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1887",
                "description": "description",
                "dna": "3c7a97f9a9dcb1b13e30c9faa4efcddd3d465d55",
                "date": 1683429636412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1887",
                "rarity_score": 159.3772643685433,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1887.png",
            "scheme": "https"
        }
    },
    "3067": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3067",
                "description": "description",
                "dna": "e12e11fb89fba34c56da46c4eed9a9c67b667e72",
                "date": 1683429582925,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3067",
                "rarity_score": 162.2133191736008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3067.png",
            "scheme": "https"
        }
    },
    "2684": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2684",
                "description": "description",
                "dna": "8a94649e4938130b735a11a3b304e5e360d0a2ad",
                "date": 1683443302733,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2684",
                "rarity_score": 255.07723090126618,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2684.png",
            "scheme": "https"
        }
    },
    "2987": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2987",
                "description": "description",
                "dna": "dfa33a48b3f4811ac4bf062a0c9d855c44d24472",
                "date": 1683433357862,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2987",
                "rarity_score": 155.13468332580825,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2987.png",
            "scheme": "https"
        }
    },
    "548": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #548",
                "description": "description",
                "dna": "694e858066915798f5b8303493c5c341bd6cd6a0",
                "date": 1683439942694,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "548",
                "rarity_score": 252.5037935547132,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/548.png",
            "scheme": "https"
        }
    },
    "566": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #566",
                "description": "description",
                "dna": "391f84d21c16998f872188b527c0e0d496c61d53",
                "date": 1683436393457,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "566",
                "rarity_score": 178.64290484767875,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/566.png",
            "scheme": "https"
        }
    },
    "3113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3113",
                "description": "description",
                "dna": "379b0f5e8be61823da88b2d7f5f3594ce26929ca",
                "date": 1683508648143,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3113",
                "rarity_score": 492.2302913331264,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3113.png",
            "scheme": "https"
        }
    },
    "3958": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3958",
                "description": "description",
                "dna": "7a39bdc8c06d1befd00851610584914272468096",
                "date": 1683443473439,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3958",
                "rarity_score": 177.91720309183683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3958.png",
            "scheme": "https"
        }
    },
    "3011": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3011",
                "description": "description",
                "dna": "a926c4273f78060f622844058c2deedfc75fad85",
                "date": 1683443404149,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3011",
                "rarity_score": 268.67065289152345,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3011.png",
            "scheme": "https"
        }
    },
    "1032": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1032",
                "description": "description",
                "dna": "58a714ac7d9827019342b4b09c9e991c31baa057",
                "date": 1683436103079,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1032",
                "rarity_score": 266.3408943026309,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1032.png",
            "scheme": "https"
        }
    },
    "2935": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2935",
                "description": "description",
                "dna": "2425e80f4d672ec803435f4103fcf7f42b1a2a37",
                "date": 1683436289921,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2935",
                "rarity_score": 167.50416521489151,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2935.png",
            "scheme": "https"
        }
    },
    "1153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1153",
                "description": "description",
                "dna": "7b28cdf3420e51952e7879799270de9da058bfd9",
                "date": 1683447452193,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1153",
                "rarity_score": 204.3117930315003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1153.png",
            "scheme": "https"
        }
    },
    "898": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #898",
                "description": "description",
                "dna": "8b417e78fc5b96bf417498427a7d5502cbac89cb",
                "date": 1683443265887,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "898",
                "rarity_score": 176.42170772370238,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/898.png",
            "scheme": "https"
        }
    },
    "2185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2185",
                "description": "description",
                "dna": "3d843abe824ad6c3d786c0fb055eabcf8e215d70",
                "date": 1683430217591,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2185",
                "rarity_score": 149.54597518762392,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2185.png",
            "scheme": "https"
        }
    },
    "3365": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3365",
                "description": "description",
                "dna": "0d4a5a2aaeca0bf65a551c5741e1ff6c60e91a32",
                "date": 1683441391054,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3365",
                "rarity_score": 151.9368151797492,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3365.png",
            "scheme": "https"
        }
    },
    "368": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #368",
                "description": "description",
                "dna": "6d9dc861ad8577f8f27507df46bd4f1ed019249d",
                "date": 1683442082875,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "368",
                "rarity_score": 205.41629567183162,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/368.png",
            "scheme": "https"
        }
    },
    "2031": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2031",
                "description": "description",
                "dna": "2820534046de0f6533fa780f86c539ba10cc80ba",
                "date": 1683771565422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2031",
                "rarity_score": 208.4012882352447,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2031.png",
            "scheme": "https"
        }
    },
    "1380": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1380",
                "description": "description",
                "dna": "bba4c08004c6f47608a500787cdf417189211481",
                "date": 1683437002417,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1380",
                "rarity_score": 139.6620968437219,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1380.png",
            "scheme": "https"
        }
    },
    "3848": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3848",
                "description": "description",
                "dna": "4ef6201319ec1de356aa9e363b1ccf51e9783ba8",
                "date": 1683429964050,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3848",
                "rarity_score": 177.39184550377942,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3848.png",
            "scheme": "https"
        }
    },
    "524": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #524",
                "description": "description",
                "dna": "90528112c5ba7a8f408d9cc66b868cfa23483c86",
                "date": 1683430154200,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "524",
                "rarity_score": 268.8986450460097,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/524.png",
            "scheme": "https"
        }
    },
    "3694": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3694",
                "description": "description",
                "dna": "93483616518a079f9a806883eeb89d819e36f734",
                "date": 1683430052786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3694",
                "rarity_score": 227.07436604169607,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3694.png",
            "scheme": "https"
        }
    },
    "847": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #847",
                "description": "description",
                "dna": "7e30c3c0e8b851666d0a2e2e44102376d92eb850",
                "date": 1683445811729,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "847",
                "rarity_score": 204.15682625242408,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/847.png",
            "scheme": "https"
        }
    },
    "2338": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2338",
                "description": "description",
                "dna": "8bec2046cc312cca4cca73a812e899b6857aa4c2",
                "date": 1683432730239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2338",
                "rarity_score": 159.63737863536585,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2338.png",
            "scheme": "https"
        }
    },
    "1872": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1872",
                "description": "description",
                "dna": "ad5751d925dc5b4c3dc3c69acdc6ed09ecdf49a7",
                "date": 1683440685236,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1872",
                "rarity_score": 246.61850064094534,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1872.png",
            "scheme": "https"
        }
    },
    "2105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2105",
                "description": "description",
                "dna": "b27addbc485f3e868538c87a715e94b8b4b39818",
                "date": 1683446545722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2105",
                "rarity_score": 203.48562764927735,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2105.png",
            "scheme": "https"
        }
    },
    "2066": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2066",
                "description": "description",
                "dna": "dc80e88756b94aa2c72070558df0bbef2357474d",
                "date": 1683440069736,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2066",
                "rarity_score": 176.01059615797388,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2066.png",
            "scheme": "https"
        }
    },
    "3003": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3003",
                "description": "description",
                "dna": "e4c6e36c29042f95282031548c2376adec152d00",
                "date": 1683770490309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3003",
                "rarity_score": 439.04710748811925,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3003.png",
            "scheme": "https"
        }
    },
    "2893": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2893",
                "description": "description",
                "dna": "34e64f6a348e464a37e9d2ba78d6b9a46b983b02",
                "date": 1683437552715,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2893",
                "rarity_score": 171.69759781592205,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2893.png",
            "scheme": "https"
        }
    },
    "3726": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3726",
                "description": "description",
                "dna": "71eb487c9d582a7c46ee3f9e60e194d4c3344efa",
                "date": 1683430338637,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3726",
                "rarity_score": 192.26415551515353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3726.png",
            "scheme": "https"
        }
    },
    "3965": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3965",
                "description": "description",
                "dna": "26ec60e2e4433f5c7147bd895a53bef8c48aef26",
                "date": 1683436824550,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3965",
                "rarity_score": 184.26351210879426,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3965.png",
            "scheme": "https"
        }
    },
    "3284": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3284",
                "description": "description",
                "dna": "b2e4b67a0abc53d46a116aa5d41fd772622795e6",
                "date": 1683430274469,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3284",
                "rarity_score": 149.82002694841862,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3284.png",
            "scheme": "https"
        }
    },
    "13": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #13",
                "description": "description",
                "dna": "f0bb8a1829d999102e47fa6e57fb993e3f4ab7b6",
                "date": 1683444616276,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "13",
                "rarity_score": 158.11058885909907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/13.png",
            "scheme": "https"
        }
    },
    "95": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #95",
                "description": "description",
                "dna": "b7ed970d156bff77a3fa7ac1221e598d963893f7",
                "date": 1683436454879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "95",
                "rarity_score": 254.29608619621564,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/95.png",
            "scheme": "https"
        }
    },
    "2476": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2476",
                "description": "description",
                "dna": "0810b22b3f2b9fbc58a76d19d7c12d277aec4cbf",
                "date": 1683435657827,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2476",
                "rarity_score": 212.09013233793513,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2476.png",
            "scheme": "https"
        }
    },
    "2331": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2331",
                "description": "description",
                "dna": "41b9a6a70209f36f5f02ce5187c32ea91f427f05",
                "date": 1683440313527,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2331",
                "rarity_score": 203.89610096859963,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2331.png",
            "scheme": "https"
        }
    },
    "2532": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2532",
                "description": "description",
                "dna": "f345625fec70c57ebe63f82a93cb19955d6f255b",
                "date": 1683443873409,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2532",
                "rarity_score": 179.89876594510974,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2532.png",
            "scheme": "https"
        }
    },
    "2428": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2428",
                "description": "description",
                "dna": "a89ce7c60e29ba8e288005b2f59a7dd2fdf33862",
                "date": 1683434117390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2428",
                "rarity_score": 191.67477382886202,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2428.png",
            "scheme": "https"
        }
    },
    "3431": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3431",
                "description": "description",
                "dna": "53232812de38c60c98992b8a9170da1a3d92d6c6",
                "date": 1683438172574,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3431",
                "rarity_score": 216.91759093660028,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3431.png",
            "scheme": "https"
        }
    },
    "431": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #431",
                "description": "description",
                "dna": "4b408eae2ac7e808bff748577f2170dbdf5e897a",
                "date": 1683430208596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "431",
                "rarity_score": 167.48990645882304,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/431.png",
            "scheme": "https"
        }
    },
    "1339": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1339",
                "description": "description",
                "dna": "c8f0b792b7ef6c1967540e126f47fd9fc47d1eee",
                "date": 1683776443349,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1339",
                "rarity_score": 231.49913999824577,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1339.png",
            "scheme": "https"
        }
    },
    "2760": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2760",
                "description": "description",
                "dna": "97cea863852c0309b9647629afd5f1fa704b5faf",
                "date": 1683447086593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2760",
                "rarity_score": 168.42874536374902,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2760.png",
            "scheme": "https"
        }
    },
    "125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #125",
                "description": "description",
                "dna": "d994be929b72020fb76def377ce7283a9ed6a5d4",
                "date": 1683444938432,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "125",
                "rarity_score": 167.34044528372056,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/125.png",
            "scheme": "https"
        }
    },
    "3460": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3460",
                "description": "description",
                "dna": "c7d91ecd1531daac74c1e1d7c340af7a665a987a",
                "date": 1683429520081,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3460",
                "rarity_score": 160.08011565066133,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3460.png",
            "scheme": "https"
        }
    },
    "3030": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3030",
                "description": "description",
                "dna": "7fc107774d2384d781d738c7f5cd4aa72e3dd294",
                "date": 1683774920247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3030",
                "rarity_score": 291.738240600717,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3030.png",
            "scheme": "https"
        }
    },
    "1983": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1983",
                "description": "description",
                "dna": "868ba4d94e11fe26b82071eb182c105ad2b0efc6",
                "date": 1683771137246,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1983",
                "rarity_score": 210.00776672350474,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1983.png",
            "scheme": "https"
        }
    },
    "1455": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1455",
                "description": "description",
                "dna": "093b5a0ce65fb3b6bb7505a45316dc5ce9eb8469",
                "date": 1683436153491,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1455",
                "rarity_score": 185.8840678005394,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1455.png",
            "scheme": "https"
        }
    },
    "2455": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2455",
                "description": "description",
                "dna": "b4489cb2ba0495221285018ad8f5e3aa7798b7c4",
                "date": 1683774757505,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2455",
                "rarity_score": 210.55399460087557,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2455.png",
            "scheme": "https"
        }
    },
    "3805": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3805",
                "description": "description",
                "dna": "c007c053fc260cd8c9166897423baafe41ff1a86",
                "date": 1683434212702,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3805",
                "rarity_score": 155.14727750683574,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3805.png",
            "scheme": "https"
        }
    },
    "3402": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3402",
                "description": "description",
                "dna": "8c68ba1a5807a57e7424e264fb3a424c9919c237",
                "date": 1683775087246,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3402",
                "rarity_score": 200.99192582035195,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3402.png",
            "scheme": "https"
        }
    },
    "1596": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1596",
                "description": "description",
                "dna": "047ff535efc3c4e0a3faff8b0c652826b027b5b7",
                "date": 1683435517011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1596",
                "rarity_score": 163.34948139760712,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1596.png",
            "scheme": "https"
        }
    },
    "3659": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3659",
                "description": "description",
                "dna": "b45d4b0121a31cec327f2eb75900802419f9f2c1",
                "date": 1683442164320,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3659",
                "rarity_score": 224.32913491259225,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3659.png",
            "scheme": "https"
        }
    },
    "2939": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2939",
                "description": "description",
                "dna": "af5bc5b351753a568e86954bcdb2b8744bae8757",
                "date": 1683431767955,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2939",
                "rarity_score": 188.39301540273388,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2939.png",
            "scheme": "https"
        }
    },
    "2086": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2086",
                "description": "description",
                "dna": "483fd5e7df60ca3d55599aeab6a0074788965616",
                "date": 1683442277058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2086",
                "rarity_score": 187.12564391598062,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2086.png",
            "scheme": "https"
        }
    },
    "880": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #880",
                "description": "description",
                "dna": "3bec0f7bb69c84c3fbf61542c78fc19e9a8256a3",
                "date": 1683447056963,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "880",
                "rarity_score": 176.26320570945109,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/880.png",
            "scheme": "https"
        }
    },
    "1877": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1877",
                "description": "description",
                "dna": "411ecf8ca8775463d5174d80482f3fe41030b92a",
                "date": 1683440093182,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1877",
                "rarity_score": 186.68537809593093,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1877.png",
            "scheme": "https"
        }
    },
    "760": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #760",
                "description": "description",
                "dna": "f44dcfcca0c7bb7f082071681a54c35bff940416",
                "date": 1683437247825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "760",
                "rarity_score": 136.16777898751198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/760.png",
            "scheme": "https"
        }
    },
    "3508": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3508",
                "description": "description",
                "dna": "0df95d6df349345a8c6e145c1ec238b793019ab5",
                "date": 1683432489376,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3508",
                "rarity_score": 174.48516480139867,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3508.png",
            "scheme": "https"
        }
    },
    "3483": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3483",
                "description": "description",
                "dna": "c9b9d296d2650d86c2eb130ac90b23ac1acb3c1e",
                "date": 1683776724465,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3483",
                "rarity_score": 214.49122893370492,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3483.png",
            "scheme": "https"
        }
    },
    "2495": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2495",
                "description": "description",
                "dna": "723a6895973003663fd185994e8b299fb88161ca",
                "date": 1683444677893,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2495",
                "rarity_score": 173.67118319838784,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2495.png",
            "scheme": "https"
        }
    },
    "3425": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3425",
                "description": "description",
                "dna": "cac0552467a91e193bc7591e271fae66abb39f1a",
                "date": 1683441327866,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3425",
                "rarity_score": 182.96479742465212,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3425.png",
            "scheme": "https"
        }
    },
    "913": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #913",
                "description": "description",
                "dna": "8a0c78d7ce1dfcb0f3972c0a679aee388d842be8",
                "date": 1683446709317,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "913",
                "rarity_score": 152.43811286085298,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/913.png",
            "scheme": "https"
        }
    },
    "3711": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3711",
                "description": "description",
                "dna": "2880668362325f24ac9633b3833467e8b601927b",
                "date": 1683432932482,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3711",
                "rarity_score": 173.18855705186454,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3711.png",
            "scheme": "https"
        }
    },
    "3393": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3393",
                "description": "description",
                "dna": "955d6c13a852d5922a2cb1d0a81b80e8138ccc24",
                "date": 1683442853347,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3393",
                "rarity_score": 158.18457542640692,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3393.png",
            "scheme": "https"
        }
    },
    "1508": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1508",
                "description": "description",
                "dna": "f0c89546ed45b6086f633c1c0c6bd7168720589a",
                "date": 1683440968814,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1508",
                "rarity_score": 226.58755733823256,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1508.png",
            "scheme": "https"
        }
    },
    "221": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #221",
                "description": "description",
                "dna": "dc5c8fcfc3e3058161bdf87a24f311bfdb1023ec",
                "date": 1683436181461,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "221",
                "rarity_score": 245.228961898942,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/221.png",
            "scheme": "https"
        }
    },
    "2599": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2599",
                "description": "description",
                "dna": "ab0dc0ba3315d5629bab610040b48dfcd5d89be7",
                "date": 1683441813364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2599",
                "rarity_score": 165.28279394529451,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2599.png",
            "scheme": "https"
        }
    },
    "2034": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2034",
                "description": "description",
                "dna": "29a56de872626d9656625ca6ac63159ee5eec038",
                "date": 1683444370672,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2034",
                "rarity_score": 280.93132900237663,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2034.png",
            "scheme": "https"
        }
    },
    "1609": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1609",
                "description": "description",
                "dna": "9b1ad3ce1f12344b659209890942e7625141c3e6",
                "date": 1683437096353,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1609",
                "rarity_score": 191.79507609740637,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1609.png",
            "scheme": "https"
        }
    },
    "29": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #29",
                "description": "description",
                "dna": "078bcd4c9b798d7be1820ea58f7adf29dde8e1f9",
                "date": 1683446078115,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "29",
                "rarity_score": 153.38051734098076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/29.png",
            "scheme": "https"
        }
    },
    "39": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #39",
                "description": "description",
                "dna": "3acf5a6cb081a16066c9f313ff635d05469e7c10",
                "date": 1683441574982,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "39",
                "rarity_score": 228.63924971364924,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/39.png",
            "scheme": "https"
        }
    },
    "3901": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3901",
                "description": "description",
                "dna": "2700c8487e1ae9d7c2190ca5c7d1cbffa524837e",
                "date": 1683444899222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3901",
                "rarity_score": 157.89831577717172,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3901.png",
            "scheme": "https"
        }
    },
    "2190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2190",
                "description": "description",
                "dna": "53ab021d8fb915547a5b2466f620ce903175f846",
                "date": 1683438898385,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2190",
                "rarity_score": 313.44930071873193,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2190.png",
            "scheme": "https"
        }
    },
    "252": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #252",
                "description": "description",
                "dna": "5d08f58a8ed6ea504df64ce39b6411a43ae041c5",
                "date": 1683445172398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "252",
                "rarity_score": 188.10762389886432,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/252.png",
            "scheme": "https"
        }
    },
    "1873": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1873",
                "description": "description",
                "dna": "bb4cd33b743835a17f56aead0b196db836efeaaf",
                "date": 1683436358518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1873",
                "rarity_score": 173.2351702461864,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1873.png",
            "scheme": "https"
        }
    },
    "2262": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2262",
                "description": "description",
                "dna": "5d449bedcc6d31a5015b0ba1ef0a7ffb911e3591",
                "date": 1683430651559,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2262",
                "rarity_score": 148.05049864045415,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2262.png",
            "scheme": "https"
        }
    },
    "1922": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1922",
                "description": "description",
                "dna": "14d3b0e4aaa18a11f16f2b3a3dbf542cfd3faa85",
                "date": 1683438293318,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1922",
                "rarity_score": 186.22230384648958,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1922.png",
            "scheme": "https"
        }
    },
    "381": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #381",
                "description": "description",
                "dna": "1ed612c71f3b49989c8f1738bf03d5d1fed059c2",
                "date": 1683444721621,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "381",
                "rarity_score": 203.07704945464326,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/381.png",
            "scheme": "https"
        }
    },
    "4076": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4076",
                "description": "description",
                "dna": "5ee3b377ff5dcdb95b124f0b0788e5b9d5748c8f",
                "date": 1683432435928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "4076",
                "rarity_score": 192.3909709638508,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4076.png",
            "scheme": "https"
        }
    },
    "1809": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1809",
                "description": "description",
                "dna": "898ca507a744883c39d8be37a2202f19403afdc9",
                "date": 1683432129770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1809",
                "rarity_score": 194.76313546746502,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1809.png",
            "scheme": "https"
        }
    },
    "1733": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1733",
                "description": "description",
                "dna": "684d6a97580a21f66272b3231715d53e1e2c8e23",
                "date": 1683432946119,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1733",
                "rarity_score": 193.73730295789048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1733.png",
            "scheme": "https"
        }
    },
    "3506": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3506",
                "description": "description",
                "dna": "cfaf23c809ce8b8980a87e10f6578dca0b1d4d99",
                "date": 1683443705309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3506",
                "rarity_score": 165.94694476473936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3506.png",
            "scheme": "https"
        }
    },
    "3392": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3392",
                "description": "description",
                "dna": "ed1d326c6d91a941c29e971beab385c80eb5e69d",
                "date": 1683429790430,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3392",
                "rarity_score": 226.98043468156882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3392.png",
            "scheme": "https"
        }
    },
    "2057": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2057",
                "description": "description",
                "dna": "c85274976583397cbc33e7431ba6cd9353cfe96b",
                "date": 1683429573784,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2057",
                "rarity_score": 197.90335913995045,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2057.png",
            "scheme": "https"
        }
    },
    "513": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #513",
                "description": "description",
                "dna": "751846fe60c14911eff4217054bf8864e5b04931",
                "date": 1683776694179,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "513",
                "rarity_score": 195.64416463142658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/513.png",
            "scheme": "https"
        }
    },
    "3862": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3862",
                "description": "description",
                "dna": "93378366eb0dc07779a4cfa0a11b8e07ef39f679",
                "date": 1683439623667,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3862",
                "rarity_score": 184.85125667686395,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3862.png",
            "scheme": "https"
        }
    },
    "3541": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3541",
                "description": "description",
                "dna": "c8ef985065e1806b5e8796b798af5fbcf255a1a8",
                "date": 1683435214684,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3541",
                "rarity_score": 186.94702118500365,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3541.png",
            "scheme": "https"
        }
    },
    "3688": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3688",
                "description": "description",
                "dna": "ca4248ad94ed9671bc8df44ecb7cee3c5f1b240d",
                "date": 1683442956725,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3688",
                "rarity_score": 208.7689124036626,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3688.png",
            "scheme": "https"
        }
    },
    "3800": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3800",
                "description": "description",
                "dna": "775dbe2c4809d7543a597ae1f13cdacb64c24439",
                "date": 1683439071995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3800",
                "rarity_score": 205.5837521142002,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3800.png",
            "scheme": "https"
        }
    },
    "1893": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1893",
                "description": "description",
                "dna": "ec4a1c82d503ca28c7097a24584bf87d9c4e0136",
                "date": 1683440273746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1893",
                "rarity_score": 153.58920344939668,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1893.png",
            "scheme": "https"
        }
    },
    "2920": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2920",
                "description": "description",
                "dna": "60725417dead7245ada9e03be5f8fc2a0414daaf",
                "date": 1683436021957,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2920",
                "rarity_score": 156.962377966316,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2920.png",
            "scheme": "https"
        }
    },
    "3549": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3549",
                "description": "description",
                "dna": "c72cf708140b8a8359fb9c39dafcd8b375db30f9",
                "date": 1683434286315,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3549",
                "rarity_score": 212.45354228884992,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3549.png",
            "scheme": "https"
        }
    },
    "447": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #447",
                "description": "description",
                "dna": "762c98568519cdda2900303856bb427178cea2a1",
                "date": 1683431595396,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "447",
                "rarity_score": 193.36169253858213,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/447.png",
            "scheme": "https"
        }
    },
    "1237": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1237",
                "description": "description",
                "dna": "1181c809e9ef643c29f73e59706eaa341b6773b6",
                "date": 1683444397081,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1237",
                "rarity_score": 202.4603814499717,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1237.png",
            "scheme": "https"
        }
    },
    "2261": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2261",
                "description": "description",
                "dna": "7630eb41a7960dd32df9d75d1ccee2fa05906489",
                "date": 1683446914442,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2261",
                "rarity_score": 184.4050297308894,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2261.png",
            "scheme": "https"
        }
    },
    "2604": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2604",
                "description": "description",
                "dna": "a99d1e6c51a567a6c5775557c5b4fee7cef6edcc",
                "date": 1683432567541,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2604",
                "rarity_score": 216.65823051297306,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2604.png",
            "scheme": "https"
        }
    },
    "553": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #553",
                "description": "description",
                "dna": "aec1c66fd8a9a73a14015b0f9c03f620ad31893c",
                "date": 1683774399352,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "553",
                "rarity_score": 170.83493960988415,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/553.png",
            "scheme": "https"
        }
    },
    "1045": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1045",
                "description": "description",
                "dna": "53351720373cf2fcf75da63918f99e9735097e29",
                "date": 1683433114791,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1045",
                "rarity_score": 303.7706064685886,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1045.png",
            "scheme": "https"
        }
    },
    "1746": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1746",
                "description": "description",
                "dna": "f8f7b00956d7cc79283eec58bc7d7330182b1b54",
                "date": 1683445773003,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1746",
                "rarity_score": 212.2077338491698,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1746.png",
            "scheme": "https"
        }
    },
    "2670": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2670",
                "description": "description",
                "dna": "82cb1c577a8218964c953ae07a3e2327f67de27a",
                "date": 1683441395177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2670",
                "rarity_score": 233.42719267632626,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2670.png",
            "scheme": "https"
        }
    },
    "191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #191",
                "description": "description",
                "dna": "10b98f21a33b04137c56f05081b5739473a146c4",
                "date": 1683431969160,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "191",
                "rarity_score": 178.75380325692947,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/191.png",
            "scheme": "https"
        }
    },
    "809": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #809",
                "description": "description",
                "dna": "128be272166cb642e31af2de3aa710e6be215e89",
                "date": 1683434875774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "809",
                "rarity_score": 201.8896159377179,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/809.png",
            "scheme": "https"
        }
    },
    "1268": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1268",
                "description": "description",
                "dna": "8ea68e68b88e8f8cc9352a3c7f23cffe382af95e",
                "date": 1683433430062,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1268",
                "rarity_score": 217.15812380867536,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1268.png",
            "scheme": "https"
        }
    },
    "1048": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1048",
                "description": "description",
                "dna": "10236b2fc072f1f52d1ae60d5a40ffd489c1370e",
                "date": 1683438830375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1048",
                "rarity_score": 164.11141372066308,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1048.png",
            "scheme": "https"
        }
    },
    "906": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #906",
                "description": "description",
                "dna": "37e5a6c4f46c0a5e24898cf3440c5c89a490cfd8",
                "date": 1683446462803,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "906",
                "rarity_score": 150.53275631186438,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/906.png",
            "scheme": "https"
        }
    },
    "2072": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2072",
                "description": "description",
                "dna": "0f51ebfea4342eceeeeefb942bc123c7c6bc164e",
                "date": 1683439759064,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2072",
                "rarity_score": 218.3749691947787,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2072.png",
            "scheme": "https"
        }
    },
    "795": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #795",
                "description": "description",
                "dna": "8cdd593ef0c1847d19145d018d8184943176e975",
                "date": 1683429688366,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "795",
                "rarity_score": 210.71290079800724,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/795.png",
            "scheme": "https"
        }
    },
    "3620": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3620",
                "description": "description",
                "dna": "d0e7bfc686f20a2c37d19585adb18ea80a2f4e34",
                "date": 1683446713520,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3620",
                "rarity_score": 175.68452619170912,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3620.png",
            "scheme": "https"
        }
    },
    "1573": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1573",
                "description": "description",
                "dna": "3e52b200ad68dbe80003bbdb63febb070fab98aa",
                "date": 1683439735872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1573",
                "rarity_score": 179.0238589598431,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1573.png",
            "scheme": "https"
        }
    },
    "2783": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2783",
                "description": "description",
                "dna": "6773f32cb4e83d51cff068007c7a0c5e3041088d",
                "date": 1683435719101,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2783",
                "rarity_score": 219.54242595743472,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2783.png",
            "scheme": "https"
        }
    },
    "2566": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2566",
                "description": "description",
                "dna": "b474e37af943949a2d8adaa8f1d1cdc825a7e470",
                "date": 1683771603755,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2566",
                "rarity_score": 212.98271064906334,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2566.png",
            "scheme": "https"
        }
    },
    "202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #202",
                "description": "description",
                "dna": "66eb5602da1ca496c952c716471c516a18f827dd",
                "date": 1683446234333,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "202",
                "rarity_score": 150.31987201444747,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/202.png",
            "scheme": "https"
        }
    },
    "665": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #665",
                "description": "description",
                "dna": "42b4f8eddda6dffeeffee1e97fb02c09d8debdf4",
                "date": 1683439089961,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "665",
                "rarity_score": 225.4107643600728,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/665.png",
            "scheme": "https"
        }
    },
    "2144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2144",
                "description": "description",
                "dna": "1dd1314d619fdd730cc805558af656b25af9c936",
                "date": 1683436340596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2144",
                "rarity_score": 225.98604069967104,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2144.png",
            "scheme": "https"
        }
    },
    "555": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #555",
                "description": "description",
                "dna": "6604f3c4eebfc5981ee00efd42ec129589c6f9f9",
                "date": 1683438104826,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "555",
                "rarity_score": 222.95552668694828,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/555.png",
            "scheme": "https"
        }
    },
    "1843": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1843",
                "description": "description",
                "dna": "da90bd0d394de836d7d43ef3f0829ffba0f4463f",
                "date": 1683445070162,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1843",
                "rarity_score": 205.45593289013007,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1843.png",
            "scheme": "https"
        }
    },
    "528": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #528",
                "description": "description",
                "dna": "4c96e8a7ee236a565217cce3dcd2ff13ad89e954",
                "date": 1683442743950,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "528",
                "rarity_score": 233.33004575992385,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/528.png",
            "scheme": "https"
        }
    },
    "1793": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1793",
                "description": "description",
                "dna": "fd19f3d465b572cddc68bc8636150dfbef5cce40",
                "date": 1683443744573,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1793",
                "rarity_score": 250.78254464151183,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1793.png",
            "scheme": "https"
        }
    },
    "2405": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2405",
                "description": "description",
                "dna": "c6e0e5543ac3aa8853b15818b19d2bd889766140",
                "date": 1683435696914,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2405",
                "rarity_score": 276.7929409786851,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2405.png",
            "scheme": "https"
        }
    },
    "2767": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2767",
                "description": "description",
                "dna": "d5dc1185c1318a3873aa19912469b1e8d9572e42",
                "date": 1683430083910,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2767",
                "rarity_score": 287.8104763173858,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2767.png",
            "scheme": "https"
        }
    },
    "394": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #394",
                "description": "description",
                "dna": "4284f0532124040193e9f0eafa81208981825c78",
                "date": 1683447019123,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "394",
                "rarity_score": 297.31833723124356,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/394.png",
            "scheme": "https"
        }
    },
    "372": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #372",
                "description": "description",
                "dna": "042a915a50899bc83a3d4142c42071f275d20ee1",
                "date": 1683432404976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "372",
                "rarity_score": 298.3984557683342,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/372.png",
            "scheme": "https"
        }
    },
    "2444": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2444",
                "description": "description",
                "dna": "e55d437169b629c4d6c14d1fa64770b2f6ed898a",
                "date": 1683432208413,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2444",
                "rarity_score": 204.46173620856206,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2444.png",
            "scheme": "https"
        }
    },
    "3421": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3421",
                "description": "description",
                "dna": "f1315c84cf821805cd9258c191549a13ee09e74d",
                "date": 1683436501487,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3421",
                "rarity_score": 229.99860918036958,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3421.png",
            "scheme": "https"
        }
    },
    "2161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2161",
                "description": "description",
                "dna": "65f9f2ca62c13ddc3c6c92b12ff82f739b3a0a1d",
                "date": 1683447327356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2161",
                "rarity_score": 252.3207960346896,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2161.png",
            "scheme": "https"
        }
    },
    "1259": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1259",
                "description": "description",
                "dna": "ae65d2d73220506b494b61b1b5ffd5b5cd820fb1",
                "date": 1683437112800,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1259",
                "rarity_score": 172.87090356302085,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1259.png",
            "scheme": "https"
        }
    },
    "4038": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4038",
                "description": "description",
                "dna": "19a5354ad61f7bc460927dd27f15325e03db00a9",
                "date": 1683432625898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "4038",
                "rarity_score": 156.7846727811389,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4038.png",
            "scheme": "https"
        }
    },
    "3773": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3773",
                "description": "description",
                "dna": "628b19c47c4ca319bdcb5539e867b3b2434f93cc",
                "date": 1683431750260,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3773",
                "rarity_score": 192.30140143122748,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3773.png",
            "scheme": "https"
        }
    },
    "3304": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3304",
                "description": "description",
                "dna": "3d78dceeee901a12ee46e6e70a4bb2426ff63869",
                "date": 1683774717836,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3304",
                "rarity_score": 232.12389143716888,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3304.png",
            "scheme": "https"
        }
    },
    "1742": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1742",
                "description": "description",
                "dna": "119f488bc8e52a66cde27ed169245c2d19e9998e",
                "date": 1683437231961,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1742",
                "rarity_score": 153.47786313758087,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1742.png",
            "scheme": "https"
        }
    },
    "2210": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2210",
                "description": "description",
                "dna": "6bde50a5edb867eb5866ea87a8bf7deb9b0f7f62",
                "date": 1683440900062,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2210",
                "rarity_score": 166.10397055794493,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2210.png",
            "scheme": "https"
        }
    },
    "2045": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2045",
                "description": "description",
                "dna": "4be968a3e23928ea21a74fc9a6bbf2b9cec2a3c2",
                "date": 1683438297845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2045",
                "rarity_score": 166.0684208982153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2045.png",
            "scheme": "https"
        }
    },
    "2977": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2977",
                "description": "description",
                "dna": "35db2fdac3e66fa697904bcf7c94dd97e80f59e8",
                "date": 1683770606670,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2977",
                "rarity_score": 272.1850594022176,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2977.png",
            "scheme": "https"
        }
    },
    "1410": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1410",
                "description": "description",
                "dna": "eecc713f42c50768974618b4f599afdfecd87410",
                "date": 1683429609782,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1410",
                "rarity_score": 164.58193464025672,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1410.png",
            "scheme": "https"
        }
    },
    "605": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #605",
                "description": "description",
                "dna": "e24855d1f0419bfaafba31736b0c3b30eff67f01",
                "date": 1683435890270,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "605",
                "rarity_score": 176.82259300777935,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/605.png",
            "scheme": "https"
        }
    },
    "3222": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3222",
                "description": "description",
                "dna": "e0f1adf18f01d88d3d27bc4b08c3df86699ea82f",
                "date": 1683441292765,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3222",
                "rarity_score": 165.96511866621796,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3222.png",
            "scheme": "https"
        }
    },
    "737": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #737",
                "description": "description",
                "dna": "fa18fe2b7f8462099fa1e42d8ba3ad8d3a1bb871",
                "date": 1683441417318,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "737",
                "rarity_score": 243.0290713488102,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/737.png",
            "scheme": "https"
        }
    },
    "1115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1115",
                "description": "description",
                "dna": "7c48a4f6415a8756772ed77c2e939c4a7a421cde",
                "date": 1683437441629,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1115",
                "rarity_score": 126.9073793961907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1115.png",
            "scheme": "https"
        }
    },
    "3855": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3855",
                "description": "description",
                "dna": "614613edf98a6ddd2cbf8da75d03323cda0ae7bf",
                "date": 1683774691233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3855",
                "rarity_score": 211.42084629675801,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3855.png",
            "scheme": "https"
        }
    },
    "3180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3180",
                "description": "description",
                "dna": "011ecbdecacad27b15bc5974bfd0e1caf58d1676",
                "date": 1683446242297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3180",
                "rarity_score": 153.3934404235252,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3180.png",
            "scheme": "https"
        }
    },
    "3178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3178",
                "description": "description",
                "dna": "f61296b463d2872f95bcdcce4f62283b582cf457",
                "date": 1683439390008,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3178",
                "rarity_score": 174.51796353994808,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3178.png",
            "scheme": "https"
        }
    },
    "2212": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2212",
                "description": "description",
                "dna": "e66cbcf32c6acb79501dc19298d63e3d2be39690",
                "date": 1683430924897,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2212",
                "rarity_score": 204.1362267975405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2212.png",
            "scheme": "https"
        }
    },
    "1939": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1939",
                "description": "description",
                "dna": "1fe7e39035ae9a1d2be52eacf5a8b49ed9777966",
                "date": 1683770627011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1939",
                "rarity_score": 242.32340896753678,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1939.png",
            "scheme": "https"
        }
    },
    "1009": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1009",
                "description": "description",
                "dna": "4c17056cfdf6eea707423124969706960a0c2566",
                "date": 1683435989855,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1009",
                "rarity_score": 211.7728991791223,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1009.png",
            "scheme": "https"
        }
    },
    "1052": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1052",
                "description": "description",
                "dna": "b778ed7931a6f72a003e537955be26e76c0940a4",
                "date": 1683440431746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1052",
                "rarity_score": 171.5422601295127,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1052.png",
            "scheme": "https"
        }
    },
    "3610": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3610",
                "description": "description",
                "dna": "91cc381549e276e926e0f4663a3e9c2e4c3f6057",
                "date": 1683430256928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3610",
                "rarity_score": 177.9792401469142,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3610.png",
            "scheme": "https"
        }
    },
    "1651": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1651",
                "description": "description",
                "dna": "ef62c9bf8baeddfdd78c317f1a6f63c8911c8154",
                "date": 1683432666701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1651",
                "rarity_score": 197.51345264607968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1651.png",
            "scheme": "https"
        }
    },
    "1015": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1015",
                "description": "description",
                "dna": "c91d84b190646960479a7193ede8306b5049d85e",
                "date": 1683445247675,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1015",
                "rarity_score": 188.804506289396,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1015.png",
            "scheme": "https"
        }
    },
    "1408": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1408",
                "description": "description",
                "dna": "4e4e2e56bf43fcca878f4431bf0b6f63a10cdf17",
                "date": 1683437576827,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1408",
                "rarity_score": 229.89342641384678,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1408.png",
            "scheme": "https"
        }
    },
    "1785": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1785",
                "description": "description",
                "dna": "e3b097d90457a4a5693a009be5158d51dbc71c8f",
                "date": 1683446522812,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1785",
                "rarity_score": 169.59029023188273,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1785.png",
            "scheme": "https"
        }
    },
    "3167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3167",
                "description": "description",
                "dna": "dbad11f75d6bc57ced7dd249d4a73b2a6f306466",
                "date": 1683776485042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3167",
                "rarity_score": 206.195840053218,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3167.png",
            "scheme": "https"
        }
    },
    "831": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #831",
                "description": "description",
                "dna": "57f18aee8b91beafa10d12f00003cf543e59b5cf",
                "date": 1683430070779,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "831",
                "rarity_score": 151.75230575330065,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/831.png",
            "scheme": "https"
        }
    },
    "1567": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1567",
                "description": "description",
                "dna": "3ecc27c56f0e2db8ac1bab99914c3219e6a8f330",
                "date": 1683440322634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1567",
                "rarity_score": 275.50048372294094,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1567.png",
            "scheme": "https"
        }
    },
    "2755": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2755",
                "description": "description",
                "dna": "9a6246f99095d7be63256e2d1b277d4e2b99f2b4",
                "date": 1683441908334,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2755",
                "rarity_score": 171.52124159401325,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2755.png",
            "scheme": "https"
        }
    },
    "225": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #225",
                "description": "description",
                "dna": "196f5f7591e8961745f178e27c2d9ab67444dde2",
                "date": 1683435648770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "225",
                "rarity_score": 212.23387004292036,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/225.png",
            "scheme": "https"
        }
    },
    "2743": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2743",
                "description": "description",
                "dna": "66f979565427e3dfcc54ee99824141a1d9d4aa50",
                "date": 1683441184184,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2743",
                "rarity_score": 157.0434430331706,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2743.png",
            "scheme": "https"
        }
    },
    "457": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #457",
                "description": "description",
                "dna": "aff1376cdde25cbe523f82f6cc40f848edf5f582",
                "date": 1683429658738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "457",
                "rarity_score": 188.73098690775961,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/457.png",
            "scheme": "https"
        }
    },
    "2731": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2731",
                "description": "description",
                "dna": "a203902667f5b049408a0d61eeac9894a9ddd74a",
                "date": 1683446519111,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2731",
                "rarity_score": 125.14233165173562,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2731.png",
            "scheme": "https"
        }
    },
    "944": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #944",
                "description": "description",
                "dna": "c12d99eeae7bfc849e6023b649e17f6991d026fc",
                "date": 1683774811313,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "944",
                "rarity_score": 250.4051294025835,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/944.png",
            "scheme": "https"
        }
    },
    "3093": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3093",
                "description": "description",
                "dna": "5a5f1fb22a346ea5af9a7097b05fce67320dcfb5",
                "date": 1683431179593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3093",
                "rarity_score": 200.4751028073349,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3093.png",
            "scheme": "https"
        }
    },
    "38": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #38",
                "description": "description",
                "dna": "460f962611f2a18c2ce6b7b908fe0b7a3222ba23",
                "date": 1683445508269,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "38",
                "rarity_score": 227.54994349536787,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/38.png",
            "scheme": "https"
        }
    },
    "479": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #479",
                "description": "description",
                "dna": "5606f0231b14a10bacdeeedb65cd2927db317367",
                "date": 1683431142314,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "479",
                "rarity_score": 182.85683697187173,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/479.png",
            "scheme": "https"
        }
    },
    "3657": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3657",
                "description": "description",
                "dna": "2c7ad7262ced5924dbf1b1577ed263bd02620a72",
                "date": 1683432282642,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3657",
                "rarity_score": 210.8591041568606,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3657.png",
            "scheme": "https"
        }
    },
    "2595": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2595",
                "description": "description",
                "dna": "e72dea110cba6797a1dd35805896c3d8975b8b6d",
                "date": 1683431973297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2595",
                "rarity_score": 191.7860513722497,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2595.png",
            "scheme": "https"
        }
    },
    "2374": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2374",
                "description": "description",
                "dna": "54c0b8f0d5882f2cb891585785a37a184db927ea",
                "date": 1683771192063,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2374",
                "rarity_score": 197.30165683899835,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2374.png",
            "scheme": "https"
        }
    },
    "3578": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3578",
                "description": "description",
                "dna": "0748ccf0daf8350ae4483073672086370f909699",
                "date": 1683433798209,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3578",
                "rarity_score": 272.2165307412146,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3578.png",
            "scheme": "https"
        }
    },
    "2083": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2083",
                "description": "description",
                "dna": "25e21daf1f6d352ef9bf3acf3ac3929cbdb040e1",
                "date": 1683436157655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2083",
                "rarity_score": 314.24671640626445,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2083.png",
            "scheme": "https"
        }
    },
    "2021": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2021",
                "description": "description",
                "dna": "5f233caa9901fe13ae325016b5684f70ae59d311",
                "date": 1683446031436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2021",
                "rarity_score": 231.05691996936886,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2021.png",
            "scheme": "https"
        }
    },
    "518": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #518",
                "description": "description",
                "dna": "faba3ff715d0211c1398e4b8310dfbc912b4606a",
                "date": 1683446046795,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "518",
                "rarity_score": 124.81937791438398,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/518.png",
            "scheme": "https"
        }
    },
    "2282": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2282",
                "description": "description",
                "dna": "8c8187516d97980ec34dd531a3f7b5ea3f4d4146",
                "date": 1683432954983,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2282",
                "rarity_score": 198.78637797695998,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2282.png",
            "scheme": "https"
        }
    },
    "2497": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2497",
                "description": "description",
                "dna": "798ed24b9c1060f0bfa64b36a2f29693bb1ea369",
                "date": 1683432339378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2497",
                "rarity_score": 216.9526042259255,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2497.png",
            "scheme": "https"
        }
    },
    "1552": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1552",
                "description": "description",
                "dna": "8925a9aefed0dfefdd47d71b458184576f0f9dbd",
                "date": 1683775135400,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1552",
                "rarity_score": 275.2815293036105,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1552.png",
            "scheme": "https"
        }
    },
    "199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #199",
                "description": "description",
                "dna": "d2b83f0226ff66a996c2f80e993027ef2e155f76",
                "date": 1683430952753,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "199",
                "rarity_score": 236.9374899634115,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/199.png",
            "scheme": "https"
        }
    },
    "2028": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2028",
                "description": "description",
                "dna": "7ad455b4c14cca63346d217f771005f0369b644c",
                "date": 1683441795358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2028",
                "rarity_score": 214.85692199181747,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2028.png",
            "scheme": "https"
        }
    },
    "1022": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1022",
                "description": "description",
                "dna": "d4977e480ca4e2af8352dc229aa985b9152e9998",
                "date": 1683436800361,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1022",
                "rarity_score": 155.45882233121557,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1022.png",
            "scheme": "https"
        }
    },
    "2336": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2336",
                "description": "description",
                "dna": "7a8b04df34206c137c3558f7496ad9b3df5e1f23",
                "date": 1683431002820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2336",
                "rarity_score": 211.64971036027663,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2336.png",
            "scheme": "https"
        }
    },
    "2070": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2070",
                "description": "description",
                "dna": "f0a79960723ad7003b2d31355b7e05d754c11ada",
                "date": 1683431238998,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2070",
                "rarity_score": 233.75955880775683,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2070.png",
            "scheme": "https"
        }
    },
    "2949": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2949",
                "description": "description",
                "dna": "4b863bfdda2df552b1e9cd096570d514db9f5fad",
                "date": 1683440731760,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2949",
                "rarity_score": 249.61377755834138,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2949.png",
            "scheme": "https"
        }
    },
    "934": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #934",
                "description": "description",
                "dna": "84c0e49448ab143a9d8bfbf29337f5261bb5e065",
                "date": 1683441170442,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "934",
                "rarity_score": 226.1584934497098,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/934.png",
            "scheme": "https"
        }
    },
    "2343": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2343",
                "description": "description",
                "dna": "7b063d9c0ee48b91df8c6187cf9562aeed823c5e",
                "date": 1683443778975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2343",
                "rarity_score": 138.09072113428783,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2343.png",
            "scheme": "https"
        }
    },
    "2180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2180",
                "description": "description",
                "dna": "71bbc84d5e704a15c4c969ff95a4be0187723832",
                "date": 1683447245734,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2180",
                "rarity_score": 167.65858713885322,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2180.png",
            "scheme": "https"
        }
    },
    "931": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #931",
                "description": "description",
                "dna": "bef911ff15ec1f708560aa8e43dd7d1422c9878b",
                "date": 1683771277110,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "931",
                "rarity_score": 202.90144775544027,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/931.png",
            "scheme": "https"
        }
    },
    "2239": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2239",
                "description": "description",
                "dna": "189c4e782b39debdcfb3ff60a4fc7d0540f90c83",
                "date": 1683776689898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2239",
                "rarity_score": 177.1970780743224,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2239.png",
            "scheme": "https"
        }
    },
    "1239": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1239",
                "description": "description",
                "dna": "b8c76e04e2181893530942395b32e00c124f4423",
                "date": 1683439465500,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1239",
                "rarity_score": 179.9827766623262,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1239.png",
            "scheme": "https"
        }
    },
    "1214": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1214",
                "description": "description",
                "dna": "e2f7a33efca62afd0b47f162dcba22e6e64a97e5",
                "date": 1683429401213,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1214",
                "rarity_score": 254.52063908598348,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1214.png",
            "scheme": "https"
        }
    },
    "1224": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1224",
                "description": "description",
                "dna": "62947579e14f5fdaa38b0b03f9501e3e6856faed",
                "date": 1683431745282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1224",
                "rarity_score": 151.57864828056935,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1224.png",
            "scheme": "https"
        }
    },
    "510": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #510",
                "description": "description",
                "dna": "34a191abbebab34ac10256f0bffa2fe5de86daa6",
                "date": 1683775100177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "510",
                "rarity_score": 182.6430148144691,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/510.png",
            "scheme": "https"
        }
    },
    "2815": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2815",
                "description": "description",
                "dna": "8ccabcd91586e7e3067be45f9d21eac5f415af7b",
                "date": 1683776766908,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2815",
                "rarity_score": 186.5287689824302,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2815.png",
            "scheme": "https"
        }
    },
    "3752": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3752",
                "description": "description",
                "dna": "ad78804bd8e49b16f939530469783feb40604339",
                "date": 1683439650125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3752",
                "rarity_score": 255.61868187357015,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3752.png",
            "scheme": "https"
        }
    },
    "1274": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1274",
                "description": "description",
                "dna": "1eb8bd73133bf7d2ad8bb27518d144a8217a45e3",
                "date": 1683440282356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1274",
                "rarity_score": 221.8629233461844,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1274.png",
            "scheme": "https"
        }
    },
    "1997": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1997",
                "description": "description",
                "dna": "1df04786c0c642d307033a433cdf25c4bf6a57be",
                "date": 1683771332482,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1997",
                "rarity_score": 189.1705289897393,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1997.png",
            "scheme": "https"
        }
    },
    "1800": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1800",
                "description": "description",
                "dna": "edad7d686c65ac29bc62d88f4c40b3cbb09e90fd",
                "date": 1683774888694,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1800",
                "rarity_score": 157.05037829788805,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1800.png",
            "scheme": "https"
        }
    },
    "2313": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2313",
                "description": "description",
                "dna": "1aeb123d2e695fba0dcbaa263217fd2742849f78",
                "date": 1683444960669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2313",
                "rarity_score": 203.12723974488046,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2313.png",
            "scheme": "https"
        }
    },
    "1184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1184",
                "description": "description",
                "dna": "77728ce42b0e730d86a4dff6456a36f42e6451ab",
                "date": 1683438926374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1184",
                "rarity_score": 218.24601498070365,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1184.png",
            "scheme": "https"
        }
    },
    "2784": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2784",
                "description": "description",
                "dna": "5584608a47ec3fb90ad7de71dc48908e89056cf6",
                "date": 1683434726037,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2784",
                "rarity_score": 353.56419394399893,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2784.png",
            "scheme": "https"
        }
    },
    "1849": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1849",
                "description": "description",
                "dna": "e57f0bac8de8e6499a774c44727ae66994a8ca70",
                "date": 1683446581481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1849",
                "rarity_score": 213.03842946985753,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1849.png",
            "scheme": "https"
        }
    },
    "2076": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2076",
                "description": "description",
                "dna": "8c47a08cdf19360b1a901bef5bdf14a8bbae50ca",
                "date": 1683434041546,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2076",
                "rarity_score": 212.09422436672463,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2076.png",
            "scheme": "https"
        }
    },
    "2359": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2359",
                "description": "description",
                "dna": "2d7bf1a3a3339c38673a4d7bf88fbbb2862b798f",
                "date": 1683439421286,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2359",
                "rarity_score": 186.25930432356495,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2359.png",
            "scheme": "https"
        }
    },
    "3262": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3262",
                "description": "description",
                "dna": "1fa5525cab498eec7127d7379bba769253ef8455",
                "date": 1683438423752,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3262",
                "rarity_score": 219.2890944287834,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3262.png",
            "scheme": "https"
        }
    },
    "2990": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2990",
                "description": "description",
                "dna": "d8ac18fa962cc33cdd74ecd9a63794f81baa3b90",
                "date": 1683445700806,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2990",
                "rarity_score": 312.06018813451175,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2990.png",
            "scheme": "https"
        }
    },
    "2365": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2365",
                "description": "description",
                "dna": "31aab50c9fb4fa0b2ea1f171944467e42668e194",
                "date": 1683442064774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2365",
                "rarity_score": 176.93598721307114,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2365.png",
            "scheme": "https"
        }
    },
    "697": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #697",
                "description": "description",
                "dna": "6eeb38a5fcfe7a5dbaa3af6de8df12df411cbcec",
                "date": 1683774856912,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "697",
                "rarity_score": 217.6450024381203,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/697.png",
            "scheme": "https"
        }
    },
    "136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #136",
                "description": "description",
                "dna": "ee3bf882c0a167a5cf9b4a55da74d5ec4a3c4734",
                "date": 1683430943687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "136",
                "rarity_score": 198.4024222848755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/136.png",
            "scheme": "https"
        }
    },
    "3457": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3457",
                "description": "description",
                "dna": "d348b763ff5b30cad84dd667abe41824c4b8fd79",
                "date": 1683441826787,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3457",
                "rarity_score": 171.45840880398612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3457.png",
            "scheme": "https"
        }
    },
    "1449": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1449",
                "description": "description",
                "dna": "05e127052f6f587c39e7aed9250b33d477401a52",
                "date": 1683441498417,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1449",
                "rarity_score": 251.7560496778014,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1449.png",
            "scheme": "https"
        }
    },
    "3149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3149",
                "description": "description",
                "dna": "ad8a3e7bbcafff0403cfc9fe00f231d90c2f5289",
                "date": 1683439116441,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3149",
                "rarity_score": 165.3305012634275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3149.png",
            "scheme": "https"
        }
    },
    "3618": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3618",
                "description": "description",
                "dna": "c9c93927b75e8708a0678e5010d1c9790025670d",
                "date": 1683446935385,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3618",
                "rarity_score": 196.65070435007806,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3618.png",
            "scheme": "https"
        }
    },
    "3401": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3401",
                "description": "description",
                "dna": "293ded124c8fee2cbed28e5f0311ac87557b0e43",
                "date": 1683776532360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3401",
                "rarity_score": 208.65736554282586,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3401.png",
            "scheme": "https"
        }
    },
    "3214": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3214",
                "description": "description",
                "dna": "4899c4f0e0124a41d4ad9d44e1f7f23e01dfb526",
                "date": 1683436556484,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3214",
                "rarity_score": 357.48459382012663,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3214.png",
            "scheme": "https"
        }
    },
    "840": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #840",
                "description": "description",
                "dna": "f1f62e90c16b2aa969a719076d9c3d4cb8c7214b",
                "date": 1683771255808,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "840",
                "rarity_score": 212.79841866106324,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/840.png",
            "scheme": "https"
        }
    },
    "3838": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3838",
                "description": "description",
                "dna": "3f458d9bdffc4b57ab955b4b78ead84262bd999c",
                "date": 1683432941561,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3838",
                "rarity_score": 175.64812423393496,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3838.png",
            "scheme": "https"
        }
    },
    "639": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #639",
                "description": "description",
                "dna": "82e3834bc74af1248d904a69ad1e1b8b408d3a82",
                "date": 1683436429015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "639",
                "rarity_score": 217.32088279927942,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/639.png",
            "scheme": "https"
        }
    },
    "2514": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2514",
                "description": "description",
                "dna": "0da6c2d843ecafdb14abeac25aae2cb87d1ce19d",
                "date": 1683443155241,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2514",
                "rarity_score": 286.0233658926972,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2514.png",
            "scheme": "https"
        }
    },
    "3225": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3225",
                "description": "description",
                "dna": "c1f71f3902956a496a6710c22f037db6abe56a2b",
                "date": 1683444991893,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3225",
                "rarity_score": 167.93338291244362,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3225.png",
            "scheme": "https"
        }
    },
    "539": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #539",
                "description": "description",
                "dna": "e3068730d8169934d611222e9d4a707fe224a447",
                "date": 1683441310451,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "539",
                "rarity_score": 207.11153942723956,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/539.png",
            "scheme": "https"
        }
    },
    "15": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #15",
                "description": "description",
                "dna": "e5ca6b759be82f691cea7c8e64ab03d72ba81cc0",
                "date": 1683444050968,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "15",
                "rarity_score": 194.810871758789,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/15.png",
            "scheme": "https"
        }
    },
    "3712": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3712",
                "description": "description",
                "dna": "3be5ed78dac930ddbb8198778f56205bc3dabf60",
                "date": 1683446308636,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3712",
                "rarity_score": 120.40191802006167,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3712.png",
            "scheme": "https"
        }
    },
    "3385": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3385",
                "description": "description",
                "dna": "dcd9d9562b30ec6277bfa3503d305d9e0cdc1b1f",
                "date": 1683431490584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3385",
                "rarity_score": 240.83955462010098,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3385.png",
            "scheme": "https"
        }
    },
    "1121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1121",
                "description": "description",
                "dna": "ef1e84c5af1aaaecdb16530c15ef0fee8d1f1c71",
                "date": 1683771616997,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1121",
                "rarity_score": 304.0459329084093,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1121.png",
            "scheme": "https"
        }
    },
    "1554": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1554",
                "description": "description",
                "dna": "7eee3ad8f8f47faa33da389cad1d7cc4741cd985",
                "date": 1683774511840,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "1554",
                "rarity_score": 331.4172911631917,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1554.png",
            "scheme": "https"
        }
    },
    "1378": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1378",
                "description": "description",
                "dna": "c68ce727c52c5e0769181be1b10ec467247c5104",
                "date": 1683776707250,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1378",
                "rarity_score": 194.32290365739877,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1378.png",
            "scheme": "https"
        }
    },
    "2060": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2060",
                "description": "description",
                "dna": "862799fd6201472539277543f09110c6907ba326",
                "date": 1683436729095,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2060",
                "rarity_score": 261.67045126443145,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2060.png",
            "scheme": "https"
        }
    },
    "2141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2141",
                "description": "description",
                "dna": "1f34094973719206f86487cf57c3417d385ed6b9",
                "date": 1683770615170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2141",
                "rarity_score": 319.77495850469944,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2141.png",
            "scheme": "https"
        }
    },
    "748": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #748",
                "description": "description",
                "dna": "8b928b06ddadd6e0a90e2449ff327f9b15b2b4cb",
                "date": 1683437243879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "748",
                "rarity_score": 183.99300018790726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/748.png",
            "scheme": "https"
        }
    },
    "2474": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2474",
                "description": "description",
                "dna": "400d9a435c87b5336de67a5654cd9ea799f58e13",
                "date": 1683774897579,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2474",
                "rarity_score": 207.7048913467574,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2474.png",
            "scheme": "https"
        }
    },
    "2652": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2652",
                "description": "description",
                "dna": "2ad86c19c3d57fd5ca2dcf4da562cc087fd767d1",
                "date": 1683441831301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2652",
                "rarity_score": 171.42560267978985,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2652.png",
            "scheme": "https"
        }
    },
    "1789": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1789",
                "description": "description",
                "dna": "fd863ec1cfb2d268fdfaabafa81ccecdd2c3e023",
                "date": 1683433882798,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1789",
                "rarity_score": 180.45948989419375,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1789.png",
            "scheme": "https"
        }
    },
    "4036": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4036",
                "description": "description",
                "dna": "8309f621b4145dd59fd581723fee8ae6d52b05ac",
                "date": 1683431781847,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4036",
                "rarity_score": 208.59615596842463,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4036.png",
            "scheme": "https"
        }
    },
    "2260": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2260",
                "description": "description",
                "dna": "944e5dba878588dba5f70a2495f0dfcc9231c67c",
                "date": 1683441502805,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2260",
                "rarity_score": 205.46839864022294,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2260.png",
            "scheme": "https"
        }
    },
    "1505": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1505",
                "description": "description",
                "dna": "a8d06a98833e2a39b87e422735824c62d97f2b6e",
                "date": 1683443002647,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1505",
                "rarity_score": 157.63145842072987,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1505.png",
            "scheme": "https"
        }
    },
    "1425": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1425",
                "description": "description",
                "dna": "0784c4d8086c37ce3c82c30f3f6b4cd9e6232b6a",
                "date": 1683437817691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1425",
                "rarity_score": 269.76636739955194,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1425.png",
            "scheme": "https"
        }
    },
    "1078": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1078",
                "description": "description",
                "dna": "a1cc9cc4440c3df0c8edbfd1c62d94f5535b6a70",
                "date": 1683446289378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1078",
                "rarity_score": 143.63516965721993,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1078.png",
            "scheme": "https"
        }
    },
    "755": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #755",
                "description": "description",
                "dna": "a20e7dadcf0fc23b965448c6b9bee292b2e7c3e1",
                "date": 1683436808296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "755",
                "rarity_score": 188.39661671985033,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/755.png",
            "scheme": "https"
        }
    },
    "1606": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1606",
                "description": "description",
                "dna": "6356cde52b77989ded16d9aa5ca4186066ce006c",
                "date": 1683440264842,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1606",
                "rarity_score": 232.5593630031226,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1606.png",
            "scheme": "https"
        }
    },
    "148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #148",
                "description": "description",
                "dna": "b89cb9fb39e012d4f8777ba0f8aa40e0145ef62f",
                "date": 1683437709754,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "148",
                "rarity_score": 198.59166263321606,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/148.png",
            "scheme": "https"
        }
    },
    "2211": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2211",
                "description": "description",
                "dna": "eb8ceb080c93fc6f390574d0e6ef7e30dbae9443",
                "date": 1683440573834,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2211",
                "rarity_score": 170.98626667072693,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2211.png",
            "scheme": "https"
        }
    },
    "2022": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2022",
                "description": "description",
                "dna": "ac4ff063589f99015f328a7e80ed42bd363dfb37",
                "date": 1683439067857,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2022",
                "rarity_score": 174.22874643700413,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2022.png",
            "scheme": "https"
        }
    },
    "832": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #832",
                "description": "description",
                "dna": "5f94ec368e36814e81c0a42ab71d0ce3a2d47516",
                "date": 1683440349384,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "832",
                "rarity_score": 197.2298846245017,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/832.png",
            "scheme": "https"
        }
    },
    "3947": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3947",
                "description": "description",
                "dna": "9748a4472cc6a92414e38123df402e2207c1b97d",
                "date": 1683432680169,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3947",
                "rarity_score": 163.91328128883154,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3947.png",
            "scheme": "https"
        }
    },
    "2804": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2804",
                "description": "description",
                "dna": "7d9d4fe9e7c490bab97d6ea4df9e95a65b1b33c3",
                "date": 1683441552697,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2804",
                "rarity_score": 173.49767482401415,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2804.png",
            "scheme": "https"
        }
    },
    "1871": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1871",
                "description": "description",
                "dna": "e6065e82314bbd93ece5885808f6e52501685380",
                "date": 1683441413237,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1871",
                "rarity_score": 151.2836320840669,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1871.png",
            "scheme": "https"
        }
    },
    "1547": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1547",
                "description": "description",
                "dna": "1032b811b191c4161a81225d23f7fd2f85013e6f",
                "date": 1683434919782,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1547",
                "rarity_score": 155.14653899403277,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1547.png",
            "scheme": "https"
        }
    },
    "3107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3107",
                "description": "description",
                "dna": "cf407e4fa91efa5ad0b7db806c9d3b10cdece208",
                "date": 1683441212198,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3107",
                "rarity_score": 152.4021586891513,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3107.png",
            "scheme": "https"
        }
    },
    "875": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #875",
                "description": "description",
                "dna": "e045476664fe1f33eb07e60f1d4528bb900c2ab5",
                "date": 1683445297405,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "875",
                "rarity_score": 309.75149502100123,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/875.png",
            "scheme": "https"
        }
    },
    "4022": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4022",
                "description": "description",
                "dna": "bd2825125974e4edaf49f065597fc825935e9d89",
                "date": 1683771099252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "4022",
                "rarity_score": 174.08285367440777,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4022.png",
            "scheme": "https"
        }
    },
    "1677": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1677",
                "description": "description",
                "dna": "1d4ddb601c4e72f4dc8830a1b3f97178e01d265d",
                "date": 1683774488898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1677",
                "rarity_score": 169.59955534979184,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1677.png",
            "scheme": "https"
        }
    },
    "962": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #962",
                "description": "description",
                "dna": "2f6ee98470aa9b127761bc3b11668bdd41e972ec",
                "date": 1683436897355,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "962",
                "rarity_score": 166.71600474119577,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/962.png",
            "scheme": "https"
        }
    },
    "416": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #416",
                "description": "description",
                "dna": "bbf9dfc04a6bcd93fc2e98bc1ea60b10483980a4",
                "date": 1683437589257,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "416",
                "rarity_score": 192.76219059524072,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/416.png",
            "scheme": "https"
        }
    },
    "2871": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2871",
                "description": "description",
                "dna": "9ac63235ab0e89e06f37692f6798439aa8ee5491",
                "date": 1683438857426,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2871",
                "rarity_score": 242.04836077456807,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2871.png",
            "scheme": "https"
        }
    },
    "2975": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2975",
                "description": "description",
                "dna": "fc00f0841977c5a85ee0d5ece5fb4d5a18d26893",
                "date": 1683442037465,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2975",
                "rarity_score": 296.6597797092629,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2975.png",
            "scheme": "https"
        }
    },
    "2353": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2353",
                "description": "description",
                "dna": "d8f0499656cf7a15f12eed1ae1da6e23755497e0",
                "date": 1683436176961,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2353",
                "rarity_score": 252.26774678727534,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2353.png",
            "scheme": "https"
        }
    },
    "1236": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1236",
                "description": "description",
                "dna": "54fa005db8f3dda7e3dacfdc11910e23cf2693f3",
                "date": 1683429746714,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1236",
                "rarity_score": 180.22028495651003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1236.png",
            "scheme": "https"
        }
    },
    "2155": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2155",
                "description": "description",
                "dna": "e3e2f7f70c25e792ab037c929aa38c01ff0cd77c",
                "date": 1683445705059,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2155",
                "rarity_score": 221.0385484607272,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2155.png",
            "scheme": "https"
        }
    },
    "1569": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1569",
                "description": "description",
                "dna": "2a94394af900272b369f3a79497db60877e4763c",
                "date": 1683439156591,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1569",
                "rarity_score": 148.3633691320405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1569.png",
            "scheme": "https"
        }
    },
    "582": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #582",
                "description": "description",
                "dna": "0ee33b7f6007efb8fb8cbd330d054b41bcf01a3f",
                "date": 1683436209300,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "582",
                "rarity_score": 252.41261687463276,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/582.png",
            "scheme": "https"
        }
    },
    "153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #153",
                "description": "description",
                "dna": "b259dd1633ba67018df0ab5cdb8586e8810542bf",
                "date": 1683437264049,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "153",
                "rarity_score": 131.74279031058887,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/153.png",
            "scheme": "https"
        }
    },
    "3929": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3929",
                "description": "description",
                "dna": "d0c7b42bee0e2316a648350116a957f476ba1c44",
                "date": 1683434602555,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3929",
                "rarity_score": 271.46543191005077,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3929.png",
            "scheme": "https"
        }
    },
    "3418": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3418",
                "description": "description",
                "dna": "aea558505fcc3b767b072e16ab73d12d659ab819",
                "date": 1683435223928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3418",
                "rarity_score": 203.18949197132196,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3418.png",
            "scheme": "https"
        }
    },
    "112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #112",
                "description": "description",
                "dna": "f1b81992ed91620414e0f713e3dc9743c6de02ac",
                "date": 1683444077543,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "112",
                "rarity_score": 259.70595039809956,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/112.png",
            "scheme": "https"
        }
    },
    "2854": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2854",
                "description": "description",
                "dna": "5cc7b40e18fee4cc6b82767e0e753afe3351599b",
                "date": 1683447103140,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2854",
                "rarity_score": 227.67325190972613,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2854.png",
            "scheme": "https"
        }
    },
    "1395": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1395",
                "description": "description",
                "dna": "d4ce46d675b3c209c6310e3cb9e966602322ae3e",
                "date": 1683517634356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "V Mask",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1395",
                "rarity_score": 4213.719525848948,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1395.png",
            "scheme": "https"
        }
    },
    "1822": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1822",
                "description": "description",
                "dna": "679f7b842dcac9ff44db0f47def14de45ec959b7",
                "date": 1683429304596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1822",
                "rarity_score": 212.72328664848015,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1822.png",
            "scheme": "https"
        }
    },
    "1670": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1670",
                "description": "description",
                "dna": "24399cbb323566574d38b4ba189fe565d631a110",
                "date": 1683439910991,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1670",
                "rarity_score": 264.6325033079882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1670.png",
            "scheme": "https"
        }
    },
    "708": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #708",
                "description": "description",
                "dna": "09e48c72bb38146fcbf36d9332074ac94360f8b8",
                "date": 1683447031318,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "708",
                "rarity_score": 202.44741320324596,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/708.png",
            "scheme": "https"
        }
    },
    "417": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #417",
                "description": "description",
                "dna": "cac00a4fc129c17feda85bf7507cb5e72645e6fd",
                "date": 1683440339772,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "417",
                "rarity_score": 189.29028699261087,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/417.png",
            "scheme": "https"
        }
    },
    "819": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #819",
                "description": "description",
                "dna": "208d53b1bab677781111304003fa5d31a8de5c89",
                "date": 1683440736649,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "819",
                "rarity_score": 189.4338444965471,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/819.png",
            "scheme": "https"
        }
    },
    "441": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #441",
                "description": "description",
                "dna": "d15e70cbe7eb0ad1dc3990754a02eb3fc24d0987",
                "date": 1683443146455,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "441",
                "rarity_score": 204.5031021685085,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/441.png",
            "scheme": "https"
        }
    },
    "2097": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2097",
                "description": "description",
                "dna": "5457f58f4c09c5ed122992791ec20b78242bfe30",
                "date": 1683443256423,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2097",
                "rarity_score": 245.01762182895195,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2097.png",
            "scheme": "https"
        }
    },
    "491": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #491",
                "description": "description",
                "dna": "7be89f64beeef191c53df99b9ad8b707d16afb84",
                "date": 1683441979718,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "491",
                "rarity_score": 200.533215115171,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/491.png",
            "scheme": "https"
        }
    },
    "2600": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2600",
                "description": "description",
                "dna": "1152f19fdacfca5371884151722e3e6478f9a410",
                "date": 1683433317145,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2600",
                "rarity_score": 214.64569834366586,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2600.png",
            "scheme": "https"
        }
    },
    "3559": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3559",
                "description": "description",
                "dna": "b725117312becbc78208e54d78cbcdd4bcaf9807",
                "date": 1683776569820,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3559",
                "rarity_score": 215.83954529032536,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3559.png",
            "scheme": "https"
        }
    },
    "2807": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2807",
                "description": "description",
                "dna": "021df5c4de22680aaac52de387d4409bfeb4b9ff",
                "date": 1683431712792,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2807",
                "rarity_score": 180.59372010014874,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2807.png",
            "scheme": "https"
        }
    },
    "1756": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1756",
                "description": "description",
                "dna": "cbbb26f0a1c4b28ebb672db607046f1aaae3c255",
                "date": 1683443278959,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1756",
                "rarity_score": 203.7770491145268,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1756.png",
            "scheme": "https"
        }
    },
    "3627": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3627",
                "description": "description",
                "dna": "5e4bab6d618124be8c56caef83b47a4ad2fcef11",
                "date": 1683432968393,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3627",
                "rarity_score": 221.76988559534738,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3627.png",
            "scheme": "https"
        }
    },
    "110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #110",
                "description": "description",
                "dna": "9cd683fd101cff674fcda8c4cda0951beb69e391",
                "date": 1683434880370,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "110",
                "rarity_score": 157.52819201365435,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/110.png",
            "scheme": "https"
        }
    },
    "1570": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1570",
                "description": "description",
                "dna": "043aa01870fa1a76351b454c0723d5113acc9215",
                "date": 1683442707744,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1570",
                "rarity_score": 232.77266391909566,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1570.png",
            "scheme": "https"
        }
    },
    "2203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2203",
                "description": "description",
                "dna": "ae2cea045a683b2dd06cd099a72e85f0d1ab9299",
                "date": 1683439322383,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2203",
                "rarity_score": 170.73201025776237,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2203.png",
            "scheme": "https"
        }
    },
    "860": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #860",
                "description": "description",
                "dna": "3e67a67bdc8246fbde21f779c70153ccd1f7955a",
                "date": 1683443079584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "860",
                "rarity_score": 158.48417395838936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/860.png",
            "scheme": "https"
        }
    },
    "2395": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2395",
                "description": "description",
                "dna": "ea98a48d3aea16160ea6bcb508ed1b2bdad266f1",
                "date": 1683434457219,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2395",
                "rarity_score": 223.13815968301427,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2395.png",
            "scheme": "https"
        }
    },
    "1359": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1359",
                "description": "description",
                "dna": "6979c7abf400af7cbdea8b904970abc4f977dbc8",
                "date": 1683429514780,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1359",
                "rarity_score": 255.8094806878795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1359.png",
            "scheme": "https"
        }
    },
    "2873": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2873",
                "description": "description",
                "dna": "2ae79d20c47960831f11559b5af742faf832aee6",
                "date": 1683436845239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2873",
                "rarity_score": 164.95826348593766,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2873.png",
            "scheme": "https"
        }
    },
    "822": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #822",
                "description": "description",
                "dna": "8590aa34394950e4f8565c8f48c7a7a0cc88af79",
                "date": 1683429510240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "822",
                "rarity_score": 232.8360366620991,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/822.png",
            "scheme": "https"
        }
    },
    "4089": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4089",
                "description": "description",
                "dna": "715e10511e922f40583cac9ed858bf69c3f4fd79",
                "date": 1683438573084,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4089",
                "rarity_score": 201.9766958953084,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4089.png",
            "scheme": "https"
        }
    },
    "1152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1152",
                "description": "description",
                "dna": "1eba1e3460996333fbebd55e22abc900872f54d2",
                "date": 1683437347372,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1152",
                "rarity_score": 147.979711820586,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1152.png",
            "scheme": "https"
        }
    },
    "1013": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1013",
                "description": "description",
                "dna": "7b4aa93b4eea6dbb5f8a2d7fc27d65e113d68730",
                "date": 1683432640227,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1013",
                "rarity_score": 238.40453617170516,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1013.png",
            "scheme": "https"
        }
    },
    "918": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #918",
                "description": "description",
                "dna": "c103ff36adb4dcd62179dd8ab828695f2ce4e958",
                "date": 1683439645576,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "918",
                "rarity_score": 167.42351197664223,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/918.png",
            "scheme": "https"
        }
    },
    "1038": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1038",
                "description": "description",
                "dna": "c95c71d38e31587b0098a1e49247b0018f6fe60b",
                "date": 1683431421953,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1038",
                "rarity_score": 203.47795371969784,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1038.png",
            "scheme": "https"
        }
    },
    "767": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #767",
                "description": "description",
                "dna": "27894f6846f98779bd2d4c7c90f38a0d0cdd2d75",
                "date": 1683437067665,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "767",
                "rarity_score": 130.72607153650145,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/767.png",
            "scheme": "https"
        }
    },
    "2453": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2453",
                "description": "description",
                "dna": "6ba0cda74d20fb3ee8939f5c84f7a28c03d25d00",
                "date": 1683447341261,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2453",
                "rarity_score": 179.7860111907169,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2453.png",
            "scheme": "https"
        }
    },
    "230": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #230",
                "description": "description",
                "dna": "d6226b917bd418695c47f646b6c192911f6c07bc",
                "date": 1683435507282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "230",
                "rarity_score": 203.1859954901382,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/230.png",
            "scheme": "https"
        }
    },
    "3481": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3481",
                "description": "description",
                "dna": "04ee19d3a45ee67a6027ac8d98b9ff9e294d77dd",
                "date": 1683443798583,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3481",
                "rarity_score": 182.69172753767813,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3481.png",
            "scheme": "https"
        }
    },
    "3299": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3299",
                "description": "description",
                "dna": "623659bf5a637230a58f992867ef534f3470245a",
                "date": 1683446411106,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3299",
                "rarity_score": 142.70723598600065,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3299.png",
            "scheme": "https"
        }
    },
    "289": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #289",
                "description": "description",
                "dna": "23017523515756180e24fec731370a52f1900fbe",
                "date": 1683439782751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "289",
                "rarity_score": 168.53291439443328,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/289.png",
            "scheme": "https"
        }
    },
    "2932": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2932",
                "description": "description",
                "dna": "8a6ffaf62013ec73985cab76403e1be91e9d0fc2",
                "date": 1683446206252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2932",
                "rarity_score": 142.49158662928753,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2932.png",
            "scheme": "https"
        }
    },
    "2322": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2322",
                "description": "description",
                "dna": "c6eeea9226ea101457dbf2cb98dfd0d4b92fa10c",
                "date": 1683439304279,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2322",
                "rarity_score": 168.13395201878882,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2322.png",
            "scheme": "https"
        }
    },
    "3505": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3505",
                "description": "description",
                "dna": "8c0a85450dbfec01862624126314b530a940cb25",
                "date": 1683429476858,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3505",
                "rarity_score": 295.26586011555105,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3505.png",
            "scheme": "https"
        }
    },
    "2082": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2082",
                "description": "description",
                "dna": "92bb41042b0c87e006ee256ded9ac17369e1ddf1",
                "date": 1683440559669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2082",
                "rarity_score": 160.1182789497534,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2082.png",
            "scheme": "https"
        }
    },
    "1366": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1366",
                "description": "description",
                "dna": "853eda915228fcf66fe087b23954161db6a47835",
                "date": 1683438139774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1366",
                "rarity_score": 229.67544399348947,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1366.png",
            "scheme": "https"
        }
    },
    "2797": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2797",
                "description": "description",
                "dna": "ddc0f30d33c595073661fe160e3445f5ad37f0ab",
                "date": 1683440650160,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2797",
                "rarity_score": 296.97105426986076,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2797.png",
            "scheme": "https"
        }
    },
    "3653": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3653",
                "description": "description",
                "dna": "8c2787f0b74c302b8a872a710be841a21e1d811d",
                "date": 1683440922633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3653",
                "rarity_score": 191.24972354740433,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3653.png",
            "scheme": "https"
        }
    },
    "3839": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3839",
                "description": "description",
                "dna": "29ac8ab32dd4a1c0669fab7002f9e5bdf2723d0e",
                "date": 1683440184320,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3839",
                "rarity_score": 156.58582420214913,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3839.png",
            "scheme": "https"
        }
    },
    "4075": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4075",
                "description": "description",
                "dna": "d7d86fde19d97d4c6485bcfc7e5ce847a5b8af1e",
                "date": 1683447061162,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4075",
                "rarity_score": 210.557959247637,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4075.png",
            "scheme": "https"
        }
    },
    "602": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #602",
                "description": "description",
                "dna": "d08c8a09cf32fbd3e931d1e93f7016dedbc19d81",
                "date": 1683434836354,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "602",
                "rarity_score": 163.1329193261194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/602.png",
            "scheme": "https"
        }
    },
    "1297": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1297",
                "description": "description",
                "dna": "9a1854a249b7ceb55c19bdc14fd74a70ba098494",
                "date": 1683434452703,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1297",
                "rarity_score": 226.0912693916529,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1297.png",
            "scheme": "https"
        }
    },
    "3824": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3824",
                "description": "description",
                "dna": "56002e16d70de406cac7ef1a021525174c7b75a0",
                "date": 1683432147375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3824",
                "rarity_score": 291.1361274863149,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3824.png",
            "scheme": "https"
        }
    },
    "1011": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1011",
                "description": "description",
                "dna": "c67cb917e08e6b9c2d2a60b612200eff6b7f61ce",
                "date": 1683443766170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1011",
                "rarity_score": 159.10089308600945,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1011.png",
            "scheme": "https"
        }
    },
    "145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #145",
                "description": "description",
                "dna": "3f7b1088ed734872c519bc30a95588b1df2d546f",
                "date": 1683438167669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "145",
                "rarity_score": 204.89153653485738,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/145.png",
            "scheme": "https"
        }
    },
    "586": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #586",
                "description": "description",
                "dna": "7a1eef4b6711ef66abb3600270231e383a327d19",
                "date": 1683432725714,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "586",
                "rarity_score": 240.26003898076095,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/586.png",
            "scheme": "https"
        }
    },
    "2501": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2501",
                "description": "description",
                "dna": "a586d2c27e99599f88c4000a89797b0b378287ab",
                "date": 1683431704160,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2501",
                "rarity_score": 255.17427860071894,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2501.png",
            "scheme": "https"
        }
    },
    "2867": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2867",
                "description": "description",
                "dna": "03a7f908ea2df816838831f74ce28fe5b8cfaa46",
                "date": 1683442835398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2867",
                "rarity_score": 169.504880006306,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2867.png",
            "scheme": "https"
        }
    },
    "2715": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2715",
                "description": "description",
                "dna": "f8fb33de6ee4dcdf7f881f0b61bfe73af9d4fad1",
                "date": 1683447443524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2715",
                "rarity_score": 205.3312784219305,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2715.png",
            "scheme": "https"
        }
    },
    "3624": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3624",
                "description": "description",
                "dna": "786144bc9e055d3384af0c10aec2d741308dc7b1",
                "date": 1683445904425,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3624",
                "rarity_score": 138.25250027661153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3624.png",
            "scheme": "https"
        }
    },
    "2315": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2315",
                "description": "description",
                "dna": "d5fdbf62c9899f857e6956b0ec8e67b769aa5afb",
                "date": 1683443482638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2315",
                "rarity_score": 215.86258711750034,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2315.png",
            "scheme": "https"
        }
    },
    "2243": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2243",
                "description": "description",
                "dna": "8218e313f416721e68d1d5fcbdf92255c9b7cc8b",
                "date": 1683431274176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2243",
                "rarity_score": 212.46890352259385,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2243.png",
            "scheme": "https"
        }
    },
    "1076": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1076",
                "description": "description",
                "dna": "e37610f8408997651665584963a90544339f71d7",
                "date": 1683441853890,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1076",
                "rarity_score": 177.6255023738865,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1076.png",
            "scheme": "https"
        }
    },
    "284": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #284",
                "description": "description",
                "dna": "6e0b6d3ab3480668238da45e2bcc6d22e78d5634",
                "date": 1683441466395,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "284",
                "rarity_score": 169.38630296823607,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/284.png",
            "scheme": "https"
        }
    },
    "314": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #314",
                "description": "description",
                "dna": "2d33dfda1f26dc4d26a636994fcf9aefe4eab02b",
                "date": 1683446542030,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "314",
                "rarity_score": 265.4369139753251,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/314.png",
            "scheme": "https"
        }
    },
    "3783": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3783",
                "description": "description",
                "dna": "76e293a4d928abacdfda749e5dc84c44b8308815",
                "date": 1683430752795,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3783",
                "rarity_score": 179.66099926061668,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3783.png",
            "scheme": "https"
        }
    },
    "2171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2171",
                "description": "description",
                "dna": "80a245188e16c4ad382c31373f378a0d59ab2864",
                "date": 1683433891925,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2171",
                "rarity_score": 150.5110851368953,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2171.png",
            "scheme": "https"
        }
    },
    "3339": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3339",
                "description": "description",
                "dna": "ffc40a958df241cb5a51c78be90e14dcc0c6c929",
                "date": 1683438617868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3339",
                "rarity_score": 223.19726643694818,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3339.png",
            "scheme": "https"
        }
    },
    "1620": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1620",
                "description": "description",
                "dna": "4a1eed18b31e4930255d33a927d80c4a7bbe19a1",
                "date": 1683446214249,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1620",
                "rarity_score": 134.25107935637035,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1620.png",
            "scheme": "https"
        }
    },
    "1018": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1018",
                "description": "description",
                "dna": "abbe6b1ac04a42b87b7643d1be6225ac4ccf3fde",
                "date": 1683775262945,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1018",
                "rarity_score": 176.8429821007775,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1018.png",
            "scheme": "https"
        }
    },
    "84": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #84",
                "description": "description",
                "dna": "c16067f12e36b64731d27dc2cbba221fc6ca6a3b",
                "date": 1683774507079,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "84",
                "rarity_score": 209.8528119810329,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/84.png",
            "scheme": "https"
        }
    },
    "2204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2204",
                "description": "description",
                "dna": "183af9524a2d0e9e90ffea88524a378425749c87",
                "date": 1683437765499,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2204",
                "rarity_score": 303.66046794489796,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2204.png",
            "scheme": "https"
        }
    },
    "3266": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3266",
                "description": "description",
                "dna": "6c28bf687a10b7dd3ec4fb02c5baf109a59a2e88",
                "date": 1683442876609,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3266",
                "rarity_score": 229.4590329596976,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3266.png",
            "scheme": "https"
        }
    },
    "2663": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2663",
                "description": "description",
                "dna": "102c4e64d73c232b9905560c25c0a9a41d1132fd",
                "date": 1683445426682,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2663",
                "rarity_score": 372.6538242444892,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2663.png",
            "scheme": "https"
        }
    },
    "419": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #419",
                "description": "description",
                "dna": "c4234abda557e50e8f47e0f1b15d0b9f9339e29f",
                "date": 1683774797132,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "419",
                "rarity_score": 195.18730289819874,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/419.png",
            "scheme": "https"
        }
    },
    "3900": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3900",
                "description": "description",
                "dna": "c847150550175ee461ec64825b9251daea664a1d",
                "date": 1683431430751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3900",
                "rarity_score": 280.16271080681645,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3900.png",
            "scheme": "https"
        }
    },
    "1030": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1030",
                "description": "description",
                "dna": "2f8c1073fffc7009eef68f65043411f224197a97",
                "date": 1683447290661,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1030",
                "rarity_score": 228.6911312988098,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1030.png",
            "scheme": "https"
        }
    },
    "3382": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3382",
                "description": "description",
                "dna": "7dd1216562941db5be704529f8c86571c06d5281",
                "date": 1683774955590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3382",
                "rarity_score": 278.64475816351245,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3382.png",
            "scheme": "https"
        }
    },
    "803": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #803",
                "description": "description",
                "dna": "3c63d9f3b66b22389f0b75a138004c4fe037330f",
                "date": 1683434854616,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "803",
                "rarity_score": 303.92952401556823,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/803.png",
            "scheme": "https"
        }
    },
    "2954": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2954",
                "description": "description",
                "dna": "cbc0dbf6971d307097fe524ef0834b1acb66ad9c",
                "date": 1683433443682,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2954",
                "rarity_score": 163.61808684721052,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2954.png",
            "scheme": "https"
        }
    },
    "2398": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2398",
                "description": "description",
                "dna": "1ef9c40e7cb85da08417f1a28aa87fa8bdc0b739",
                "date": 1683442508139,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2398",
                "rarity_score": 159.37225568987628,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2398.png",
            "scheme": "https"
        }
    },
    "1501": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1501",
                "description": "description",
                "dna": "877a552d8bab1c738feefea280590f91cb78e2e2",
                "date": 1683443500946,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1501",
                "rarity_score": 166.67541585979757,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1501.png",
            "scheme": "https"
        }
    },
    "2635": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2635",
                "description": "description",
                "dna": "f0399fec8f8a2fce59db93392d3e4f54443d1a47",
                "date": 1683440358053,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2635",
                "rarity_score": 153.30297539142043,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2635.png",
            "scheme": "https"
        }
    },
    "3572": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3572",
                "description": "description",
                "dna": "89afd76bcbc96970880b02a2bfe21867b908356d",
                "date": 1683774655266,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3572",
                "rarity_score": 178.94288651520228,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3572.png",
            "scheme": "https"
        }
    },
    "1479": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1479",
                "description": "description",
                "dna": "767545363852cd56218818111e51f264b6b13726",
                "date": 1683441035134,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1479",
                "rarity_score": 176.11469855893836,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1479.png",
            "scheme": "https"
        }
    },
    "3473": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3473",
                "description": "description",
                "dna": "187782c37ec8df17374935a8c7c4215e06b2c843",
                "date": 1683442619627,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3473",
                "rarity_score": 160.68403028272567,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3473.png",
            "scheme": "https"
        }
    },
    "1496": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1496",
                "description": "description",
                "dna": "098b606ff843a1bfd97ec81bf29512cc8eeaf134",
                "date": 1683444763974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1496",
                "rarity_score": 206.8166707826046,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1496.png",
            "scheme": "https"
        }
    },
    "4006": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4006",
                "description": "description",
                "dna": "830f835cdfb1740ec4a67834dde5aaaef4fadf5c",
                "date": 1683774403770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "4006",
                "rarity_score": 193.11126981830944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4006.png",
            "scheme": "https"
        }
    },
    "2044": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2044",
                "description": "description",
                "dna": "0917ffb5121248805d2bc10143e92c3d88ce3bd0",
                "date": 1683776536375,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2044",
                "rarity_score": 209.13414497061618,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2044.png",
            "scheme": "https"
        }
    },
    "3125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3125",
                "description": "description",
                "dna": "323321abfbc3801c84bdcb2fa38636e4954ac8c3",
                "date": 1683444489034,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3125",
                "rarity_score": 232.36949631571895,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3125.png",
            "scheme": "https"
        }
    },
    "64": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #64",
                "description": "description",
                "dna": "a25480012412cb1212ad96847739c8cac3c53e42",
                "date": 1683434185673,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "64",
                "rarity_score": 151.9895350561463,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/64.png",
            "scheme": "https"
        }
    },
    "1600": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1600",
                "description": "description",
                "dna": "8dc25e7e58bd97a4be7702b127c349d6a7089aa4",
                "date": 1683438414575,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1600",
                "rarity_score": 192.52850827735506,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1600.png",
            "scheme": "https"
        }
    },
    "3383": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3383",
                "description": "description",
                "dna": "6929fd25427273489e127c3d2176b18b6ffabe75",
                "date": 1683776599363,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3383",
                "rarity_score": 191.3765058020311,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3383.png",
            "scheme": "https"
        }
    },
    "2273": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2273",
                "description": "description",
                "dna": "a000f36d7567034ba9d9ba08c4d87d3b10489395",
                "date": 1683775153146,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2273",
                "rarity_score": 192.40060747657736,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2273.png",
            "scheme": "https"
        }
    },
    "1548": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1548",
                "description": "description",
                "dna": "ddda665fbbe148611f40ea44ac7bc1d30b55ac15",
                "date": 1683435172000,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1548",
                "rarity_score": 174.36561745981206,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1548.png",
            "scheme": "https"
        }
    },
    "3967": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3967",
                "description": "description",
                "dna": "4b95502e03c8fd971e585c22a79869617708a24c",
                "date": 1683774802263,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3967",
                "rarity_score": 328.60132439537625,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3967.png",
            "scheme": "https"
        }
    },
    "1886": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1886",
                "description": "description",
                "dna": "67c93854e0e937cab88169c6614c8ff9d7720dc8",
                "date": 1683438825920,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1886",
                "rarity_score": 207.86912160584322,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1886.png",
            "scheme": "https"
        }
    },
    "3014": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3014",
                "description": "description",
                "dna": "ab77f81c8134d036e11305a6a7a9bcc7069cc803",
                "date": 1683439255807,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3014",
                "rarity_score": 184.30577665115305,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3014.png",
            "scheme": "https"
        }
    },
    "2077": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2077",
                "description": "description",
                "dna": "9d11239bcb2654775d992695dc58f2f022a05974",
                "date": 1683437902487,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2077",
                "rarity_score": 297.83690005361393,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2077.png",
            "scheme": "https"
        }
    },
    "2357": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2357",
                "description": "description",
                "dna": "69f07783c7dbb61b912f77c464a49d4f617983fc",
                "date": 1683443423108,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2357",
                "rarity_score": 207.69490973024125,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2357.png",
            "scheme": "https"
        }
    },
    "2119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2119",
                "description": "description",
                "dna": "a20f1891f867e32c5e429aa02c9b37201c7e9d3c",
                "date": 1683433453119,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2119",
                "rarity_score": 273.34877735227315,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2119.png",
            "scheme": "https"
        }
    },
    "3072": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3072",
                "description": "description",
                "dna": "bd8f708241f0d595e7761ee6a35a4cf689281a49",
                "date": 1683429799825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3072",
                "rarity_score": 226.80230173443982,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3072.png",
            "scheme": "https"
        }
    },
    "1585": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1585",
                "description": "description",
                "dna": "dea385606db6d2aa5d8736064b3ae56f7d58b5f9",
                "date": 1683440074477,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1585",
                "rarity_score": 227.03158001104737,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1585.png",
            "scheme": "https"
        }
    },
    "1946": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1946",
                "description": "description",
                "dna": "88fa7a057d2635fb986e63a0b68a54126acb3f70",
                "date": 1683443770263,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1946",
                "rarity_score": 145.70392366951785,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1946.png",
            "scheme": "https"
        }
    },
    "3074": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3074",
                "description": "description",
                "dna": "808492f07c41c635cd2dc2b864e0b46a85a37c9a",
                "date": 1683771247635,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3074",
                "rarity_score": 204.8473249370353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3074.png",
            "scheme": "https"
        }
    },
    "3863": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3863",
                "description": "description",
                "dna": "67ceab6f4c4167b30f1e356b5e3094bc932908de",
                "date": 1683443270282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3863",
                "rarity_score": 244.63136666345864,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3863.png",
            "scheme": "https"
        }
    },
    "2554": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2554",
                "description": "description",
                "dna": "5f522d996b4d4af61cffb3f93701b77ea49c783b",
                "date": 1683444379662,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2554",
                "rarity_score": 202.58452287256054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2554.png",
            "scheme": "https"
        }
    },
    "2926": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2926",
                "description": "description",
                "dna": "13ebf8ddd935f00ac5e1d1c16ed3b25a9c8d713b",
                "date": 1683433592932,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2926",
                "rarity_score": 187.26933223123683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2926.png",
            "scheme": "https"
        }
    },
    "1177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1177",
                "description": "description",
                "dna": "69ac1303e731098fff0122fc850fd2ac80262658",
                "date": 1683443586848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1177",
                "rarity_score": 174.44021048832406,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1177.png",
            "scheme": "https"
        }
    },
    "2582": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2582",
                "description": "description",
                "dna": "ab105a14f77b8c84fcfceb3b2334e7f3267007b9",
                "date": 1683445892660,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2582",
                "rarity_score": 185.33231452289056,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2582.png",
            "scheme": "https"
        }
    },
    "1617": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1617",
                "description": "description",
                "dna": "509c09ac4f2138245f49bdbb6b59233889b8cac3",
                "date": 1683436375943,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1617",
                "rarity_score": 194.05072772566484,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1617.png",
            "scheme": "https"
        }
    },
    "618": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #618",
                "description": "description",
                "dna": "1558657f9aa663dd9bac68d2d5730ee78f9c3f35",
                "date": 1683437372489,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "618",
                "rarity_score": 181.24439361200842,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/618.png",
            "scheme": "https"
        }
    },
    "3845": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3845",
                "description": "description",
                "dna": "615a93083e6428dfd277465cc892a46cf8f67386",
                "date": 1683432365594,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3845",
                "rarity_score": 263.26441440329836,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3845.png",
            "scheme": "https"
        }
    },
    "2202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2202",
                "description": "description",
                "dna": "b053bd1098a5f7198cf006b4b9f82c89838a91d1",
                "date": 1683433935653,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2202",
                "rarity_score": 241.71901660671932,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2202.png",
            "scheme": "https"
        }
    },
    "3060": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3060",
                "description": "description",
                "dna": "8904e1fd1094416b6d8737e4ae3c81a1cda177e3",
                "date": 1683776366856,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "3060",
                "rarity_score": 292.74203508531394,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3060.png",
            "scheme": "https"
        }
    },
    "3774": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3774",
                "description": "description",
                "dna": "de1f1925083545c935dff062017b0b973f1febfe",
                "date": 1683439398964,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3774",
                "rarity_score": 203.26937990734456,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3774.png",
            "scheme": "https"
        }
    },
    "2531": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2531",
                "description": "description",
                "dna": "2fa92fa34a3d5088c14fc342dc662a65824e9e72",
                "date": 1683440859518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2531",
                "rarity_score": 168.59233088909826,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2531.png",
            "scheme": "https"
        }
    },
    "2477": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2477",
                "description": "description",
                "dna": "9f80feb6e14ddd74c610957424d48b5946d04ac9",
                "date": 1683776603770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2477",
                "rarity_score": 238.97626526733413,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2477.png",
            "scheme": "https"
        }
    },
    "3380": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3380",
                "description": "description",
                "dna": "3623eb573e239078fa273138a2c7629ef67fa9a5",
                "date": 1683444193176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3380",
                "rarity_score": 194.6088669223715,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3380.png",
            "scheme": "https"
        }
    },
    "3497": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3497",
                "description": "description",
                "dna": "03328044f7b9fad8b644719765f2ec5f0d2fe382",
                "date": 1683444725983,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3497",
                "rarity_score": 167.95490189718302,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3497.png",
            "scheme": "https"
        }
    },
    "3858": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3858",
                "description": "description",
                "dna": "05a4f37653da8da024517211096aa63c9492b3c5",
                "date": 1683442503454,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3858",
                "rarity_score": 271.4187018956271,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3858.png",
            "scheme": "https"
        }
    },
    "2166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2166",
                "description": "description",
                "dna": "735601a0b794f70ad93a6221eb5e1e6de81f635d",
                "date": 1683433073633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2166",
                "rarity_score": 167.0287057210612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2166.png",
            "scheme": "https"
        }
    },
    "1120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1120",
                "description": "description",
                "dna": "726d40e17db773215fc60889ed7e4508f7f5893f",
                "date": 1683445908520,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1120",
                "rarity_score": 165.0002986373471,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1120.png",
            "scheme": "https"
        }
    },
    "3981": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3981",
                "description": "description",
                "dna": "37dbc5adf3ea0472019703ce568eb9714f460921",
                "date": 1683436049060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3981",
                "rarity_score": 225.93843005462722,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3981.png",
            "scheme": "https"
        }
    },
    "212": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #212",
                "description": "description",
                "dna": "e56f253097e3f12058075059f13b487bd4d33ade",
                "date": 1683444947283,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "212",
                "rarity_score": 207.72993876377126,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/212.png",
            "scheme": "https"
        }
    },
    "392": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #392",
                "description": "description",
                "dna": "5254d7026728d9b747244c68e35ccb0face625bd",
                "date": 1683436363124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "392",
                "rarity_score": 254.7641795878256,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/392.png",
            "scheme": "https"
        }
    },
    "2946": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2946",
                "description": "description",
                "dna": "b2c54fa6df2b8fb3a3d53dbe6f94b8742c57e311",
                "date": 1683443326979,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2946",
                "rarity_score": 181.17886428854646,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2946.png",
            "scheme": "https"
        }
    },
    "3432": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3432",
                "description": "description",
                "dna": "e4255df31c8d4bffb6415a312b6f4063b1a5ed24",
                "date": 1683435074211,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3432",
                "rarity_score": 172.03661269955745,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3432.png",
            "scheme": "https"
        }
    },
    "2102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2102",
                "description": "description",
                "dna": "b46211b5e256eb022283db7ecd8c1ce9793dee55",
                "date": 1683429979262,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2102",
                "rarity_score": 163.01176816848496,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2102.png",
            "scheme": "https"
        }
    },
    "2994": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2994",
                "description": "description",
                "dna": "471f6087adc1b58ed59804543a7f2e364bd89758",
                "date": 1683430720954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2994",
                "rarity_score": 264.31632266932945,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2994.png",
            "scheme": "https"
        }
    },
    "2200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2200",
                "description": "description",
                "dna": "9796f1f7a6343bcbf101f10ba1a275681c1e5f67",
                "date": 1683434983770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2200",
                "rarity_score": 217.81345457479927,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2200.png",
            "scheme": "https"
        }
    },
    "2197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2197",
                "description": "description",
                "dna": "4e672abd39995d3117f6aab2e760c3d0c489c5b6",
                "date": 1683439636644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2197",
                "rarity_score": 200.17058617616405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2197.png",
            "scheme": "https"
        }
    },
    "526": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #526",
                "description": "description",
                "dna": "4bcea34e93a57115201e1d983d55fcdecd5a6d75",
                "date": 1683445145918,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "526",
                "rarity_score": 170.9348986002675,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/526.png",
            "scheme": "https"
        }
    },
    "1561": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1561",
                "description": "description",
                "dna": "948aee35be452b5edbde29f00ab4290648be92cf",
                "date": 1683771496090,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1561",
                "rarity_score": 178.6269944308161,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1561.png",
            "scheme": "https"
        }
    },
    "2608": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2608",
                "description": "description",
                "dna": "adea49a34257ef201d3aec177cda0753118cd337",
                "date": 1683442494750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2608",
                "rarity_score": 191.13719240346052,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2608.png",
            "scheme": "https"
        }
    },
    "1319": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1319",
                "description": "description",
                "dna": "681cf7afb919b3776dddeeb58ac02828137bdfd2",
                "date": 1683446455018,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1319",
                "rarity_score": 121.83983080118283,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1319.png",
            "scheme": "https"
        }
    },
    "1625": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1625",
                "description": "description",
                "dna": "92c11e7190955e3203fdbfb1c78d282875d7fa2b",
                "date": 1683431151654,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1625",
                "rarity_score": 205.19585911582683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1625.png",
            "scheme": "https"
        }
    },
    "3798": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3798",
                "description": "description",
                "dna": "4d16fe3e2a7498dc6d64bb4e5a2215b555818b90",
                "date": 1683430606418,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3798",
                "rarity_score": 255.6577878094528,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3798.png",
            "scheme": "https"
        }
    },
    "163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #163",
                "description": "description",
                "dna": "bad66879a12371c91deed5ce940ff67bc8ac8962",
                "date": 1683440850823,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "163",
                "rarity_score": 207.58417856166426,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/163.png",
            "scheme": "https"
        }
    },
    "3119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3119",
                "description": "description",
                "dna": "c2e1ead8729bfe6c983d2b3bb7bcb1044e15f692",
                "date": 1683441084571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3119",
                "rarity_score": 161.19280419217515,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3119.png",
            "scheme": "https"
        }
    },
    "330": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #330",
                "description": "description",
                "dna": "dabcf459014e1bcd8791fbd15c195b794d70e9fa",
                "date": 1683442555051,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "330",
                "rarity_score": 186.16289102312342,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/330.png",
            "scheme": "https"
        }
    },
    "102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #102",
                "description": "description",
                "dna": "9df8a8437d130c338fc36594ed35fd5b6cfe0649",
                "date": 1683441142655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "102",
                "rarity_score": 201.15399435636522,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/102.png",
            "scheme": "https"
        }
    },
    "3884": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3884",
                "description": "description",
                "dna": "f957106d6a4090952546374b145a9f2b131acbdb",
                "date": 1683432300594,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3884",
                "rarity_score": 161.69859524216713,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3884.png",
            "scheme": "https"
        }
    },
    "3056": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3056",
                "description": "description",
                "dna": "a166d698747468a41b4936d6ed2ff61426264480",
                "date": 1683440782494,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3056",
                "rarity_score": 249.17105299089272,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3056.png",
            "scheme": "https"
        }
    },
    "687": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #687",
                "description": "description",
                "dna": "2e14983f65efc1e367b2c72a806a670c97a7d608",
                "date": 1683446403545,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "687",
                "rarity_score": 171.4744161594756,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/687.png",
            "scheme": "https"
        }
    },
    "2356": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2356",
                "description": "description",
                "dna": "bcb1da45b3501cc5a065ebf4afa1883119f0d29b",
                "date": 1683432598629,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2356",
                "rarity_score": 182.73081299416933,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2356.png",
            "scheme": "https"
        }
    },
    "2376": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2376",
                "description": "description",
                "dna": "31e2ef694e568602e102bd73ad9cfa74e99b4c44",
                "date": 1683430695450,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2376",
                "rarity_score": 218.7479093026419,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2376.png",
            "scheme": "https"
        }
    },
    "3765": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3765",
                "description": "description",
                "dna": "bb392a22c250334c14c52d3c4cfdfc1901dcd486",
                "date": 1683442913967,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3765",
                "rarity_score": 186.05634432610972,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3765.png",
            "scheme": "https"
        }
    },
    "4052": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4052",
                "description": "description",
                "dna": "c33b99b7f5eeb4cd1d7f13ab5606e54f34a8b8f2",
                "date": 1683434069518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "4052",
                "rarity_score": 206.88777512062407,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4052.png",
            "scheme": "https"
        }
    },
    "1836": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1836",
                "description": "description",
                "dna": "1460a922b77a53a78cb683db73477b1b603e85f8",
                "date": 1683442965703,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1836",
                "rarity_score": 172.459366855199,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1836.png",
            "scheme": "https"
        }
    },
    "2460": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2460",
                "description": "description",
                "dna": "f9c329c19aeb122187ed96a049e80a75e6677580",
                "date": 1683436126190,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2460",
                "rarity_score": 311.38311412354204,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2460.png",
            "scheme": "https"
        }
    },
    "3807": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3807",
                "description": "description",
                "dna": "e28578805c12abdf10657ec7ffbd2ab63aa62baa",
                "date": 1683775126553,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3807",
                "rarity_score": 256.1154839975495,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3807.png",
            "scheme": "https"
        }
    },
    "2367": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2367",
                "description": "description",
                "dna": "c3557ca8ce72f03dcc12d4eddc006259511faeb3",
                "date": 1683446859309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2367",
                "rarity_score": 208.6481520810389,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2367.png",
            "scheme": "https"
        }
    },
    "1814": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1814",
                "description": "description",
                "dna": "cbddce7cd11e09660c1cdeefae72bccd7bea9baf",
                "date": 1683437149603,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1814",
                "rarity_score": 145.438095566176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1814.png",
            "scheme": "https"
        }
    },
    "3413": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3413",
                "description": "description",
                "dna": "7777ba6fde95f888a52ea67f4da176f767f4583e",
                "date": 1683443444218,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3413",
                "rarity_score": 164.7290506296406,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3413.png",
            "scheme": "https"
        }
    },
    "3555": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3555",
                "description": "description",
                "dna": "54cdec84feee8c506e3e5a73e53f938fc7f02cb0",
                "date": 1683447250233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3555",
                "rarity_score": 221.22867892385838,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3555.png",
            "scheme": "https"
        }
    },
    "3788": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3788",
                "description": "description",
                "dna": "d42037e738910163a6c9354c31d639e5ee871a8b",
                "date": 1683437335292,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3788",
                "rarity_score": 202.0724385106183,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3788.png",
            "scheme": "https"
        }
    },
    "4042": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4042",
                "description": "description",
                "dna": "ad4b58be64ca911b23edbc7e5390366afc4913a7",
                "date": 1683436551939,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "4042",
                "rarity_score": 227.09807959572544,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4042.png",
            "scheme": "https"
        }
    },
    "239": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #239",
                "description": "description",
                "dna": "2d1f203660ca4c5b181e6f70adfcd9343a894a85",
                "date": 1683437343252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "239",
                "rarity_score": 258.5751723172524,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/239.png",
            "scheme": "https"
        }
    },
    "534": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #534",
                "description": "description",
                "dna": "9e4b68f675accd7d0202793430980176561b50c3",
                "date": 1683442550311,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "534",
                "rarity_score": 240.0895780692909,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/534.png",
            "scheme": "https"
        }
    },
    "515": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #515",
                "description": "description",
                "dna": "b7fcc7f0ec2b0924d7021a02cd5c329caf27c596",
                "date": 1683437235995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "515",
                "rarity_score": 144.79879990874255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/515.png",
            "scheme": "https"
        }
    },
    "1060": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1060",
                "description": "description",
                "dna": "15c4cb15d08612cf40028061562529c0d9402a2b",
                "date": 1683444038132,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1060",
                "rarity_score": 227.56536003475986,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1060.png",
            "scheme": "https"
        }
    },
    "1298": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1298",
                "description": "description",
                "dna": "b07c8c48c0464b34197442f10b582bdc68097a25",
                "date": 1683446624688,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1298",
                "rarity_score": 194.51707891538138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1298.png",
            "scheme": "https"
        }
    },
    "1440": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1440",
                "description": "description",
                "dna": "dc9bc18d321c6ff387c1dedeabab29e0365e1283",
                "date": 1683771230762,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "1440",
                "rarity_score": 343.724983470884,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1440.png",
            "scheme": "https"
        }
    },
    "2824": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2824",
                "description": "description",
                "dna": "0c21f43463e01677c2002d3182afa4db3a1ea024",
                "date": 1683431897660,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2824",
                "rarity_score": 156.12662079209696,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2824.png",
            "scheme": "https"
        }
    },
    "1706": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1706",
                "description": "description",
                "dna": "e90c61ebaa44f479ddc12ac85fda8e125c43dffc",
                "date": 1683434831927,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1706",
                "rarity_score": 181.11632738275694,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1706.png",
            "scheme": "https"
        }
    },
    "3808": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3808",
                "description": "description",
                "dna": "7b83c1957aefdda0cf19471af62d0f732b058c94",
                "date": 1683434598125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3808",
                "rarity_score": 222.42119364817697,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3808.png",
            "scheme": "https"
        }
    },
    "2737": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2737",
                "description": "description",
                "dna": "569961a10157e4bc9d17ad66b5902281cf2394af",
                "date": 1683431377928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2737",
                "rarity_score": 270.35385248851134,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2737.png",
            "scheme": "https"
        }
    },
    "3945": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3945",
                "description": "description",
                "dna": "4d82cc0731a671d63857529f72e389d25db663d5",
                "date": 1683440088394,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3945",
                "rarity_score": 299.8371313872882,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3945.png",
            "scheme": "https"
        }
    },
    "2510": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2510",
                "description": "description",
                "dna": "90f02c91b7afacd34b912f73160039e3e9883fb3",
                "date": 1683430195678,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2510",
                "rarity_score": 175.06241627212592,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2510.png",
            "scheme": "https"
        }
    },
    "4011": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4011",
                "description": "description",
                "dna": "1fd2522f97a3d5c174aa26f571e16aabc6b6b225",
                "date": 1683771366552,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4011",
                "rarity_score": 188.6169648129996,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4011.png",
            "scheme": "https"
        }
    },
    "3358": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3358",
                "description": "description",
                "dna": "452301f7f65fd8b120b18579dbe7bcc3d3183809",
                "date": 1683445957841,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3358",
                "rarity_score": 188.39572218967783,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3358.png",
            "scheme": "https"
        }
    },
    "1374": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1374",
                "description": "description",
                "dna": "e3184db88f3ef1f366220da841aad70c1e400e71",
                "date": 1683437018248,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1374",
                "rarity_score": 240.8985345256239,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1374.png",
            "scheme": "https"
        }
    },
    "2780": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2780",
                "description": "description",
                "dna": "f14812f8f9099b6de9743a85a08f6df790cc23f2",
                "date": 1683440760101,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2780",
                "rarity_score": 180.82607050138833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2780.png",
            "scheme": "https"
        }
    },
    "3739": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3739",
                "description": "description",
                "dna": "4101f2cf7b5f5f104b27c05cd434c357ba143339",
                "date": 1683445759103,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3739",
                "rarity_score": 245.16533819464274,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3739.png",
            "scheme": "https"
        }
    },
    "1828": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1828",
                "description": "description",
                "dna": "c79ef7a9954e106eca9ef00a3f28c64401fa1d3d",
                "date": 1683440512988,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1828",
                "rarity_score": 166.82595587258052,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1828.png",
            "scheme": "https"
        }
    },
    "2375": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2375",
                "description": "description",
                "dna": "a6ac87a455aaa843d4776a3b700e8b0cdd5600e3",
                "date": 1683442606030,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2375",
                "rarity_score": 221.15602769303834,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2375.png",
            "scheme": "https"
        }
    },
    "1397": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1397",
                "description": "description",
                "dna": "87ec9bc410eb59b0987a038a4e7fdffa8a932ab6",
                "date": 1683433766537,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1397",
                "rarity_score": 156.78093550980012,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1397.png",
            "scheme": "https"
        }
    },
    "3046": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3046",
                "description": "description",
                "dna": "daf06b4336c1eee424e49e571f446c367b4a5ebe",
                "date": 1683443821204,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3046",
                "rarity_score": 204.32601409107977,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3046.png",
            "scheme": "https"
        }
    },
    "1133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1133",
                "description": "description",
                "dna": "6099cd10806e3e9a88bba74edba0f6c849e47a89",
                "date": 1683446784408,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1133",
                "rarity_score": 171.33206278052353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1133.png",
            "scheme": "https"
        }
    },
    "1701": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1701",
                "description": "description",
                "dna": "95c36cb111bce77b07b7f8b53418f41df1e956da",
                "date": 1683430842974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1701",
                "rarity_score": 171.4013552239272,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1701.png",
            "scheme": "https"
        }
    },
    "3930": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3930",
                "description": "description",
                "dna": "5ad14a8505a9404bb143f0301dfc7153b5936f2e",
                "date": 1683771560912,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3930",
                "rarity_score": 228.25665914449735,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3930.png",
            "scheme": "https"
        }
    },
    "1652": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1652",
                "description": "description",
                "dna": "b065d096e1214393584d905e57b0ccd89d0c0fbe",
                "date": 1683439565054,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1652",
                "rarity_score": 188.54526114172123,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1652.png",
            "scheme": "https"
        }
    },
    "3723": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3723",
                "description": "description",
                "dna": "2e3bd5be520dfa347bdc4f276a5091729ad1eccf",
                "date": 1683431307998,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3723",
                "rarity_score": 201.64343897260576,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3723.png",
            "scheme": "https"
        }
    },
    "2091": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2091",
                "description": "description",
                "dna": "c48e4f9382eb7055f57598897bb6f32ad71c67f0",
                "date": 1683776775094,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2091",
                "rarity_score": 193.0683403564641,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2091.png",
            "scheme": "https"
        }
    },
    "577": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #577",
                "description": "description",
                "dna": "aa0b69494c2f13b8577d302695510510798176de",
                "date": 1683446470936,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "577",
                "rarity_score": 228.52579447334494,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/577.png",
            "scheme": "https"
        }
    },
    "2953": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2953",
                "description": "description",
                "dna": "d6c991cbbaf1ba7afea29f279ef3256d34c1cb4c",
                "date": 1683442448794,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2953",
                "rarity_score": 222.78189695375855,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2953.png",
            "scheme": "https"
        }
    },
    "2568": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2568",
                "description": "description",
                "dna": "c8a80da252894e3df83e59dc9c51f00f70705854",
                "date": 1683438419075,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2568",
                "rarity_score": 141.2932982492477,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2568.png",
            "scheme": "https"
        }
    },
    "204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #204",
                "description": "description",
                "dna": "63178892746c67a3fd8911a0cdae504c0d0f917d",
                "date": 1683441071125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "204",
                "rarity_score": 172.01505553070285,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/204.png",
            "scheme": "https"
        }
    },
    "2025": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2025",
                "description": "description",
                "dna": "1f5f92c73f9afeb35417245fe83ddfc6c2960c7c",
                "date": 1683774739976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2025",
                "rarity_score": 161.44478983028614,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2025.png",
            "scheme": "https"
        }
    },
    "1014": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1014",
                "description": "description",
                "dna": "4262c2bd07c5bb12160dfc0544dee092a3013d68",
                "date": 1683441948831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1014",
                "rarity_score": 239.30957245734425,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1014.png",
            "scheme": "https"
        }
    },
    "2019": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2019",
                "description": "description",
                "dna": "b50e4a9734c8de8ded31468b48181e2f6098decd",
                "date": 1683439429995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2019",
                "rarity_score": 278.8610293524205,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2019.png",
            "scheme": "https"
        }
    },
    "1287": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1287",
                "description": "description",
                "dna": "c078441842415ba053ee0014505f72bc50a67186",
                "date": 1683430847464,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1287",
                "rarity_score": 210.78517143666812,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1287.png",
            "scheme": "https"
        }
    },
    "3564": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3564",
                "description": "description",
                "dna": "89d5765f5d4a93a1685fa84f0b6ba94011c3afd3",
                "date": 1683431893115,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3564",
                "rarity_score": 265.89710338643573,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3564.png",
            "scheme": "https"
        }
    },
    "894": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #894",
                "description": "description",
                "dna": "68c5a5e1b28900e8d7f3a2770f7500f2919daa47",
                "date": 1683776659364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "894",
                "rarity_score": 188.76442760309797,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/894.png",
            "scheme": "https"
        }
    },
    "1834": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1834",
                "description": "description",
                "dna": "160ac4bff3cab2c992e0a933b0c91453feea4427",
                "date": 1683440680913,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1834",
                "rarity_score": 218.68604396549057,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1834.png",
            "scheme": "https"
        }
    },
    "1132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1132",
                "description": "description",
                "dna": "cb1d8b054d454629373e48c2315fb7e6238d25c0",
                "date": 1683430182606,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1132",
                "rarity_score": 189.31300158870636,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1132.png",
            "scheme": "https"
        }
    },
    "489": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #489",
                "description": "description",
                "dna": "86250d7630419fee10de735c6948f60cf09bf33b",
                "date": 1683432684833,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "489",
                "rarity_score": 248.9233972513689,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/489.png",
            "scheme": "https"
        }
    },
    "1046": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1046",
                "description": "description",
                "dna": "77c6ea1039f2dc2338e6a945b87438303481e4ef",
                "date": 1683440260328,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1046",
                "rarity_score": 300.7228623161477,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1046.png",
            "scheme": "https"
        }
    },
    "135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #135",
                "description": "description",
                "dna": "75c38ac5a1fd9a7c72757a1e91ca6ce6a16d1ae0",
                "date": 1683444251487,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "135",
                "rarity_score": 155.31173001876655,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/135.png",
            "scheme": "https"
        }
    },
    "2583": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2583",
                "description": "description",
                "dna": "1d0790013437a28b4fd95a6867e5aa92176db467",
                "date": 1683447317861,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2583",
                "rarity_score": 211.103852183823,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2583.png",
            "scheme": "https"
        }
    },
    "2765": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2765",
                "description": "description",
                "dna": "4486dc553d276e460d7c77959bbd56f5c8266e26",
                "date": 1683438600114,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2765",
                "rarity_score": 236.74271497526576,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2765.png",
            "scheme": "https"
        }
    },
    "3435": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3435",
                "description": "description",
                "dna": "e89840f77eb08ec4fcb9bcb3dd027cd2b78b91d9",
                "date": 1683444016493,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3435",
                "rarity_score": 228.82989776680876,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3435.png",
            "scheme": "https"
        }
    },
    "3269": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3269",
                "description": "description",
                "dna": "f8b94ff92edf265901091aaf88e15237be30d525",
                "date": 1683437153925,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3269",
                "rarity_score": 179.89151557049155,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3269.png",
            "scheme": "https"
        }
    },
    "3033": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3033",
                "description": "description",
                "dna": "c558b07ae8d03b826d071711fe447f8a889d3575",
                "date": 1683770572482,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3033",
                "rarity_score": 277.19941594524994,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3033.png",
            "scheme": "https"
        }
    },
    "2502": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2502",
                "description": "description",
                "dna": "441f9542073a13dc24f9d51d0d0659c6f9c36d2c",
                "date": 1683437867537,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2502",
                "rarity_score": 188.25389527962182,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2502.png",
            "scheme": "https"
        }
    },
    "979": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #979",
                "description": "description",
                "dna": "ee6d7d2df380c487726b5a14cd96e7c087a5ac31",
                "date": 1683443093153,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "979",
                "rarity_score": 180.9031524662138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/979.png",
            "scheme": "https"
        }
    },
    "85": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #85",
                "description": "description",
                "dna": "fd202f59729ffb1df5619b9714b0ee70201fa54f",
                "date": 1683436724286,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "85",
                "rarity_score": 257.5706030802826,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/85.png",
            "scheme": "https"
        }
    },
    "2120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2120",
                "description": "description",
                "dna": "3743d16c9c616d31d24d7e99690e098eda37a63a",
                "date": 1683439677501,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2120",
                "rarity_score": 172.71631209182664,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2120.png",
            "scheme": "https"
        }
    },
    "3668": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3668",
                "description": "description",
                "dna": "46ce0b2799c262573226296e5924b7970579e71b",
                "date": 1683776672420,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3668",
                "rarity_score": 235.51818843162914,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3668.png",
            "scheme": "https"
        }
    },
    "2450": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2450",
                "description": "description",
                "dna": "300d1ed3dbeea9c4934cc171326b54f14e33510b",
                "date": 1683508635153,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2450",
                "rarity_score": 372.78351302534963,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2450.png",
            "scheme": "https"
        }
    },
    "3138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3138",
                "description": "description",
                "dna": "e7919a2eaaf5509785b5fc5653f7fb8a1779497f",
                "date": 1683444121964,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3138",
                "rarity_score": 156.89236616073705,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3138.png",
            "scheme": "https"
        }
    },
    "1138": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1138",
                "description": "description",
                "dna": "ce1310a76f5f1c8efc2797ef5e8acfea6e11107f",
                "date": 1683442730664,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1138",
                "rarity_score": 202.74911671656727,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1138.png",
            "scheme": "https"
        }
    },
    "2973": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2973",
                "description": "description",
                "dna": "21dd7860807ba3a920108725fc97a47e5992cebf",
                "date": 1683433695106,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2973",
                "rarity_score": 178.7592649903737,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2973.png",
            "scheme": "https"
        }
    },
    "50": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #50",
                "description": "description",
                "dna": "89224b702f81410c1339f1c8cf618b2e8a25d715",
                "date": 1683442087027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "50",
                "rarity_score": 249.0912605211826,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/50.png",
            "scheme": "https"
        }
    },
    "21": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #21",
                "description": "description",
                "dna": "0aa045d3ac0158c8606fba0c7011a7e601e35f68",
                "date": 1683434606760,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "21",
                "rarity_score": 161.628606241292,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/21.png",
            "scheme": "https"
        }
    },
    "1726": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1726",
                "description": "description",
                "dna": "2a60992c9f18666668d31fc25842b7e37d615106",
                "date": 1683771578218,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1726",
                "rarity_score": 259.0149695602637,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1726.png",
            "scheme": "https"
        }
    },
    "471": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #471",
                "description": "description",
                "dna": "36a564588022d640902c567b1614c38cab77d734",
                "date": 1683430102854,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "471",
                "rarity_score": 262.1066309299474,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/471.png",
            "scheme": "https"
        }
    },
    "711": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #711",
                "description": "description",
                "dna": "9e7e45432cb94f37a461c0e0acfcb71007281277",
                "date": 1683429719571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "711",
                "rarity_score": 169.53559893564324,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/711.png",
            "scheme": "https"
        }
    },
    "1956": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1956",
                "description": "description",
                "dna": "dac0d834b71d04befd66b328aec46801d31a8021",
                "date": 1683434380805,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1956",
                "rarity_score": 189.88303866982145,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1956.png",
            "scheme": "https"
        }
    },
    "657": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #657",
                "description": "description",
                "dna": "06439990c6cf18f3209902a5045843eabaf0280a",
                "date": 1683434693819,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "657",
                "rarity_score": 180.20203284673602,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/657.png",
            "scheme": "https"
        }
    },
    "2577": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2577",
                "description": "description",
                "dna": "ce708a3885dde35f5bc24cc0de0f1a37b5eddacf",
                "date": 1683435502856,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2577",
                "rarity_score": 215.95911412977267,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2577.png",
            "scheme": "https"
        }
    },
    "3550": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3550",
                "description": "description",
                "dna": "6909d4fca9d935870ed917e0fbccc2a29255ce0f",
                "date": 1683441297327,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3550",
                "rarity_score": 155.06132270104217,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3550.png",
            "scheme": "https"
        }
    },
    "1159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1159",
                "description": "description",
                "dna": "a8de9e877d46e3a812488ce1d12d078a4918efea",
                "date": 1683771535436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1159",
                "rarity_score": 246.34695024774464,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1159.png",
            "scheme": "https"
        }
    },
    "3482": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3482",
                "description": "description",
                "dna": "43e2ace3ee909d4468228266184dc1579f865761",
                "date": 1683432977388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3482",
                "rarity_score": 158.1923102188852,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3482.png",
            "scheme": "https"
        }
    },
    "3993": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3993",
                "description": "description",
                "dna": "ff01e6c9578559801e22fc29fdea96944d2d3d66",
                "date": 1683446121021,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3993",
                "rarity_score": 165.8432747459319,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3993.png",
            "scheme": "https"
        }
    },
    "1492": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1492",
                "description": "description",
                "dna": "10f97b0aa916b8a674411d3151f9f023c435b72a",
                "date": 1683446273599,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1492",
                "rarity_score": 165.4347872124632,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1492.png",
            "scheme": "https"
        }
    },
    "2803": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2803",
                "description": "description",
                "dna": "11c0b14a0515f868c185dbf82de3dd16a229e4b9",
                "date": 1683431265372,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2803",
                "rarity_score": 191.3244678738583,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2803.png",
            "scheme": "https"
        }
    },
    "2662": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2662",
                "description": "description",
                "dna": "7491dffe7b194553142e1c981654774b38182968",
                "date": 1683431826022,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2662",
                "rarity_score": 189.81263356219333,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2662.png",
            "scheme": "https"
        }
    },
    "3354": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3354",
                "description": "description",
                "dna": "2592b9178be1365f89b3f7d38e4275f54b898854",
                "date": 1683437272880,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3354",
                "rarity_score": 167.87957212415063,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3354.png",
            "scheme": "https"
        }
    },
    "17": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #17",
                "description": "description",
                "dna": "33ed5faf165a9b7072ae2f2cf38a3d45b1cf18a0",
                "date": 1683438153557,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "17",
                "rarity_score": 361.5482470018559,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/17.png",
            "scheme": "https"
        }
    },
    "295": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #295",
                "description": "description",
                "dna": "6ede0e8068eab257deeb65703fb19c9457250a18",
                "date": 1683439394533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "295",
                "rarity_score": 200.74391013050175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/295.png",
            "scheme": "https"
        }
    },
    "641": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #641",
                "description": "description",
                "dna": "62e7793dee0bfb95689f81b31bc7c716a8724474",
                "date": 1683446662685,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "641",
                "rarity_score": 171.58715779039136,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/641.png",
            "scheme": "https"
        }
    },
    "1056": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1056",
                "description": "description",
                "dna": "73d3c14d54dc72db59ca1faa50b44ee5ed2c2014",
                "date": 1683776582689,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1056",
                "rarity_score": 245.99587649450402,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1056.png",
            "scheme": "https"
        }
    },
    "3787": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3787",
                "description": "description",
                "dna": "33684a9f87f4506ff964c300875fc42f08e65f8f",
                "date": 1683446478709,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3787",
                "rarity_score": 176.44148959027262,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3787.png",
            "scheme": "https"
        }
    },
    "3069": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3069",
                "description": "description",
                "dna": "bc360966eaeb2a0ee6ef2eb537042625c6b8069e",
                "date": 1683441570457,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3069",
                "rarity_score": 257.0107639409886,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3069.png",
            "scheme": "https"
        }
    },
    "1199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1199",
                "description": "description",
                "dna": "9b3c95c67f4d34ffc425debb529644c1438f85aa",
                "date": 1683440672259,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1199",
                "rarity_score": 224.84299810653818,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1199.png",
            "scheme": "https"
        }
    },
    "1517": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1517",
                "description": "description",
                "dna": "c519479f6fafe9e576d45049ed16c843b165da66",
                "date": 1683434777001,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1517",
                "rarity_score": 224.3266780945877,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1517.png",
            "scheme": "https"
        }
    },
    "3436": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3436",
                "description": "description",
                "dna": "4b00fb930a545e1a3e01d0976bb2b3ba0c9412af",
                "date": 1683443362282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3436",
                "rarity_score": 189.22017481572814,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3436.png",
            "scheme": "https"
        }
    },
    "151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #151",
                "description": "description",
                "dna": "b5d67adb2b5230b6c69cda67cb31822ec0c7039a",
                "date": 1683446467099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "151",
                "rarity_score": 132.67990278130003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/151.png",
            "scheme": "https"
        }
    },
    "1300": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1300",
                "description": "description",
                "dna": "abb8a2508e581add66ded9b8e77b01a7e1e4c07b",
                "date": 1683435028919,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1300",
                "rarity_score": 306.0473921787266,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1300.png",
            "scheme": "https"
        }
    },
    "2636": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2636",
                "description": "description",
                "dna": "e005537c53f75d705b36e6c7f17ee87c098b1fb9",
                "date": 1683429472614,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2636",
                "rarity_score": 269.41697885895223,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2636.png",
            "scheme": "https"
        }
    },
    "280": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #280",
                "description": "description",
                "dna": "1d36f27173e7298981ec22321a86dc1a381677ea",
                "date": 1683441894546,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "280",
                "rarity_score": 212.33210018424802,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/280.png",
            "scheme": "https"
        }
    },
    "1963": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1963",
                "description": "description",
                "dna": "26cbc1ecfb91d949626a620af97844cf088e0a49",
                "date": 1683441306245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1963",
                "rarity_score": 334.92182590555797,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1963.png",
            "scheme": "https"
        }
    },
    "2029": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2029",
                "description": "description",
                "dna": "cfe6b4e076e4a22fd2877a12450ad871ce4f4dea",
                "date": 1683771573849,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2029",
                "rarity_score": 171.55245435100818,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2029.png",
            "scheme": "https"
        }
    },
    "1192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1192",
                "description": "description",
                "dna": "34e6e520bd4b39e557052a959814f15e813b7b29",
                "date": 1683444072952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1192",
                "rarity_score": 203.87066233238482,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1192.png",
            "scheme": "https"
        }
    },
    "58": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #58",
                "description": "description",
                "dna": "d8e42bb62bc07de9d5e9f1f16f43418d973a25f5",
                "date": 1683441862926,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "58",
                "rarity_score": 163.60997854573662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/58.png",
            "scheme": "https"
        }
    },
    "4094": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4094",
                "description": "description",
                "dna": "8a573198d86adbd4140d52c0e6d30eb2a24102c4",
                "date": 1683432156008,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "4094",
                "rarity_score": 145.88615609864578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4094.png",
            "scheme": "https"
        }
    },
    "2698": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2698",
                "description": "description",
                "dna": "86ed90ccd42adb2577dc69855d8b085bc3fb2151",
                "date": 1683439686607,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2698",
                "rarity_score": 153.9443697139006,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2698.png",
            "scheme": "https"
        }
    },
    "2129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2129",
                "description": "description",
                "dna": "c9896fbc01d2a0f4744f9843192fbf2a541c6f45",
                "date": 1683442587030,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2129",
                "rarity_score": 213.82522194926094,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2129.png",
            "scheme": "https"
        }
    },
    "3736": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3736",
                "description": "description",
                "dna": "57dd22c8cec081aa8dd742ae8ba2e52085139f82",
                "date": 1683444664447,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3736",
                "rarity_score": 154.6126501840132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3736.png",
            "scheme": "https"
        }
    },
    "2271": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2271",
                "description": "description",
                "dna": "2bdde9c899edc49fd5f0e0d01e63af73da8a8ce2",
                "date": 1683430704080,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2271",
                "rarity_score": 337.9288973645024,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2271.png",
            "scheme": "https"
        }
    },
    "1296": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1296",
                "description": "description",
                "dna": "d7b100951852c60814682e85227af9b54bfdcaac",
                "date": 1683771213268,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1296",
                "rarity_score": 186.78954080049556,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1296.png",
            "scheme": "https"
        }
    },
    "2810": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2810",
                "description": "description",
                "dna": "7f9224803c260951b53cef578b9b6671ea554b74",
                "date": 1683431184223,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2810",
                "rarity_score": 205.42024686182313,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2810.png",
            "scheme": "https"
        }
    },
    "2295": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2295",
                "description": "description",
                "dna": "4540453fec980eaf1947b7806b02ecdfc5cf85fe",
                "date": 1683436611337,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2295",
                "rarity_score": 228.56898869186196,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2295.png",
            "scheme": "https"
        }
    },
    "3454": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3454",
                "description": "description",
                "dna": "cc045d79c0f08dc762486da0dc0b86b6e655e120",
                "date": 1683442517239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3454",
                "rarity_score": 238.27687409628822,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3454.png",
            "scheme": "https"
        }
    },
    "784": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #784",
                "description": "description",
                "dna": "bdb2567aea5a86bf12a042d7898fe8a686c72e50",
                "date": 1683441741706,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "784",
                "rarity_score": 186.94932541116157,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/784.png",
            "scheme": "https"
        }
    },
    "1062": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1062",
                "description": "description",
                "dna": "06c42c58f77be04a37c2f47f74de9a958ddd4910",
                "date": 1683444211561,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1062",
                "rarity_score": 175.07912571316245,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1062.png",
            "scheme": "https"
        }
    },
    "3756": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3756",
                "description": "description",
                "dna": "e7b95e50ee6ad37d9b5c08c7068f9ab506880f47",
                "date": 1683434910626,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3756",
                "rarity_score": 223.00317668954017,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3756.png",
            "scheme": "https"
        }
    },
    "3849": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3849",
                "description": "description",
                "dna": "6f19f3dd7e08e38573edcf3123c7f8adf582c9f2",
                "date": 1683438400283,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3849",
                "rarity_score": 173.86176435049924,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3849.png",
            "scheme": "https"
        }
    },
    "3362": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3362",
                "description": "description",
                "dna": "216375749990e0bdeec62f3a6cda0b2b243e37ea",
                "date": 1683435219387,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "3362",
                "rarity_score": 316.74921352622096,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3362.png",
            "scheme": "https"
        }
    },
    "487": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #487",
                "description": "description",
                "dna": "4f045cff505af76681e2e5ec51df560dcbc2ea8b",
                "date": 1683442872184,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "487",
                "rarity_score": 176.69832607202488,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/487.png",
            "scheme": "https"
        }
    },
    "2310": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2310",
                "description": "description",
                "dna": "93f365ba9f184a8b5c6f48da33e4b59448761443",
                "date": 1683432852974,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2310",
                "rarity_score": 232.69076567762625,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2310.png",
            "scheme": "https"
        }
    },
    "73": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #73",
                "description": "description",
                "dna": "af3ea0c37951fa844d81a780704adadec32d82b4",
                "date": 1683437251793,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "73",
                "rarity_score": 166.8068293472996,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/73.png",
            "scheme": "https"
        }
    },
    "3150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3150",
                "description": "description",
                "dna": "117b3a6dad240288e3ccc290200c773f0c7c4a5f",
                "date": 1683771543609,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3150",
                "rarity_score": 191.8981504503801,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3150.png",
            "scheme": "https"
        }
    },
    "2067": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2067",
                "description": "description",
                "dna": "33bd6456984d20fc17119bf5dfd78b4c7ac527a9",
                "date": 1683443917138,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2067",
                "rarity_score": 205.42865752079285,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2067.png",
            "scheme": "https"
        }
    },
    "1710": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1710",
                "description": "description",
                "dna": "bef026dafb872106fcc6012bcdb60f412d27d6be",
                "date": 1683432630536,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1710",
                "rarity_score": 203.62839923998695,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1710.png",
            "scheme": "https"
        }
    },
    "1807": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1807",
                "description": "description",
                "dna": "739d27d981b755be85df5cef2dd7dc64e30196b1",
                "date": 1683431910942,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1807",
                "rarity_score": 191.85017754434443,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1807.png",
            "scheme": "https"
        }
    },
    "3609": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3609",
                "description": "description",
                "dna": "e19744b3bf7efe0134659f65d2d4eca8ced1e684",
                "date": 1683430956905,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3609",
                "rarity_score": 386.2300268571855,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3609.png",
            "scheme": "https"
        }
    },
    "3815": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3815",
                "description": "description",
                "dna": "36e41ec45de4b8a85c48c1f863d5eb5befcd1893",
                "date": 1683433390362,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3815",
                "rarity_score": 206.92329447779954,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3815.png",
            "scheme": "https"
        }
    },
    "2657": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2657",
                "description": "description",
                "dna": "5a7240810996797ff90d72bb852ae1b779a4998c",
                "date": 1683437560427,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2657",
                "rarity_score": 135.10631132010198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2657.png",
            "scheme": "https"
        }
    },
    "308": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #308",
                "description": "description",
                "dna": "4fab1e60ebd66f46f0860bff9649bde21d8f7bef",
                "date": 1683437756416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "308",
                "rarity_score": 252.53836435915952,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/308.png",
            "scheme": "https"
        }
    },
    "3025": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3025",
                "description": "description",
                "dna": "07f5af82853b46d7ecb78d8e498e429288b8b28d",
                "date": 1683446884611,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3025",
                "rarity_score": 172.82676404566425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3025.png",
            "scheme": "https"
        }
    },
    "3135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3135",
                "description": "description",
                "dna": "702a3a93db0bbb12f901301d974957e89b00a421",
                "date": 1683445132396,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3135",
                "rarity_score": 290.56066409926103,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3135.png",
            "scheme": "https"
        }
    },
    "3777": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3777",
                "description": "description",
                "dna": "e40a3256c698051ff4ab1f6717e38335d7c7b0b9",
                "date": 1683440111860,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3777",
                "rarity_score": 169.12135496742235,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3777.png",
            "scheme": "https"
        }
    },
    "629": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #629",
                "description": "description",
                "dna": "996ea079d6f3f69d0d15684363b72308c9aa31bb",
                "date": 1683435283325,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "629",
                "rarity_score": 403.7900283257485,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/629.png",
            "scheme": "https"
        }
    },
    "3019": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3019",
                "description": "description",
                "dna": "535fecb0a1e8f9d91259becef13d55db6ecd9b7b",
                "date": 1683446590777,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3019",
                "rarity_score": 227.3315792356886,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3019.png",
            "scheme": "https"
        }
    },
    "678": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #678",
                "description": "description",
                "dna": "68056d7735d0fb0eb9111046b2f3c3e833dc098e",
                "date": 1683447114952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "678",
                "rarity_score": 187.9166132981899,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/678.png",
            "scheme": "https"
        }
    },
    "923": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #923",
                "description": "description",
                "dna": "239f9b8c37810a010bec31542bdd9de93501fc0e",
                "date": 1683445879800,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "923",
                "rarity_score": 211.3964314850654,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/923.png",
            "scheme": "https"
        }
    },
    "3566": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3566",
                "description": "description",
                "dna": "4999b7c1854266d7213651cb228049d01d4d7042",
                "date": 1683447006581,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3566",
                "rarity_score": 182.39499759784175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3566.png",
            "scheme": "https"
        }
    },
    "144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #144",
                "description": "description",
                "dna": "38ac23d328d6cd33155120971d9bef039ee07517",
                "date": 1683776586854,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "144",
                "rarity_score": 209.176230147177,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/144.png",
            "scheme": "https"
        }
    },
    "1329": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1329",
                "description": "description",
                "dna": "be22fe808854bbf2146591d3d8d452d0b2f2def4",
                "date": 1683435355076,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1329",
                "rarity_score": 226.44562553626548,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1329.png",
            "scheme": "https"
        }
    },
    "214": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #214",
                "description": "description",
                "dna": "357850b7105f35959bb457191c8f1c8cd5ebbd27",
                "date": 1683437211273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "214",
                "rarity_score": 229.0780474516393,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/214.png",
            "scheme": "https"
        }
    },
    "1129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1129",
                "description": "description",
                "dna": "a363487fb025cc4a9813dafa58f796de59b20ea0",
                "date": 1683437083241,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1129",
                "rarity_score": 145.9845436377922,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1129.png",
            "scheme": "https"
        }
    },
    "3108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3108",
                "description": "description",
                "dna": "b6470f3e4fccec4c6c883a65bb44c85af632a833",
                "date": 1683434448185,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3108",
                "rarity_score": 208.90735737565365,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3108.png",
            "scheme": "https"
        }
    },
    "1200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1200",
                "description": "description",
                "dna": "b8f8ee703d5afedfb1e6514b7a189b70bb1f3bd7",
                "date": 1683430247641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1200",
                "rarity_score": 219.990553510625,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1200.png",
            "scheme": "https"
        }
    },
    "2996": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2996",
                "description": "description",
                "dna": "35fc0e25ff4bd316c8894804c8fa9c665b40d4fa",
                "date": 1683442568781,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2996",
                "rarity_score": 171.37678851116058,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2996.png",
            "scheme": "https"
        }
    },
    "1023": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1023",
                "description": "description",
                "dna": "1f61ff506fda3b1cce46fb99e77d56cc54d11d28",
                "date": 1683436478147,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1023",
                "rarity_score": 229.41754555398578,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1023.png",
            "scheme": "https"
        }
    },
    "3840": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3840",
                "description": "description",
                "dna": "4f5815545f188930e1675205d785505dc4193c2c",
                "date": 1683445816280,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3840",
                "rarity_score": 303.4512983179583,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3840.png",
            "scheme": "https"
        }
    },
    "981": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #981",
                "description": "description",
                "dna": "acc669e450c1c2c3d643c4e05cb7cd704288e688",
                "date": 1683446293217,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "981",
                "rarity_score": 162.17254205839671,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/981.png",
            "scheme": "https"
        }
    },
    "2445": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2445",
                "description": "description",
                "dna": "4aa69da4d857f7ebc2876ca75049eaa863c65d48",
                "date": 1683446230436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2445",
                "rarity_score": 120.08141919145373,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2445.png",
            "scheme": "https"
        }
    },
    "3321": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3321",
                "description": "description",
                "dna": "a889a53a666fdb9da35aad2a17dbc65456dbb270",
                "date": 1683444225527,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3321",
                "rarity_score": 185.82450920723986,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3321.png",
            "scheme": "https"
        }
    },
    "1969": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1969",
                "description": "description",
                "dna": "464cf062517d4e55ced6300f8b4ae5d39e83439a",
                "date": 1683441152039,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1969",
                "rarity_score": 194.48550337834138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1969.png",
            "scheme": "https"
        }
    },
    "4063": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4063",
                "description": "description",
                "dna": "7619e0438636ff6a1d6674b7225563821cbba8a6",
                "date": 1683446666829,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "4063",
                "rarity_score": 217.27971186746825,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4063.png",
            "scheme": "https"
        }
    },
    "725": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #725",
                "description": "description",
                "dna": "1056ff5885f4e9d269ad077a79567fda201af3a6",
                "date": 1683432748436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "725",
                "rarity_score": 161.97909715460034,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/725.png",
            "scheme": "https"
        }
    },
    "2830": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2830",
                "description": "description",
                "dna": "f16ce9d89ef87b5bb36064192fd37e79a7be999d",
                "date": 1683429888737,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2830",
                "rarity_score": 194.59010999896472,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2830.png",
            "scheme": "https"
        }
    },
    "1916": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1916",
                "description": "description",
                "dna": "c5d8867d1110ea97f766e986f00b8ba8123f3abb",
                "date": 1683444099358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1916",
                "rarity_score": 178.73439909553986,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1916.png",
            "scheme": "https"
        }
    },
    "1179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1179",
                "description": "description",
                "dna": "0aa34fa6e41adfed1ed10df0473ab555004a7528",
                "date": 1683434366489,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1179",
                "rarity_score": 185.25980831832024,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1179.png",
            "scheme": "https"
        }
    },
    "2680": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2680",
                "description": "description",
                "dna": "b3401171605272310d8f956d979d40d56a65bf4f",
                "date": 1683439891825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2680",
                "rarity_score": 198.74771663871047,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2680.png",
            "scheme": "https"
        }
    },
    "98": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #98",
                "description": "description",
                "dna": "fea21ba63a03d17efadd16750bcfde4866f6425d",
                "date": 1683774722162,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "98",
                "rarity_score": 202.10458441993518,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/98.png",
            "scheme": "https"
        }
    },
    "2142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2142",
                "description": "description",
                "dna": "7e25b73865721d6eb563d664df8a0a30ee9c5ffe",
                "date": 1683443191729,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2142",
                "rarity_score": 176.69999560738674,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2142.png",
            "scheme": "https"
        }
    },
    "1837": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1837",
                "description": "description",
                "dna": "304c35c1728f8a31cb5c208c2173bbcce9fdf76a",
                "date": 1683774480266,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1837",
                "rarity_score": 188.6157112720582,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1837.png",
            "scheme": "https"
        }
    },
    "1385": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1385",
                "description": "description",
                "dna": "8437870ca82b35bbbb8fe4b797085d9a23a7d601",
                "date": 1683436433198,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1385",
                "rarity_score": 207.24258362044876,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1385.png",
            "scheme": "https"
        }
    },
    "1144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1144",
                "description": "description",
                "dna": "1272e85a53ae5e021db1a26dc6da59c7b458625f",
                "date": 1683430984515,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1144",
                "rarity_score": 283.42915756427055,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1144.png",
            "scheme": "https"
        }
    },
    "2589": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2589",
                "description": "description",
                "dna": "9970dcbc4ddaa342d76097081ca56bfc9ec70c41",
                "date": 1683438091592,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2589",
                "rarity_score": 246.31753425983888,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2589.png",
            "scheme": "https"
        }
    },
    "601": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #601",
                "description": "description",
                "dna": "491d7621d8565367a1148a9540be01cc8934e07f",
                "date": 1683447210952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "601",
                "rarity_score": 179.81090357652243,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/601.png",
            "scheme": "https"
        }
    },
    "1344": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1344",
                "description": "description",
                "dna": "0f4f9501bdb8c1efa47f2f5a1407943cc8215d1e",
                "date": 1683433628369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1344",
                "rarity_score": 199.90205594308122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1344.png",
            "scheme": "https"
        }
    },
    "285": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #285",
                "description": "description",
                "dna": "71bfcbabec96c65cdd5961da054e75a381320039",
                "date": 1683438130308,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "285",
                "rarity_score": 175.89141344706968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/285.png",
            "scheme": "https"
        }
    },
    "2827": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2827",
                "description": "description",
                "dna": "c6ba39295f657618670a245a596fbcf5d8742b44",
                "date": 1683435894760,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2827",
                "rarity_score": 200.3403356165325,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2827.png",
            "scheme": "https"
        }
    },
    "1289": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1289",
                "description": "description",
                "dna": "ff91bd3d407969cf5c308d6b63da03b5b4a7cbc9",
                "date": 1683435434888,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1289",
                "rarity_score": 179.77430241741254,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1289.png",
            "scheme": "https"
        }
    },
    "2641": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2641",
                "description": "description",
                "dna": "858907111cb1161fca82368a1fff2972b0b1633d",
                "date": 1683774533904,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2641",
                "rarity_score": 194.30342314446736,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2641.png",
            "scheme": "https"
        }
    },
    "1333": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1333",
                "description": "description",
                "dna": "87d1f2ee7d36fb5f735bee0dac4fe9778dc30672",
                "date": 1683447358348,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1333",
                "rarity_score": 195.76781257909911,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1333.png",
            "scheme": "https"
        }
    },
    "1309": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1309",
                "description": "description",
                "dna": "b870ddf2f9ca23ccee07b6c5d7e38cbe123d7a4c",
                "date": 1683432884878,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1309",
                "rarity_score": 183.80300266580144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1309.png",
            "scheme": "https"
        }
    },
    "2457": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2457",
                "description": "description",
                "dna": "ce31d1f31eb1a88f97b3a07ca4c9a2a3077994ab",
                "date": 1683439605278,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2457",
                "rarity_score": 194.30188619247483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2457.png",
            "scheme": "https"
        }
    },
    "2170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2170",
                "description": "description",
                "dna": "0ead5a4d5da907fdc3c7d96dae2d2ae6eda58b8f",
                "date": 1683437030556,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2170",
                "rarity_score": 312.1844478214778,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2170.png",
            "scheme": "https"
        }
    },
    "2991": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2991",
                "description": "description",
                "dna": "45c505b6c5b69161a9fb9746ba72a7c74993a7c9",
                "date": 1683437593612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2991",
                "rarity_score": 277.85263277852107,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2991.png",
            "scheme": "https"
        }
    },
    "589": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #589",
                "description": "description",
                "dna": "e8363cc9c5b6ec3b7578f82026e1985d75b432d7",
                "date": 1683435675079,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "589",
                "rarity_score": 186.62291274537148,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/589.png",
            "scheme": "https"
        }
    },
    "2048": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2048",
                "description": "description",
                "dna": "53894584719e31fea37d64609fa33e5859a48ff5",
                "date": 1683430066302,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2048",
                "rarity_score": 191.96931440828854,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2048.png",
            "scheme": "https"
        }
    },
    "3692": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3692",
                "description": "description",
                "dna": "b6a1ef85fa92a658e93ca4a9053612bfccb1db93",
                "date": 1683436529105,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3692",
                "rarity_score": 369.443276942679,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3692.png",
            "scheme": "https"
        }
    },
    "2785": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2785",
                "description": "description",
                "dna": "19a964cbfc91afa4ca38cb3f6e655f96c5dd4d18",
                "date": 1683443722333,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2785",
                "rarity_score": 194.5348234297513,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2785.png",
            "scheme": "https"
        }
    },
    "3866": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3866",
                "description": "description",
                "dna": "7e1e81cd4cf704433c03a3a112b73cf77e97f022",
                "date": 1683444117335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3866",
                "rarity_score": 249.77351855584746,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3866.png",
            "scheme": "https"
        }
    },
    "3317": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3317",
                "description": "description",
                "dna": "88cec9121dc18b8010a17a8568ca48da9ccc665b",
                "date": 1683441763763,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3317",
                "rarity_score": 169.09956422020701,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3317.png",
            "scheme": "https"
        }
    },
    "1167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1167",
                "description": "description",
                "dna": "73b674d7772620c658160729f74cec5b723dd527",
                "date": 1683446586548,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1167",
                "rarity_score": 285.63704568662854,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1167.png",
            "scheme": "https"
        }
    },
    "1286": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1286",
                "description": "description",
                "dna": "01190163314ef7d9b91163a8937272e4190a5e69",
                "date": 1683445302258,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1286",
                "rarity_score": 246.7535092243931,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1286.png",
            "scheme": "https"
        }
    },
    "972": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #972",
                "description": "description",
                "dna": "e5c4c576a04db04c2a6fa0e584c1458dc0255380",
                "date": 1683771500310,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "972",
                "rarity_score": 226.71479059964744,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/972.png",
            "scheme": "https"
        }
    },
    "1545": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1545",
                "description": "description",
                "dna": "9ee44035a38bb537f9ec300531a424ce50dbc5ef",
                "date": 1683440202031,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1545",
                "rarity_score": 295.04801448827374,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1545.png",
            "scheme": "https"
        }
    },
    "1316": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1316",
                "description": "description",
                "dna": "09aac17ad2fd2c9efea1da11db45b3920204f5f3",
                "date": 1683431509542,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1316",
                "rarity_score": 206.33412970932466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1316.png",
            "scheme": "https"
        }
    },
    "4096": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4096",
                "description": "description",
                "dna": "03a1c0d91ca16a74554254359a443a303bc314f5",
                "date": 1683774870515,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "4096",
                "rarity_score": 172.56512775760783,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4096.png",
            "scheme": "https"
        }
    },
    "2971": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2971",
                "description": "description",
                "dna": "4c58251e43b34d0dd563c26370956edfd5a2874b",
                "date": 1683433761853,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2971",
                "rarity_score": 196.42524843545374,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2971.png",
            "scheme": "https"
        }
    },
    "1122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1122",
                "description": "description",
                "dna": "fee1c3eaaaa3127aa57e284f7c8148257b81e20c",
                "date": 1683436272459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1122",
                "rarity_score": 347.29594796270374,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1122.png",
            "scheme": "https"
        }
    },
    "1075": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1075",
                "description": "description",
                "dna": "cbc46c0416ba79ca36bb371e782a090cc2867208",
                "date": 1683444330131,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1075",
                "rarity_score": 205.79390879619461,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1075.png",
            "scheme": "https"
        }
    },
    "778": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #778",
                "description": "description",
                "dna": "65a397ac1a8d2025aa040047bcbd87395d29c9f8",
                "date": 1683447286527,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "778",
                "rarity_score": 213.53830530596545,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/778.png",
            "scheme": "https"
        }
    },
    "1685": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1685",
                "description": "description",
                "dna": "5edc6fb41040c1e9796b1d4f378a24b22548dc04",
                "date": 1683439525035,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1685",
                "rarity_score": 204.66918491407546,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1685.png",
            "scheme": "https"
        }
    },
    "2114": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2114",
                "description": "description",
                "dna": "eb0d50514c3c7570147db9d950ece01410a6582d",
                "date": 1683445888624,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2114",
                "rarity_score": 232.9542626869139,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2114.png",
            "scheme": "https"
        }
    },
    "3395": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3395",
                "description": "description",
                "dna": "aa5852fbe8c63ef4dd9b89bcc8d6ca27ae569512",
                "date": 1683775048539,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3395",
                "rarity_score": 156.78980073952525,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3395.png",
            "scheme": "https"
        }
    },
    "2436": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2436",
                "description": "description",
                "dna": "bc3e3d787be0a9d270a34cd8d7513f4b69ef5ff8",
                "date": 1683444405623,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2436",
                "rarity_score": 198.26499301603644,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2436.png",
            "scheme": "https"
        }
    },
    "3769": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3769",
                "description": "description",
                "dna": "ce530add00c6fa90f57aab5db5f9a91560292541",
                "date": 1683444003585,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3769",
                "rarity_score": 152.97656816946053,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3769.png",
            "scheme": "https"
        }
    },
    "1112": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1112",
                "description": "description",
                "dna": "f7f5f8d31963a1b08939ef92c0a087d446bff0e5",
                "date": 1683446998244,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1112",
                "rarity_score": 162.75217274460266,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1112.png",
            "scheme": "https"
        }
    },
    "1912": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1912",
                "description": "description",
                "dna": "fadd625742bdb319c5a648d6fc18cab6237cb6f1",
                "date": 1683435859481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1912",
                "rarity_score": 206.2222622506187,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1912.png",
            "scheme": "https"
        }
    },
    "3199": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3199",
                "description": "description",
                "dna": "817110601bc555d316176a415d52826fab576488",
                "date": 1683436597659,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3199",
                "rarity_score": 224.1921601514607,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3199.png",
            "scheme": "https"
        }
    },
    "2674": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2674",
                "description": "description",
                "dna": "4f393f559f56a78db17cdeafb1a9ad6d1512ff92",
                "date": 1683440699562,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2674",
                "rarity_score": 214.8843855933468,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2674.png",
            "scheme": "https"
        }
    },
    "1119": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1119",
                "description": "description",
                "dna": "0524127f5018d6f1fbe8a169a65565075a9b7c8e",
                "date": 1683441025594,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1119",
                "rarity_score": 194.5617054797815,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1119.png",
            "scheme": "https"
        }
    },
    "1687": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1687",
                "description": "description",
                "dna": "1351377cd1932365d9b3fd2cad42f2e21980b161",
                "date": 1683441382085,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1687",
                "rarity_score": 225.48721795131308,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1687.png",
            "scheme": "https"
        }
    },
    "3734": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3734",
                "description": "description",
                "dna": "b2794b3c8f9c46111afc61e3866b1427e7372b7f",
                "date": 1683443829706,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3734",
                "rarity_score": 187.70421129571506,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3734.png",
            "scheme": "https"
        }
    },
    "22": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #22",
                "description": "description",
                "dna": "e480bcda623f99371f3a29a7ad5f01e00614cf6c",
                "date": 1683437026553,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "22",
                "rarity_score": 179.98429587871027,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/22.png",
            "scheme": "https"
        }
    },
    "3232": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3232",
                "description": "description",
                "dna": "8de48e87b3ae368fbfcee8b2aa80b0936c5e415c",
                "date": 1683430370632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3232",
                "rarity_score": 156.12952328986822,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3232.png",
            "scheme": "https"
        }
    },
    "2406": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2406",
                "description": "description",
                "dna": "a345b5c8d56fb9a4f3dc06e1b8eb4a965eef130f",
                "date": 1683431560576,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2406",
                "rarity_score": 189.32878434484292,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2406.png",
            "scheme": "https"
        }
    },
    "4090": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4090",
                "description": "description",
                "dna": "733af24b6dbd7187a9108c3305b6fb4cbbc45a63",
                "date": 1683438981369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "4090",
                "rarity_score": 347.02418602561585,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4090.png",
            "scheme": "https"
        }
    },
    "2314": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2314",
                "description": "description",
                "dna": "80c4e5a1abc5f49eae58341665c713a0e5c8a90f",
                "date": 1683432203832,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2314",
                "rarity_score": 165.8499354489978,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2314.png",
            "scheme": "https"
        }
    },
    "768": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #768",
                "description": "description",
                "dna": "1162f359e8b5452e80d38a931b3e484129bac25c",
                "date": 1683776590779,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "768",
                "rarity_score": 231.19873763584533,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/768.png",
            "scheme": "https"
        }
    },
    "564": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #564",
                "description": "description",
                "dna": "2e01f573bd32f93cba37b8a659b99eaa70cc0323",
                "date": 1683446265872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "564",
                "rarity_score": 239.26113997293723,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/564.png",
            "scheme": "https"
        }
    },
    "3706": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3706",
                "description": "description",
                "dna": "4e25906de18d94d4a478db0a06313a7b80ba672b",
                "date": 1683446332285,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3706",
                "rarity_score": 199.42263922540985,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3706.png",
            "scheme": "https"
        }
    },
    "4050": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4050",
                "description": "description",
                "dna": "f604798dc479235610941182dd31264955a1c409",
                "date": 1683775091418,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4050",
                "rarity_score": 250.08961676200963,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4050.png",
            "scheme": "https"
        }
    },
    "496": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #496",
                "description": "description",
                "dna": "de2f1934e4b54478540e90348787d9b02f1f3d20",
                "date": 1683431439990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "496",
                "rarity_score": 194.85263530831787,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/496.png",
            "scheme": "https"
        }
    },
    "859": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #859",
                "description": "description",
                "dna": "ab5e6273ec1f8a26db6d6315b8b4486393f88ffb",
                "date": 1683442974690,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "859",
                "rarity_score": 226.18946198551458,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/859.png",
            "scheme": "https"
        }
    },
    "3349": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3349",
                "description": "description",
                "dna": "d825a263e5f375ae478b30b9d6ee6b12abd8dd82",
                "date": 1683446257932,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3349",
                "rarity_score": 147.20582607457555,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3349.png",
            "scheme": "https"
        }
    },
    "346": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #346",
                "description": "description",
                "dna": "244d74d43b0d2aa14065f41a24f18bc6ecba131b",
                "date": 1683439851562,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "346",
                "rarity_score": 210.39747296266486,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/346.png",
            "scheme": "https"
        }
    },
    "3545": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3545",
                "description": "description",
                "dna": "6fdbbb8f501db8f4f3c4ffb58a6d589bd36caf0d",
                "date": 1683432785649,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3545",
                "rarity_score": 203.67613618853673,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3545.png",
            "scheme": "https"
        }
    },
    "776": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #776",
                "description": "description",
                "dna": "c6e0fbfdcbff26d6c7508492f92b42c2e975fa58",
                "date": 1683439855636,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "776",
                "rarity_score": 190.3049955087458,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/776.png",
            "scheme": "https"
        }
    },
    "475": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #475",
                "description": "description",
                "dna": "1210c90fd2d3a00991077f630bf89aa1d964321d",
                "date": 1683444819752,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "475",
                "rarity_score": 163.94653412924708,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/475.png",
            "scheme": "https"
        }
    },
    "984": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #984",
                "description": "description",
                "dna": "525bef047d3ed187ef309bd81eefffdfc23e9f6d",
                "date": 1683437642288,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "984",
                "rarity_score": 181.746754691785,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/984.png",
            "scheme": "https"
        }
    },
    "3871": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3871",
                "description": "description",
                "dna": "a692119418be2b0c217f18888a4f0292f86b74bb",
                "date": 1683435569602,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3871",
                "rarity_score": 157.7324259330829,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3871.png",
            "scheme": "https"
        }
    },
    "3584": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3584",
                "description": "description",
                "dna": "4305e799a6f31da81c531e291c658f7ad1c87818",
                "date": 1683436998596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3584",
                "rarity_score": 232.16131011391718,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3584.png",
            "scheme": "https"
        }
    },
    "3297": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3297",
                "description": "description",
                "dna": "189a41b5fbb6f5ddb0cd73c81d2d6da4cc2c8ec5",
                "date": 1683436588613,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3297",
                "rarity_score": 269.22179698962015,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3297.png",
            "scheme": "https"
        }
    },
    "980": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #980",
                "description": "description",
                "dna": "43e0719620b817711741d69cbc709f776cc95445",
                "date": 1683437556330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "980",
                "rarity_score": 146.15893501628304,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/980.png",
            "scheme": "https"
        }
    },
    "765": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #765",
                "description": "description",
                "dna": "b4f48c388dee245a4b8e9a8ec92982cf1924ec1b",
                "date": 1683442735359,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "765",
                "rarity_score": 259.57500411012,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/765.png",
            "scheme": "https"
        }
    },
    "686": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #686",
                "description": "description",
                "dna": "cfb4a8e7932fc3c9bf08e3d12afa2a53b8eb6a47",
                "date": 1683771404398,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "686",
                "rarity_score": 282.28317582658127,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/686.png",
            "scheme": "https"
        }
    },
    "2130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2130",
                "description": "description",
                "dna": "3b4355e7e7efadbc09a76f50be46ac583b8c8c60",
                "date": 1683430820821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2130",
                "rarity_score": 207.11627918948793,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2130.png",
            "scheme": "https"
        }
    },
    "3591": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3591",
                "description": "description",
                "dna": "fdb65ea1486a045f440bae65172c8c32a1dffe90",
                "date": 1683508656943,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3591",
                "rarity_score": 390.94352280930536,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3591.png",
            "scheme": "https"
        }
    },
    "1996": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1996",
                "description": "description",
                "dna": "6d5aeb91a997cc89efa5ff51236b7d545a818afb",
                "date": 1683440472294,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1996",
                "rarity_score": 146.25455925637016,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1996.png",
            "scheme": "https"
        }
    },
    "1593": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1593",
                "description": "description",
                "dna": "b48e997178a4ce991dd37e5091bd72b00a89cdbc",
                "date": 1683445736814,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1593",
                "rarity_score": 236.43938954622527,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1593.png",
            "scheme": "https"
        }
    },
    "3992": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3992",
                "description": "description",
                "dna": "37186261630e9d8f3c5af30f810697e2d4c4734e",
                "date": 1683771158630,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3992",
                "rarity_score": 228.0920322457564,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3992.png",
            "scheme": "https"
        }
    },
    "1394": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1394",
                "description": "description",
                "dna": "cf79955cfc4bf74963b508a23f0f5baa9d8c6455",
                "date": 1683776715944,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1394",
                "rarity_score": 243.14720462817306,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1394.png",
            "scheme": "https"
        }
    },
    "4068": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4068",
                "description": "description",
                "dna": "5c4256754eb13fd9c321c5853d02b5df72ed3eda",
                "date": 1683776477227,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "4068",
                "rarity_score": 192.19279953570737,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4068.png",
            "scheme": "https"
        }
    },
    "3954": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3954",
                "description": "description",
                "dna": "b49b4be04f0206b31e3ef116a9b316857807fb61",
                "date": 1683444384428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3954",
                "rarity_score": 171.62793770486064,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3954.png",
            "scheme": "https"
        }
    },
    "306": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #306",
                "description": "description",
                "dna": "b680a2f2a760d6a646403d7ce67e7d7e1f31fb68",
                "date": 1683437198954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "306",
                "rarity_score": 183.62619339443745,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/306.png",
            "scheme": "https"
        }
    },
    "3082": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3082",
                "description": "description",
                "dna": "ed4d0db005c914ce676810ba0a036eadec9d759c",
                "date": 1683444055535,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3082",
                "rarity_score": 163.89323327108994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3082.png",
            "scheme": "https"
        }
    },
    "3491": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3491",
                "description": "description",
                "dna": "98a8305f72156add0f7edaf00afde45d639da62d",
                "date": 1683433780183,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3491",
                "rarity_score": 194.88443281336376,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3491.png",
            "scheme": "https"
        }
    },
    "935": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #935",
                "description": "description",
                "dna": "d27f571792c30879c0b5599c79b43907ab75b9d2",
                "date": 1683774838753,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "935",
                "rarity_score": 165.7341646671453,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/935.png",
            "scheme": "https"
        }
    },
    "8": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #8",
                "description": "description",
                "dna": "f6cb1a317509b3276d998d7d9691cad9a7e360fd",
                "date": 1683447027108,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "8",
                "rarity_score": 172.20027434484192,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/8.png",
            "scheme": "https"
        }
    },
    "599": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #599",
                "description": "description",
                "dna": "ef80f359847013a95ee357ef3668912aeb911fdc",
                "date": 1683438468632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "599",
                "rarity_score": 231.28043434051324,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/599.png",
            "scheme": "https"
        }
    },
    "1865": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1865",
                "description": "description",
                "dna": "7d4a5879efd632d8fdad132cad05aa7b429fb096",
                "date": 1683446595527,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1865",
                "rarity_score": 192.9414630070322,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1865.png",
            "scheme": "https"
        }
    },
    "2553": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2553",
                "description": "description",
                "dna": "ca55817c4cb14dc812b3feabd55a92f2ddf671c0",
                "date": 1683446147922,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2553",
                "rarity_score": 150.03636646135152,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2553.png",
            "scheme": "https"
        }
    },
    "3084": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3084",
                "description": "description",
                "dna": "6cba7b633673ba950d6dc716a82f6ad15359e365",
                "date": 1683447082303,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3084",
                "rarity_score": 206.57979332411125,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3084.png",
            "scheme": "https"
        }
    },
    "1137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1137",
                "description": "description",
                "dna": "e752dd7c8c9d4a1c82223985e452f7a1fc499e7b",
                "date": 1683436756253,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1137",
                "rarity_score": 418.1959735397046,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1137.png",
            "scheme": "https"
        }
    },
    "1234": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1234",
                "description": "description",
                "dna": "10d4893e893f64480f274a54041f8bf57830e773",
                "date": 1683442683978,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1234",
                "rarity_score": 190.46057153551376,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1234.png",
            "scheme": "https"
        }
    },
    "2334": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2334",
                "description": "description",
                "dna": "5b1bb757881a15ee128cd0ad634069ced918586b",
                "date": 1683446093732,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2334",
                "rarity_score": 134.31717225773866,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2334.png",
            "scheme": "https"
        }
    },
    "3218": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3218",
                "description": "description",
                "dna": "4e896d9e601cf9e45f99b797829a51d7d0e1eb55",
                "date": 1683441288578,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3218",
                "rarity_score": 231.22160669695614,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3218.png",
            "scheme": "https"
        }
    },
    "241": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #241",
                "description": "description",
                "dna": "012dc702815d9fb6487f8536f627f15640371ea4",
                "date": 1683445555988,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "241",
                "rarity_score": 229.5688967813137,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/241.png",
            "scheme": "https"
        }
    },
    "2454": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2454",
                "description": "description",
                "dna": "38a6e2d04e2907c256789cc035d31ea2f32567cf",
                "date": 1683447163160,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2454",
                "rarity_score": 177.9681545270853,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2454.png",
            "scheme": "https"
        }
    },
    "433": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #433",
                "description": "description",
                "dna": "03a87d720d73567da52803e9412fe3b45f7474d8",
                "date": 1683445439254,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "433",
                "rarity_score": 296.71996782055595,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/433.png",
            "scheme": "https"
        }
    },
    "2567": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2567",
                "description": "description",
                "dna": "703db990a538db92df2fa52bff5e43abc1c8c99d",
                "date": 1683430865160,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2567",
                "rarity_score": 142.5578296284202,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2567.png",
            "scheme": "https"
        }
    },
    "2148": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2148",
                "description": "description",
                "dna": "a239f32b49eb02d9f95521dc0c9c4b8aeee25015",
                "date": 1683435841243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2148",
                "rarity_score": 279.99031415039866,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2148.png",
            "scheme": "https"
        }
    },
    "3680": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3680",
                "description": "description",
                "dna": "7c995a4db32490a754c5bbceb6738a6836aa5f17",
                "date": 1683440786680,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3680",
                "rarity_score": 319.3029272168574,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3680.png",
            "scheme": "https"
        }
    },
    "1536": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1536",
                "description": "description",
                "dna": "8cbbc126dd89879e53b265beb8a8de5d394f29eb",
                "date": 1683444502047,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1536",
                "rarity_score": 205.8279564651612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1536.png",
            "scheme": "https"
        }
    },
    "2410": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2410",
                "description": "description",
                "dna": "abd9074bc49ee78231aca189a47ccb032252b1d8",
                "date": 1683446335928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2410",
                "rarity_score": 155.76674541937348,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2410.png",
            "scheme": "https"
        }
    },
    "2732": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2732",
                "description": "description",
                "dna": "411feec560a5c32f26d31361ed4fde037cf511e0",
                "date": 1683441377909,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2732",
                "rarity_score": 264.7233859718349,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2732.png",
            "scheme": "https"
        }
    },
    "1351": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1351",
                "description": "description",
                "dna": "425a5d627d53623e1f41f8a79db6f08b46015e96",
                "date": 1683439600616,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1351",
                "rarity_score": 168.79317635759813,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1351.png",
            "scheme": "https"
        }
    },
    "1813": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1813",
                "description": "description",
                "dna": "9c0202ecd6aa2e573d63f8f4925a13573d95e243",
                "date": 1683436738215,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1813",
                "rarity_score": 228.39933298590327,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1813.png",
            "scheme": "https"
        }
    },
    "1919": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1919",
                "description": "description",
                "dna": "51006ae63c3af2baf885a8964825334e769d65bf",
                "date": 1683435904586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1919",
                "rarity_score": 189.59749207082493,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1919.png",
            "scheme": "https"
        }
    },
    "558": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #558",
                "description": "description",
                "dna": "13db6b25c87b1cd07e8487377be59470d22b9b48",
                "date": 1683435033245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "558",
                "rarity_score": 229.8408012113101,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/558.png",
            "scheme": "https"
        }
    },
    "2886": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2886",
                "description": "description",
                "dna": "3edd42bd09fd4477d642be5fb2463e48db51fc0f",
                "date": 1683443380364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2886",
                "rarity_score": 176.57608789810607,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2886.png",
            "scheme": "https"
        }
    },
    "3892": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3892",
                "description": "description",
                "dna": "b10a043ae811e51f857098a94ddb7963c5e96609",
                "date": 1683432902250,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3892",
                "rarity_score": 184.71415311690112,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3892.png",
            "scheme": "https"
        }
    },
    "1823": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1823",
                "description": "description",
                "dna": "95b0e129e12850656f0ec3b647d3be6f1c4a9a31",
                "date": 1683432998841,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1823",
                "rarity_score": 196.26139557019238,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1823.png",
            "scheme": "https"
        }
    },
    "246": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #246",
                "description": "description",
                "dna": "e747877560d2f6196be2128ebf93cb114451a483",
                "date": 1683434584176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "246",
                "rarity_score": 273.203198213099,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/246.png",
            "scheme": "https"
        }
    },
    "1875": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1875",
                "description": "description",
                "dna": "47d285ac301e42dd94637c76cc5e19081fadd274",
                "date": 1683431812779,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1875",
                "rarity_score": 205.8146554849042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1875.png",
            "scheme": "https"
        }
    },
    "3330": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3330",
                "description": "description",
                "dna": "26d1c7c92f60c8869c962ffdb81a517bd52300a2",
                "date": 1683438839609,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3330",
                "rarity_score": 157.79545100944296,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3330.png",
            "scheme": "https"
        }
    },
    "545": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #545",
                "description": "description",
                "dna": "e66c51773a2e7cc44689d9102ea0a059c5793ea2",
                "date": 1683440554917,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "545",
                "rarity_score": 208.7768449189021,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/545.png",
            "scheme": "https"
        }
    },
    "2330": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2330",
                "description": "description",
                "dna": "23ab31f7e385ba49054c67e9d014943506af906f",
                "date": 1683431670047,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2330",
                "rarity_score": 165.01534068039012,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2330.png",
            "scheme": "https"
        }
    },
    "338": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #338",
                "description": "description",
                "dna": "870b6ee19f8e73bb299d4d483aeebdbe85bca206",
                "date": 1683776629723,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "338",
                "rarity_score": 212.84198018787453,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/338.png",
            "scheme": "https"
        }
    },
    "3830": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3830",
                "description": "description",
                "dna": "fedbc6e579379e1e3c7c9ba4e0e491345e997959",
                "date": 1683440904247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3830",
                "rarity_score": 161.1145496544906,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3830.png",
            "scheme": "https"
        }
    },
    "1497": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1497",
                "description": "description",
                "dna": "345ec295dca398e026d67ce6718a46ae5c76752b",
                "date": 1683435788006,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1497",
                "rarity_score": 190.39968461002647,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1497.png",
            "scheme": "https"
        }
    },
    "2878": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2878",
                "description": "description",
                "dna": "7918f102fdda615cbc88d601883c8da155163dcf",
                "date": 1683429742612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2878",
                "rarity_score": 207.85550752945858,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2878.png",
            "scheme": "https"
        }
    },
    "2219": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2219",
                "description": "description",
                "dna": "19527c97623c70e422bdc07efad7f491cdca78e4",
                "date": 1683434198932,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2219",
                "rarity_score": 202.0926853050328,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2219.png",
            "scheme": "https"
        }
    },
    "576": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #576",
                "description": "description",
                "dna": "4360448cd02f1d86a91f3b09098285b821eb3e57",
                "date": 1683445053081,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "576",
                "rarity_score": 175.1464850224657,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/576.png",
            "scheme": "https"
        }
    },
    "3629": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3629",
                "description": "description",
                "dna": "4ad74d8fbc07acb2e29eb427048a347fcea263e3",
                "date": 1683441457745,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3629",
                "rarity_score": 178.26094708101203,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3629.png",
            "scheme": "https"
        }
    },
    "3428": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3428",
                "description": "description",
                "dna": "86e9a46d7dee16094630ffc4ba9fe02ed84442ec",
                "date": 1683446985604,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3428",
                "rarity_score": 174.14874362866297,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3428.png",
            "scheme": "https"
        }
    },
    "569": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #569",
                "description": "description",
                "dna": "3bb5a133b0efad09f2a21ffce0d9722727a0cbb7",
                "date": 1683431486110,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "569",
                "rarity_score": 304.5134483846952,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/569.png",
            "scheme": "https"
        }
    },
    "2889": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2889",
                "description": "description",
                "dna": "9ee7dc5d9afdfb864265e7dc3c48ff2d05631478",
                "date": 1683437568718,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2889",
                "rarity_score": 175.63113092142902,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2889.png",
            "scheme": "https"
        }
    },
    "40": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #40",
                "description": "description",
                "dna": "fff7428142092b4454e67b293f7118a8cf0a0f7c",
                "date": 1683684128042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Gem Eyes",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "40",
                "rarity_score": 4291.016696968891,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/40.png",
            "scheme": "https"
        }
    },
    "3466": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3466",
                "description": "description",
                "dna": "9380f69ddd9575d2de72c3a6e8baa3c52b351a11",
                "date": 1683436190914,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3466",
                "rarity_score": 275.29621955188634,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3466.png",
            "scheme": "https"
        }
    },
    "2669": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2669",
                "description": "description",
                "dna": "c65c9db878c402be2fc0e201481a3aaee8d47839",
                "date": 1683442300513,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2669",
                "rarity_score": 169.54835600875933,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2669.png",
            "scheme": "https"
        }
    },
    "2000": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2000",
                "description": "description",
                "dna": "b88300c6baaf5c48b0c8b06080a4804dad3ea5a3",
                "date": 1683517630459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "CoD",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2000",
                "rarity_score": 4336.055021326242,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2000.png",
            "scheme": "https"
        }
    },
    "3209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3209",
                "description": "description",
                "dna": "b66c30baf135391d5b4313aeaa4d34470a4b6187",
                "date": 1683435457615,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3209",
                "rarity_score": 281.7473225232705,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3209.png",
            "scheme": "https"
        }
    },
    "2032": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2032",
                "description": "description",
                "dna": "bad94bcdf91d443eda0ad0b46b15b727dd6d0382",
                "date": 1683771179396,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2032",
                "rarity_score": 199.96665227850247,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2032.png",
            "scheme": "https"
        }
    },
    "628": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #628",
                "description": "description",
                "dna": "54d3591d5e34547c0764855a0a1eb321d3c21f91",
                "date": 1683444158663,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "628",
                "rarity_score": 248.7412006113768,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/628.png",
            "scheme": "https"
        }
    },
    "2085": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2085",
                "description": "description",
                "dna": "2ab36dc2c72ea9d8db2c232c2619708cf1f51bf0",
                "date": 1683775057301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2085",
                "rarity_score": 230.55790185750385,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2085.png",
            "scheme": "https"
        }
    },
    "3453": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3453",
                "description": "description",
                "dna": "e25a1c15a60115cb51a348cf89cc2c27dfc57dae",
                "date": 1683437059083,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3453",
                "rarity_score": 158.01986466944263,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3453.png",
            "scheme": "https"
        }
    },
    "108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #108",
                "description": "description",
                "dna": "88018d2b4c7289cbca768309d182bf12a8aa9cc5",
                "date": 1683439076628,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "108",
                "rarity_score": 176.5507185522609,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/108.png",
            "scheme": "https"
        }
    },
    "1900": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1900",
                "description": "description",
                "dna": "f735a5d5c67a1a36e52d8bd5f8d49f37304a561b",
                "date": 1683430278858,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1900",
                "rarity_score": 187.16049783655913,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1900.png",
            "scheme": "https"
        }
    },
    "1945": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1945",
                "description": "description",
                "dna": "6a43522bbf531320665e9510cfc567094b83e3d4",
                "date": 1683442314087,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1945",
                "rarity_score": 158.35036098195656,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1945.png",
            "scheme": "https"
        }
    },
    "1001": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1001",
                "description": "description",
                "dna": "504ba7f6d52f45ea567ede9a4f0bb494796edd52",
                "date": 1683442005881,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1001",
                "rarity_score": 189.47633123499514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1001.png",
            "scheme": "https"
        }
    },
    "858": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #858",
                "description": "description",
                "dna": "1f99146cba5cf0f762ff500c4c2bcab24e3b913b",
                "date": 1683429538225,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "858",
                "rarity_score": 165.54327224073262,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/858.png",
            "scheme": "https"
        }
    },
    "2914": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2914",
                "description": "description",
                "dna": "42d8b6b95879e4d01deb54c61367165a4d02a165",
                "date": 1683440954503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2914",
                "rarity_score": 201.3701450385111,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2914.png",
            "scheme": "https"
        }
    },
    "1830": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1830",
                "description": "description",
                "dna": "f922664444f9afca0430b9727e5501df4dc2af4e",
                "date": 1683439534013,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1830",
                "rarity_score": 147.86981949222658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1830.png",
            "scheme": "https"
        }
    },
    "1131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1131",
                "description": "description",
                "dna": "1cf1a2602145e641a022c6eb9c915ea174d73323",
                "date": 1683437914905,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1131",
                "rarity_score": 211.8267350896913,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1131.png",
            "scheme": "https"
        }
    },
    "305": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #305",
                "description": "description",
                "dna": "1373cdaa282f8c4b9dca1dfaa47319e37c3ee682",
                "date": 1683444220928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "305",
                "rarity_score": 194.8514606756234,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/305.png",
            "scheme": "https"
        }
    },
    "451": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #451",
                "description": "description",
                "dna": "cb14c0c0e633f8eb7d62ff213ca168132dbd9af6",
                "date": 1683435452704,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "451",
                "rarity_score": 281.01782599025233,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/451.png",
            "scheme": "https"
        }
    },
    "48": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #48",
                "description": "description",
                "dna": "a3e011e7866980929673287a78fd75a830df1d59",
                "date": 1683446943213,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "48",
                "rarity_score": 251.1814514987962,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/48.png",
            "scheme": "https"
        }
    },
    "2468": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2468",
                "description": "description",
                "dna": "13190a5f44b80c054ee0b5e199a4493721cd8da9",
                "date": 1683429852611,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2468",
                "rarity_score": 183.80044608563833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2468.png",
            "scheme": "https"
        }
    },
    "2104": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2104",
                "description": "description",
                "dna": "a54ad86183915b64851b14fba2448893b0c30457",
                "date": 1683441475522,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2104",
                "rarity_score": 193.23343232928042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2104.png",
            "scheme": "https"
        }
    },
    "1277": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1277",
                "description": "description",
                "dna": "895d86485429c7035dc06d34556cdc3ced88f32a",
                "date": 1683441440059,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1277",
                "rarity_score": 221.84471288565936,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1277.png",
            "scheme": "https"
        }
    },
    "4080": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4080",
                "description": "description",
                "dna": "5ac5cc256df4b48ed97eac59861115fd81c5c700",
                "date": 1683441754422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "4080",
                "rarity_score": 255.60067903613196,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4080.png",
            "scheme": "https"
        }
    },
    "3520": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3520",
                "description": "description",
                "dna": "03dcc062ac819fa42d23b6f69de9ff6019de1700",
                "date": 1683776451950,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3520",
                "rarity_score": 217.09692346562423,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3520.png",
            "scheme": "https"
        }
    },
    "461": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #461",
                "description": "description",
                "dna": "81336bca2e30d93285c7ba778048fa157c58e11e",
                "date": 1683429830668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "461",
                "rarity_score": 198.62994331054693,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/461.png",
            "scheme": "https"
        }
    },
    "1285": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1285",
                "description": "description",
                "dna": "cac925aa33e30a666e14fa75f81f84c3f7a29bd7",
                "date": 1683445366557,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1285",
                "rarity_score": 183.52669345253545,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1285.png",
            "scheme": "https"
        }
    },
    "2980": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2980",
                "description": "description",
                "dna": "e6f35360f6e927f589495b30c94d581e2aece075",
                "date": 1683776612069,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2980",
                "rarity_score": 216.89688616963304,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2980.png",
            "scheme": "https"
        }
    },
    "1976": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1976",
                "description": "description",
                "dna": "c7365297d2790f40980a6f73f58d29bb798ca04b",
                "date": 1683440868381,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1976",
                "rarity_score": 209.93045527573184,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1976.png",
            "scheme": "https"
        }
    },
    "3516": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3516",
                "description": "description",
                "dna": "d5934cb13562aaebc9faf8cc0cb648536d1f5c0e",
                "date": 1683432603396,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3516",
                "rarity_score": 188.57670640237342,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3516.png",
            "scheme": "https"
        }
    },
    "1736": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1736",
                "description": "description",
                "dna": "efbfbdc609485801e46117bc48b45c25ea120330",
                "date": 1683446474696,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1736",
                "rarity_score": 165.47614033926794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1736.png",
            "scheme": "https"
        }
    },
    "1787": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1787",
                "description": "description",
                "dna": "3080c63d6341d6dcfb97c7eb15e478866a1a8280",
                "date": 1683430375439,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1787",
                "rarity_score": 174.3479981570032,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1787.png",
            "scheme": "https"
        }
    },
    "3412": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3412",
                "description": "description",
                "dna": "679c028f79f6f1fe8a5121589a154a05c810ae33",
                "date": 1683435723408,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3412",
                "rarity_score": 173.49297932364158,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3412.png",
            "scheme": "https"
        }
    },
    "1292": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1292",
                "description": "description",
                "dna": "2e7030c7e75965aef219e9895b25ea4191a0254f",
                "date": 1683431871326,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1292",
                "rarity_score": 206.75350924611465,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1292.png",
            "scheme": "https"
        }
    },
    "2751": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2751",
                "description": "description",
                "dna": "4400584ac3b70a2fb1b436302cc24809a5ed6484",
                "date": 1683444546456,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2751",
                "rarity_score": 213.97606035205072,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2751.png",
            "scheme": "https"
        }
    },
    "4074": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4074",
                "description": "description",
                "dna": "12b6985dbde268a9c55e4e25459c334e304f558f",
                "date": 1683433434483,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "4074",
                "rarity_score": 205.92078429997105,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4074.png",
            "scheme": "https"
        }
    },
    "3649": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3649",
                "description": "description",
                "dna": "d522733a28197e2eff9fa3eab18a1ef47e91ba2b",
                "date": 1683434557090,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3649",
                "rarity_score": 215.09485778121228,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3649.png",
            "scheme": "https"
        }
    },
    "3000": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3000",
                "description": "description",
                "dna": "b2ed4b8d5f2ba9c15773272415dc98e54010f407",
                "date": 1683771162720,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3000",
                "rarity_score": 169.62891813980212,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3000.png",
            "scheme": "https"
        }
    },
    "380": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #380",
                "description": "description",
                "dna": "eb0ba41fa833ec2b84cc743ff5f0bb0a45605bc2",
                "date": 1683439552558,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "380",
                "rarity_score": 171.2697464422409,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/380.png",
            "scheme": "https"
        }
    },
    "2697": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2697",
                "description": "description",
                "dna": "d14d33088674d2aa09581c6cf6172761f3213565",
                "date": 1683439335825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2697",
                "rarity_score": 256.7122266877879,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2697.png",
            "scheme": "https"
        }
    },
    "2793": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2793",
                "description": "description",
                "dna": "426aa875c4890a300eefd5a69be00c76b7f22cff",
                "date": 1683446658829,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2793",
                "rarity_score": 258.0837391384025,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2793.png",
            "scheme": "https"
        }
    },
    "3055": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3055",
                "description": "description",
                "dna": "4c1f1d353b415fb5a560fa06b6081d08a18327ab",
                "date": 1683434512260,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3055",
                "rarity_score": 166.04692447827924,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3055.png",
            "scheme": "https"
        }
    },
    "2761": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2761",
                "description": "description",
                "dna": "bf880565d58634166f2a5e7f98e387721438ae5d",
                "date": 1683442215912,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2761",
                "rarity_score": 187.12269615972434,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2761.png",
            "scheme": "https"
        }
    },
    "3078": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3078",
                "description": "description",
                "dna": "6fc631a9b9f2c0e7dbd51bf395f247a3f29c72a7",
                "date": 1683771120249,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3078",
                "rarity_score": 245.94921270490153,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3078.png",
            "scheme": "https"
        }
    },
    "174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #174",
                "description": "description",
                "dna": "70119eef184e8d0e8bc6053731e0566fbf3d1731",
                "date": 1683445074717,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "174",
                "rarity_score": 183.47243875073713,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/174.png",
            "scheme": "https"
        }
    },
    "667": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #667",
                "description": "description",
                "dna": "5edf2cf9867440c413f2c518f8153fbf1990953d",
                "date": 1683446515019,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "667",
                "rarity_score": 152.68441890121449,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/667.png",
            "scheme": "https"
        }
    },
    "3665": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3665",
                "description": "description",
                "dna": "282cbf957cc8d2b659d677eccdb6c0b6a742b65b",
                "date": 1683445953996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3665",
                "rarity_score": 174.85257242263995,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3665.png",
            "scheme": "https"
        }
    },
    "671": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #671",
                "description": "description",
                "dna": "5be6d788cb731caebe7246ad5a864bda2261ee1d",
                "date": 1683436974343,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "671",
                "rarity_score": 130.66674438058152,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/671.png",
            "scheme": "https"
        }
    },
    "1531": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1531",
                "description": "description",
                "dna": "2d2a2c2b9171e71059fb1a7bd510145ffeda55e7",
                "date": 1683443509981,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1531",
                "rarity_score": 226.08383646362444,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1531.png",
            "scheme": "https"
        }
    },
    "1211": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1211",
                "description": "description",
                "dna": "d811d1f9dbeeccdf9db4495089193e5e58b51cac",
                "date": 1683440463655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1211",
                "rarity_score": 191.97181951236476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1211.png",
            "scheme": "https"
        }
    },
    "2739": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2739",
                "description": "description",
                "dna": "a3a102fae0fa2d3e7c5a61c3a46bd961a1afc159",
                "date": 1683431047122,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2739",
                "rarity_score": 167.61021320786156,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2739.png",
            "scheme": "https"
        }
    },
    "522": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #522",
                "description": "description",
                "dna": "25834763595a64b583fdc4a646df44514316c667",
                "date": 1683444458455,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "522",
                "rarity_score": 198.305819738519,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/522.png",
            "scheme": "https"
        }
    },
    "4087": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4087",
                "description": "description",
                "dna": "e02471246a716ecba89f18a87610e3ccd0ea1cde",
                "date": 1683430489935,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "4087",
                "rarity_score": 186.2781425355798,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4087.png",
            "scheme": "https"
        }
    },
    "2892": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2892",
                "description": "description",
                "dna": "a63f4ee42c4170c0c7a4c3a3fed235306b3aac92",
                "date": 1683437544848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2892",
                "rarity_score": 126.2559360858285,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2892.png",
            "scheme": "https"
        }
    },
    "1595": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1595",
                "description": "description",
                "dna": "405b48c92f75f4bcdc98392bdab0f6181ca1ea30",
                "date": 1683432143084,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1595",
                "rarity_score": 305.42073867591034,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1595.png",
            "scheme": "https"
        }
    },
    "2209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2209",
                "description": "description",
                "dna": "095d4169ec71e4f2e105e00a3d056b9cc2ea3420",
                "date": 1683441696436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2209",
                "rarity_score": 235.9118099926509,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2209.png",
            "scheme": "https"
        }
    },
    "3660": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3660",
                "description": "description",
                "dna": "d7dae1780d121cb6a8c40f23f88aae498f79fc72",
                "date": 1683434045980,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3660",
                "rarity_score": 191.33301843301112,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3660.png",
            "scheme": "https"
        }
    },
    "3821": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3821",
                "description": "description",
                "dna": "776d4a9e7f5e3f17536d865cd8d70d613cf74ab8",
                "date": 1683431740318,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3821",
                "rarity_score": 214.77935628074016,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3821.png",
            "scheme": "https"
        }
    },
    "2055": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2055",
                "description": "description",
                "dna": "757121020b7a0f91f87df11290169360027d67b8",
                "date": 1683432699034,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2055",
                "rarity_score": 187.31431298472612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2055.png",
            "scheme": "https"
        }
    },
    "3601": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3601",
                "description": "description",
                "dna": "cf21a44382655b829e29857e5540fcfb4bbe78f5",
                "date": 1683776637987,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3601",
                "rarity_score": 222.21088068063668,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3601.png",
            "scheme": "https"
        }
    },
    "1310": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1310",
                "description": "description",
                "dna": "f8216fc02eb1c467ae85ff712bb3fa13312c687d",
                "date": 1683445021993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1310",
                "rarity_score": 149.68316587349787,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1310.png",
            "scheme": "https"
        }
    },
    "1265": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1265",
                "description": "description",
                "dna": "8e4ab83337492f57cfc3939877bc08c6ed288474",
                "date": 1683774986243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1265",
                "rarity_score": 163.9206036572035,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1265.png",
            "scheme": "https"
        }
    },
    "3034": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3034",
                "description": "description",
                "dna": "9326d26937bf6312f50f44efc004235b88c43e11",
                "date": 1683436560644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3034",
                "rarity_score": 354.23368330286144,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3034.png",
            "scheme": "https"
        }
    },
    "3045": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3045",
                "description": "description",
                "dna": "088ee9a180ede8432e1c3f0b4c31878477465fb0",
                "date": 1683442388219,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3045",
                "rarity_score": 268.23983799454066,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3045.png",
            "scheme": "https"
        }
    },
    "1317": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1317",
                "description": "description",
                "dna": "b0a72189436da26d340a740ca55f3a35b7f561b1",
                "date": 1683435737824,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1317",
                "rarity_score": 265.45252680085144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1317.png",
            "scheme": "https"
        }
    },
    "2963": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2963",
                "description": "description",
                "dna": "431d2fbf835c6b924b5e55e98313baa6d037c36b",
                "date": 1683447402568,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2963",
                "rarity_score": 226.83422668784985,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2963.png",
            "scheme": "https"
        }
    },
    "1127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1127",
                "description": "description",
                "dna": "2b6e6a1f9fbca577ad94da24d11a75e3a6ddc643",
                "date": 1683442679966,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1127",
                "rarity_score": 184.83007517337018,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1127.png",
            "scheme": "https"
        }
    },
    "948": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #948",
                "description": "description",
                "dna": "d665fc4ac5f946d6945a945ea7335c6d0de09795",
                "date": 1683445653008,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "948",
                "rarity_score": 220.70129731287892,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/948.png",
            "scheme": "https"
        }
    },
    "1629": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1629",
                "description": "description",
                "dna": "43b4939d18f09e8db837345e9ee0ee3a0546c350",
                "date": 1683437010281,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1629",
                "rarity_score": 201.55997900991315,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1629.png",
            "scheme": "https"
        }
    },
    "3151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3151",
                "description": "description",
                "dna": "a783915f405f25165660e8f5d13346817ab11830",
                "date": 1683442309776,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3151",
                "rarity_score": 218.01409865671206,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3151.png",
            "scheme": "https"
        }
    },
    "1035": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1035",
                "description": "description",
                "dna": "a895dcd204d9044fbf41a9eed4221b14094f0407",
                "date": 1683436371632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1035",
                "rarity_score": 274.7183735623612,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1035.png",
            "scheme": "https"
        }
    },
    "971": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #971",
                "description": "description",
                "dna": "9f499a48106f0a3d9168312ad9868fe68ad0a344",
                "date": 1683431019866,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "971",
                "rarity_score": 319.70877355192374,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/971.png",
            "scheme": "https"
        }
    },
    "106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #106",
                "description": "description",
                "dna": "0757bab298f3c3bf3b282d78db256eca3cc78de5",
                "date": 1683776685355,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "106",
                "rarity_score": 190.5364016206082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/106.png",
            "scheme": "https"
        }
    },
    "1790": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1790",
                "description": "description",
                "dna": "b90f805b0cdf5f9205f4b5f9a3147367ae8c2ed4",
                "date": 1683439641194,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1790",
                "rarity_score": 211.4408987709694,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1790.png",
            "scheme": "https"
        }
    },
    "3939": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3939",
                "description": "description",
                "dna": "f95ebb2661498cd93516579928963c0ab6ea098e",
                "date": 1683432711779,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3939",
                "rarity_score": 251.45948429551598,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3939.png",
            "scheme": "https"
        }
    },
    "1579": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1579",
                "description": "description",
                "dna": "443ac9e18e2eafa0ddc23ea2ad29d5b0c2b1be46",
                "date": 1683440550231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1579",
                "rarity_score": 169.28988929996024,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1579.png",
            "scheme": "https"
        }
    },
    "848": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #848",
                "description": "description",
                "dna": "0889edda157570e8fe22e3b42f702fa65fdd34f6",
                "date": 1683435097344,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "848",
                "rarity_score": 160.21955381298773,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/848.png",
            "scheme": "https"
        }
    },
    "3196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3196",
                "description": "description",
                "dna": "f5c5c3f330f9f388aa596b67d26c56ff85f10f96",
                "date": 1683441484908,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3196",
                "rarity_score": 203.34413482130506,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3196.png",
            "scheme": "https"
        }
    },
    "835": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #835",
                "description": "description",
                "dna": "d1baaedd0a9b2b3d0932aadc302cbfd34d41caf6",
                "date": 1683434159148,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "835",
                "rarity_score": 195.60269089571585,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/835.png",
            "scheme": "https"
        }
    },
    "2218": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2218",
                "description": "description",
                "dna": "d7838647a2f9a34b7c571de5a4c15374775dc604",
                "date": 1683774529639,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2218",
                "rarity_score": 192.53963262934298,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2218.png",
            "scheme": "https"
        }
    },
    "2762": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2762",
                "description": "description",
                "dna": "e9d2a127c934e33400078a29edcd017abdf6be7b",
                "date": 1683776544889,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2762",
                "rarity_score": 239.17735968976388,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2762.png",
            "scheme": "https"
        }
    },
    "141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #141",
                "description": "description",
                "dna": "1add22e6c98b1e7437265ef4fe4e8a5cb95302b0",
                "date": 1683429569203,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "141",
                "rarity_score": 175.89633412806933,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/141.png",
            "scheme": "https"
        }
    },
    "3599": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3599",
                "description": "description",
                "dna": "6f3ce6d5ef475cf1cccc7727f94d761af3126872",
                "date": 1683437384481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3599",
                "rarity_score": 197.4667798560622,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3599.png",
            "scheme": "https"
        }
    },
    "1812": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1812",
                "description": "description",
                "dna": "0096bd82ef348c09a9fd5e0fffb0028f41ce122f",
                "date": 1683438053315,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1812",
                "rarity_score": 231.6858996501071,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1812.png",
            "scheme": "https"
        }
    },
    "999": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #999",
                "description": "description",
                "dna": "0ff66b8b431a073000e889cccf39b2c87c2dee20",
                "date": 1683431915571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "999",
                "rarity_score": 146.82456196953342,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/999.png",
            "scheme": "https"
        }
    },
    "3722": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3722",
                "description": "description",
                "dna": "13d7feb77fe061f6fb8d05940133862ac5b94d04",
                "date": 1683446768156,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3722",
                "rarity_score": 162.91275410586786,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3722.png",
            "scheme": "https"
        }
    },
    "1183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1183",
                "description": "description",
                "dna": "f11745b25e3f40e52aaa01ef759473c83f347d3a",
                "date": 1683435498420,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1183",
                "rarity_score": 155.78725620084907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1183.png",
            "scheme": "https"
        }
    },
    "3973": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3973",
                "description": "description",
                "dna": "85c72e6025612dd33a492442375758ba971efef7",
                "date": 1683446190226,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3973",
                "rarity_score": 150.3881091420141,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3973.png",
            "scheme": "https"
        }
    },
    "1209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1209",
                "description": "description",
                "dna": "f9ffa71a5a17f32212bf4c2ab157abb98da4de85",
                "date": 1683433740278,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1209",
                "rarity_score": 181.1681321897791,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1209.png",
            "scheme": "https"
        }
    },
    "1158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1158",
                "description": "description",
                "dna": "4ac3bc34ded003f5368f7eeeca773a28a09c2edc",
                "date": 1683439718074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1158",
                "rarity_score": 198.20640141335645,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1158.png",
            "scheme": "https"
        }
    },
    "1619": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1619",
                "description": "description",
                "dna": "22a0a07fdaaeea924364d9a3b9014a1f900a83e5",
                "date": 1683770668339,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1619",
                "rarity_score": 224.83203323680462,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1619.png",
            "scheme": "https"
        }
    },
    "1216": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1216",
                "description": "description",
                "dna": "39e0065b2148b35a6929617c03739e7011759f52",
                "date": 1683439326980,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1216",
                "rarity_score": 173.31059200443403,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1216.png",
            "scheme": "https"
        }
    },
    "1941": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1941",
                "description": "description",
                "dna": "df1665da71171a200fc1517cf75b4a22e8f2a9f0",
                "date": 1683432871666,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1941",
                "rarity_score": 264.594885218821,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1941.png",
            "scheme": "https"
        }
    },
    "3586": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3586",
                "description": "description",
                "dna": "27bc307f8e005a54235f7442cba8d5f4cdfe2515",
                "date": 1683438682258,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3586",
                "rarity_score": 197.1681287841375,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3586.png",
            "scheme": "https"
        }
    },
    "694": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #694",
                "description": "description",
                "dna": "0d2502fa118b2f9c541182b34265018e751dc6ab",
                "date": 1683439814924,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "694",
                "rarity_score": 176.96088080704683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/694.png",
            "scheme": "https"
        }
    },
    "4009": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4009",
                "description": "description",
                "dna": "fe89189501aaf9a59cd7a6f3408fe331b3b1466b",
                "date": 1683441822357,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "4009",
                "rarity_score": 214.9349450982376,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4009.png",
            "scheme": "https"
        }
    },
    "688": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #688",
                "description": "description",
                "dna": "be732aede845659c8d0ee347f37ee9fe32ad185a",
                "date": 1683435810112,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "688",
                "rarity_score": 194.8220225924934,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/688.png",
            "scheme": "https"
        }
    },
    "986": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #986",
                "description": "description",
                "dna": "2eff6640f1e742e50792ab65ae011106a34671f6",
                "date": 1683438765302,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "986",
                "rarity_score": 203.98826667630703,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/986.png",
            "scheme": "https"
        }
    },
    "2695": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2695",
                "description": "description",
                "dna": "e8c65355600fe613fd6d9d61748628c4f1d93fb3",
                "date": 1683441777361,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2695",
                "rarity_score": 160.63431048065974,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2695.png",
            "scheme": "https"
        }
    },
    "1004": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1004",
                "description": "description",
                "dna": "7f35332141491a2d09bfd85a5159aeac7fd76374",
                "date": 1683433838537,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "1004",
                "rarity_score": 328.2397089479568,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1004.png",
            "scheme": "https"
        }
    },
    "324": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #324",
                "description": "description",
                "dna": "aff4be387e6c7a086c24a637865f3d7d6f471e34",
                "date": 1683443238745,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "324",
                "rarity_score": 198.2299953879685,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/324.png",
            "scheme": "https"
        }
    },
    "807": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #807",
                "description": "description",
                "dna": "77be31db3050cb87908af2fcad09905c858cc803",
                "date": 1683774417247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "807",
                "rarity_score": 158.08536786923125,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/807.png",
            "scheme": "https"
        }
    },
    "4064": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4064",
                "description": "description",
                "dna": "3833523978bc750543805169c6a07f736c4b9b9d",
                "date": 1683433771475,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "4064",
                "rarity_score": 316.37444573483623,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4064.png",
            "scheme": "https"
        }
    },
    "2049": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2049",
                "description": "description",
                "dna": "5825d70940910b974d34cc57e114580884c823e2",
                "date": 1683445422135,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2049",
                "rarity_score": 182.4910257821138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2049.png",
            "scheme": "https"
        }
    },
    "2970": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2970",
                "description": "description",
                "dna": "8c6823218fa34c2accf214770c61bca75f90d2b2",
                "date": 1683430251787,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2970",
                "rarity_score": 208.0151956829436,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2970.png",
            "scheme": "https"
        }
    },
    "937": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #937",
                "description": "description",
                "dna": "dcac641aaec1f35afb31502277a01e081d2d1621",
                "date": 1683442055720,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "937",
                "rarity_score": 228.0046455665625,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/937.png",
            "scheme": "https"
        }
    },
    "3302": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3302",
                "description": "description",
                "dna": "10fecfe58700437a8d8024c03e5e3b73713555a3",
                "date": 1683432703341,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3302",
                "rarity_score": 162.00160344749992,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3302.png",
            "scheme": "https"
        }
    },
    "2487": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2487",
                "description": "description",
                "dna": "9d46ccc964f021ec608ae7d49df6f56f2c5bcd9c",
                "date": 1683431831059,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2487",
                "rarity_score": 167.30113371695342,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2487.png",
            "scheme": "https"
        }
    },
    "529": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #529",
                "description": "description",
                "dna": "cea12f7b83b45eec525a38c5f8bb9f7c89d330e9",
                "date": 1683438645463,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "529",
                "rarity_score": 145.9207769804057,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/529.png",
            "scheme": "https"
        }
    },
    "1273": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1273",
                "description": "description",
                "dna": "ddec6f04818d9f8ab2cf256dd1a35edba9c832d8",
                "date": 1683445150434,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1273",
                "rarity_score": 162.51749748639344,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1273.png",
            "scheme": "https"
        }
    },
    "3120": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3120",
                "description": "description",
                "dna": "53299fa8e6825e184b94c5cc513c8c1b65163959",
                "date": 1683429468297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3120",
                "rarity_score": 192.72163892029693,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3120.png",
            "scheme": "https"
        }
    },
    "3779": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3779",
                "description": "description",
                "dna": "f9b4612dd447ec7f75c22d4176125ff022da24e8",
                "date": 1683439009400,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3779",
                "rarity_score": 179.3246150936694,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3779.png",
            "scheme": "https"
        }
    },
    "1904": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1904",
                "description": "description",
                "dna": "3031f7a2a907ac76bd4b7cd2d8011694be259d83",
                "date": 1683435967774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1904",
                "rarity_score": 150.55170660001122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1904.png",
            "scheme": "https"
        }
    },
    "2929": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2929",
                "description": "description",
                "dna": "d23abed3141e1acf22de3b71e6f4d43bdb5ac1a9",
                "date": 1683437983958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2929",
                "rarity_score": 169.6828217722503,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2929.png",
            "scheme": "https"
        }
    },
    "134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #134",
                "description": "description",
                "dna": "2588ff0e5c40d045b07a8542e6b3392df82005dd",
                "date": 1683771408716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "134",
                "rarity_score": 206.92029048823267,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/134.png",
            "scheme": "https"
        }
    },
    "301": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #301",
                "description": "description",
                "dna": "85ea5825d481b16e2c6e13f9779de0911e7a7538",
                "date": 1683430164495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "301",
                "rarity_score": 204.80014328974616,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/301.png",
            "scheme": "https"
        }
    },
    "996": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #996",
                "description": "description",
                "dna": "9dae197e91f95d3bfe5acb62a3ca1dcb4c6a2c57",
                "date": 1683430530187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "996",
                "rarity_score": 207.78996121165656,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/996.png",
            "scheme": "https"
        }
    },
    "773": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #773",
                "description": "description",
                "dna": "a02c44fc5beb6c16306cbe2adf057fea22fa815c",
                "date": 1683433515255,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "773",
                "rarity_score": 156.9665725675793,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/773.png",
            "scheme": "https"
        }
    },
    "2748": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2748",
                "description": "description",
                "dna": "db7ee772c9d3c992ecbb8aa9ea84caeb1ea773ce",
                "date": 1683431617826,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2748",
                "rarity_score": 234.0462617103234,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2748.png",
            "scheme": "https"
        }
    },
    "2317": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2317",
                "description": "description",
                "dna": "3d19c252efcf3d338fe05df834468cb8be281588",
                "date": 1683437478678,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2317",
                "rarity_score": 127.63876947498892,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2317.png",
            "scheme": "https"
        }
    },
    "201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #201",
                "description": "description",
                "dna": "0ba398e84ab53d69f3de66ffc32458ca384efebe",
                "date": 1683432343389,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "201",
                "rarity_score": 357.94786062060905,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/201.png",
            "scheme": "https"
        }
    },
    "3639": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3639",
                "description": "description",
                "dna": "b19c7e1c5063c116d45d48b516c74fbe17494370",
                "date": 1683444290154,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3639",
                "rarity_score": 187.2411665378414,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3639.png",
            "scheme": "https"
        }
    },
    "3181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3181",
                "description": "description",
                "dna": "f40628f74649efd409741a2bd694723c1e653b85",
                "date": 1683445992694,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3181",
                "rarity_score": 120.84727350117326,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3181.png",
            "scheme": "https"
        }
    },
    "3643": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3643",
                "description": "description",
                "dna": "af888a899744a275922b4fd21e78e1d51458dcaa",
                "date": 1683445794596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3643",
                "rarity_score": 205.85461431014633,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3643.png",
            "scheme": "https"
        }
    },
    "2355": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2355",
                "description": "description",
                "dna": "a8cd7683d56dd816d9b08eb5dd3147976a87760e",
                "date": 1683771349810,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2355",
                "rarity_score": 199.70943479511655,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2355.png",
            "scheme": "https"
        }
    },
    "877": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #877",
                "description": "description",
                "dna": "29507f4399e40f5c4993857ed294524d63a3be11",
                "date": 1683437944584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "877",
                "rarity_score": 216.30413102821194,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/877.png",
            "scheme": "https"
        }
    },
    "1815": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1815",
                "description": "description",
                "dna": "43083ba5b614e854eff694c433ad49babaac86fb",
                "date": 1683441453578,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1815",
                "rarity_score": 163.0809078450934,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1815.png",
            "scheme": "https"
        }
    },
    "1908": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1908",
                "description": "description",
                "dna": "49509b93d76bfe182eda08671652923ea3be9429",
                "date": 1683437318635,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1908",
                "rarity_score": 165.41822320223164,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1908.png",
            "scheme": "https"
        }
    },
    "1457": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1457",
                "description": "description",
                "dna": "3df97726893de5ee3d23665df6acabfe63db1ca4",
                "date": 1683444216233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1457",
                "rarity_score": 213.6248660661012,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1457.png",
            "scheme": "https"
        }
    },
    "2150": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2150",
                "description": "description",
                "dna": "8fdae6620af493b0b8a38e05a18a0d4a1b06e776",
                "date": 1683438330790,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2150",
                "rarity_score": 214.08073460023573,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2150.png",
            "scheme": "https"
        }
    },
    "2293": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2293",
                "description": "description",
                "dna": "d3badbdbf4dc6f865ad203d37c67489b7914dfda",
                "date": 1683433611231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2293",
                "rarity_score": 202.8069107750323,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2293.png",
            "scheme": "https"
        }
    },
    "3375": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3375",
                "description": "description",
                "dna": "1dbe5cc09e4b2eb467cf24689828524bdc34446d",
                "date": 1683441592593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3375",
                "rarity_score": 262.7939957347572,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3375.png",
            "scheme": "https"
        }
    },
    "3885": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3885",
                "description": "description",
                "dna": "4524ed30f295022ead409ca6ef5642d755255da7",
                "date": 1683774753294,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3885",
                "rarity_score": 187.83087905126388,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3885.png",
            "scheme": "https"
        }
    },
    "3471": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3471",
                "description": "description",
                "dna": "12344432b993975c55397b3d71260aaca87b47de",
                "date": 1683434420486,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3471",
                "rarity_score": 166.79102737435366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3471.png",
            "scheme": "https"
        }
    },
    "1970": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1970",
                "description": "description",
                "dna": "db83239170a965687a10c88b70ab7f88ad26b208",
                "date": 1683435521400,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1970",
                "rarity_score": 177.41088593918843,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1970.png",
            "scheme": "https"
        }
    },
    "2612": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2612",
                "description": "description",
                "dna": "a2200411414de1b4dd556949c7e17e902fda9885",
                "date": 1683445321020,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2612",
                "rarity_score": 248.45513195091382,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2612.png",
            "scheme": "https"
        }
    },
    "1515": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1515",
                "description": "description",
                "dna": "b72318ba84bdfe54d6407a3ff8acd05e5278f02f",
                "date": 1683445687759,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1515",
                "rarity_score": 209.137261122061,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1515.png",
            "scheme": "https"
        }
    },
    "2249": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2249",
                "description": "description",
                "dna": "0f1591f679762335e10a11d2d8148ac15618f11a",
                "date": 1683775209743,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2249",
                "rarity_score": 187.28884473190897,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2249.png",
            "scheme": "https"
        }
    },
    "105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #105",
                "description": "description",
                "dna": "ed3aec3ba541f8540096c0d244220a135a93ab48",
                "date": 1683442596588,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "105",
                "rarity_score": 199.74862629969545,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/105.png",
            "scheme": "https"
        }
    },
    "3832": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3832",
                "description": "description",
                "dna": "50dab18db03b31d527850f9e0053f43724f05dac",
                "date": 1683436506407,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3832",
                "rarity_score": 227.1495960413077,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3832.png",
            "scheme": "https"
        }
    },
    "2347": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2347",
                "description": "description",
                "dna": "b915b85b4dd78a6fc5c27f3822cd2b602bcdeeb3",
                "date": 1683433105898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2347",
                "rarity_score": 180.32445246416688,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2347.png",
            "scheme": "https"
        }
    },
    "1456": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1456",
                "description": "description",
                "dna": "5e215bf77b79acdcd024f9add6cc0bb64c749cfe",
                "date": 1683429879870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1456",
                "rarity_score": 159.99685302775075,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1456.png",
            "scheme": "https"
        }
    },
    "477": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #477",
                "description": "description",
                "dna": "5774066e57772bb9d5abf5b121d83229ab635170",
                "date": 1683443016190,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "477",
                "rarity_score": 245.6432987349221,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/477.png",
            "scheme": "https"
        }
    },
    "3775": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3775",
                "description": "description",
                "dna": "6a704e7e88d16646d831011f7acf02ecdaebb5e3",
                "date": 1683434167595,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3775",
                "rarity_score": 205.58567310746386,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3775.png",
            "scheme": "https"
        }
    },
    "1878": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1878",
                "description": "description",
                "dna": "81f0f72211e44f7fb51e2dec86c404d52d4d616e",
                "date": 1683774597425,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1878",
                "rarity_score": 164.50640607854385,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1878.png",
            "scheme": "https"
        }
    },
    "2094": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2094",
                "description": "description",
                "dna": "d92a0a6aa501835577a95603fd9e302edbfe2222",
                "date": 1683436367172,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2094",
                "rarity_score": 243.7473635069373,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2094.png",
            "scheme": "https"
        }
    },
    "4097": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4097",
                "description": "description",
                "dna": "129a1e8e464ae6d3ec3af578cb74623b91c67c73",
                "date": 1683438311809,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "4097",
                "rarity_score": 235.45492153811338,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4097.png",
            "scheme": "https"
        }
    },
    "1683": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1683",
                "description": "description",
                "dna": "8276f0f6c858dfeb64dbfef3ddf2aa5405f1c75d",
                "date": 1683774977747,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1683",
                "rarity_score": 172.96453388166276,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1683.png",
            "scheme": "https"
        }
    },
    "946": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #946",
                "description": "description",
                "dna": "55704f9d19c7cac7a2dafea9dfd7e1931acb395b",
                "date": 1683436849188,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "946",
                "rarity_score": 128.52217861447556,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/946.png",
            "scheme": "https"
        }
    },
    "2809": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2809",
                "description": "description",
                "dna": "d75e3a039c83b81ab119c9f3798f00605f22d665",
                "date": 1683431248135,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2809",
                "rarity_score": 280.5819185934816,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2809.png",
            "scheme": "https"
        }
    },
    "2960": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2960",
                "description": "description",
                "dna": "2800c46a7fbe34e11182ee6dd8d51478e34402fa",
                "date": 1683435157520,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2960",
                "rarity_score": 199.58385378265976,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2960.png",
            "scheme": "https"
        }
    },
    "93": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #93",
                "description": "description",
                "dna": "06faa30dbeb026a9e3f991fbe4ebff6f97c85ddf",
                "date": 1683438377129,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "93",
                "rarity_score": 192.79963448958338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/93.png",
            "scheme": "https"
        }
    },
    "664": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #664",
                "description": "description",
                "dna": "d21ff4561de6c2b2e59d437e30d16bddc68c9025",
                "date": 1683444815571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "664",
                "rarity_score": 203.1236592934723,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/664.png",
            "scheme": "https"
        }
    },
    "1959": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1959",
                "description": "description",
                "dna": "d7709bb8bf2d734f0c46779eee2b1d13d87fcdcf",
                "date": 1683438779347,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1959",
                "rarity_score": 228.10567978426587,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1959.png",
            "scheme": "https"
        }
    },
    "712": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #712",
                "description": "description",
                "dna": "e8ac1a997e5c0badcbbbfb1f7b97729d0c4617eb",
                "date": 1683770535513,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "712",
                "rarity_score": 223.03762355207488,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/712.png",
            "scheme": "https"
        }
    },
    "1074": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1074",
                "description": "description",
                "dna": "b4d749d45f39b33563d1ef73ec355ee68443821e",
                "date": 1683440157261,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1074",
                "rarity_score": 379.9002285033291,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1074.png",
            "scheme": "https"
        }
    },
    "1734": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1734",
                "description": "description",
                "dna": "8d0a7005281b2a9461ff982b5f4b6e290495ee7b",
                "date": 1683445866490,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1734",
                "rarity_score": 206.41605161710842,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1734.png",
            "scheme": "https"
        }
    },
    "2386": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2386",
                "description": "description",
                "dna": "b68b1bc3a19a067b9e30c097652738b12b3ae879",
                "date": 1683439847069,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2386",
                "rarity_score": 180.91366768055036,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2386.png",
            "scheme": "https"
        }
    },
    "2117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2117",
                "description": "description",
                "dna": "c2ceb6b382324f82f71e797102994ae680ce8d62",
                "date": 1683445950282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2117",
                "rarity_score": 132.09937309543201,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2117.png",
            "scheme": "https"
        }
    },
    "1322": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1322",
                "description": "description",
                "dna": "5c9cb06ec32f114db2d98b331acc6b1d4f5d89d7",
                "date": 1683436335678,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1322",
                "rarity_score": 253.97628721508949,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1322.png",
            "scheme": "https"
        }
    },
    "3650": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3650",
                "description": "description",
                "dna": "f50930fe2ea3822881a2f9da88d1786d21510ddb",
                "date": 1683430292044,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3650",
                "rarity_score": 248.0818223385235,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3650.png",
            "scheme": "https"
        }
    },
    "3596": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3596",
                "description": "description",
                "dna": "1defcd32aefad3a2e9a3631b6f4ae0ada75c4858",
                "date": 1683442341356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3596",
                "rarity_score": 158.27953825394374,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3596.png",
            "scheme": "https"
        }
    },
    "969": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #969",
                "description": "description",
                "dna": "3ff08a0915589bd3795e366bd1898de0879d6621",
                "date": 1683437277039,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "969",
                "rarity_score": 259.21845936086174,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/969.png",
            "scheme": "https"
        }
    },
    "4033": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4033",
                "description": "description",
                "dna": "9cb7cb71d0db1aa98dbc053cddc78b943e90b131",
                "date": 1683774493322,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "4033",
                "rarity_score": 174.48184849280324,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4033.png",
            "scheme": "https"
        }
    },
    "3450": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3450",
                "description": "description",
                "dna": "9f37f63cfbd6e3df4ece70557e796d51a71fa224",
                "date": 1683445529576,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3450",
                "rarity_score": 217.1881509763184,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3450.png",
            "scheme": "https"
        }
    },
    "844": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #844",
                "description": "description",
                "dna": "ac491bd3cc8117acbce78334fe8bdbe365abc51d",
                "date": 1683441261406,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "844",
                "rarity_score": 362.38687454856966,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/844.png",
            "scheme": "https"
        }
    },
    "1682": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1682",
                "description": "description",
                "dna": "b46bb55f2f086819eb8a93a2af941ddd36bb903a",
                "date": 1683442155063,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1682",
                "rarity_score": 175.59111489537605,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1682.png",
            "scheme": "https"
        }
    },
    "357": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #357",
                "description": "description",
                "dna": "729a783ef6dc1b0bf5c7d54f89a37bca571e9e30",
                "date": 1683443968070,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "357",
                "rarity_score": 237.66165685901944,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/357.png",
            "scheme": "https"
        }
    },
    "2890": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2890",
                "description": "description",
                "dna": "4b7d45a344bd7c6cd93409261f1735eeadcca923",
                "date": 1683439036416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2890",
                "rarity_score": 210.34060292389776,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2890.png",
            "scheme": "https"
        }
    },
    "3684": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3684",
                "description": "description",
                "dna": "f1ba1bf62479931014be02524d491efab6587425",
                "date": 1683446050784,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3684",
                "rarity_score": 136.18407793828544,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3684.png",
            "scheme": "https"
        }
    },
    "2009": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2009",
                "description": "description",
                "dna": "4445c398811dd937ae54c7b69199c30229eecc72",
                "date": 1683441252938,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2009",
                "rarity_score": 192.0688465879292,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2009.png",
            "scheme": "https"
        }
    },
    "3420": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3420",
                "description": "description",
                "dna": "de00aaaf2de837b45391c6d98a6decd7893be4af",
                "date": 1683441623511,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3420",
                "rarity_score": 193.02972646709227,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3420.png",
            "scheme": "https"
        }
    },
    "269": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #269",
                "description": "description",
                "dna": "5c509ebbf2062cd8f2177e5d910ee3bb4e585dd0",
                "date": 1683432400689,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "269",
                "rarity_score": 175.59983940919608,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/269.png",
            "scheme": "https"
        }
    },
    "744": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #744",
                "description": "description",
                "dna": "40e100a4a512a124e642cd7142e6e3f47d0dc228",
                "date": 1683517585822,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Grey Hand",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "744",
                "rarity_score": 4314.238088229003,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/744.png",
            "scheme": "https"
        }
    },
    "508": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #508",
                "description": "description",
                "dna": "08c54a647cdfbdb15e7fe57ecaf1d860f71b714d",
                "date": 1683771458697,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "508",
                "rarity_score": 172.40462482189758,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/508.png",
            "scheme": "https"
        }
    },
    "3936": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3936",
                "description": "description",
                "dna": "2a01657cbef5e1e4b582ecf0ac8bb2264c28b2bc",
                "date": 1683436349691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3936",
                "rarity_score": 263.72356726381906,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3936.png",
            "scheme": "https"
        }
    },
    "2499": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2499",
                "description": "description",
                "dna": "f15dfca161c888a8372ff153dba354f6dd275330",
                "date": 1683434131718,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2499",
                "rarity_score": 171.8977168404409,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2499.png",
            "scheme": "https"
        }
    },
    "813": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #813",
                "description": "description",
                "dna": "ca0bd53657e733948e24d4c83e96c8bf27937ac7",
                "date": 1683445763811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "813",
                "rarity_score": 196.84301695641292,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/813.png",
            "scheme": "https"
        }
    },
    "1658": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1658",
                "description": "description",
                "dna": "1fb1b7f787922bc159b14f26fd0750953f56a9b8",
                "date": 1683439299599,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1658",
                "rarity_score": 276.3149743052821,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1658.png",
            "scheme": "https"
        }
    },
    "815": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #815",
                "description": "description",
                "dna": "deccd9eddd30a22dbe8b98627ee2eeff551afe70",
                "date": 1683435932009,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "815",
                "rarity_score": 259.95295202648055,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/815.png",
            "scheme": "https"
        }
    },
    "3536": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3536",
                "description": "description",
                "dna": "8286413c3a13646718bee03d41472c898bd88d69",
                "date": 1683447447633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3536",
                "rarity_score": 184.7644854087358,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3536.png",
            "scheme": "https"
        }
    },
    "2337": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2337",
                "description": "description",
                "dna": "1a0f04843bd2e6b1c17121101d746464857da3c1",
                "date": 1683442248896,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2337",
                "rarity_score": 218.45498327387529,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2337.png",
            "scheme": "https"
        }
    },
    "3904": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3904",
                "description": "description",
                "dna": "1e609554f9d6318a7470fe321916f4e554c0b952",
                "date": 1683434340412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3904",
                "rarity_score": 235.8813000733431,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3904.png",
            "scheme": "https"
        }
    },
    "3852": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3852",
                "description": "description",
                "dna": "ca9a1af335f66f9866ae941b5f41572b3a2510b0",
                "date": 1683446616454,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3852",
                "rarity_score": 276.8889770929976,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3852.png",
            "scheme": "https"
        }
    },
    "3480": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3480",
                "description": "description",
                "dna": "592c5dc3714396df8288a7aebdb4ea63c1675a7b",
                "date": 1683432242654,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3480",
                "rarity_score": 269.42825127762444,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3480.png",
            "scheme": "https"
        }
    },
    "1263": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1263",
                "description": "description",
                "dna": "7fe25d2fd3b57a0281842611e95aeea236145e9b",
                "date": 1683444233942,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1263",
                "rarity_score": 219.97720684710018,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1263.png",
            "scheme": "https"
        }
    },
    "3751": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3751",
                "description": "description",
                "dna": "fed3daca3f88e06c659dc9716ceaaae54b9b12d9",
                "date": 1683440546052,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3751",
                "rarity_score": 206.71901491976973,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3751.png",
            "scheme": "https"
        }
    },
    "3088": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3088",
                "description": "description",
                "dna": "d87186b47bda1b21482779c09c07c56ead75cfeb",
                "date": 1683438279046,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3088",
                "rarity_score": 215.25447900300824,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3088.png",
            "scheme": "https"
        }
    },
    "976": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #976",
                "description": "description",
                "dna": "3ffec97da17ed309995e169d6a2cbe576fa46d13",
                "date": 1683430775774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "976",
                "rarity_score": 275.71257538834044,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/976.png",
            "scheme": "https"
        }
    },
    "3670": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3670",
                "description": "description",
                "dna": "f6837fe68592a6aa56d93faa673905c0a616997f",
                "date": 1683429322667,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3670",
                "rarity_score": 155.6194230005861,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3670.png",
            "scheme": "https"
        }
    },
    "1735": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1735",
                "description": "description",
                "dna": "37e5005d238998c656c8a781967e0f34bc946aae",
                "date": 1683435621092,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1735",
                "rarity_score": 153.09893478797565,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1735.png",
            "scheme": "https"
        }
    },
    "467": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #467",
                "description": "description",
                "dna": "5a32a146792a7df3f9b9f1971e4eaa435a7fe954",
                "date": 1683437931901,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "467",
                "rarity_score": 231.79266623928692,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/467.png",
            "scheme": "https"
        }
    },
    "3410": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3410",
                "description": "description",
                "dna": "b159e1ba2a2206340a8269165a3b805238abedcd",
                "date": 1683446360288,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3410",
                "rarity_score": 160.47258898823875,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3410.png",
            "scheme": "https"
        }
    },
    "1546": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1546",
                "description": "description",
                "dna": "1f3cefd2f19f6bc2dc0a375e44fa4035efacf147",
                "date": 1683436547394,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1546",
                "rarity_score": 225.76922196028556,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1546.png",
            "scheme": "https"
        }
    },
    "215": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #215",
                "description": "description",
                "dna": "fc8300976be4dc96e34f32daae8fc5bf81f1cae4",
                "date": 1683446486910,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "215",
                "rarity_score": 134.93855166769015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/215.png",
            "scheme": "https"
        }
    },
    "751": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #751",
                "description": "description",
                "dna": "6435db0dbf4c41c40a59b46bb045ec13464f1534",
                "date": 1683443297196,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "751",
                "rarity_score": 191.50214192786325,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/751.png",
            "scheme": "https"
        }
    },
    "2101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2101",
                "description": "description",
                "dna": "7006f2b0ab77c8473b9d1806483a072639c9d6db",
                "date": 1683443133388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2101",
                "rarity_score": 172.66503317186644,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2101.png",
            "scheme": "https"
        }
    },
    "2368": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2368",
                "description": "description",
                "dna": "a70053f5805479426b14ef45414e513e421e8e17",
                "date": 1683432515825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2368",
                "rarity_score": 324.5473536209663,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2368.png",
            "scheme": "https"
        }
    },
    "361": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #361",
                "description": "description",
                "dna": "8a2a705ef44a55d5791715930dc1de0d22e63c27",
                "date": 1683508683804,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "361",
                "rarity_score": 421.32172555308574,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/361.png",
            "scheme": "https"
        }
    },
    "3245": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3245",
                "description": "description",
                "dna": "f1e6f5f869004936598602e644586f931c8932d7",
                "date": 1683437761040,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3245",
                "rarity_score": 156.18429972966854,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3245.png",
            "scheme": "https"
        }
    },
    "4004": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4004",
                "description": "description",
                "dna": "fffc1bebd0054d48ade0818d7424350251e2d30c",
                "date": 1683443752877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "4004",
                "rarity_score": 208.0767388585267,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4004.png",
            "scheme": "https"
        }
    },
    "2647": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2647",
                "description": "description",
                "dna": "fbc30530fe583d7525ee86a5ce7aafb5fb4fde9d",
                "date": 1683444131562,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2647",
                "rarity_score": 204.52832165848216,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2647.png",
            "scheme": "https"
        }
    },
    "1840": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1840",
                "description": "description",
                "dna": "50b9209ae2626b2a8820ab5ce4fc68e151fd1f0e",
                "date": 1683770523195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1840",
                "rarity_score": 276.8110494139772,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1840.png",
            "scheme": "https"
        }
    },
    "820": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #820",
                "description": "description",
                "dna": "1f81d789a4159265c39bcc412e57940d3f54f5c6",
                "date": 1683439313578,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "820",
                "rarity_score": 155.05835622101995,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/820.png",
            "scheme": "https"
        }
    },
    "1139": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1139",
                "description": "description",
                "dna": "c6a5c1df1e6976dfa529958e671e7e2187f29818",
                "date": 1683438134956,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1139",
                "rarity_score": 184.5583151044862,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1139.png",
            "scheme": "https"
        }
    },
    "845": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #845",
                "description": "description",
                "dna": "151f07cbf8a4cf493dee466c81749cbbe832bccc",
                "date": 1683429678643,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "845",
                "rarity_score": 172.7163258269122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/845.png",
            "scheme": "https"
        }
    },
    "862": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #862",
                "description": "description",
                "dna": "c959f7a83a5808d435295bd3f00c865f4ccdfe91",
                "date": 1683430825765,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "862",
                "rarity_score": 261.040610638183,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/862.png",
            "scheme": "https"
        }
    },
    "2950": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2950",
                "description": "description",
                "dna": "902d85bb194b3e9ee1f2e260999037af089682a9",
                "date": 1683447384944,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2950",
                "rarity_score": 190.41987435826823,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2950.png",
            "scheme": "https"
        }
    },
    "3185": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3185",
                "description": "description",
                "dna": "d464dadfd34f5e5edd7a4eecc08113e14f7b40a0",
                "date": 1683431125220,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3185",
                "rarity_score": 162.16246330946464,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3185.png",
            "scheme": "https"
        }
    },
    "2287": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2287",
                "description": "description",
                "dna": "f468c99386c62555916f3935ef48f200981cd647",
                "date": 1683434799492,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2287",
                "rarity_score": 188.83240609766187,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2287.png",
            "scheme": "https"
        }
    },
    "3611": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3611",
                "description": "description",
                "dna": "cefcb6103fd725829a79e3fce4d281c54215267d",
                "date": 1683436267697,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3611",
                "rarity_score": 171.40748767834327,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3611.png",
            "scheme": "https"
        }
    },
    "161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #161",
                "description": "description",
                "dna": "3598721394e7c41ab3f0e1d37f6c859d9474b875",
                "date": 1683771290078,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "161",
                "rarity_score": 220.7243685227528,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/161.png",
            "scheme": "https"
        }
    },
    "2671": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2671",
                "description": "description",
                "dna": "cbc2f3153091e67fc339bb2435d2bfd780f09b17",
                "date": 1683447439135,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2671",
                "rarity_score": 180.69853280218464,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2671.png",
            "scheme": "https"
        }
    },
    "3872": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3872",
                "description": "description",
                "dna": "5206efc691e3822cabece718d6564dd4278e52fb",
                "date": 1683443164378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3872",
                "rarity_score": 229.574835765711,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3872.png",
            "scheme": "https"
        }
    },
    "4012": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4012",
                "description": "description",
                "dna": "4ef4fb088e152ed4c72dec3dd54d5db3a850f933",
                "date": 1683442867337,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4012",
                "rarity_score": 169.37866200287615,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4012.png",
            "scheme": "https"
        }
    },
    "3918": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3918",
                "description": "description",
                "dna": "0803ab61ca03454041657479c9f99e9159580a1c",
                "date": 1683434398662,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3918",
                "rarity_score": 241.78808695620182,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3918.png",
            "scheme": "https"
        }
    },
    "2781": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2781",
                "description": "description",
                "dna": "a04187664872ee179b22682f1dd2e59409541ce4",
                "date": 1683446637474,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2781",
                "rarity_score": 217.52500299269911,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2781.png",
            "scheme": "https"
        }
    },
    "1565": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1565",
                "description": "description",
                "dna": "b974c3516033e4113ab5c96d979bbe967bd581e0",
                "date": 1683443110523,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1565",
                "rarity_score": 191.07799895176174,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1565.png",
            "scheme": "https"
        }
    },
    "2265": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2265",
                "description": "description",
                "dna": "6a07f358b75dce955d6dd5ae7bdad993eaacbe52",
                "date": 1683517578552,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Gold Fist",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2265",
                "rarity_score": 4251.389033406287,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2265.png",
            "scheme": "https"
        }
    },
    "2225": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2225",
                "description": "description",
                "dna": "d92c327d145ed634a630ea9bdb6f7a8d58f5cd6f",
                "date": 1683446058495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2225",
                "rarity_score": 184.9885830694198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2225.png",
            "scheme": "https"
        }
    },
    "407": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #407",
                "description": "description",
                "dna": "dc9dce7dbe47ba10ede7cd1ca2dc31638f0b570c",
                "date": 1683436770192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "407",
                "rarity_score": 257.61718017941797,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/407.png",
            "scheme": "https"
        }
    },
    "2277": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2277",
                "description": "description",
                "dna": "367423bd1fa250b33e8e658d904dd9eede7c33fc",
                "date": 1683446304738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2277",
                "rarity_score": 120.67892183715414,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2277.png",
            "scheme": "https"
        }
    },
    "304": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #304",
                "description": "description",
                "dna": "89f033bc724556b08bfae55c3c998a95f82ccd38",
                "date": 1683434698326,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "304",
                "rarity_score": 197.45351161838067,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/304.png",
            "scheme": "https"
        }
    },
    "2656": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2656",
                "description": "description",
                "dna": "83160bcfad1bde8444ed2981d17243a9a985074c",
                "date": 1683444409798,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2656",
                "rarity_score": 187.6812142560795,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2656.png",
            "scheme": "https"
        }
    },
    "3255": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3255",
                "description": "description",
                "dna": "4dcdd0f7336c2056461addf244dc36bd7a1fda80",
                "date": 1683440143631,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3255",
                "rarity_score": 152.6321941866802,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3255.png",
            "scheme": "https"
        }
    },
    "3126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3126",
                "description": "description",
                "dna": "66bbb26925c7f3f232097121939170d9af6d9c6f",
                "date": 1683438559572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3126",
                "rarity_score": 199.82362811632368,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3126.png",
            "scheme": "https"
        }
    },
    "1695": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1695",
                "description": "description",
                "dna": "eff270277068639ea7561280f34acadc78b822ac",
                "date": 1683776736898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1695",
                "rarity_score": 220.90262113549727,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1695.png",
            "scheme": "https"
        }
    },
    "3616": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3616",
                "description": "description",
                "dna": "58e570645de04d2ea2a319cbe78d0bbe05ce0bd3",
                "date": 1683433579267,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3616",
                "rarity_score": 204.08887486003468,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3616.png",
            "scheme": "https"
        }
    },
    "2851": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2851",
                "description": "description",
                "dna": "1b12faa2ebf1eafee1b52edd306628979f4b01ed",
                "date": 1683444484872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2851",
                "rarity_score": 157.45266984944706,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2851.png",
            "scheme": "https"
        }
    },
    "3526": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3526",
                "description": "description",
                "dna": "e5236113fea33af3fc0f88b3473a3a5e87eb194a",
                "date": 1683445961966,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3526",
                "rarity_score": 130.68039437591815,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3526.png",
            "scheme": "https"
        }
    },
    "3103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3103",
                "description": "description",
                "dna": "a939aa268bae56151e229f99da273be6c4ef60ce",
                "date": 1683434424904,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3103",
                "rarity_score": 277.76226062506476,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3103.png",
            "scheme": "https"
        }
    },
    "1925": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1925",
                "description": "description",
                "dna": "c5f8ef20a7e570a47ab7bdabac3d69d70c3e5ab4",
                "date": 1683446407130,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1925",
                "rarity_score": 133.65668529808434,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1925.png",
            "scheme": "https"
        }
    },
    "2381": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2381",
                "description": "description",
                "dna": "bc8497f9428e9bc66e3b552bcd7a81eea0400f3e",
                "date": 1683443348459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2381",
                "rarity_score": 277.4984035236902,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2381.png",
            "scheme": "https"
        }
    },
    "2250": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2250",
                "description": "description",
                "dna": "f04da5d75956aa30e7cdafc445ba35a85e54bc7d",
                "date": 1683439662863,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2250",
                "rarity_score": 231.6884350620804,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2250.png",
            "scheme": "https"
        }
    },
    "954": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #954",
                "description": "description",
                "dna": "0025a19bd680b8b48a0eed943a7165335c9425d2",
                "date": 1683437826005,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "954",
                "rarity_score": 196.84230341929117,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/954.png",
            "scheme": "https"
        }
    },
    "701": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #701",
                "description": "description",
                "dna": "2887d0e600343fe04af789616192b023a58e9fa2",
                "date": 1683437368033,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "701",
                "rarity_score": 136.31423466107623,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/701.png",
            "scheme": "https"
        }
    },
    "1671": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1671",
                "description": "description",
                "dna": "ebd57b63de82be269eb946233ff8707af2f6caf5",
                "date": 1683433524658,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1671",
                "rarity_score": 185.2065861437694,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1671.png",
            "scheme": "https"
        }
    },
    "2242": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2242",
                "description": "description",
                "dna": "e77e3da8db538bd93bf1c13412825f1d7c038e58",
                "date": 1683446004340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2242",
                "rarity_score": 211.74487037552208,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2242.png",
            "scheme": "https"
        }
    },
    "168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #168",
                "description": "description",
                "dna": "bc09a6e9e2ca79c70603a23d9e8baedbdea55369",
                "date": 1683430239551,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "168",
                "rarity_score": 168.97517879461398,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/168.png",
            "scheme": "https"
        }
    },
    "1163": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1163",
                "description": "description",
                "dna": "f6bfafef1d19a1ba34339fc8e0499a40bab4827a",
                "date": 1683444480664,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1163",
                "rarity_score": 190.20293426600477,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1163.png",
            "scheme": "https"
        }
    },
    "2363": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2363",
                "description": "description",
                "dna": "46575aed122a094204e304424d80f4c236306a9a",
                "date": 1683437137352,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2363",
                "rarity_score": 128.53295529050624,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2363.png",
            "scheme": "https"
        }
    },
    "3527": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3527",
                "description": "description",
                "dna": "ce41c5683136560d6b93284f927b0f4699c258ca",
                "date": 1683429491691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3527",
                "rarity_score": 305.35009511005904,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3527.png",
            "scheme": "https"
        }
    },
    "3092": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3092",
                "description": "description",
                "dna": "0b8af6b030a50917cf75805d1e033d61f05ebd36",
                "date": 1683446755885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3092",
                "rarity_score": 175.75839832175572,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3092.png",
            "scheme": "https"
        }
    },
    "659": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #659",
                "description": "description",
                "dna": "c336ef0acb3e68c16376b8c2dc6b3fdcb1e70527",
                "date": 1683447299344,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "659",
                "rarity_score": 194.96291004845472,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/659.png",
            "scheme": "https"
        }
    },
    "2709": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2709",
                "description": "description",
                "dna": "d6b0e132b36b18a527cf1417329b8aecb8ba9bc1",
                "date": 1683434375910,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2709",
                "rarity_score": 252.49248757048034,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2709.png",
            "scheme": "https"
        }
    },
    "1418": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1418",
                "description": "description",
                "dna": "194e6a96ba441d005eb2499e4d8e40c810c00be5",
                "date": 1683434484475,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1418",
                "rarity_score": 163.34196881426377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1418.png",
            "scheme": "https"
        }
    },
    "1804": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1804",
                "description": "description",
                "dna": "bd0d51aa86305128beadb475db7e33eced80d0a5",
                "date": 1683430212969,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1804",
                "rarity_score": 174.98412720095794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1804.png",
            "scheme": "https"
        }
    },
    "1454": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1454",
                "description": "description",
                "dna": "1a9077a9f9fcc8c506afd69aaa1b56d71d67af58",
                "date": 1683429913157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1454",
                "rarity_score": 206.17654552627582,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1454.png",
            "scheme": "https"
        }
    },
    "1700": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1700",
                "description": "description",
                "dna": "6dbbc7afad78c89e6cf25ed4968f08365efbbbd5",
                "date": 1683443413175,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1700",
                "rarity_score": 167.04467114376456,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1700.png",
            "scheme": "https"
        }
    },
    "4054": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4054",
                "description": "description",
                "dna": "985976850605835fd8eff3f5bd7804df676f0ee4",
                "date": 1683436922768,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "4054",
                "rarity_score": 183.87638491720708,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4054.png",
            "scheme": "https"
        }
    },
    "1458": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1458",
                "description": "description",
                "dna": "48c94087a7dff4f2167292736cf7ea148a3137cd",
                "date": 1683436483061,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1458",
                "rarity_score": 197.1192285877155,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1458.png",
            "scheme": "https"
        }
    },
    "3268": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3268",
                "description": "description",
                "dna": "e14327e3597ca75ef6ecfbe9abf7c0fa54558eab",
                "date": 1683434015094,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3268",
                "rarity_score": 155.90392022145488,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3268.png",
            "scheme": "https"
        }
    },
    "3015": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3015",
                "description": "description",
                "dna": "e53c53ad6ce923332d11dbc407a429da4c80c4b1",
                "date": 1683434871359,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3015",
                "rarity_score": 176.0759079948192,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3015.png",
            "scheme": "https"
        }
    },
    "2181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2181",
                "description": "description",
                "dna": "feb625df9b019e5adbe4ce88766d3bde6745f1a4",
                "date": 1683438126081,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2181",
                "rarity_score": 171.1733648828308,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2181.png",
            "scheme": "https"
        }
    },
    "348": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #348",
                "description": "description",
                "dna": "44d063ae936788e54a4e0c733f9dc2668ce10db2",
                "date": 1683775021014,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "348",
                "rarity_score": 161.90759442624915,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/348.png",
            "scheme": "https"
        }
    },
    "1911": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1911",
                "description": "description",
                "dna": "72854d0f0eb8495c0ecd91f54a1fafdd3016e343",
                "date": 1683443371017,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1911",
                "rarity_score": 241.84616526743707,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1911.png",
            "scheme": "https"
        }
    },
    "2051": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2051",
                "description": "description",
                "dna": "da6022ce7d93f81d66aa2d78e6d8b9a499eeacc2",
                "date": 1683431457997,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2051",
                "rarity_score": 203.75660128236296,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2051.png",
            "scheme": "https"
        }
    },
    "1070": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1070",
                "description": "description",
                "dna": "f75ee20d4b9d667673bbde9265f21aaaf982f2bf",
                "date": 1683441962350,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1070",
                "rarity_score": 244.00670366083472,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1070.png",
            "scheme": "https"
        }
    },
    "1988": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1988",
                "description": "description",
                "dna": "8c446537268744ff314450b5f2480b0f422f8801",
                "date": 1683447014879,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1988",
                "rarity_score": 338.86518715298445,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1988.png",
            "scheme": "https"
        }
    },
    "4099": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4099",
                "description": "description",
                "dna": "4ca73ca90c792d5e8b029f1611db41e4b2029bc4",
                "date": 1683430424045,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "4099",
                "rarity_score": 206.4904990477537,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4099.png",
            "scheme": "https"
        }
    },
    "3635": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3635",
                "description": "description",
                "dna": "b96f408acaa8eadff4177611294bc802981c4908",
                "date": 1683438386610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3635",
                "rarity_score": 210.71396530299762,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3635.png",
            "scheme": "https"
        }
    },
    "3487": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3487",
                "description": "description",
                "dna": "73c16d9037b07c2c0270dbd500d1cbb6873ab6dd",
                "date": 1683445379240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3487",
                "rarity_score": 182.70787450886218,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3487.png",
            "scheme": "https"
        }
    },
    "3278": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3278",
                "description": "description",
                "dna": "9ce413f5e6f13d141b43bfab2b15da9409a763e9",
                "date": 1683444476214,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3278",
                "rarity_score": 227.15977308463283,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3278.png",
            "scheme": "https"
        }
    },
    "3598": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3598",
                "description": "description",
                "dna": "f31f913afc7ea6c6d844880fc84f032ae31d71a6",
                "date": 1683438196543,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3598",
                "rarity_score": 230.74862921536354,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3598.png",
            "scheme": "https"
        }
    },
    "2018": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2018",
                "description": "description",
                "dna": "e894b714cb0f55ca7bb1acdd6bc7b3768e13949d",
                "date": 1683774632939,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2018",
                "rarity_score": 167.8433666246589,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2018.png",
            "scheme": "https"
        }
    },
    "30": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #30",
                "description": "description",
                "dna": "30366ec39c047bb087e638ed812c94e78807a350",
                "date": 1683431293064,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "30",
                "rarity_score": 184.255327669005,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/30.png",
            "scheme": "https"
        }
    },
    "1712": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1712",
                "description": "description",
                "dna": "57f5b0d4686e09cc5e166d6787b06b50945a682b",
                "date": 1683443735749,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1712",
                "rarity_score": 193.09296672125595,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1712.png",
            "scheme": "https"
        }
    },
    "2483": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2483",
                "description": "description",
                "dna": "2282842841c6432700ea97d6c1f65e9a328cecd4",
                "date": 1683440931340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2483",
                "rarity_score": 236.0354140709488,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2483.png",
            "scheme": "https"
        }
    },
    "2839": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2839",
                "description": "description",
                "dna": "a110d24abe9b28df6d8fb3a9f36d9f9e9d8bcb90",
                "date": 1683445486346,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2839",
                "rarity_score": 242.4109452272627,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2839.png",
            "scheme": "https"
        }
    },
    "3100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3100",
                "description": "description",
                "dna": "1a54cafcafee52a4881eaeaf6dd58105573257d1",
                "date": 1683771345436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3100",
                "rarity_score": 180.1510589323512,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3100.png",
            "scheme": "https"
        }
    },
    "2004": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2004",
                "description": "description",
                "dna": "552b7009a96be80580e4fc9bb40b3125e893b67f",
                "date": 1683771509348,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2004",
                "rarity_score": 313.3771239558525,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2004.png",
            "scheme": "https"
        }
    },
    "3437": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3437",
                "description": "description",
                "dna": "25d3b332b6ef817516face45bed15b1a07db9537",
                "date": 1683771285808,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3437",
                "rarity_score": 215.90440789871172,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3437.png",
            "scheme": "https"
        }
    },
    "3115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3115",
                "description": "description",
                "dna": "cd1680b5bd8582468ceb062e43cb916db3686d42",
                "date": 1683438381902,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3115",
                "rarity_score": 252.94970570088134,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3115.png",
            "scheme": "https"
        }
    },
    "1829": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1829",
                "description": "description",
                "dna": "27d753c760c94bd7d94d51383d0df6b303598a2b",
                "date": 1683444845870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1829",
                "rarity_score": 162.19219934121398,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1829.png",
            "scheme": "https"
        }
    },
    "435": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #435",
                "description": "description",
                "dna": "c68163bfb44d21ebcc277d40a6908efaed7a4314",
                "date": 1683443591751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "435",
                "rarity_score": 194.35474021578526,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/435.png",
            "scheme": "https"
        }
    },
    "2639": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2639",
                "description": "description",
                "dna": "8fb414f2ccdfe21a56d48c0e56b65d1ac3b819ed",
                "date": 1683435401918,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2639",
                "rarity_score": 176.37337151819168,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2639.png",
            "scheme": "https"
        }
    },
    "395": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #395",
                "description": "description",
                "dna": "d32984236b8990efe115791d046a7b8af241e73f",
                "date": 1683431573220,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "395",
                "rarity_score": 277.8365273845284,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/395.png",
            "scheme": "https"
        }
    },
    "2296": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2296",
                "description": "description",
                "dna": "bba17f1a14aea69f9b279aa709ecf6396f24d437",
                "date": 1683437923456,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2296",
                "rarity_score": 277.7201514367615,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2296.png",
            "scheme": "https"
        }
    },
    "3009": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3009",
                "description": "description",
                "dna": "e9d928923c6190a3993722877a73f8173f8e4313",
                "date": 1683443940980,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3009",
                "rarity_score": 195.272675089477,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3009.png",
            "scheme": "https"
        }
    },
    "3652": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3652",
                "description": "description",
                "dna": "0481511f47399fa241e4448a9262066fad4357c5",
                "date": 1683775258356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3652",
                "rarity_score": 185.4812783355444,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3652.png",
            "scheme": "https"
        }
    },
    "3941": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3941",
                "description": "description",
                "dna": "5630908dc1f877c6f55ff59dee78ced832ca0525",
                "date": 1683430799245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3941",
                "rarity_score": 153.1867562718274,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3941.png",
            "scheme": "https"
        }
    },
    "4095": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4095",
                "description": "description",
                "dna": "ccd1cae866b93f1ffdd63550bf0b704a5b4d3232",
                "date": 1683447263504,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "4095",
                "rarity_score": 218.33317256493336,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4095.png",
            "scheme": "https"
        }
    },
    "328": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #328",
                "description": "description",
                "dna": "3088b3f8c138588616f915248b641eada097ecaf",
                "date": 1683444449918,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "328",
                "rarity_score": 173.96144913730583,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/328.png",
            "scheme": "https"
        }
    },
    "1326": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1326",
                "description": "description",
                "dna": "6904bda50c0fff4bc8be4a75f9ddf63b046a1b4e",
                "date": 1683436832847,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1326",
                "rarity_score": 169.74442918879075,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1326.png",
            "scheme": "https"
        }
    },
    "2409": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2409",
                "description": "description",
                "dna": "6bfed8ec5782d6c23ec3ec55fa27b214041a63b2",
                "date": 1683771259953,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2409",
                "rarity_score": 218.44786383446564,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2409.png",
            "scheme": "https"
        }
    },
    "2951": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2951",
                "description": "description",
                "dna": "75fd9232b5e75caccd397a391858fedf28adc228",
                "date": 1683434730303,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2951",
                "rarity_score": 170.06102813723143,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2951.png",
            "scheme": "https"
        }
    },
    "213": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #213",
                "description": "description",
                "dna": "925d9f56d5f4240c2dfdd330d8a1c8d49fffb85a",
                "date": 1683430191250,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "213",
                "rarity_score": 159.94320903607587,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/213.png",
            "scheme": "https"
        }
    },
    "111": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #111",
                "description": "description",
                "dna": "4fca98209d2797c482711041621a828f98202099",
                "date": 1683446352262,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "111",
                "rarity_score": 130.7083156571366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/111.png",
            "scheme": "https"
        }
    },
    "1806": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1806",
                "description": "description",
                "dna": "173387f58fb6a4aa2900a7ce6bd2fba9f46e1ab0",
                "date": 1683445745459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1806",
                "rarity_score": 213.24152827874138,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1806.png",
            "scheme": "https"
        }
    },
    "396": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #396",
                "description": "description",
                "dna": "2256c90cbe1ea7404e5bcad21eeb464073fd210c",
                "date": 1683775025532,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "396",
                "rarity_score": 223.14690008304655,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/396.png",
            "scheme": "https"
        }
    },
    "1572": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1572",
                "description": "description",
                "dna": "bfc18a9158aa8dbb91120d6b80bd85563d2a0d28",
                "date": 1683433519605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1572",
                "rarity_score": 227.78714217985322,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1572.png",
            "scheme": "https"
        }
    },
    "344": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #344",
                "description": "description",
                "dna": "07f4b10e02c2f8661b9a2f2a059a245bed99d2db",
                "date": 1683438040607,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "344",
                "rarity_score": 195.03136403311248,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/344.png",
            "scheme": "https"
        }
    },
    "1668": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1668",
                "description": "description",
                "dna": "06c7debf9fd02e54d248e0ecac695ca751d4ca25",
                "date": 1683441462061,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1668",
                "rarity_score": 156.71141075078378,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1668.png",
            "scheme": "https"
        }
    },
    "2691": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2691",
                "description": "description",
                "dna": "3a4575aee9c2dcb0e20319ad3f461aaebf3eb673",
                "date": 1683442132136,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2691",
                "rarity_score": 152.37287047357157,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2691.png",
            "scheme": "https"
        }
    },
    "3999": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3999",
                "description": "description",
                "dna": "4ce4d331c81f5b9c601fea43a12b2dad7d87e6d4",
                "date": 1683774421808,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3999",
                "rarity_score": 210.93894866411944,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3999.png",
            "scheme": "https"
        }
    },
    "3469": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3469",
                "description": "description",
                "dna": "899f69599b4dcf0b132e62c01b71060316584d75",
                "date": 1683437871765,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3469",
                "rarity_score": 186.97067623024918,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3469.png",
            "scheme": "https"
        }
    },
    "3372": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3372",
                "description": "description",
                "dna": "9b5083b25a1e719325d44a35462e5398c1be5b4c",
                "date": 1683435606772,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3372",
                "rarity_score": 211.9381459995971,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3372.png",
            "scheme": "https"
        }
    },
    "1026": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1026",
                "description": "description",
                "dna": "9d6d86deeb1066f1e7442a7186d9324c4027eb3e",
                "date": 1683441930914,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1026",
                "rarity_score": 174.8281735531489,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1026.png",
            "scheme": "https"
        }
    },
    "704": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #704",
                "description": "description",
                "dna": "e99dc6dcaee699bb56f6aceb211ae166f420e2e7",
                "date": 1683440206430,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "704",
                "rarity_score": 138.99627195740894,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/704.png",
            "scheme": "https"
        }
    },
    "2196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2196",
                "description": "description",
                "dna": "a10204ca7d64f57eec245df13494e62c11cd8bb9",
                "date": 1683430107752,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2196",
                "rarity_score": 231.78528507216208,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2196.png",
            "scheme": "https"
        }
    },
    "4079": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4079",
                "description": "description",
                "dna": "e2f402f1f4cb9841eaa0cd9be17002236f245510",
                "date": 1683429860983,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "4079",
                "rarity_score": 200.0366350605252,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4079.png",
            "scheme": "https"
        }
    },
    "1847": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1847",
                "description": "description",
                "dna": "09319a91c77a6a935d6335d2345488017b08b226",
                "date": 1683436317057,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1847",
                "rarity_score": 193.26039604699275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1847.png",
            "scheme": "https"
        }
    },
    "71": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #71",
                "description": "description",
                "dna": "5d81453ea227063c81bfdd1306231c5c234170bd",
                "date": 1683433163344,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "71",
                "rarity_score": 283.64863598010623,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/71.png",
            "scheme": "https"
        }
    },
    "16": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #16",
                "description": "description",
                "dna": "1715d81b973b068a3ab52451de64e0e8cb36c0f7",
                "date": 1683444785084,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "16",
                "rarity_score": 172.7382153449397,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/16.png",
            "scheme": "https"
        }
    },
    "3423": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3423",
                "description": "description",
                "dna": "edcc67ed4b0245371dffd060b326a36d50e9bd02",
                "date": 1683435635267,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3423",
                "rarity_score": 216.1039347602072,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3423.png",
            "scheme": "https"
        }
    },
    "1314": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1314",
                "description": "description",
                "dna": "bc325a684413680026d2136883412ebcfb2e4511",
                "date": 1683442485698,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1314",
                "rarity_score": 170.6526753871201,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1314.png",
            "scheme": "https"
        }
    },
    "437": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #437",
                "description": "description",
                "dna": "80da219ad3cd8e4cb32070f5105ada68e95b2017",
                "date": 1683445683157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "437",
                "rarity_score": 284.22233227510367,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/437.png",
            "scheme": "https"
        }
    },
    "2822": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2822",
                "description": "description",
                "dna": "0cdb724a44ecf108063234024a31db799acc6cd2",
                "date": 1683442345861,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2822",
                "rarity_score": 194.15972973147817,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2822.png",
            "scheme": "https"
        }
    },
    "2586": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2586",
                "description": "description",
                "dna": "c2ccff3ee24f512f5faae067af018c38357ff0e2",
                "date": 1683438816713,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2586",
                "rarity_score": 169.75148151116053,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2586.png",
            "scheme": "https"
        }
    },
    "3306": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3306",
                "description": "description",
                "dna": "be5e10f6acaab565a891d3dc5f1988092bf49c52",
                "date": 1683433375885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3306",
                "rarity_score": 219.9212560419932,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3306.png",
            "scheme": "https"
        }
    },
    "661": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #661",
                "description": "description",
                "dna": "ef75d7252d3e5885b062112f5b0ad9d25f8b0e53",
                "date": 1683440569184,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "661",
                "rarity_score": 201.90685363448338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/661.png",
            "scheme": "https"
        }
    },
    "2373": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2373",
                "description": "description",
                "dna": "f56bda3f1a7ebe8b74aff8384ff61958a8d3bdab",
                "date": 1683435949750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2373",
                "rarity_score": 165.94039753739068,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2373.png",
            "scheme": "https"
        }
    },
    "3634": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3634",
                "description": "description",
                "dna": "a8b5c13c6f364e0e7c2175da9e5cebabcd508f5f",
                "date": 1683444352639,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3634",
                "rarity_score": 178.01475754249236,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3634.png",
            "scheme": "https"
        }
    },
    "3683": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3683",
                "description": "description",
                "dna": "8a800f9e1e1802ce96025b169a69c06c4470a1c8",
                "date": 1683446721658,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3683",
                "rarity_score": 175.2959331405578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3683.png",
            "scheme": "https"
        }
    },
    "1368": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1368",
                "description": "description",
                "dna": "4603cd1e7a248dc340a7fbbb3f3a449a057ec724",
                "date": 1683776396215,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1368",
                "rarity_score": 261.9809504429264,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1368.png",
            "scheme": "https"
        }
    },
    "1494": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1494",
                "description": "description",
                "dna": "00bf9cf1af5127620fe0b5c78d880a191257d813",
                "date": 1683443678007,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1494",
                "rarity_score": 203.7802285141019,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1494.png",
            "scheme": "https"
        }
    },
    "1467": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1467",
                "description": "description",
                "dna": "82facaea8cba1e17168a51d91c7e3b00a721f2ea",
                "date": 1683443487412,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1467",
                "rarity_score": 230.79505990863498,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1467.png",
            "scheme": "https"
        }
    },
    "403": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #403",
                "description": "description",
                "dna": "43f03b28fc1b9cfd3d1d0b031d435265bbd5cd7d",
                "date": 1683435818401,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "403",
                "rarity_score": 252.78714241709216,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/403.png",
            "scheme": "https"
        }
    },
    "546": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #546",
                "description": "description",
                "dna": "c12d3b745bc172e9d765e34d46389d2a02db17dc",
                "date": 1683445361817,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "546",
                "rarity_score": 248.29959858394528,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/546.png",
            "scheme": "https"
        }
    },
    "1803": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1803",
                "description": "description",
                "dna": "2f3dd8dc649231425e12c74725f1838460fc89e7",
                "date": 1683446074341,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1803",
                "rarity_score": 198.49878524787925,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1803.png",
            "scheme": "https"
        }
    },
    "1876": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1876",
                "description": "description",
                "dna": "6caac45bc2a11c1e12a454b2d59530d2da6d42bd",
                "date": 1683430997881,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1876",
                "rarity_score": 258.5373680377755,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1876.png",
            "scheme": "https"
        }
    },
    "2819": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2819",
                "description": "description",
                "dna": "22cb4ca4b4dcfb891cca8184268118daf431f5db",
                "date": 1683445503992,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2819",
                "rarity_score": 205.35546102658924,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2819.png",
            "scheme": "https"
        }
    },
    "1382": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1382",
                "description": "description",
                "dna": "83b9b263a9952e8cd03d89f11a8cba0f99cc37a1",
                "date": 1683440978206,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1382",
                "rarity_score": 205.83117438675907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1382.png",
            "scheme": "https"
        }
    },
    "227": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #227",
                "description": "description",
                "dna": "4bbb0dad82554812ddb3941180b522c928d6a40f",
                "date": 1683434171978,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "227",
                "rarity_score": 174.32120323947015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/227.png",
            "scheme": "https"
        }
    },
    "1696": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1696",
                "description": "description",
                "dna": "171b560bf8bbda410a179d4d54e5e4bf910055dc",
                "date": 1683436389144,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1696",
                "rarity_score": 224.3617465515027,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1696.png",
            "scheme": "https"
        }
    },
    "1202": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1202",
                "description": "description",
                "dna": "24968e2226a694be68c7cb11ecdf9a1aee1f5448",
                "date": 1683438834949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1202",
                "rarity_score": 191.82373411425107,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1202.png",
            "scheme": "https"
        }
    },
    "3308": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3308",
                "description": "description",
                "dna": "af6239124c874bcf82508af464ea64d0588920ad",
                "date": 1683432662460,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3308",
                "rarity_score": 241.20321962843803,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3308.png",
            "scheme": "https"
        }
    },
    "1049": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1049",
                "description": "description",
                "dna": "a4b7b19d2b9e96902eea799a8a0f7a214e256d97",
                "date": 1683437486365,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1049",
                "rarity_score": 160.88766453691397,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1049.png",
            "scheme": "https"
        }
    },
    "4057": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4057",
                "description": "description",
                "dna": "d6fa9d7d7ae967cc6533a96dd6a50ec5e6e3f4ff",
                "date": 1683436679422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4057",
                "rarity_score": 213.55786824375588,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4057.png",
            "scheme": "https"
        }
    },
    "1128": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1128",
                "description": "description",
                "dna": "d9a9809658afc5517e78e3e9b987219003811af5",
                "date": 1683434548102,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1128",
                "rarity_score": 204.08354080099684,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1128.png",
            "scheme": "https"
        }
    },
    "1608": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1608",
                "description": "description",
                "dna": "a2fd23e57a59033ab4a785ccdf2a79ede074dd69",
                "date": 1683438205884,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1608",
                "rarity_score": 221.00590068214683,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1608.png",
            "scheme": "https"
        }
    },
    "4092": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4092",
                "description": "description",
                "dna": "164bf0089251af2b58fc9ca2af1a86ec75ae2cb7",
                "date": 1683444085989,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "4092",
                "rarity_score": 171.6204392285296,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4092.png",
            "scheme": "https"
        }
    },
    "2244": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2244",
                "description": "description",
                "dna": "456574a12f1e8edb89691ed9220472bd4393a6b9",
                "date": 1683447397953,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2244",
                "rarity_score": 194.17547604215866,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2244.png",
            "scheme": "https"
        }
    },
    "1450": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1450",
                "description": "description",
                "dna": "0808dd5a87684b6283c0ec58a9f5aa43078b7e86",
                "date": 1683436982655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1450",
                "rarity_score": 127.21550033229931,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1450.png",
            "scheme": "https"
        }
    },
    "2904": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2904",
                "description": "description",
                "dna": "eed5cfd44998342f8b618101b11ae88286f6fc91",
                "date": 1683774700324,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2904",
                "rarity_score": 197.89432408077343,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2904.png",
            "scheme": "https"
        }
    },
    "351": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #351",
                "description": "description",
                "dna": "69d0bf62ae808b3d8528ccede2ad6ec168c2809a",
                "date": 1683435410342,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "351",
                "rarity_score": 208.34500823306362,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/351.png",
            "scheme": "https"
        }
    },
    "2682": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2682",
                "description": "description",
                "dna": "af458e9a143d910e881cf4687f27948a37044b6b",
                "date": 1683441903733,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2682",
                "rarity_score": 223.7594924246324,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2682.png",
            "scheme": "https"
        }
    },
    "2689": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2689",
                "description": "description",
                "dna": "4fae2e8cd9a6197cabbce87f15731067280ad501",
                "date": 1683440107440,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2689",
                "rarity_score": 184.70654482164676,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2689.png",
            "scheme": "https"
        }
    },
    "1688": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1688",
                "description": "description",
                "dna": "7ed08b46537b2a27592ce8642b7b203c9a43ab89",
                "date": 1683430061994,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1688",
                "rarity_score": 176.67193289587755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1688.png",
            "scheme": "https"
        }
    },
    "1694": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1694",
                "description": "description",
                "dna": "5d9e409dd4729b3a081257457f3adbbc90cb6f18",
                "date": 1683432572033,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1694",
                "rarity_score": 162.80108903754876,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1694.png",
            "scheme": "https"
        }
    },
    "3869": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3869",
                "description": "description",
                "dna": "9cf3f1b3e9960954dab3c312baca780c55234cfa",
                "date": 1683444863426,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3869",
                "rarity_score": 216.65458289617567,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3869.png",
            "scheme": "https"
        }
    },
    "3101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3101",
                "description": "description",
                "dna": "e42693aa9a80275f939dcbf92b15d77cc0e8882f",
                "date": 1683432576430,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3101",
                "rarity_score": 176.63540898862425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3101.png",
            "scheme": "https"
        }
    },
    "421": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #421",
                "description": "description",
                "dna": "2b6425b0d304aa2ed64ba16c5c07798732a1b5fd",
                "date": 1683771226221,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "421",
                "rarity_score": 222.1605042887252,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/421.png",
            "scheme": "https"
        }
    },
    "3337": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3337",
                "description": "description",
                "dna": "972177a59f4748d71280a12cac25754320cc1f97",
                "date": 1683441275779,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3337",
                "rarity_score": 225.9305650053448,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3337.png",
            "scheme": "https"
        }
    },
    "3619": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3619",
                "description": "description",
                "dna": "6d007d8b8f9255cf56b3225cfbb6186e32a7b1ca",
                "date": 1683442803926,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3619",
                "rarity_score": 236.26244002526462,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3619.png",
            "scheme": "https"
        }
    },
    "3058": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3058",
                "description": "description",
                "dna": "da649efd448647d2c4eb4e012d191a35ea62d188",
                "date": 1683434840987,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3058",
                "rarity_score": 203.5643625015949,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3058.png",
            "scheme": "https"
        }
    },
    "1243": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1243",
                "description": "description",
                "dna": "1f58ffe9ea42db01525e18d3a652c3b18c995a0e",
                "date": 1683774525282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1243",
                "rarity_score": 163.3654274454662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1243.png",
            "scheme": "https"
        }
    },
    "2478": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2478",
                "description": "description",
                "dna": "96762479bec3300763d61d9700b196488d327122",
                "date": 1683440440814,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2478",
                "rarity_score": 317.1891687565388,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2478.png",
            "scheme": "https"
        }
    },
    "684": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #684",
                "description": "description",
                "dna": "e509a7b933b65de0c8370f3db73ac310f0607598",
                "date": 1683446144001,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "684",
                "rarity_score": 161.15876922621612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/684.png",
            "scheme": "https"
        }
    },
    "1190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1190",
                "description": "description",
                "dna": "50c428cbe34fdc64dd686abb47b673e351590283",
                "date": 1683433583564,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1190",
                "rarity_score": 234.10366947281636,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1190.png",
            "scheme": "https"
        }
    },
    "2132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2132",
                "description": "description",
                "dna": "6aae42ffe45a6eb0c51c5c5e1241a684caadabd8",
                "date": 1683774610422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2132",
                "rarity_score": 219.10878938799797,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2132.png",
            "scheme": "https"
        }
    },
    "888": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #888",
                "description": "description",
                "dna": "15cb17305acde8405da301b8d6a2bd35d3bf517d",
                "date": 1683434084006,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "888",
                "rarity_score": 273.3174989199076,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/888.png",
            "scheme": "https"
        }
    },
    "2402": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2402",
                "description": "description",
                "dna": "4f887d0ae0c53477b244b2915b5c975fbdefd543",
                "date": 1683432074139,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2402",
                "rarity_score": 218.2229361902131,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2402.png",
            "scheme": "https"
        }
    },
    "1943": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1943",
                "description": "description",
                "dna": "bae227449d08e185fedda677a30150bba5663bf6",
                "date": 1683434629700,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1943",
                "rarity_score": 165.54230718993173,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1943.png",
            "scheme": "https"
        }
    },
    "1562": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1562",
                "description": "description",
                "dna": "0d9735b3e978b15ef9cb3fff6ee3d277abab5366",
                "date": 1683447111191,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1562",
                "rarity_score": 170.7892348381517,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1562.png",
            "scheme": "https"
        }
    },
    "3581": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3581",
                "description": "description",
                "dna": "4125d1800d21855a5676616f1ad64540c27483cf",
                "date": 1683431038144,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3581",
                "rarity_score": 229.04449943616012,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3581.png",
            "scheme": "https"
        }
    },
    "2956": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2956",
                "description": "description",
                "dna": "c0f1dd9e9c8925bd50cfd2aba6b5ece3ae471a0a",
                "date": 1683437408844,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2956",
                "rarity_score": 126.6128413498402,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2956.png",
            "scheme": "https"
        }
    },
    "1472": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1472",
                "description": "description",
                "dna": "4d82d168694a5301b9f8b460cb058aec19cad0d2",
                "date": 1683433533582,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1472",
                "rarity_score": 238.62868419550512,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1472.png",
            "scheme": "https"
        }
    },
    "789": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #789",
                "description": "description",
                "dna": "d5f99281aa7974cb11f308cff260561211303afb",
                "date": 1683432326463,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "789",
                "rarity_score": 176.8918295929052,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/789.png",
            "scheme": "https"
        }
    },
    "3166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3166",
                "description": "description",
                "dna": "c31076894f97459ec7b6c37ef5aec5867bad3d46",
                "date": 1683433159045,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3166",
                "rarity_score": 298.6347615438223,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3166.png",
            "scheme": "https"
        }
    },
    "242": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #242",
                "description": "description",
                "dna": "bb100b0c35823a74811d720b72b8f90d28bac5a4",
                "date": 1683446632836,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "242",
                "rarity_score": 153.09703627621298,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/242.png",
            "scheme": "https"
        }
    },
    "2507": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2507",
                "description": "description",
                "dna": "53ed416636b90caa9b6d1cf0b109292f5612531f",
                "date": 1683440950089,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2507",
                "rarity_score": 195.603849922561,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2507.png",
            "scheme": "https"
        }
    },
    "861": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #861",
                "description": "description",
                "dna": "9a5d5ce17aacb67fcbfa8a2ffb1c0b726655ea5d",
                "date": 1683439013800,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "861",
                "rarity_score": 275.10750758650954,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/861.png",
            "scheme": "https"
        }
    },
    "1084": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1084",
                "description": "description",
                "dna": "bc8e12baa5e541677970386a7027238ed58f4380",
                "date": 1683443537558,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1084",
                "rarity_score": 191.743195583243,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1084.png",
            "scheme": "https"
        }
    },
    "2278": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2278",
                "description": "description",
                "dna": "83cf0ee689029ed81ee03cbeca89c1821c2a3649",
                "date": 1683438958495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2278",
                "rarity_score": 198.50181387884217,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2278.png",
            "scheme": "https"
        }
    },
    "1313": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1313",
                "description": "description",
                "dna": "64a3d8040b5ae33e3a36af50fec4380fceb6aa71",
                "date": 1683440494813,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1313",
                "rarity_score": 171.89140863327208,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1313.png",
            "scheme": "https"
        }
    },
    "3477": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3477",
                "description": "description",
                "dna": "aa2a29cbdd2cc43c50896a74d71c18b7ad8fe271",
                "date": 1683431643369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3477",
                "rarity_score": 197.01354270874123,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3477.png",
            "scheme": "https"
        }
    },
    "490": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #490",
                "description": "description",
                "dna": "e2ded046cb31687a9cce9f6129e2d14719a449ca",
                "date": 1683434154569,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "490",
                "rarity_score": 241.0454133729349,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/490.png",
            "scheme": "https"
        }
    },
    "802": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #802",
                "description": "description",
                "dna": "fa38f5223929e6f324d50bb9ed08c0cfaa48145c",
                "date": 1683447259369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "802",
                "rarity_score": 171.40667566332314,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/802.png",
            "scheme": "https"
        }
    },
    "2345": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2345",
                "description": "description",
                "dna": "e5217be509f77ca01253eb27660cf58fb1a64387",
                "date": 1683445278893,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2345",
                "rarity_score": 312.6259048936948,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2345.png",
            "scheme": "https"
        }
    },
    "3539": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3539",
                "description": "description",
                "dna": "b4641e7fe6d8b6778d032f4a7acd05edf9dac614",
                "date": 1683430048066,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3539",
                "rarity_score": 210.9954607557841,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3539.png",
            "scheme": "https"
        }
    },
    "3677": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3677",
                "description": "description",
                "dna": "1b28f6f563aee701b968b3a386a7133d4ecced81",
                "date": 1683441061904,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3677",
                "rarity_score": 206.85713966503795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3677.png",
            "scheme": "https"
        }
    },
    "2708": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2708",
                "description": "description",
                "dna": "e3e397523984ef794bf1cfd893f2f0cff1821933",
                "date": 1683433735886,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2708",
                "rarity_score": 245.87504571811104,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2708.png",
            "scheme": "https"
        }
    },
    "752": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #752",
                "description": "description",
                "dna": "8b5055cf1730f2661357b9a9e1904b54b8e7f7c2",
                "date": 1683434923844,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "752",
                "rarity_score": 174.64461165198497,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/752.png",
            "scheme": "https"
        }
    },
    "492": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #492",
                "description": "description",
                "dna": "eec39eceb96db7e63e5e6e5aef364e037640cdc4",
                "date": 1683439165163,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "492",
                "rarity_score": 172.39125809085746,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/492.png",
            "scheme": "https"
        }
    },
    "2551": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2551",
                "description": "description",
                "dna": "c99b1f0876d8044065a041646a9aa7d1d0562b3d",
                "date": 1683435643785,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2551",
                "rarity_score": 156.57207336908525,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2551.png",
            "scheme": "https"
        }
    },
    "646": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #646",
                "description": "description",
                "dna": "d9de5dc1b2aa1888e4e0c863b7d12dbac460f7e9",
                "date": 1683430365781,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "646",
                "rarity_score": 195.2571116251578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/646.png",
            "scheme": "https"
        }
    },
    "1754": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1754",
                "description": "description",
                "dna": "98d91c84ad9b90fd4da48c9a76641514244b395c",
                "date": 1683446612074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1754",
                "rarity_score": 247.1613853319026,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1754.png",
            "scheme": "https"
        }
    },
    "876": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #876",
                "description": "description",
                "dna": "dcaa5dfc9f409f68ae42eac0abe30e0b93ce3fa4",
                "date": 1683437281313,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "876",
                "rarity_score": 123.82611573975608,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/876.png",
            "scheme": "https"
        }
    },
    "268": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #268",
                "description": "description",
                "dna": "16ac772f8a6871a842e5ec4c70bf9bf84b7cb807",
                "date": 1683445723371,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "268",
                "rarity_score": 217.1267677132902,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/268.png",
            "scheme": "https"
        }
    },
    "231": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #231",
                "description": "description",
                "dna": "c560c0c4d829362d92863f81c55ea431140bd974",
                "date": 1683439286309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "231",
                "rarity_score": 240.0403450673384,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/231.png",
            "scheme": "https"
        }
    },
    "933": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #933",
                "description": "description",
                "dna": "b9340f85fc15ef63a5254915490cbbb06b9a584d",
                "date": 1683776350365,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "933",
                "rarity_score": 184.2651349571561,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/933.png",
            "scheme": "https"
        }
    },
    "1303": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1303",
                "description": "description",
                "dna": "1231276f11eae104785ad35ca532c9a9753d407b",
                "date": 1683429327078,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1303",
                "rarity_score": 206.15990024643438,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1303.png",
            "scheme": "https"
        }
    },
    "3075": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3075",
                "description": "description",
                "dna": "46efd19e7237ac7210aa2e880e58e34cf499e99f",
                "date": 1683444242605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3075",
                "rarity_score": 146.93244903095223,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3075.png",
            "scheme": "https"
        }
    },
    "1862": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1862",
                "description": "description",
                "dna": "63e5852f95066f2b271e8e532bc5610e9f274944",
                "date": 1683443038304,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1862",
                "rarity_score": 175.48982221223528,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1862.png",
            "scheme": "https"
        }
    },
    "3784": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3784",
                "description": "description",
                "dna": "d2e3699c52e83f08a112c9bcad03da63160a74c3",
                "date": 1683436149113,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3784",
                "rarity_score": 180.71699055853287,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3784.png",
            "scheme": "https"
        }
    },
    "2757": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2757",
                "description": "description",
                "dna": "dbe95a6e4d0f1dabd3a8956c2dd85486c024c7b1",
                "date": 1683444755485,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2757",
                "rarity_score": 194.01367687135865,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2757.png",
            "scheme": "https"
        }
    },
    "3681": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3681",
                "description": "description",
                "dna": "278bf1dec25a36a2aa2686d083e6a5fb1c1c4b15",
                "date": 1683442535785,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3681",
                "rarity_score": 174.57350104531324,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3681.png",
            "scheme": "https"
        }
    },
    "1530": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1530",
                "description": "description",
                "dna": "88c80bcfae3f71609782cbe4963d132d2894565c",
                "date": 1683432317456,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1530",
                "rarity_score": 211.2932112430234,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1530.png",
            "scheme": "https"
        }
    },
    "3137": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3137",
                "description": "description",
                "dna": "3edf3bfdad1f33b1cd6b409c9638fe851b42ccce",
                "date": 1683433565109,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3137",
                "rarity_score": 184.648452979896,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3137.png",
            "scheme": "https"
        }
    },
    "2257": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2257",
                "description": "description",
                "dna": "9a2ca44412412d366f45d021aab2ff594f40fcc1",
                "date": 1683443891196,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2257",
                "rarity_score": 203.94778608260958,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2257.png",
            "scheme": "https"
        }
    },
    "2441": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2441",
                "description": "description",
                "dna": "ded27fdd2b9eaece373e3fa2d539bc3f271142e0",
                "date": 1683444828615,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2441",
                "rarity_score": 195.25584246465615,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2441.png",
            "scheme": "https"
        }
    },
    "940": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #940",
                "description": "description",
                "dna": "77932ed6c69d03c6afe6699be059d6a763a29333",
                "date": 1683443709966,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "940",
                "rarity_score": 168.0662015069116,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/940.png",
            "scheme": "https"
        }
    },
    "1708": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1708",
                "description": "description",
                "dna": "65eb2df1f8cf44449492561b243275f2100906f6",
                "date": 1683775061534,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1708",
                "rarity_score": 169.51463643505426,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1708.png",
            "scheme": "https"
        }
    },
    "1375": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1375",
                "description": "description",
                "dna": "f24f04aa6a6552652165bbdd0e2f644a216efc54",
                "date": 1683775201192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1375",
                "rarity_score": 209.82538333466624,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1375.png",
            "scheme": "https"
        }
    },
    "588": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #588",
                "description": "description",
                "dna": "8cf66162dfe63074bfa3db90665acc585e26156c",
                "date": 1683442230539,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "588",
                "rarity_score": 212.23234348849553,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/588.png",
            "scheme": "https"
        }
    },
    "132": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #132",
                "description": "description",
                "dna": "fe1bb7429688241e73e321f05f3f5d5c37d96fb8",
                "date": 1683774641790,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "132",
                "rarity_score": 172.82202285472877,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/132.png",
            "scheme": "https"
        }
    },
    "1358": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1358",
                "description": "description",
                "dna": "5d5fd0864256cd935e65ca3054b66a5ebd0189f5",
                "date": 1683435134034,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1358",
                "rarity_score": 211.49686252951267,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1358.png",
            "scheme": "https"
        }
    },
    "1051": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1051",
                "description": "description",
                "dna": "fa610f4b922c27049b687c0c4ba13beb73ddf8ac",
                "date": 1683436200396,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1051",
                "rarity_score": 297.8963836158535,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1051.png",
            "scheme": "https"
        }
    },
    "3213": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3213",
                "description": "description",
                "dna": "a0ee6cfc8b2510616359099deba78e874f5d24d5",
                "date": 1683774928755,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3213",
                "rarity_score": 272.14874864438434,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3213.png",
            "scheme": "https"
        }
    },
    "1460": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1460",
                "description": "description",
                "dna": "093e20f159cb3703bda1fca011a1a77e25145bb8",
                "date": 1683442060392,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1460",
                "rarity_score": 160.71278103048766,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1460.png",
            "scheme": "https"
        }
    },
    "2938": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2938",
                "description": "description",
                "dna": "e2a251c88f1a0bfcc54767161f74c5d942de8e00",
                "date": 1683437227556,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2938",
                "rarity_score": 121.42014355204155,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2938.png",
            "scheme": "https"
        }
    },
    "2777": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2777",
                "description": "description",
                "dna": "cb27fd6ba895464b107e06219e35bcd8814ac33c",
                "date": 1683432023264,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2777",
                "rarity_score": 270.88202081828,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2777.png",
            "scheme": "https"
        }
    },
    "1719": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1719",
                "description": "description",
                "dna": "cd79d6e088209670e711df540f27b9d5a994a43e",
                "date": 1683508630831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1719",
                "rarity_score": 413.630483912358,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1719.png",
            "scheme": "https"
        }
    },
    "554": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #554",
                "description": "description",
                "dna": "3fe069c4f0dd60ed4a35c273ecf4a1c7996bf0b8",
                "date": 1683440454667,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "554",
                "rarity_score": 166.92382335687398,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/554.png",
            "scheme": "https"
        }
    },
    "3891": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3891",
                "description": "description",
                "dna": "72f758b750b50bdd2b41ae7fc3d9081e09fb0762",
                "date": 1683445781843,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3891",
                "rarity_score": 261.50332000506853,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3891.png",
            "scheme": "https"
        }
    },
    "3037": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3037",
                "description": "description",
                "dna": "92554c18d4bd7acaa5f5bd6ceeda1452db37ec86",
                "date": 1683430512409,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3037",
                "rarity_score": 198.78202616521227,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3037.png",
            "scheme": "https"
        }
    },
    "3822": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3822",
                "description": "description",
                "dna": "2c134949b206b3fbb4944f6a79a9921b09b0d34d",
                "date": 1683435161952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3822",
                "rarity_score": 324.43105692466327,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3822.png",
            "scheme": "https"
        }
    },
    "3352": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3352",
                "description": "description",
                "dna": "243586854d4edf8302a04e93bdefcaf66f6942aa",
                "date": 1683435555354,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3352",
                "rarity_score": 256.9588377070452,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3352.png",
            "scheme": "https"
        }
    },
    "1693": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1693",
                "description": "description",
                "dna": "eb6a76c990539c5b3c2de419e947a195f0677987",
                "date": 1683442434797,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1693",
                "rarity_score": 162.08359325572735,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1693.png",
            "scheme": "https"
        }
    },
    "905": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #905",
                "description": "description",
                "dna": "e698f1e43c2e15e75ef4bfd82e7ba5af7b3b93f7",
                "date": 1683440586849,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "905",
                "rarity_score": 177.83673589071935,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/905.png",
            "scheme": "https"
        }
    },
    "512": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #512",
                "description": "description",
                "dna": "a863749a5c87eae478828ed32087133d69053e42",
                "date": 1683434906790,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "512",
                "rarity_score": 145.71445065686655,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/512.png",
            "scheme": "https"
        }
    },
    "634": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #634",
                "description": "description",
                "dna": "05cc69b29f57948ba7fb28e159d1a2fd11c1f886",
                "date": 1683771462595,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "634",
                "rarity_score": 220.12673711895835,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/634.png",
            "scheme": "https"
        }
    },
    "3489": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3489",
                "description": "description",
                "dna": "8ee52c6190adf70c807cb2c8ddc324eb8725984a",
                "date": 1683435868720,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3489",
                "rarity_score": 285.363149255868,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3489.png",
            "scheme": "https"
        }
    },
    "723": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #723",
                "description": "description",
                "dna": "e8129d91eca6d8285e80306ce46fe5c1df292198",
                "date": 1683440445628,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "723",
                "rarity_score": 177.85140825209646,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/723.png",
            "scheme": "https"
        }
    },
    "1692": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1692",
                "description": "description",
                "dna": "27b2af202a386da04c5fbdb10521f0aba924cd0e",
                "date": 1683431400200,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1692",
                "rarity_score": 197.03206177817697,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1692.png",
            "scheme": "https"
        }
    },
    "2122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2122",
                "description": "description",
                "dna": "e3e39ffc533d893073cff3db0be2b3406c1fc962",
                "date": 1683435611358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2122",
                "rarity_score": 181.4607252598836,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2122.png",
            "scheme": "https"
        }
    },
    "2728": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2728",
                "description": "description",
                "dna": "4caaf01414f66d722a5fab2cc6859facbff49b2e",
                "date": 1683436093772,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2728",
                "rarity_score": 254.8203151027976,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2728.png",
            "scheme": "https"
        }
    },
    "1393": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1393",
                "description": "description",
                "dna": "f80b788ae365ce6fff54a148bbd69b87ac8b8c8e",
                "date": 1683434638256,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1393",
                "rarity_score": 162.20677210488097,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1393.png",
            "scheme": "https"
        }
    },
    "2496": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2496",
                "description": "description",
                "dna": "4a81298a12eda5a42667678f655be8816d028630",
                "date": 1683437166049,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2496",
                "rarity_score": 153.52302452927077,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2496.png",
            "scheme": "https"
        }
    },
    "2625": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2625",
                "description": "description",
                "dna": "830ea067122859cd188a0867a621762dd7bfd032",
                "date": 1683429432665,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2625",
                "rarity_score": 273.9723448240919,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2625.png",
            "scheme": "https"
        }
    },
    "1553": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1553",
                "description": "description",
                "dna": "44b501a77aec4274d96d500ae4faf1fad45ddf40",
                "date": 1683444712807,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1553",
                "rarity_score": 194.76239536912152,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1553.png",
            "scheme": "https"
        }
    },
    "3679": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3679",
                "description": "description",
                "dna": "872d211b71d854a739e1aee45b8f8b2250851968",
                "date": 1683446388011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3679",
                "rarity_score": 129.92267455517896,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3679.png",
            "scheme": "https"
        }
    },
    "2442": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2442",
                "description": "description",
                "dna": "d7033e902884d9af63ab657e27e513ed40d3f89e",
                "date": 1683433294852,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2442",
                "rarity_score": 172.55188661270537,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2442.png",
            "scheme": "https"
        }
    },
    "624": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #624",
                "description": "description",
                "dna": "97172c9f1973ddb12fae5d80ce078abb7286c878",
                "date": 1683508609575,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "624",
                "rarity_score": 380.93576922289395,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/624.png",
            "scheme": "https"
        }
    },
    "1173": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1173",
                "description": "description",
                "dna": "b59488d9012e1057624f497c35fc254a16830226",
                "date": 1683433030058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1173",
                "rarity_score": 170.0128009238761,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1173.png",
            "scheme": "https"
        }
    },
    "2899": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2899",
                "description": "description",
                "dna": "0e9f8c1e058c3642bbaa57cf8dc0ef17e0ab9c11",
                "date": 1683434680520,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2899",
                "rarity_score": 290.2298662279034,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2899.png",
            "scheme": "https"
        }
    },
    "2194": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2194",
                "description": "description",
                "dna": "ad639f112fefba1f14d411dcc4abdf4d5d25dcc0",
                "date": 1683771124585,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2194",
                "rarity_score": 183.14263191757647,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2194.png",
            "scheme": "https"
        }
    },
    "772": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #772",
                "description": "description",
                "dna": "8ee8740b65a8b86ca2b60b9e374f6b5f2ccd6443",
                "date": 1683446671074,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "772",
                "rarity_score": 171.59366449061417,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/772.png",
            "scheme": "https"
        }
    },
    "1484": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1484",
                "description": "description",
                "dna": "63b65287124b5cad28d8a787e93cb0b4da863400",
                "date": 1683446955677,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1484",
                "rarity_score": 185.15857594081692,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1484.png",
            "scheme": "https"
        }
    },
    "1337": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1337",
                "description": "description",
                "dna": "ded2f7a50712daf8a942736969aaaea9b9303907",
                "date": 1683446696616,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1337",
                "rarity_score": 186.55594700832873,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1337.png",
            "scheme": "https"
        }
    },
    "2207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2207",
                "description": "description",
                "dna": "fcf3a897d79af61e31fbf99eda227cea1da10f50",
                "date": 1683445124038,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2207",
                "rarity_score": 197.9581576714382,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2207.png",
            "scheme": "https"
        }
    },
    "4015": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4015",
                "description": "description",
                "dna": "26015387870c76835ccea920749bf4a46d8c5540",
                "date": 1683429532275,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "4015",
                "rarity_score": 194.00455358451106,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4015.png",
            "scheme": "https"
        }
    },
    "1618": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1618",
                "description": "description",
                "dna": "78c659929d7b1342607c832d410356945e54a0a6",
                "date": 1683433060726,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1618",
                "rarity_score": 183.96679058702406,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1618.png",
            "scheme": "https"
        }
    },
    "3860": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3860",
                "description": "description",
                "dna": "653db76f99611f1fff2fd50e361c78c178e27a8d",
                "date": 1683437821897,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3860",
                "rarity_score": 207.84182984476314,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3860.png",
            "scheme": "https"
        }
    },
    "2156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2156",
                "description": "description",
                "dna": "2dd53dc9c3b61066678dcb2fd54151619803d9c7",
                "date": 1683431840427,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2156",
                "rarity_score": 204.02512188202354,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2156.png",
            "scheme": "https"
        }
    },
    "850": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #850",
                "description": "description",
                "dna": "e3d08f2d45adc4074c1242a5cf371faf0b42ead5",
                "date": 1683445338868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "850",
                "rarity_score": 242.90148316738882,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/850.png",
            "scheme": "https"
        }
    },
    "1426": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1426",
                "description": "description",
                "dna": "b51995e79321867648019a7ee5bba055aabb6775",
                "date": 1683439120515,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1426",
                "rarity_score": 169.49183906882132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1426.png",
            "scheme": "https"
        }
    },
    "3605": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3605",
                "description": "description",
                "dna": "0779a2b89120a6869816a0597396672ddae8fbfc",
                "date": 1683435241641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3605",
                "rarity_score": 288.8677901322329,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3605.png",
            "scheme": "https"
        }
    },
    "3542": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3542",
                "description": "description",
                "dna": "488f116606d854905ac35d0b456675bd71bacf0a",
                "date": 1683436630128,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3542",
                "rarity_score": 402.535091877837,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3542.png",
            "scheme": "https"
        }
    },
    "1271": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1271",
                "description": "description",
                "dna": "8d3a565d71f61905513ab10dfdf52fcf4d667559",
                "date": 1683444150056,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1271",
                "rarity_score": 263.3172854834692,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1271.png",
            "scheme": "https"
        }
    },
    "3856": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3856",
                "description": "description",
                "dna": "f6aa4ad226b088c90b6b52185e2dcf609c2b5abd",
                "date": 1683435335618,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3856",
                "rarity_score": 147.06236707805138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3856.png",
            "scheme": "https"
        }
    },
    "1819": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1819",
                "description": "description",
                "dna": "fd3cfcd8dd56ef81a6174fe73439a4b602c2a5fe",
                "date": 1683441102209,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1819",
                "rarity_score": 188.16351542228838,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1819.png",
            "scheme": "https"
        }
    },
    "1755": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1755",
                "description": "description",
                "dna": "ef2ac1885eec1dde950e7cbbc3e0a5a60f9ba5fc",
                "date": 1683438409731,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1755",
                "rarity_score": 240.18742868821633,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1755.png",
            "scheme": "https"
        }
    },
    "2866": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2866",
                "description": "description",
                "dna": "3532eb781a4f733028d84c17b3e50558623e33d8",
                "date": 1683771141623,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2866",
                "rarity_score": 170.39306017692354,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2866.png",
            "scheme": "https"
        }
    },
    "3587": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3587",
                "description": "description",
                "dna": "6b9bc3fba4d217babc29912e41ff2a4d2f392289",
                "date": 1683440001892,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3587",
                "rarity_score": 166.52545116466587,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3587.png",
            "scheme": "https"
        }
    },
    "3827": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3827",
                "description": "description",
                "dna": "9831ed59a3dfea2d78de9b2d412892e4e2244c2f",
                "date": 1683433101555,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3827",
                "rarity_score": 164.83598320690442,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3827.png",
            "scheme": "https"
        }
    },
    "2294": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2294",
                "description": "description",
                "dna": "cb7ca8cec95bfff65c393581a1e59afc23d2724c",
                "date": 1683437830340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2294",
                "rarity_score": 160.3213014031947,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2294.png",
            "scheme": "https"
        }
    },
    "3548": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3548",
                "description": "description",
                "dna": "e70a0dcdbf4cf2c1d90568eacbd9b2c1ca5d88e2",
                "date": 1683430678071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3548",
                "rarity_score": 211.93807692465148,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3548.png",
            "scheme": "https"
        }
    },
    "1306": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1306",
                "description": "description",
                "dna": "0d050f6a0299eb11b23ebdac6fc15c56f45efa27",
                "date": 1683435210027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1306",
                "rarity_score": 177.2551195895189,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1306.png",
            "scheme": "https"
        }
    },
    "1108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1108",
                "description": "description",
                "dna": "c1972e682ec089a8caad7a6dde95871df4ee052e",
                "date": 1683431844850,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1108",
                "rarity_score": 150.31567539902025,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1108.png",
            "scheme": "https"
        }
    },
    "4018": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4018",
                "description": "description",
                "dna": "16e7924d9f4bad4cac97dfefaa35035483fe3f70",
                "date": 1683437100579,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "4018",
                "rarity_score": 209.82147021560277,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4018.png",
            "scheme": "https"
        }
    },
    "912": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #912",
                "description": "description",
                "dna": "eb53c93f03f1a4cd23d00a8869aba39e5eebb992",
                "date": 1683434937338,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "912",
                "rarity_score": 194.11580881298886,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/912.png",
            "scheme": "https"
        }
    },
    "2831": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2831",
                "description": "description",
                "dna": "34c94c476b26cda6ea3682a66a1df42954500ad6",
                "date": 1683442578194,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2831",
                "rarity_score": 170.0161751303548,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2831.png",
            "scheme": "https"
        }
    },
    "814": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #814",
                "description": "description",
                "dna": "cbbe163647822568d5416609efaf13649a72388d",
                "date": 1683442042165,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "814",
                "rarity_score": 188.81351317512468,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/814.png",
            "scheme": "https"
        }
    },
    "3980": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3980",
                "description": "description",
                "dna": "188b21cbb8c3d3bd5fb3e9732f6cec9641db81cb",
                "date": 1683437966865,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3980",
                "rarity_score": 188.26901151636227,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3980.png",
            "scheme": "https"
        }
    },
    "1776": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1776",
                "description": "description",
                "dna": "d6b88c85264812b18bfcc0bae6bbe1e0e15b6e2a",
                "date": 1683433574623,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1776",
                "rarity_score": 187.82716879980154,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1776.png",
            "scheme": "https"
        }
    },
    "1327": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1327",
                "description": "description",
                "dna": "e1fde7952d35124d99592e6c43915f0647b4909e",
                "date": 1683440918161,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1327",
                "rarity_score": 159.6365203646407,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1327.png",
            "scheme": "https"
        }
    },
    "1301": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1301",
                "description": "description",
                "dna": "0ac7313fa955068f69b9b6060ceffa219d73f252",
                "date": 1683438433101,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1301",
                "rarity_score": 201.33826611497835,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1301.png",
            "scheme": "https"
        }
    },
    "674": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #674",
                "description": "description",
                "dna": "be3c2801c1bcd192cb2ceedebd83f5ef5418465e",
                "date": 1683441858413,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "674",
                "rarity_score": 146.05598179345833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/674.png",
            "scheme": "https"
        }
    },
    "195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #195",
                "description": "description",
                "dna": "637a51bee0c9e9e9b5775cdc15120b9c5d758208",
                "date": 1683439704140,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "195",
                "rarity_score": 207.88939940371722,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/195.png",
            "scheme": "https"
        }
    },
    "1571": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1571",
                "description": "description",
                "dna": "dd45179510edeb4a672d656509cdbe00c3258c0b",
                "date": 1683438967649,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1571",
                "rarity_score": 151.46842252365755,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1571.png",
            "scheme": "https"
        }
    },
    "3868": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3868",
                "description": "description",
                "dna": "9d714d0849f6f369f7d453c59416031e7a431a95",
                "date": 1683446993856,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3868",
                "rarity_score": 161.6836829009739,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3868.png",
            "scheme": "https"
        }
    },
    "253": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #253",
                "description": "description",
                "dna": "8566541d074ca63e41d16b7e3d41dfde918fe443",
                "date": 1683446155501,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "253",
                "rarity_score": 175.8948945465353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/253.png",
            "scheme": "https"
        }
    },
    "627": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #627",
                "description": "description",
                "dna": "be2ba2e5843d4647d041ed4ca8c9ad37dbd5ba3b",
                "date": 1683446809315,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "627",
                "rarity_score": 189.13570151029003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/627.png",
            "scheme": "https"
        }
    },
    "3403": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3403",
                "description": "description",
                "dna": "6b658bf2d0d59974a69f78c38d46127befe25784",
                "date": 1683508618359,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3403",
                "rarity_score": 421.49223672718426,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3403.png",
            "scheme": "https"
        }
    },
    "3603": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3603",
                "description": "description",
                "dna": "e6d4522ba317579b5721896f7c34f9b0f9b44960",
                "date": 1683443439018,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3603",
                "rarity_score": 182.5258388783071,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3603.png",
            "scheme": "https"
        }
    },
    "1439": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1439",
                "description": "description",
                "dna": "e0a412a5844f36b5b01f9dd06ba4f0e4d9998d47",
                "date": 1683434643118,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1439",
                "rarity_score": 215.89795803751412,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1439.png",
            "scheme": "https"
        }
    },
    "1578": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1578",
                "description": "description",
                "dna": "f0a6f61046fdb30b01c957e74fa29a6314df5ef6",
                "date": 1683441075849,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1578",
                "rarity_score": 158.6513263076527,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1578.png",
            "scheme": "https"
        }
    },
    "1477": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1477",
                "description": "description",
                "dna": "7fc5a5fbd4833222ec4dc67bc0bdc53936023289",
                "date": 1683430691300,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1477",
                "rarity_score": 235.23774238522196,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1477.png",
            "scheme": "https"
        }
    },
    "3959": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3959",
                "description": "description",
                "dna": "28f85d6e4da8d5552575f3f3767defbeb1b6a571",
                "date": 1683446889000,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3959",
                "rarity_score": 308.92175992771666,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3959.png",
            "scheme": "https"
        }
    },
    "731": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #731",
                "description": "description",
                "dna": "b80bf52f4a6238d2cdedb97f7a097cf8aba52ea8",
                "date": 1683435056264,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "731",
                "rarity_score": 293.75035899037425,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/731.png",
            "scheme": "https"
        }
    },
    "3925": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3925",
                "description": "description",
                "dna": "d2587225e0a80acfdf7de22e83e9693cb4449433",
                "date": 1683431817437,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3925",
                "rarity_score": 324.48372814513857,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3925.png",
            "scheme": "https"
        }
    },
    "2188": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2188",
                "description": "description",
                "dna": "8075a1205aa9270592d63989139af4838c89f571",
                "date": 1683441970768,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2188",
                "rarity_score": 179.3192858932875,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2188.png",
            "scheme": "https"
        }
    },
    "2983": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2983",
                "description": "description",
                "dna": "019ac3b049d6aa8975c02e0916ac767edb0f7f3c",
                "date": 1683443124480,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2983",
                "rarity_score": 151.87705408726777,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2983.png",
            "scheme": "https"
        }
    },
    "1580": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1580",
                "description": "description",
                "dna": "62d8f6111f0c8c65cdd4aa57d94e84b6d28cfb30",
                "date": 1683440640904,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1580",
                "rarity_score": 167.6073008169487,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1580.png",
            "scheme": "https"
        }
    },
    "3229": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3229",
                "description": "description",
                "dna": "03eaf8ebdff66ceb57359f37f0a84b80e293bef0",
                "date": 1683433213072,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3229",
                "rarity_score": 197.13524526712658,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3229.png",
            "scheme": "https"
        }
    },
    "1379": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1379",
                "description": "description",
                "dna": "c8619323f7b7ce41910c38bfda9b6f0e5f5645df",
                "date": 1683446384231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1379",
                "rarity_score": 131.52794140364267,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1379.png",
            "scheme": "https"
        }
    },
    "3417": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3417",
                "description": "description",
                "dna": "24f782cd1b39bd542bfc8162baaf063713c2d5ad",
                "date": 1683445225397,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3417",
                "rarity_score": 337.65999445895653,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3417.png",
            "scheme": "https"
        }
    },
    "1362": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1362",
                "description": "description",
                "dna": "f06bf15ecfa59a80450f7ddda273ae79ffce0f06",
                "date": 1683439667722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1362",
                "rarity_score": 212.4538904107646,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1362.png",
            "scheme": "https"
        }
    },
    "1622": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1622",
                "description": "description",
                "dna": "b9490e0467c94ffe0fd42af82209d2b95580c0ca",
                "date": 1683770564168,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1622",
                "rarity_score": 234.75995541116748,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1622.png",
            "scheme": "https"
        }
    },
    "2750": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2750",
                "description": "description",
                "dna": "e7fe68848d208c9109e8c11c5a90352c0bcae4dc",
                "date": 1683444229749,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2750",
                "rarity_score": 180.91671131876882,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2750.png",
            "scheme": "https"
        }
    },
    "235": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #235",
                "description": "description",
                "dna": "1ba1d7e3368cb66573507b5d2f8e5e7328c1e037",
                "date": 1683444794141,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "235",
                "rarity_score": 179.96952618489172,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/235.png",
            "scheme": "https"
        }
    },
    "792": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #792",
                "description": "description",
                "dna": "1cc018310d57687d9e8e5748be01c20195fbcab3",
                "date": 1683442923278,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "792",
                "rarity_score": 194.20445194175463,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/792.png",
            "scheme": "https"
        }
    },
    "1885": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1885",
                "description": "description",
                "dna": "5ebe2c4aa6e0579faa7c4ee34d14e36bd9f0e761",
                "date": 1683429701367,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1885",
                "rarity_score": 188.82008812388312,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1885.png",
            "scheme": "https"
        }
    },
    "1737": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1737",
                "description": "description",
                "dna": "9ef484d673a8439f92965afaaca09007766086c5",
                "date": 1683441872207,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1737",
                "rarity_score": 162.27803688077697,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1737.png",
            "scheme": "https"
        }
    },
    "3972": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3972",
                "description": "description",
                "dna": "86b9c9bae32141d4788ca543d18345d2902bf3cb",
                "date": 1683447201520,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3972",
                "rarity_score": 247.12703007296548,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3972.png",
            "scheme": "https"
        }
    },
    "2912": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2912",
                "description": "description",
                "dna": "24e03b8d2e425a1b57833722b7bafd63a2ad1688",
                "date": 1683443020543,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2912",
                "rarity_score": 204.69524573057788,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2912.png",
            "scheme": "https"
        }
    },
    "3197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3197",
                "description": "description",
                "dna": "9e1fae9f9e78b9d4ca27d69cbc320eb07a3d9d0c",
                "date": 1683432484990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3197",
                "rarity_score": 145.77266019308874,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3197.png",
            "scheme": "https"
        }
    },
    "2523": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2523",
                "description": "description",
                "dna": "e249bc048b22b530372f41f9cb7b6b8656843497",
                "date": 1683430525440,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2523",
                "rarity_score": 165.2181320247743,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2523.png",
            "scheme": "https"
        }
    },
    "1372": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1372",
                "description": "description",
                "dna": "049653af9286cbb65f9cbf38924f7515cf561842",
                "date": 1683770610780,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1372",
                "rarity_score": 261.3595660068983,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1372.png",
            "scheme": "https"
        }
    },
    "2258": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2258",
                "description": "description",
                "dna": "f5bbcd83d62f9172effc920a7169b1905ba46203",
                "date": 1683437669378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2258",
                "rarity_score": 227.52950748068227,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2258.png",
            "scheme": "https"
        }
    },
    "2642": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2642",
                "description": "description",
                "dna": "0866d379dcf13f7359383f2767bb2ae09059adf7",
                "date": 1683771607901,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2642",
                "rarity_score": 181.35037920463986,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2642.png",
            "scheme": "https"
        }
    },
    "3485": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3485",
                "description": "description",
                "dna": "7ee81543e58271eea154d1e76e59aebb9a6fe444",
                "date": 1683441983678,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3485",
                "rarity_score": 164.50361111813885,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3485.png",
            "scheme": "https"
        }
    },
    "3498": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3498",
                "description": "description",
                "dna": "a91f40a9a13b980bec17c492e6dcd6de82c4d70a",
                "date": 1683446316503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3498",
                "rarity_score": 153.14590154297565,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3498.png",
            "scheme": "https"
        }
    },
    "3685": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3685",
                "description": "description",
                "dna": "4d0c4396e31d1290745b4d92f483fcc5442d3e50",
                "date": 1683443629602,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3685",
                "rarity_score": 165.215613920401,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3685.png",
            "scheme": "https"
        }
    },
    "3147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3147",
                "description": "description",
                "dna": "d6e8342274a65306dc7c240fa8a727a31f036bd4",
                "date": 1683435302273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3147",
                "rarity_score": 195.50026260964717,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3147.png",
            "scheme": "https"
        }
    },
    "3318": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3318",
                "description": "description",
                "dna": "74467290c6b9a6ebf0ca3d082c745612c8e85925",
                "date": 1683770555958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3318",
                "rarity_score": 247.68542087476536,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3318.png",
            "scheme": "https"
        }
    },
    "2127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2127",
                "description": "description",
                "dna": "0612bc6ddcd01f0941a451ba75e11d9f5b8a54d2",
                "date": 1683441600875,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2127",
                "rarity_score": 230.44133064800718,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2127.png",
            "scheme": "https"
        }
    },
    "296": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #296",
                "description": "description",
                "dna": "ef550c06a2be970d9a01fe33de290a24268465f5",
                "date": 1683436195681,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "296",
                "rarity_score": 364.9243816373338,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/296.png",
            "scheme": "https"
        }
    },
    "3742": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3742",
                "description": "description",
                "dna": "24105800905ef10d5cc44c279bbf8a6217682307",
                "date": 1683437834762,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3742",
                "rarity_score": 214.21515114547836,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3742.png",
            "scheme": "https"
        }
    },
    "2665": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2665",
                "description": "description",
                "dna": "718d24ca30ca6bef116ad976bd3fdac96074fddf",
                "date": 1683433385732,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2665",
                "rarity_score": 244.11765798041884,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2665.png",
            "scheme": "https"
        }
    },
    "3592": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3592",
                "description": "description",
                "dna": "c5af72d3486e1e82fd4d067850e93c7e768e70c6",
                "date": 1683434862717,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3592",
                "rarity_score": 187.15683482955814,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3592.png",
            "scheme": "https"
        }
    },
    "226": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #226",
                "description": "description",
                "dna": "d50811b3ff3f57571817d0f35b3ec94a31e5ab8a",
                "date": 1683774412701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "226",
                "rarity_score": 197.70007441581242,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/226.png",
            "scheme": "https"
        }
    },
    "3920": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3920",
                "description": "description",
                "dna": "d55c179c6bedf995d75734e60ed9d0a9eaece8b0",
                "date": 1683771294393,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3920",
                "rarity_score": 198.8805682156739,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3920.png",
            "scheme": "https"
        }
    },
    "1968": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1968",
                "description": "description",
                "dna": "210e2495dc069ca4af399d13c8150051b7317b6b",
                "date": 1683447411554,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1968",
                "rarity_score": 155.41681046670425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1968.png",
            "scheme": "https"
        }
    },
    "1105": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1105",
                "description": "description",
                "dna": "83c91769ec2722207926831140800c6340ea174c",
                "date": 1683433788914,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1105",
                "rarity_score": 218.54956719502394,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1105.png",
            "scheme": "https"
        }
    },
    "3340": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3340",
                "description": "description",
                "dna": "cc0cf5f4256ff0a778b914026a0a4990aac8bcec",
                "date": 1683430419645,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3340",
                "rarity_score": 161.20992292774488,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3340.png",
            "scheme": "https"
        }
    },
    "3921": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3921",
                "description": "description",
                "dna": "622ffef8c6970d96fabec7cfeb60f92c6746a285",
                "date": 1683433699587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3921",
                "rarity_score": 227.7188738453422,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3921.png",
            "scheme": "https"
        }
    },
    "2519": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2519",
                "description": "description",
                "dna": "a959669d6678243c5b2da46d0281baa7eaff8bc9",
                "date": 1683446423358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2519",
                "rarity_score": 169.75316465187748,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2519.png",
            "scheme": "https"
        }
    },
    "2673": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2673",
                "description": "description",
                "dna": "a88a45617b690535f389a6a54e8d24600b97d822",
                "date": 1683445194242,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2673",
                "rarity_score": 211.18594365637614,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2673.png",
            "scheme": "https"
        }
    },
    "3504": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3504",
                "description": "description",
                "dna": "ea52707032660b661facf90eac59ac73b21eee7e",
                "date": 1683441736941,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3504",
                "rarity_score": 203.3009866188499,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3504.png",
            "scheme": "https"
        }
    },
    "45": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #45",
                "description": "description",
                "dna": "b34bd20faea98238afdf61b04344e1232e110ffb",
                "date": 1683436437482,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "45",
                "rarity_score": 221.37959439300226,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/45.png",
            "scheme": "https"
        }
    },
    "2289": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2289",
                "description": "description",
                "dna": "aea952009def799cf248329eeb8e29fcd799372f",
                "date": 1683441867470,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2289",
                "rarity_score": 232.264869657657,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2289.png",
            "scheme": "https"
        }
    },
    "69": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #69",
                "description": "description",
                "dna": "0d0fe93173b0045a6bf312db0cbd60657b91249e",
                "date": 1683432476146,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "69",
                "rarity_score": 216.515862731916,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/69.png",
            "scheme": "https"
        }
    },
    "677": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #677",
                "description": "description",
                "dna": "98d28afe6e9037e75e8e704e974aac8f97799ca7",
                "date": 1683439447310,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "677",
                "rarity_score": 196.8538290804716,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/677.png",
            "scheme": "https"
        }
    },
    "400": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #400",
                "description": "description",
                "dna": "c8b19603e7df6ffd9177562bc352c439ef0ba761",
                "date": 1683443210992,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "400",
                "rarity_score": 168.3472660295488,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/400.png",
            "scheme": "https"
        }
    },
    "1221": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1221",
                "description": "description",
                "dna": "7675e37859982da2141975c554d8299b29cd9af4",
                "date": 1683446174390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1221",
                "rarity_score": 148.39811700965004,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1221.png",
            "scheme": "https"
        }
    },
    "2735": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2735",
                "description": "description",
                "dna": "05024f92106b59187455437e97d7d4320c7669ad",
                "date": 1683440864170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2735",
                "rarity_score": 212.46102323064375,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2735.png",
            "scheme": "https"
        }
    },
    "1909": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1909",
                "description": "description",
                "dna": "ac03bb21dc5c8dfce58042ca98fcc6adb18924f2",
                "date": 1683439529410,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1909",
                "rarity_score": 154.51956879125046,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1909.png",
            "scheme": "https"
        }
    },
    "668": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #668",
                "description": "description",
                "dna": "71aae46257ce88236398a4623af05b7c83a61ba1",
                "date": 1683435083259,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "668",
                "rarity_score": 242.09798819410557,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/668.png",
            "scheme": "https"
        }
    },
    "3294": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3294",
                "description": "description",
                "dna": "dd4f07b1d86bd2f18091d41493e2ae3863b5aa79",
                "date": 1683774453331,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3294",
                "rarity_score": 190.2552873857416,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3294.png",
            "scheme": "https"
        }
    },
    "796": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #796",
                "description": "description",
                "dna": "96a5e4286c1f741bbd4d9b0981cd47c9a4a65586",
                "date": 1683446375878,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "796",
                "rarity_score": 179.26505821159623,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/796.png",
            "scheme": "https"
        }
    },
    "391": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #391",
                "description": "description",
                "dna": "57607a86827c61894813d25d5227ceca1fb0c7c0",
                "date": 1683440372394,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "391",
                "rarity_score": 220.24589306722646,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/391.png",
            "scheme": "https"
        }
    },
    "2588": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2588",
                "description": "description",
                "dna": "d2ebaa720258bea81bb86b6eecf2c981a8afc13d",
                "date": 1683441561474,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2588",
                "rarity_score": 187.79865533149527,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2588.png",
            "scheme": "https"
        }
    },
    "3633": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3633",
                "description": "description",
                "dna": "fb9ef1d550ce1f08ff5f7626d618b44cfe252937",
                "date": 1683775034655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3633",
                "rarity_score": 176.88040909656462,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3633.png",
            "scheme": "https"
        }
    },
    "3280": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3280",
                "description": "description",
                "dna": "4956d726cbf16ed656f6cf146f3abd42f5bcd31e",
                "date": 1683429954229,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3280",
                "rarity_score": 162.32797198039145,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3280.png",
            "scheme": "https"
        }
    },
    "1147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1147",
                "description": "description",
                "dna": "eaaa197fc207ea4bb53e57196ce104fb4c11c0a5",
                "date": 1683436860668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1147",
                "rarity_score": 134.29144533552468,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1147.png",
            "scheme": "https"
        }
    },
    "578": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #578",
                "description": "description",
                "dna": "8c16a9383dc2450c5d8981c4ed761a45bbf34973",
                "date": 1683431937198,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "578",
                "rarity_score": 238.3936111230916,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/578.png",
            "scheme": "https"
        }
    },
    "2064": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2064",
                "description": "description",
                "dna": "3c4f37e893bc66b6cf24ec94bb679846509c9e94",
                "date": 1683771272485,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2064",
                "rarity_score": 191.5599178855435,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2064.png",
            "scheme": "https"
        }
    },
    "1646": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1646",
                "description": "description",
                "dna": "26142fd261b362c4e0d7165a1597dca71793646b",
                "date": 1683437207138,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1646",
                "rarity_score": 136.41962521153263,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1646.png",
            "scheme": "https"
        }
    },
    "1791": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1791",
                "description": "description",
                "dna": "916e7cfa81da60c861906c1753686d5a8cea38e2",
                "date": 1683444059973,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1791",
                "rarity_score": 162.10567432733194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1791.png",
            "scheme": "https"
        }
    },
    "2997": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2997",
                "description": "description",
                "dna": "7ac478c76560b5962f909172d659723733a6d8f9",
                "date": 1683446654422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2997",
                "rarity_score": 157.2102794436664,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2997.png",
            "scheme": "https"
        }
    },
    "366": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #366",
                "description": "description",
                "dna": "cf89ae16f6c75f791a027edb02d6387f472aa008",
                "date": 1683444603874,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "366",
                "rarity_score": 178.1396427446711,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/366.png",
            "scheme": "https"
        }
    },
    "2644": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2644",
                "description": "description",
                "dna": "976e5faa36134afd4152440bbc9fd3f7367160bd",
                "date": 1683435670674,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2644",
                "rarity_score": 231.50629959649336,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2644.png",
            "scheme": "https"
        }
    },
    "1204": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1204",
                "description": "description",
                "dna": "d32a0890517a5ebde20013773dcf557a928512dd",
                "date": 1683437519944,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1204",
                "rarity_score": 138.04407246381766,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1204.png",
            "scheme": "https"
        }
    },
    "3825": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3825",
                "description": "description",
                "dna": "391525c4f0cde72a04c711075a821d879a2c286d",
                "date": 1683431906063,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3825",
                "rarity_score": 294.0206172620827,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3825.png",
            "scheme": "https"
        }
    },
    "3978": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3978",
                "description": "description",
                "dna": "6c2f61be63ec8e7a8f3c18e05914f9facb369ec0",
                "date": 1683439063446,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3978",
                "rarity_score": 254.03976776099256,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3978.png",
            "scheme": "https"
        }
    },
    "2115": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2115",
                "description": "description",
                "dna": "e0a340f8eb56b72270d769ec49ec993dd650f19e",
                "date": 1683446503314,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2115",
                "rarity_score": 183.0947672317728,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2115.png",
            "scheme": "https"
        }
    },
    "1558": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1558",
                "description": "description",
                "dna": "139f46294f4db6704c367f537757e191362919ab",
                "date": 1683441165572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1558",
                "rarity_score": 177.2650040993396,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1558.png",
            "scheme": "https"
        }
    },
    "1550": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1550",
                "description": "description",
                "dna": "9422943e9db238fcc547c7d7d059b220178dae37",
                "date": 1683447219590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1550",
                "rarity_score": 206.4268948325861,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1550.png",
            "scheme": "https"
        }
    },
    "220": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #220",
                "description": "description",
                "dna": "621b4ad892610b4259a5755f3c5bdd7b6aa755ba",
                "date": 1683432653234,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "220",
                "rarity_score": 208.43602915726805,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/220.png",
            "scheme": "https"
        }
    },
    "2717": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2717",
                "description": "description",
                "dna": "4fd05f1477e2ae478c07d6a364c5211ebd27191a",
                "date": 1683441614704,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2717",
                "rarity_score": 174.32983839550303,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2717.png",
            "scheme": "https"
        }
    },
    "1193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1193",
                "description": "description",
                "dna": "7719747f0b39ce00f4ec6594e6bfcb89a2228e65",
                "date": 1683437306092,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1193",
                "rarity_score": 122.73963331121048,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1193.png",
            "scheme": "https"
        }
    },
    "2678": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2678",
                "description": "description",
                "dna": "1174821a05653a2386ef4c147c666a20ede5ef55",
                "date": 1683437327206,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2678",
                "rarity_score": 133.68760620169004,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2678.png",
            "scheme": "https"
        }
    },
    "717": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #717",
                "description": "description",
                "dna": "3148a78d25b2cfffc377c8540da4dc98824ad790",
                "date": 1683433185561,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "717",
                "rarity_score": 174.72041615120617,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/717.png",
            "scheme": "https"
        }
    },
    "1073": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1073",
                "description": "description",
                "dna": "88e15ee4ed7451478776fe1847de374b4e7d9b80",
                "date": 1683446198366,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1073",
                "rarity_score": 128.93592154828042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1073.png",
            "scheme": "https"
        }
    },
    "2023": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2023",
                "description": "description",
                "dna": "a9e0cda108913475f5da982be64161df4428e9ec",
                "date": 1683775222311,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2023",
                "rarity_score": 172.67448896338271,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2023.png",
            "scheme": "https"
        }
    },
    "2814": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2814",
                "description": "description",
                "dna": "b1d31a1c182bb58fd8061c45a624cdaab5fe2b58",
                "date": 1683446140183,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2814",
                "rarity_score": 127.05459394537269,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2814.png",
            "scheme": "https"
        }
    },
    "3175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3175",
                "description": "description",
                "dna": "d7f9bb4d611ced4727f75c4e237969a578fbced3",
                "date": 1683432444171,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3175",
                "rarity_score": 162.26820906040447,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3175.png",
            "scheme": "https"
        }
    },
    "885": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #885",
                "description": "description",
                "dna": "cf73ea56aa96a7fea5334286bacb8b620fe931a6",
                "date": 1683431382201,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "885",
                "rarity_score": 161.02004158778865,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/885.png",
            "scheme": "https"
        }
    },
    "1994": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1994",
                "description": "description",
                "dna": "77b5d9dfc1da350c991be4a443efafce0f32ba98",
                "date": 1683770510802,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1994",
                "rarity_score": 222.28744511241376,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1994.png",
            "scheme": "https"
        }
    },
    "325": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #325",
                "description": "description",
                "dna": "bbea6e8c54bc5f834593bdbbe61f6151d420c4a1",
                "date": 1683430784840,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "325",
                "rarity_score": 156.36197139794663,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/325.png",
            "scheme": "https"
        }
    },
    "3182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3182",
                "description": "description",
                "dna": "b12a4784184d27900515085eec64a721b87e1a74",
                "date": 1683433272324,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3182",
                "rarity_score": 205.40426567853527,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3182.png",
            "scheme": "https"
        }
    },
    "925": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #925",
                "description": "description",
                "dna": "125c2a79b293f31ac82034fd95a826b0e3ceeda5",
                "date": 1683436533535,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "925",
                "rarity_score": 253.54644353508849,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/925.png",
            "scheme": "https"
        }
    },
    "3833": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3833",
                "description": "description",
                "dna": "45ee25126da796f7ef305d85bbd2993d083641a6",
                "date": 1683444268836,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3833",
                "rarity_score": 185.95597215918286,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3833.png",
            "scheme": "https"
        }
    },
    "4078": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4078",
                "description": "description",
                "dna": "7d4cf9a892d2ad9438d472f6655c24f6ed56dea0",
                "date": 1683776469570,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "4078",
                "rarity_score": 205.0912870794088,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4078.png",
            "scheme": "https"
        }
    },
    "1499": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1499",
                "description": "description",
                "dna": "711cd5e158fd08f5300397039d8b416224309ac9",
                "date": 1683430402610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1499",
                "rarity_score": 184.74592940019824,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1499.png",
            "scheme": "https"
        }
    },
    "180": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #180",
                "description": "description",
                "dna": "6a80be02377e891745bfc00cb66ac4fd7dc32681",
                "date": 1683436218701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "180",
                "rarity_score": 280.21720135373386,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/180.png",
            "scheme": "https"
        }
    },
    "1917": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1917",
                "description": "description",
                "dna": "e087dc52241bcbe4263e1ef1ff09e9709b627264",
                "date": 1683437775178,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1917",
                "rarity_score": 240.9721760909249,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1917.png",
            "scheme": "https"
        }
    },
    "821": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #821",
                "description": "description",
                "dna": "db436fd9db6cb14b68986d1a13fe76554c98cdf1",
                "date": 1683444068696,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "821",
                "rarity_score": 166.99279784659547,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/821.png",
            "scheme": "https"
        }
    },
    "240": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #240",
                "description": "description",
                "dna": "f3eda2a60f908f3ddcf34f9469a790cad5bb69ad",
                "date": 1683437619231,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "240",
                "rarity_score": 199.33349668975936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/240.png",
            "scheme": "https"
        }
    },
    "2001": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2001",
                "description": "description",
                "dna": "7200da1c2701a7103cd5ccb0ad16ae66a29e44ae",
                "date": 1683445004490,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2001",
                "rarity_score": 192.6078305623046,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2001.png",
            "scheme": "https"
        }
    },
    "1835": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1835",
                "description": "description",
                "dna": "e0d6829bcb5301e41814f1b157793cdaafc0d95d",
                "date": 1683440718558,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1835",
                "rarity_score": 248.13285739766067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1835.png",
            "scheme": "https"
        }
    },
    "516": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #516",
                "description": "description",
                "dna": "5376594fb130cd20e72a38e8d9b7ff3f75fea984",
                "date": 1683447206572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "516",
                "rarity_score": 235.06116183642064,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/516.png",
            "scheme": "https"
        }
    },
    "1914": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1914",
                "description": "description",
                "dna": "a6220fce1e94063e0e7007d010cf3f7fa77f317f",
                "date": 1683445490925,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1914",
                "rarity_score": 172.16310131183053,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1914.png",
            "scheme": "https"
        }
    },
    "1099": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1099",
                "description": "description",
                "dna": "f889f9769e643243e36731a6d45201f57e23cea1",
                "date": 1683776425694,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1099",
                "rarity_score": 180.0776884788966,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1099.png",
            "scheme": "https"
        }
    },
    "2833": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2833",
                "description": "description",
                "dna": "0661aa8e055b6fa3b7726633d5c0e8f323a89a13",
                "date": 1683439408048,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2833",
                "rarity_score": 166.02906646852287,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2833.png",
            "scheme": "https"
        }
    },
    "3754": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3754",
                "description": "description",
                "dna": "24515b8a996231d1b051fcda8a12546f887cc504",
                "date": 1683771353821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3754",
                "rarity_score": 185.1297151624211,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3754.png",
            "scheme": "https"
        }
    },
    "3795": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3795",
                "description": "description",
                "dna": "d28979ee4d6020d1689b47eb914d759021f25937",
                "date": 1683436885183,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3795",
                "rarity_score": 225.29665712033926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3795.png",
            "scheme": "https"
        }
    },
    "2467": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2467",
                "description": "description",
                "dna": "5268a4b8e0a231cb8f5e434e1d273c374279dc3d",
                "date": 1683770660040,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2467",
                "rarity_score": 252.84579812233403,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2467.png",
            "scheme": "https"
        }
    },
    "66": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #66",
                "description": "description",
                "dna": "dfae85a05af39f03b52fc580342a03c9ce91a992",
                "date": 1683445973687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "66",
                "rarity_score": 192.43046487540008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/66.png",
            "scheme": "https"
        }
    },
    "729": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #729",
                "description": "description",
                "dna": "0943585ce75bc36f95b35c1c1d1578e04913228b",
                "date": 1683775122132,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "729",
                "rarity_score": 169.266703204379,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/729.png",
            "scheme": "https"
        }
    },
    "3332": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3332",
                "description": "description",
                "dna": "72731fb9ccde6bdab40c2d8ecab18633e1fb6483",
                "date": 1683445443585,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3332",
                "rarity_score": 291.26421760075925,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3332.png",
            "scheme": "https"
        }
    },
    "1811": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1811",
                "description": "description",
                "dna": "29bb6f7838fc6d4eb01391ca9b6bf8947f7cbc85",
                "date": 1683437162176,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1811",
                "rarity_score": 163.44611441896873,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1811.png",
            "scheme": "https"
        }
    },
    "1661": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1661",
                "description": "description",
                "dna": "aed7924387f148f57eb5ace6b647f852501a5020",
                "date": 1683770473472,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1661",
                "rarity_score": 296.6833791545733,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1661.png",
            "scheme": "https"
        }
    },
    "1760": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1760",
                "description": "description",
                "dna": "ceb5198b21943b30613f20187b3c10ece6e1229f",
                "date": 1683446863620,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1760",
                "rarity_score": 201.72233923259628,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1760.png",
            "scheme": "https"
        }
    },
    "2965": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2965",
                "description": "description",
                "dna": "81e138251b4d34e5d2a447a29c0808dc2442e57d",
                "date": 1683446007976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2965",
                "rarity_score": 173.46072847240922,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2965.png",
            "scheme": "https"
        }
    },
    "100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #100",
                "description": "description",
                "dna": "765cc73e8634c0964e58e61d9a1e84a83991e23a",
                "date": 1683438531099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "100",
                "rarity_score": 265.60602353304915,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/100.png",
            "scheme": "https"
        }
    },
    "124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #124",
                "description": "description",
                "dna": "c1d064fda27307d3c8d231e38cb7a0425c5c1eb1",
                "date": 1683432134092,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "124",
                "rarity_score": 165.48335649169806,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/124.png",
            "scheme": "https"
        }
    },
    "3522": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3522",
                "description": "description",
                "dna": "338531c2636c6523f193f64492b74f8796cb1f0f",
                "date": 1683771103330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3522",
                "rarity_score": 182.3572632612271,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3522.png",
            "scheme": "https"
        }
    },
    "581": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #581",
                "description": "description",
                "dna": "c6905f221d62a0772e005c8cc7a1b234b012d16e",
                "date": 1683437071597,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "581",
                "rarity_score": 152.39059652027305,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/581.png",
            "scheme": "https"
        }
    },
    "1899": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1899",
                "description": "description",
                "dna": "ea4b82d358c56a7b330f4f6d02c4469f25654d83",
                "date": 1683517570036,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Gold Hand",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1899",
                "rarity_score": 4332.566931012404,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1899.png",
            "scheme": "https"
        }
    },
    "1290": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1290",
                "description": "description",
                "dna": "0b4f3e3c38966d94071709263fc6f097e173afa4",
                "date": 1683430122587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1290",
                "rarity_score": 201.48634374676416,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1290.png",
            "scheme": "https"
        }
    },
    "97": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #97",
                "description": "description",
                "dna": "6c994132c581bf5a536af84ec710ea09b930a0e6",
                "date": 1683430342616,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "97",
                "rarity_score": 148.23691246693946,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/97.png",
            "scheme": "https"
        }
    },
    "3106": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3106",
                "description": "description",
                "dna": "3b7ccb8cb9c7c98e218e17c7abdf3b9bba5238c3",
                "date": 1683771522188,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3106",
                "rarity_score": 201.68736467895422,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3106.png",
            "scheme": "https"
        }
    },
    "747": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #747",
                "description": "description",
                "dna": "d12c4cd838b7e2a18bd16e14f965b1e39c2d3ad2",
                "date": 1683430173821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "747",
                "rarity_score": 169.97954200627672,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/747.png",
            "scheme": "https"
        }
    },
    "1639": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1639",
                "description": "description",
                "dna": "1b9ac43d729d024afc9dde2b57118b1227694ed4",
                "date": 1683431269777,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1639",
                "rarity_score": 271.2645380016144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1639.png",
            "scheme": "https"
        }
    },
    "243": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #243",
                "description": "description",
                "dna": "0133fbd2f45816c2a2dae20eb2b6cc415635aec4",
                "date": 1683443972087,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "243",
                "rarity_score": 159.99531400275845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/243.png",
            "scheme": "https"
        }
    },
    "852": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #852",
                "description": "description",
                "dna": "d3cabfc13affc03dc9741ef3007c5d03e0dd8d48",
                "date": 1683434005925,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "852",
                "rarity_score": 163.918283479492,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/852.png",
            "scheme": "https"
        }
    },
    "2255": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2255",
                "description": "description",
                "dna": "2a8c1b07e6a1fe5d0f5745c8cfca66731f65bcd1",
                "date": 1683432994822,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2255",
                "rarity_score": 166.16365225395023,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2255.png",
            "scheme": "https"
        }
    },
    "3289": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3289",
                "description": "description",
                "dna": "1f7e8446320e6134168d60678a004f42e6fdfc7f",
                "date": 1683771336931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3289",
                "rarity_score": 235.67089155761232,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3289.png",
            "scheme": "https"
        }
    },
    "1238": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1238",
                "description": "description",
                "dna": "6a47d528a639b631bc4e41c112bfe979b3e780f7",
                "date": 1683429505588,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1238",
                "rarity_score": 227.79962484412368,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1238.png",
            "scheme": "https"
        }
    },
    "3563": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3563",
                "description": "description",
                "dna": "cd891b1c999386b89f13262e15d589668490e3be",
                "date": 1683440817975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3563",
                "rarity_score": 186.25696143209632,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3563.png",
            "scheme": "https"
        }
    },
    "1918": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1918",
                "description": "description",
                "dna": "0ddd0e75cd3c6ca44911c92d1453670ac7cea062",
                "date": 1683438485554,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1918",
                "rarity_score": 218.22507228355744,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1918.png",
            "scheme": "https"
        }
    },
    "924": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #924",
                "description": "description",
                "dna": "75494fda91098cf29e7b9a83f65ad5f06ecba9db",
                "date": 1683445621855,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "924",
                "rarity_score": 189.52277098609804,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/924.png",
            "scheme": "https"
        }
    },
    "3212": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3212",
                "description": "description",
                "dna": "4d8dce79841d54ea55a05c390d2da0a001c825a2",
                "date": 1683432448786,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3212",
                "rarity_score": 201.48289256908686,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3212.png",
            "scheme": "https"
        }
    },
    "2206": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2206",
                "description": "description",
                "dna": "d024d5b9d965813b928027280e43ec8cc9e4a822",
                "date": 1683770547780,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2206",
                "rarity_score": 306.7082231701991,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2206.png",
            "scheme": "https"
        }
    },
    "1799": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1799",
                "description": "description",
                "dna": "325a8c3ac4ba851373874b0c75539a6659a03c0c",
                "date": 1683440518380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1799",
                "rarity_score": 225.2969258381417,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1799.png",
            "scheme": "https"
        }
    },
    "2087": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2087",
                "description": "description",
                "dna": "0d9018895158c05b66b31bdeb1765d166e0d2a30",
                "date": 1683445447612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2087",
                "rarity_score": 397.36088463122775,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2087.png",
            "scheme": "https"
        }
    },
    "3558": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3558",
                "description": "description",
                "dna": "2ed34a9317dde63a09153cdc930ca858808a283a",
                "date": 1683774973265,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3558",
                "rarity_score": 175.5646353187573,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3558.png",
            "scheme": "https"
        }
    },
    "133": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #133",
                "description": "description",
                "dna": "6e8fb4ed53a1e86f7c4444bbeadcfad022c25dec",
                "date": 1683433253775,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "133",
                "rarity_score": 185.99102624653065,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/133.png",
            "scheme": "https"
        }
    },
    "326": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #326",
                "description": "description",
                "dna": "2ead6864914b88166146a2a26c0284662fc384b1",
                "date": 1683446867685,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "326",
                "rarity_score": 152.32227789109044,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/326.png",
            "scheme": "https"
        }
    },
    "406": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #406",
                "description": "description",
                "dna": "a7b84248769c986ebec310fc8ff99c64d1e3cd41",
                "date": 1683432286953,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "406",
                "rarity_score": 165.61140691340336,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/406.png",
            "scheme": "https"
        }
    },
    "616": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #616",
                "description": "description",
                "dna": "9cd1f0aa4ad42a55c93233b69f77275ad1273073",
                "date": 1683441786301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "616",
                "rarity_score": 255.88310634269814,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/616.png",
            "scheme": "https"
        }
    },
    "1920": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1920",
                "description": "description",
                "dna": "6fc09f0f13f482e40ccf6521649d09f8acc3d5bf",
                "date": 1683434666610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1920",
                "rarity_score": 239.90488682372597,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1920.png",
            "scheme": "https"
        }
    },
    "2246": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2246",
                "description": "description",
                "dna": "234e8b8a2ba20401f5d8e7c3fba1a1fa0c290a61",
                "date": 1683435639652,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2246",
                "rarity_score": 160.76297302061602,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2246.png",
            "scheme": "https"
        }
    },
    "1264": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1264",
                "description": "description",
                "dna": "1df847b7e631e2cae31c0ca377f8ebcc7e4b1aa9",
                "date": 1683443331428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1264",
                "rarity_score": 193.04024148457967,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1264.png",
            "scheme": "https"
        }
    },
    "2372": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2372",
                "description": "description",
                "dna": "954e127eee1b63fe15bf22233d6c338129982ca0",
                "date": 1683433326109,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2372",
                "rarity_score": 273.48246945183826,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2372.png",
            "scheme": "https"
        }
    },
    "1230": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1230",
                "description": "description",
                "dna": "f377871f519fcf327ea93e69439b7dbe1f96347a",
                "date": 1683434475304,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1230",
                "rarity_score": 153.68671824364043,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1230.png",
            "scheme": "https"
        }
    },
    "1533": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1533",
                "description": "description",
                "dna": "1725d27e0a0aa79a660e92fc40730e4290d8cfda",
                "date": 1683433784390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1533",
                "rarity_score": 201.9700361115273,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1533.png",
            "scheme": "https"
        }
    },
    "3753": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3753",
                "description": "description",
                "dna": "16ebbef907109680c179c0583ed2cff26b182f0e",
                "date": 1683430939436,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3753",
                "rarity_score": 255.26855396088837,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3753.png",
            "scheme": "https"
        }
    },
    "2769": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2769",
                "description": "description",
                "dna": "252d8ad5608742ff68f8ffab6fa408ad32b6709c",
                "date": 1683434721622,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2769",
                "rarity_score": 158.5907695236438,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2769.png",
            "scheme": "https"
        }
    },
    "3190": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3190",
                "description": "description",
                "dna": "5c1a81c64e9e7bf152e258ff210a2b849c15471a",
                "date": 1683439806286,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3190",
                "rarity_score": 180.65671801638766,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3190.png",
            "scheme": "https"
        }
    },
    "2836": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2836",
                "description": "description",
                "dna": "b794a6f17ad7ffee8fafb6beb912209f964e8d68",
                "date": 1683446692425,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2836",
                "rarity_score": 246.3907962783519,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2836.png",
            "scheme": "https"
        }
    },
    "3632": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3632",
                "description": "description",
                "dna": "1f6f8d3f37356d89ac60c6be60e3a1d4aa662255",
                "date": 1683442582605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3632",
                "rarity_score": 157.605542411258,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3632.png",
            "scheme": "https"
        }
    },
    "650": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #650",
                "description": "description",
                "dna": "bc5f999203111d9b667aa88a161352d190351451",
                "date": 1683771175366,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "650",
                "rarity_score": 202.56297969343393,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/650.png",
            "scheme": "https"
        }
    },
    "3745": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3745",
                "description": "description",
                "dna": "2bc0aa6bc0ac6e39eecfc154bbfd9aea5efa1c81",
                "date": 1683435714942,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3745",
                "rarity_score": 179.50705442915316,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3745.png",
            "scheme": "https"
        }
    },
    "573": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #573",
                "description": "description",
                "dna": "2795960e62930d50684c56209ab913e3248e6a9e",
                "date": 1683447123098,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "573",
                "rarity_score": 277.1217153345079,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/573.png",
            "scheme": "https"
        }
    },
    "621": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #621",
                "description": "description",
                "dna": "85ec41a618e09fef27863a3324a6dc621661c798",
                "date": 1683437778995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "621",
                "rarity_score": 176.4342024330445,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/621.png",
            "scheme": "https"
        }
    },
    "1272": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1272",
                "description": "description",
                "dna": "c5feef518a4c432a49a7ef9719f9ebdbbc675b04",
                "date": 1683434103076,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1272",
                "rarity_score": 151.67701626503353,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1272.png",
            "scheme": "https"
        }
    },
    "3948": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3948",
                "description": "description",
                "dna": "7e418a0ffddf91dd6825c073407413cdefd53842",
                "date": 1683444423010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3948",
                "rarity_score": 183.91591049306624,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3948.png",
            "scheme": "https"
        }
    },
    "2134": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2134",
                "description": "description",
                "dna": "88d89862c746444b270c16042a520d78b25183d8",
                "date": 1683440427316,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2134",
                "rarity_score": 164.64472634665927,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2134.png",
            "scheme": "https"
        }
    },
    "1215": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1215",
                "description": "description",
                "dna": "d1d50e89ae996a17963e5a70deeb1d90a9326969",
                "date": 1683440166340,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1215",
                "rarity_score": 195.25577005603503,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1215.png",
            "scheme": "https"
        }
    },
    "2404": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2404",
                "description": "description",
                "dna": "068da4904665299a3f6b7c6da15b3482d10a66a9",
                "date": 1683435236633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2404",
                "rarity_score": 203.55863884914552,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2404.png",
            "scheme": "https"
        }
    },
    "197": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #197",
                "description": "description",
                "dna": "6c950c77dacd3ee3e16e41d8360e941177394f2e",
                "date": 1683429764520,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "197",
                "rarity_score": 239.85621311117998,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/197.png",
            "scheme": "https"
        }
    },
    "1929": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1929",
                "description": "description",
                "dna": "0956eef86de6544c732ff853031132fce0929255",
                "date": 1683440269287,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1929",
                "rarity_score": 180.2290285634363,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1929.png",
            "scheme": "https"
        }
    },
    "2738": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2738",
                "description": "description",
                "dna": "6ec7f9782fe5d3e318c99337a579bb5073e50d6d",
                "date": 1683434675931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2738",
                "rarity_score": 205.05827048346907,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2738.png",
            "scheme": "https"
        }
    },
    "1560": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1560",
                "description": "description",
                "dna": "2572c58383ddcf93e603ddcc474efc59808f6c2e",
                "date": 1683430485803,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1560",
                "rarity_score": 235.82447604552127,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1560.png",
            "scheme": "https"
        }
    },
    "219": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #219",
                "description": "description",
                "dna": "002fd656c5cb27fe6321cebfba08e03f6626a6d5",
                "date": 1683434656591,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "219",
                "rarity_score": 255.15678272683897,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/219.png",
            "scheme": "https"
        }
    },
    "382": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #382",
                "description": "description",
                "dna": "971b0491bece3e0b6e02250496bd29395f76ff3f",
                "date": 1683445608834,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "382",
                "rarity_score": 249.9754526806474,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/382.png",
            "scheme": "https"
        }
    },
    "2010": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2010",
                "description": "description",
                "dna": "ed5e0a2b0a2d0a4db0c060744289cc581e61b0c3",
                "date": 1683430771327,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2010",
                "rarity_score": 159.73640791487458,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2010.png",
            "scheme": "https"
        }
    },
    "1079": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1079",
                "description": "description",
                "dna": "d1d52071535d3b92a905d9c10aa75f5476386f2f",
                "date": 1683429642284,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1079",
                "rarity_score": 174.0008688769993,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1079.png",
            "scheme": "https"
        }
    },
    "265": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #265",
                "description": "description",
                "dna": "2f2868829358e0817d5227b529c64015c4a6cc13",
                "date": 1683432431531,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "265",
                "rarity_score": 203.55120364479592,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/265.png",
            "scheme": "https"
        }
    },
    "1672": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1672",
                "description": "description",
                "dna": "7f613b6fe994f598ba3c6b557beaebb9a1403324",
                "date": 1683440591361,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1672",
                "rarity_score": 163.11302971083845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1672.png",
            "scheme": "https"
        }
    },
    "3226": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3226",
                "description": "description",
                "dna": "5fba49859e7489dbecc93ab2bb6d9d7999814a18",
                "date": 1683434647668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3226",
                "rarity_score": 252.14802578837202,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3226.png",
            "scheme": "https"
        }
    },
    "3781": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3781",
                "description": "description",
                "dna": "ae420f92d8fc22d355e77471cb3d291b079ec724",
                "date": 1683437962337,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3781",
                "rarity_score": 214.13220863194604,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3781.png",
            "scheme": "https"
        }
    },
    "3316": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3316",
                "description": "description",
                "dna": "c1dbf304e4d3359760e58bfdab4333206e01aab1",
                "date": 1683435602519,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3316",
                "rarity_score": 204.7750455065692,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3316.png",
            "scheme": "https"
        }
    },
    "2193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2193",
                "description": "description",
                "dna": "b61effd39807815f1c3c1ed8b14d1c641570c805",
                "date": 1683430762454,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2193",
                "rarity_score": 214.91114036247146,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2193.png",
            "scheme": "https"
        }
    },
    "2321": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2321",
                "description": "description",
                "dna": "dcbde371d2c755e4ae776e29f2621361f5172fc3",
                "date": 1683432304993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2321",
                "rarity_score": 208.29886258858954,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2321.png",
            "scheme": "https"
        }
    },
    "1387": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1387",
                "description": "description",
                "dna": "73dd4884bb4ce1f8f10f843c16a186519bfe26de",
                "date": 1683436953669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1387",
                "rarity_score": 160.55976722323643,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1387.png",
            "scheme": "https"
        }
    },
    "2515": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2515",
                "description": "description",
                "dna": "b24b6bae6de73c4ba7f4ed22108b64cc736aa52e",
                "date": 1683439201211,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2515",
                "rarity_score": 177.21707694038636,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2515.png",
            "scheme": "https"
        }
    },
    "780": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #780",
                "description": "description",
                "dna": "705290cc1e398c2383fd3c91711634dae026cb05",
                "date": 1683431608390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "780",
                "rarity_score": 221.4734231422699,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/780.png",
            "scheme": "https"
        }
    },
    "177": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #177",
                "description": "description",
                "dna": "5d0de5ac5af20f16389f5ae17321ba03c43a2c54",
                "date": 1683431849094,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "177",
                "rarity_score": 171.09620340520866,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/177.png",
            "scheme": "https"
        }
    },
    "2539": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2539",
                "description": "description",
                "dna": "2ba99382968cd0c1fbd9f57e35e369bb55f349f8",
                "date": 1683433852249,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2539",
                "rarity_score": 217.20859829267383,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2539.png",
            "scheme": "https"
        }
    },
    "911": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #911",
                "description": "description",
                "dna": "d71050b95fafe735dce871b3231de94850cf5c13",
                "date": 1683446439105,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "911",
                "rarity_score": 131.69568590153494,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/911.png",
            "scheme": "https"
        }
    },
    "2430": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2430",
                "description": "description",
                "dna": "d99ca15dda95412800138cae5252d997234a3de1",
                "date": 1683436994335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2430",
                "rarity_score": 147.62762544578726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2430.png",
            "scheme": "https"
        }
    },
    "43": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #43",
                "description": "description",
                "dna": "9feb1475cf668cfe1a25003193f6584639f105f7",
                "date": 1683438083314,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "43",
                "rarity_score": 217.0071040047693,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/43.png",
            "scheme": "https"
        }
    },
    "1107": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1107",
                "description": "description",
                "dna": "4a4b006b884e9e8adb43a0f2114b9e26dc051b38",
                "date": 1683440958722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1107",
                "rarity_score": 190.1206650201059,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1107.png",
            "scheme": "https"
        }
    },
    "1357": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1357",
                "description": "description",
                "dna": "a62cca733ceb0239177a34151aa8a1d3a918d1de",
                "date": 1683444995865,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1357",
                "rarity_score": 175.70230441404485,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1357.png",
            "scheme": "https"
        }
    },
    "1005": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1005",
                "description": "description",
                "dna": "704cb9466e0faa1322efdaea1d437d280340cf7c",
                "date": 1683433708778,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1005",
                "rarity_score": 214.9103575022018,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1005.png",
            "scheme": "https"
        }
    },
    "922": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #922",
                "description": "description",
                "dna": "aecc23b511b1d2ae9d0f5dd26a22f6c12e3ae9fb",
                "date": 1683774430938,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "922",
                "rarity_score": 286.4375496056549,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/922.png",
            "scheme": "https"
        }
    },
    "2710": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2710",
                "description": "description",
                "dna": "ea38a980d38c7c4b43f9f509bf0e2795ca64a998",
                "date": 1683774990422,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2710",
                "rarity_score": 294.23483627266415,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2710.png",
            "scheme": "https"
        }
    },
    "3258": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3258",
                "description": "description",
                "dna": "9bf88ad3428f483853bc550f37e80b3b14b486dd",
                "date": 1683774538241,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3258",
                "rarity_score": 200.49072635337095,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3258.png",
            "scheme": "https"
        }
    },
    "3924": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3924",
                "description": "description",
                "dna": "e61c9ba1f80fb04fd3a7240ef3ceb605c439b3fd",
                "date": 1683444798528,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3924",
                "rarity_score": 222.11929708386288,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3924.png",
            "scheme": "https"
        }
    },
    "1195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1195",
                "description": "description",
                "dna": "9569a157d894e308f48e153663cfa5cd5365e2e8",
                "date": 1683429647960,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1195",
                "rarity_score": 213.08194908934257,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1195.png",
            "scheme": "https"
        }
    },
    "1100": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1100",
                "description": "description",
                "dna": "3b6913e8a88f5412b1cddcec720e7c01e1fcf8e6",
                "date": 1683441718495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1100",
                "rarity_score": 191.99712781770927,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1100.png",
            "scheme": "https"
        }
    },
    "2676": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2676",
                "description": "description",
                "dna": "4545645e83c37af5ee39258ec66b78a6754d6f4e",
                "date": 1683429409692,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2676",
                "rarity_score": 232.7108423177,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2676.png",
            "scheme": "https"
        }
    },
    "3200": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3200",
                "description": "description",
                "dna": "51d15c08b5590a241143841d1c4e1e014facb817",
                "date": 1683446419336,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3200",
                "rarity_score": 217.79083455728798,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3200.png",
            "scheme": "https"
        }
    },
    "3546": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3546",
                "description": "description",
                "dna": "267ecb573801f8dc7a719a2590c64fefb916ad9a",
                "date": 1683442108990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3546",
                "rarity_score": 170.3136245355796,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3546.png",
            "scheme": "https"
        }
    },
    "3812": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3812",
                "description": "description",
                "dna": "bb1f7bc7565e0f1dc1d20e2c980b52e78e562c8f",
                "date": 1683445799037,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3812",
                "rarity_score": 215.8867708317366,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3812.png",
            "scheme": "https"
        }
    },
    "598": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #598",
                "description": "description",
                "dna": "0cda17cc664aa41f43495e84f80def3afeac677f",
                "date": 1683434429364,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "598",
                "rarity_score": 153.12580905897363,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/598.png",
            "scheme": "https"
        }
    },
    "1161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1161",
                "description": "description",
                "dna": "4f633be4e5f5ae02f6aac74e86a0150272a52d27",
                "date": 1683432771538,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1161",
                "rarity_score": 161.1036192478664,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1161.png",
            "scheme": "https"
        }
    },
    "2280": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2280",
                "description": "description",
                "dna": "b7b3d07b2014af95833aa8e72bfbfad692100594",
                "date": 1683429883999,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2280",
                "rarity_score": 169.67685716478874,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2280.png",
            "scheme": "https"
        }
    },
    "1468": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1468",
                "description": "description",
                "dna": "5437fa6cf151380f81123e680519df2b1bcf0d02",
                "date": 1683439340395,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1468",
                "rarity_score": 227.99830242669833,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1468.png",
            "scheme": "https"
        }
    },
    "2358": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2358",
                "description": "description",
                "dna": "46e11df8bcdb13ce4f10145919ec8fabe5cb556a",
                "date": 1683434145057,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2358",
                "rarity_score": 195.98143141438905,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2358.png",
            "scheme": "https"
        }
    },
    "1730": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1730",
                "description": "description",
                "dna": "a9626179b4b66865dbb0cc2ef06d1a4572c5a1d5",
                "date": 1683445520997,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1730",
                "rarity_score": 232.04558847832556,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1730.png",
            "scheme": "https"
        }
    },
    "3476": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3476",
                "description": "description",
                "dna": "b12be571de78c38a4e2ccbd9d9ef83e20fa08120",
                "date": 1683445946551,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3476",
                "rarity_score": 122.98414244254451,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3476.png",
            "scheme": "https"
        }
    },
    "1466": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1466",
                "description": "description",
                "dna": "740c4a4dcf07c85ed040c7a7aa57dbfdedc37f35",
                "date": 1683435010918,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1466",
                "rarity_score": 165.36203501450908,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1466.png",
            "scheme": "https"
        }
    },
    "1564": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1564",
                "description": "description",
                "dna": "544cb47b5e27b7be17acbc4a51abbe4e5c8e3bba",
                "date": 1683771595285,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1564",
                "rarity_score": 220.0125836544497,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1564.png",
            "scheme": "https"
        }
    },
    "162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #162",
                "description": "description",
                "dna": "8777da67e62da55afab036e00632d33c6bcba4ce",
                "date": 1683431256962,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "162",
                "rarity_score": 207.3103235338737,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/162.png",
            "scheme": "https"
        }
    },
    "3170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3170",
                "description": "description",
                "dna": "ec44a9ec618ea405d6364f2cdc636e4328a8d765",
                "date": 1683435756033,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "3170",
                "rarity_score": 337.68066862241153,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3170.png",
            "scheme": "https"
        }
    },
    "463": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #463",
                "description": "description",
                "dna": "553f80e91cb16bb8c0c57b374cb9d157d9bcb014",
                "date": 1683446939370,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "463",
                "rarity_score": 233.12685269467698,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/463.png",
            "scheme": "https"
        }
    },
    "3256": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3256",
                "description": "description",
                "dna": "3b2985e56818705090469c3e6390d31ba2572ed0",
                "date": 1683434965683,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3256",
                "rarity_score": 159.98309577772784,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3256.png",
            "scheme": "https"
        }
    },
    "3570": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3570",
                "description": "description",
                "dna": "805d44c9dcca4c1d3d168e2685a3689a5456b142",
                "date": 1683444141029,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3570",
                "rarity_score": 181.32728146208854,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3570.png",
            "scheme": "https"
        }
    },
    "1338": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1338",
                "description": "description",
                "dna": "c204b28ac9cc6358ef801d18a075b902ea4ce633",
                "date": 1683436901634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1338",
                "rarity_score": 200.1621224608356,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1338.png",
            "scheme": "https"
        }
    },
    "2774": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2774",
                "description": "description",
                "dna": "eea93b37ae33b29f98f65c073324887d7ec363d9",
                "date": 1683441449051,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2774",
                "rarity_score": 201.87202387408405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2774.png",
            "scheme": "https"
        }
    },
    "917": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #917",
                "description": "description",
                "dna": "9a25a2eb6a614cdf58f55311ad46ae56d66e1c36",
                "date": 1683432313383,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "917",
                "rarity_score": 161.18299277158852,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/917.png",
            "scheme": "https"
        }
    },
    "841": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #841",
                "description": "description",
                "dna": "9fa4e5c9604a2b5b1439661dc6989d716f5a8701",
                "date": 1683433411907,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "841",
                "rarity_score": 232.20386161630088,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/841.png",
            "scheme": "https"
        }
    },
    "1783": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1783",
                "description": "description",
                "dna": "1272104c8e1243cc4be82faccfc8551d5bba76ac",
                "date": 1683439438848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1783",
                "rarity_score": 175.80613291719672,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1783.png",
            "scheme": "https"
        }
    },
    "1628": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1628",
                "description": "description",
                "dna": "92d50f7329fd6735d32189b69f9f8056244323c6",
                "date": 1683442244448,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1628",
                "rarity_score": 200.170257147612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1628.png",
            "scheme": "https"
        }
    },
    "2332": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2332",
                "description": "description",
                "dna": "e7ed3a5b1a11396db4f27a7ecbf82ff185df90d4",
                "date": 1683434194738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2332",
                "rarity_score": 313.65471644506476,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2332.png",
            "scheme": "https"
        }
    },
    "3658": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3658",
                "description": "description",
                "dna": "20beb7c1e40ec9da8da292132e47c366ceec21be",
                "date": 1683437223503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3658",
                "rarity_score": 136.62763198187884,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3658.png",
            "scheme": "https"
        }
    },
    "536": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #536",
                "description": "description",
                "dna": "8e59debc714b3ca1c57db7ff021b2c253450f547",
                "date": 1683443339838,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "536",
                "rarity_score": 151.63813488700717,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/536.png",
            "scheme": "https"
        }
    },
    "964": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #964",
                "description": "description",
                "dna": "f7f2bcdf0e20e8252a9f3108545bc947c09957a0",
                "date": 1683775044124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "964",
                "rarity_score": 357.67830338509054,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/964.png",
            "scheme": "https"
        }
    },
    "4083": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4083",
                "description": "description",
                "dna": "e8c2014d55178847483e18aa6fe5d700b3a3dd76",
                "date": 1683439690901,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4083",
                "rarity_score": 154.26438863989887,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4083.png",
            "scheme": "https"
        }
    },
    "4051": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4051",
                "description": "description",
                "dna": "f8a96fa1d25d3c2ee5938023b36b6325720135bb",
                "date": 1683441668708,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4051",
                "rarity_score": 192.90745108474138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4051.png",
            "scheme": "https"
        }
    },
    "3248": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3248",
                "description": "description",
                "dna": "94d6a056de168aa6c488de0a4629f31cb81005e4",
                "date": 1683433753397,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3248",
                "rarity_score": 178.3388391904647,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3248.png",
            "scheme": "https"
        }
    },
    "1429": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1429",
                "description": "description",
                "dna": "c890b095ff637b7a455d4f2b7497fa1c023e5d75",
                "date": 1683443904489,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1429",
                "rarity_score": 182.8524946182255,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1429.png",
            "scheme": "https"
        }
    },
    "237": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #237",
                "description": "description",
                "dna": "f02a85f0014b718885e978a9b3bcd93cbcf9fac6",
                "date": 1683431556057,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "237",
                "rarity_score": 159.55680179893636,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/237.png",
            "scheme": "https"
        }
    },
    "1146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1146",
                "description": "description",
                "dna": "d8a47685065dadc4ac0952d55f14491a6388956a",
                "date": 1683444577704,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1146",
                "rarity_score": 169.28352397876134,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1146.png",
            "scheme": "https"
        }
    },
    "2576": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2576",
                "description": "description",
                "dna": "b3b29f456e6c75d1d55c30153c369e20082868d4",
                "date": 1683433039183,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2576",
                "rarity_score": 237.74732405143342,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2576.png",
            "scheme": "https"
        }
    },
    "3990": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3990",
                "description": "description",
                "dna": "f423d11e2e5a82a1c4835482c05d291c8cd8b406",
                "date": 1683441678258,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3990",
                "rarity_score": 185.02590993014192,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3990.png",
            "scheme": "https"
        }
    },
    "3794": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3794",
                "description": "description",
                "dna": "afb019ba94d5b15798efb5f9a773c77b61be46ee",
                "date": 1683432757576,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3794",
                "rarity_score": 168.4924500715654,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3794.png",
            "scheme": "https"
        }
    },
    "2390": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2390",
                "description": "description",
                "dna": "5b5f1d0efccadc3e67c2c505e35b2a681f6a68cc",
                "date": 1683776561998,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2390",
                "rarity_score": 192.12078390008057,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2390.png",
            "scheme": "https"
        }
    },
    "607": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #607",
                "description": "description",
                "dna": "4f9b7c5c0592e9595f103a62c5adab9f91860bff",
                "date": 1683437301957,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "607",
                "rarity_score": 141.65332989198257,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/607.png",
            "scheme": "https"
        }
    },
    "3191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3191",
                "description": "description",
                "dna": "7ed547a0406debcc15de7839a9359d8757450745",
                "date": 1683445315890,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3191",
                "rarity_score": 221.7621384307618,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3191.png",
            "scheme": "https"
        }
    },
    "2529": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2529",
                "description": "description",
                "dna": "d22979bad6e52bfc448e5278af27eaeee851b811",
                "date": 1683441007035,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2529",
                "rarity_score": 153.7115105932848,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2529.png",
            "scheme": "https"
        }
    },
    "2705": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2705",
                "description": "description",
                "dna": "f96f2ba5e4024aebbc371198386dad12329f0558",
                "date": 1683444590126,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2705",
                "rarity_score": 293.307764090843,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2705.png",
            "scheme": "https"
        }
    },
    "3346": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3346",
                "description": "description",
                "dna": "f72be557e25afbb1f702cdd6ff2b6382f9b7826a",
                "date": 1683433731156,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3346",
                "rarity_score": 152.67895576070703,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3346.png",
            "scheme": "https"
        }
    },
    "3261": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3261",
                "description": "description",
                "dna": "6e25e4bbb483d9c870008ef9756062873a559e76",
                "date": 1683429366295,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3261",
                "rarity_score": 239.62226146440935,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3261.png",
            "scheme": "https"
        }
    },
    "3970": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3970",
                "description": "description",
                "dna": "494545342df1f4b91664075810d8f403fddad491",
                "date": 1683446684213,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3970",
                "rarity_score": 162.19392790505944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3970.png",
            "scheme": "https"
        }
    },
    "743": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #743",
                "description": "description",
                "dna": "469993d3c52effc55efa03565b8dd0cb5be817c8",
                "date": 1683775104187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "743",
                "rarity_score": 197.93887042392146,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/743.png",
            "scheme": "https"
        }
    },
    "879": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #879",
                "description": "description",
                "dna": "0c596e8d07b0f62a4ff172e387415700c1eed886",
                "date": 1683437940301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "879",
                "rarity_score": 180.99852631219144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/879.png",
            "scheme": "https"
        }
    },
    "3408": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3408",
                "description": "description",
                "dna": "22f78782865a1272854745a28ddbbe95c2290d29",
                "date": 1683440992812,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3408",
                "rarity_score": 289.7313284599141,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3408.png",
            "scheme": "https"
        }
    },
    "2934": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2934",
                "description": "description",
                "dna": "27421d89cfab699d355e3d78248a58a195b82410",
                "date": 1683439308859,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2934",
                "rarity_score": 204.60155497085015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2934.png",
            "scheme": "https"
        }
    },
    "2555": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2555",
                "description": "description",
                "dna": "7dafb3ed62047229105a66bebe01b990c0058311",
                "date": 1683444188709,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2555",
                "rarity_score": 206.13239359166732,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2555.png",
            "scheme": "https"
        }
    },
    "2700": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2700",
                "description": "description",
                "dna": "a35673cf048519782b3319afd8d0bd1967e57454",
                "date": 1683442398056,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2700",
                "rarity_score": 248.50245635029503,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2700.png",
            "scheme": "https"
        }
    },
    "2366": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2366",
                "description": "description",
                "dna": "ba44e5edfaefc85a1d7bf215d12eaece3ddc0e8d",
                "date": 1683445405374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2366",
                "rarity_score": 213.61590303004905,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2366.png",
            "scheme": "https"
        }
    },
    "1028": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1028",
                "description": "description",
                "dna": "ce3520fae84e2b0708c3bbed139e729e130cd4c9",
                "date": 1683433087015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "1028",
                "rarity_score": 316.5736187360218,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1028.png",
            "scheme": "https"
        }
    },
    "648": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #648",
                "description": "description",
                "dna": "b8e34388132b7e94faf151e3a1d65b2545b5a3ad",
                "date": 1683445185797,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "648",
                "rarity_score": 186.61798408398988,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/648.png",
            "scheme": "https"
        }
    },
    "3062": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3062",
                "description": "description",
                "dna": "8cfff61ab3233ab1c8a11d30bd783eb40bee21d1",
                "date": 1683430020622,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3062",
                "rarity_score": 160.3091187424021,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3062.png",
            "scheme": "https"
        }
    },
    "1643": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1643",
                "description": "description",
                "dna": "a60fb483ae3e0ac799965d117f0110c1f7cdb289",
                "date": 1683432524869,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1643",
                "rarity_score": 224.09025107881433,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1643.png",
            "scheme": "https"
        }
    },
    "1178": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1178",
                "description": "description",
                "dna": "19ecc38648feda06d5076c931a464eca55814cb2",
                "date": 1683442826278,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1178",
                "rarity_score": 228.1153353365342,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1178.png",
            "scheme": "https"
        }
    },
    "1012": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1012",
                "description": "description",
                "dna": "a823e852dc72cfe109bb44cf22342ab0512b5c23",
                "date": 1683446871553,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1012",
                "rarity_score": 179.1976284450241,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1012.png",
            "scheme": "https"
        }
    },
    "2267": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2267",
                "description": "description",
                "dna": "832052b979437fe61a2bab2e37f0c9fee808c459",
                "date": 1683436415553,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2267",
                "rarity_score": 202.98088336859178,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2267.png",
            "scheme": "https"
        }
    },
    "3961": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3961",
                "description": "description",
                "dna": "ea2a61b3c8f68bd9e479d0f60cb3edf7c65bb1ac",
                "date": 1683771599466,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3961",
                "rarity_score": 333.10975364057157,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3961.png",
            "scheme": "https"
        }
    },
    "797": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #797",
                "description": "description",
                "dna": "54766f42947984619c3b3d7cfc0cb17026ec2f72",
                "date": 1683445418060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "797",
                "rarity_score": 207.07868931228018,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/797.png",
            "scheme": "https"
        }
    },
    "1995": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1995",
                "description": "description",
                "dna": "e658fe03a978a7380c43c2fb5f8bf9b4db04d0ab",
                "date": 1683438113627,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1995",
                "rarity_score": 217.517366930471,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1995.png",
            "scheme": "https"
        }
    },
    "2003": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2003",
                "description": "description",
                "dna": "b42e425f4f54c1bb5512921f360d2f17842ab3f6",
                "date": 1683435936233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2003",
                "rarity_score": 156.11797710062177,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2003.png",
            "scheme": "https"
        }
    },
    "2205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2205",
                "description": "description",
                "dna": "bdb0a460a866345e2dfba8c442665bec2e4b0e84",
                "date": 1683443838834,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2205",
                "rarity_score": 175.84889039424627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2205.png",
            "scheme": "https"
        }
    },
    "3802": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3802",
                "description": "description",
                "dna": "91857395ee71c98448b2ff8a2b14c847a9a7d65e",
                "date": 1683776345932,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3802",
                "rarity_score": 208.3568397201793,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3802.png",
            "scheme": "https"
        }
    },
    "318": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #318",
                "description": "description",
                "dna": "301c76cf91cd64c45084ec52617261a7b91d0cf8",
                "date": 1683442014877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "318",
                "rarity_score": 166.2249970272837,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/318.png",
            "scheme": "https"
        }
    },
    "3164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3164",
                "description": "description",
                "dna": "99b74c8a1c5bff7ce01ac18a2e72c1a0aafb8035",
                "date": 1683438321292,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3164",
                "rarity_score": 267.42686226739903,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3164.png",
            "scheme": "https"
        }
    },
    "656": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #656",
                "description": "description",
                "dna": "029ac55f90ce626dd4b708eb67c62415c4adf6a6",
                "date": 1683446097669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "656",
                "rarity_score": 192.73318285861194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/656.png",
            "scheme": "https"
        }
    },
    "793": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #793",
                "description": "description",
                "dna": "c7ba7055fa8f31d6188fce3531fbaca7307de0fc",
                "date": 1683770594120,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "793",
                "rarity_score": 297.18560205866885,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/793.png",
            "scheme": "https"
        }
    },
    "2309": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2309",
                "description": "description",
                "dna": "fbb1f110aec09a53923f6d82032f932677fac1e2",
                "date": 1683431252700,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2309",
                "rarity_score": 271.31126687718034,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2309.png",
            "scheme": "https"
        }
    },
    "2593": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2593",
                "description": "description",
                "dna": "bbc5c167a0c9eb922a14a36051ea53b6f9c21697",
                "date": 1683442942298,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2593",
                "rarity_score": 298.28620331383917,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2593.png",
            "scheme": "https"
        }
    },
    "3474": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3474",
                "description": "description",
                "dna": "b4bfdaac50278a2ad2a0a387afbc7a23383fcd8d",
                "date": 1683441011869,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3474",
                "rarity_score": 185.27698549843615,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3474.png",
            "scheme": "https"
        }
    },
    "3462": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3462",
                "description": "description",
                "dna": "8e9aebf59c00c999f9fbaad961f1e41334b38598",
                "date": 1683771612217,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3462",
                "rarity_score": 212.1630228363799,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3462.png",
            "scheme": "https"
        }
    },
    "1470": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1470",
                "description": "description",
                "dna": "4594c44f63b44348000ac87885ce28d5a9eddf29",
                "date": 1683435359985,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1470",
                "rarity_score": 188.11621510811935,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1470.png",
            "scheme": "https"
        }
    },
    "3230": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3230",
                "description": "description",
                "dna": "5e41604784f34d1fcb2854ee7c9eae6ac525aafd",
                "date": 1683439819288,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3230",
                "rarity_score": 168.90181182630153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3230.png",
            "scheme": "https"
        }
    },
    "570": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #570",
                "description": "description",
                "dna": "a6c260fc30c8ab6d4e33cf55252acce07cbf669c",
                "date": 1683433021073,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "570",
                "rarity_score": 173.27706552318122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/570.png",
            "scheme": "https"
        }
    },
    "1102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1102",
                "description": "description",
                "dna": "ab114e1426ab0addea45c5ffb1ed99ce87178809",
                "date": 1683442890600,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1102",
                "rarity_score": 234.57271723667836,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1102.png",
            "scheme": "https"
        }
    },
    "1436": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1436",
                "description": "description",
                "dna": "9c4bdb87413ea4697a5d0f49b44805231f59059e",
                "date": 1683439507334,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1436",
                "rarity_score": 161.34925337849546,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1436.png",
            "scheme": "https"
        }
    },
    "827": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #827",
                "description": "description",
                "dna": "7f769d64cb27959b3962e5f5fdd231cec879a544",
                "date": 1683437400673,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "827",
                "rarity_score": 187.2009036516144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/827.png",
            "scheme": "https"
        }
    },
    "2923": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2923",
                "description": "description",
                "dna": "5db289511b5f774c56d182bfa7ec61dbd82ccb7a",
                "date": 1683446170548,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2923",
                "rarity_score": 127.21031764465341,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2923.png",
            "scheme": "https"
        }
    },
    "3741": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3741",
                "description": "description",
                "dna": "627261b82bf9573ed9205699080586d9ad25a42f",
                "date": 1683775235907,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3741",
                "rarity_score": 174.17329441100068,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3741.png",
            "scheme": "https"
        }
    },
    "2534": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2534",
                "description": "description",
                "dna": "cca1480c6546351e9653670088298160fcc84081",
                "date": 1683776574152,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2534",
                "rarity_score": 171.24814442678704,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2534.png",
            "scheme": "https"
        }
    },
    "297": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #297",
                "description": "description",
                "dna": "63cec4a86a4ebbae8d2bb59f0fad874f269a1146",
                "date": 1683442458638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "297",
                "rarity_score": 154.9165428323734,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/297.png",
            "scheme": "https"
        }
    },
    "1775": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1775",
                "description": "description",
                "dna": "222fc71f1b48019904dee37a98b2c109716857ed",
                "date": 1683442206154,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1775",
                "rarity_score": 228.90848484125144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1775.png",
            "scheme": "https"
        }
    },
    "2533": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2533",
                "description": "description",
                "dna": "7c64ee40b0e0ec572f5b17b4e66689ebe3bfe319",
                "date": 1683439988118,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2533",
                "rarity_score": 165.16997828177182,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2533.png",
            "scheme": "https"
        }
    },
    "763": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #763",
                "description": "description",
                "dna": "1d8a3b0b82dc340c7f9df3614af3c19ff4dfd779",
                "date": 1683774502327,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "763",
                "rarity_score": 257.9522451906163,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/763.png",
            "scheme": "https"
        }
    },
    "2016": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2016",
                "description": "description",
                "dna": "50951f9bd2a2133a4ba651241e228f4fd79e6f90",
                "date": 1683435430356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2016",
                "rarity_score": 192.74797402590178,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2016.png",
            "scheme": "https"
        }
    },
    "1586": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1586",
                "description": "description",
                "dna": "d4242ee8f7f4c334e085f4fc33fa048b70e23766",
                "date": 1683436515302,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1586",
                "rarity_score": 210.50964909342292,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1586.png",
            "scheme": "https"
        }
    },
    "1780": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1780",
                "description": "description",
                "dna": "5277c2ae1315763b26ee7df52da14f9d7479e672",
                "date": 1683435917977,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1780",
                "rarity_score": 197.1474085298774,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1780.png",
            "scheme": "https"
        }
    },
    "315": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #315",
                "description": "description",
                "dna": "0c6a366bad9c030877301dcbeac031753c96c4b7",
                "date": 1683432812142,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "315",
                "rarity_score": 245.6826926348452,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/315.png",
            "scheme": "https"
        }
    },
    "1031": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1031",
                "description": "description",
                "dna": "e64f2f702fe6be412624af88b5b0334f5eea49e5",
                "date": 1683434181237,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1031",
                "rarity_score": 163.42139961846922,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1031.png",
            "scheme": "https"
        }
    },
    "816": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #816",
                "description": "description",
                "dna": "6e826b268153dc09394a9c3ed956249765b183ca",
                "date": 1683433722640,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "816",
                "rarity_score": 268.7990056470177,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/816.png",
            "scheme": "https"
        }
    },
    "1423": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1423",
                "description": "description",
                "dna": "3297bb00e0e85d792ea422734b94556c32c018ae",
                "date": 1683431225539,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1423",
                "rarity_score": 187.76743346407144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1423.png",
            "scheme": "https"
        }
    },
    "2328": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2328",
                "description": "description",
                "dna": "b1ac1764e1435f3359be1c95138e1255160e3a34",
                "date": 1683445383937,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2328",
                "rarity_score": 191.92688770716794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2328.png",
            "scheme": "https"
        }
    },
    "3446": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3446",
                "description": "description",
                "dna": "692709e78974535a71edfa782c4aeea9726c9c62",
                "date": 1683441179429,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3446",
                "rarity_score": 184.2508567862962,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3446.png",
            "scheme": "https"
        }
    },
    "1627": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1627",
                "description": "description",
                "dna": "443bd219d52dfab52cb64a8ab8fc193d026f9a25",
                "date": 1683434961459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1627",
                "rarity_score": 163.21253606668338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1627.png",
            "scheme": "https"
        }
    },
    "1471": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1471",
                "description": "description",
                "dna": "6bfe49a13582e873e7be4833f8e94d4ea7c4c885",
                "date": 1683432255635,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1471",
                "rarity_score": 220.17416549268228,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1471.png",
            "scheme": "https"
        }
    },
    "3789": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3789",
                "description": "description",
                "dna": "39b45867d8dda530c9e9590e4b8922bedb981921",
                "date": 1683444388756,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3789",
                "rarity_score": 203.2032853805818,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3789.png",
            "scheme": "https"
        }
    },
    "3922": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3922",
                "description": "description",
                "dna": "b9fb363238a275d55eb7625b2f42d47ce8cc5146",
                "date": 1683771357853,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3922",
                "rarity_score": 176.314054115818,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3922.png",
            "scheme": "https"
        }
    },
    "1732": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1732",
                "description": "description",
                "dna": "6068ec4e8f1730a73fb253d52c16ec62c5653689",
                "date": 1683436312492,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1732",
                "rarity_score": 323.95458837395483,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1732.png",
            "scheme": "https"
        }
    },
    "3411": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3411",
                "description": "description",
                "dna": "0dfa63528d84d7a23d3117e0086becb16f1b0b03",
                "date": 1683436710273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3411",
                "rarity_score": 313.7833157069769,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3411.png",
            "scheme": "https"
        }
    },
    "507": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #507",
                "description": "description",
                "dna": "7a22a7a2af0cda5165045a03f37c272fba072413",
                "date": 1683439129015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "507",
                "rarity_score": 231.24273155107548,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/507.png",
            "scheme": "https"
        }
    },
    "2903": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2903",
                "description": "description",
                "dna": "27b313d3bee0e58834bb5ad6d4895219ffb9e5e5",
                "date": 1683445538555,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2903",
                "rarity_score": 219.06184875532244,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2903.png",
            "scheme": "https"
        }
    },
    "509": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #509",
                "description": "description",
                "dna": "4f8d70aa6458c42d81ea3dd2fc613a338b1b90d2",
                "date": 1683440065653,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "509",
                "rarity_score": 216.4152482004869,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/509.png",
            "scheme": "https"
        }
    },
    "378": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #378",
                "description": "description",
                "dna": "abaefd86d08809d9dd0816cfa8dcb2c879023f78",
                "date": 1683774924641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "378",
                "rarity_score": 161.75866310918732,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/378.png",
            "scheme": "https"
        }
    },
    "1165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1165",
                "description": "description",
                "dna": "d84f321269c2acab4615575992129aa0dd6ea6e5",
                "date": 1683444668954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1165",
                "rarity_score": 225.03609854031941,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1165.png",
            "scheme": "https"
        }
    },
    "1667": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1667",
                "description": "description",
                "dna": "01d77876c6edf7666c505385cfcac0a61c233ae2",
                "date": 1683439169338,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1667",
                "rarity_score": 186.00704224327814,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1667.png",
            "scheme": "https"
        }
    },
    "1741": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1741",
                "description": "description",
                "dna": "53c48d634e78a71a882c81863e3bfe13dda5e172",
                "date": 1683447322907,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1741",
                "rarity_score": 330.6942437868594,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1741.png",
            "scheme": "https"
        }
    },
    "3758": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3758",
                "description": "description",
                "dna": "e07def2caa5daa2b7986e03998afd43a3291b348",
                "date": 1683429482200,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3758",
                "rarity_score": 310.75641180485997,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3758.png",
            "scheme": "https"
        }
    },
    "836": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #836",
                "description": "description",
                "dna": "50203c35c04be74a60e79a610e7f0ac02bc116fe",
                "date": 1683430029308,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "836",
                "rarity_score": 189.77149325013477,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/836.png",
            "scheme": "https"
        }
    },
    "4059": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4059",
                "description": "description",
                "dna": "813dedae758ea9464b372b3ddf3232256294b59a",
                "date": 1683442355252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4059",
                "rarity_score": 185.18452037981987,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4059.png",
            "scheme": "https"
        }
    },
    "1528": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1528",
                "description": "description",
                "dna": "25d0d1973b9b15514e0f3984d75a4b6f1e76c21e",
                "date": 1683443344191,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1528",
                "rarity_score": 213.74216459125978,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1528.png",
            "scheme": "https"
        }
    },
    "1487": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1487",
                "description": "description",
                "dna": "99c46d87c83493248a45905aed383ed4f0873785",
                "date": 1683446070700,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1487",
                "rarity_score": 244.11343164927803,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1487.png",
            "scheme": "https"
        }
    },
    "514": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #514",
                "description": "description",
                "dna": "08b708c5099178ba2e45b52e89d80f977f30e346",
                "date": 1683446296843,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "514",
                "rarity_score": 150.88409514444166,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/514.png",
            "scheme": "https"
        }
    },
    "1402": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1402",
                "description": "description",
                "dna": "714d516f74ee9144c8d40c9ae531f38fe8cf0634",
                "date": 1683447023297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1402",
                "rarity_score": 183.66198260974082,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1402.png",
            "scheme": "https"
        }
    },
    "2622": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2622",
                "description": "description",
                "dna": "59ee07298511e95e21ca8356e8da4d606f942ed5",
                "date": 1683445768288,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2622",
                "rarity_score": 204.17175878647092,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2622.png",
            "scheme": "https"
        }
    },
    "1926": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1926",
                "description": "description",
                "dna": "05bbde81cad41c6623626e48b84d51c2ee817c1a",
                "date": 1683435701007,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1926",
                "rarity_score": 271.7373047936634,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1926.png",
            "scheme": "https"
        }
    },
    "871": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #871",
                "description": "description",
                "dna": "dea2ef9213704de58264f0dae97c51bc565ca295",
                "date": 1683430948054,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "871",
                "rarity_score": 206.47654492238402,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/871.png",
            "scheme": "https"
        }
    },
    "3168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3168",
                "description": "description",
                "dna": "0bb0a28ccf9d3c0f288cc343b89c826f3e2bbd6b",
                "date": 1683438219884,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3168",
                "rarity_score": 176.45772276696496,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3168.png",
            "scheme": "https"
        }
    },
    "1826": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1826",
                "description": "description",
                "dna": "d57953cb09165314a573bedfb119f2fb2e5a0291",
                "date": 1683436450746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1826",
                "rarity_score": 238.6792827547433,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1826.png",
            "scheme": "https"
        }
    },
    "1895": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1895",
                "description": "description",
                "dna": "fa54a088f8238642fbdacaf6ee2c4a6fe723caa3",
                "date": 1683441138225,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1895",
                "rarity_score": 245.36052604799346,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1895.png",
            "scheme": "https"
        }
    },
    "2187": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2187",
                "description": "description",
                "dna": "66b7e49d1faff5d2ac28b6b02fbd73de4ae5ecb6",
                "date": 1683446178388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2187",
                "rarity_score": 189.3746581904726,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2187.png",
            "scheme": "https"
        }
    },
    "2354": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2354",
                "description": "description",
                "dna": "9fbc63ea7b7b40ea86f6e82520b6c1c7ae9c91b5",
                "date": 1683434502658,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2354",
                "rarity_score": 206.8021834878444,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2354.png",
            "scheme": "https"
        }
    },
    "1725": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1725",
                "description": "description",
                "dna": "b279f1299ade6664e079e244403145bb927be889",
                "date": 1683430748581,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1725",
                "rarity_score": 305.0012332902929,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1725.png",
            "scheme": "https"
        }
    },
    "3242": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3242",
                "description": "description",
                "dna": "785f38a2c4298d346fb2a555adbd5aecce449f93",
                "date": 1683435078675,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3242",
                "rarity_score": 217.58036371998875,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3242.png",
            "scheme": "https"
        }
    },
    "3201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3201",
                "description": "description",
                "dna": "f05b3dbbdea96e0fbdf1342bfe96f76fbbe154d6",
                "date": 1683436593132,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3201",
                "rarity_score": 218.8371964414876,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3201.png",
            "scheme": "https"
        }
    },
    "3179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3179",
                "description": "description",
                "dna": "74bfc19532f5841f76fdf8d7d934f80a8cbd7afc",
                "date": 1683439353612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3179",
                "rarity_score": 203.59139907755792,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3179.png",
            "scheme": "https"
        }
    },
    "2068": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2068",
                "description": "description",
                "dna": "9364edf83a587922e3e29646ac4b6ac5bbeeb223",
                "date": 1683435106070,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2068",
                "rarity_score": 162.5753329098005,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2068.png",
            "scheme": "https"
        }
    },
    "1442": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1442",
                "description": "description",
                "dna": "f50b3587af0ef1c39bd7cf68b4ca22a843f74e5e",
                "date": 1683437145363,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1442",
                "rarity_score": 159.8344112185696,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1442.png",
            "scheme": "https"
        }
    },
    "1186": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1186",
                "description": "description",
                "dna": "f10aa34ac41677f2772b0d7be09362a6740d133b",
                "date": 1683438811956,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1186",
                "rarity_score": 170.37416159751652,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1186.png",
            "scheme": "https"
        }
    },
    "2550": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2550",
                "description": "description",
                "dna": "3bc3ed806bd845057e32391e4f7ffaf1cb44a4a5",
                "date": 1683431083928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2550",
                "rarity_score": 173.4736425235121,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2550.png",
            "scheme": "https"
        }
    },
    "2775": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2775",
                "description": "description",
                "dna": "29de021bf350d85aa2ecfbb20ca3b06663b580eb",
                "date": 1683435273441,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2775",
                "rarity_score": 194.99321668157134,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2775.png",
            "scheme": "https"
        }
    },
    "502": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #502",
                "description": "description",
                "dna": "957aec80f009fc986c2d93edb71ce34ebe673b06",
                "date": 1683438876333,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "502",
                "rarity_score": 173.4974072621958,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/502.png",
            "scheme": "https"
        }
    },
    "3447": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3447",
                "description": "description",
                "dna": "92274180481899989075d01d23fa0654fdb9d003",
                "date": 1683442839651,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3447",
                "rarity_score": 221.98944520819074,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3447.png",
            "scheme": "https"
        }
    },
    "1583": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1583",
                "description": "description",
                "dna": "f17dd7d4220f67fd10fbf21e89af4047b1c385eb",
                "date": 1683774605999,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1583",
                "rarity_score": 241.4271616875698,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1583.png",
            "scheme": "https"
        }
    },
    "393": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #393",
                "description": "description",
                "dna": "735c2b95341ac5f994eb5e6782f14dfa30057208",
                "date": 1683447277199,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "393",
                "rarity_score": 177.30629616065337,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/393.png",
            "scheme": "https"
        }
    },
    "486": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #486",
                "description": "description",
                "dna": "add8e028d8b0c93c4614ca9f2435e59ec5f55a81",
                "date": 1683432972819,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "486",
                "rarity_score": 241.14489233915836,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/486.png",
            "scheme": "https"
        }
    },
    "1248": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1248",
                "description": "description",
                "dna": "4fb35370e593e88ecd73fd4a850ba83509a41c58",
                "date": 1683431621903,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1248",
                "rarity_score": 196.4325400229059,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1248.png",
            "scheme": "https"
        }
    },
    "1859": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1859",
                "description": "description",
                "dna": "bbd6fdb838941b69d000ff0bb1d98ae9bd7e7a70",
                "date": 1683436853097,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1859",
                "rarity_score": 177.52975145938885,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1859.png",
            "scheme": "https"
        }
    },
    "1751": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1751",
                "description": "description",
                "dna": "823d02d698b40c1e848fb628a2abf10ec3cdb05a",
                "date": 1683446011923,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1751",
                "rarity_score": 140.33868451675343,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1751.png",
            "scheme": "https"
        }
    },
    "3672": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3672",
                "description": "description",
                "dna": "d99171fa99c444b472db285d8bd8bef626854a94",
                "date": 1683435466754,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3672",
                "rarity_score": 180.29981447222983,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3672.png",
            "scheme": "https"
        }
    },
    "3348": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3348",
                "description": "description",
                "dna": "82274358b70d5cff2fcb3f107c1c451df5e71a22",
                "date": 1683771513575,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3348",
                "rarity_score": 224.75164873411038,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3348.png",
            "scheme": "https"
        }
    },
    "294": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #294",
                "description": "description",
                "dna": "71ac374ca2cb02c59aa65f2f70e01b8346b6672e",
                "date": 1683432212832,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "294",
                "rarity_score": 299.7288301776615,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/294.png",
            "scheme": "https"
        }
    },
    "3895": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3895",
                "description": "description",
                "dna": "5eb4a567c879fb6345aa88a9b0f884ec2681c57c",
                "date": 1683444673703,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3895",
                "rarity_score": 156.3763570780293,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3895.png",
            "scheme": "https"
        }
    },
    "2884": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2884",
                "description": "description",
                "dna": "4731d018ddfe6c817925ec9a33733441f59612a7",
                "date": 1683433025446,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2884",
                "rarity_score": 317.02544206964905,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2884.png",
            "scheme": "https"
        }
    },
    "783": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #783",
                "description": "description",
                "dna": "b5e19f17c441ee94ceca3e124fd496cb22c6ab2a",
                "date": 1683438508121,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "783",
                "rarity_score": 150.77952700232356,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/783.png",
            "scheme": "https"
        }
    },
    "2275": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2275",
                "description": "description",
                "dna": "d845ddf92dc32428ebcc6448f0e9ee46225bc7a0",
                "date": 1683429894142,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "2275",
                "rarity_score": 357.7722367963409,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2275.png",
            "scheme": "https"
        }
    },
    "1766": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1766",
                "description": "description",
                "dna": "28375ff29e1fdfafaf02a5477aae53ddf5663b2a",
                "date": 1683439946940,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1766",
                "rarity_score": 174.9734185185225,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1766.png",
            "scheme": "https"
        }
    },
    "2998": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2998",
                "description": "description",
                "dna": "3bb3b4b0e044402a20843446211e5b57149ff3db",
                "date": 1683437787584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2998",
                "rarity_score": 208.85639467788826,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2998.png",
            "scheme": "https"
        }
    },
    "929": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #929",
                "description": "description",
                "dna": "eddd8369c22744ea7d6c52c8e6cd1e767991ced5",
                "date": 1683441421393,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "929",
                "rarity_score": 209.87767636316798,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/929.png",
            "scheme": "https"
        }
    },
    "2490": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2490",
                "description": "description",
                "dna": "2926b90a32707b914dec8c628aff94863b5fb6af",
                "date": 1683444889942,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2490",
                "rarity_score": 170.99325968172872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2490.png",
            "scheme": "https"
        }
    },
    "3873": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3873",
                "description": "description",
                "dna": "909ad4c1b843303b03f539804fcdd24542e5239d",
                "date": 1683444811195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3873",
                "rarity_score": 178.20709080392143,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3873.png",
            "scheme": "https"
        }
    },
    "544": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #544",
                "description": "description",
                "dna": "2bf213844051b9338fc95cd56b930f21d00871df",
                "date": 1683436241781,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "544",
                "rarity_score": 289.15819528065947,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/544.png",
            "scheme": "https"
        }
    },
    "3597": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3597",
                "description": "description",
                "dna": "1b81b9c78695225fda760844a89f9182150704e3",
                "date": 1683776400524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3597",
                "rarity_score": 204.53533956747012,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3597.png",
            "scheme": "https"
        }
    },
    "503": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #503",
                "description": "description",
                "dna": "8c47e963430fa85eb7ff6a8eabd20af9d1166fcd",
                "date": 1683438368085,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "503",
                "rarity_score": 170.00827117075966,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/503.png",
            "scheme": "https"
        }
    },
    "567": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #567",
                "description": "description",
                "dna": "dd60e92a5f8531e61cfcd3b2f9cbd95ea093316e",
                "date": 1683445670252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "567",
                "rarity_score": 259.8984142065007,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/567.png",
            "scheme": "https"
        }
    },
    "2167": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2167",
                "description": "description",
                "dna": "62afa0a61a48414374210ce120fb03fafd7edcda",
                "date": 1683442988871,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2167",
                "rarity_score": 222.0359219552384,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2167.png",
            "scheme": "https"
        }
    },
    "2520": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2520",
                "description": "description",
                "dna": "1ca93ac6faac75311ed5e4906fdd565c2b8db7a4",
                "date": 1683436942301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2520",
                "rarity_score": 161.3927421587527,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2520.png",
            "scheme": "https"
        }
    },
    "2817": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2817",
                "description": "description",
                "dna": "d086e52fe6ca0a76218e9c7b3ce04a0a52ec2c48",
                "date": 1683438302057,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2817",
                "rarity_score": 232.59925062902596,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2817.png",
            "scheme": "https"
        }
    },
    "3038": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3038",
                "description": "description",
                "dna": "b89deeb39eceb8fc769f9e668ec36fcc887d762e",
                "date": 1683429729008,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3038",
                "rarity_score": 255.96566754486275,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3038.png",
            "scheme": "https"
        }
    },
    "855": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #855",
                "description": "description",
                "dna": "8cdd7ac4d867923768b4c9bb3836991291cea2be",
                "date": 1683432689379,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "855",
                "rarity_score": 254.5675484862544,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/855.png",
            "scheme": "https"
        }
    },
    "1921": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1921",
                "description": "description",
                "dna": "15c9ad99b6e826cb824de99b9d94ea0ed444eb39",
                "date": 1683438036239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1921",
                "rarity_score": 159.4219928855407,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1921.png",
            "scheme": "https"
        }
    },
    "3991": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3991",
                "description": "description",
                "dna": "c84933cc7b1d9413a18c2c1bf649dd9596bd3fa7",
                "date": 1683432360978,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "3991",
                "rarity_score": 339.9886986391059,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3991.png",
            "scheme": "https"
        }
    },
    "2976": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2976",
                "description": "description",
                "dna": "9b5438b8795a84c7c0693c1ed9b971ae1ed4edda",
                "date": 1683434845593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2976",
                "rarity_score": 300.07172721354243,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2976.png",
            "scheme": "https"
        }
    },
    "1758": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1758",
                "description": "description",
                "dna": "0def0d15cb057abdc277b3625c2ff8e225fceed6",
                "date": 1683437752202,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1758",
                "rarity_score": 188.3534745500458,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1758.png",
            "scheme": "https"
        }
    },
    "3583": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3583",
                "description": "description",
                "dna": "1843b0ad44bd086f13465d1690f26b763dedb772",
                "date": 1683441876583,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3583",
                "rarity_score": 154.6791515801245,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3583.png",
            "scheme": "https"
        }
    },
    "2611": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2611",
                "description": "description",
                "dna": "63986418b0bb0549757a2e0896241b9fa3dfce62",
                "date": 1683432830302,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2611",
                "rarity_score": 292.26241595042404,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2611.png",
            "scheme": "https"
        }
    },
    "3950": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3950",
                "description": "description",
                "dna": "c989f87287abde48da02b177d6878b849f9733bf",
                "date": 1683776607808,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3950",
                "rarity_score": 195.4491970935479,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3950.png",
            "scheme": "https"
        }
    },
    "1659": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1659",
                "description": "description",
                "dna": "d3111b61f5788eead80156cf9491963e8bf709d5",
                "date": 1683441016438,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1659",
                "rarity_score": 222.6667634524857,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1659.png",
            "scheme": "https"
        }
    },
    "2772": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2772",
                "description": "description",
                "dna": "87c43f650590afaa263867bfbcbad56f9fffbeae",
                "date": 1683436733341,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2772",
                "rarity_score": 215.042206047138,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2772.png",
            "scheme": "https"
        }
    },
    "2281": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2281",
                "description": "description",
                "dna": "434e369df7b0f5f721d8b3659873e48e9b407fec",
                "date": 1683436575069,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2281",
                "rarity_score": 242.40012835484288,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2281.png",
            "scheme": "https"
        }
    },
    "1841": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1841",
                "description": "description",
                "dna": "618bbd073cea5acaf25c6a4f88610a59e3077f00",
                "date": 1683438177519,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1841",
                "rarity_score": 227.76041838312284,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1841.png",
            "scheme": "https"
        }
    },
    "350": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #350",
                "description": "description",
                "dna": "094d685f9c33f17d645a126334740c549eceeb4c",
                "date": 1683435583201,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "350",
                "rarity_score": 234.93749401585512,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/350.png",
            "scheme": "https"
        }
    },
    "2164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2164",
                "description": "description",
                "dna": "bddf89d56960de89f61bd375c65462520b557cc8",
                "date": 1683445657089,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2164",
                "rarity_score": 255.170354805309,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2164.png",
            "scheme": "https"
        }
    },
    "3234": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3234",
                "description": "description",
                "dna": "1f0c93e2109e250c684aa604e1bd1ca47f5e9257",
                "date": 1683432028577,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3234",
                "rarity_score": 162.16695934990076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3234.png",
            "scheme": "https"
        }
    },
    "2948": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2948",
                "description": "description",
                "dna": "aa0538336e6117afd5c543c445eeca8a7d19f350",
                "date": 1683446826226,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2948",
                "rarity_score": 171.3924358275537,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2948.png",
            "scheme": "https"
        }
    },
    "958": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #958",
                "description": "description",
                "dna": "50aa80c94cf55313f1521c18eb7d815a946ec7e4",
                "date": 1683774946834,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "958",
                "rarity_score": 212.25380332944945,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/958.png",
            "scheme": "https"
        }
    },
    "1711": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1711",
                "description": "description",
                "dna": "f04d389892f1078be190d85ba288500686eafe6e",
                "date": 1683429496382,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1711",
                "rarity_score": 169.91074813270404,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1711.png",
            "scheme": "https"
        }
    },
    "2175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2175",
                "description": "description",
                "dna": "6e81ac6db75fcb2dfb0df5b08d86c4b805497c1e",
                "date": 1683437413010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2175",
                "rarity_score": 126.98404991235076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2175.png",
            "scheme": "https"
        }
    },
    "1582": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1582",
                "description": "description",
                "dna": "f542c2fa6ba928d9a816cbbaf37389592e9a6334",
                "date": 1683435564909,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1582",
                "rarity_score": 274.65950201029966,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1582.png",
            "scheme": "https"
        }
    },
    "1516": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1516",
                "description": "description",
                "dna": "829fd993c9d2a4d598a55eb1bd758698b88230e6",
                "date": 1683440192887,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1516",
                "rarity_score": 167.88702770347535,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1516.png",
            "scheme": "https"
        }
    },
    "897": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #897",
                "description": "description",
                "dna": "aba1fb848897008a9c80cce427136db074de7e87",
                "date": 1683445330318,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "897",
                "rarity_score": 302.87152377637466,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/897.png",
            "scheme": "https"
        }
    },
    "2224": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2224",
                "description": "description",
                "dna": "cf923ad3195d0e99cb5156875f4bfe3f6cbf6891",
                "date": 1683437584950,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2224",
                "rarity_score": 207.36744744570547,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2224.png",
            "scheme": "https"
        }
    },
    "1842": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1842",
                "description": "description",
                "dna": "0d5bf6dec562577e867f0a8169e8addd8f36775e",
                "date": 1683437322964,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1842",
                "rarity_score": 152.30868044938515,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1842.png",
            "scheme": "https"
        }
    },
    "10": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #10",
                "description": "description",
                "dna": "645519e6ad18886e2167f3606b5a4659b444ee1b",
                "date": 1683430098358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "10",
                "rarity_score": 189.59367849160074,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/10.png",
            "scheme": "https"
        }
    },
    "1674": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1674",
                "description": "description",
                "dna": "c2dbe45bc5b8d5d889f55db628682dc97329993d",
                "date": 1683437449841,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1674",
                "rarity_score": 142.88177878890988,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1674.png",
            "scheme": "https"
        }
    },
    "1166": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1166",
                "description": "description",
                "dna": "8a6798187ea2984e6cdb94803a35c8c3a9420843",
                "date": 1683439124992,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1166",
                "rarity_score": 161.64504220860158,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1166.png",
            "scheme": "https"
        }
    },
    "1660": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1660",
                "description": "description",
                "dna": "d57c78d84525988ec81f1a5664dbb1794d61cd10",
                "date": 1683442221248,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1660",
                "rarity_score": 255.18129166604683,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1660.png",
            "scheme": "https"
        }
    },
    "1940": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1940",
                "description": "description",
                "dna": "63c0d703a75e2e590bd10d744a2f7fa165b99306",
                "date": 1683430712632,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1940",
                "rarity_score": 163.32610721866334,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1940.png",
            "scheme": "https"
        }
    },
    "4071": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4071",
                "description": "description",
                "dna": "007cfc023d1d35d7fa548d0cba61803f6701bbde",
                "date": 1683431880243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "4071",
                "rarity_score": 217.92300359091385,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4071.png",
            "scheme": "https"
        }
    },
    "2458": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2458",
                "description": "description",
                "dna": "2c51ced77f1c2eeaf896cac4a942ae3e685ec934",
                "date": 1683430432754,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2458",
                "rarity_score": 162.07111283660834,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2458.png",
            "scheme": "https"
        }
    },
    "371": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #371",
                "description": "description",
                "dna": "61799b61806308e3e72160bb40f31ed422aac221",
                "date": 1683438921870,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "371",
                "rarity_score": 166.7984854100074,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/371.png",
            "scheme": "https"
        }
    },
    "9": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #9",
                "description": "description",
                "dna": "bb410f0fdcd43b9b42e961bd6361722a15492c3f",
                "date": 1683776460744,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "9",
                "rarity_score": 198.1694385782031,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/9.png",
            "scheme": "https"
        }
    },
    "693": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #693",
                "description": "description",
                "dna": "62ad35065a603bb9cadc147ff70b511cdfc0e0f6",
                "date": 1683446817575,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "693",
                "rarity_score": 159.6948716944056,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/693.png",
            "scheme": "https"
        }
    },
    "3588": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3588",
                "description": "description",
                "dna": "309ff1d3af1ff4d88da6954d5092d476a55b0696",
                "date": 1683446507127,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3588",
                "rarity_score": 199.615233861691,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3588.png",
            "scheme": "https"
        }
    },
    "3879": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3879",
                "description": "description",
                "dna": "73c70307624c3a3edbd4d76a76effc5964d78fe6",
                "date": 1683436812332,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3879",
                "rarity_score": 221.19230706138734,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3879.png",
            "scheme": "https"
        }
    },
    "1175": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1175",
                "description": "description",
                "dna": "baa7fba35c249c2de7357c5402555bfe5efb55b9",
                "date": 1683440926623,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1175",
                "rarity_score": 159.6914068496519,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1175.png",
            "scheme": "https"
        }
    },
    "1861": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1861",
                "description": "description",
                "dna": "20a52eceb953be44c0c3395cbdbc2cd6842759df",
                "date": 1683439380310,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1861",
                "rarity_score": 185.18219610122253,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1861.png",
            "scheme": "https"
        }
    },
    "209": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #209",
                "description": "description",
                "dna": "3ec6b083bcdadeb4ef155ea2c76c4e6baa91e006",
                "date": 1683434754542,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "209",
                "rarity_score": 295.5960785446066,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/209.png",
            "scheme": "https"
        }
    },
    "3414": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3414",
                "description": "description",
                "dna": "31cf26b97090dacfec54bffcaea039ecf8e891ac",
                "date": 1683437260052,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3414",
                "rarity_score": 163.49312101840405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3414.png",
            "scheme": "https"
        }
    },
    "2603": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2603",
                "description": "description",
                "dna": "f67229a5880178971467658884475cfc4b64d0bb",
                "date": 1683776523457,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2603",
                "rarity_score": 262.6171178215501,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2603.png",
            "scheme": "https"
        }
    },
    "2685": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2685",
                "description": "description",
                "dna": "041d63d4e8f42e58af65c647958ee5f1367d1c06",
                "date": 1683437174140,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2685",
                "rarity_score": 130.52989434907118,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2685.png",
            "scheme": "https"
        }
    },
    "2870": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2870",
                "description": "description",
                "dna": "99215b38d8b7ef8a591b2862bda74672badb3d05",
                "date": 1683438446926,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2870",
                "rarity_score": 234.38217895979474,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2870.png",
            "scheme": "https"
        }
    },
    "560": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #560",
                "description": "description",
                "dna": "c0721690789ff2c549fd47217622935a1166119c",
                "date": 1683441610123,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "560",
                "rarity_score": 177.72885150597506,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/560.png",
            "scheme": "https"
        }
    },
    "615": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #615",
                "description": "description",
                "dna": "6d43a4b5dd5482e65af4732b06858683f1bca4ee",
                "date": 1683437063290,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "615",
                "rarity_score": 115.69212381403754,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/615.png",
            "scheme": "https"
        }
    },
    "2465": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2465",
                "description": "description",
                "dna": "a0e525d17b9186919ac6d3b2a7f9fc2037863a6e",
                "date": 1683770655747,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2465",
                "rarity_score": 298.3961268310595,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2465.png",
            "scheme": "https"
        }
    },
    "811": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #811",
                "description": "description",
                "dna": "ff3fa986e4399dbe66a488070088ac05135ee102",
                "date": 1683435250479,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "811",
                "rarity_score": 224.7500840440951,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/811.png",
            "scheme": "https"
        }
    },
    "3221": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3221",
                "description": "description",
                "dna": "92276faa2bd0121ac37f04331e1b921a34a744b6",
                "date": 1683445306872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3221",
                "rarity_score": 247.4638834503191,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3221.png",
            "scheme": "https"
        }
    },
    "1370": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1370",
                "description": "description",
                "dna": "f0b0c9094b53c2eb58cd58487697201eaaac979c",
                "date": 1683438100564,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1370",
                "rarity_score": 191.7376312289239,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1370.png",
            "scheme": "https"
        }
    },
    "1890": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1890",
                "description": "description",
                "dna": "cbb1aea3422131601ad6ca8a160b9d5f0650e717",
                "date": 1683436285512,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1890",
                "rarity_score": 225.58531214599327,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1890.png",
            "scheme": "https"
        }
    },
    "3043": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3043",
                "description": "description",
                "dna": "6be709b1f3ff3b9ef17e5a3de3cf0e553a672571",
                "date": 1683446964417,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3043",
                "rarity_score": 314.76254571926813,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3043.png",
            "scheme": "https"
        }
    },
    "3396": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3396",
                "description": "description",
                "dna": "324c88c1d2c89fb5e216683f1054a600c1233995",
                "date": 1683437988380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3396",
                "rarity_score": 275.0084570981357,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3396.png",
            "scheme": "https"
        }
    },
    "1905": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1905",
                "description": "description",
                "dna": "6f4f46d6ea5e4265ada8d01a7b4ebb2a67b82a42",
                "date": 1683443234267,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1905",
                "rarity_score": 193.00787946319298,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1905.png",
            "scheme": "https"
        }
    },
    "2471": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2471",
                "description": "description",
                "dna": "98f464976a2b7287c52b660cc5abdd6b07cf7eb2",
                "date": 1683433172081,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2471",
                "rarity_score": 182.12355052914108,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2471.png",
            "scheme": "https"
        }
    },
    "1935": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1935",
                "description": "description",
                "dna": "f4b0b84b5dbab8377634feb774521bdc5d900d31",
                "date": 1683441053732,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1935",
                "rarity_score": 168.46331086215417,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1935.png",
            "scheme": "https"
        }
    },
    "2981": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2981",
                "description": "description",
                "dna": "2f2cdc809a1e75fc4dddbe39d81ae6c45d057e79",
                "date": 1683774668913,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2981",
                "rarity_score": 213.68128411983457,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2981.png",
            "scheme": "https"
        }
    },
    "3493": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3493",
                "description": "description",
                "dna": "1667cd7f2e30c6b562543aae6e8c322c8d424734",
                "date": 1683432383371,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3493",
                "rarity_score": 169.38313966580972,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3493.png",
            "scheme": "https"
        }
    },
    "3193": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3193",
                "description": "description",
                "dna": "a5c0d7ebcc2a9b9ea4c1435910176282ef44729e",
                "date": 1683446788311,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3193",
                "rarity_score": 201.5678340291774,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3193.png",
            "scheme": "https"
        }
    },
    "1765": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1765",
                "description": "description",
                "dna": "0faa262f2bbe20f4ad87aeea82b84e4f0a75a610",
                "date": 1683440060703,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1765",
                "rarity_score": 197.3651229408482,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1765.png",
            "scheme": "https"
        }
    },
    "3996": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3996",
                "description": "description",
                "dna": "893170e8f456516344666a98f086679dd8c5b026",
                "date": 1683446324535,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3996",
                "rarity_score": 130.0134938750852,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3996.png",
            "scheme": "https"
        }
    },
    "1709": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1709",
                "description": "description",
                "dna": "abc0368dd518dfc7dbd8dee7a72e829fa4699a10",
                "date": 1683776371045,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1709",
                "rarity_score": 232.08377668670445,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1709.png",
            "scheme": "https"
        }
    },
    "488": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #488",
                "description": "description",
                "dna": "3cf9618c9b1e257f3aef6d5f9ceb08fd33dc1ead",
                "date": 1683443070708,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "488",
                "rarity_score": 167.21516750024801,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/488.png",
            "scheme": "https"
        }
    },
    "983": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #983",
                "description": "description",
                "dna": "848c69fb01a0b45e7182f301fc49dbdce387ab9f",
                "date": 1683440636220,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "983",
                "rarity_score": 197.34228423382845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/983.png",
            "scheme": "https"
        }
    },
    "2631": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2631",
                "description": "description",
                "dna": "6eddd0880eb419f8a994c7a34d5cc363c3d06ed4",
                "date": 1683440964211,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2631",
                "rarity_score": 184.66234865890132,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2631.png",
            "scheme": "https"
        }
    },
    "83": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #83",
                "description": "description",
                "dna": "39d73d504d2a1a5294c9d0735693ca8f898b373d",
                "date": 1683442716872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "83",
                "rarity_score": 158.16964791773992,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/83.png",
            "scheme": "https"
        }
    },
    "3708": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3708",
                "description": "description",
                "dna": "0a53bbdf3c2e4a58b4b9bbe5edcf48e18ab0f19c",
                "date": 1683774774790,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3708",
                "rarity_score": 184.63380141067265,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3708.png",
            "scheme": "https"
        }
    },
    "2544": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2544",
                "description": "description",
                "dna": "e6ee74c1744c2034132722a9705a325afa5f6562",
                "date": 1683446128472,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2544",
                "rarity_score": 186.0133416363278,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2544.png",
            "scheme": "https"
        }
    },
    "1360": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1360",
                "description": "description",
                "dna": "90aecda083347be222b799d178e3ce58aab63034",
                "date": 1683438044831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1360",
                "rarity_score": 243.00005836386924,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1360.png",
            "scheme": "https"
        }
    },
    "3429": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3429",
                "description": "description",
                "dna": "b051e7f203c2a08f39dfa7b159e2c80aa03dd04d",
                "date": 1683443168831,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3429",
                "rarity_score": 193.51117505855245,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3429.png",
            "scheme": "https"
        }
    },
    "77": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #77",
                "description": "description",
                "dna": "bad7c14f4a4c4d6aef7bf98873bae5869f9a949e",
                "date": 1683435994489,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "77",
                "rarity_score": 176.85795778614263,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/77.png",
            "scheme": "https"
        }
    },
    "2628": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2628",
                "description": "description",
                "dna": "8cc686b780adeaa28aa5fb625d482f41dd1a4fc1",
                "date": 1683433658951,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2628",
                "rarity_score": 187.91724723863487,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2628.png",
            "scheme": "https"
        }
    },
    "2123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2123",
                "description": "description",
                "dna": "4172824d3d778fcd3d96f86edea8f4580a78e73c",
                "date": 1683445942612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2123",
                "rarity_score": 220.46602538947076,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2123.png",
            "scheme": "https"
        }
    },
    "3626": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3626",
                "description": "description",
                "dna": "aea99f694a503744a9bc750f54fff2298c589667",
                "date": 1683447179607,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3626",
                "rarity_score": 228.37164176297085,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3626.png",
            "scheme": "https"
        }
    },
    "2276": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2276",
                "description": "description",
                "dna": "13120b298cd1417769c9ae20a5821f9b3cbc65a8",
                "date": 1683775069996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2276",
                "rarity_score": 332.24935288114625,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2276.png",
            "scheme": "https"
        }
    },
    "1563": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1563",
                "description": "description",
                "dna": "8796f8d996f32d359d772e23add162be7507cb27",
                "date": 1683774766403,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1563",
                "rarity_score": 172.21305719015243,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1563.png",
            "scheme": "https"
        }
    },
    "3323": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3323",
                "description": "description",
                "dna": "0c380791009620da22ab400212be462ca2cb933e",
                "date": 1683443186893,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3323",
                "rarity_score": 162.1766250391349,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3323.png",
            "scheme": "https"
        }
    },
    "1130": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1130",
                "description": "description",
                "dna": "9f853dc324d019e2179184a439455f9d9ea71344",
                "date": 1683444951580,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1130",
                "rarity_score": 189.2803992188892,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1130.png",
            "scheme": "https"
        }
    },
    "23": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #23",
                "description": "description",
                "dna": "8a21b76958cac12f44dc6e7d1d08520901ad7958",
                "date": 1683438733011,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "23",
                "rarity_score": 241.39046358842887,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/23.png",
            "scheme": "https"
        }
    },
    "3140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3140",
                "description": "description",
                "dna": "17820b2be18899c6f16afe935b1e540df913c5db",
                "date": 1683445000481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3140",
                "rarity_score": 168.48191991617097,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3140.png",
            "scheme": "https"
        }
    },
    "672": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #672",
                "description": "description",
                "dna": "94b2100505be03c93d935cff38823c9c01b638b5",
                "date": 1683440795835,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "672",
                "rarity_score": 160.83655148572632,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/672.png",
            "scheme": "https"
        }
    },
    "2440": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2440",
                "description": "description",
                "dna": "a871d468aa85b415e69d2571d9985c87bf57181b",
                "date": 1683445212333,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2440",
                "rarity_score": 221.75548319235884,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2440.png",
            "scheme": "https"
        }
    },
    "2968": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2968",
                "description": "description",
                "dna": "6143a06928a406b41afdaa6b6541d2cac025b683",
                "date": 1683437979868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2968",
                "rarity_score": 199.11343415043436,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2968.png",
            "scheme": "https"
        }
    },
    "4032": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4032",
                "description": "description",
                "dna": "5358ac9838ee1fbc7fc2798d2597c5ecaa94e728",
                "date": 1683438747374,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "4032",
                "rarity_score": 176.1377948392628,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4032.png",
            "scheme": "https"
        }
    },
    "3608": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3608",
                "description": "description",
                "dna": "9ba7861c6ab416b7c9e9a19e00fcbdcadfa58586",
                "date": 1683444418769,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3608",
                "rarity_score": 274.4753436526285,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3608.png",
            "scheme": "https"
        }
    },
    "2304": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2304",
                "description": "description",
                "dna": "b8c18a23b8938147e5cba3553194e7da81df5163",
                "date": 1683438503450,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2304",
                "rarity_score": 152.78925942950005,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2304.png",
            "scheme": "https"
        }
    },
    "61": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #61",
                "description": "description",
                "dna": "316f2e9bd9d4bd0a0e6184aa12b63e8bfcc0fc41",
                "date": 1683771239131,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "61",
                "rarity_score": 179.02489571906384,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/61.png",
            "scheme": "https"
        }
    },
    "3524": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3524",
                "description": "description",
                "dna": "3c28d39be51e2e6a769a6a5167afcbce7d3a7614",
                "date": 1683441425876,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3524",
                "rarity_score": 183.9924907538349,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3524.png",
            "scheme": "https"
        }
    },
    "3124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3124",
                "description": "description",
                "dna": "42f05bc63600d563a977c357f0f221747a4f9741",
                "date": 1683770469495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3124",
                "rarity_score": 327.7094780245433,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3124.png",
            "scheme": "https"
        }
    },
    "2030": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2030",
                "description": "description",
                "dna": "12e037a25aaf569995ca0981d3731f97c57891b0",
                "date": 1683436459681,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2030",
                "rarity_score": 189.81774389387863,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2030.png",
            "scheme": "https"
        }
    },
    "1125": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1125",
                "description": "description",
                "dna": "b39d7c5a58c092b5bd7c51bb9dea55b11040a9c8",
                "date": 1683433371307,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1125",
                "rarity_score": 199.99211028700265,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1125.png",
            "scheme": "https"
        }
    },
    "1427": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1427",
                "description": "description",
                "dna": "0eb68f523482b301fe0552a63d0e21c720e32a75",
                "date": 1683443042844,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1427",
                "rarity_score": 161.46762024302276,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1427.png",
            "scheme": "https"
        }
    },
    "149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #149",
                "description": "description",
                "dna": "8df7d542b4fe933bff9887969a054422ae5a86af",
                "date": 1683445934829,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "149",
                "rarity_score": 131.67205220035123,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/149.png",
            "scheme": "https"
        }
    },
    "1632": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1632",
                "description": "description",
                "dna": "7d1fb31dea33660d203fc8610de40b6380721b66",
                "date": 1683437614766,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1632",
                "rarity_score": 197.46087930431787,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1632.png",
            "scheme": "https"
        }
    },
    "140": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #140",
                "description": "description",
                "dna": "5815303a2fa9edaf218ef9ace0739191be92d3fc",
                "date": 1683443052155,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "140",
                "rarity_score": 264.2199796708759,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/140.png",
            "scheme": "https"
        }
    },
    "1519": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1519",
                "description": "description",
                "dna": "4aab7b05df37e3a5bb5f7b22bc652a854f2ff3b0",
                "date": 1684016273021,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "Mushroom",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1519",
                "rarity_score": 4260.179426486551,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1519.png",
            "scheme": "https"
        }
    },
    "2559": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2559",
                "description": "description",
                "dna": "948be9046dbb75b9b5c5a9e2d0230e33d779f924",
                "date": 1684016281166,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 2",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2559",
                "rarity_score": 4225.013463052588,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2559.png",
            "scheme": "https"
        }
    },
    "82": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #82",
                "description": "description",
                "dna": "47189e61521876305d51d9837e696b1eb874ff21",
                "date": 1683435980691,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "82",
                "rarity_score": 185.57806349648047,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/82.png",
            "scheme": "https"
        }
    },
    "3293": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3293",
                "description": "description",
                "dna": "beb2cfe08d90e0343ad78a883d15c3028d2aade3",
                "date": 1683437906552,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3293",
                "rarity_score": 205.76019192730269,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3293.png",
            "scheme": "https"
        }
    },
    "662": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #662",
                "description": "description",
                "dna": "bcafaedceaac7f324d07ffe98f3ec52c2e3aff0a",
                "date": 1683444339222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "662",
                "rarity_score": 166.87200319688523,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/662.png",
            "scheme": "https"
        }
    },
    "1892": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1892",
                "description": "description",
                "dna": "e21267bdb4c94c947b5ce9e026e5ee02398af5f2",
                "date": 1683430929330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1892",
                "rarity_score": 166.6076955613752,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1892.png",
            "scheme": "https"
        }
    },
    "3343": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3343",
                "description": "description",
                "dna": "44e75df9d4c38ec555e867def8d873cdc09e2bf6",
                "date": 1683435002273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3343",
                "rarity_score": 233.0434136006988,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3343.png",
            "scheme": "https"
        }
    },
    "3987": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3987",
                "description": "description",
                "dna": "96ddb5d239e41cb7d11a6bd67e0eb3067b1f976d",
                "date": 1683433874435,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3987",
                "rarity_score": 166.336046390953,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3987.png",
            "scheme": "https"
        }
    },
    "1630": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1630",
                "description": "description",
                "dna": "2c47d83346968b21a87121d7146b13d432a00efe",
                "date": 1683437791992,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1630",
                "rarity_score": 217.47490004355558,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1630.png",
            "scheme": "https"
        }
    },
    "1772": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1772",
                "description": "description",
                "dna": "92127d47c834678bb7693234e60549c883d8880c",
                "date": 1683433339071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1772",
                "rarity_score": 273.6897813131229,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1772.png",
            "scheme": "https"
        }
    },
    "998": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #998",
                "description": "description",
                "dna": "71386445c64f49407418cbc0cf3be18b864ef2ce",
                "date": 1683446577560,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "998",
                "rarity_score": 202.36638707929868,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/998.png",
            "scheme": "https"
        }
    },
    "1162": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1162",
                "description": "description",
                "dna": "b566c0087c9c685c0f9d137b972d1055a547551e",
                "date": 1683433926555,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1162",
                "rarity_score": 176.45142958951226,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1162.png",
            "scheme": "https"
        }
    },
    "2408": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2408",
                "description": "description",
                "dna": "c059b401cd91f2dbd26ede787d50462658881ed8",
                "date": 1683441817859,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2408",
                "rarity_score": 188.17466401373133,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2408.png",
            "scheme": "https"
        }
    },
    "1451": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1451",
                "description": "description",
                "dna": "3f2776f797f3dba3baddd3b97c522a9771bbf7bc",
                "date": 1683433949000,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1451",
                "rarity_score": 203.76980138501727,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1451.png",
            "scheme": "https"
        }
    },
    "3020": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3020",
                "description": "description",
                "dna": "3c5c0d24388a6ec8deca3a51a5942ab4c589e7ba",
                "date": 1683429620273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3020",
                "rarity_score": 173.10845586591068,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3020.png",
            "scheme": "https"
        }
    },
    "3615": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3615",
                "description": "description",
                "dna": "3be0f87e1e164de7595c85e1f3512b6c8815fca6",
                "date": 1683437651360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3615",
                "rarity_score": 211.9645069654743,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3615.png",
            "scheme": "https"
        }
    },
    "299": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #299",
                "description": "description",
                "dna": "c9cbba063e33c23a494fc8e3add0012fa5ca31c4",
                "date": 1683442895371,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "299",
                "rarity_score": 170.2410338695993,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/299.png",
            "scheme": "https"
        }
    },
    "2891": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2891",
                "description": "description",
                "dna": "f5ad6dd5b17c302a024099344a2e1085c7287453",
                "date": 1683775253822,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2891",
                "rarity_score": 223.15881179186246,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2891.png",
            "scheme": "https"
        }
    },
    "3727": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3727",
                "description": "description",
                "dna": "5f1dbe7f21df4605b99a2d572be55ffc3fd227c3",
                "date": 1683446086125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3727",
                "rarity_score": 131.70839530974592,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3727.png",
            "scheme": "https"
        }
    },
    "2040": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2040",
                "description": "description",
                "dna": "8324aa5aef26a255aa9a23a9dd1275ed2b0f8a56",
                "date": 1683433654722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2040",
                "rarity_score": 232.53268067988543,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2040.png",
            "scheme": "https"
        }
    },
    "3778": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3778",
                "description": "description",
                "dna": "52769d08d8a0c12651a4c9df7e169555dd756a69",
                "date": 1683437392036,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3778",
                "rarity_score": 120.36020716617273,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3778.png",
            "scheme": "https"
        }
    },
    "592": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #592",
                "description": "description",
                "dna": "7c9ddf201b3fbafe7b9138211c57a32bcec4e396",
                "date": 1683436492053,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "592",
                "rarity_score": 220.5158233887312,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/592.png",
            "scheme": "https"
        }
    },
    "825": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #825",
                "description": "description",
                "dna": "fc6338bc3edb9ce17e2d13415007055425c50780",
                "date": 1683446020060,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "825",
                "rarity_score": 133.3043028648928,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/825.png",
            "scheme": "https"
        }
    },
    "1750": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1750",
                "description": "description",
                "dna": "75474320f37745b04c722a7d0efb1e8336569177",
                "date": 1683437623695,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1750",
                "rarity_score": 158.80608904594234,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1750.png",
            "scheme": "https"
        }
    },
    "851": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #851",
                "description": "description",
                "dna": "9e7b605bca6a62beabea03ad5205aa5c4a1d866a",
                "date": 1683434244652,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "851",
                "rarity_score": 179.03126602033564,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/851.png",
            "scheme": "https"
        }
    },
    "1097": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1097",
                "description": "description",
                "dna": "50fb189f946f9adef48273bb1e36010b65f5483c",
                "date": 1683437739726,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1097",
                "rarity_score": 173.48657996044471,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1097.png",
            "scheme": "https"
        }
    },
    "3647": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3647",
                "description": "description",
                "dna": "9c9e64392eeaa33307e374119a6bf75f94d6d42f",
                "date": 1683771483428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3647",
                "rarity_score": 192.54645090227493,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3647.png",
            "scheme": "https"
        }
    },
    "3143": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3143",
                "description": "description",
                "dna": "881433561d3000573edca9f5dccfbd62a3ca64de",
                "date": 1683434814037,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3143",
                "rarity_score": 185.12100719916083,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3143.png",
            "scheme": "https"
        }
    },
    "4002": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4002",
                "description": "description",
                "dna": "0407ee2a91843c027fbb231789f3cc2d4123b452",
                "date": 1683429773562,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4002",
                "rarity_score": 159.16870386452695,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4002.png",
            "scheme": "https"
        }
    },
    "2745": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2745",
                "description": "description",
                "dna": "c4bce3e299093baad494ea96e12c3b0383b07096",
                "date": 1683442281373,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2745",
                "rarity_score": 241.57824768915688,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2745.png",
            "scheme": "https"
        }
    },
    "936": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #936",
                "description": "description",
                "dna": "5a69c2a7a11865fb3d170ca2b04df8aa9ff407b1",
                "date": 1683447139350,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "936",
                "rarity_score": 225.7829280855349,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/936.png",
            "scheme": "https"
        }
    },
    "2634": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2634",
                "description": "description",
                "dna": "b09f40f4e0c4f50695368809f2c3fba3127e9b76",
                "date": 1683440913503,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2634",
                "rarity_score": 165.15381282346348,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2634.png",
            "scheme": "https"
        }
    },
    "4049": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4049",
                "description": "description",
                "dna": "4216c47dd8df19489bafb171c46823a2c3efc58f",
                "date": 1683445199178,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "4049",
                "rarity_score": 206.41090793275174,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4049.png",
            "scheme": "https"
        }
    },
    "3153": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3153",
                "description": "description",
                "dna": "21f00f9d88ddae84370e7bd079b94f2bd0ef8e1c",
                "date": 1683431604002,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3153",
                "rarity_score": 165.34226062572827,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3153.png",
            "scheme": "https"
        }
    },
    "714": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #714",
                "description": "description",
                "dna": "8e990866162a649391602b3608ee1e0960584dea",
                "date": 1683439502844,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "714",
                "rarity_score": 161.9862830583441,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/714.png",
            "scheme": "https"
        }
    },
    "2563": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2563",
                "description": "description",
                "dna": "0a10aee18379e09da51c1e2568c2b280f1cefe04",
                "date": 1683445988762,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2563",
                "rarity_score": 258.93647258883396,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2563.png",
            "scheme": "https"
        }
    },
    "442": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #442",
                "description": "description",
                "dna": "baf7b1864fd7942a0394d6beee1cf6be9887af02",
                "date": 1683444704363,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "442",
                "rarity_score": 174.9937198524397,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/442.png",
            "scheme": "https"
        }
    },
    "2860": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2860",
                "description": "description",
                "dna": "e6e21cb47eabeee2dc4f77ac8691e042c960fd33",
                "date": 1683432387941,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2860",
                "rarity_score": 248.92739438592326,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2860.png",
            "scheme": "https"
        }
    },
    "405": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #405",
                "description": "description",
                "dna": "fc1ce4036c20370dbe942c509d06a4110b0ef592",
                "date": 1683436026427,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "405",
                "rarity_score": 236.74864864816414,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/405.png",
            "scheme": "https"
        }
    },
    "342": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #342",
                "description": "description",
                "dna": "ab13371d46fb41eb296f081d7d8abbb26449aab0",
                "date": 1683775157625,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "342",
                "rarity_score": 159.34935472102617,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/342.png",
            "scheme": "https"
        }
    },
    "425": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #425",
                "description": "description",
                "dna": "9fa189f6433810503c15481f957cba4409fdbc37",
                "date": 1683445648469,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "425",
                "rarity_score": 273.16817939183795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/425.png",
            "scheme": "https"
        }
    },
    "2660": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2660",
                "description": "description",
                "dna": "9e0ebabc29ab8b9ca5ec60d0c88f93ce415a8bf9",
                "date": 1683438391180,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2660",
                "rarity_score": 186.09447285234458,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2660.png",
            "scheme": "https"
        }
    },
    "142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #142",
                "description": "description",
                "dna": "dffc2e7709e89f950cb016bdee96626ebc18f6c2",
                "date": 1683775148610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "142",
                "rarity_score": 209.1437730388475,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/142.png",
            "scheme": "https"
        }
    },
    "3327": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3327",
                "description": "description",
                "dna": "f8754ef2292b364427268bb7e5a6fe532205dcc3",
                "date": 1683441534796,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3327",
                "rarity_score": 304.72438552240914,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3327.png",
            "scheme": "https"
        }
    },
    "47": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #47",
                "description": "description",
                "dna": "11ceed53d1e51531c690cd1c370460cc17648429",
                "date": 1683436424585,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "47",
                "rarity_score": 186.6140931089102,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/47.png",
            "scheme": "https"
        }
    },
    "3399": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3399",
                "description": "description",
                "dna": "b92460b7a596abfd7cdb192e2df42f66f1360a5f",
                "date": 1683440467949,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3399",
                "rarity_score": 151.60090292724973,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3399.png",
            "scheme": "https"
        }
    },
    "3160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3160",
                "description": "description",
                "dna": "927a7217beb05412888044b38d94356628518a40",
                "date": 1683439081182,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3160",
                "rarity_score": 158.40922986806976,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3160.png",
            "scheme": "https"
        }
    },
    "3285": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3285",
                "description": "description",
                "dna": "b1ab3a6c55e11d33f0e5415d9cba8174a3df7e3d",
                "date": 1683431243830,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3285",
                "rarity_score": 154.2992476734424,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3285.png",
            "scheme": "https"
        }
    },
    "2875": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2875",
                "description": "description",
                "dna": "86b773d2c34e1fed8e9b0d7c86290606ab7881f7",
                "date": 1683433953373,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2875",
                "rarity_score": 141.44959952256994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2875.png",
            "scheme": "https"
        }
    },
    "2637": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2637",
                "description": "description",
                "dna": "4ec1de3c45fdab285a9a1532a91be2290509121a",
                "date": 1683434902297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2637",
                "rarity_score": 280.2735751306803,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2637.png",
            "scheme": "https"
        }
    },
    "1369": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1369",
                "description": "description",
                "dna": "6632cc444b862be6f05aeb58f4088e8207d8a287",
                "date": 1683445283569,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1369",
                "rarity_score": 220.3673184581349,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1369.png",
            "scheme": "https"
        }
    },
    "3301": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3301",
                "description": "description",
                "dna": "6d0242e8210831f7c4fde85d9394bee3181f5c35",
                "date": 1683436326061,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3301",
                "rarity_score": 167.26605064097572,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3301.png",
            "scheme": "https"
        }
    },
    "2543": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2543",
                "description": "description",
                "dna": "929c21001de7de8cb44c60c0013eb62c9b1a1590",
                "date": 1683434735413,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2543",
                "rarity_score": 190.69284744806583,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2543.png",
            "scheme": "https"
        }
    },
    "2080": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2080",
                "description": "description",
                "dna": "786d9a30fa5392c63b76a7555e5e66fa13e21d55",
                "date": 1683438481357,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2080",
                "rarity_score": 171.35691887990427,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2080.png",
            "scheme": "https"
        }
    },
    "823": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #823",
                "description": "description",
                "dna": "594c33c5d55c5c8d240d9820b674ff716913ae0e",
                "date": 1683443449194,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "823",
                "rarity_score": 195.99430464059657,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/823.png",
            "scheme": "https"
        }
    },
    "728": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #728",
                "description": "description",
                "dna": "138107636ce43d7cff2c2e8820217583bcc93f9e",
                "date": 1683444908117,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "728",
                "rarity_score": 188.97433965763585,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/728.png",
            "scheme": "https"
        }
    },
    "3464": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3464",
                "description": "description",
                "dna": "e49c33c96df440c3d75b5e80160cd67a9f081688",
                "date": 1683441124675,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3464",
                "rarity_score": 188.12876296816495,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3464.png",
            "scheme": "https"
        }
    },
    "3281": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3281",
                "description": "description",
                "dna": "8c8f625c1c59567d455e8ffdd514bb3084c00308",
                "date": 1683771251681,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3281",
                "rarity_score": 206.61111545215968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3281.png",
            "scheme": "https"
        }
    },
    "3158": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3158",
                "description": "description",
                "dna": "cd656da10d56ed787d9f58938e32566144df11a4",
                "date": 1684016285319,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard with Blunt",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3158",
                "rarity_score": 4234.631633171757,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3158.png",
            "scheme": "https"
        }
    },
    "2090": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2090",
                "description": "description",
                "dna": "56d908e9955b0daf62be2df224c8ebdddb66b9e4",
                "date": 1683433239963,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2090",
                "rarity_score": 280.39925686258493,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2090.png",
            "scheme": "https"
        }
    },
    "4016": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4016",
                "description": "description",
                "dna": "407413446b6c86dd915a662ee71fa6466673713a",
                "date": 1683439260143,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "4016",
                "rarity_score": 172.29906939363195,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4016.png",
            "scheme": "https"
        }
    },
    "568": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #568",
                "description": "description",
                "dna": "feb94eca8316b48d806b9623a0a6f25c3133a67f",
                "date": 1683776502365,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "568",
                "rarity_score": 219.7221655384042,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/568.png",
            "scheme": "https"
        }
    },
    "1270": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1270",
                "description": "description",
                "dna": "d4f1ece48d6c659234328cde0831bce7b3ac7193",
                "date": 1683442258719,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1270",
                "rarity_score": 251.85368942065884,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1270.png",
            "scheme": "https"
        }
    },
    "2298": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2298",
                "description": "description",
                "dna": "e081f1bce81ef65fd54d8bf1e94ccfa935792e53",
                "date": 1683439919608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2298",
                "rarity_score": 181.64624925475584,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2298.png",
            "scheme": "https"
        }
    },
    "2585": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2585",
                "description": "description",
                "dna": "e6e856cfd720c0dfbdbf1e11bc72ac6bf6d09f01",
                "date": 1683436473556,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2585",
                "rarity_score": 191.46485745423507,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2585.png",
            "scheme": "https"
        }
    },
    "679": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #679",
                "description": "description",
                "dna": "fa0194cc67db00fe0e0f95944404ca1eefb86e5d",
                "date": 1683432221045,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "679",
                "rarity_score": 193.6529303637903,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/679.png",
            "scheme": "https"
        }
    },
    "3580": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3580",
                "description": "description",
                "dna": "f6ba4a22dec3e871ccd2c2fccb9fee653ab1279f",
                "date": 1683433190565,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3580",
                "rarity_score": 243.25309689960238,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3580.png",
            "scheme": "https"
        }
    },
    "3962": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3962",
                "description": "description",
                "dna": "fe81f7e484fccbcab8b800daf32345caa6941156",
                "date": 1683443173166,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3962",
                "rarity_score": 271.1422619777525,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3962.png",
            "scheme": "https"
        }
    },
    "865": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #865",
                "description": "description",
                "dna": "28f415924534a558a0fdd8317f2bee1ef1582f92",
                "date": 1683775007661,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "865",
                "rarity_score": 199.43243816511892,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/865.png",
            "scheme": "https"
        }
    },
    "1973": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1973",
                "description": "description",
                "dna": "5d77f8e4282003ffeb0cb36c14795caf0a38928a",
                "date": 1683434889158,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1973",
                "rarity_score": 155.78229090118788,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1973.png",
            "scheme": "https"
        }
    },
    "3109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3109",
                "description": "description",
                "dna": "0ff48ca153d968f273590d0ffa3d410366ce11ce",
                "date": 1683433975261,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3109",
                "rarity_score": 171.46605918803496,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3109.png",
            "scheme": "https"
        }
    },
    "663": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #663",
                "description": "description",
                "dna": "435053cc4d8a7d72661d57cfe64adc91d694da87",
                "date": 1683432644648,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "663",
                "rarity_score": 197.65697844030754,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/663.png",
            "scheme": "https"
        }
    },
    "336": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #336",
                "description": "description",
                "dna": "edac7fd8e9051a0a62c1d287ccc0583858342de7",
                "date": 1683439027658,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "336",
                "rarity_score": 161.9266614188839,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/336.png",
            "scheme": "https"
        }
    },
    "3851": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3851",
                "description": "description",
                "dna": "e5276920fecf3bb144b4d46c3630fce573271ad7",
                "date": 1683442918444,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3851",
                "rarity_score": 156.138945116002,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3851.png",
            "scheme": "https"
        }
    },
    "2110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2110",
                "description": "description",
                "dna": "c25210539aa44eecb91925363eba7f542ebeb4bd",
                "date": 1683433483937,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2110",
                "rarity_score": 278.54977379136346,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2110.png",
            "scheme": "https"
        }
    },
    "321": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #321",
                "description": "description",
                "dna": "332fe2a1e4ee8d52e2bcdd2d025cac54612a0073",
                "date": 1683431862613,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "321",
                "rarity_score": 165.87650522408157,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/321.png",
            "scheme": "https"
        }
    },
    "2026": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2026",
                "description": "description",
                "dna": "3eda056191d9b53be61cd7e46db9cd6686b5b0b0",
                "date": 1683444112996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2026",
                "rarity_score": 149.9165983004369,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2026.png",
            "scheme": "https"
        }
    },
    "2571": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2571",
                "description": "description",
                "dna": "d64efcf10ae9549512cf8255ce31d61df6e1a7ea",
                "date": 1683442051257,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2571",
                "rarity_score": 177.13173985432474,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2571.png",
            "scheme": "https"
        }
    },
    "932": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #932",
                "description": "description",
                "dna": "13ca97e4c8355bcd375deff9296781a41cedc414",
                "date": 1683774677973,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "932",
                "rarity_score": 175.62325999912503,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/932.png",
            "scheme": "https"
        }
    },
    "4025": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4025",
                "description": "description",
                "dna": "d0bc51f8565a8a9272187aca9e443df391aadc8c",
                "date": 1683430548344,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "4025",
                "rarity_score": 208.17264952828265,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4025.png",
            "scheme": "https"
        }
    },
    "1729": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1729",
                "description": "description",
                "dna": "cf2aa5098922f0b1ded42b688d085deed4b5e0f3",
                "date": 1683440103015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1729",
                "rarity_score": 170.76963212326774,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1729.png",
            "scheme": "https"
        }
    },
    "1980": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1980",
                "description": "description",
                "dna": "6e4599e3f0c34e8373faff99cdfca12563cb22b0",
                "date": 1683443066137,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1980",
                "rarity_score": 203.64326129455438,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1980.png",
            "scheme": "https"
        }
    },
    "2266": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2266",
                "description": "description",
                "dna": "30bc24f44aa680862977893964c175b57a86542d",
                "date": 1683446599392,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2266",
                "rarity_score": 245.16245568559728,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2266.png",
            "scheme": "https"
        }
    },
    "1007": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1007",
                "description": "description",
                "dna": "4d7d5ccaef347cecd4d570966c22ec393a20ff28",
                "date": 1683433816717,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1007",
                "rarity_score": 155.16186091802476,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1007.png",
            "scheme": "https"
        }
    },
    "3322": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3322",
                "description": "description",
                "dna": "d6fdcbb4a461393f4e5044f250b075880137a576",
                "date": 1683429397460,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3322",
                "rarity_score": 175.19222762945404,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3322.png",
            "scheme": "https"
        }
    },
    "3496": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3496",
                "description": "description",
                "dna": "3364a8730ea24c87922bac530d8a91fbdbbd4175",
                "date": 1683440777533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3496",
                "rarity_score": 176.19629648873297,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3496.png",
            "scheme": "https"
        }
    },
    "438": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #438",
                "description": "description",
                "dna": "97d688c69bcb6d5cade06afd53a8be9e2323bb1f",
                "date": 1683446725634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "438",
                "rarity_score": 171.0623656411435,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/438.png",
            "scheme": "https"
        }
    },
    "146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #146",
                "description": "description",
                "dna": "ff1eea6e2af7aa31bc27cfd31e8ef910ace5be48",
                "date": 1683770560028,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "146",
                "rarity_score": 306.95758387483124,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/146.png",
            "scheme": "https"
        }
    },
    "415": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #415",
                "description": "description",
                "dna": "cbf01a421e620c2d2a40a0c381b23d1314e4b694",
                "date": 1683439246256,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "415",
                "rarity_score": 148.35158839468264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/415.png",
            "scheme": "https"
        }
    },
    "3073": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3073",
                "description": "description",
                "dna": "02c11046a55f1a0e8be024e338141390f261540d",
                "date": 1683432867040,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3073",
                "rarity_score": 185.34988495251554,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3073.png",
            "scheme": "https"
        }
    },
    "547": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #547",
                "description": "description",
                "dna": "2c5bd96b7ff0075e49081e2f0f3a5a7e2f3decdc",
                "date": 1683436652802,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "547",
                "rarity_score": 228.2638643603637,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/547.png",
            "scheme": "https"
        }
    },
    "830": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #830",
                "description": "description",
                "dna": "800aabb0072c668fdf55772aa85e2d1eba433923",
                "date": 1683438288662,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "830",
                "rarity_score": 198.84508485827683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/830.png",
            "scheme": "https"
        }
    },
    "1305": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1305",
                "description": "description",
                "dna": "caced57d8c8b47ca5942df9784c34ff535b41b3d",
                "date": 1683774516005,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1305",
                "rarity_score": 235.47404512384247,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1305.png",
            "scheme": "https"
        }
    },
    "2307": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2307",
                "description": "description",
                "dna": "28576fa21b576dce5b55756757adf13bed9c15f1",
                "date": 1683433807661,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2307",
                "rarity_score": 225.92123591371043,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2307.png",
            "scheme": "https"
        }
    },
    "3698": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3698",
                "description": "description",
                "dna": "8f09b5e3c67b822f34406159a7e6393c1d563d68",
                "date": 1683447375685,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3698",
                "rarity_score": 202.73185659992814,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3698.png",
            "scheme": "https"
        }
    },
    "571": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #571",
                "description": "description",
                "dna": "f3e4b688d46cf8c9314f60a6c697af760835e6f6",
                "date": 1683435246143,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "571",
                "rarity_score": 254.36435328504092,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/571.png",
            "scheme": "https"
        }
    },
    "812": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #812",
                "description": "description",
                "dna": "f72aa704a2123f59f44454008163cd2616fdf57f",
                "date": 1683435060862,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "812",
                "rarity_score": 183.7755761873218,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/812.png",
            "scheme": "https"
        }
    },
    "2740": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2740",
                "description": "description",
                "dna": "f3a151232d1befe57a48e3aa761dad76a1850baf",
                "date": 1683438595860,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2740",
                "rarity_score": 192.3108029630613,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2740.png",
            "scheme": "https"
        }
    },
    "1534": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1534",
                "description": "description",
                "dna": "9242fbad9d382984c27b88eeced86ec86a918951",
                "date": 1683439878767,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1534",
                "rarity_score": 334.6711229331938,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1534.png",
            "scheme": "https"
        }
    },
    "1978": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1978",
                "description": "description",
                "dna": "2c4e862d9392e3aeac46ad936e4b79cb2681275e",
                "date": 1683445357184,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1978",
                "rarity_score": 220.1531268818568,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1978.png",
            "scheme": "https"
        }
    },
    "957": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #957",
                "description": "description",
                "dna": "3d9482f44555faca514f13e1f68e7fcf059ea7a0",
                "date": 1683446226222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "957",
                "rarity_score": 142.93540823279233,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/957.png",
            "scheme": "https"
        }
    },
    "1020": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1020",
                "description": "description",
                "dna": "8aa830adda892474dbeb5bdf59a2816634c1e1e8",
                "date": 1683440051853,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1020",
                "rarity_score": 236.62982106513732,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1020.png",
            "scheme": "https"
        }
    },
    "2184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2184",
                "description": "description",
                "dna": "4f46c89adf8aded9d0941b36bcf5a9c1f1ae316a",
                "date": 1683774466550,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2184",
                "rarity_score": 180.65837739346432,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2184.png",
            "scheme": "https"
        }
    },
    "956": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #956",
                "description": "description",
                "dna": "5124bdb92b11fb7ba8cf2e80f54d61eb73f44e80",
                "date": 1683446646227,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "956",
                "rarity_score": 165.689117605967,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/956.png",
            "scheme": "https"
        }
    },
    "3070": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3070",
                "description": "description",
                "dna": "c5148623404249375ead491900a6f92440298317",
                "date": 1683436542405,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3070",
                "rarity_score": 229.82149608333015,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3070.png",
            "scheme": "https"
        }
    },
    "117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #117",
                "description": "description",
                "dna": "976ba496807a2ca8b94719ea7a8bf761e4c29045",
                "date": 1683445786079,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "117",
                "rarity_score": 209.24472849876372,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/117.png",
            "scheme": "https"
        }
    },
    "2169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2169",
                "description": "description",
                "dna": "c0f7aac4069ef32bd87c768727a43ce839162d59",
                "date": 1683445516945,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2169",
                "rarity_score": 173.6869570317181,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2169.png",
            "scheme": "https"
        }
    },
    "2145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2145",
                "description": "description",
                "dna": "2af0fc1fc3365fd97a19fa95264db54455f2faaa",
                "date": 1683446565417,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2145",
                "rarity_score": 139.83520486496485,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2145.png",
            "scheme": "https"
        }
    },
    "951": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #951",
                "description": "description",
                "dna": "09596c9f4b3f222d87e63602ec0b8f2138c90a1a",
                "date": 1683437997212,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "951",
                "rarity_score": 189.1480626267377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/951.png",
            "scheme": "https"
        }
    },
    "3883": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3883",
                "description": "description",
                "dna": "18066baac14d1c89add2d2ddee9d4740e30e0c5c",
                "date": 1683440290424,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3883",
                "rarity_score": 229.60057544339986,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3883.png",
            "scheme": "https"
        }
    },
    "347": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #347",
                "description": "description",
                "dna": "0eee14ef7e31f54129a0fa4d77583faf8a2da345",
                "date": 1683440390982,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "347",
                "rarity_score": 217.0481952219826,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/347.png",
            "scheme": "https"
        }
    },
    "761": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #761",
                "description": "description",
                "dna": "64bb46609cdcfb3b96bd2bd125df6fb8eb6cc49a",
                "date": 1683445595027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "761",
                "rarity_score": 169.51337045467608,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/761.png",
            "scheme": "https"
        }
    },
    "1697": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1697",
                "description": "description",
                "dna": "a6b10503dd9ebe6dd543c06043155811699b552d",
                "date": 1683436008370,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1697",
                "rarity_score": 205.75915886218058,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1697.png",
            "scheme": "https"
        }
    },
    "3847": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3847",
                "description": "description",
                "dna": "bbc7ae4bbff053e11875dc16ef76f2838a578663",
                "date": 1683437376247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3847",
                "rarity_score": 157.41292108548572,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3847.png",
            "scheme": "https"
        }
    },
    "798": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #798",
                "description": "description",
                "dna": "2a4b145b6acb9aac91a41e1a88a2302766f4865d",
                "date": 1683442615402,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "798",
                "rarity_score": 186.57383977802067,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/798.png",
            "scheme": "https"
        }
    },
    "1117": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1117",
                "description": "description",
                "dna": "ef6f2345f242b0fbb524ce1ec56754fe42d09d42",
                "date": 1683435369357,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1117",
                "rarity_score": 154.97041256339648,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1117.png",
            "scheme": "https"
        }
    },
    "323": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #323",
                "description": "description",
                "dna": "c68cd955fd2ba2510fb8da5db99e7a27c0bac833",
                "date": 1683432356124,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "323",
                "rarity_score": 165.254814743662,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/323.png",
            "scheme": "https"
        }
    },
    "426": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #426",
                "description": "description",
                "dna": "e0a2c304e18e5ef2649524f156c27976688d31b5",
                "date": 1683440278096,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "426",
                "rarity_score": 204.79565054843542,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/426.png",
            "scheme": "https"
        }
    },
    "1566": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1566",
                "description": "description",
                "dna": "8d0fbb9df708e4f483e91915d91bc650e620b913",
                "date": 1683432554885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1566",
                "rarity_score": 291.85023472778505,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1566.png",
            "scheme": "https"
        }
    },
    "399": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #399",
                "description": "description",
                "dna": "5fc158d84fd81c5b8be3205844516b904d0e7871",
                "date": 1683435940459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "399",
                "rarity_score": 186.70769602224902,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/399.png",
            "scheme": "https"
        }
    },
    "2535": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2535",
                "description": "description",
                "dna": "b1cf2ce660d994f9ec212149f62c884bafd66ff6",
                "date": 1683438010583,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2535",
                "rarity_score": 227.1470714271017,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2535.png",
            "scheme": "https"
        }
    },
    "978": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #978",
                "description": "description",
                "dna": "55ba4e5f61760ff9eb75f6c204f8096b99e154a7",
                "date": 1683441687316,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "978",
                "rarity_score": 167.82756027325973,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/978.png",
            "scheme": "https"
        }
    },
    "2096": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2096",
                "description": "description",
                "dna": "6d126cc8597b3fea9541e0aa1cd14ee224856675",
                "date": 1683438442172,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2096",
                "rarity_score": 170.7538564427162,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2096.png",
            "scheme": "https"
        }
    },
    "3730": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3730",
                "description": "description",
                "dna": "25e0e0b66767c8f03ae2d5b1c384b3603b441fd0",
                "date": 1683434915003,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3730",
                "rarity_score": 176.20422388198395,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3730.png",
            "scheme": "https"
        }
    },
    "57": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #57",
                "description": "description",
                "dna": "67de3038e7f72827b01a0d546eb1af0815a39863",
                "date": 1683432964071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "57",
                "rarity_score": 158.397954772533,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/57.png",
            "scheme": "https"
        }
    },
    "764": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #764",
                "description": "description",
                "dna": "284be77cb01c79cd46d4bc36760d0b457abca8ae",
                "date": 1683431717186,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "764",
                "rarity_score": 196.4685607880375,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/764.png",
            "scheme": "https"
        }
    },
    "3347": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3347",
                "description": "description",
                "dna": "9f275d95075db4a5027702e3e1f9a5306732f836",
                "date": 1683774457610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3347",
                "rarity_score": 187.65895997081014,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3347.png",
            "scheme": "https"
        }
    },
    "1949": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1949",
                "description": "description",
                "dna": "25e4a6ffa13a401a04eac9b66a8c7b1d0d2cb452",
                "date": 1683443181945,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1949",
                "rarity_score": 171.44721018161118,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1949.png",
            "scheme": "https"
        }
    },
    "1010": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1010",
                "description": "description",
                "dna": "93246bfbf6db3f8221d7dbe67f3cb3d7ff309000",
                "date": 1683434371280,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1010",
                "rarity_score": 171.5774915317356,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1010.png",
            "scheme": "https"
        }
    },
    "353": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #353",
                "description": "description",
                "dna": "bb8a5169cf4954b9aa1ffc9c6211ac449abbc439",
                "date": 1683775030015,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "353",
                "rarity_score": 256.6260223963503,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/353.png",
            "scheme": "https"
        }
    },
    "2421": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2421",
                "description": "description",
                "dna": "3d11f38016f9481e83c1825fb4b1c7b0b4a3fa83",
                "date": 1683440676629,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2421",
                "rarity_score": 184.97989853389288,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2421.png",
            "scheme": "https"
        }
    },
    "2794": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2794",
                "description": "description",
                "dna": "328fed92b91a77500838ccccf96d7b97239a01a6",
                "date": 1683775249233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2794",
                "rarity_score": 248.35347525228457,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2794.png",
            "scheme": "https"
        }
    },
    "476": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #476",
                "description": "description",
                "dna": "beee2efdf4b8c324abc02cce6b9aabd20f66ce5f",
                "date": 1683774959687,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "476",
                "rarity_score": 235.70829857573997,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/476.png",
            "scheme": "https"
        }
    },
    "3648": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3648",
                "description": "description",
                "dna": "d97bd5ddb057160ae7e122f6084946010ee61822",
                "date": 1683429418683,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3648",
                "rarity_score": 170.61865983427703,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3648.png",
            "scheme": "https"
        }
    },
    "2764": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2764",
                "description": "description",
                "dna": "a85e9041e2336b002489677f85180c31ab13c776",
                "date": 1683437462501,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2764",
                "rarity_score": 154.1868772711671,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2764.png",
            "scheme": "https"
        }
    },
    "3768": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3768",
                "description": "description",
                "dna": "af5a94d076080dad5e2f3838986c3a4448056d06",
                "date": 1683444375177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3768",
                "rarity_score": 170.09477216514028,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3768.png",
            "scheme": "https"
        }
    },
    "2371": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2371",
                "description": "description",
                "dna": "1d16c62cf9f6ca277aa6649832e4c02099cbd7a1",
                "date": 1683432911155,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2371",
                "rarity_score": 147.45470361921804,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2371.png",
            "scheme": "https"
        }
    },
    "3360": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3360",
                "description": "description",
                "dna": "6db406f5541244258c2e50c958372b3da8f28b71",
                "date": 1683438761041,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3360",
                "rarity_score": 156.26722263028972,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3360.png",
            "scheme": "https"
        }
    },
    "1377": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1377",
                "description": "description",
                "dna": "7c43d4eb45c42f0f120fb52ec591fd4204bbb248",
                "date": 1683447366772,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1377",
                "rarity_score": 172.64092375299379,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1377.png",
            "scheme": "https"
        }
    },
    "3356": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3356",
                "description": "description",
                "dna": "1e3dc2b7237f1df48122bbd0ebc1ad8039658eab",
                "date": 1683437474818,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3356",
                "rarity_score": 115.03999545441948,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3356.png",
            "scheme": "https"
        }
    },
    "311": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #311",
                "description": "description",
                "dna": "b06d4bd8c2ec3f2324a77e7138b73fab07734a62",
                "date": 1683435115269,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "311",
                "rarity_score": 188.62246047465237,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/311.png",
            "scheme": "https"
        }
    },
    "3803": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3803",
                "description": "description",
                "dna": "49d411af134894afdc811439c11742d8d90b0de9",
                "date": 1683443541940,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3803",
                "rarity_score": 137.72650634840667,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3803.png",
            "scheme": "https"
        }
    },
    "757": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #757",
                "description": "description",
                "dna": "e096b9b59bf3b27b6f48437aacb705a930e6e6c5",
                "date": 1683437314596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "757",
                "rarity_score": 283.9888434670414,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/757.png",
            "scheme": "https"
        }
    },
    "3249": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3249",
                "description": "description",
                "dna": "166333022d2acac9d36bd139b4be87bcda213141",
                "date": 1683430588341,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3249",
                "rarity_score": 273.37132257530726,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3249.png",
            "scheme": "https"
        }
    },
    "666": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #666",
                "description": "description",
                "dna": "8442e1df4f7e386d2a92074e879b4787f3ca4076",
                "date": 1683437194628,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "666",
                "rarity_score": 251.92238088361222,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/666.png",
            "scheme": "https"
        }
    },
    "1191": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1191",
                "description": "description",
                "dna": "c4239a25753e434da2943fe58aa18dc048ff3934",
                "date": 1683440536774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1191",
                "rarity_score": 176.39008407402872,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1191.png",
            "scheme": "https"
        }
    },
    "3928": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3928",
                "description": "description",
                "dna": "9db929f58b04de8c23856757161a4774a63fa479",
                "date": 1683435710638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "3928",
                "rarity_score": 236.66654405535473,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3928.png",
            "scheme": "https"
        }
    },
    "337": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #337",
                "description": "description",
                "dna": "f7d6f032249cf2e5fadc8b068a7ca00bab8047d9",
                "date": 1683437548657,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "337",
                "rarity_score": 126.52048485719007,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/337.png",
            "scheme": "https"
        }
    },
    "3391": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3391",
                "description": "description",
                "dna": "684badbfc248667a99660076f994b2c65e1a41cc",
                "date": 1683446833921,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3391",
                "rarity_score": 181.6288933335906,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3391.png",
            "scheme": "https"
        }
    },
    "2464": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2464",
                "description": "description",
                "dna": "458c8edaf7460fe1fecca6c032739975a07c3d40",
                "date": 1683434019144,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2464",
                "rarity_score": 212.01437653069354,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2464.png",
            "scheme": "https"
        }
    },
    "4046": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4046",
                "description": "description",
                "dna": "01de6a8acac984527d25eae44265ec1060d6d504",
                "date": 1683436075724,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "4046",
                "rarity_score": 173.86216608276345,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4046.png",
            "scheme": "https"
        }
    },
    "3184": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3184",
                "description": "description",
                "dna": "2545e5e50a6eb04d7e04ad82eb254f2dcaac3a0c",
                "date": 1683446557849,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3184",
                "rarity_score": 123.59936595786125,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3184.png",
            "scheme": "https"
        }
    },
    "3793": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3793",
                "description": "description",
                "dna": "0ec6d4b6f4d1992733bf8ab86d8563f26312049d",
                "date": 1683446023528,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3793",
                "rarity_score": 150.4551804047585,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3793.png",
            "scheme": "https"
        }
    },
    "3607": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3607",
                "description": "description",
                "dna": "bf48e30ff9ff0fc9468e0cc5c18871ed82544989",
                "date": 1683444180282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3607",
                "rarity_score": 187.55649389372175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3607.png",
            "scheme": "https"
        }
    },
    "511": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #511",
                "description": "description",
                "dna": "77e2a934aaeb2eb129ff7574af242246e277c7a0",
                "date": 1683442675043,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "511",
                "rarity_score": 359.9489249725574,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/511.png",
            "scheme": "https"
        }
    },
    "2350": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2350",
                "description": "description",
                "dna": "de3f2498b10d3df68d3b37d1944a79fbbf911e2d",
                "date": 1683439050025,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2350",
                "rarity_score": 157.63973593932386,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2350.png",
            "scheme": "https"
        }
    },
    "3076": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3076",
                "description": "description",
                "dna": "48689f0667b1d60d6085cac320a26a042c4f84a0",
                "date": 1683433930770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3076",
                "rarity_score": 202.78235689887453,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3076.png",
            "scheme": "https"
        }
    },
    "791": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #791",
                "description": "description",
                "dna": "e8a47490807831ea92325071ef2a7cc6912b026d",
                "date": 1683445495222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "791",
                "rarity_score": 206.26016774851723,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/791.png",
            "scheme": "https"
        }
    },
    "1390": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1390",
                "description": "description",
                "dna": "6f75aec358c23ff0bae19e302edecde90d05aff6",
                "date": 1683435297722,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1390",
                "rarity_score": 218.49965003154972,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1390.png",
            "scheme": "https"
        }
    },
    "574": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #574",
                "description": "description",
                "dna": "d81aa349ad3ed3d276bbf6bd189233e69efd701e",
                "date": 1683437897707,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "574",
                "rarity_score": 200.74836039088936,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/574.png",
            "scheme": "https"
        }
    },
    "2268": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2268",
                "description": "description",
                "dna": "8407ffa16dfd154fa9db87899e094a82731025e3",
                "date": 1683771379114,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2268",
                "rarity_score": 198.25502989046834,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2268.png",
            "scheme": "https"
        }
    },
    "1085": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1085",
                "description": "description",
                "dna": "7b6eebec55115bb4ae7e66111ebff730e9c68e12",
                "date": 1683434074484,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1085",
                "rarity_score": 162.81272633543355,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1085.png",
            "scheme": "https"
        }
    },
    "1522": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1522",
                "description": "description",
                "dna": "5091bd4d07ad86b22900c8556676d41d0b6939cd",
                "date": 1683441507189,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1522",
                "rarity_score": 162.2813181697448,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1522.png",
            "scheme": "https"
        }
    },
    "2116": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2116",
                "description": "description",
                "dna": "f0dcdc817ca5d0c7c13cf7d96121c195520e77ca",
                "date": 1683433556545,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2116",
                "rarity_score": 234.37338156168386,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2116.png",
            "scheme": "https"
        }
    },
    "3035": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3035",
                "description": "description",
                "dna": "be76dce2b242b0c9712cd1cb78386b0bcc0c60a6",
                "date": 1683439682068,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3035",
                "rarity_score": 235.46936734624913,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3035.png",
            "scheme": "https"
        }
    },
    "2591": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2591",
                "description": "description",
                "dna": "1d776a3b383cd3a9b74294eea5dcc254c7839f33",
                "date": 1683433615378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2591",
                "rarity_score": 266.63364628599106,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2591.png",
            "scheme": "https"
        }
    },
    "909": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #909",
                "description": "description",
                "dna": "c6cdeac20df23aa9344370039de748c965b49bb7",
                "date": 1683440362957,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "909",
                "rarity_score": 170.55314810165845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/909.png",
            "scheme": "https"
        }
    },
    "3470": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3470",
                "description": "description",
                "dna": "71daea0accad1b21afe1921b56713ba8a43ae35f",
                "date": 1683774547242,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3470",
                "rarity_score": 200.65211721582892,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3470.png",
            "scheme": "https"
        }
    },
    "1932": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1932",
                "description": "description",
                "dna": "8641446f3b55c62c9dc30fe6594940d6c15db37d",
                "date": 1683439591999,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1932",
                "rarity_score": 201.51090672944645,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1932.png",
            "scheme": "https"
        }
    },
    "3290": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3290",
                "description": "description",
                "dna": "d6524f833f1975a6bee546092655abe624f1c02e",
                "date": 1683433497590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "3290",
                "rarity_score": 378.8581551645624,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3290.png",
            "scheme": "https"
        }
    },
    "1797": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1797",
                "description": "description",
                "dna": "638e80a59cc311194552961a132aa11edcbb5ad8",
                "date": 1683440668042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1797",
                "rarity_score": 184.70931398758165,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1797.png",
            "scheme": "https"
        }
    },
    "1068": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1068",
                "description": "description",
                "dna": "8d40e9c36afca2b668ab0db290488b6066b9c8df",
                "date": 1683439187625,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1068",
                "rarity_score": 222.5274994415659,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1068.png",
            "scheme": "https"
        }
    },
    "1820": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1820",
                "description": "description",
                "dna": "8d956b8b5aeb95a9ba03181ae72bef926ac04ad6",
                "date": 1683432528977,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1820",
                "rarity_score": 207.98350028171598,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1820.png",
            "scheme": "https"
        }
    },
    "3554": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3554",
                "description": "description",
                "dna": "8b297e49a9483285c1a8f53c2956160c670c0170",
                "date": 1683444008040,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3554",
                "rarity_score": 305.9480572978754,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3554.png",
            "scheme": "https"
        }
    },
    "1061": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1061",
                "description": "description",
                "dna": "e51b8a1831993ccc1e8e6309fd4c615844f16197",
                "date": 1683437664850,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1061",
                "rarity_score": 269.64671814035574,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1061.png",
            "scheme": "https"
        }
    },
    "3899": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3899",
                "description": "description",
                "dna": "c8cd9b0fe59cdef18362dd1b47c8e5a197117d2a",
                "date": 1683431634524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3899",
                "rarity_score": 196.59897034154554,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3899.png",
            "scheme": "https"
        }
    },
    "498": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #498",
                "description": "description",
                "dna": "d882e7ee215b7b181c23395a95a0b95c01ad28c8",
                "date": 1683771433284,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "498",
                "rarity_score": 249.13529534880124,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/498.png",
            "scheme": "https"
        }
    },
    "1160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1160",
                "description": "description",
                "dna": "7c667d846d99b7ef714e296d1a7e1008ae0d9cca",
                "date": 1683434064977,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1160",
                "rarity_score": 178.12649106868238,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1160.png",
            "scheme": "https"
        }
    },
    "1824": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1824",
                "description": "description",
                "dna": "d575e7108df592bcb2556af12f184245496ef893",
                "date": 1683446035273,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1824",
                "rarity_score": 135.4558855433038,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1824.png",
            "scheme": "https"
        }
    },
    "1141": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1141",
                "description": "description",
                "dna": "4b43fc24c4e4e6ee31b2d4912f99f6a552d85254",
                "date": 1683444925120,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1141",
                "rarity_score": 177.94737443133417,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1141.png",
            "scheme": "https"
        }
    },
    "3052": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3052",
                "description": "description",
                "dna": "c38d48b12a193c9182f9f289b6b0d9b4284667ca",
                "date": 1683440294930,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3052",
                "rarity_score": 189.38416750875112,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3052.png",
            "scheme": "https"
        }
    },
    "3250": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3250",
                "description": "description",
                "dna": "b5c20f5261c194e2ab5a521c171a4863f5b0196f",
                "date": 1683444973668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3250",
                "rarity_score": 250.41214132770102,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3250.png",
            "scheme": "https"
        }
    },
    "339": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #339",
                "description": "description",
                "dna": "be8913b5d5db2ea0ae908f81eb41e105923a8c7b",
                "date": 1683441579620,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "339",
                "rarity_score": 174.41063918220848,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/339.png",
            "scheme": "https"
        }
    },
    "1330": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1330",
                "description": "description",
                "dna": "1676f8bfcfef28fb437d87ab137d3f1dfe9f62ad",
                "date": 1683439040750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1330",
                "rarity_score": 207.35411708837057,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1330.png",
            "scheme": "https"
        }
    },
    "3828": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3828",
                "description": "description",
                "dna": "1905fa7bec7c45e25d44f710035e45f96b0c1182",
                "date": 1683438251257,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3828",
                "rarity_score": 297.27242194147766,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3828.png",
            "scheme": "https"
        }
    },
    "2470": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2470",
                "description": "description",
                "dna": "11701cce3ae1f824c414d30a7b1eba112bcf44bf",
                "date": 1683439933335,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2470",
                "rarity_score": 297.4479171348336,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2470.png",
            "scheme": "https"
        }
    },
    "1781": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1781",
                "description": "description",
                "dna": "92a2c8c3f6c849abef21dfc5b42e3c2584e18cbb",
                "date": 1683443025248,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1781",
                "rarity_score": 162.5107007702514,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1781.png",
            "scheme": "https"
        }
    },
    "3776": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3776",
                "description": "description",
                "dna": "df965c35f189412795655af6e72ebdbfa51d4d10",
                "date": 1683442069145,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3776",
                "rarity_score": 176.58924998114122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3776.png",
            "scheme": "https"
        }
    },
    "1288": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1288",
                "description": "description",
                "dna": "6eaf789472019346b42d7ac408a8c3a57e0e4c6e",
                "date": 1683433069252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1288",
                "rarity_score": 187.4235879329443,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1288.png",
            "scheme": "https"
        }
    },
    "3142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3142",
                "description": "description",
                "dna": "a856377869e6d8368af999737503ac31ba29cc7d",
                "date": 1683446443222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3142",
                "rarity_score": 146.79170573984578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3142.png",
            "scheme": "https"
        }
    },
    "3630": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3630",
                "description": "description",
                "dna": "20ce7cfecdcb94d80aaf6a4f6ae0799dfdd493de",
                "date": 1683437863566,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3630",
                "rarity_score": 149.9282153144295,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3630.png",
            "scheme": "https"
        }
    },
    "1539": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1539",
                "description": "description",
                "dna": "d17bf27f9d71c15b52601c59c6338a7d674a19c8",
                "date": 1683440019571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1539",
                "rarity_score": 215.31177047535834,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1539.png",
            "scheme": "https"
        }
    },
    "3569": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3569",
                "description": "description",
                "dna": "9d5b0b0518ee01496986532fcc7b1bdf90d8dae7",
                "date": 1683433203839,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3569",
                "rarity_score": 286.3706786361773,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3569.png",
            "scheme": "https"
        }
    },
    "585": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #585",
                "description": "description",
                "dna": "ce5dbc0d2c7204a5297294b1805920167c656da1",
                "date": 1683770519073,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "585",
                "rarity_score": 337.4693078125867,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/585.png",
            "scheme": "https"
        }
    },
    "1088": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1088",
                "description": "description",
                "dna": "b7030277822b6d6cc8e199a5d3a39f3e49325f2a",
                "date": 1683438149018,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1088",
                "rarity_score": 205.63538768952185,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1088.png",
            "scheme": "https"
        }
    },
    "1262": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1262",
                "description": "description",
                "dna": "b7a0d7d3c6a5bde37b08df9461f0100fb0f9a697",
                "date": 1683436893366,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1262",
                "rarity_score": 128.98840618664838,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1262.png",
            "scheme": "https"
        }
    },
    "1509": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1509",
                "description": "description",
                "dna": "7cdefe955fbe2c01e94b78b8ec9fdae562064990",
                "date": 1683441584117,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1509",
                "rarity_score": 171.3210270990482,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1509.png",
            "scheme": "https"
        }
    },
    "3475": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3475",
                "description": "description",
                "dna": "b5171fd2c82255bf5d43106a72d7fcb1544124b8",
                "date": 1683437120769,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3475",
                "rarity_score": 171.98822648354763,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3475.png",
            "scheme": "https"
        }
    },
    "335": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #335",
                "description": "description",
                "dna": "f95dfeb94f7746aa972076373ad3a563870caf0e",
                "date": 1683446641913,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "335",
                "rarity_score": 162.3470044928833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/335.png",
            "scheme": "https"
        }
    },
    "993": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #993",
                "description": "description",
                "dna": "16286c3925a8a1525f1055813b9ec83d08f1b7d4",
                "date": 1683429563967,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "993",
                "rarity_score": 249.54868701691325,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/993.png",
            "scheme": "https"
        }
    },
    "3755": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3755",
                "description": "description",
                "dna": "66e03857e3dd435776731ebb7320ca61ecc48899",
                "date": 1683437141407,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3755",
                "rarity_score": 170.40200079904434,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3755.png",
            "scheme": "https"
        }
    },
    "658": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #658",
                "description": "description",
                "dna": "aa53b8befe7d86471bc041222814f7e47beefdc7",
                "date": 1683444647081,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "658",
                "rarity_score": 161.2127254375127,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/658.png",
            "scheme": "https"
        }
    },
    "2930": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2930",
                "description": "description",
                "dna": "76bba316cf49a6aa6e7b42d79481be14cab5f9d5",
                "date": 1683443496277,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2930",
                "rarity_score": 210.55070374942233,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2930.png",
            "scheme": "https"
        }
    },
    "3495": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3495",
                "description": "description",
                "dna": "2da3084cb8af46626d0a9b84fd9fc568ef0fcc7a",
                "date": 1683438260383,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3495",
                "rarity_score": 242.20231783191923,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3495.png",
            "scheme": "https"
        }
    },
    "3171": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3171",
                "description": "description",
                "dna": "d1854c3ef501a6c2494bcd572be7e1f6845774b3",
                "date": 1683446620538,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3171",
                "rarity_score": 164.51608617206892,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3171.png",
            "scheme": "https"
        }
    },
    "81": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #81",
                "description": "description",
                "dna": "45821574b9c1586bfbfc54027f3e88197b0f6d66",
                "date": 1683439219296,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "81",
                "rarity_score": 156.4824142935253,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/81.png",
            "scheme": "https"
        }
    },
    "1752": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1752",
                "description": "description",
                "dna": "b1d5eb8e2965e751843d68d242e507e20fdac044",
                "date": 1683443102068,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1752",
                "rarity_score": 192.79763030256845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1752.png",
            "scheme": "https"
        }
    },
    "2880": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2880",
                "description": "description",
                "dna": "ac4bb76562c9f403bbf0f5500d139e4ec4008980",
                "date": 1683437404760,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2880",
                "rarity_score": 223.92784019805916,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2880.png",
            "scheme": "https"
        }
    },
    "3099": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3099",
                "description": "description",
                "dna": "3ae9f619fdc48452836f4b847871b0d408132352",
                "date": 1683444171645,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3099",
                "rarity_score": 165.84706665846045,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3099.png",
            "scheme": "https"
        }
    },
    "1879": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1879",
                "description": "description",
                "dna": "4ce7db21378629260d884a8d5fce1d3fbc5a276a",
                "date": 1683442481044,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1879",
                "rarity_score": 161.63341015434804,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1879.png",
            "scheme": "https"
        }
    },
    "1233": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1233",
                "description": "description",
                "dna": "9af8118dbc6591da63555cdd80a7a557b1155ab5",
                "date": 1683429931748,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1233",
                "rarity_score": 227.92678694083173,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1233.png",
            "scheme": "https"
        }
    },
    "3792": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3792",
                "description": "description",
                "dna": "25fcfccb0ace1bf0da6b03113df3d920553ff22a",
                "date": 1683437006409,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3792",
                "rarity_score": 126.87612741396858,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3792.png",
            "scheme": "https"
        }
    },
    "1407": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1407",
                "description": "description",
                "dna": "63a1be5b00b34d296d5282357db38094a9e26943",
                "date": 1683443985393,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1407",
                "rarity_score": 202.86085118327563,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1407.png",
            "scheme": "https"
        }
    },
    "4039": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4039",
                "description": "description",
                "dna": "645f699479e89e8a7062f6f92a2525eda95a80a5",
                "date": 1683432671432,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4039",
                "rarity_score": 185.95800291120634,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4039.png",
            "scheme": "https"
        }
    },
    "1246": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1246",
                "description": "description",
                "dna": "7809ef939010be11eb884a123cef8c879264dcb8",
                "date": 1683438109067,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1246",
                "rarity_score": 220.63456998074855,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1246.png",
            "scheme": "https"
        }
    },
    "3010": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3010",
                "description": "description",
                "dna": "9e4aff6f11eb25cca67fe85d0acec1f2d48dd5c7",
                "date": 1683774994717,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3010",
                "rarity_score": 220.0098000230987,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3010.png",
            "scheme": "https"
        }
    },
    "2888": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2888",
                "description": "description",
                "dna": "d1e62a8af77f853c72c298493a9b00d2ef5e27a2",
                "date": 1683443261085,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2888",
                "rarity_score": 182.4762874317605,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2888.png",
            "scheme": "https"
        }
    },
    "2694": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2694",
                "description": "description",
                "dna": "3aede5c048bdec7ef86cdc8dc62b5c67b52eff29",
                "date": 1683775003252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2694",
                "rarity_score": 201.8115499415889,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2694.png",
            "scheme": "https"
        }
    },
    "899": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #899",
                "description": "description",
                "dna": "393f91b65346b40ab7866da0515045588b729f31",
                "date": 1683446202330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "899",
                "rarity_score": 188.7992257859018,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/899.png",
            "scheme": "https"
        }
    },
    "730": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #730",
                "description": "description",
                "dna": "bec57b8fa8f289ab71ab2451ebad555c59834203",
                "date": 1683432776543,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "730",
                "rarity_score": 283.20001910876437,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/730.png",
            "scheme": "https"
        }
    },
    "3540": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3540",
                "description": "description",
                "dna": "54fc07c00ec50d3d8c2c23d043ec8dbaf65b4d46",
                "date": 1683771590955,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3540",
                "rarity_score": 194.35673659832628,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3540.png",
            "scheme": "https"
        }
    },
    "1858": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1858",
                "description": "description",
                "dna": "dd97d9ec0bbd97cb7548b9a46b04852459c9f483",
                "date": 1683447171066,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1858",
                "rarity_score": 217.78104274741747,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1858.png",
            "scheme": "https"
        }
    },
    "3875": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3875",
                "description": "description",
                "dna": "1d5d09a175655c75105f03eb9fd94bdb1ed5a117",
                "date": 1683444624531,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3875",
                "rarity_score": 164.90426274905064,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3875.png",
            "scheme": "https"
        }
    },
    "409": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #409",
                "description": "description",
                "dna": "f755be47df482726cf9c7f9477d543e862fa74a5",
                "date": 1683774861420,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "409",
                "rarity_score": 215.94896683680503,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/409.png",
            "scheme": "https"
        }
    },
    "450": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #450",
                "description": "description",
                "dna": "2763d112f80224ce59283592ca4ace9ca9b3f7f9",
                "date": 1683437359837,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "450",
                "rarity_score": 198.2673609697578,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/450.png",
            "scheme": "https"
        }
    },
    "1882": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1882",
                "description": "description",
                "dna": "cf57e3c10bbccd068ff54075bab0c40f4b92d000",
                "date": 1683444802308,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1882",
                "rarity_score": 186.35085021591405,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1882.png",
            "scheme": "https"
        }
    },
    "2897": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2897",
                "description": "description",
                "dna": "76f17cdfc64d55be90d01c00610fba10c38649e4",
                "date": 1683437673976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2897",
                "rarity_score": 181.73720989116498,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2897.png",
            "scheme": "https"
        }
    },
    "286": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #286",
                "description": "description",
                "dna": "9742cbaf1ea472199cc78e38e9e01ee9fc53432d",
                "date": 1683438494459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "286",
                "rarity_score": 174.9281449753483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/286.png",
            "scheme": "https"
        }
    },
    "468": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #468",
                "description": "description",
                "dna": "bc276b1fe8da8321605b59a0ebfea90cddd18707",
                "date": 1683440171143,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "468",
                "rarity_score": 170.6658956214673,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/468.png",
            "scheme": "https"
        }
    },
    "2126": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2126",
                "description": "description",
                "dna": "549e0f2807e123bd4be70d9821b56170bd1177f1",
                "date": 1683446829982,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2126",
                "rarity_score": 267.40312202426463,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2126.png",
            "scheme": "https"
        }
    },
    "1373": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1373",
                "description": "description",
                "dna": "c47f4f34b246553beb9ea53375613e16ebf76301",
                "date": 1683446163067,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1373",
                "rarity_score": 150.01834297173468,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1373.png",
            "scheme": "https"
        }
    },
    "1260": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1260",
                "description": "description",
                "dna": "c91b4055b01cf19c971013a20bd85dc86e34b321",
                "date": 1683431467085,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "1260",
                "rarity_score": 348.18043392884624,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1260.png",
            "scheme": "https"
        }
    },
    "3053": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3053",
                "description": "description",
                "dna": "8e4df133ff5413bc76e25ece73d467459cf77f09",
                "date": 1683430593282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "3053",
                "rarity_score": 288.16787993382434,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3053.png",
            "scheme": "https"
        }
    },
    "540": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #540",
                "description": "description",
                "dna": "d231552b677bea310f159f77e3f9e1d5fb64d363",
                "date": 1683434357225,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "540",
                "rarity_score": 172.6219477069916,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/540.png",
            "scheme": "https"
        }
    },
    "3667": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3667",
                "description": "description",
                "dna": "fafb3869ff8461ab3e712cbc26ceb50700121695",
                "date": 1683445088645,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3667",
                "rarity_score": 181.34277146822117,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3667.png",
            "scheme": "https"
        }
    },
    "3956": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3956",
                "description": "description",
                "dna": "e754a7f087c2e36aa953456950c79cf60882b8f1",
                "date": 1683435167222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3956",
                "rarity_score": 293.64555731718997,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3956.png",
            "scheme": "https"
        }
    },
    "2859": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2859",
                "description": "description",
                "dna": "c33d02968962ad4451990424d7478c56cdebd8ef",
                "date": 1683435152884,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2859",
                "rarity_score": 198.80443586034116,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2859.png",
            "scheme": "https"
        }
    },
    "3716": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3716",
                "description": "description",
                "dna": "eb6e437ffe00c629742fbb9e24fc444034eb1599",
                "date": 1683434348821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3716",
                "rarity_score": 225.81033983562415,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3716.png",
            "scheme": "https"
        }
    },
    "1304": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1304",
                "description": "description",
                "dna": "52fe62ab34eb13a443d2b96c452c9ed5575cbdf1",
                "date": 1683429543244,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1304",
                "rarity_score": 161.2324970767553,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1304.png",
            "scheme": "https"
        }
    },
    "3438": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3438",
                "description": "description",
                "dna": "76fa3d7a2280db42bb73dff1f6c0b73563ae59ad",
                "date": 1683775011939,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3438",
                "rarity_score": 242.1220160464185,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3438.png",
            "scheme": "https"
        }
    },
    "4044": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4044",
                "description": "description",
                "dna": "7627027be3d921a9b68a8d0a5ddb250d5b915a4e",
                "date": 1683440822653,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4044",
                "rarity_score": 184.61461318797976,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4044.png",
            "scheme": "https"
        }
    },
    "3709": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3709",
                "description": "description",
                "dna": "8b923689da1b29a6d38e3987f68d1fc64ecf112c",
                "date": 1683442790641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3709",
                "rarity_score": 168.00752296974431,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3709.png",
            "scheme": "https"
        }
    },
    "960": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #960",
                "description": "description",
                "dna": "1108a0fedd6a9ae7a08a70962fefaa8c4b3cbafa",
                "date": 1683439870283,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "960",
                "rarity_score": 237.036045691403,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/960.png",
            "scheme": "https"
        }
    },
    "313": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #313",
                "description": "description",
                "dna": "7a2ebbe7beeef8cd5fe5611d042dcf479dadfdea",
                "date": 1683447232617,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "313",
                "rarity_score": 246.7347457980336,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/313.png",
            "scheme": "https"
        }
    },
    "2754": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2754",
                "description": "description",
                "dna": "61346835cd66e20596f92d4d00ad06719bf9e27d",
                "date": 1683444642514,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2754",
                "rarity_score": 171.70272704864283,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2754.png",
            "scheme": "https"
        }
    },
    "713": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #713",
                "description": "description",
                "dna": "9e472771f4a23c5fa4cdafcabf5a3227c2051ba2",
                "date": 1683438363356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "713",
                "rarity_score": 201.40363313157826,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/713.png",
            "scheme": "https"
        }
    },
    "896": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #896",
                "description": "description",
                "dna": "833a949cd06464668d48bf9932dc930bd20cea76",
                "date": 1683770531349,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "896",
                "rarity_score": 415.69432473083964,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/896.png",
            "scheme": "https"
        }
    },
    "834": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #834",
                "description": "description",
                "dna": "549bef03b48c54aa420a40b14b572ca5a4102c92",
                "date": 1683432658020,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "834",
                "rarity_score": 153.16906658760033,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/834.png",
            "scheme": "https"
        }
    },
    "56": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #56",
                "description": "description",
                "dna": "2c34d2778d0b2ad9ee637820b3aa03a638a1c038",
                "date": 1683776383532,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "56",
                "rarity_score": 178.31035082480216,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/56.png",
            "scheme": "https"
        }
    },
    "1588": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1588",
                "description": "description",
                "dna": "4380526e8c75a87731ec13323978289f9f2a75ae",
                "date": 1683774783755,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1588",
                "rarity_score": 162.90673075606554,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1588.png",
            "scheme": "https"
        }
    },
    "3002": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3002",
                "description": "description",
                "dna": "3c7d665eb3c09cf5406145abdc4d09835fde13be",
                "date": 1683439416916,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3002",
                "rarity_score": 203.94074305530683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3002.png",
            "scheme": "https"
        }
    },
    "3091": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3091",
                "description": "description",
                "dna": "4ea723a54f9387dff0d2ea278600f251f8a89cfa",
                "date": 1683435264051,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3091",
                "rarity_score": 265.73145905639376,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3091.png",
            "scheme": "https"
        }
    },
    "2574": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2574",
                "description": "description",
                "dna": "81d614b2f9704d213bc8282e2c04829d5b855cac",
                "date": 1683434222358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "2574",
                "rarity_score": 271.84254707932416,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2574.png",
            "scheme": "https"
        }
    },
    "3335": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3335",
                "description": "description",
                "dna": "f75766b513cb7cac4b011a2a5cc1215a0fb6c86a",
                "date": 1683446751681,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3335",
                "rarity_score": 190.60596406678036,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3335.png",
            "scheme": "https"
        }
    },
    "3253": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3253",
                "description": "description",
                "dna": "200fae59a8862808fd7afbc1121a9071d808c73d",
                "date": 1683441799490,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3253",
                "rarity_score": 207.31231957698037,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3253.png",
            "scheme": "https"
        }
    },
    "2629": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2629",
                "description": "description",
                "dna": "3b4edc47b54b8d52c6baa1ef55652a403d8204d5",
                "date": 1683434951428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2629",
                "rarity_score": 188.95332262165874,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2629.png",
            "scheme": "https"
        }
    },
    "1901": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1901",
                "description": "description",
                "dna": "f74f12ea59a5e3ca89c32f3248cefc6fee176f53",
                "date": 1683436130628,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1901",
                "rarity_score": 171.42011861305215,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1901.png",
            "scheme": "https"
        }
    },
    "2993": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2993",
                "description": "description",
                "dna": "ca0acc48e66359d37bc096e315d40a4a748a96c2",
                "date": 1683445568514,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2993",
                "rarity_score": 213.28124363852436,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2993.png",
            "scheme": "https"
        }
    },
    "1769": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1769",
                "description": "description",
                "dna": "087a718b0f3bd92a496e34eaa33c00536df8d155",
                "date": 1683430057346,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1769",
                "rarity_score": 152.91307666452576,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1769.png",
            "scheme": "https"
        }
    },
    "1462": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1462",
                "description": "description",
                "dna": "ccd199f8a52cec556a25c7ab14984e76dba0b534",
                "date": 1683437091850,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1462",
                "rarity_score": 158.16031915095115,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1462.png",
            "scheme": "https"
        }
    },
    "3699": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3699",
                "description": "description",
                "dna": "b8d03a3b361e6e7388b77f9069bcd58b17339353",
                "date": 1683429856626,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3699",
                "rarity_score": 238.46153295736792,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3699.png",
            "scheme": "https"
        }
    },
    "2906": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2906",
                "description": "description",
                "dna": "0668ff076ef73a97dac660a660c7b615f0a87e6f",
                "date": 1683434543962,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2906",
                "rarity_score": 162.0440473184252,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2906.png",
            "scheme": "https"
        }
    },
    "244": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #244",
                "description": "description",
                "dna": "8a9a5bea60adada2b3b441991e29ff0bd38369fc",
                "date": 1683435976579,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "244",
                "rarity_score": 186.53615289075546,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/244.png",
            "scheme": "https"
        }
    },
    "952": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #952",
                "description": "description",
                "dna": "e4fa35e454c844b945f66525421c2f0a4469ec84",
                "date": 1683447308701,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "952",
                "rarity_score": 184.27434577398202,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/952.png",
            "scheme": "https"
        }
    },
    "1256": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1256",
                "description": "description",
                "dna": "94cacbb410c976011b11b299a29c0240b0834c7d",
                "date": 1683446186397,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1256",
                "rarity_score": 194.08338731231234,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1256.png",
            "scheme": "https"
        }
    },
    "2790": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2790",
                "description": "description",
                "dna": "b8edcea8d7f313b6195ee87f05019f5283d521fa",
                "date": 1683445919524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2790",
                "rarity_score": 154.08040173138912,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2790.png",
            "scheme": "https"
        }
    },
    "3016": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3016",
                "description": "description",
                "dna": "9ec5735ae71d5dc2ddf83f698a6c9259c4544d35",
                "date": 1683446561746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3016",
                "rarity_score": 151.4400861904618,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3016.png",
            "scheme": "https"
        }
    },
    "1777": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1777",
                "description": "description",
                "dna": "98320ee8070fff2d9d8e8bf00709ef3f2a24bbe9",
                "date": 1683444708493,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1777",
                "rarity_score": 154.71563145953382,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1777.png",
            "scheme": "https"
        }
    },
    "1396": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1396",
                "description": "description",
                "dna": "ba69085f0924a6f5570dd68e17f3482877bb60a9",
                "date": 1683432138895,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1396",
                "rarity_score": 189.1076771013317,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1396.png",
            "scheme": "https"
        }
    },
    "423": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #423",
                "description": "description",
                "dna": "5d6615e7cc5c3ebdd2e8014a46bfb98c8b8b8935",
                "date": 1683446850967,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "423",
                "rarity_score": 169.95290801964984,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/423.png",
            "scheme": "https"
        }
    },
    "2902": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2902",
                "description": "description",
                "dna": "47980ff626473dbba8a019f4ef638739ab72d8e4",
                "date": 1683435069572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2902",
                "rarity_score": 190.06405375190332,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2902.png",
            "scheme": "https"
        }
    },
    "390": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #390",
                "description": "description",
                "dna": "843be2f7fdcb8e850e9e2e5ed5dbc30cad47dba7",
                "date": 1683774628714,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "390",
                "rarity_score": 229.87092755872865,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/390.png",
            "scheme": "https"
        }
    },
    "2901": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2901",
                "description": "description",
                "dna": "59884a179b46ba9c7c4c3add0acc9780ab5225cf",
                "date": 1683438121952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2901",
                "rarity_score": 166.06898540378444,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2901.png",
            "scheme": "https"
        }
    },
    "208": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #208",
                "description": "description",
                "dna": "5abfd87abf3f7ba59731aef6cba64d53c54d2e83",
                "date": 1683438191857,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "208",
                "rarity_score": 251.37562911500174,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/208.png",
            "scheme": "https"
        }
    },
    "3439": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3439",
                "description": "description",
                "dna": "cdd29b5b8ea02aa00572415ec25120ff138820e5",
                "date": 1683439801522,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3439",
                "rarity_score": 183.43458263858443,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3439.png",
            "scheme": "https"
        }
    },
    "481": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #481",
                "description": "description",
                "dna": "95c29a74e3e3bec4a61dc4f61ad7cc54ac12fc50",
                "date": 1683439344898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "481",
                "rarity_score": 172.00432866171673,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/481.png",
            "scheme": "https"
        }
    },
    "3324": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3324",
                "description": "description",
                "dna": "0dd229f339f398be52592a6da2751b3a0829667c",
                "date": 1683437687016,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3324",
                "rarity_score": 190.99049375529347,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3324.png",
            "scheme": "https"
        }
    },
    "2667": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2667",
                "description": "description",
                "dna": "9b29fd46e1a879cc7e5e9036615c05fa3d6704f1",
                "date": 1683444277347,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2667",
                "rarity_score": 180.22199280156113,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2667.png",
            "scheme": "https"
        }
    },
    "1910": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1910",
                "description": "description",
                "dna": "95662e03ecf68323a2085c3a730528d98f2158cb",
                "date": 1683770619243,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1910",
                "rarity_score": 247.08361001707198,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1910.png",
            "scheme": "https"
        }
    },
    "2061": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2061",
                "description": "description",
                "dna": "19d0ecd4aa4d8aa1fc96cf372cf2b1e1756ebaea",
                "date": 1683446062712,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2061",
                "rarity_score": 131.96373356404175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2061.png",
            "scheme": "https"
        }
    },
    "1189": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1189",
                "description": "description",
                "dna": "b0dde3f936a389162ad151a5103e79ed88d60958",
                "date": 1683447197222,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1189",
                "rarity_score": 230.0734642437772,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1189.png",
            "scheme": "https"
        }
    },
    "480": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #480",
                "description": "description",
                "dna": "3e06986ec9d9e4f119c9c77cbafd380ebc00dca9",
                "date": 1683439268613,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "480",
                "rarity_score": 190.0965812630928,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/480.png",
            "scheme": "https"
        }
    },
    "3161": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3161",
                "description": "description",
                "dna": "0aababc574a9f0caeb70fb8f6b8587c5ddd53305",
                "date": 1683438372676,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3161",
                "rarity_score": 215.38570532508788,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3161.png",
            "scheme": "https"
        }
    },
    "383": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #383",
                "description": "description",
                "dna": "a184cd140df520d4ad281e94d18e9391a28a1241",
                "date": 1683430113116,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "383",
                "rarity_score": 284.90881066790405,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/383.png",
            "scheme": "https"
        }
    },
    "2517": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2517",
                "description": "description",
                "dna": "1ba046962e6fef1e926c340762e933d810bf8fd7",
                "date": 1683774969259,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2517",
                "rarity_score": 159.20001494645788,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2517.png",
            "scheme": "https"
        }
    },
    "2391": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2391",
                "description": "description",
                "dna": "f93f0f26a6f6fc16f8d2bfec9d49a503f294511b",
                "date": 1683433362274,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2391",
                "rarity_score": 200.8665560886138,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2391.png",
            "scheme": "https"
        }
    },
    "170": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #170",
                "description": "description",
                "dna": "fd927f892d96c774664222e9c33e78c08fcac8a5",
                "date": 1683438549359,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "170",
                "rarity_score": 170.9485612324042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/170.png",
            "scheme": "https"
        }
    },
    "1759": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1759",
                "description": "description",
                "dna": "cccecd69d68a17785e6770966442e174c2ae15b2",
                "date": 1683443714282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1759",
                "rarity_score": 219.24768499074588,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1759.png",
            "scheme": "https"
        }
    },
    "79": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #79",
                "description": "description",
                "dna": "351484992f53592b640b2b30a52dba90ad7bbf46",
                "date": 1683444581639,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "79",
                "rarity_score": 169.45588977887792,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/79.png",
            "scheme": "https"
        }
    },
    "2007": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2007",
                "description": "description",
                "dna": "afaef8e520a6deb5ea4862d27b23e5c2e30660dd",
                "date": 1683438807693,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2007",
                "rarity_score": 199.42813244294794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2007.png",
            "scheme": "https"
        }
    },
    "3732": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3732",
                "description": "description",
                "dna": "72b6c0cd9ff2abceb8f7e33e036f14e7e150a1f7",
                "date": 1683430789513,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3732",
                "rarity_score": 234.37969619461052,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3732.png",
            "scheme": "https"
        }
    },
    "157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #157",
                "description": "description",
                "dna": "d6b5a744ef265e49f014055650c07c6eebf6e3e9",
                "date": 1683447048845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "157",
                "rarity_score": 244.93336664625048,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/157.png",
            "scheme": "https"
        }
    },
    "3090": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3090",
                "description": "description",
                "dna": "260f83aea16e10b49aa34fa94cf1e5c118d251be",
                "date": 1683446124738,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3090",
                "rarity_score": 141.585321865932,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3090.png",
            "scheme": "https"
        }
    },
    "1500": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1500",
                "description": "description",
                "dna": "5e099f71d70939d94f89559cb5bd27916e8e39d4",
                "date": 1683776796644,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1500",
                "rarity_score": 171.31036653612483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1500.png",
            "scheme": "https"
        }
    },
    "1747": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1747",
                "description": "description",
                "dna": "1d4de5fca02737d37ca1b779f389f7a4b491956f",
                "date": 1683441408752,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1747",
                "rarity_score": 201.81075159991929,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1747.png",
            "scheme": "https"
        }
    },
    "3333": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3333",
                "description": "description",
                "dna": "5c610f355d64c9bd09c7b0bd6b09d8824d57665e",
                "date": 1683435325676,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3333",
                "rarity_score": 147.8440381689339,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3333.png",
            "scheme": "https"
        }
    },
    "3915": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3915",
                "description": "description",
                "dna": "04ecfd1e0a98942e5d0f89fd897de56376475ffe",
                "date": 1683438591251,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3915",
                "rarity_score": 181.5375740047366,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3915.png",
            "scheme": "https"
        }
    },
    "1512": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1512",
                "description": "description",
                "dna": "348804e3d0b60c7fe1799f1c1711651db41dbbc1",
                "date": 1683430320023,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1512",
                "rarity_score": 168.44941707320643,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1512.png",
            "scheme": "https"
        }
    },
    "1227": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1227",
                "description": "description",
                "dna": "4a697e94ae3b06701a7e7d0c5ff89f312e5d0d18",
                "date": 1683429994486,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1227",
                "rarity_score": 175.56435744132966,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1227.png",
            "scheme": "https"
        }
    },
    "1673": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1673",
                "description": "description",
                "dna": "276ac6d4f3029529bd202e745af585dd1af16d6d",
                "date": 1683446101633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1673",
                "rarity_score": 133.35315715495625,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1673.png",
            "scheme": "https"
        }
    },
    "2805": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2805",
                "description": "description",
                "dna": "6c7ed5a9ed440a30699490f3a294cfa34df7a3a9",
                "date": 1683774673403,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2805",
                "rarity_score": 356.46686686847534,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2805.png",
            "scheme": "https"
        }
    },
    "878": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #878",
                "description": "description",
                "dna": "f1b9e631ef8b97beb943133b335c95509aeed28d",
                "date": 1683437723025,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "878",
                "rarity_score": 216.96101269699938,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/878.png",
            "scheme": "https"
        }
    },
    "753": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #753",
                "description": "description",
                "dna": "106c7e8863810547bb3624bf46a634c817f995ce",
                "date": 1683437769727,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "753",
                "rarity_score": 195.1441895318563,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/753.png",
            "scheme": "https"
        }
    },
    "3853": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3853",
                "description": "description",
                "dna": "7249315609c8d993762990cf129a0f4c023181cf",
                "date": 1683432889390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3853",
                "rarity_score": 215.16809684801146,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3853.png",
            "scheme": "https"
        }
    },
    "3371": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3371",
                "description": "description",
                "dna": "8aa34dd266b4c8d94ec13f058d9c2c0c40afe8ad",
                "date": 1683434226634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3371",
                "rarity_score": 177.2845113497579,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3371.png",
            "scheme": "https"
        }
    },
    "2131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2131",
                "description": "description",
                "dna": "0dd858a4cd3e7dd9b405c17b912ca32544735f12",
                "date": 1683439708763,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2131",
                "rarity_score": 185.19406858345815,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2131.png",
            "scheme": "https"
        }
    },
    "3328": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3328",
                "description": "description",
                "dna": "7da25b939e91c014385981c9b391767f27137eff",
                "date": 1683439569255,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3328",
                "rarity_score": 199.21460707131334,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3328.png",
            "scheme": "https"
        }
    },
    "3760": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3760",
                "description": "description",
                "dna": "e53818db6aaf8d75bbd1e4a5813a22b10d936233",
                "date": 1683441129285,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3760",
                "rarity_score": 166.99996963241117,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3760.png",
            "scheme": "https"
        }
    },
    "1142": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1142",
                "description": "description",
                "dna": "ef731e2205c0f1894289c2965f2d55e61bead795",
                "date": 1683443142233,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1142",
                "rarity_score": 183.76797872080533,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1142.png",
            "scheme": "https"
        }
    },
    "165": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #165",
                "description": "description",
                "dna": "2eb2fca3ba98b14b98660d1d680b7c9f617eaf72",
                "date": 1683436446232,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "165",
                "rarity_score": 184.57687771426197,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/165.png",
            "scheme": "https"
        }
    },
    "955": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #955",
                "description": "description",
                "dna": "6513acfaa70193dc408db118a0bc7fff1a8a5c64",
                "date": 1683776527897,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "955",
                "rarity_score": 223.4214736998702,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/955.png",
            "scheme": "https"
        }
    },
    "3345": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3345",
                "description": "description",
                "dna": "a6d8fed9c19f410f31ed4ef27de1a02a2f49a939",
                "date": 1683774426187,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3345",
                "rarity_score": 178.509986988856,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3345.png",
            "scheme": "https"
        }
    },
    "2463": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2463",
                "description": "description",
                "dna": "1bb99f4afd18533d83b75f151e3f4c374a117383",
                "date": 1683771115871,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2463",
                "rarity_score": 175.88811530121637,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2463.png",
            "scheme": "https"
        }
    },
    "2002": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2002",
                "description": "description",
                "dna": "cd8322934486d3b0b8d1a63055dec8dba89318a5",
                "date": 1683430334059,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2002",
                "rarity_score": 168.87290753262195,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2002.png",
            "scheme": "https"
        }
    },
    "1864": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1864",
                "description": "description",
                "dna": "cde914fca303776fb24a4185dcaddd6c4a8de32a",
                "date": 1683438871901,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1864",
                "rarity_score": 201.51382138007503,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1864.png",
            "scheme": "https"
        }
    },
    "333": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #333",
                "description": "description",
                "dna": "18577245b04db6f54cae1fb7beb3ec678631cc95",
                "date": 1683771387553,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "333",
                "rarity_score": 207.24657916253983,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/333.png",
            "scheme": "https"
        }
    },
    "2113": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2113",
                "description": "description",
                "dna": "7b69c4de6cc46d0d38c430747c664c20817f873d",
                "date": 1683441156443,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2113",
                "rarity_score": 239.86933860931236,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2113.png",
            "scheme": "https"
        }
    },
    "2602": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2602",
                "description": "description",
                "dna": "3f8a82595cbc03b4df81eb7fab763e840ccdd1ad",
                "date": 1683430126850,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2602",
                "rarity_score": 171.6798984439191,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2602.png",
            "scheme": "https"
        }
    },
    "2396": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2396",
                "description": "description",
                "dna": "095d3609ffa709a21b52aba93201a938bffc4045",
                "date": 1683431875750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2396",
                "rarity_score": 147.29031158895106,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2396.png",
            "scheme": "https"
        }
    },
    "594": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #594",
                "description": "description",
                "dna": "0b234f8c53b14eadfab7e538e0f140353adc1fda",
                "date": 1683438696428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "594",
                "rarity_score": 155.42736144191008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/594.png",
            "scheme": "https"
        }
    },
    "2910": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2910",
                "description": "description",
                "dna": "c395197ad28690bcbb3787932057f385f2dbb6ac",
                "date": 1683441543565,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2910",
                "rarity_score": 190.2234343613548,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2910.png",
            "scheme": "https"
        }
    },
    "1523": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1523",
                "description": "description",
                "dna": "79b9eb2e57e91639f2295fdfe12dbe550391463a",
                "date": 1683433258461,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1523",
                "rarity_score": 216.01910445273228,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1523.png",
            "scheme": "https"
        }
    },
    "1053": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1053",
                "description": "description",
                "dna": "656b783d7129d9b81199ceafc95a4be19e6f92d5",
                "date": 1683438654909,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1053",
                "rarity_score": 238.70303944254454,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1053.png",
            "scheme": "https"
        }
    },
    "3994": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3994",
                "description": "description",
                "dna": "118b0b90fb4249e3d1606a59c356843a95633c73",
                "date": 1683775139636,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3994",
                "rarity_score": 197.49757844552886,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3994.png",
            "scheme": "https"
        }
    },
    "2231": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2231",
                "description": "description",
                "dna": "ab30cd57e6c966b2ae21d18ed19c9b6796de1f49",
                "date": 1683439094478,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2231",
                "rarity_score": 234.78816286322436,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2231.png",
            "scheme": "https"
        }
    },
    "2151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2151",
                "description": "description",
                "dna": "8c781dcf01dc637be6a2b4a1265f6ae3876f1fd6",
                "date": 1683770602348,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2151",
                "rarity_score": 281.1398230486194,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2151.png",
            "scheme": "https"
        }
    },
    "3144": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3144",
                "description": "description",
                "dna": "f8b256ba6db43fb2dc6b773ee436a61616f5e5da",
                "date": 1683438889800,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3144",
                "rarity_score": 198.98931951618468,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3144.png",
            "scheme": "https"
        }
    },
    "224": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #224",
                "description": "description",
                "dna": "32cbba99c326443bb8a6cfa5d94a57d90a234667",
                "date": 1683435854638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "224",
                "rarity_score": 187.111818794995,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/224.png",
            "scheme": "https"
        }
    },
    "1168": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1168",
                "description": "description",
                "dna": "02f8f25829d435c2cda276d969fabc72035f7811",
                "date": 1683436411441,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1168",
                "rarity_score": 239.0923030777816,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1168.png",
            "scheme": "https"
        }
    },
    "620": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #620",
                "description": "description",
                "dna": "036ff2bcab61dca0a992e8eab8a4668d1298d2f5",
                "date": 1683774824895,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "620",
                "rarity_score": 281.655183257749,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/620.png",
            "scheme": "https"
        }
    },
    "103": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #103",
                "description": "description",
                "dna": "a049a3ba675713a4426217e64b0ab114a2cc168c",
                "date": 1683435406236,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "103",
                "rarity_score": 215.8892332566742,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/103.png",
            "scheme": "https"
        }
    },
    "1757": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1757",
                "description": "description",
                "dna": "53adca85a3af3150b531d8ee27fdfe7093003a5f",
                "date": 1683438563952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1757",
                "rarity_score": 198.89084327117598,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1757.png",
            "scheme": "https"
        }
    },
    "3465": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3465",
                "description": "description",
                "dna": "bf8d9503ae07f4c1f7907caf67b9aca6f6d46883",
                "date": 1683434272637,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3465",
                "rarity_score": 194.05876896739318,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3465.png",
            "scheme": "https"
        }
    },
    "3622": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3622",
                "description": "description",
                "dna": "aa16ed662585c8cfe37e19c55355c68cc5872293",
                "date": 1683776792309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3622",
                "rarity_score": 270.38854811634764,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3622.png",
            "scheme": "https"
        }
    },
    "3512": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3512",
                "description": "description",
                "dna": "05a9061f32b564a2d2771beed6306b2240c426ec",
                "date": 1683774560648,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3512",
                "rarity_score": 190.59375544774795,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3512.png",
            "scheme": "https"
        }
    },
    "312": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #312",
                "description": "description",
                "dna": "bc1115998613c7ca9f822c8fd36c0b5ff1b2c6c4",
                "date": 1683438861832,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "312",
                "rarity_score": 178.78426821182373,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/312.png",
            "scheme": "https"
        }
    },
    "89": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #89",
                "description": "description",
                "dna": "957a5339b6e385e4f35bfb39cb463e7b7e4eb491",
                "date": 1683445260715,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "89",
                "rarity_score": 261.88362270224764,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/89.png",
            "scheme": "https"
        }
    },
    "68": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #68",
                "description": "description",
                "dna": "0f04fade805f9dbeeb8de72350202905a84eda6c",
                "date": 1683431426339,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "68",
                "rarity_score": 161.0146944839948,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/68.png",
            "scheme": "https"
        }
    },
    "4082": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4082",
                "description": "description",
                "dna": "2f498256448c2edecc6d27e214216c1c35cf0036",
                "date": 1683445875173,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "4082",
                "rarity_score": 229.1883329728107,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4082.png",
            "scheme": "https"
        }
    },
    "2195": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2195",
                "description": "description",
                "dna": "5b586e99a5e4e93cfb822e2f4b8de617e58b99d2",
                "date": 1683439210230,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2195",
                "rarity_score": 227.37093230902695,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2195.png",
            "scheme": "https"
        }
    },
    "3026": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3026",
                "description": "description",
                "dna": "56f4c05a2daf52b65e8b3beab33c37dfc0b7930e",
                "date": 1683434088431,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3026",
                "rarity_score": 192.09465748601764,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3026.png",
            "scheme": "https"
        }
    },
    "3553": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3553",
                "description": "description",
                "dna": "11b115c83b5e1d93005dffbcd0c52ccefdd6b0a9",
                "date": 1683434344590,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3553",
                "rarity_score": 193.2008219865996,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3553.png",
            "scheme": "https"
        }
    },
    "759": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #759",
                "description": "description",
                "dna": "3e30ea5db8e289735ad26b3855365c2ae40a7dc9",
                "date": 1683774569739,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "759",
                "rarity_score": 203.5967155910194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/759.png",
            "scheme": "https"
        }
    },
    "331": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #331",
                "description": "description",
                "dna": "5eac8ec563f2f7122e46eaedc307ee97153f5273",
                "date": 1683435796939,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "331",
                "rarity_score": 248.37116242773635,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/331.png",
            "scheme": "https"
        }
    },
    "3203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3203",
                "description": "description",
                "dna": "4c7df5a90fd4faec27deff8b5253199d6f1e1a1b",
                "date": 1683445348027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3203",
                "rarity_score": 299.9862432267496,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3203.png",
            "scheme": "https"
        }
    },
    "273": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #273",
                "description": "description",
                "dna": "fc28d57caf9f2bec9c55d1ba657bc35f32d98e9b",
                "date": 1683771530794,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "273",
                "rarity_score": 288.64358648537,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/273.png",
            "scheme": "https"
        }
    },
    "2305": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2305",
                "description": "description",
                "dna": "40839287d6849aeb8ef899892d1c55a1641a1ebd",
                "date": 1683431129367,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "2305",
                "rarity_score": 299.0507291010405,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2305.png",
            "scheme": "https"
        }
    },
    "2722": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2722",
                "description": "description",
                "dna": "e394e6e912beca4b9000889af56598eb232f4a29",
                "date": 1683774704596,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2722",
                "rarity_score": 172.25583555661535,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2722.png",
            "scheme": "https"
        }
    },
    "1581": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1581",
                "description": "description",
                "dna": "9c6a844927b1362cfe7f67e8c043b485fbb236f8",
                "date": 1683438274284,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1581",
                "rarity_score": 193.42235514989144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1581.png",
            "scheme": "https"
        }
    },
    "267": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #267",
                "description": "description",
                "dna": "b92a571d48b6013daf341e13a048d1fffc39868d",
                "date": 1683430829778,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "267",
                "rarity_score": 209.3037185784566,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/267.png",
            "scheme": "https"
        }
    },
    "642": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #642",
                "description": "description",
                "dna": "fc2ac57a252cad8e09a4df140da4af42727d1f02",
                "date": 1683434589058,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "642",
                "rarity_score": 161.4013444442627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/642.png",
            "scheme": "https"
        }
    },
    "1383": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1383",
                "description": "description",
                "dna": "5298b04db98c525e42f1d7d2cfa8cd09549d85cb",
                "date": 1683434712027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1383",
                "rarity_score": 179.8614525972354,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1383.png",
            "scheme": "https"
        }
    },
    "610": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #610",
                "description": "description",
                "dna": "11241f64d18ef1d032aef7597691ab00bf290ae1",
                "date": 1683771374854,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "610",
                "rarity_score": 179.5611778648049,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/610.png",
            "scheme": "https"
        }
    },
    "853": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #853",
                "description": "description",
                "dna": "43786ebfd417b21b589e0c15589ab2cd540e6af3",
                "date": 1683436162592,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "853",
                "rarity_score": 229.74055522671503,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/853.png",
            "scheme": "https"
        }
    },
    "1488": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1488",
                "description": "description",
                "dna": "5477ac13d715dc731f6034699d8120475a69bd16",
                "date": 1683434975098,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1488",
                "rarity_score": 175.05403493180904,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1488.png",
            "scheme": "https"
        }
    },
    "2283": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2283",
                "description": "description",
                "dna": "a28476e9b2f422a9263c0e06c5568e176e8ed971",
                "date": 1683446804864,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2283",
                "rarity_score": 271.3584016533989,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2283.png",
            "scheme": "https"
        }
    },
    "542": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #542",
                "description": "description",
                "dna": "6c9cd201b6ce477da4bceda09c8f6a7b08ae78f5",
                "date": 1683438577477,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "542",
                "rarity_score": 202.96391749389937,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/542.png",
            "scheme": "https"
        }
    },
    "2461": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2461",
                "description": "description",
                "dna": "f24a7892f4cfcb8e538075388de3a5a0c3314def",
                "date": 1683437682723,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "2461",
                "rarity_score": 188.80718664422176,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2461.png",
            "scheme": "https"
        }
    },
    "3750": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3750",
                "description": "description",
                "dna": "20b9b18246ef3a4646ceadd31fad519f9065dfc5",
                "date": 1683435927294,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3750",
                "rarity_score": 187.74116434427054,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3750.png",
            "scheme": "https"
        }
    },
    "3207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3207",
                "description": "description",
                "dna": "b7531edf3d22ce2f5bd8b2b361ae5ef59be6fdfd",
                "date": 1683770643604,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3207",
                "rarity_score": 234.72117540028154,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3207.png",
            "scheme": "https"
        }
    },
    "2437": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2437",
                "description": "description",
                "dna": "89696925a42bdb748838d4bdd6effd121c61352e",
                "date": 1683441106828,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2437",
                "rarity_score": 237.95341032496754,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2437.png",
            "scheme": "https"
        }
    },
    "2329": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2329",
                "description": "description",
                "dna": "331889283ec7b4223adadfa706641292cdc78029",
                "date": 1683436579433,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2329",
                "rarity_score": 196.5452604499001,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2329.png",
            "scheme": "https"
        }
    },
    "2513": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2513",
                "description": "description",
                "dna": "861b9333fdd1bb64c886e34dd39f0775b0d03c37",
                "date": 1683445292908,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2513",
                "rarity_score": 329.8836239181214,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2513.png",
            "scheme": "https"
        }
    },
    "2300": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2300",
                "description": "description",
                "dna": "d59bbe9472b7b80dd03ced333a61b541da6af898",
                "date": 1683440481170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2300",
                "rarity_score": 167.27589499945492,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2300.png",
            "scheme": "https"
        }
    },
    "863": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #863",
                "description": "description",
                "dna": "a1274387b22537cb524e2a5813949213648b8550",
                "date": 1683433244383,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "863",
                "rarity_score": 160.667541394657,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/863.png",
            "scheme": "https"
        }
    },
    "2862": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2862",
                "description": "description",
                "dna": "ce5ccf13b61043932c909eeece667a75abb16307",
                "date": 1683771209054,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2862",
                "rarity_score": 181.90724765748416,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2862.png",
            "scheme": "https"
        }
    },
    "3192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3192",
                "description": "description",
                "dna": "6b565bff220a5f64ab758d77067d8cc86417c0f7",
                "date": 1683434970278,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3192",
                "rarity_score": 207.89291146953926,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3192.png",
            "scheme": "https"
        }
    },
    "20": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #20",
                "description": "description",
                "dna": "0ddde1212e4dde4f654ebadb560984206da80943",
                "date": 1683433870567,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "20",
                "rarity_score": 206.8500568042724,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/20.png",
            "scheme": "https"
        }
    },
    "2564": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2564",
                "description": "description",
                "dna": "6d6bc2dd56b1507ea710bb59f3476f1e81a4a2a2",
                "date": 1683776553453,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2564",
                "rarity_score": 203.87324293528326,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2564.png",
            "scheme": "https"
        }
    },
    "3131": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3131",
                "description": "description",
                "dna": "e1be2b53f983382b8179b9541bdaed14348cbf89",
                "date": 1683434010613,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3131",
                "rarity_score": 184.11081416886597,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3131.png",
            "scheme": "https"
        }
    },
    "902": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #902",
                "description": "description",
                "dna": "d273d35a22fa1ffb9c8ea2c9540522295b86c59b",
                "date": 1683442104168,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "902",
                "rarity_score": 323.76364772642114,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/902.png",
            "scheme": "https"
        }
    },
    "3312": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3312",
                "description": "description",
                "dna": "88092d825ba4a964d82499b30015ab5586cc454a",
                "date": 1683438096177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3312",
                "rarity_score": 196.1462945196072,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3312.png",
            "scheme": "https"
        }
    },
    "1634": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1634",
                "description": "description",
                "dna": "9184f7df317b9c4fae9c66f725de9beab32a3381",
                "date": 1683443229856,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1634",
                "rarity_score": 267.6414680212157,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1634.png",
            "scheme": "https"
        }
    },
    "3942": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3942",
                "description": "description",
                "dna": "c336d516e028237930ab749e0fa26c9e11484afd",
                "date": 1683771264330,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3942",
                "rarity_score": 212.95980952352124,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3942.png",
            "scheme": "https"
        }
    },
    "322": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #322",
                "description": "description",
                "dna": "0828ca2f428b88ceb435aac220d3cfc091e3e9b6",
                "date": 1683434240413,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "322",
                "rarity_score": 191.7028482509177,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/322.png",
            "scheme": "https"
        }
    },
    "2633": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2633",
                "description": "description",
                "dna": "89b068563e9514bcb026e7e0d006eea150936ddc",
                "date": 1683440541547,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2633",
                "rarity_score": 266.39830378032485,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2633.png",
            "scheme": "https"
        }
    },
    "2623": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2623",
                "description": "description",
                "dna": "64bc4e7f406351b026e4e576e47916e9f3094240",
                "date": 1683438844205,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "2623",
                "rarity_score": 176.7292750977515,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2623.png",
            "scheme": "https"
        }
    },
    "26": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #26",
                "description": "description",
                "dna": "83f4eb116a08811b4726a137f74282ea1cb206b3",
                "date": 1683436510792,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "26",
                "rarity_score": 243.2579556082642,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/26.png",
            "scheme": "https"
        }
    },
    "413": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #413",
                "description": "description",
                "dna": "2e706be958b51b4cf651c324f7a106a4864c402d",
                "date": 1683442201149,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "413",
                "rarity_score": 211.59111575454278,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/413.png",
            "scheme": "https"
        }
    },
    "2216": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2216",
                "description": "description",
                "dna": "77f7149b851eedcb8971967cf14a1b4b0efa322a",
                "date": 1683432808104,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2216",
                "rarity_score": 161.73887630246824,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2216.png",
            "scheme": "https"
        }
    },
    "2837": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2837",
                "description": "description",
                "dna": "53779117c637fac8538f365388b9e13e4cf06c7c",
                "date": 1683433834463,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "2837",
                "rarity_score": 285.5339296036931,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2837.png",
            "scheme": "https"
        }
    },
    "2270": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2270",
                "description": "description",
                "dna": "e5d979d65ba83811f44e48543b540dedc0ceda12",
                "date": 1683433713309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2270",
                "rarity_score": 323.51606258743385,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2270.png",
            "scheme": "https"
        }
    },
    "4013": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4013",
                "description": "description",
                "dna": "f888f257ddcf1b47433442b41e0d0f1f1fab1d85",
                "date": 1683775244816,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4013",
                "rarity_score": 210.7913655508993,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4013.png",
            "scheme": "https"
        }
    },
    "355": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #355",
                "description": "description",
                "dna": "b6f2ceab3ada9cf3027d0707dc88d62b354d6cee",
                "date": 1683444162744,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "355",
                "rarity_score": 181.86235988073508,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/355.png",
            "scheme": "https"
        }
    },
    "2606": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2606",
                "description": "description",
                "dna": "1930cc3c3aebf8455ee27546224a328faee9e216",
                "date": 1683429526533,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "2606",
                "rarity_score": 303.3727613893345,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2606.png",
            "scheme": "https"
        }
    },
    "2995": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2995",
                "description": "description",
                "dna": "4c2831dc27c6f4cf6f1fc146b873ee17e97b1ba0",
                "date": 1683447073685,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2995",
                "rarity_score": 158.4298525328912,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2995.png",
            "scheme": "https"
        }
    },
    "2936": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2936",
                "description": "description",
                "dna": "9b5803514e2d7c67e7b20cc900377c48cd3091c3",
                "date": 1683446951369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2936",
                "rarity_score": 265.3313042638524,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2936.png",
            "scheme": "https"
        }
    },
    "829": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #829",
                "description": "description",
                "dna": "b27b46e796e8f47de54fc66f7f00427035ae2899",
                "date": 1683436053615,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "829",
                "rarity_score": 162.23799037622655,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/829.png",
            "scheme": "https"
        }
    },
    "2626": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2626",
                "description": "description",
                "dna": "55efe7890ba76fbe60a9b806477a9a83a2da701e",
                "date": 1683429738171,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2626",
                "rarity_score": 180.65718965895266,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2626.png",
            "scheme": "https"
        }
    },
    "2326": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2326",
                "description": "description",
                "dna": "c211e3299579b10d25e24164c3ccc100018f4b49",
                "date": 1683435592170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2326",
                "rarity_score": 337.279467514348,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2326.png",
            "scheme": "https"
        }
    },
    "1293": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1293",
                "description": "description",
                "dna": "9f718ab297434bb8a413068310690cbd36073015",
                "date": 1683430079162,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1293",
                "rarity_score": 162.9927447314587,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1293.png",
            "scheme": "https"
        }
    },
    "3780": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3780",
                "description": "description",
                "dna": "adad2301682daaf7a6d252cf605207afc3445e74",
                "date": 1683435684111,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3780",
                "rarity_score": 175.92170901158934,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3780.png",
            "scheme": "https"
        }
    },
    "3702": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3702",
                "description": "description",
                "dna": "28d66b34b1c0fd146a93b7792ee15bac7912ca00",
                "date": 1683435597861,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "3702",
                "rarity_score": 301.5614676609451,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3702.png",
            "scheme": "https"
        }
    },
    "2726": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2726",
                "description": "description",
                "dna": "fea6cb469bc744a2e5b161a54094835b65dee787",
                "date": 1683443375554,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2726",
                "rarity_score": 160.8429236292071,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2726.png",
            "scheme": "https"
        }
    },
    "1568": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1568",
                "description": "description",
                "dna": "38b137a35523e141d096d7d6fc24d51f756b431b",
                "date": 1683776375277,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1568",
                "rarity_score": 199.15183023552788,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1568.png",
            "scheme": "https"
        }
    },
    "3695": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3695",
                "description": "description",
                "dna": "cf21a32087fdc7941342e18fd290566974d45597",
                "date": 1683434434091,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3695",
                "rarity_score": 180.65745185965483,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3695.png",
            "scheme": "https"
        }
    },
    "965": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #965",
                "description": "description",
                "dna": "ecdad758ffbcc0c65b8932c25241f2d79a738804",
                "date": 1683438344799,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "965",
                "rarity_score": 332.4009373682929,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/965.png",
            "scheme": "https"
        }
    },
    "2379": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2379",
                "description": "description",
                "dna": "23d0550f5fc1f746cfea6b04575b3f371979e2c7",
                "date": 1683432546157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2379",
                "rarity_score": 198.3452666814742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2379.png",
            "scheme": "https"
        }
    },
    "388": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #388",
                "description": "description",
                "dna": "63909c9a0a35b380533e864313736c184fb5b645",
                "date": 1683440408446,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "388",
                "rarity_score": 206.08283118701283,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/388.png",
            "scheme": "https"
        }
    },
    "970": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #970",
                "description": "description",
                "dna": "e75cdc82b4cba1828f89fe25b128d98f2abd86ca",
                "date": 1683442564043,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "970",
                "rarity_score": 276.18535003681893,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/970.png",
            "scheme": "https"
        }
    },
    "3350": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3350",
                "description": "description",
                "dna": "4dbbd0ba65423cce6189a20e5a131e26c8fc2e8b",
                "date": 1683441565835,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3350",
                "rarity_score": 175.04909301363466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3350.png",
            "scheme": "https"
        }
    },
    "3102": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3102",
                "description": "description",
                "dna": "6263b96ab222291cb250079ff1a2cab41f7821ca",
                "date": 1683432927958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3102",
                "rarity_score": 188.36772767879364,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3102.png",
            "scheme": "https"
        }
    },
    "3445": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3445",
                "description": "description",
                "dna": "9d1c1649a16074122e24357e673530b8d4fcde0c",
                "date": 1683437809117,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "3445",
                "rarity_score": 222.5745263582699,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3445.png",
            "scheme": "https"
        }
    },
    "2699": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2699",
                "description": "description",
                "dna": "b54654fa5ee94bce68e7763b478af932d5eb7d07",
                "date": 1683441710328,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2699",
                "rarity_score": 157.89883385971768,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2699.png",
            "scheme": "https"
        }
    },
    "3127": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3127",
                "description": "description",
                "dna": "e1d76c732a537841fda6a20ed13a2a448a0febfc",
                "date": 1683439376037,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3127",
                "rarity_score": 216.08955277741254,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3127.png",
            "scheme": "https"
        }
    },
    "2422": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2422",
                "description": "description",
                "dna": "88eeb70200dd238fc278d13537f597b81f61293d",
                "date": 1683771328107,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2422",
                "rarity_score": 194.7633457282548,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2422.png",
            "scheme": "https"
        }
    },
    "2149": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2149",
                "description": "description",
                "dna": "c58db5e8d986398d5be9ad38a76d59f6376b3e44",
                "date": 1683447010759,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2149",
                "rarity_score": 224.534932040976,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2149.png",
            "scheme": "https"
        }
    },
    "4010": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4010",
                "description": "description",
                "dna": "2c554098130b2debfe7ed4bfa2718fc9c901565d",
                "date": 1683447434402,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4010",
                "rarity_score": 189.06227673060422,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4010.png",
            "scheme": "https"
        }
    },
    "27": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #27",
                "description": "description",
                "dna": "3ac609639057f91e61562b9f762ebdb6b50c9fe4",
                "date": 1683431977585,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "27",
                "rarity_score": 224.63241651676128,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/27.png",
            "scheme": "https"
        }
    },
    "3861": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3861",
                "description": "description",
                "dna": "3267c36c8d5ee05b534e3824e3ded1a754dd8679",
                "date": 1683437838668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3861",
                "rarity_score": 309.9630626151611,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3861.png",
            "scheme": "https"
        }
    },
    "2978": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2978",
                "description": "description",
                "dna": "dc897df92d8a280097de180a478a54f9c2bf80bc",
                "date": 1683446246126,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2978",
                "rarity_score": 145.36686959510442,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2978.png",
            "scheme": "https"
        }
    },
    "1331": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1331",
                "description": "description",
                "dna": "b166a9e70dc54794573c8f02277833c958df3e19",
                "date": 1683440450170,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1331",
                "rarity_score": 229.75245465601188,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1331.png",
            "scheme": "https"
        }
    },
    "2921": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2921",
                "description": "description",
                "dna": "730f5a89390077c52f43e3cc1b51e3cc4a845270",
                "date": 1683435578634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2921",
                "rarity_score": 199.85887690468283,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2921.png",
            "scheme": "https"
        }
    },
    "2540": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2540",
                "description": "description",
                "dna": "5efcbc5850f8df6b774c11f5e9eb92db8d70f4ba",
                "date": 1683771187891,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2540",
                "rarity_score": 192.96606970115664,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2540.png",
            "scheme": "https"
        }
    },
    "626": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #626",
                "description": "description",
                "dna": "bba4816b49a00d67b3e972d563b8effece2c7fda",
                "date": 1683776447577,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "626",
                "rarity_score": 174.88267204384223,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/626.png",
            "scheme": "https"
        }
    },
    "2078": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2078",
                "description": "description",
                "dna": "471f760508534ef755bd8688ac97ce4a00218a69",
                "date": 1683441530502,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2078",
                "rarity_score": 228.00780487001316,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2078.png",
            "scheme": "https"
        }
    },
    "707": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #707",
                "description": "description",
                "dna": "3c67a9daa3152c4ab89607788aec07c462628283",
                "date": 1683436281167,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "707",
                "rarity_score": 210.41613273261694,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/707.png",
            "scheme": "https"
        }
    },
    "2462": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2462",
                "description": "description",
                "dna": "2800c1ab808f6cf0f3a5ad48d454e99186045862",
                "date": 1683443357716,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2462",
                "rarity_score": 368.06755354063074,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2462.png",
            "scheme": "https"
        }
    },
    "1727": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1727",
                "description": "description",
                "dna": "b6d578026d59dc3ef06efed96e17a158157afc89",
                "date": 1683439864966,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1727",
                "rarity_score": 272.09468391384297,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1727.png",
            "scheme": "https"
        }
    },
    "3036": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3036",
                "description": "description",
                "dna": "25b82748b99576fb814d81415f2078417e564d09",
                "date": 1683434389674,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3036",
                "rarity_score": 205.28001824059362,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3036.png",
            "scheme": "https"
        }
    },
    "2955": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2955",
                "description": "description",
                "dna": "14d498d1feb552131a44703025eac1f06c74e4c7",
                "date": 1683774470935,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2955",
                "rarity_score": 184.99396453130603,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2955.png",
            "scheme": "https"
        }
    },
    "3227": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3227",
                "description": "description",
                "dna": "43d163f3eb9026a939623f9261dc8b1eef80a378",
                "date": 1683432635483,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3227",
                "rarity_score": 253.0655985059831,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3227.png",
            "scheme": "https"
        }
    },
    "3366": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3366",
                "description": "description",
                "dna": "8448401b65daf66ac02e81ecc5f5b12ee93696a9",
                "date": 1683437453668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3366",
                "rarity_score": 133.2291415738594,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3366.png",
            "scheme": "https"
        }
    },
    "3532": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3532",
                "description": "description",
                "dna": "6c511b8b10848ec5a32ddb0bee487d32f2037265",
                "date": 1683771243506,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3532",
                "rarity_score": 175.67311975315852,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3532.png",
            "scheme": "https"
        }
    },
    "1258": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1258",
                "description": "description",
                "dna": "4737eebc5e37f01e1c72bc0112887082889a4c01",
                "date": 1683438233481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1258",
                "rarity_score": 193.81316869906198,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1258.png",
            "scheme": "https"
        }
    },
    "443": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #443",
                "description": "description",
                "dna": "ce6d48f3ea105fc68c1ff1f74c7163736470d4a2",
                "date": 1683433686017,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "443",
                "rarity_score": 147.46767553199535,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/443.png",
            "scheme": "https"
        }
    },
    "1096": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1096",
                "description": "description",
                "dna": "4d51c5b57d42454d287e21b59b12996387121b72",
                "date": 1683440335629,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1096",
                "rarity_score": 157.8633730264345,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1096.png",
            "scheme": "https"
        }
    },
    "2252": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2252",
                "description": "description",
                "dna": "a85ec77a248683141df692832171589eec6b3a8a",
                "date": 1683430498877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2252",
                "rarity_score": 169.1700092636129,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2252.png",
            "scheme": "https"
        }
    },
    "3625": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3625",
                "description": "description",
                "dna": "9491c0eae0c7e1b1f7317b36ec51510e62844e94",
                "date": 1683436583932,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3625",
                "rarity_score": 230.0962471946745,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3625.png",
            "scheme": "https"
        }
    },
    "2088": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2088",
                "description": "description",
                "dna": "c547d573dd63d714d08cba3e68af41f928ee0eb9",
                "date": 1683440845985,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2088",
                "rarity_score": 145.04193771393622,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2088.png",
            "scheme": "https"
        }
    },
    "3282": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3282",
                "description": "description",
                "dna": "7870205135b58a25ba29b64dc9c6cb83959ad6cb",
                "date": 1683429290744,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3282",
                "rarity_score": 210.50506920326154,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3282.png",
            "scheme": "https"
        }
    },
    "37": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #37",
                "description": "description",
                "dna": "00e589d438614059d3224fac6153eba9a0525e9c",
                "date": 1683434781587,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "37",
                "rarity_score": 161.50539192546503,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/37.png",
            "scheme": "https"
        }
    },
    "959": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #959",
                "description": "description",
                "dna": "3cae62aff7ebfc221f1a4e009b40cf9980c30575",
                "date": 1683438158430,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "959",
                "rarity_score": 181.4571934913083,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/959.png",
            "scheme": "https"
        }
    },
    "2565": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2565",
                "description": "description",
                "dna": "76ff6da0b80f7082f3286fc64a082dec09386ce3",
                "date": 1683443803195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2565",
                "rarity_score": 157.9568229838517,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2565.png",
            "scheme": "https"
        }
    },
    "484": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #484",
                "description": "description",
                "dna": "a1a6e446af765e21d203b9974f8b54631a1c1db5",
                "date": 1683434932792,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "484",
                "rarity_score": 198.58638877923863,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/484.png",
            "scheme": "https"
        }
    },
    "2136": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2136",
                "description": "description",
                "dna": "1711471bcd9849eedf4835e69ac079c905398661",
                "date": 1683435138966,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2136",
                "rarity_score": 267.412313852916,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2136.png",
            "scheme": "https"
        }
    },
    "660": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #660",
                "description": "description",
                "dna": "e028913811b602769e927f433de4f7d81abd9fd1",
                "date": 1683447371228,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "660",
                "rarity_score": 193.43505597489042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/660.png",
            "scheme": "https"
        }
    },
    "991": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #991",
                "description": "description",
                "dna": "d811015b67254067e9f6d22edfdb4737053a6dcb",
                "date": 1683446182326,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "991",
                "rarity_score": 131.76982654607275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/991.png",
            "scheme": "https"
        }
    },
    "2557": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2557",
                "description": "description",
                "dna": "d484cd0907098bcbbc697a876257c8e92b6e86c6",
                "date": 1683439384848,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2557",
                "rarity_score": 182.13511349249652,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2557.png",
            "scheme": "https"
        }
    },
    "1906": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1906",
                "description": "description",
                "dna": "9f888f80a968a4405e7b35c1b83b158cf55ec2fa",
                "date": 1683444559360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1906",
                "rarity_score": 170.58701464755407,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1906.png",
            "scheme": "https"
        }
    },
    "3368": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3368",
                "description": "description",
                "dna": "36c3a9c9db80e7cf16020fd5fd53b04b63954817",
                "date": 1683429405660,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3368",
                "rarity_score": 234.6666998548552,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3368.png",
            "scheme": "https"
        }
    },
    "2959": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2959",
                "description": "description",
                "dna": "58852ca8f792aefa4267695a3d4214a97d6e26d4",
                "date": 1683775065839,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2959",
                "rarity_score": 214.403247674694,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2959.png",
            "scheme": "https"
        }
    },
    "123": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #123",
                "description": "description",
                "dna": "fa5c3e93d457841d0fce52311a3ac52367ccc23a",
                "date": 1683771205008,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "123",
                "rarity_score": 205.25628364857369,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/123.png",
            "scheme": "https"
        }
    },
    "300": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #300",
                "description": "description",
                "dna": "83341d5932ad861586d21210e17634d6d6a3f16d",
                "date": 1683446159219,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "300",
                "rarity_score": 141.2516901317191,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/300.png",
            "scheme": "https"
        }
    },
    "1348": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1348",
                "description": "description",
                "dna": "2bebcbb86f5a4f5d774e57c24c1615c7110c0439",
                "date": 1683432803843,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1348",
                "rarity_score": 165.57700521004404,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1348.png",
            "scheme": "https"
        }
    },
    "464": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #464",
                "description": "description",
                "dna": "bb3b1001c95d3293727c73ddd58e421019fb9e1e",
                "date": 1683437268620,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "464",
                "rarity_score": 125.07052361229059,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/464.png",
            "scheme": "https"
        }
    },
    "3298": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3298",
                "description": "description",
                "dna": "ca527ef2f77f687af2329d183e47dafba6ef432b",
                "date": 1683440116439,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3298",
                "rarity_score": 198.81761531664228,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3298.png",
            "scheme": "https"
        }
    },
    "3499": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3499",
                "description": "description",
                "dna": "eaec02283275857f93a971357c64d8cc19325644",
                "date": 1683444563803,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3499",
                "rarity_score": 158.58077618103806,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3499.png",
            "scheme": "https"
        }
    },
    "3455": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3455",
                "description": "description",
                "dna": "665514777adf20e47d438f397460e969a94ac548",
                "date": 1683436420194,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3455",
                "rarity_score": 173.76558996619676,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3455.png",
            "scheme": "https"
        }
    },
    "1021": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1021",
                "description": "description",
                "dna": "9cd855007044c5fed9164450d04e18c08e002630",
                "date": 1683443847211,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1021",
                "rarity_score": 211.91367029629524,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1021.png",
            "scheme": "https"
        }
    },
    "4084": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4084",
                "description": "description",
                "dna": "270292e6dc973b9200e4b1c7af49066a758722af",
                "date": 1683770514885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4084",
                "rarity_score": 295.2794157872106,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4084.png",
            "scheme": "https"
        }
    },
    "1592": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1592",
                "description": "description",
                "dna": "c8cd62386bf6e13bb62e0473babf4f127cacf1bc",
                "date": 1683442693044,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1592",
                "rarity_score": 154.52932208135354,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1592.png",
            "scheme": "https"
        }
    },
    "1764": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1764",
                "description": "description",
                "dna": "1f1d45d08253ea401247dff33a7f236a4d52f0a8",
                "date": 1683776595185,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1764",
                "rarity_score": 253.6751860082751,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1764.png",
            "scheme": "https"
        }
    },
    "2434": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2434",
                "description": "description",
                "dna": "b76b1d1ba887fa5201425a7f108b8a74af2af825",
                "date": 1683439874855,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2434",
                "rarity_score": 157.91516356204173,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2434.png",
            "scheme": "https"
        }
    },
    "309": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #309",
                "description": "description",
                "dna": "aed851555a60a68e4ec6c3dbb0c0662ec051cc00",
                "date": 1683441147288,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "309",
                "rarity_score": 165.44808432106433,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/309.png",
            "scheme": "https"
        }
    },
    "886": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #886",
                "description": "description",
                "dna": "98c0bb5a30b93f61d91e684dd7c9fd90d94c86b1",
                "date": 1683442369347,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "886",
                "rarity_score": 182.66727391844267,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/886.png",
            "scheme": "https"
        }
    },
    "724": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #724",
                "description": "description",
                "dna": "c57f672cff7371ede046d0f269e8bb9f15e92db2",
                "date": 1683508626721,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "724",
                "rarity_score": 379.4786096659853,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/724.png",
            "scheme": "https"
        }
    },
    "1232": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1232",
                "description": "description",
                "dna": "1a22136c900362b79c3de37a1af4c1a4ee5bd4c5",
                "date": 1683441944336,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1232",
                "rarity_score": 174.12445886259263,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1232.png",
            "scheme": "https"
        }
    },
    "1948": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1948",
                "description": "description",
                "dna": "de189f5325a9a201cc7d083c043ffc0831cf9805",
                "date": 1683443812276,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1948",
                "rarity_score": 150.07095816719828,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1948.png",
            "scheme": "https"
        }
    },
    "51": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #51",
                "description": "description",
                "dna": "2fb5e09bee9de803dc3bdd02a08f3221bf9d1000",
                "date": 1683431754763,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "51",
                "rarity_score": 231.9314704420035,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/51.png",
            "scheme": "https"
        }
    },
    "411": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #411",
                "description": "description",
                "dna": "8d73c854bf4be77937e8bdf926c7047bb9ddcef1",
                "date": 1683436602123,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "411",
                "rarity_score": 218.02926583932611,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/411.png",
            "scheme": "https"
        }
    },
    "1637": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1637",
                "description": "description",
                "dna": "30c77e9c5dbd2447ad4bac2aea00a6084982964a",
                "date": 1683442753069,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1637",
                "rarity_score": 203.7643600799409,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1637.png",
            "scheme": "https"
        }
    },
    "4098": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4098",
                "description": "description",
                "dna": "005569bcae2fb2803f8866a470e6890d83241762",
                "date": 1683775143986,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4098",
                "rarity_score": 174.6230283986943,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4098.png",
            "scheme": "https"
        }
    },
    "207": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #207",
                "description": "description",
                "dna": "69e675336b90dbb8891635d6e903a15f93981d33",
                "date": 1683441808566,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "207",
                "rarity_score": 178.58850465295396,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/207.png",
            "scheme": "https"
        }
    },
    "1077": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1077",
                "description": "description",
                "dna": "c52f620c06d2ab142343659235827259f9d52989",
                "date": 1683432396369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1077",
                "rarity_score": 190.678528245133,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1077.png",
            "scheme": "https"
        }
    },
    "3843": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3843",
                "description": "description",
                "dna": "da58c7e43ae670ff5aba50e088b1134f325e85d5",
                "date": 1683444281719,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3843",
                "rarity_score": 213.01355722848018,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3843.png",
            "scheme": "https"
        }
    },
    "521": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #521",
                "description": "description",
                "dna": "c708f7bccaf01cef05e15edb6d034cc255fed21d",
                "date": 1683432816433,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "521",
                "rarity_score": 192.08286796841043,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/521.png",
            "scheme": "https"
        }
    },
    "2201": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2201",
                "description": "description",
                "dna": "c426c695ff67b0cbbf7428d0f437941abe8bbc6e",
                "date": 1683446534276,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2201",
                "rarity_score": 133.47989751397859,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2201.png",
            "scheme": "https"
        }
    },
    "1432": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1432",
                "description": "description",
                "dna": "072abb8d57d1248e3abe038d4698b4916e4ac6b8",
                "date": 1683446027411,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1432",
                "rarity_score": 167.3756371098583,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1432.png",
            "scheme": "https"
        }
    },
    "977": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #977",
                "description": "description",
                "dna": "d5dc0561ebfc9d202681f09049281d6f35da7c8f",
                "date": 1683446415131,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "977",
                "rarity_score": 121.8564746385454,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/977.png",
            "scheme": "https"
        }
    },
    "1033": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1033",
                "description": "description",
                "dna": "db31ad627d9041746403b670278bf808fa180278",
                "date": 1683446066723,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1033",
                "rarity_score": 160.7186360838189,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1033.png",
            "scheme": "https"
        }
    },
    "1738": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1738",
                "description": "description",
                "dna": "69e6820cd05bfd8fb28dc9f7dccf7f0ec125e08c",
                "date": 1683438237872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1738",
                "rarity_score": 244.5773748983599,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1738.png",
            "scheme": "https"
        }
    },
    "2911": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2911",
                "description": "description",
                "dna": "26729c3127e75384de4fb297d0524537baf00250",
                "date": 1683436171958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2911",
                "rarity_score": 240.67304158904255,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2911.png",
            "scheme": "https"
        }
    },
    "4008": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4008",
                "description": "description",
                "dna": "df93a395f526d21c1ea5b0c4cdeb2f6d17731450",
                "date": 1683430016284,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "4008",
                "rarity_score": 286.2031182724227,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4008.png",
            "scheme": "https"
        }
    },
    "260": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #260",
                "description": "description",
                "dna": "c23513099954690fd8b13896a07e5d8806a60d14",
                "date": 1683447065226,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "260",
                "rarity_score": 222.16393619598543,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/260.png",
            "scheme": "https"
        }
    },
    "2925": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2925",
                "description": "description",
                "dna": "0e127ec1e12f8396bf354142e677a7de906dc7f2",
                "date": 1683438283877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2925",
                "rarity_score": 221.6062293862305,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2925.png",
            "scheme": "https"
        }
    },
    "685": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #685",
                "description": "description",
                "dna": "5ed350d392157218a7105ae190e1b029f691a9e6",
                "date": 1683775226618,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "685",
                "rarity_score": 168.47262250092143,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/685.png",
            "scheme": "https"
        }
    },
    "864": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #864",
                "description": "description",
                "dna": "d845f98868977ab9ca009463b3a2ce3f314922bf",
                "date": 1683444108198,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "864",
                "rarity_score": 151.3558377545676,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/864.png",
            "scheme": "https"
        }
    },
    "973": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #973",
                "description": "description",
                "dna": "19417fc9a14059396cb1e42fea564540bb70d286",
                "date": 1683435143746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "973",
                "rarity_score": 191.25628958152944,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/973.png",
            "scheme": "https"
        }
    },
    "3378": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3378",
                "description": "description",
                "dna": "ee560e486224570e28ddf9c40c615b4ffcb1ac81",
                "date": 1683440047378,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3378",
                "rarity_score": 211.59963526250954,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3378.png",
            "scheme": "https"
        }
    },
    "1827": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1827",
                "description": "description",
                "dna": "67a8c30b1b0b0be5a90b96e2ae1a842b3d00cf5f",
                "date": 1683443011630,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1827",
                "rarity_score": 154.29953153171084,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1827.png",
            "scheme": "https"
        }
    },
    "1196": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1196",
                "description": "description",
                "dna": "67d40ed534fb5f8699de0663b6e5dd7272c7ab43",
                "date": 1683439456682,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1196",
                "rarity_score": 220.03747284818485,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1196.png",
            "scheme": "https"
        }
    },
    "1164": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1164",
                "description": "description",
                "dna": "618cfb409b05049afad28c4562761565833b018c",
                "date": 1683429945583,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1164",
                "rarity_score": 182.94669239744704,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1164.png",
            "scheme": "https"
        }
    },
    "2146": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2146",
                "description": "description",
                "dna": "644ebd47edd706968f3fa38b764b764373ae76f1",
                "date": 1683430034016,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2146",
                "rarity_score": 192.43295573414426,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2146.png",
            "scheme": "https"
        }
    },
    "2241": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2241",
                "description": "description",
                "dna": "14d821b8f968e8a07d7ae3b54a92ca45838dbe93",
                "date": 1683440608801,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2241",
                "rarity_score": 156.31406992049136,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2241.png",
            "scheme": "https"
        }
    },
    "3796": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3796",
                "description": "description",
                "dna": "fde9880c986ea3732ce2ab9bdb92158a3f5f99f6",
                "date": 1683435801524,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3796",
                "rarity_score": 177.785342145168,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3796.png",
            "scheme": "https"
        }
    },
    "3433": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3433",
                "description": "description",
                "dna": "2761f3c990c97e2a068fdf20590eaccb300cd86b",
                "date": 1683442702402,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3433",
                "rarity_score": 158.763257101742,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3433.png",
            "scheme": "https"
        }
    },
    "3023": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3023",
                "description": "description",
                "dna": "13699b6c5b56857f251b86e6fd9280bb0fcfcc2d",
                "date": 1683776749173,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3023",
                "rarity_score": 216.50722309541482,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3023.png",
            "scheme": "https"
        }
    },
    "4007": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4007",
                "description": "description",
                "dna": "0ed3ba652a3b8f6050e6b0602abbf0585b65c3d1",
                "date": 1683436227931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "4007",
                "rarity_score": 338.724072426513,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4007.png",
            "scheme": "https"
        }
    },
    "869": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #869",
                "description": "description",
                "dna": "e17e5043a0d62d6f776aa12417c1766ae2531673",
                "date": 1683442647734,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "869",
                "rarity_score": 180.01898502005074,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/869.png",
            "scheme": "https"
        }
    },
    "52": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #52",
                "description": "description",
                "dna": "0c57c6eb7f049b5c8a3e3b517aa8422e47166eae",
                "date": 1683437181990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "52",
                "rarity_score": 155.6281983109023,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/52.png",
            "scheme": "https"
        }
    },
    "1089": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1089",
                "description": "description",
                "dna": "0051e97ec59c24fe54fcd9f6a682f7edc46af7b5",
                "date": 1683445370956,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1089",
                "rarity_score": 174.2371249703144,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1089.png",
            "scheme": "https"
        }
    },
    "719": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #719",
                "description": "description",
                "dna": "9e35bd732f0bf8fd2a8a95f5646fa38d633aa09c",
                "date": 1683438006125,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "719",
                "rarity_score": 233.87943807035776,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/719.png",
            "scheme": "https"
        }
    },
    "2853": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2853",
                "description": "description",
                "dna": "9e5dadd437c5aeb6c835f3906e85d881ba13e6a0",
                "date": 1683438087388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "2853",
                "rarity_score": 196.36932204415137,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2853.png",
            "scheme": "https"
        }
    },
    "920": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #920",
                "description": "description",
                "dna": "f4264755d3b18313647ffe660126fc903e2f0e22",
                "date": 1683434749719,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "920",
                "rarity_score": 168.86645535011388,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/920.png",
            "scheme": "https"
        }
    },
    "2966": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2966",
                "description": "description",
                "dna": "f0fd5d29be4eeccc01eee671c4127cd9921dfc9e",
                "date": 1683440604558,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2966",
                "rarity_score": 205.79672634522402,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2966.png",
            "scheme": "https"
        }
    },
    "2556": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2556",
                "description": "description",
                "dna": "69f97c5c8d70ceb30053002ff6134a9c96964ed4",
                "date": 1683431691634,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2556",
                "rarity_score": 171.51857138814734,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2556.png",
            "scheme": "https"
        }
    },
    "804": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #804",
                "description": "description",
                "dna": "70b9fa950af050e785397056dcdc3922c6a86f7d",
                "date": 1683437429676,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "804",
                "rarity_score": 234.16692199837559,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/804.png",
            "scheme": "https"
        }
    },
    "833": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #833",
                "description": "description",
                "dna": "a494b56f4d835b46ee634a640f1b9dee8194354b",
                "date": 1683439610080,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "833",
                "rarity_score": 199.504448423525,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/833.png",
            "scheme": "https"
        }
    },
    "790": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #790",
                "description": "description",
                "dna": "8b1261c1fc1ea36cba93b82d873fab39a5aeba65",
                "date": 1683445896571,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "790",
                "rarity_score": 132.54457787303122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/790.png",
            "scheme": "https"
        }
    },
    "692": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #692",
                "description": "description",
                "dna": "63076ca5d36a9edbf5fd175b63223c2c2b8cd5fa",
                "date": 1683445525315,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "692",
                "rarity_score": 322.01722627142567,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/692.png",
            "scheme": "https"
        }
    },
    "1587": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1587",
                "description": "description",
                "dna": "f54182edca8c716b5d0020ab548d48a4ad286596",
                "date": 1683433110382,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1587",
                "rarity_score": 212.93250905060302,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1587.png",
            "scheme": "https"
        }
    },
    "2905": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2905",
                "description": "description",
                "dna": "9ce8ed0dd425a29e90461c8c711e15941be0feab",
                "date": 1683775197173,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2905",
                "rarity_score": 157.8698255740616,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2905.png",
            "scheme": "https"
        }
    },
    "1974": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1974",
                "description": "description",
                "dna": "23016f8d6c3f49bbb70df1109e2ed4cf1c162839",
                "date": 1683438512614,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1974",
                "rarity_score": 218.72124613644067,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1974.png",
            "scheme": "https"
        }
    },
    "1761": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1761",
                "description": "description",
                "dna": "097813ab71685edc28598faf20be32306e2ef7ef",
                "date": 1683776510885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1761",
                "rarity_score": 208.77685168930296,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1761.png",
            "scheme": "https"
        }
    },
    "2933": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2933",
                "description": "description",
                "dna": "eebaa39691b0e9ea4892eb562e65994c11cf6315",
                "date": 1683446451192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2933",
                "rarity_score": 117.43119924151009,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2933.png",
            "scheme": "https"
        }
    },
    "1753": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1753",
                "description": "description",
                "dna": "d8aed2a64423d94edd85e65832c173f1c36943bc",
                "date": 1683433502157,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1753",
                "rarity_score": 170.42069135137297,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1753.png",
            "scheme": "https"
        }
    },
    "857": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #857",
                "description": "description",
                "dna": "3bfe8573b88f5953b75423505971617dfaaf79f2",
                "date": 1683445542902,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "857",
                "rarity_score": 266.4230369208527,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/857.png",
            "scheme": "https"
        }
    },
    "4021": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4021",
                "description": "description",
                "dna": "74fbccf92d813033df6b0b63782e9ec4f2eb7f4c",
                "date": 1683434521642,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "4021",
                "rarity_score": 188.05525993401994,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4021.png",
            "scheme": "https"
        }
    },
    "181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #181",
                "description": "description",
                "dna": "302297fdd6c65f47edc494d162a613486253f92a",
                "date": 1683442182792,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "181",
                "rarity_score": 156.69479566192655,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/181.png",
            "scheme": "https"
        }
    },
    "1538": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1538",
                "description": "description",
                "dna": "c501e40048c3f9f6630040dc6738d3058c6398b7",
                "date": 1683443842902,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1538",
                "rarity_score": 196.67010987747182,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1538.png",
            "scheme": "https"
        }
    },
    "12": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #12",
                "description": "description",
                "dna": "71fb385cf572a352dd940aed99fd7b4ec79633a0",
                "date": 1683440326851,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "12",
                "rarity_score": 363.61829675220974,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/12.png",
            "scheme": "https"
        }
    },
    "1283": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1283",
                "description": "description",
                "dna": "9fe48781bd8b3403a11bb66dc3e64783d1551238",
                "date": 1683445613099,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1283",
                "rarity_score": 247.9399902849263,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1283.png",
            "scheme": "https"
        }
    },
    "1691": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1691",
                "description": "description",
                "dna": "4d18aa35eaac3105b84b2184a512bd1f81709a75",
                "date": 1683434993361,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1691",
                "rarity_score": 244.21503824880142,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1691.png",
            "scheme": "https"
        }
    },
    "3086": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3086",
                "description": "description",
                "dna": "f3ee60dcc9d8616db7eaadd062914bf1a9c5138d",
                "date": 1683438472826,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3086",
                "rarity_score": 169.32790127505342,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3086.png",
            "scheme": "https"
        }
    },
    "1907": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1907",
                "description": "description",
                "dna": "72ab73117a43332953feeb8c55ac1c3e72fad005",
                "date": 1683444978152,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1907",
                "rarity_score": 160.03261460593063,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1907.png",
            "scheme": "https"
        }
    },
    "2849": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2849",
                "description": "description",
                "dna": "41d2d3eb4f21e9fa1c85ddf26d1210c654b53d22",
                "date": 1683445414110,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2849",
                "rarity_score": 163.62687754553076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2849.png",
            "scheme": "https"
        }
    },
    "1318": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1318",
                "description": "description",
                "dna": "ba1a29039acb0741eaac5284c23353589dd0bbcd",
                "date": 1683442336751,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1318",
                "rarity_score": 185.48485049080287,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1318.png",
            "scheme": "https"
        }
    },
    "3110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3110",
                "description": "description",
                "dna": "aaeebee5f2aedb71689e798b4290c598640125c8",
                "date": 1683436670606,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3110",
                "rarity_score": 257.95928832468695,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3110.png",
            "scheme": "https"
        }
    },
    "63": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #63",
                "description": "description",
                "dna": "213d38f73ff0370c48fbbb5137da14b3ab3e2e15",
                "date": 1683431444260,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "63",
                "rarity_score": 177.8330505508341,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/63.png",
            "scheme": "https"
        }
    },
    "356": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #356",
                "description": "description",
                "dna": "93362a0664341af927150a1775ba3881bdccd152",
                "date": 1683447362605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "356",
                "rarity_score": 197.89002957177965,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/356.png",
            "scheme": "https"
        }
    },
    "2109": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2109",
                "description": "description",
                "dna": "9a7afe7953c8ff869931c01cb63b22f96cc2b6aa",
                "date": 1683431011414,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2109",
                "rarity_score": 245.28343414895136,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2109.png",
            "scheme": "https"
        }
    },
    "2108": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2108",
                "description": "description",
                "dna": "f710b1f967d19eaf7ebcbe08e18c1ec609687d0d",
                "date": 1683445208040,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2108",
                "rarity_score": 145.69365173080575,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2108.png",
            "scheme": "https"
        }
    },
    "2713": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2713",
                "description": "description",
                "dna": "82ade5ee5ef2fc840c4974efc0ec46b1be45ee8c",
                "date": 1683441605342,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2713",
                "rarity_score": 194.44874442050303,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2713.png",
            "scheme": "https"
        }
    },
    "2651": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2651",
                "description": "description",
                "dna": "557f0f0595f04a52a1b293e449e9be8ae3963f33",
                "date": 1683441057773,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2651",
                "rarity_score": 211.84875986185514,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2651.png",
            "scheme": "https"
        }
    },
    "2610": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2610",
                "description": "description",
                "dna": "0d2b788b82d2a0577c6a1cf78e8aebd915cc6b01",
                "date": 1683431928131,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2610",
                "rarity_score": 202.76687221839308,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2610.png",
            "scheme": "https"
        }
    },
    "2399": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2399",
                "description": "description",
                "dna": "510cdf525d69e80e00c2345d09fa21ba3d567db8",
                "date": 1683440578196,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2399",
                "rarity_score": 166.00729116149012,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2399.png",
            "scheme": "https"
        }
    },
    "1636": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1636",
                "description": "description",
                "dna": "da40b7bafcfeaa487f702b8bc6dab91f378ba472",
                "date": 1683431188733,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1636",
                "rarity_score": 166.93125392057487,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1636.png",
            "scheme": "https"
        }
    },
    "2729": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2729",
                "description": "description",
                "dna": "2130e0561e5442d0349e80409d5d4068ed024a2e",
                "date": 1683430834123,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2729",
                "rarity_score": 187.91251067044968,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2729.png",
            "scheme": "https"
        }
    },
    "1055": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1055",
                "description": "description",
                "dna": "5f627e18f5eeb34daddfce8b154e122b4e9a1b36",
                "date": 1683770589961,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1055",
                "rarity_score": 229.77722822161894,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1055.png",
            "scheme": "https"
        }
    },
    "3267": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3267",
                "description": "description",
                "dna": "b42d6a6fe4837621928ed2f47d7e5feb7836c083",
                "date": 1683446218508,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3267",
                "rarity_score": 121.13916438427606,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3267.png",
            "scheme": "https"
        }
    },
    "1825": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1825",
                "description": "description",
                "dna": "46db8e18a6d18ce37c87fbdd068e360432a939d9",
                "date": 1683439797401,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1825",
                "rarity_score": 175.4558449291805,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1825.png",
            "scheme": "https"
        }
    },
    "1226": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1226",
                "description": "description",
                "dna": "6f01e41377cf53961e0aacea53f99125bedad39b",
                "date": 1683775179776,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1226",
                "rarity_score": 174.11194305164003,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1226.png",
            "scheme": "https"
        }
    },
    "1110": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1110",
                "description": "description",
                "dna": "5ec70313e564d89fd8955daf373afd6c9c45c5e1",
                "date": 1683433290528,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1110",
                "rarity_score": 177.14850813309693,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1110.png",
            "scheme": "https"
        }
    },
    "1181": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1181",
                "description": "description",
                "dna": "104c669cbf6a1577131e30a9f4b92bf77b7bfb5a",
                "date": 1683442192390,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1181",
                "rarity_score": 290.4357012033912,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1181.png",
            "scheme": "https"
        }
    },
    "3573": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3573",
                "description": "description",
                "dna": "0ceecf7c90865de983eeb0cbf48fe984fdbb3e99",
                "date": 1683433235598,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3573",
                "rarity_score": 186.08085771226237,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3573.png",
            "scheme": "https"
        }
    },
    "2900": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2900",
                "description": "description",
                "dna": "1a39b16488a6fd28980c6eb8a0bcf5bd0a04b6a1",
                "date": 1683443605677,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2900",
                "rarity_score": 164.63336120093814,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2900.png",
            "scheme": "https"
        }
    },
    "3952": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3952",
                "description": "description",
                "dna": "2faa0b5356ac8c4afb8ddb677d7792f73bc2ca7f",
                "date": 1683436747043,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3952",
                "rarity_score": 204.62812772734216,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3952.png",
            "scheme": "https"
        }
    },
    "182": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #182",
                "description": "description",
                "dna": "4ea6648bf78840541f857224f2453bf1455ef816",
                "date": 1683436836690,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "182",
                "rarity_score": 212.84211337251122,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/182.png",
            "scheme": "https"
        }
    },
    "2160": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2160",
                "description": "description",
                "dna": "004285a6f2de1a71a7b6f9bff437a558ca89108d",
                "date": 1683437655868,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2160",
                "rarity_score": 183.17570173184959,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2160.png",
            "scheme": "https"
        }
    },
    "3472": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3472",
                "description": "description",
                "dna": "e155db1374b4f6c02aec4653fe6d625cb6a5e97d",
                "date": 1683443555253,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3472",
                "rarity_score": 143.32869034915345,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3472.png",
            "scheme": "https"
        }
    },
    "1816": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1816",
                "description": "description",
                "dna": "75182bc444bab630737bc278bb65a53da8333eca",
                "date": 1683440727416,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1816",
                "rarity_score": 177.26462810773683,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1816.png",
            "scheme": "https"
        }
    },
    "24": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #24",
                "description": "description",
                "dna": "589e18df699f82bf69a89402bc36e2eb825c2ea2",
                "date": 1683431495509,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "24",
                "rarity_score": 229.15791174845316,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/24.png",
            "scheme": "https"
        }
    },
    "2027": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2027",
                "description": "description",
                "dna": "3619eaceb763d17628d082e7e83e3569eb3ddfc4",
                "date": 1683430897801,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "2027",
                "rarity_score": 311.14874598748844,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2027.png",
            "scheme": "https"
        }
    },
    "3478": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3478",
                "description": "description",
                "dna": "b2b71260c2afd1d72596e694dbdb30b9a37b041e",
                "date": 1683436625532,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3478",
                "rarity_score": 223.5360646686993,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3478.png",
            "scheme": "https"
        }
    },
    "636": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #636",
                "description": "description",
                "dna": "6230d87afc97b2e1e4927fb169b7c1fe484ff1d2",
                "date": 1683444471433,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "636",
                "rarity_score": 238.60720673647393,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/636.png",
            "scheme": "https"
        }
    },
    "3977": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3977",
                "description": "description",
                "dna": "a3e35b353bc0d882cc87bf1181d2a96c0e06c6e9",
                "date": 1683435913638,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3977",
                "rarity_score": 171.62333041116716,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3977.png",
            "scheme": "https"
        }
    },
    "1251": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1251",
                "description": "description",
                "dna": "747c280e0f54f25a33d284f67c80da9c9de04143",
                "date": 1683440872774,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1251",
                "rarity_score": 350.8089782058561,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1251.png",
            "scheme": "https"
        }
    },
    "3909": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3909",
                "description": "description",
                "dna": "c2c14ea0a48a9dd91355338f3d9785ceed4491d3",
                "date": 1683444431389,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3909",
                "rarity_score": 223.94008368342523,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3909.png",
            "scheme": "https"
        }
    },
    "3305": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3305",
                "description": "description",
                "dna": "a5efe7a46c7bb1cbdb788c475e83602c856dd9b0",
                "date": 1683446380205,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3305",
                "rarity_score": 122.09355672561657,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3305.png",
            "scheme": "https"
        }
    },
    "3561": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3561",
                "description": "description",
                "dna": "1e97fe3826c3c5a077d64185f07f4b25072383e7",
                "date": 1683444025192,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3561",
                "rarity_score": 163.1768254756008,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3561.png",
            "scheme": "https"
        }
    },
    "3257": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3257",
                "description": "description",
                "dna": "4b45626322bd62bde58d1668ee1317f94ab9c0f1",
                "date": 1683435227995,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3257",
                "rarity_score": 155.65219273082081,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3257.png",
            "scheme": "https"
        }
    },
    "373": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #373",
                "description": "description",
                "dna": "b5172499a7678a07712c680918beeff02663cd06",
                "date": 1683434570522,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "373",
                "rarity_score": 150.65894139190434,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/373.png",
            "scheme": "https"
        }
    },
    "654": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #654",
                "description": "description",
                "dna": "82dd0afb6b804cbfef05e6a9bc31132e916c7582",
                "date": 1683445847972,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "654",
                "rarity_score": 245.46762514074393,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/654.png",
            "scheme": "https"
        }
    },
    "1662": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1662",
                "description": "description",
                "dna": "f100c1e722cb9bb5d5301f302df6a50e9161269b",
                "date": 1683439787828,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1662",
                "rarity_score": 189.55806243470977,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1662.png",
            "scheme": "https"
        }
    },
    "881": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #881",
                "description": "description",
                "dna": "90dd1805621d02b0b2a12f1c4dad14f15c54a1a8",
                "date": 1683437971190,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "881",
                "rarity_score": 204.06122478166856,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/881.png",
            "scheme": "https"
        }
    },
    "3844": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3844",
                "description": "description",
                "dna": "16121e6fcfdcb6a05a91b31b1ede8010fab296e8",
                "date": 1683445435027,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "3844",
                "rarity_score": 229.68811084000544,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3844.png",
            "scheme": "https"
        }
    },
    "2744": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2744",
                "description": "description",
                "dna": "42bee0d6dc67523d5e4ec4c953e7c3fd5d66526e",
                "date": 1683508605298,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2744",
                "rarity_score": 433.8284011536278,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2744.png",
            "scheme": "https"
        }
    },
    "2174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2174",
                "description": "description",
                "dna": "358894c6a66ea9e0fac57b2e2adf04e15dbbe69b",
                "date": 1683433909586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2174",
                "rarity_score": 221.89626396145212,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2174.png",
            "scheme": "https"
        }
    },
    "1989": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1989",
                "description": "description",
                "dna": "777732b7b13e2a45366f3e97392d8c9bb895de6e",
                "date": 1683508622586,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "1989",
                "rarity_score": 483.5913317742927,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1989.png",
            "scheme": "https"
        }
    },
    "1437": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1437",
                "description": "description",
                "dna": "edae19befb56d4b2b40f7911e9f8507102382a15",
                "date": 1683432352053,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1437",
                "rarity_score": 249.01205538794687,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1437.png",
            "scheme": "https"
        }
    },
    "1888": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1888",
                "description": "description",
                "dna": "67b7166188fbd788b30f6c93affaa13eee84b00e",
                "date": 1683441280098,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1888",
                "rarity_score": 231.94991325899522,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1888.png",
            "scheme": "https"
        }
    },
    "2638": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2638",
                "description": "description",
                "dna": "81ba3fb0343b51e10038c7f9aa802270f94ce7c3",
                "date": 1683431453522,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2638",
                "rarity_score": 189.72598661250927,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2638.png",
            "scheme": "https"
        }
    },
    "1025": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1025",
                "description": "description",
                "dna": "d13971e7b94875902ead889deff1cbcedafac688",
                "date": 1683443647821,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1025",
                "rarity_score": 183.80066018410713,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1025.png",
            "scheme": "https"
        }
    },
    "1679": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1679",
                "description": "description",
                "dna": "ce7359f2f9ba6bd9833d33b9e6b59ef44b807ab9",
                "date": 1683441039683,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1679",
                "rarity_score": 199.9439200211612,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1679.png",
            "scheme": "https"
        }
    },
    "2723": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2723",
                "description": "description",
                "dna": "2726e44caf3b9cd50974617e99ba826e9a33b603",
                "date": 1683771166885,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2723",
                "rarity_score": 216.73660627137343,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2723.png",
            "scheme": "https"
        }
    },
    "1607": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1607",
                "description": "description",
                "dna": "31c2bc9424d62dabcae6eeae979bd8b6e4ac5c2a",
                "date": 1683431007217,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1607",
                "rarity_score": 193.95393497988536,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1607.png",
            "scheme": "https"
        }
    },
    "702": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #702",
                "description": "description",
                "dna": "261acea25c7345058456552cfd23c00581235942",
                "date": 1683433812291,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "702",
                "rarity_score": 173.24812194741511,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/702.png",
            "scheme": "https"
        }
    },
    "2290": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2290",
                "description": "description",
                "dna": "194163ea347ec3ef7b0fdfc9dd8b50062eda5c44",
                "date": 1683441714247,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "2290",
                "rarity_score": 196.99860196071592,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2290.png",
            "scheme": "https"
        }
    },
    "2570": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2570",
                "description": "description",
                "dna": "5c2fd395436e86f1c3ed1c8c18b14fe7410747f3",
                "date": 1683438437647,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2570",
                "rarity_score": 159.55916352373006,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2570.png",
            "scheme": "https"
        }
    },
    "2482": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2482",
                "description": "description",
                "dna": "e089f36f818d38fb72d9335b2ea5b6462e51aafe",
                "date": 1683437158370,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2482",
                "rarity_score": 172.4747745858097,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2482.png",
            "scheme": "https"
        }
    },
    "872": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #872",
                "description": "description",
                "dna": "20b04ece5d3106581439a2ab549a0a87a23acd8c",
                "date": 1683443582499,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "872",
                "rarity_score": 214.7287707192832,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/872.png",
            "scheme": "https"
        }
    },
    "1942": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1942",
                "description": "description",
                "dna": "5917ba9d50be28776a3bff716a5e7e46a07c5443",
                "date": 1683438677990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1942",
                "rarity_score": 183.30799764082172,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1942.png",
            "scheme": "https"
        }
    },
    "2335": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2335",
                "description": "description",
                "dna": "dae9ea59ad3cdce1ebe7f82831cd9aa5f48b4a92",
                "date": 1683431565024,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2335",
                "rarity_score": 176.25168790787544,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2335.png",
            "scheme": "https"
        }
    },
    "2834": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2834",
                "description": "description",
                "dna": "aec22c67319f55c2fbe4c6e2e1b24ac3ab3b13cc",
                "date": 1683447184008,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2834",
                "rarity_score": 200.8297291891145,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2834.png",
            "scheme": "https"
        }
    },
    "2962": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2962",
                "description": "description",
                "dna": "d5b07875dd5de1d075df4bbdda0abbd41957220f",
                "date": 1683446113240,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2962",
                "rarity_score": 197.65059015926087,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2962.png",
            "scheme": "https"
        }
    },
    "2401": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2401",
                "description": "description",
                "dna": "7ef1d60a6f4087a03c97dd3386380163bfbc406c",
                "date": 1683438848685,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2401",
                "rarity_score": 191.7512957304562,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2401.png",
            "scheme": "https"
        }
    },
    "1413": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1413",
                "description": "description",
                "dna": "45b5d4b8b186a419ea68762063af50853190b663",
                "date": 1683431476480,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1413",
                "rarity_score": 291.95016597902065,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1413.png",
            "scheme": "https"
        }
    },
    "4053": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4053",
                "description": "description",
                "dna": "592cf9a17afce50ca7fdb5f173706af057d6a713",
                "date": 1683776404545,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "4053",
                "rarity_score": 202.95814814749264,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4053.png",
            "scheme": "https"
        }
    },
    "1971": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1971",
                "description": "description",
                "dna": "dec2c9d3e369c8e9855df1f2d16fbdeea3f508cf",
                "date": 1683439543506,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1971",
                "rarity_score": 209.9631824917147,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1971.png",
            "scheme": "https"
        }
    },
    "900": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #900",
                "description": "description",
                "dna": "22821c0a68732b257642c9bf3ff03989ba0ac74a",
                "date": 1683442421291,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "900",
                "rarity_score": 203.80915726231532,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/900.png",
            "scheme": "https"
        }
    },
    "1642": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1642",
                "description": "description",
                "dna": "3f00459183fa69984d3d4ebce8d835138a52d5f6",
                "date": 1683430553042,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1642",
                "rarity_score": 210.91579227415264,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1642.png",
            "scheme": "https"
        }
    },
    "1894": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1894",
                "description": "description",
                "dna": "6bc6f1d88b17095831f2058cff567060c22ad400",
                "date": 1683440654492,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1894",
                "rarity_score": 188.00090931109057,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1894.png",
            "scheme": "https"
        }
    },
    "281": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #281",
                "description": "description",
                "dna": "01c9e4081f12c54146dfe15076b1a0c931357f2a",
                "date": 1683437285214,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "281",
                "rarity_score": 166.69835232421778,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/281.png",
            "scheme": "https"
        }
    },
    "2877": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2877",
                "description": "description",
                "dna": "4bd5ee88ba920631cb01c61f2c5ae1a29558dfa8",
                "date": 1683433394878,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2877",
                "rarity_score": 166.8046173610941,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2877.png",
            "scheme": "https"
        }
    },
    "4061": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4061",
                "description": "description",
                "dna": "622b2721380549568bb3e7eaf56aa37db6071f28",
                "date": 1683774866147,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "4061",
                "rarity_score": 196.09359592755237,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4061.png",
            "scheme": "https"
        }
    },
    "3903": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3903",
                "description": "description",
                "dna": "36cf7953cdfa33efad540f5b1b4ad98264b3f433",
                "date": 1683435544929,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3903",
                "rarity_score": 163.7584642255624,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3903.png",
            "scheme": "https"
        }
    },
    "3502": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3502",
                "description": "description",
                "dna": "cac8c56e33d1cb9d2c3eba28599f0a6efef3dbb8",
                "date": 1683776565732,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3502",
                "rarity_score": 231.5108280303103,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3502.png",
            "scheme": "https"
        }
    },
    "1336": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1336",
                "description": "description",
                "dna": "da08fb71b41f2033020259a37bd019812062f35f",
                "date": 1683447192655,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1336",
                "rarity_score": 224.629817851665,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1336.png",
            "scheme": "https"
        }
    },
    "205": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #205",
                "description": "description",
                "dna": "eea4598396a0da1f9b471e81ae748397c531946c",
                "date": 1683774695825,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "205",
                "rarity_score": 256.80170020693487,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/205.png",
            "scheme": "https"
        }
    },
    "2073": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2073",
                "description": "description",
                "dna": "cc108dd9258a9693951c81469565b2d6e560ffdb",
                "date": 1683439371768,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2073",
                "rarity_score": 189.07436896033124,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2073.png",
            "scheme": "https"
        }
    },
    "2312": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2312",
                "description": "description",
                "dna": "8b0062d27c958cfb8aa65abd82e6d15f1880a24b",
                "date": 1683776753663,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2312",
                "rarity_score": 199.83905249306392,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2312.png",
            "scheme": "https"
        }
    },
    "1923": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1923",
                "description": "description",
                "dna": "6feef3e0fa768ca2f874ab246a7b8e1d451968e9",
                "date": 1683441239418,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1923",
                "rarity_score": 239.07958247837485,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1923.png",
            "scheme": "https"
        }
    },
    "1203": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1203",
                "description": "description",
                "dna": "693ca7d794a95e59e8b5f9e2058ae71afbbc7328",
                "date": 1683442642846,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1203",
                "rarity_score": 185.27658927158194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1203.png",
            "scheme": "https"
        }
    },
    "466": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #466",
                "description": "description",
                "dna": "abd27507617a42ae73221edeba9588a36fd5e3df",
                "date": 1683444651518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "466",
                "rarity_score": 161.28884615499845,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/466.png",
            "scheme": "https"
        }
    },
    "3790": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3790",
                "description": "description",
                "dna": "acd6148c97831c7848f6d6072b4546ce4fdd4be0",
                "date": 1683430557481,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3790",
                "rarity_score": 208.87828603829166,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3790.png",
            "scheme": "https"
        }
    },
    "2179": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2179",
                "description": "description",
                "dna": "2178648265a3fe3c517d1a73c41798b5d547941a",
                "date": 1683774998872,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2179",
                "rarity_score": 197.76912515528537,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2179.png",
            "scheme": "https"
        }
    },
    "930": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #930",
                "description": "description",
                "dna": "674fd284efbf4208419b46eeb6676b89938be2df",
                "date": 1683435092931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "930",
                "rarity_score": 191.6434514043432,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/930.png",
            "scheme": "https"
        }
    },
    "3560": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3560",
                "description": "description",
                "dna": "98fb7d2d6c2b60a0cfd853f513343d3e56fc9ba5",
                "date": 1683433308055,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3560",
                "rarity_score": 179.671696927463,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3560.png",
            "scheme": "https"
        }
    },
    "3357": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3357",
                "description": "description",
                "dna": "dacea601fec0c205f3e86e572d130eaa3f3d68ac",
                "date": 1683437564793,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "3357",
                "rarity_score": 209.41775611191355,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3357.png",
            "scheme": "https"
        }
    },
    "2703": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2703",
                "description": "description",
                "dna": "11f2aca845563255f02922cc144802bb95afb55d",
                "date": 1683430503277,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2703",
                "rarity_score": 152.38231635856425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2703.png",
            "scheme": "https"
        }
    },
    "3556": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3556",
                "description": "description",
                "dna": "a591c7bf32201701e6f54fbf29f54d2aa5e5ea66",
                "date": 1683436877163,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3556",
                "rarity_score": 149.03053845191852,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3556.png",
            "scheme": "https"
        }
    },
    "1044": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1044",
                "description": "description",
                "dna": "029b63b0fc078b95e55c5e0319a1ddb04a5328af",
                "date": 1683445221169,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1044",
                "rarity_score": 196.56607332873878,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1044.png",
            "scheme": "https"
        }
    },
    "3889": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3889",
                "description": "description",
                "dna": "9120ca2e7e2fc8cf546d236c9435f9ec0bcd615a",
                "date": 1683430725161,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3889",
                "rarity_score": 164.6814505290342,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3889.png",
            "scheme": "https"
        }
    },
    "3865": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3865",
                "description": "description",
                "dna": "4dd05323d2b5eb6aa3fd1052bcba6159d5e2ae4d",
                "date": 1683429558609,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3865",
                "rarity_score": 152.07923466830042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3865.png",
            "scheme": "https"
        }
    },
    "1543": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1543",
                "description": "description",
                "dna": "72ced7a50027b66384f927c3d8bf818778a48f9e",
                "date": 1683445732384,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1543",
                "rarity_score": 209.62303996359287,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1543.png",
            "scheme": "https"
        }
    },
    "1094": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1094",
                "description": "description",
                "dna": "d6f43e06d7a707ca513abddba9ccf344800b3ca3",
                "date": 1683440436133,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1094",
                "rarity_score": 255.90489240454227,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1094.png",
            "scheme": "https"
        }
    },
    "805": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #805",
                "description": "description",
                "dna": "2d9dbb03dc89e17fbb2ce414a9dbee04fca6bd3e",
                "date": 1683445916087,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "805",
                "rarity_score": 163.7546104091889,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/805.png",
            "scheme": "https"
        }
    },
    "3926": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3926",
                "description": "description",
                "dna": "d4b3284c7f21f185410393f62cf8b7094fc3c861",
                "date": 1683771479177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3926",
                "rarity_score": 168.49306489365523,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3926.png",
            "scheme": "https"
        }
    },
    "893": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #893",
                "description": "description",
                "dna": "d75686f1f842f9846fff2303a9ea5b7324154d02",
                "date": 1683444414229,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "893",
                "rarity_score": 198.1158321887377,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/893.png",
            "scheme": "https"
        }
    },
    "3017": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3017",
                "description": "description",
                "dna": "e718b6b1829b57aaa3e896190a10d68640cc47db",
                "date": 1683434253900,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3017",
                "rarity_score": 233.5177185118671,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3017.png",
            "scheme": "https"
        }
    },
    "3728": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3728",
                "description": "description",
                "dna": "e8dbf3835481f8c107e6d878bcfbb77bdfd0055d",
                "date": 1683774592562,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3728",
                "rarity_score": 196.7347660344989,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3728.png",
            "scheme": "https"
        }
    },
    "1715": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1715",
                "description": "description",
                "dna": "0d3c9934dea4df445f33686a3baed7de8a1f1293",
                "date": 1683432061010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1715",
                "rarity_score": 197.1133510929718,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1715.png",
            "scheme": "https"
        }
    },
    "276": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #276",
                "description": "description",
                "dna": "a4b35d82c688c526fec42b3027781699555454ff",
                "date": 1683434281612,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "276",
                "rarity_score": 223.53503916735255,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/276.png",
            "scheme": "https"
        }
    },
    "1808": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1808",
                "description": "description",
                "dna": "6cde3ae7771f6fe2d630e64e8badcd5b1e59dbfb",
                "date": 1683439331343,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1808",
                "rarity_score": 187.01771638518898,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1808.png",
            "scheme": "https"
        }
    },
    "3211": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3211",
                "description": "description",
                "dna": "b0b9fd30aa8c40484fc0aa08ac2f97e94e09f12e",
                "date": 1683436519605,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3211",
                "rarity_score": 319.088258842539,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3211.png",
            "scheme": "https"
        }
    },
    "2024": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2024",
                "description": "description",
                "dna": "ca924ceeaaaa5b26984bba7af4f406d1c7421fe7",
                "date": 1683444285931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2024",
                "rarity_score": 239.363018579635,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2024.png",
            "scheme": "https"
        }
    },
    "2771": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2771",
                "description": "description",
                "dna": "e2a2106cb894a2564be79e77cef3d52dc567897d",
                "date": 1683446977369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2771",
                "rarity_score": 171.82115170073428,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2771.png",
            "scheme": "https"
        }
    },
    "2172": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2172",
                "description": "description",
                "dna": "08975608c31daaaf866a950d8c93719940300068",
                "date": 1683440714013,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2172",
                "rarity_score": 219.46803981031462,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2172.png",
            "scheme": "https"
        }
    },
    "1991": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1991",
                "description": "description",
                "dna": "072297d8a094688effadc125e981030af8e20720",
                "date": 1683442122838,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1991",
                "rarity_score": 161.8931126645997,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1991.png",
            "scheme": "https"
        }
    },
    "1090": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1090",
                "description": "description",
                "dna": "be5ca3482c012132e9d4103b80649fc2df47c7c3",
                "date": 1683776515190,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1090",
                "rarity_score": 323.8188281608583,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1090.png",
            "scheme": "https"
        }
    },
    "2008": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2008",
                "description": "description",
                "dna": "cbadea4e32727e2534b9cd5debc7681189a61538",
                "date": 1683434858593,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2008",
                "rarity_score": 202.98876880707982,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2008.png",
            "scheme": "https"
        }
    },
    "1728": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1728",
                "description": "description",
                "dna": "603924eba82580a3388a38314c0f39f54350e072",
                "date": 1683440023958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kadcars",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1728",
                "rarity_score": 247.22868070667676,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1728.png",
            "scheme": "https"
        }
    },
    "2011": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2011",
                "description": "description",
                "dna": "fd6ccf4c93c6c97f3ecd00db80377c35f616273a",
                "date": 1683442881354,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2011",
                "rarity_score": 220.51163539091579,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2011.png",
            "scheme": "https"
        }
    },
    "527": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #527",
                "description": "description",
                "dna": "f2ff009d1f57e230d89b0099e8cc64eebcd6e785",
                "date": 1683435631029,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "527",
                "rarity_score": 246.2047490226144,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/527.png",
            "scheme": "https"
        }
    },
    "1302": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1302",
                "description": "description",
                "dna": "6a9f668132f15a4f6040538f8fcd204e0538ca6b",
                "date": 1683430393349,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Miner",
                        "score": 128.125
                    }
                ],
                "id": "1302",
                "rarity_score": 269.69317442921476,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1302.png",
            "scheme": "https"
        }
    },
    "3829": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3829",
                "description": "description",
                "dna": "7184f840ff5f87930d789be93bfd64b06012b70b",
                "date": 1683446821778,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3829",
                "rarity_score": 191.58573062427493,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3829.png",
            "scheme": "https"
        }
    },
    "1902": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1902",
                "description": "description",
                "dna": "020154aaea398709618025b5583a691f35a278ce",
                "date": 1683431821958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1902",
                "rarity_score": 267.96658087498827,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1902.png",
            "scheme": "https"
        }
    },
    "2041": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2041",
                "description": "description",
                "dna": "b52044f932c7b663a4ee9aee8cf5c86444901892",
                "date": 1683445825630,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2041",
                "rarity_score": 263.9637815528781,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2041.png",
            "scheme": "https"
        }
    },
    "483": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #483",
                "description": "description",
                "dna": "ef6897d1886d3807287ea57e4750701d817dd0b9",
                "date": 1683430633062,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "483",
                "rarity_score": 212.69509988453723,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/483.png",
            "scheme": "https"
        }
    },
    "2503": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2503",
                "description": "description",
                "dna": "459d960de8b5d80041efd27790edbb4b8209bb0f",
                "date": 1683774792763,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2503",
                "rarity_score": 161.03349617010582,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2503.png",
            "scheme": "https"
        }
    },
    "2746": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2746",
                "description": "description",
                "dna": "29916a6ed962acb98aa659aa5addedc0bf6048e0",
                "date": 1683441244032,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2746",
                "rarity_score": 166.87835342187583,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2746.png",
            "scheme": "https"
        }
    },
    "3097": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3097",
                "description": "description",
                "dna": "20b1f6f91635de5f089af6fdc864ba7bb005f54d",
                "date": 1683439107252,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3097",
                "rarity_score": 184.7042044239349,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3097.png",
            "scheme": "https"
        }
    },
    "2846": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2846",
                "description": "description",
                "dna": "e22fa36edcb362b5db95a37615723f366fd1d59f",
                "date": 1683438802862,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2846",
                "rarity_score": 298.77013010884156,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2846.png",
            "scheme": "https"
        }
    },
    "2766": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2766",
                "description": "description",
                "dna": "f3c5e4858c3695be81bb26452ebc4658e7ead3b9",
                "date": 1683444312654,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2766",
                "rarity_score": 168.1578675520362,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2766.png",
            "scheme": "https"
        }
    },
    "700": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #700",
                "description": "description",
                "dna": "6f3e5c225df0522fc85a02b624337fb55dce919b",
                "date": 1683430579530,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "700",
                "rarity_score": 159.07516427068174,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/700.png",
            "scheme": "https"
        }
    },
    "3145": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3145",
                "description": "description",
                "dna": "3b9537fbb356339031fab4067c6cbf81919d2cee",
                "date": 1683440485845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3145",
                "rarity_score": 197.02328324651765,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3145.png",
            "scheme": "https"
        }
    },
    "608": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #608",
                "description": "description",
                "dna": "a2746eb32c2727de5942345c74e5f81cf6717b2e",
                "date": 1683430507959,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "608",
                "rarity_score": 220.1374633109973,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/608.png",
            "scheme": "https"
        }
    },
    "3443": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3443",
                "description": "description",
                "dna": "5d0ce5fe0611ce1ade6055afcfa504cc4760ee72",
                "date": 1683430988696,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3443",
                "rarity_score": 182.59849148858626,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3443.png",
            "scheme": "https"
        }
    },
    "3551": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3551",
                "description": "description",
                "dna": "62d8681d9a17f9dec5ba1823517f57e8f01acc45",
                "date": 1683443225166,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3551",
                "rarity_score": 193.81255395396863,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3551.png",
            "scheme": "https"
        }
    },
    "3589": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3589",
                "description": "description",
                "dna": "4bd9bc9cc5c926b8cffaab1b20f5226c509ac8a9",
                "date": 1683446747410,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3589",
                "rarity_score": 201.59810431338943,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3589.png",
            "scheme": "https"
        }
    },
    "3763": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3763",
                "description": "description",
                "dna": "681a46abec1bf78f548c1ddbe6c873cfb947780b",
                "date": 1683438995862,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3763",
                "rarity_score": 203.00985265890543,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3763.png",
            "scheme": "https"
        }
    },
    "3747": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3747",
                "description": "description",
                "dna": "9b8ed3ed178a3f34901f27fbc8ff0332f99ce3c3",
                "date": 1683774950985,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3747",
                "rarity_score": 223.75678803229346,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3747.png",
            "scheme": "https"
        }
    },
    "2856": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2856",
                "description": "description",
                "dna": "7625605fb269a9bf12a1113f603da6b9a1899a73",
                "date": 1683440936133,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2856",
                "rarity_score": 151.55179048450285,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2856.png",
            "scheme": "https"
        }
    },
    "638": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #638",
                "description": "description",
                "dna": "7df3039351177a873afca439ca86cdc033575897",
                "date": 1683444145208,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "638",
                "rarity_score": 319.2306236694086,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/638.png",
            "scheme": "https"
        }
    },
    "1856": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1856",
                "description": "description",
                "dna": "e6d0cfdb1b94dfb3510f1c578bd41c3c12625807",
                "date": 1683445499566,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1856",
                "rarity_score": 171.7209877815705,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1856.png",
            "scheme": "https"
        }
    },
    "919": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #919",
                "description": "description",
                "dna": "7a29bcc2a6ef99beb14550110ee0a22dce3f94a8",
                "date": 1683434479946,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Kadena",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "919",
                "rarity_score": 282.6694563319213,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/919.png",
            "scheme": "https"
        }
    },
    "1135": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1135",
                "description": "description",
                "dna": "950381a80885e86f397810d74d2a6574d534eab2",
                "date": 1683431700085,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Garden Secrets Scroll",
                        "score": 97.61904761904762
                    }
                ],
                "id": "1135",
                "rarity_score": 271.8277109987762,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1135.png",
            "scheme": "https"
        }
    },
    "122": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #122",
                "description": "description",
                "dna": "e3f5bcf02628bc4e52bc62a3d236601ba8a693d5",
                "date": 1683445678898,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "122",
                "rarity_score": 251.93594423453487,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/122.png",
            "scheme": "https"
        }
    },
    "101": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #101",
                "description": "description",
                "dna": "767683815004ebb021f43310e53b9f848a65e462",
                "date": 1683771505245,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "101",
                "rarity_score": 313.17507016600996,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/101.png",
            "scheme": "https"
        }
    },
    "1334": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1334",
                "description": "description",
                "dna": "9051111445e730afcbc4fd01e9c3dfffa3f99403",
                "date": 1683433672511,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1334",
                "rarity_score": 229.33738412331658,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1334.png",
            "scheme": "https"
        }
    },
    "216": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #216",
                "description": "description",
                "dna": "bc8e8d583bcc916c527866fa062f65643db3dd1b",
                "date": 1683774964301,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "216",
                "rarity_score": 197.56951082664258,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/216.png",
            "scheme": "https"
        }
    },
    "2320": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2320",
                "description": "description",
                "dna": "08c36e1678da54fa5ad067ced55febd9d193132f",
                "date": 1683770568391,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2320",
                "rarity_score": 286.32429866689176,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2320.png",
            "scheme": "https"
        }
    },
    "2159": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2159",
                "description": "description",
                "dna": "e589c1cee513883e6bed1aae234a7dd2f45cde56",
                "date": 1683442666663,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2159",
                "rarity_score": 207.88217122401812,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2159.png",
            "scheme": "https"
        }
    },
    "229": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #229",
                "description": "description",
                "dna": "e15c8c76126f7b167ec4c1c10474ac541618ca71",
                "date": 1683443945591,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "229",
                "rarity_score": 156.3885836323717,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/229.png",
            "scheme": "https"
        }
    },
    "4041": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4041",
                "description": "description",
                "dna": "23f24905d01e3e25e897804f51f4fdcfc12f77f3",
                "date": 1683442830863,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "4041",
                "rarity_score": 199.61507252314772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4041.png",
            "scheme": "https"
        }
    },
    "3946": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3946",
                "description": "description",
                "dna": "e1de0a8230b010dea07f729f00cfa72de6afa6fc",
                "date": 1683440663739,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3946",
                "rarity_score": 256.4502900390308,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3946.png",
            "scheme": "https"
        }
    },
    "2466": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2466",
                "description": "description",
                "dna": "6a4dcf1f9ffb933f3706b0f5092834950bbb5133",
                "date": 1683438498665,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2466",
                "rarity_score": 173.97197692936288,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2466.png",
            "scheme": "https"
        }
    },
    "4062": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4062",
                "description": "description",
                "dna": "c09c1d536aca4ca4dc9ac50d3fef7e843d565134",
                "date": 1683435792380,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "&",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "4062",
                "rarity_score": 249.01238614828063,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4062.png",
            "scheme": "https"
        }
    },
    "2259": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2259",
                "description": "description",
                "dna": "dc9bead9b733a688b60edff4f98f1eed14b67a9c",
                "date": 1683436930584,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2259",
                "rarity_score": 154.66085154057976,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2259.png",
            "scheme": "https"
        }
    },
    "1535": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1535",
                "description": "description",
                "dna": "5dcc73cb179bc1ac6ad937254805c7b1e97fa9af",
                "date": 1683432919756,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1535",
                "rarity_score": 189.90491832162303,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1535.png",
            "scheme": "https"
        }
    },
    "3949": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3949",
                "description": "description",
                "dna": "9ef9f8ab8bb3f817cbbb8a6cb4ce6bf876419f08",
                "date": 1683440422492,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3949",
                "rarity_score": 204.77948668069956,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3949.png",
            "scheme": "https"
        }
    },
    "4045": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #4045",
                "description": "description",
                "dna": "892614a3ed58ec739dca031791ae0ac89561dc5c",
                "date": 1683442467417,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "4045",
                "rarity_score": 182.73805203473472,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/4045.png",
            "scheme": "https"
        }
    },
    "90": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #90",
                "description": "description",
                "dna": "ff66d56fdf04a74a2c204ff80f462642fd906aac",
                "date": 1683431665582,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "90",
                "rarity_score": 187.13179001127259,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/90.png",
            "scheme": "https"
        }
    },
    "2618": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2618",
                "description": "description",
                "dna": "fdac6342dca062576b38d00eab9416eecf795e06",
                "date": 1683432422633,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "2618",
                "rarity_score": 431.6970084862884,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2618.png",
            "scheme": "https"
        }
    },
    "3600": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3600",
                "description": "description",
                "dna": "c8afc8ad842b849cd7dcab97d84018d713c985bf",
                "date": 1683433602096,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3600",
                "rarity_score": 159.18600211925127,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3600.png",
            "scheme": "https"
        }
    },
    "2787": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2787",
                "description": "description",
                "dna": "1dc80f77656907e7126c89b09f42d162e34b5bfb",
                "date": 1683430270104,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2787",
                "rarity_score": 174.52206027881036,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2787.png",
            "scheme": "https"
        }
    },
    "340": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #340",
                "description": "description",
                "dna": "0877a155562b3b25ea2fb5f9a0b8711087327928",
                "date": 1683440631970,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "340",
                "rarity_score": 160.1443880372228,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/340.png",
            "scheme": "https"
        }
    },
    "354": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #354",
                "description": "description",
                "dna": "80999405488bc010460e3974e5a3e0a59bbc5310",
                "date": 1683432781073,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "354",
                "rarity_score": 202.9092847531641,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/354.png",
            "scheme": "https"
        }
    },
    "3216": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3216",
                "description": "description",
                "dna": "307afa89da7557d9381cc756996fb69fc17ff579",
                "date": 1683776332931,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3216",
                "rarity_score": 201.32950142169992,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3216.png",
            "scheme": "https"
        }
    },
    "2547": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2547",
                "description": "description",
                "dna": "eba4e56c50090711493eda056ae6ba251e9ea6e1",
                "date": 1683435046744,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2547",
                "rarity_score": 202.5268593195229,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2547.png",
            "scheme": "https"
        }
    },
    "1405": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1405",
                "description": "description",
                "dna": "74ba94eb553b6840fb715a786de3f9ffff48da7b",
                "date": 1683435278958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "1405",
                "rarity_score": 328.91240703193336,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1405.png",
            "scheme": "https"
        }
    },
    "432": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #432",
                "description": "description",
                "dna": "3afc7da196b6fb466de15ebc32f6a145a9b6193b",
                "date": 1683440238195,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "432",
                "rarity_score": 158.40086867492187,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/432.png",
            "scheme": "https"
        }
    },
    "2999": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2999",
                "description": "description",
                "dna": "02131064cd2fcc715bfd53e62ab938e551048ad8",
                "date": 1683438631730,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2999",
                "rarity_score": 196.28281451405257,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2999.png",
            "scheme": "https"
        }
    },
    "1435": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1435",
                "description": "description",
                "dna": "5599c78c3ca93d425b982c33bffa781ac688a840",
                "date": 1683771362206,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1435",
                "rarity_score": 163.9219330195883,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1435.png",
            "scheme": "https"
        }
    },
    "70": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #70",
                "description": "description",
                "dna": "e1f59485ba3ef27cede0b661edd72e7d9bfb6660",
                "date": 1683433775952,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "70",
                "rarity_score": 194.94538360124363,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/70.png",
            "scheme": "https"
        }
    },
    "1680": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1680",
                "description": "description",
                "dna": "d77f89369f49694dab00464af47f6f8c8752379a",
                "date": 1683430356549,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "1680",
                "rarity_score": 187.8856988477769,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1680.png",
            "scheme": "https"
        }
    },
    "1332": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1332",
                "description": "description",
                "dna": "c8847ca2b1159a7dbf2a1ccfaa9210ef245147d2",
                "date": 1683771556619,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1332",
                "rarity_score": 229.9526947458126,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1332.png",
            "scheme": "https"
        }
    },
    "3938": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3938",
                "description": "description",
                "dna": "e82dc62e46d1865854c0109cc03fa10c3dbd23bc",
                "date": 1683776358309,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3938",
                "rarity_score": 177.56017238514102,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3938.png",
            "scheme": "https"
        }
    },
    "2254": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2254",
                "description": "description",
                "dna": "db15e1033ad4a999c5e932c1ce16632d5a7047ac",
                "date": 1683442721388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2254",
                "rarity_score": 220.0089371640249,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2254.png",
            "scheme": "https"
        }
    },
    "3236": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3236",
                "description": "description",
                "dna": "3d943bb4af47ce86e327cabf26a1de63ec7f3593",
                "date": 1683437524010,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3236",
                "rarity_score": 171.6568067868285,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3236.png",
            "scheme": "https"
        }
    },
    "1198": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1198",
                "description": "description",
                "dna": "13a300405836919d3dd8d22d7e9e285ec26e3ced",
                "date": 1683774748800,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1198",
                "rarity_score": 213.4716112280232,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1198.png",
            "scheme": "https"
        }
    },
    "147": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #147",
                "description": "description",
                "dna": "54b9ca1e7732a97152e1719a4285c8ac80c0f3b1",
                "date": 1683443783652,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "147",
                "rarity_score": 200.89982940585466,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/147.png",
            "scheme": "https"
        }
    },
    "843": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #843",
                "description": "description",
                "dna": "b23725c03fef9fa6b89cd86c9c4c4d06a721ac88",
                "date": 1683440175708,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "843",
                "rarity_score": 186.24821057352233,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/843.png",
            "scheme": "https"
        }
    },
    "2214": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2214",
                "description": "description",
                "dna": "4046643896956cb12f938e1178dbfeafed8207b3",
                "date": 1683436787955,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2214",
                "rarity_score": 238.86002589929575,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2214.png",
            "scheme": "https"
        }
    },
    "3998": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3998",
                "description": "description",
                "dna": "d023af0f248b50381838a2fbf0b1221dccf3b3b2",
                "date": 1683437038838,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3998",
                "rarity_score": 166.33097376113122,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3998.png",
            "scheme": "https"
        }
    },
    "2412": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2412",
                "description": "description",
                "dna": "98462186a458425895f4dd707adbd29bc301d69f",
                "date": 1683434331103,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "2412",
                "rarity_score": 335.5896948734986,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2412.png",
            "scheme": "https"
        }
    },
    "828": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #828",
                "description": "description",
                "dna": "96694ea4b205dd30a73f2b3ba82a2a6005d5be8b",
                "date": 1683437532107,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "828",
                "rarity_score": 137.67446529031622,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/828.png",
            "scheme": "https"
        }
    },
    "1954": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1954",
                "description": "description",
                "dna": "801dc54b7044d1fbda4a5ce8b776f73bb63568cc",
                "date": 1683776354274,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1954",
                "rarity_score": 195.31269490309106,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1954.png",
            "scheme": "https"
        }
    },
    "1406": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1406",
                "description": "description",
                "dna": "0e52947ebfccb0a18c12d1259e2e348f36ab467f",
                "date": 1683443731326,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "1406",
                "rarity_score": 162.8301047187805,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1406.png",
            "scheme": "https"
        }
    },
    "3518": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3518",
                "description": "description",
                "dna": "aeee54e8fb329d7bc23e1291fdc07bff8db673c8",
                "date": 1683434092982,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "3518",
                "rarity_score": 231.07612657642437,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3518.png",
            "scheme": "https"
        }
    },
    "3085": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3085",
                "description": "description",
                "dna": "4bbd4c7f4c0d3dc8ae3caf9e64ed7b26699464f7",
                "date": 1683774709325,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "3085",
                "rarity_score": 192.55901055304741,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3085.png",
            "scheme": "https"
        }
    },
    "1354": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1354",
                "description": "description",
                "dna": "f2f15d2687b67f4a720372af92241f3a4bbcacb9",
                "date": 1683442378641,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1354",
                "rarity_score": 188.71219062048692,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1354.png",
            "scheme": "https"
        }
    },
    "3251": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3251",
                "description": "description",
                "dna": "892e3cb8c5e034bebccbf6e93ab5dda71a4c85d5",
                "date": 1683434317483,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "3251",
                "rarity_score": 330.7666279360054,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3251.png",
            "scheme": "https"
        }
    },
    "3183": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3183",
                "description": "description",
                "dna": "866afb23478478a17f5e1ded026de6254c193ca6",
                "date": 1683443056889,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3183",
                "rarity_score": 175.3676013905263,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3183.png",
            "scheme": "https"
        }
    },
    "3129": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3129",
                "description": "description",
                "dna": "3271d61349f7f6cd6fb05c76f7cd7781f5342b4e",
                "date": 1683443243265,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mystical Stones",
                        "score": 128.125
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3129",
                "rarity_score": 272.5675137567496,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3129.png",
            "scheme": "https"
        }
    },
    "3916": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3916",
                "description": "description",
                "dna": "d72f5d32e038aa1d0ead289c981d54e11f8e6fb7",
                "date": 1683776741046,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "3916",
                "rarity_score": 193.67524318247627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3916.png",
            "scheme": "https"
        }
    },
    "1151": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1151",
                "description": "description",
                "dna": "4d551602b298a963057d716127df99ef385a711e",
                "date": 1683434107826,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1151",
                "rarity_score": 165.11126746210573,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1151.png",
            "scheme": "https"
        }
    },
    "1703": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1703",
                "description": "description",
                "dna": "a6b7bf3b19156c2c725ce2a4cc7495fd3c1db125",
                "date": 1683439241367,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1703",
                "rarity_score": 259.78309319301235,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1703.png",
            "scheme": "https"
        }
    },
    "2753": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2753",
                "description": "description",
                "dna": "42ea53890cc270961c77093d9558c0900dd20fb8",
                "date": 1683771383319,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "2753",
                "rarity_score": 219.28369491874994,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2753.png",
            "scheme": "https"
        }
    },
    "3039": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3039",
                "description": "description",
                "dna": "fcdb5643d17bab735bd01edba25536f3c3215fe7",
                "date": 1683445777461,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "3039",
                "rarity_score": 236.41871205722765,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3039.png",
            "scheme": "https"
        }
    },
    "2284": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2284",
                "description": "description",
                "dna": "3d01316b23ef26b6302f004b7fae20a86129ef14",
                "date": 1683445102062,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "2284",
                "rarity_score": 229.6352982854962,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2284.png",
            "scheme": "https"
        }
    },
    "1016": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1016",
                "description": "description",
                "dna": "838b594bee7c66d9e4bbb32c1f2b17b893a44482",
                "date": 1683446772107,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1016",
                "rarity_score": 206.58643809194837,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1016.png",
            "scheme": "https"
        }
    },
    "2152": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2152",
                "description": "description",
                "dna": "85b84f9fcaf02cc04ef7f91b3f9952d08878faad",
                "date": 1683434849770,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2152",
                "rarity_score": 203.5629140322637,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2152.png",
            "scheme": "https"
        }
    },
    "247": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #247",
                "description": "description",
                "dna": "8e94a6ec25bde996a6333f9d62e7461f8aeaa36a",
                "date": 1683434493653,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "247",
                "rarity_score": 180.24143847290298,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/247.png",
            "scheme": "https"
        }
    },
    "292": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #292",
                "description": "description",
                "dna": "7d4aebe6a57b17e923a63d09c30a92ecb6e7d4de",
                "date": 1683434163282,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "292",
                "rarity_score": 182.3748169292349,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/292.png",
            "scheme": "https"
        }
    },
    "3492": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3492",
                "description": "description",
                "dna": "8ce492183738dcca66cbea1bac94396b673b5a02",
                "date": 1683446968803,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3492",
                "rarity_score": 154.87911282651083,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3492.png",
            "scheme": "https"
        }
    },
    "2828": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2828",
                "description": "description",
                "dna": "53eecc791db22e0a6b38c39daf31dedec03b3b08",
                "date": 1683441435763,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2828",
                "rarity_score": 168.4307252315681,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2828.png",
            "scheme": "https"
        }
    },
    "3749": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3749",
                "description": "description",
                "dna": "51891a06dc427ccd862d0e3579bb3fd7cae1cc2e",
                "date": 1683440417817,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3749",
                "rarity_score": 214.71356038398767,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3749.png",
            "scheme": "https"
        }
    },
    "907": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #907",
                "description": "description",
                "dna": "6f3724c0b4b6d36f7ff21752e59f7367cab3e778",
                "date": 1683443825551,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "907",
                "rarity_score": 179.62814731683068,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/907.png",
            "scheme": "https"
        }
    },
    "1412": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1412",
                "description": "description",
                "dna": "0526ed8e8749fa6acbaab4c0ab6208cf79b28e1e",
                "date": 1683432216911,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1412",
                "rarity_score": 204.50392594525772,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1412.png",
            "scheme": "https"
        }
    },
    "720": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #720",
                "description": "description",
                "dna": "7581039d3edd23b26a29d7af1995d0d5c2eb648f",
                "date": 1683434566280,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "720",
                "rarity_score": 211.78700673337272,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/720.png",
            "scheme": "https"
        }
    },
    "3835": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3835",
                "description": "description",
                "dna": "051238bc7110d8ec4218504269b99f2df6cc930a",
                "date": 1683437637411,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "3835",
                "rarity_score": 239.17039273586977,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3835.png",
            "scheme": "https"
        }
    },
    "2561": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2561",
                "description": "description",
                "dna": "dd0e8e046ec185d3a76149f306f1169c39e3ddac",
                "date": 1683436934420,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2561",
                "rarity_score": 130.13465179896585,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2561.png",
            "scheme": "https"
        }
    },
    "2360": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2360",
                "description": "description",
                "dna": "e6c75ada727e38407b9515d97211862ad37372c5",
                "date": 1683432273433,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2360",
                "rarity_score": 162.5859248817042,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2360.png",
            "scheme": "https"
        }
    },
    "1307": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1307",
                "description": "description",
                "dna": "4f8dc84f32ae759ae5c78929c60c89485272613f",
                "date": 1683446116984,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1307",
                "rarity_score": 168.00059513060006,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1307.png",
            "scheme": "https"
        }
    },
    "277": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #277",
                "description": "description",
                "dna": "e6b9d13229ac64e1893cc06abc848211d923f91d",
                "date": 1683774601928,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "277",
                "rarity_score": 186.43404021283973,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/277.png",
            "scheme": "https"
        }
    },
    "500": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #500",
                "description": "description",
                "dna": "b2404e969b5ebb9108797217e19316f9f4186414",
                "date": 1683439672753,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "500",
                "rarity_score": 143.29109952028608,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/500.png",
            "scheme": "https"
        }
    },
    "3303": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3303",
                "description": "description",
                "dna": "20169bbf5baec541e68c94ff5f82ef68c24e5f12",
                "date": 1683771400067,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "3303",
                "rarity_score": 204.25691603929235,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3303.png",
            "scheme": "https"
        }
    },
    "1157": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1157",
                "description": "description",
                "dna": "06082f45a473ce7e86050e26dd748fd6c3fbe51f",
                "date": 1683439317954,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1157",
                "rarity_score": 151.9339592234495,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1157.png",
            "scheme": "https"
        }
    },
    "2773": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2773",
                "description": "description",
                "dna": "8405a2dd7f2bd1ecebdbcf441c79e29e4fcf830b",
                "date": 1683441470987,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2773",
                "rarity_score": 180.3015952191397,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2773.png",
            "scheme": "https"
        }
    },
    "1": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1",
                "description": "description",
                "dna": "539b0626df624e4551b6414d67bab24778bfb00d",
                "date": 1683430561805,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "1",
                "rarity_score": 178.0648902664788,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1.png",
            "scheme": "https"
        }
    },
    "1848": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1848",
                "description": "description",
                "dna": "a1113bcf611c19db8d844df5c231212ff5ba21c7",
                "date": 1683434611288,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "1848",
                "rarity_score": 218.04669880615222,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1848.png",
            "scheme": "https"
        }
    },
    "2272": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2272",
                "description": "description",
                "dna": "f76e4b9151735c2127286463e921c5597113097b",
                "date": 1683438640589,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2272",
                "rarity_score": 165.00108447869076,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2272.png",
            "scheme": "https"
        }
    },
    "3975": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3975",
                "description": "description",
                "dna": "a7cd5e17d0e191df577666e5fb049387e183d947",
                "date": 1683432550518,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3975",
                "rarity_score": 216.163822612612,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3975.png",
            "scheme": "https"
        }
    },
    "3364": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3364",
                "description": "description",
                "dna": "e1036e9c2b9c8e7c960dff0fab31671f939f88d1",
                "date": 1683446042706,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3364",
                "rarity_score": 181.0340241364558,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3364.png",
            "scheme": "https"
        }
    },
    "1431": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1431",
                "description": "description",
                "dna": "c30cc295b181923a9fa55ca021c86d088efbcace",
                "date": 1683441301750,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1431",
                "rarity_score": 149.45911867516833,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1431.png",
            "scheme": "https"
        }
    },
    "2504": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2504",
                "description": "description",
                "dna": "0977d2f91444445c9c9e5c75196b25372568dc83",
                "date": 1683439223635,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2504",
                "rarity_score": 165.20059100895392,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2504.png",
            "scheme": "https"
        }
    },
    "1404": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1404",
                "description": "description",
                "dna": "36e315d8efe1bb741ab908180950f3842ca52585",
                "date": 1683435364710,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1404",
                "rarity_score": 204.06884259670153,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1404.png",
            "scheme": "https"
        }
    },
    "3654": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3654",
                "description": "description",
                "dna": "6fdd07f3482eabf88d322d8109022a39285ceafc",
                "date": 1683442489984,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3654",
                "rarity_score": 187.51849377131146,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3654.png",
            "scheme": "https"
        }
    },
    "2253": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2253",
                "description": "description",
                "dna": "3ff981077f68b9038a43e15a3b90b491022b66b1",
                "date": 1683684133300,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Special",
                        "value": "Mechanical Claw",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "2253",
                "rarity_score": 4273.389938569577,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2253.png",
            "scheme": "https"
        }
    },
    "982": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #982",
                "description": "description",
                "dna": "876f9b5e933d2ac60794694a7a248181a952121a",
                "date": 1683684142760,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Treasure Hunter",
                        "score": 4100.0
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "982",
                "rarity_score": 4227.223339630414,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/982.png",
            "scheme": "https"
        }
    },
    "1255": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1255",
                "description": "description",
                "dna": "65da6952ed5c05d5b8387628f526cf3ae311827d",
                "date": 1683771474878,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1255",
                "rarity_score": 201.59465232586194,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1255.png",
            "scheme": "https"
        }
    },
    "1409": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1409",
                "description": "description",
                "dna": "44a44f912d81c569a6661501199c57bf0961814b",
                "date": 1683443726500,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1409",
                "rarity_score": 238.36512248376863,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1409.png",
            "scheme": "https"
        }
    },
    "856": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #856",
                "description": "description",
                "dna": "c140555ebb570ea29f19da9c61a6e00ed585310a",
                "date": 1683433334735,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "856",
                "rarity_score": 179.58025116258648,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/856.png",
            "scheme": "https"
        }
    },
    "3048": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3048",
                "description": "description",
                "dna": "a4e6db5f936d9110b0d6b278f49049edd6bd3e4e",
                "date": 1683444964975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3048",
                "rarity_score": 186.2012166529051,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3048.png",
            "scheme": "https"
        }
    },
    "3260": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3260",
                "description": "description",
                "dna": "ecaf8a8befe2040d0d666a547772684109ad1939",
                "date": 1683438162933,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3260",
                "rarity_score": 215.00449082040456,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3260.png",
            "scheme": "https"
        }
    },
    "2688": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2688",
                "description": "description",
                "dna": "578d74e695e16068abdfbb99e54f22d5d992c1e4",
                "date": 1683439098933,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2688",
                "rarity_score": 179.29565748013164,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2688.png",
            "scheme": "https"
        }
    },
    "2619": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2619",
                "description": "description",
                "dna": "779cafe5f3417661744f70f40891aa1a2bc0192f",
                "date": 1683445464877,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2619",
                "rarity_score": 173.94077664732086,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2619.png",
            "scheme": "https"
        }
    },
    "3415": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3415",
                "description": "description",
                "dna": "c43d1f84821159df986e330655cbbf1209d0cb75",
                "date": 1683441840679,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3415",
                "rarity_score": 240.40142222169655,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3415.png",
            "scheme": "https"
        }
    },
    "758": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #758",
                "description": "description",
                "dna": "bfdbf23ce1e9e3b2850f99e68c4b07fa2849f4eb",
                "date": 1683443718209,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "ISOKO",
                        "score": 73.21428571428571
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "758",
                "rarity_score": 234.42020275660357,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/758.png",
            "scheme": "https"
        }
    },
    "2364": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2364",
                "description": "description",
                "dna": "80de6d0dc087d491c636d8c74370f3675a8b530f",
                "date": 1683432173385,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2364",
                "rarity_score": 181.28303772067238,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2364.png",
            "scheme": "https"
        }
    },
    "953": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #953",
                "description": "description",
                "dna": "80c0a742c0dcb00ffe097143f2df538197e7f7ec",
                "date": 1683438255703,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Blue with Cigar",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "953",
                "rarity_score": 189.91327498763425,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/953.png",
            "scheme": "https"
        }
    },
    "1541": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1541",
                "description": "description",
                "dna": "b27a173df038b83eba2f08c010b4d1abab20db73",
                "date": 1683445862183,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1541",
                "rarity_score": 301.62669539611363,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1541.png",
            "scheme": "https"
        }
    },
    "3964": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3964",
                "description": "description",
                "dna": "27a9c9d3040612b7e834814c515625ef9a254e78",
                "date": 1683439956036,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Top Hat",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "3964",
                "rarity_score": 287.2500892806204,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3964.png",
            "scheme": "https"
        }
    },
    "2217": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2217",
                "description": "description",
                "dna": "a068849d3faaaf45e3b561af38661c92c4f6a1a6",
                "date": 1683447159139,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2217",
                "rarity_score": 221.6697412057608,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2217.png",
            "scheme": "https"
        }
    },
    "1241": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1241",
                "description": "description",
                "dna": "8b35bf7b4b593f641899cb8db928d522aba91d9f",
                "date": 1683443288433,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1241",
                "rarity_score": 242.5007123908968,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1241.png",
            "scheme": "https"
        }
    },
    "2844": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2844",
                "description": "description",
                "dna": "25addffba1a7e72c2d75cbbfa339e83088d09819",
                "date": 1683432105669,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2844",
                "rarity_score": 240.92388325738472,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2844.png",
            "scheme": "https"
        }
    },
    "1773": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1773",
                "description": "description",
                "dna": "2b025729d5f3049d03e64b7e0c2ab7ed09da5dfe",
                "date": 1683444638297,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1773",
                "rarity_score": 193.05842633693032,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1773.png",
            "scheme": "https"
        }
    },
    "3932": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3932",
                "description": "description",
                "dna": "76952bec82ca79913581bdff269deb576a0c9b84",
                "date": 1683432762479,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3932",
                "rarity_score": 149.6467153300398,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3932.png",
            "scheme": "https"
        }
    },
    "3762": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3762",
                "description": "description",
                "dna": "8981d9621d8037d158139154fe78321983b23286",
                "date": 1683447425315,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "3762",
                "rarity_score": 216.03315664559776,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3762.png",
            "scheme": "https"
        }
    },
    "2233": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2233",
                "description": "description",
                "dna": "8a8c7a3266708668de54d60ba46337b088f2e015",
                "date": 1683435666610,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 6",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Pipe",
                        "score": 67.21311475409836
                    }
                ],
                "id": "2233",
                "rarity_score": 244.28709697142307,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2233.png",
            "scheme": "https"
        }
    },
    "1443": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1443",
                "description": "description",
                "dna": "667141ca5c1f6499af6922dccb0c597a03104847",
                "date": 1683432037795,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1443",
                "rarity_score": 149.54282107364193,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1443.png",
            "scheme": "https"
        }
    },
    "3065": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3065",
                "description": "description",
                "dna": "2516f1675097e583a0638bc0f81759d0cdd9cf17",
                "date": 1683438182065,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3065",
                "rarity_score": 202.6877542068862,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3065.png",
            "scheme": "https"
        }
    },
    "1740": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1740",
                "description": "description",
                "dna": "aa431693c481e78fac9be752522e92344fee9245",
                "date": 1683431170238,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1740",
                "rarity_score": 223.10411672874835,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1740.png",
            "scheme": "https"
        }
    },
    "2397": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2397",
                "description": "description",
                "dna": "02967dfcd2ef6c4f33a146b33e255a25b4ce2a71",
                "date": 1683442862360,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2397",
                "rarity_score": 155.09804379930011,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2397.png",
            "scheme": "https"
        }
    },
    "2392": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2392",
                "description": "description",
                "dna": "19fd1984dba7f3752a49341b7c2110bd1eedfef8",
                "date": 1683775078499,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2392",
                "rarity_score": 192.00840792280547,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2392.png",
            "scheme": "https"
        }
    },
    "440": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #440",
                "description": "description",
                "dna": "bbd1f85fe430c4ed8f112aac9b2b20dd9d81b2c6",
                "date": 1683431500092,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Stones of Power",
                        "score": 78.84615384615384
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "440",
                "rarity_score": 249.26840100162863,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/440.png",
            "scheme": "https"
        }
    },
    "647": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #647",
                "description": "description",
                "dna": "591be54869f4ac82905f04af67b2147e18aae848",
                "date": 1683442795127,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "647",
                "rarity_score": 175.0788903872893,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/647.png",
            "scheme": "https"
        }
    },
    "3696": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3696",
                "description": "description",
                "dna": "ee315da1521fdf8e9d69e8df0275fcbc0a4b6e2b",
                "date": 1683442430177,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3696",
                "rarity_score": 187.74596893309808,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3696.png",
            "scheme": "https"
        }
    },
    "32": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #32",
                "description": "description",
                "dna": "708fee47ea24eead2293bb8d3b395d72b4f756d7",
                "date": 1683439614576,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "32",
                "rarity_score": 210.50763368986856,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/32.png",
            "scheme": "https"
        }
    },
    "2882": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2882",
                "description": "description",
                "dna": "956c65533990f2f5c42f963bb9276e33bdfac53b",
                "date": 1683437528032,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2882",
                "rarity_score": 216.28194443990213,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2882.png",
            "scheme": "https"
        }
    },
    "3001": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3001",
                "description": "description",
                "dna": "3f79f9803d9862f0c9c51cddaa69c18bec78c48b",
                "date": 1683430766782,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3001",
                "rarity_score": 178.21965590389308,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3001.png",
            "scheme": "https"
        }
    },
    "520": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #520",
                "description": "description",
                "dna": "a8e85c441021fc4a317eec06f11c26383438575c",
                "date": 1683442425845,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "520",
                "rarity_score": 157.93747308159584,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/520.png",
            "scheme": "https"
        }
    },
    "1291": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1291",
                "description": "description",
                "dna": "2811a8f168228913a7a1221a37b6c31ae1412a37",
                "date": 1683437087459,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1291",
                "rarity_score": 122.98319826253771,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1291.png",
            "scheme": "https"
        }
    },
    "870": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #870",
                "description": "description",
                "dna": "907fdd5993e70b562e68ba3652576d54942b8537",
                "date": 1683433663693,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "870",
                "rarity_score": 199.7264839741103,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/870.png",
            "scheme": "https"
        }
    },
    "1403": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1403",
                "description": "description",
                "dna": "a4d1ff2688442c80916007d52bbb5a466b78f9b4",
                "date": 1683430415608,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "1403",
                "rarity_score": 158.23557379348895,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1403.png",
            "scheme": "https"
        }
    },
    "3529": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3529",
                "description": "description",
                "dna": "23d25af49dff3338d3b1842c722e630f218c4dbd",
                "date": 1683429552975,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3529",
                "rarity_score": 205.3349687892643,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3529.png",
            "scheme": "https"
        }
    },
    "1299": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1299",
                "description": "description",
                "dna": "fb22654b6d05b2c1f9d7d6b63990e106a7439cde",
                "date": 1683431990876,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1299",
                "rarity_score": 169.99694914804257,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1299.png",
            "scheme": "https"
        }
    },
    "2864": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2864",
                "description": "description",
                "dna": "172b020de6a8a7c1d9d3c4a0be79b2ae9a6e8005",
                "date": 1683436254993,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "2864",
                "rarity_score": 308.2043350600624,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2864.png",
            "scheme": "https"
        }
    },
    "1846": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1846",
                "description": "description",
                "dna": "0b0b55ae61e0b87f5371867ac49720b36da8966c",
                "date": 1683433632731,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1846",
                "rarity_score": 170.89841338394254,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1846.png",
            "scheme": "https"
        }
    },
    "1650": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1650",
                "description": "description",
                "dna": "52c7822b0b6f96373af30cf3b50131e6bd6a621d",
                "date": 1683437714191,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1650",
                "rarity_score": 179.94196119302555,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1650.png",
            "scheme": "https"
        }
    },
    "2592": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2592",
                "description": "description",
                "dna": "57af714407aeba016eb24c6c044308964fa6ee28",
                "date": 1683439574307,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2592",
                "rarity_score": 227.6557374490414,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2592.png",
            "scheme": "https"
        }
    },
    "3571": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3571",
                "description": "description",
                "dna": "14ae0f4e989867d609e0c649a8c2bf4737c3a673",
                "date": 1683436765896,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "27",
                        "score": 37.61467889908257
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Mutton Chops",
                        "score": 43.61702127659574
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3571",
                "rarity_score": 249.0990751699695,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3571.png",
            "scheme": "https"
        }
    },
    "420": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #420",
                "description": "description",
                "dna": "97536c00337eea4bb5fd7d6a8322e93b9550c904",
                "date": 1683445189981,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Wayfarer",
                        "score": 26.973684210526315
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "420",
                "rarity_score": 221.17918966453237,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/420.png",
            "scheme": "https"
        }
    },
    "1610": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1610",
                "description": "description",
                "dna": "2dd53c2d6714d268eaa7739168ca9435b2c6ac9d",
                "date": 1683771315963,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1610",
                "rarity_score": 198.74173252053205,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1610.png",
            "scheme": "https"
        }
    },
    "2719": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2719",
                "description": "description",
                "dna": "1225ae1c1d0369056d4d43c64551a750beb001ea",
                "date": 1683430347140,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "2719",
                "rarity_score": 326.06375777047776,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2719.png",
            "scheme": "https"
        }
    },
    "258": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #258",
                "description": "description",
                "dna": "aa258040651cd484c9e99b5e0a4b43a340dd1c4a",
                "date": 1683445870817,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Peace",
                        "score": 56.16438356164384
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "258",
                "rarity_score": 229.8060266835211,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/258.png",
            "scheme": "https"
        }
    },
    "210": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #210",
                "description": "description",
                "dna": "cb7a706f8ada8c1270dd0f96b68582e507fe3cfb",
                "date": 1683775095887,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "210",
                "rarity_score": 161.68617900614913,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/210.png",
            "scheme": "https"
        }
    },
    "445": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #445",
                "description": "description",
                "dna": "2f2315b3a729178c34435a15d94739e68a41e4e0",
                "date": 1683774619280,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "445",
                "rarity_score": 176.862836682047,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/445.png",
            "scheme": "https"
        }
    },
    "2857": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2857",
                "description": "description",
                "dna": "6d87e5bc4a4e142c5e7b0b4b1fc30a0c56be1cdb",
                "date": 1683446763903,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "2857",
                "rarity_score": 159.37966564930053,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2857.png",
            "scheme": "https"
        }
    },
    "3735": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3735",
                "description": "description",
                "dna": "df2b21047103a80eda7054781a149d8e45dc4fbb",
                "date": 1683442822056,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3735",
                "rarity_score": 252.1190242847729,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3735.png",
            "scheme": "https"
        }
    },
    "3503": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3503",
                "description": "description",
                "dna": "60215916bfe2ea9c04eae86a02644bd480dc30c5",
                "date": 1683446166556,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "31",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3503",
                "rarity_score": 144.7020732053759,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3503.png",
            "scheme": "https"
        }
    },
    "1223": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1223",
                "description": "description",
                "dna": "add9bc251dfcf7a027ddf0fd9447f1102e0e3006",
                "date": 1683441489548,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AConsole",
                        "score": 31.060606060606062
                    }
                ],
                "id": "1223",
                "rarity_score": 163.31897446483052,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1223.png",
            "scheme": "https"
        }
    },
    "787": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #787",
                "description": "description",
                "dna": "0105d633565fe13f774cdd78abe1b079401d82ac",
                "date": 1683447044640,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "787",
                "rarity_score": 197.53151552137206,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/787.png",
            "scheme": "https"
        }
    },
    "1261": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1261",
                "description": "description",
                "dna": "a71380f16f6ef4adc59d4cd903e40de2311d914c",
                "date": 1683437880890,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "1261",
                "rarity_score": 220.41359718326243,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1261.png",
            "scheme": "https"
        }
    },
    "1599": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1599",
                "description": "description",
                "dna": "30a0bc7383cdde79715abab5b0c3bf122ca09e11",
                "date": 1683431941265,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "1599",
                "rarity_score": 200.98197101085276,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1599.png",
            "scheme": "https"
        }
    },
    "270": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #270",
                "description": "description",
                "dna": "deb11a62d883cce060b6c2a9417ef5f475d65d6a",
                "date": 1683432092743,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "SGK shield",
                        "score": 105.12820512820512
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Fireball",
                        "score": 69.49152542372882
                    }
                ],
                "id": "270",
                "rarity_score": 316.14973090206564,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/270.png",
            "scheme": "https"
        }
    },
    "1794": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1794",
                "description": "description",
                "dna": "a77869bfecdddae4f3c772b4ff06880a7b8082be",
                "date": 1683431404349,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1794",
                "rarity_score": 200.8008507754601,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1794.png",
            "scheme": "https"
        }
    },
    "3461": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3461",
                "description": "description",
                "dna": "b527aa0aded3579e0936d2889132bd68e25ec5f5",
                "date": 1683440011102,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3461",
                "rarity_score": 148.20720019732983,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3461.png",
            "scheme": "https"
        }
    },
    "703": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #703",
                "description": "description",
                "dna": "093a7b989322f211620d8c950e7a85f777e978a8",
                "date": 1683430642424,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Rasta",
                        "score": 60.294117647058826
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "KDA Moon",
                        "score": 124.24242424242425
                    }
                ],
                "id": "703",
                "rarity_score": 310.69206938180673,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/703.png",
            "scheme": "https"
        }
    },
    "604": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #604",
                "description": "description",
                "dna": "0fd3264c4a38962d0412897a436eecf97632e058",
                "date": 1683441266019,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Yin Yang",
                        "score": 55.4054054054054
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "604",
                "rarity_score": 183.02576082774706,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/604.png",
            "scheme": "https"
        }
    },
    "3047": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3047",
                "description": "description",
                "dna": "42da0292bb2b0e26eae49863bff48ed91254ddf6",
                "date": 1683436524236,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Dragon Bone Hat",
                        "score": 51.89873417721519
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "3047",
                "rarity_score": 184.7158246707018,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3047.png",
            "scheme": "https"
        }
    },
    "1795": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1795",
                "description": "description",
                "dna": "e991ba7b2bb82d8d74ac5a72188667bcdbfdc89b",
                "date": 1683445176798,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "AJoystick",
                        "score": 32.8
                    }
                ],
                "id": "1795",
                "rarity_score": 173.50331976936866,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1795.png",
            "scheme": "https"
        }
    },
    "3361": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3361",
                "description": "description",
                "dna": "8401caff9d681072e33a31b2afbf401f159024d6",
                "date": 1683441650977,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3361",
                "rarity_score": 152.06206160278163,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3361.png",
            "scheme": "https"
        }
    },
    "1821": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1821",
                "description": "description",
                "dna": "8c1f0863c3b67259f2def77b640ebe95adf2c067",
                "date": 1683430310756,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1821",
                "rarity_score": 191.47258006875217,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1821.png",
            "scheme": "https"
        }
    },
    "2650": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2650",
                "description": "description",
                "dna": "37009141e5e65ddc28e4409758078d3c3b2d3b2e",
                "date": 1683433900694,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "2650",
                "rarity_score": 213.84375994210296,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2650.png",
            "scheme": "https"
        }
    },
    "397": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #397",
                "description": "description",
                "dna": "5580dedbe92f49c27e231ffb38b2c40d763b4c57",
                "date": 1683446650256,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "397",
                "rarity_score": 207.9119688972413,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/397.png",
            "scheme": "https"
        }
    },
    "1124": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1124",
                "description": "description",
                "dna": "8fc8ac40cbb2df083956f42545c2be484241ddc1",
                "date": 1683431120668,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Top Hat",
                        "score": 45.55555555555556
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "1124",
                "rarity_score": 195.6927491269763,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1124.png",
            "scheme": "https"
        }
    },
    "1469": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1469",
                "description": "description",
                "dna": "40c7703d01ac0d7cd3c5538ebd977ca71bd3fe1c",
                "date": 1683771417116,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1469",
                "rarity_score": 185.39508029558175,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1469.png",
            "scheme": "https"
        }
    },
    "3064": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3064",
                "description": "description",
                "dna": "842b0554a226920e258f2858c661872ee63694c3",
                "date": 1683508639603,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "K2 Tree",
                        "score": 241.1764705882353
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Owl",
                        "score": 164.0
                    }
                ],
                "id": "3064",
                "rarity_score": 571.9786828653843,
                "rarity_level": "LEGENDARY"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3064.png",
            "scheme": "https"
        }
    },
    "721": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #721",
                "description": "description",
                "dna": "97383c48abdf5a5ec6e8f4988513c6ed45d058e9",
                "date": 1683443220369,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "721",
                "rarity_score": 167.69425103190954,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/721.png",
            "scheme": "https"
        }
    },
    "1490": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1490",
                "description": "description",
                "dna": "672d929ff5ed6b8ded8a9583d0b7de2c49b99205",
                "date": 1683447228579,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "WW1 Helmet",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "1490",
                "rarity_score": 174.67895831217137,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1490.png",
            "scheme": "https"
        }
    },
    "2323": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2323",
                "description": "description",
                "dna": "35f50041f139a1cf166fbe7e3a3777e7729460aa",
                "date": 1683444167108,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2323",
                "rarity_score": 176.13282129592812,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2323.png",
            "scheme": "https"
        }
    },
    "2099": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2099",
                "description": "description",
                "dna": "aa977e4bb2e2669b5fea08e9f0e5f8e577d87c36",
                "date": 1683446511239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "2099",
                "rarity_score": 131.4038374670553,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2099.png",
            "scheme": "https"
        }
    },
    "2308": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2308",
                "description": "description",
                "dna": "a24ddfc3cf954907cfb359be1d263ee9b4f88368",
                "date": 1683774588239,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2308",
                "rarity_score": 177.792972261696,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2308.png",
            "scheme": "https"
        }
    },
    "250": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #250",
                "description": "description",
                "dna": "78c2978658389d0348310df95bc026e2f4870f6c",
                "date": 1683436013002,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Spyder",
                        "score": 97.61904761904762
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "250",
                "rarity_score": 262.8402020307388,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/250.png",
            "scheme": "https"
        }
    },
    "1590": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1590",
                "description": "description",
                "dna": "512d201708802fd44de70f5bc522711e4d5ef414",
                "date": 1683439488990,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "12",
                        "score": 34.166666666666664
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "C4",
                        "score": 56.16438356164384
                    }
                ],
                "id": "1590",
                "rarity_score": 257.9025784405703,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1590.png",
            "scheme": "https"
        }
    },
    "2081": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2081",
                "description": "description",
                "dna": "942479f97873285f96fdf8350b34bc7562ee0940",
                "date": 1683437050959,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2081",
                "rarity_score": 129.86044313116716,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2081.png",
            "scheme": "https"
        }
    },
    "653": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #653",
                "description": "description",
                "dna": "0b36d7ded361acd91c0bd75d5fbb75e9ef408ec6",
                "date": 1683446842339,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Eye",
                        "score": 100.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "653",
                "rarity_score": 229.94675148685798,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/653.png",
            "scheme": "https"
        }
    },
    "2012": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2012",
                "description": "description",
                "dna": "6c32d1ed83688f16d879295e6ada04acb2924ed4",
                "date": 1683430199976,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2012",
                "rarity_score": 213.28069864779835,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2012.png",
            "scheme": "https"
        }
    },
    "557": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #557",
                "description": "description",
                "dna": "c8e499ee3680ca39a91ac455e34737dfdbc8a16b",
                "date": 1683770585656,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "557",
                "rarity_score": 279.32247596499803,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/557.png",
            "scheme": "https"
        }
    },
    "2770": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2770",
                "description": "description",
                "dna": "6466e32b34a54c4b7f68f85ff6205bfa3ec72bfe",
                "date": 1683429898800,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "32",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2770",
                "rarity_score": 198.61216298774067,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2770.png",
            "scheme": "https"
        }
    },
    "1480": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1480",
                "description": "description",
                "dna": "c6c08689b0a4b6bb670329c02b014aa8db9fb9aa",
                "date": 1683443312162,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Chainweb Tattoo",
                        "score": 52.56410256410256
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "1480",
                "rarity_score": 222.2357663199829,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1480.png",
            "scheme": "https"
        }
    },
    "156": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #156",
                "description": "description",
                "dna": "92acfb49c143cc416990075da26a2201d7270973",
                "date": 1683441881292,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "20",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "156",
                "rarity_score": 152.68135596693236,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/156.png",
            "scheme": "https"
        }
    },
    "2795": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2795",
                "description": "description",
                "dna": "8ce89ffab4bdf0c08c573ff4a112bdb61ebab8f0",
                "date": 1683434126958,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "2795",
                "rarity_score": 195.6884030937445,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2795.png",
            "scheme": "https"
        }
    },
    "3387": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3387",
                "description": "description",
                "dna": "6a8ddde4b1a90ef722a8104748c2d4494f2b7611",
                "date": 1683436040100,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 1",
                        "score": 15.891472868217054
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "3387",
                "rarity_score": 151.2580428677728,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3387.png",
            "scheme": "https"
        }
    },
    "192": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #192",
                "description": "description",
                "dna": "6eda82c391fcb0978dfdd9e0dd005cedd400bc7f",
                "date": 1683441539071,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "192",
                "rarity_score": 212.48672026671545,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/192.png",
            "scheme": "https"
        }
    },
    "2448": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2448",
                "description": "description",
                "dna": "8ba2f27ad8b80ce4b6cbe11817b1b395970b08c3",
                "date": 1683429625107,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Cannabis Beard",
                        "score": 48.80952380952381
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Triple Bowl",
                        "score": 16.334661354581673
                    }
                ],
                "id": "2448",
                "rarity_score": 183.26938999277644,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2448.png",
            "scheme": "https"
        }
    },
    "1384": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1384",
                "description": "description",
                "dna": "308cb754e53dc9f824f0a015f46d57b7970793c5",
                "date": 1683432009295,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "6",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "1384",
                "rarity_score": 172.5114843183314,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1384.png",
            "scheme": "https"
        }
    },
    "2426": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2426",
                "description": "description",
                "dna": "5a479ef48f1d809607f7c2d0acb17982c79e6370",
                "date": 1683432542215,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Arkade",
                        "score": 16.205533596837945
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2426",
                "rarity_score": 175.2119458496252,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2426.png",
            "scheme": "https"
        }
    },
    "232": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #232",
                "description": "description",
                "dna": "1dcdcf681d6fc8849971c791bc4d3319ed3b4350",
                "date": 1683430221811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Aviator Glasses",
                        "score": 24.404761904761905
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Aviator",
                        "score": 23.295454545454547
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Cyborg",
                        "score": 33.88429752066116
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "232",
                "rarity_score": 209.90444248472122,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/232.png",
            "scheme": "https"
        }
    },
    "3595": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3595",
                "description": "description",
                "dna": "c2da2d8df81e3cbe4cf8ce210eeb7486ae978a41",
                "date": 1683447155356,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Cover",
                        "value": "VR",
                        "score": 38.3177570093458
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "3595",
                "rarity_score": 213.054944414383,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3595.png",
            "scheme": "https"
        }
    },
    "274": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #274",
                "description": "description",
                "dna": "8f1407ab410052633ca1052332a4d1637f350810",
                "date": 1683440997636,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "1",
                        "score": 23.976608187134502
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "274",
                "rarity_score": 159.37846658911337,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/274.png",
            "scheme": "https"
        }
    },
    "1645": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1645",
                "description": "description",
                "dna": "b4fc181a17961dc51cbf153e4946fcd609cb608f",
                "date": 1683431678575,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Leather",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1645",
                "rarity_score": 146.34129312056015,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1645.png",
            "scheme": "https"
        }
    },
    "2802": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2802",
                "description": "description",
                "dna": "30651ee79d67818a7df5efbe3170f06f0e891beb",
                "date": 1683440400116,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "4",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2802",
                "rarity_score": 152.48049595480148,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2802.png",
            "scheme": "https"
        }
    },
    "2228": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2228",
                "description": "description",
                "dna": "d2ca91bbcc910fb6243fd28c74b8539742b8e50c",
                "date": 1683430869376,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2228",
                "rarity_score": 166.8777466027452,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2228.png",
            "scheme": "https"
        }
    },
    "2443": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2443",
                "description": "description",
                "dna": "a617f2327ce95c9b8853c62fb7fbe2985c05a4c4",
                "date": 1683432848316,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Black",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Ancient Gauntlet",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gaia",
                        "score": 55.4054054054054
                    }
                ],
                "id": "2443",
                "rarity_score": 248.24667851059456,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2443.png",
            "scheme": "https"
        }
    },
    "3277": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3277",
                "description": "description",
                "dna": "3a53989424ec8787a50d9fe2c8910e5c7fd2c5e9",
                "date": 1683432839423,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "14",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Koala",
                        "score": 13.57615894039735
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Braids Black",
                        "score": 28.67132867132867
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "3277",
                "rarity_score": 211.70788094630228,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3277.png",
            "scheme": "https"
        }
    },
    "1768": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1768",
                "description": "description",
                "dna": "0b4e9c7dd489ae4f52d1ab71853fb64f9d3b5543",
                "date": 1683432101331,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "18",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Ledger",
                        "score": 48.23529411764706
                    }
                ],
                "id": "1768",
                "rarity_score": 249.32272401195206,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1768.png",
            "scheme": "https"
        }
    },
    "766": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #766",
                "description": "description",
                "dna": "b2688bfea1b1984fdfaf67f11c7e181e7e5b2221",
                "date": 1683431959677,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Gauntlet of Light",
                        "score": 35.96491228070175
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "766",
                "rarity_score": 219.86922679991076,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/766.png",
            "scheme": "https"
        }
    },
    "2297": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2297",
                "description": "description",
                "dna": "a4cb7cbc53c9b8c397c62066468615038a018de3",
                "date": 1683771196699,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Viking",
                        "score": 33.333333333333336
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "2297",
                "rarity_score": 174.21990943631593,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2297.png",
            "scheme": "https"
        }
    },
    "3693": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3693",
                "description": "description",
                "dna": "14a1ec839cbeb780bcf0c0f7b6090ffd3b24c806",
                "date": 1683437503276,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "2",
                        "score": 32.03125
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 4",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Mining Drill",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Gem",
                        "score": 17.4468085106383
                    }
                ],
                "id": "3693",
                "rarity_score": 166.43666985546247,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3693.png",
            "scheme": "https"
        }
    },
    "613": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #613",
                "description": "description",
                "dna": "fc0fb2b60be2f30e27978548dbee1965927a70ba",
                "date": 1683437597937,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Tiki",
                        "score": 20.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "613",
                "rarity_score": 140.90128799082802,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/613.png",
            "scheme": "https"
        }
    },
    "1542": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1542",
                "description": "description",
                "dna": "a990d9afca055c610d8c094f41efab189ab2c863",
                "date": 1683439085495,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "29",
                        "score": 34.74576271186441
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tools",
                        "score": 24.2603550295858
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1542",
                "rarity_score": 177.11601678750182,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1542.png",
            "scheme": "https"
        }
    },
    "3673": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3673",
                "description": "description",
                "dna": "46a89b2a1484f01b412ffa96718260adbe40eb0d",
                "date": 1683776771049,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "10",
                        "score": 32.8
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Trinkets",
                        "score": 47.674418604651166
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Stake Factory",
                        "score": 17.15481171548117
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Grey",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Loki",
                        "score": 37.27272727272727
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "3673",
                "rarity_score": 215.23913228879675,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3673.png",
            "scheme": "https"
        }
    },
    "169": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #169",
                "description": "description",
                "dna": "8236f7beef23367b30f2ebba4651b3c0422d478f",
                "date": 1683774788287,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "SGK Eyepatch",
                        "score": 18.141592920353983
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Beer Can Hat",
                        "score": 21.025641025641026
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Blood Metal",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "169",
                "rarity_score": 201.56534474960748,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/169.png",
            "scheme": "https"
        }
    },
    "3068": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3068",
                "description": "description",
                "dna": "e9fa5d62a42c9623eb2e9484b603c48ec95091f3",
                "date": 1683436232358,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "11",
                        "score": 41.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Green",
                        "score": 16.73469387755102
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Alpha Waves",
                        "score": 102.5
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3068",
                "rarity_score": 251.1266582537363,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3068.png",
            "scheme": "https"
        }
    },
    "2480": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2480",
                "description": "description",
                "dna": "9b29428dde5dcd1e0eccecb71c676105858890e1",
                "date": 1683445728145,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "15",
                        "score": 35.042735042735046
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Platemail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2480",
                "rarity_score": 204.28587694501303,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2480.png",
            "scheme": "https"
        }
    },
    "2472": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2472",
                "description": "description",
                "dna": "41a1c917cd2fc3aaf2dc2af4ff045b6dc5cf2080",
                "date": 1683429940811,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "28",
                        "score": 31.297709923664122
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Tribal",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "MOK",
                        "score": 15.298507462686567
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Kadena Tattoo",
                        "score": 48.23529411764706
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "2472",
                "rarity_score": 194.50766025186536,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2472.png",
            "scheme": "https"
        }
    },
    "1788": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1788",
                "description": "description",
                "dna": "52cad318634b8d47bab7b5f1f982f102f172fcc5",
                "date": 1683517653908,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 1",
                        "score": 50.0
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Flux",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Bullish Tattoo",
                        "score": 25.153374233128833
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kadena",
                        "score": 4100.0
                    }
                ],
                "id": "1788",
                "rarity_score": 4309.513733674432,
                "rarity_level": "ONE OF ONE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1788.png",
            "scheme": "https"
        }
    },
    "2447": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2447",
                "description": "description",
                "dna": "7d11ac76f37ffc05518e059072187c948ac067f6",
                "date": 1683446972923,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "13",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Night Vision",
                        "score": 25.625
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "2447",
                "rarity_score": 212.08142479578672,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2447.png",
            "scheme": "https"
        }
    },
    "1771": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1771",
                "description": "description",
                "dna": "305507265a504b23b565d1706a635540171b1f1a",
                "date": 1683443034095,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "21",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bearish",
                        "score": 18.89400921658986
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Green",
                        "score": 23.163841807909606
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Miner Helmet",
                        "score": 18.552036199095024
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NConsole",
                        "score": 23.03370786516854
                    }
                ],
                "id": "1771",
                "rarity_score": 172.73097087922338,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1771.png",
            "scheme": "https"
        }
    },
    "914": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #914",
                "description": "description",
                "dna": "d3b51391c833ca8c72ed93a5b060724e16f27b39",
                "date": 1683435475572,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "8",
                        "score": 29.078014184397162
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Red",
                        "score": 21.57894736842105
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Ponytail",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Death Viper",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "914",
                "rarity_score": 215.8244113388434,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/914.png",
            "scheme": "https"
        }
    },
    "3500": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3500",
                "description": "description",
                "dna": "d3cee549b8da1f5177a470b3b428c6d341038db7",
                "date": 1683442994033,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "7",
                        "score": 31.53846153846154
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Fist",
                        "score": 6.128550074738415
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Metal Top Hat",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "3500",
                "rarity_score": 162.04318795087627,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3500.png",
            "scheme": "https"
        }
    },
    "460": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #460",
                "description": "description",
                "dna": "b11877baebe369137d3236dd275023ef512e2fe9",
                "date": 1683429950332,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "17",
                        "score": 26.11464968152866
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Roots",
                        "score": 58.57142857142857
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Red",
                        "score": 16.666666666666668
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Bud",
                        "score": 41.83673469387755
                    }
                ],
                "id": "460",
                "rarity_score": 215.04762580205795,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/460.png",
            "scheme": "https"
        }
    },
    "2779": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2779",
                "description": "description",
                "dna": "1b173d5121c1664380dc5bb4183857961c5853ff",
                "date": 1683435549797,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Stitched Mouth",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KOR",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Brown",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "W Claws",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2779",
                "rarity_score": 242.09374997548278,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2779.png",
            "scheme": "https"
        }
    },
    "1933": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1933",
                "description": "description",
                "dna": "fe130572441f290c2e2c1566fb188ebd4307caec",
                "date": 1683444599183,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 2",
                        "score": 17.372881355932204
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "English Hat",
                        "score": 19.806763285024154
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Pcontroller",
                        "score": 20.0
                    }
                ],
                "id": "1933",
                "rarity_score": 174.09873594198828,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1933.png",
            "scheme": "https"
        }
    },
    "2916": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2916",
                "description": "description",
                "dna": "f36cd840233ebc9db8fa2f444f2d26aa9ce86e5e",
                "date": 1683445803411,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Fist",
                        "score": 6.612903225806452
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Brown",
                        "score": 21.80851063829787
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Raiden",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Royal Gauntlet",
                        "score": 10.224438902743142
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "PConsole",
                        "score": 19.1588785046729
                    }
                ],
                "id": "2916",
                "rarity_score": 199.04073356921424,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2916.png",
            "scheme": "https"
        }
    },
    "3089": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3089",
                "description": "description",
                "dna": "c1bde77e73e02971f09613d0765f41d76e6a49e6",
                "date": 1683431661388,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "30",
                        "score": 36.607142857142854
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Devil",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Black Ponytail",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "3089",
                "rarity_score": 174.65311212498275,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3089.png",
            "scheme": "https"
        }
    },
    "398": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #398",
                "description": "description",
                "dna": "e2b96c108a673ee2d0a1bc5cdb2e53135e6aea67",
                "date": 1683436384746,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "22",
                        "score": 30.147058823529413
                    },
                    {
                        "trait_type": "Body",
                        "value": "Medium Hand",
                        "score": 7.282415630550622
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Yellow Eyes",
                        "score": 5.256410256410256
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 4",
                        "score": 16.26984126984127
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Ecko",
                        "score": 17.22689075630252
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Nerd Glass",
                        "score": 7.976653696498055
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Kitty Kad",
                        "score": 18.141592920353983
                    }
                ],
                "id": "398",
                "rarity_score": 169.91880761440962,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/398.png",
            "scheme": "https"
        }
    },
    "1003": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1003",
                "description": "description",
                "dna": "8bb4eaf41478267e3110115eb9dc681cdd22b8f2",
                "date": 1683770634901,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "24",
                        "score": 25.0
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Fist",
                        "score": 14.695340501792115
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Rainbow Grill",
                        "score": 8.36734693877551
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena Tattoo",
                        "score": 33.064516129032256
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Gem Beard",
                        "score": 33.60655737704918
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Ancient Crown",
                        "score": 82.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Leather Gauntlet",
                        "score": 11.294765840220386
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Wrench",
                        "score": 12.1301775147929
                    }
                ],
                "id": "1003",
                "rarity_score": 248.18816299088115,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1003.png",
            "scheme": "https"
        }
    },
    "3424": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3424",
                "description": "description",
                "dna": "a80c17546c1079a0bc3809683ec7f7e753e6bea5",
                "date": 1683446109199,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "5",
                        "score": 34.45378151260504
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Kadena 3",
                        "score": 17.29957805907173
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Swarms",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Gas Mask",
                        "score": 23.69942196531792
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Jackhammer",
                        "score": 11.11111111111111
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Mining Pick",
                        "score": 12.852664576802507
                    }
                ],
                "id": "3424",
                "rarity_score": 156.99259674602794,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3424.png",
            "scheme": "https"
        }
    },
    "2829": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2829",
                "description": "description",
                "dna": "41e052731cdbe79f44dc8813b4809b61df79d7a2",
                "date": 1683438783996,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "3",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Fist",
                        "score": 35.65217391304348
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Diamond Grill",
                        "score": 13.621262458471762
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Bullish",
                        "score": 15.129151291512915
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Zelcore",
                        "score": 19.617224880382775
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Tassel Blue",
                        "score": 24.848484848484848
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Monocle",
                        "score": 6.083086053412463
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Brown Cowboy",
                        "score": 18.46846846846847
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "TSAO Flux",
                        "score": 107.89473684210526
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "NController",
                        "score": 24.698795180722893
                    }
                ],
                "id": "2829",
                "rarity_score": 303.84338905693255,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2829.png",
            "scheme": "https"
        }
    },
    "3174": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #3174",
                "description": "description",
                "dna": "d0a653168c93201b7a55c9411225e919d5358099",
                "date": 1683430204135,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "9",
                        "score": 28.08219178082192
                    },
                    {
                        "trait_type": "Body",
                        "value": "Blue Hand",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 1",
                        "score": 5.593451568894952
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Red Eyes",
                        "score": 5.290322580645161
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Pauldron",
                        "score": 17.4468085106383
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "WizardsArena",
                        "score": 14.0893470790378
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Red",
                        "score": 16.334661354581673
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Grey Ponytail",
                        "score": 16.94214876033058
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Flower of Life",
                        "score": 41.0
                    }
                ],
                "id": "3174",
                "rarity_score": 194.4139802969005,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/3174.png",
            "scheme": "https"
        }
    },
    "1340": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1340",
                "description": "description",
                "dna": "7d8f14a7d22a5de68086af61255855eb304db091",
                "date": 1683436263461,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "25",
                        "score": 29.285714285714285
                    },
                    {
                        "trait_type": "Body",
                        "value": "Dark Hand",
                        "score": 13.225806451612904
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Black_Red Eyes",
                        "score": 21.354166666666668
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK Tattoo",
                        "score": 178.2608695652174
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Alpha Slayers",
                        "score": 14.335664335664335
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Assassin Hood",
                        "score": 51.25
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Plated Gauntlet",
                        "score": 11.68091168091168
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Blow Torch",
                        "score": 13.183279742765274
                    }
                ],
                "id": "1340",
                "rarity_score": 346.8891851835027,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1340.png",
            "scheme": "https"
        }
    },
    "2121": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #2121",
                "description": "description",
                "dna": "ad69699e1c2bef244bc243a6fbf9fa81bdd6c0e9",
                "date": 1683435774588,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "23",
                        "score": 40.19607843137255
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Purple Eyes",
                        "score": 5.324675324675325
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Dark Magic",
                        "score": 49.397590361445786
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Kitty Kad",
                        "score": 13.486842105263158
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Green",
                        "score": 15.708812260536398
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Jeweler Glasses",
                        "score": 8.817204301075268
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Man Bun",
                        "score": 19.523809523809526
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Electro Gauntlet",
                        "score": 11.884057971014492
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Telescope",
                        "score": 38.3177570093458
                    }
                ],
                "id": "2121",
                "rarity_score": 215.19496657703672,
                "rarity_level": "RARE"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/2121.png",
            "scheme": "https"
        }
    },
    "1743": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1743",
                "description": "description",
                "dna": "cacca54eab92e7d47f54bd86c91421b75239674f",
                "date": 1683429781938,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "16",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Hand",
                        "score": 9.44700460829493
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Gold Grill",
                        "score": 8.932461873638344
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gnomevania",
                        "score": 32.53968253968254
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KMC",
                        "score": 16.59919028340081
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Grey",
                        "score": 21.925133689839573
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Blue Goggles",
                        "score": 8.649789029535865
                    },
                    {
                        "trait_type": "Cover",
                        "value": "Mohawk",
                        "score": 20.0
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Buckles Gauntlet",
                        "score": 10.704960835509139
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Mecha Dragon",
                        "score": 170.83333333333334
                    }
                ],
                "id": "1743",
                "rarity_score": 337.4547438875562,
                "rarity_level": "EPIC"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1743.png",
            "scheme": "https"
        }
    },
    "1638": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1638",
                "description": "description",
                "dna": "286086b0d284a67e1c7dcd125fff79bbd15ee578",
                "date": 1683429927001,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "26",
                        "score": 31.060606060606062
                    },
                    {
                        "trait_type": "Body",
                        "value": "Light Hand",
                        "score": 6.913996627318719
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 2",
                        "score": 5.662983425414365
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Blue Eyes",
                        "score": 5.086848635235732
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "SGK 3",
                        "score": 22.52747252747253
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "Babena",
                        "score": 13.804713804713804
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Triple Black",
                        "score": 14.642857142857142
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Green Goggles",
                        "score": 7.869481765834933
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Secret Garden Flowers",
                        "score": 11.452513966480447
                    },
                    {
                        "trait_type": "Open Left Hand",
                        "value": "Crystal Ball",
                        "score": 15.185185185185185
                    }
                ],
                "id": "1638",
                "rarity_score": 149.06173160488703,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1638.png",
            "scheme": "https"
        }
    },
    "1324": {
        "datum": {
            "uri": {
                "data": "nft-data",
                "scheme": "pact:schema"
            },
            "datum": {
                "name": "Gen 1 #1324",
                "description": "description",
                "dna": "6f0e675936bc4e7f48245f11739770782d1f4ede",
                "date": 1683440499428,
                "attributes": [
                    {
                        "trait_type": "Background",
                        "value": "19",
                        "score": 39.04761904761905
                    },
                    {
                        "trait_type": "Body",
                        "value": "Silver Fist",
                        "score": 10.789473684210526
                    },
                    {
                        "trait_type": "Mouth",
                        "value": "Mouth 3",
                        "score": 5.6241426611796985
                    },
                    {
                        "trait_type": "Eyes",
                        "value": "Green Eyes",
                        "score": 5.283505154639175
                    },
                    {
                        "trait_type": "Outfit",
                        "value": "Gems",
                        "score": 26.623376623376622
                    },
                    {
                        "trait_type": "Medallion",
                        "value": "KPN",
                        "score": 15.530303030303031
                    },
                    {
                        "trait_type": "Beard",
                        "value": "Full Black",
                        "score": 15.76923076923077
                    },
                    {
                        "trait_type": "Accessory",
                        "value": "Round Shades",
                        "score": 18.385650224215247
                    },
                    {
                        "trait_type": "Cover",
                        "value": "S Fighter",
                        "score": 14.855072463768115
                    },
                    {
                        "trait_type": "Gauntlet",
                        "value": "Assassin Gauntlet",
                        "score": 11.420612813370473
                    },
                    {
                        "trait_type": "Closed Left Hand",
                        "value": "Lantern",
                        "score": 13.05732484076433
                    }
                ],
                "id": "1324",
                "rarity_score": 176.38631131267704,
                "rarity_level": "COMMON"
            }
        },
        "uri": {
            "data": "https://secret-garden-of-kadena-gen-1.nyc3.cdn.digitaloceanspaces.com/images/1324.png",
            "scheme": "https"
        }
    }
}

export default sgk_gen_1_metadata